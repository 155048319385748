<template>
    <div class="myPage">
      <div class="container">
        <div class="page__titleAndNews pt-32 pb-32">
          <div class="page__titleAndNewsLeft">
            <h2 class="title07">
              <span class="title07__span">新着情報</span>
            </h2>
          </div>
        </div>
        <div
          class="newsList"
          v-if="
            typeof information_list === 'object' && information_list.length > 0
          "
        >
          <li
            class="newsList__li"
            v-for="list in information_list"
            :key="list.id"
          >
            <div class="d-flex align-items-center flex-shrink-0 py-1">
              <p class="newsList__category newsList__category--color02">
                {{ list.category_type_label }}
              </p>
              <p class="newsList__date">{{ list.set_date_date_val }}</p>
            </div>
            <p class="newsList__text" @click="go_info_detail(list.id)">
              {{ list.subject }}
            </p>
          </li>
        </div>
        <div class="mt-32">
          <bs-page-nav
            :pager_key="'cinformation'"
            @to_click_event="pageAction"
            :pager="pager"
          ></bs-page-nav>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import config from '../../../const'
    import BsPageNav from '../../molecules/BsPageNav.vue'
  
    export default {
      name: 'OwnerInformationListView',
      data: function () {
        return {
          sprm: {
            stat: [1],
            public_type: 1,
            target_type: 2
          },
          page: 0,
          page_limit: 10,
          BASE_ASSETS_URL: config.BASE_ASSETS_URL,
          information_category_type_obj: [],
          information_obj: [],
          pager: {},
        }
      },
      props: {},
      components: {
        BsPageNav,
      },
      computed: {
        information_category_type_list: function () {
          return this.information_category_type_obj
        },
        information_list: function () {
          return this.information_obj
        },
      },
  
      async created() {
        await this.loadSearchParam()
        await this.loadConfigObj('information_category_type')
        await this.loadInformationList()
      },
  
      methods: {
        go_info_detail(info_id) {
          this.$router.push({
            path: '/cinformation/data',
            query: {
              no: info_id,
            },
          })
        },
        async loadConfigObj(param) {
          const params = {}
          await this.basicPosts_S(
            config.BASE_API_URL + '/config/' + param,
            params
          )
            .then(async (response) => {
              console.log('loadConfigObj response', response)
              this[param + '_obj'] =
                response.data && response.data.body ? response.data.body : []
            })
            .catch(async (err) => {
              console.log(err)
              this[param + '_obj'] = []
            })
            .finally(async () => {
              this.$forceUpdate()
            })
        },
        async loadSearchParam() {
          const params = { ...this.$store.getters.getSearchParam('cinformation') }
          if (params) {
            Object.keys(params).forEach((key) => {
              if (this.is_json(params[key])) {
                params[key] = JSON.parse(params[key])
              }
              this.sprm[key] = params[key]
            })
          }
        },
        async pageAction(page) {
          this.page = page
          await this.loadInformationList()
        },
        async loadInformationList() {
          const params = {
            stat: this.sprm?.stat || [1],
            target_type: this.sprm?.target_type,
            // public_type: this.sprm?.public_type || 1,
            pagelimit: this.page_limit,
            pagelimit_key: this.page_limit,
            p: this.page >= 0 ? this.page : 0,
            pagegroup: 'mfj_information_data.id',
            pageorder: 'mfj_information_data.set_date',
            pagesort: 'DESC',
          }
  
          this.$store.dispatch('setSearchParam', {
            data: params,
            target: 'cinformation',
          })
  
          try {
            const response = await this.basicPosts_S(
              `${config.BASE_API_URL}/information/lists`,
              params
            )
            this.information_obj = response.data?.body || []
            this.pager = response.data?.pager || {}
            console.log('Pager data:', this.pager)
          } catch (err) {
            console.error('Load information error:', err)
            this.information_obj = []
          } finally {
            this.$forceUpdate()
          }
        },
      },
    }
  </script>
  
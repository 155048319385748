<template>
  <div class="page">
        <div class="myPage">
          <div class="container">
            <h2 class="title page__h1">マイページ</h2>
            <div class="row">
              <div class="page__inputGroupCol col-md-6">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel">主催者コード</label>
                  <span class="showData page__inputGroupInput">{{ (data_obj || {}).owner_no }}</span>
                </p>
              </div>
              <div class="page__inputGroupCol col-md-6">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel">主催者名</label>
                  <span class="showData page__inputGroupInput">
                    {{ (data_obj||{}).name_1 }}
                  </span>
                </p>
              </div>
            </div>

            <div class="page__titleAndNews pt-32 pb-32">
              <div class="page__titleAndNewsLeft">
                <h2 class="title07 page__titleAndNewsTitle"><span class="title07__span">新着情報</span></h2>
              </div>
              <div class="newsList page__titleAndNewsRight" v-if="typeof information_list === 'object' && information_list.length > 0">
                <li class="newsList__li" v-for="list in information_list.slice(0, 5)" :key="list.id">
                  <div class="d-flex align-items-center flex-shrink-0">
                    <p class="newsList__category newsList__category--color02">
                      <!-- カテゴリー5 -->
                      {{ list.category_type_label }}
                    </p>
                    <p class="newsList__date">{{ list.set_date_date_val }}</p>
                  </div>
                  <p class="newsList__text" @click="go_info_detail(list.id)">
                    {{ list.subject }}
                  </p>
                </li>
              </div>
            </div>
            <p class="text-center mb-32 pb-32" v-if="typeof information_list === 'object' && information_list.length > 0">
              <router-link class="button04" to="/oinformation/list"
                ><span class="button04__text">もっとみる</span
                ><i class="button04__icon fa-solid fa-chevron-right"></i
              ></router-link>
            </p>
            <!-- <p class="text-center">
              <a class="button04" href=""><span class="button04__text">もっとみる</span><i class="button04__icon fa-solid fa-chevron-right"></i></a>
            </p> -->
          </div>
        </div>
      </div>
</template>

<!-- <style>
  .bg_color_1 {
    background: linear-gradient(#cb000b, #cb000b, #cb000b, #ffffff);
  }
  .bg_color_2 {
    background: linear-gradient(#0c236d, #0c236d, #0c236d, #ffffff);
  }
  .bg_color_3 {
    background: linear-gradient(#feb50d, #feb50d, #feb50d, #ffffff);
  }
</style> -->

<script>
  import { mapState } from 'vuex'
  import config from '../../const'
  // import VueQrcode from "@chenfengyuan/vue-qrcode";
  // import BsPageTitle from '../molecules/BsPageTitle.vue'
  // // import BsButton from '../atoms/BsButton.vue'
  // import BsSelect from '../atoms/BsSelect.vue'
  // import BsDashBoardNav from './BsDashBoardNavView.vue'
  // import BsPageNav from '../../molecules/BsPageNav.vue'
  // import Common from '../../common/common'
  // // import Checkin from './Checkin/CheckinView.vue'
  // import axios from 'axios';
  // import config from '../../const'

  export default {
    name: 'BsOwnerMypageView',
    data: function () {
      return {
        pref_obj: [],
        license_type_obj: [],
        license_category_obj: [],
        license_class_obj: [],
        event_cd_obj: [],
        license_cd_obj: [],
        data_obj: {},
        selected_license_id: 0,
        clock: '',
        BASE_ASSETS_URL: config.BASE_ASSETS_URL,
        information_category_type_obj:[],
        information_obj: [],
        demo_info: [
          {
            id: 1,
            label: '主催レース申請方法変更のお知らせ',
            date: '2024.01.01',
            category: 'カテゴリー5',
          },
          {
            id: 2,
            label: '主催レース申請方法変更のお知らせ',
            date: '2024.01.01',
            category: 'カテゴリー4',
          },
          {
            id: 3,
            label: '主催レース申請方法変更のお知らせ',
            date: '2024.01.01',
            category: 'カテゴリー3',
          },
          {
            id: 4,
            label: '主催レース申請方法変更のお知らせ',
            date: '2024.01.01',
            category: 'カテゴリー2',
          },
          {
            id: 5,
            label: '主催レース申請方法変更のお知らせ',
            date: '2024.01.01',
            category: 'カテゴリー1',
          },
        ],
        demo_app: [
          {
            id: 1,
            label: 'レース申請',
            date: '2024.01.01',
            category: '変更（ライセンス発行書）',
          },
          {
            id: 2,
            label: '継続申請の状況',
            date: '2024.01.01',
            category: '継続',
          },
        ],
      }
    },
    props: {},
    components: {},
    computed: {
      get_auth: function(){
        return this.$store.state.oauth
      },
      information_list: function () {
        return this.information_obj
      },
      ...mapState({}),
      auth: function () {
        return this.get_load_type == 'owner'
          ? this.$store.state.oauth
          : this.get_load_type == 'club'
          ? this.$store.state.cauth
          : this.get_load_type == 'member'
          ? this.$store.state.auth
          : false
      },
    },

    async created() {
      // console.log('status ', this.$store.state);
      // return false;
      await this.loadOwnerObj();
      await this.loadTermYear()
      await this.loadConfigObj('information_category_type')
      await this.loadInformationList()
    },
    mounted: function () {},

    methods: {
      async loadConfigObj(param) {
        const params = {}
        await this.basicPosts_S(
          config.BASE_API_URL + '/config/' + param,
          params
        )
          .then(async (response) => {
            console.log('loadConfigObj response', response)
            this[param + '_obj'] =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this[param + '_obj'] = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadTermYear(){
          const params = {};
          await this.basicPosts_S(
              config.BASE_API_URL + '/term_year/lists',
              params
          )
          .then(
              async (response) => {
                  console.log('loadTermYear response', response);
                  this.term_year_obj = (response.data && response.data.body)? response.data.body: [];
              }
          )
          .catch(
              async (err) => {
              console.log(err);
              this.term_year_obj = [];
          })
          .finally(async () => {
              this.$forceUpdate();
          });
      },
      async loadInformationList() {
        const params = {
          pagelimit: 10,
          pagelimit_key: 10,
          p: 0,
          pagegroup: 'mfj_information_data.id',
          pageorder: 'mfj_information_data.set_date',
          pagesort: 'DESC'
        }
        params['stat'] = [1]
        // params['public_type'] = 1
        params['category_types'] = [1,2]
        params['target_type'] = 3

        try {
          const response = await this.basicPosts_S(
            `${config.BASE_API_URL}/information/lists`,
            params
          )
          console.log('loadInformationList response', response)
          this.information_obj = response.data?.body || []
          console.log('this.information_obj', this.information_obj)
        } catch (err) {
          console.error('Load information error:', err)
          this.information_obj = []
        } finally {
          this.$forceUpdate()
        }
        
      },


      async go_info(id) {
        console.log('info_id', id)
      },
      go_info_detail(info_id){
        this.$router.push({
          path: '/oinformation/data',
          query: {
            no: info_id,
          },
        })
      },
      async loadPrefList() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(
          config.BASE_API_URL + '/area_pref/lists',
          params
        )
          .then(async (response) => {
            console.log('loadPrefList response', response)
            this.pref_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.pref_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadOwnerObj() {
        const params = {}
        params['stat'] = [1]
        params['id'] = this.get_auth.userId
        await this.basicPosts_S(config.BASE_API_URL + '/owner/lists', params)
          .then(async (response) => {
            console.log('loadOwnerObj', response)
            this.data_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },


    },
  }
</script>

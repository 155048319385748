<template>
  <div class="d-flex flex-column min-vh-100">
    <header class="header" id="header">
      <div class="container" style="min-height: auto !important;">
        <div class="header__inner">
          <h1 class="header__logo">
            <a class="header__logoLink" href="/mfj_renewal/app/">
              <img
                :src="require('@/assets/img/header_img_01.png')"
                alt="Motorcycle Federation of Japan"
            /></a>
          </h1>
        </div>
      </div>
    </header>
    <main
      id="main"
      class="flex-grow-1 d-flex align-items-center justify-content-center py-5"
    >
      <div class="container w-100">
        <router-view></router-view>
      </div>
    </main>
    <footer class="footer" id="footer">
      <div class="footer__copy">
        <div class="container">
          <p>©2020 Motorcycle Federation of Japan All RightsReserved.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  // import BsPasswdForm from '../molecules/BsPasswdForm.vue'
  // import '../../assets/bootstrap/css/sc.css'

  export default {
    name: 'BsTemplateLogoutView',
    data: function () {
      return {
        // 'login_id': '',
        // 'birth_date': '',
        error: false,
      }
    },
    computed: {
      ...mapState({}),
    },
    components: {
      // BsPasswdForm,
    },
    methods: {},
  }
</script>

<style scoped>
  .header,
  .footer {
    flex-shrink: 0;
  }

  #main {
    overflow-y: auto;
    width: 100%;
  }

  .login__title {
    margin-bottom: 2rem;
  }

  .login__box {
    margin-top: 2rem;
  }
</style>

<template>
  <section class="page">
    <!-- ローディング表示を追加 -->
    <div class="loading-overlay" v-if="isLoading">
      <div class="spinner-border text-danger" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="container" v-if="mfj_phase_year_term_001">
      <div class="row">
          <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
              <div class="box">
                  現在年度切り替え中です。<br>
                  しばらくしてお申し込み下さい。
              </div>
          </div>
      </div>
    </div>
    <div class="container" v-else>
      <div class="d-none d-md-block">
        <step-view :step_mode="page_mode"></step-view>
      </div>
      <div class="w-100">
        <div class="mb-12">
            <div class=" text-end">
                申請日:{{ get_now_date?.date }}({{ get_term_year }}年度)
            </div>
        </div>
        <div v-if="error_mode">
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-exclamation-lg"></i>&nbsp;エラー
              </div>
              <p>URLエラー</p>
            </div>
          </div>
        </div>
        <div v-else-if="reject_mode">
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-exclamation-lg"></i>&nbsp;承認拒否
              </div>
              <p>承認されませんでした。</p>
            </div>
          </div>
        </div>
        <div v-else-if="enquate_mode">
          <div class="mb-3">
            <div class="box02 mb-32">
              <h2 class="box02__title">
                <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
                申請完了
              </h2>
              <p>
                すべての申請処理が完了しました。<br />
                アンケートにお答えください。
              </p>
            </div>
            <!-- {{ enquate_sheet_list }} -->
            <div
              class="container"
              v-if="
                typeof enquate_sheet_list === 'object' &&
                enquate_sheet_list.length > 0
              "
            >
              <h5>アンケート</h5>
              <div
                class="w100"
                v-for="sheet in enquate_sheet_list"
                :key="sheet"
              >
                <div
                  class="row mb-12"
                  v-for="enquate in sheet.enquate_data"
                  :key="enquate"
                >
                  <h4 class="col-12 title04 page__h4">
                    {{ enquate.label }}
                  </h4>
                  <div
                    v-if="enquate.enquate_type == 300"
                    class="col-12 col-md-8"
                  >
                    <bs-select
                      :options="enquate.options"
                      :nullOption="true"
                      v-model="enquate.answers"
                      class="flex-grow-1 mb-12"
                    ></bs-select>
                  </div>
                  <div
                    v-else-if="enquate.enquate_type == 400"
                    class="col-12 col-md-8"
                  >
                    <div
                      class="radio"
                      v-for="option in enquate.options"
                      :key="option.id"
                    >
                      <input
                        class="radio__input"
                        type="checkbox"
                        :id="'checkbox_' + enquate.id + '_' + option.id"
                        :value="option.label"
                        v-model="enquate.answers"
                      />
                      <label
                        class="radio__label"
                        :for="'checkbox_' + enquate.id + '_' + option.id"
                      >
                        {{ option.label }}
                      </label>
                    </div>
                  </div>
                  <div
                    v-else-if="enquate.enquate_type == 100"
                    class="col-12 col-md-8"
                  >
                    <input
                      type="text"
                      v-model="enquate.answers"
                      class="form-control"
                    />
                  </div>
                  <div
                    v-else-if="enquate.enquate_type == 110"
                    class="col-12 col-md-8"
                  >
                    <input
                      type="number"
                      v-model="enquate.answers"
                      class="form-control"
                    />
                  </div>
                  <div
                    v-else-if="enquate.enquate_type == 200"
                    class="col-12 col-md-8"
                  >
                    <textarea
                      v-model="enquate.answers"
                      class="form-control flex-grow-1 mb-12"
                    ></textarea>
                  </div>
                </div>
                <div class="row mb-2 mt-24">
                  <div class="col-12">
                    <div class="d-flex flex-column flex-md-row">
                      <button
                        class="button04 flex-grow-1"
                        @click="answer_enquate"
                      >
                        <span class="button04__text">アンケートに答える</span>
                        <font-awesome-icon
                          :icon="['fas', 'chevron-right']"
                          class="mx-2"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="approval_mode">
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-exclamation-lg"></i>&nbsp;承認
              </div>
              <p>承認されました。</p>
              <p>
                <router-link to="/">マイページ</router-link>へ進んでください。
              </p>
            </div>
          </div>
        </div>
        <div v-else-if="success_mode">
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-exclamation-lg"></i>&nbsp;承認
              </div>
              <p>承認されています。</p>
              <p>マイページへ進んでください</p>
            </div>
          </div>
        </div>
        <div v-else-if="cash_unpaid_mode">
          <div class="box02 mb-32">
            <h2 class="box02__title">
              <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
              コンビニ支払い確認中
            </h2>
            <p>
              コンビニ支払いの確認中です。<br />
              お早めにお支払い下さい
            </p>
          </div>
          <div>
            <ul v-if="has_receipt_data" class="list-group">
              <li
                v-for="(value, key) in receipt"
                :key="key"
                class="list-group-item d-flex justify-content-between"
              >
                <span class="fw-bold text-capitalize" v-if="key=='konbiniCode'">コンビニ</span>
                <span class="fw-bold text-capitalize" v-else-if="key=='receiptNumber'">お客様番号</span>
                <span class="fw-bold text-capitalize" v-else-if="key=='confirmationNumber'">確認番号</span>
                <span class="text-muted">{{ value }}</span>
              </li>
            </ul>
            <h2>各社のご案内</h2>
            <ul>
              <li v-for="(link, index) in links" :key="index">
                <a :href="link.href" target="_blank">{{ link.text }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div v-else-if="check_mode">
          <div class="box02 mb-32">
            <h2 class="box02__title">
              <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
              MFJ確認中
            </h2>
            <p>
              現在MFJ事務局にて申請を承りました。<br />
              申請より３営業日程度お待ち下さい
            </p>
          </div>
        </div>
        <div v-if="license_mode">
          <div class="box02 mb-32">
            <h2 class="box02__title">
              <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
              入力・選択してください。
            </h2>
            <p>
              {{ message }}
            </p>
          </div>

          <div
            class="mb-5 d-flex justify-content-center align-items-center flex-column"
          >
            <div class="page__inputGroupCol">
              <p
                class="page__inputGroupInner d-flex flex-column flex-md-row align-items-start align-items-md-center gap-2 gap-md-3"
              >
                <label class="label m-2 mb-md-0">生年月日</label>
                <span class="page__inputGroupInput flex-grow-1">
                  <div
                    class="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-2"
                  >
                    <div
                      class="d-flex align-items-center gap-2 w-100 w-md-auto"
                    >
                      <bs-select
                        :options="birth_date_year_list"
                        :nullOption="false"
                        v-model="data_obj.birth_date_year"
                        class="flex-grow-1"
                      ></bs-select>
                      <label class="label mb-0">年</label>
                    </div>
                    <div
                      class="d-flex align-items-center gap-2 w-100 w-md-auto"
                    >
                      <bs-select
                        :options="birth_date_month_list"
                        :nullOption="false"
                        v-model="data_obj.birth_date_month"
                        class="flex-grow-1 flex-md-grow-0"
                      ></bs-select>
                      <label class="label mb-0">月</label>
                    </div>
                    <div
                      class="d-flex align-items-center gap-2 w-100 w-md-auto"
                    >
                      <bs-select
                        :options="
                          birth_date_day_list(
                            data_obj.birth_date_year,
                            data_obj.birth_date_month
                          )
                        "
                        :nullOption="false"
                        v-model="data_obj.birth_date_day"
                        class="flex-grow-1 flex-md-grow-0"
                      ></bs-select>
                      <label class="label mb-0">日</label>
                    </div>
                  </div>
                </span>
              </p>
            </div>

            <!-- 非表示 -->
            <div class="page__inputGroupCol d-none">
              <p
                class="page__inputGroupInner d-flex flex-column flex-md-row align-items-start align-items-md-center gap-2 gap-md-3"
              >
                <span class="page__inputLabel m-2 mb-md-0">運転免許証</span>
                <span class="flex-grow-1">
                  <span class="radio">
                    <input
                      class="radio__input"
                      type="radio"
                      id="license_yes"
                      name="driver_license"
                      v-model="data_obj.driver_license"
                      value="yes"
                    />
                    <label class="radio__label" for="license_yes"
                      >あり（原付以上）</label
                    >
                  </span>
                  <span class="radio">
                    <input
                      class="radio__input"
                      type="radio"
                      id="license_no"
                      name="driver_license"
                      v-model="data_obj.driver_license"
                      value="no"
                    />
                    <label class="radio__label" for="license_no">なし</label>
                  </span>
                </span>
              </p>
            </div>
          </div>
          <p class="text-center mt-32 d-flex justify-content-center">
            <button class="button04 next-button" @click="chk_birth_date()">
              <span class="button__text">
                <span class="d-none d-md-inline"
                  >生年月日を入力してライセンスを表示する</span
                >
                <span class="d-inline d-md-none next-button-text"
                  >次へ進む</span
                >
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="mx-2"
                />
              </span>
            </button>
          </p>
        </div>

        <div v-if="license_sel_mode && check_age === true">
          <div class="box02 mb-32">
            <h2 class="box02__title">
              <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
              競技ライセンスの選択
            </h2>
            <p>
              {{ message }}
            </p>
          </div>
          <div class="container">
            <!-- 年度選択 -->
            <div class="container m-0" v-if="license_sel_year_frm_disp">
              <h3 class="title02 page__h2">年度選択</h3>
              <div class="row pb-2 mb-2">
                <div
                  class="col-12 col-md-4"
                  v-for="year in race_sports_year_list"
                  :key="year"
                >
                  <div class="d-grid">
                    <button
                      type="button"
                      :class="get_license_sel_year_class(year.key)"
                      @click="attach_license_sel_year(year.key)"
                    >
                      {{ year.label }}年度
                    </button>
                    <div class="bs_fs_9 text-center" v-if="true">
                      <span v-if="year.key== 0 && parseInt(now_year) >= parseInt(year.label)">手続完了日翌日</span>
                      <span v-else>{{ year.label }}年4月1日</span>
                      〜{{ (parseInt(year.label) + 1) }}年3月31日
                    </div>
                    <div class="bs_fs_9 text-center" v-if="get_base_env=='development'">
                      <span>競技年齢:</span>
                      {{ get_sports_age(year.key) }}歳
                    </div>
                    <div class="bs_fs_9 text-center" v-if="get_base_env=='development'">
                      <span>保険開始時年齢:</span>
                      {{ get_school_age(year.key) }}歳
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 col-md-4"
                  v-if="race_sports_year_list.length >= 2"
                >
                  <div class="d-grid">
                    <button
                      type="button"
                      :class="get_license_sel_package_class"
                      @click="attach_license_sel_package()"
                    >
                      <span>パッケージ</span>
                    </button>
                    <div class="bs_fs_9 text-center" v-if="true">
                      <span v-for="year in race_sports_year_list" :key="year" class="me-2">
                        {{ year.label }}年度 </span
                      >
                    </div>
                    <div class="bs_fs_9 text-center" v-if="get_base_env=='development'">
                      <span>競技年齢:</span>
                      <span v-if="get_sports_age(0)==get_sports_age(1)">
                        {{ get_sports_age(1) }}歳
                      </span>
                      <span v-else-if="get_sports_age(0)!=get_sports_age(1)">
                        {{get_sports_age(0)}}歳-{{ get_sports_age(1) }}歳
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pb-2 mb-4" v-if="false">
                <div class="col-12 col-md-6 offset-md-3" v-if="next_btn_year">
                  <button
                    type="button"
                    class="button04 w-100 next-button"
                    @click="next_license_sel_year()"
                  >
                    <span class="button__text next-button-text">次へ</span>
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      class="ms-2"
                    />
                  </button>
                </div>
              </div>
            </div>
            <!-- {{ article_variant_filter_lc_and_year_and_acate(1) }} -->
            <!-- ライセンス選択 -->
            <div class="mb-40" v-if="license_sel_category_frm_disp(1)">
              <div class="row pb-2 mb-2">
                <div class="col-8 col-md-10">
                  <h3 class="title02 page__h2">競技ライセンス</h3>
                </div>
              </div>
              <!-- <div class="row pb-2 mb-2" v-if="chk_license_category[1]"> -->
              <div class="row pb-2 mb-2" v-if="disp_key_btn_category_1">
                <div
                  class="col-12 col-md-6 mb-2"
                  v-for="tmp in article_variant_filter_lc_and_year_and_lcate(1)"
                  :key="tmp"
                >
                  <div class="d-grid">
                    <button
                      type="button"
                      :class="
                        get_class_variant_license(
                          tmp.license_id,
                          tmp.license_event_cd,
                          tmp.license_category
                        )
                      "
                      @click="sel_license(tmp.license_id, 1)"
                      :disabled="
                        chk_license_sel_variant(
                          tmp.license_id,
                          tmp.license_event_cd,
                          tmp.license_category
                        ) == 10
                          ? 'disabled'
                          : disabled_license_sel_category(1)
                      "
                    >
                      <span class="d-block bs_fs_7"
                        >{{ tmp.license_label }}
                      </span>
                      <!-- 料金/packによって違うよ -->
                      <!-- (({{ chk_license_sel_variant(tmp.license_id, tmp.license_event_cd) }}))
                      {{ tmp.price_list }}
                      {{ tmp.package_variant_ids }}
                      {{ tmp.package_special_cd }} -->
                      <span class="d-block" v-if="selected_package_flg==0"
                        >{{ number_format(tmp.default_price) }}円</span
                      >
                      <div class="row" v-else-if="selected_package_flg==1 && typeof get_selected_variants(tmp.license_id) === 'object'">
                        <div class="col" v-for="selval in get_selected_variants(tmp.license_id)" :key="selval">
                          <span>({{ get_year_label(selval.sports_year_add)}}) {{number_format(get_variant(selval.variant_id, selval.sports_year_add)?.default_price)}} 円</span>
                        </div>
                      </div>
                      <div class="row" v-else-if="selected_package_flg==1">
                        <div class="col" v-for="(variant_id, y_k) in tmp.package_variant_ids" :key="variant_id">
                          <span>({{ get_year_label(y_k)}}) {{number_format(get_variant(variant_id, y_k)?.default_price)}} 円</span>
                        </div>
                      </div>
                      <!-- <div class="row" v-else-if="selected_package_flg==1">
                        <div class="col" v-for="(price, y_k) in tmp.price_list" :key="price">
                          <span>({{ get_year_label(y_k) }}){{ number_format(price) }}円</span>
                        </div>
                      </div> -->
                    </button>
                  </div>
                </div>
                <div class="col-12 mb-2" v-if="article_variant_filter_lc_and_year_and_lcate(1)?.length > 0">
                  <div class="d-grid">
                    <!-- <button
                      type="button"
                      :class="get_class_license_sel_category(0, 1)"
                      @click="attach_license_sel_category_wrap(0, 1)"
                    > -->
                    <button
                      type="button"
                      :class="get_class_nonsel_license_category(1)"
                      @click="attach_nonsel_license_category(1)"
                    >
                      <span>申請しない</span>
                    </button>
                  </div>
                </div>
                <div class="box box--center mb-24" v-else-if="article_variant_filter_lc_and_year_and_lcate(1)?.length <= 0">
                  <p>選択できる競技ライセンスがありません</p>
                </div>
              </div>
              <div class="text-center mt-32" v-if="false">
                <div
                  class="col-12 col-md-6 offset-md-3"
                  v-if="
                    go_btn_category_disp(1) && chk_license_sel_category(1, 1)
                  "
                >
                  <button
                    type="button"
                    class="button04 next-button w-100"
                    @click="go_license_category(2)"
                    :disabled="next_btn_category_disabled"
                  >
                    <span
                      class="button__text next-button-text d-flex align-items-center justify-content-center"
                    >
                      <span>ピットクルーの選択へ進む</span>
                      <font-awesome-icon
                        :icon="['fa', 'chevron-down']"
                        class="ms-2"
                      />
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <!-- ピットクルーライセンス -->
              <!-- {{disp_key_btn_category_2_2024}} -->
            <div class="mb-40" v-if="license_sel_category_frm_disp(2) || (chk_sports_license && !disp_key_btn_category_2_2024) ">
              <div class="row pb-2 mb-2">
                <div class="col-8 col-md-10">
                  <h3 class="title02 page__h2">ピットクルーライセンス</h3>
                </div>
              </div>
              <div class="box box--center mb-24" v-if="chk_sports_license && chk_sub_license(31) && !disp_key_btn_category_2_2024">
                <p>ピットクルーライセンスは競技ライセンスに含まれます</p>
              </div>
              <div class="row pb-2 mb-4" v-else-if="disp_key_btn_category_2">
                <div
                  class="col-12 col-md-6 mb-2"
                  v-for="tmp in article_variant_filter_lc_and_year_and_lcate(2)"
                  :key="tmp"
                >
                  <div class="d-grid">
                    <button
                      type="button"
                      :class="
                        get_class_variant_license(
                          tmp.license_id,
                          false,
                          tmp.license_category
                        )
                      "
                      @click="sel_license(tmp.license_id, 2)"
                      :disabled="
                        chk_license_sel_variant(
                          tmp.license_id,
                          tmp.license_event_cd,
                          tmp.license_category
                        ) == 10
                          ? 'disabled'
                          : disabled_license_sel_category(2)
                      "
                    >
                      <span class="d-block bs_fs_7">{{
                        tmp.license_label
                      }}</span>
                      <!-- <span class="d-block"
                        >{{ number_format(tmp.default_price) }}円</span
                      > -->
                      <span class="d-block" v-if="selected_package_flg==0"
                        >{{ number_format(tmp.default_price) }}円</span
                      >
                      <div class="row" v-else-if="selected_package_flg==1 && typeof get_selected_variants(tmp.license_id) === 'object'">
                        <div class="col" v-for="selval in get_selected_variants(tmp.license_id)" :key="selval">
                          <span>({{ get_year_label(selval.sports_year_add)}}) {{number_format(get_variant(selval.variant_id, selval.sports_year_add)?.default_price)}} 円</span>
                        </div>
                      </div>
                      <div class="row" v-else-if="selected_package_flg==1">
                        <div class="col" v-for="(variant_id, y_k) in tmp.package_variant_ids" :key="variant_id">
                          <span>({{ get_year_label(y_k)}}) {{number_format(get_variant(variant_id, y_k)?.default_price)}} 円</span>
                        </div>
                      </div>
                      <!-- <div class="row" v-else-if="selected_package_flg==1">
                        <div class="col" v-for="(price, y_k) in tmp.price_list" :key="price">
                          <span>({{ get_year_label(y_k) }}){{ number_format(price) }}円</span>
                        </div>
                      </div> -->
                    </button>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <div class="d-grid">
                    <button
                      type="button"
                      :class="get_class_nonsel_license_category(2)"
                      @click="attach_nonsel_license_category(2)"
                    >
                      <span>申請しない</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- 競技役員ライセンス -->
            <div class="mb-40" v-if="license_sel_category_frm_disp(4)">
              <div class="row pb-2 mb-2">
                <div class="col-8 col-md-10">
                  <h3 class="title02 page__h2">競技役員ライセンス</h3>
                </div>
              </div>
              <div class="row pb-2 mb-4" v-if="disp_key_btn_category_4">
                <div
                  class="col-12 col-md-6 mb-2"
                  v-for="tmp in article_variant_filter_lc_and_year_and_lcate(4)"
                  :key="tmp"
                >
                  <div class="d-grid">
                    <button
                      type="button"
                      :class="
                        get_class_variant_license(
                          tmp.license_id,
                          false,
                          tmp.license_category
                        )
                      "
                      @click="sel_license(tmp.license_id, 4)"
                      :disabled="
                        chk_license_sel_variant(
                          tmp.license_id,
                          tmp.license_event_cd,
                          tmp.license_category
                        ) == 10
                          ? 'disabled'
                          : disabled_license_sel_category(4)
                      "
                    >
                      <span class="d-block bs_fs_7">{{
                        tmp.license_label
                      }}</span>
                      <span class="d-block" v-if="selected_package_flg==0"
                        >{{ number_format(tmp.default_price) }}円</span
                      >
                      <div class="row" v-else-if="selected_package_flg==1 && typeof get_selected_variants(tmp.license_id) === 'object'">
                        <div class="col" v-for="selval in get_selected_variants(tmp.license_id)" :key="selval">
                          <span>({{ get_year_label(selval.sports_year_add)}}) {{number_format(get_variant(selval.variant_id, selval.sports_year_add)?.default_price)}} 円</span>
                        </div>
                      </div>
                      <div class="row" v-else-if="selected_package_flg==1">
                        <div class="col" v-for="(variant_id, y_k) in tmp.package_variant_ids" :key="variant_id">
                          <span>({{ get_year_label(y_k)}}) {{number_format(get_variant(variant_id, y_k)?.default_price)}} 円</span>
                        </div>
                      </div>
                      <!-- <div class="row" v-else-if="selected_package_flg==1">
                        <div class="col" v-for="(price, y_k) in tmp.price_list" :key="price">
                          <span>({{ get_year_label(y_k) }}){{ number_format(price) }}円</span>
                        </div>
                      </div> -->
                    </button>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <div class="d-grid">
                    <button
                      type="button"
                      :class="get_class_nonsel_license_category(4)"
                      @click="attach_nonsel_license_category(4)"
                    >
                      <span>申請しない</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- 講師ライセンス -->
            <div class="mb-40" v-if="license_sel_category_frm_disp(5)">
              <div class="row pb-2 mb-2">
                <div class="col-8 col-md-10">
                  <h3 class="title02 page__h2">講師ライセンス</h3>
                </div>
              </div>
              <div class="row pb-2 mb-4" v-if="disp_key_btn_category_5">
                <div
                  class="col-12 col-md-6 mb-2"
                  v-for="tmp in article_variant_filter_lc_and_year_and_lcate(5)"
                  :key="tmp"
                >
                  <div class="d-grid">
                    <button
                      type="button"
                      :class="
                        get_class_variant_license(
                          tmp.license_id,
                          false,
                          tmp.license_category
                        )
                      "
                      @click="sel_license(tmp.license_id, 5)"
                      :disabled="
                        chk_license_sel_variant(
                          tmp.license_id,
                          tmp.license_event_cd,
                          tmp.license_category
                        ) == 10
                          ? 'disabled'
                          : disabled_license_sel_category(5)
                      "
                    >
                      <span class="d-block bs_fs_7">{{
                        tmp.license_label
                      }}</span>
                      <!-- <span class="d-block"
                        >{{ number_format(tmp.default_price) }}円</span
                      > -->
                      <span class="d-block" v-if="selected_package_flg==0"
                        >{{ number_format(tmp.default_price) }}円</span
                      >
                      <div class="row" v-else-if="selected_package_flg==1 && typeof get_selected_variants(tmp.license_id) === 'object'">
                        <div class="col" v-for="selval in get_selected_variants(tmp.license_id)" :key="selval">
                          <span>({{ get_year_label(selval.sports_year_add)}}) {{number_format(get_variant(selval.variant_id, selval.sports_year_add)?.default_price)}} 円</span>
                        </div>
                      </div>
                      <div class="row" v-else-if="selected_package_flg==1">
                        <div class="col" v-for="(variant_id, y_k) in tmp.package_variant_ids" :key="variant_id">
                          <span>({{ get_year_label(y_k)}}) {{number_format(get_variant(variant_id, y_k)?.default_price)}} 円</span>
                        </div>
                      </div>
                      <!-- <div class="row" v-else-if="selected_package_flg==1">
                        <div class="col" v-for="(price, y_k) in tmp.price_list" :key="price">
                          <span>({{ get_year_label(y_k) }}){{ number_format(price) }}円</span>
                        </div>
                      </div> -->
                    </button>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <div class="d-grid">
                    <button
                      type="button"
                      :class="get_class_nonsel_license_category(5)"
                      @click="attach_nonsel_license_category(5)"
                    >
                      <span>申請しない</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- エンジョイライセンス -->
            <div class="mb-40" v-if="license_sel_category_frm_disp(3)">
              <div class="row pb-2 mb-2">
                <div class="col-8 col-md-10">
                  <h3 class="title02 page__h2">エンジョイライセンス</h3>
                </div>
              </div>
              <div class="box box--center mb-24" v-if="chk_sports_license">
                <p>
                  競技ライセンスを選択されている場合、エンジョイライセンスは選択できません。
                </p>
              </div>
              <div class="row pb-2 mb-4" v-else-if="disp_key_btn_category_2">
                <div
                  class="col-12 col-md-6 mb-2"
                  v-for="tmp in article_variant_filter_lc_and_year_and_lcate(3)"
                  :key="tmp"
                >
                  <div class="d-grid">
                    <button
                      type="button"
                      :class="
                        get_class_variant_license(
                          tmp.license_id,
                          false,
                          tmp.license_category
                        )
                      "
                      @click="sel_license(tmp.license_id, 3)"
                      :disabled="
                        chk_license_sel_variant(
                          tmp.license_id,
                          tmp.license_event_cd,
                          tmp.license_category
                        ) == 10
                          ? 'disabled'
                          : disabled_license_sel_category(3)
                      "
                    >
                      <span class="d-block bs_fs_7">{{
                        tmp.license_label
                      }}</span>
                      <!-- <span class="d-block"
                        >{{ number_format(tmp.default_price) }}円</span
                      > -->
                      <span class="d-block" v-if="selected_package_flg==0"
                        >{{ number_format(tmp.default_price) }}円</span
                      >
                      <div class="row" v-else-if="selected_package_flg==1 && typeof get_selected_variants(tmp.license_id) === 'object'">
                        <div class="col" v-for="selval in get_selected_variants(tmp.license_id)" :key="selval">
                          <span>({{ get_year_label(selval.sports_year_add)}}) {{number_format(get_variant(selval.variant_id, selval.sports_year_add)?.default_price)}} 円</span>
                        </div>
                      </div>
                      <div class="row" v-else-if="selected_package_flg==1">
                        <div class="col" v-for="(variant_id, y_k) in tmp.package_variant_ids" :key="variant_id">
                          <span>({{ get_year_label(y_k)}}) {{number_format(get_variant(variant_id, y_k)?.default_price)}} 円</span>
                        </div>
                      </div>
                      <!-- <div class="row" v-else-if="selected_package_flg==1">
                        <div class="col" v-for="(price, y_k) in tmp.price_list" :key="price">
                          <span>({{ get_year_label(y_k) }}){{ number_format(price) }}円</span>
                        </div>
                      </div> -->
                    </button>
                  </div>
                </div>
                <div class="col-12 mb-2" v-if="false">
                  <div class="d-grid">
                    <button
                      type="button"
                      :class="get_class_nonsel_license_category(3)"
                      @click="attach_nonsel_license_category(3)"
                    >
                      <span>申請しない</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="container mb-3" v-if="license_sel_insurance_frm_disp">
              <h3 class="title02 page__h2">保険加入状況</h3>
              <p>
                本申請により加入もしくは付帯される保障制度は以下の通りです。
              </p>
              <div class="row pb-2 mb-4 border-bottom border-secondary">
                <div class="col-12 col-md-6">
                  <div>スポーツ安全保険</div>
                  <div>
                    <span class="badge bg-success" v-if="chk_insurance_sports"
                      >加入</span
                    >
                    <span class="badge bg-danger" v-else>未加入</span>
                  </div>
                  <div
                    v-for="variant in selected_variants.filter(
                      (variant) => variant.category == 1
                    )"
                    :key="variant"
                  >
                    <span class="" v-if="chk_insurance_sports">
                      {{ number_format(variant.price) }}円
                    </span>
                    <span class="" v-else>--</span>
                  </div>
                  <div>競技・エンジョイ選択時加入必須</div>
                </div>
                <div class="col-12 col-md-6">
                  <div>ご災害補償見舞金</div>
                  <div>
                    <span class="badge bg-success" v-if="chk_mimai_sports"
                      >加入</span
                    >
                    <span class="badge bg-danger" v-else>未加入</span>
                  </div>
                  <div>
                    <span class="" v-if="chk_mimai_sports">
                      競技役員・講師選択時付帯
                    </span>
                    <span class="" v-else>--</span>
                  </div>
                </div>
              </div>
              <div>
                <!-- {{ chk_article_variant_filter_lc_and_year_and_acate(1) }} -->
                スポーツ安全保険(詳しくはこちら) <br />
                高校生以上(中学卒業以上)で65歳未満の方(C区分) <br />
                保険開始日4月1日時点で65歳以上の方(B区分) <br />
                保険満了日の3月31日時点で中学生以下の方(A1区分)<br />
                <br />
                MFJ災害保障見舞金(詳しくはこちら)
              </div>
            </div>
            <div class="mt-40 mb-40" v-if="license_sel_next_btn_disp">
              <div class="row justify-content-center">
                <div class="col-12 col-md-4 mb-3 mb-md-0 text-center">
                  <button class="button03 w-100" @click="back('license')">
                    <font-awesome-icon
                      :icon="['fas', 'chevron-left']"
                      class="mx-2"
                    />
                    <span class="button03__text">戻る</span>
                  </button>
                </div>
                <div class="col-12 col-md-4 text-center">
                  <button class="button04 w-100" @click="proceed_birthdate_check('profile')">
                    <span class="button04__text">進む</span>
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      class="mx-2"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div class="mt-40 mb-40" v-else>
              <div class="row">
                <div class="col-12 col-md-4 mb-3 mb-md-0 text-center">
                  <button class="button03 w-100" @click="back('license')">
                    <font-awesome-icon
                      :icon="['fas', 'chevron-left']"
                      class="mx-2"
                    />
                    <span class="button03__text">戻る</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="container px-0">
            <!-- {{ selected_variants }} -->
            <cart-view
              :selected_variants="selected_variants"
              :race_sports_year="race_sports_year_obj"
              :draft_orders="draft_order_obj"
            ></cart-view>
          </div>
        </div>
        <div v-else-if="license_sel_mode">
          <div class="box02 mb-32">
            <h2 class="box02__title">
              <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
              競技ライセンスの選択
            </h2>
            <p>
              生年月日データが不正です。生年月日入力に戻って下さい。
            </p>
          </div>
          <div class="container">
            <div class="mt-40 mb-40">
              <div class="row justify-content-center">
                <div class="col-12 col-md-4 mb-3 mb-md-0 text-center">
                  <button class="button03 w-100" @click="back('license')">
                    <font-awesome-icon
                      :icon="['fas', 'chevron-left']"
                      class="mx-2"
                    />
                    <span class="button03__text">生年月日入力に戻る</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 個人情報の入力 -->
        <div v-else-if="profile_mode || confirm_mode">
          <div class="box02 mb-32" v-if="profile_mode">
            <h2 class="box02__title">
              <font-awesome-icon
                :icon="['fas', 'circle-exclamation']"
                class="me-2"
              />
              個人情報の入力
            </h2>
            <div class="d-flex flex-column gap-2">
              <p>
                以下の項目のうち、<span class="required-label">*</span
                >マークの付いた項目は必須入力項目です。
              </p>
              <p>
                必須項目をすべて入力しないと、申請を完了することができません。
              </p>
            </div>
          </div>
          <div class="box02 mb-32" v-else-if="confirm_mode">
            <h2 class="box02__title">
              <font-awesome-icon
                :icon="['fas', 'circle-exclamation']"
                class="me-2"
              />
              個人情報の確認
            </h2>
            <div class="d-flex flex-column gap-2">
              <p v-if="(this.application_obj || {}).application_check_type == 600">
                下記内容で再申請を行います。「再申請する」ボタンを押して申請を行って下さい。
              </p>
              <p v-else>
                下記内容で申請を行います。下の「新規申込を申請する」ボタンを押して申請を行って下さい。
              </p>
            </div>
          </div>
          <!-- {{ communication_obj }} -->
          <div class="mb-5" v-if="typeof communication_obj === 'object' && Object.keys(communication_obj).length > 0">
            <div class="alert alert-danger wysiwyg">
              <h3>申請内容について</h3>
              <p>
                申請内容に不備がありました。
              </p>
              <p>
                {{ communication_obj?.comment }}
              </p>
              <div class="" v-if="is_application_comment_item">
                <p>
                  以下の項目の申請内容をご確認下さい。
                </p>
                <template v-for="item in communication_obj.items" :key="item">
                  <span class="me-2" v-if="item.item_comment">
                    <font-awesome-icon
                        :icon="['fas', 'circle-check']"
                        class="me-1"
                      /> <span class="fw-bold">{{ get_app_list_label(item.application_check_cd) }}</span>
                  </span>
                </template>
              </div>
              <div class="row d-none">
                <template v-for="item in communication_obj.items" :key="item">
                  <div class="col-6" v-if="item.item_comment">
                    <div class="border-bottom border-danger mb-2 pb-2">
                      <font-awesome-icon
                        :icon="['fas', 'circle-check']"
                        class="me-2"
                      />
                      <span class="fw-bold">{{ get_app_list_label(item.application_check_cd) }}</span>
                    </div>
                    <div>
                      <font-awesome-icon
                        :icon="['fas', 'comment']"
                        class="me-2"
                      />
                      {{ item.item_comment }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="mb-5">
            <div class="" style="position: relative; z-index: 1">
              <div class="container mb-3">
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">年度</h4>
                  <div class="col-12 col-md-8">
                    <span
                      v-for="year in selected_year_label"
                      :key="year"
                      style="margin-right: 10px"
                    >
                      {{ year.label }}
                    </span>
                    <!-- <input
                      type="text"
                      class="form-control-plaintext"
                      value="a2024b"
                      readonly="true"
                    /> -->
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    ログインID <span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <input
                      type="text"
                      :class="input_class('')"
                      v-model="data_obj.login_id"
                      :readonly="input_readonly('')"
                    />
                  </div>
                </div>
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    ログインパスワード <span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <div class="input-group">
                      <input
                        :type="isPasswordVisible ? 'text' : 'password'"
                        :class="input_class('')"
                        v-model="data_obj.login_pass"
                        :readonly="input_readonly('')"
                      />
                      <span
                        v-if="profile_mode"
                        class="input-group-addon"
                        @click="togglePasswordVisibility"
                      >
                        <font-awesome-icon
                          :icon="
                            isPasswordVisible
                              ? ['fas', 'eye']
                              : ['fas', 'eye-slash']
                          "
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    姓 <span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <input
                      type="text"
                      :class="input_class('m_name')"
                      v-model="data_obj.name_1"
                      :readonly="input_readonly('m_name')"
                    />
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    名 <span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <input
                      type="text"
                      :class="input_class('m_name')"
                      v-model="data_obj.name_2"
                      :readonly="input_readonly('m_name')"
                    />
                  </div>
                </div>

                <div v-if="get_target_com_msg('m_name')" class="py-2">
                  <p class="alert alert-danger">
                    <font-awesome-icon
                      :icon="['fa', 'exclamation-triangle']"
                      class="me-2"
                    />{{
                      get_target_com_msg('m_name')
                    }}
                  </p>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    セイ<span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <input
                      type="text"
                      :class="input_class('m_kana')"
                      v-model="data_obj.yomi_1"
                      :readonly="input_readonly('m_kana')"
                    />
                  </div>
                </div>
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    メイ<span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <input
                      type="text"
                      :class="input_class('m_kana')"
                      v-model="data_obj.yomi_2"
                      :readonly="input_readonly('m_kana')"
                    />
                  </div>
                </div>

                <div v-if="get_target_com_msg('m_kana')" class="py-2">
                  <p class="alert alert-danger">
                    <font-awesome-icon
                      :icon="['fa', 'exclamation-triangle']"
                      class="me-2"
                    />{{
                      get_target_com_msg('m_kana')
                    }}
                  </p>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    生年月日 西暦<span class="required-label">*</span>
                  </h4>
                  <div v-if="birth_date_validate">
                    <span class="page__inputGroupInput flex-grow-1">
                      <div
                        class="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-2"
                        >
                        <div
                          class="d-flex align-items-center gap-2 w-100 w-md-auto"
                        >
                          <bs-select
                            :options="birth_date_year_list"
                            :nullOption="false"
                            v-model="data_obj.birth_date_year"
                            class="flex-grow-1"
                          ></bs-select>
                          <label class="label mb-0">年</label>
                        </div>
                        <div
                          class="d-flex align-items-center gap-2 w-100 w-md-auto"
                        >
                          <bs-select
                            :options="birth_date_month_list"
                            :nullOption="false"
                            v-model="data_obj.birth_date_month"
                            class="flex-grow-1 flex-md-grow-0"
                          ></bs-select>
                          <label class="label mb-0">月</label>
                        </div>
                        <div
                          class="d-flex align-items-center gap-2 w-100 w-md-auto"
                        >
                          <bs-select
                            :options="
                              birth_date_day_list(
                                data_obj.birth_date_year,
                                data_obj.birth_date_month
                              )
                            "
                            :nullOption="false"
                            v-model="data_obj.birth_date_day"
                            class="flex-grow-1 flex-md-grow-0"
                          ></bs-select>
                          <label class="label mb-0">日</label>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div class="col-12 col-md-8" v-else>
                    <p class="form-control-plaintext">
                      {{ data_obj.birth_date_year }}-{{
                        data_obj.birth_date_month
                      }}-{{ data_obj.birth_date_day }}
                    </p>
                  </div>
                </div>
                <div class="row pb-2 mb-2 d-none">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    生年月日 和暦
                  </h4>
                  <div class="col-12 col-md-8">
                    <input
                      type="text"
                      class="form-control-plaintext"
                      :value="
                        get_wareki(
                          data_obj.birth_date_year,
                          data_obj.birth_date_month,
                          data_obj.birth_date_day
                        )
                      "
                      readonly="true"
                    />
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    性別<span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <p
                      class=""
                      v-if="confirm_mode || chk_app_checked('m_sex_type')"
                    >
                      {{ get_list_label(data_obj.sex_type, sex_type_list) }}
                    </p>
                    <bs-select
                      v-else
                      :options="sex_type_list"
                      :nullOption="false"
                      v-model="data_obj.sex_type"
                    ></bs-select>
                    <div v-if="get_target_com_msg('m_kana')" class="py-2">
                      <p class="alert alert-danger">
                        <font-awesome-icon
                          :icon="['fa', 'exclamation-triangle']"
                          class="me-2"
                        />{{
                          get_target_com_msg('m_kana')
                        }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    顔写真<span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-3 mb-2 mb-md-0">
                    <div class="" v-if="(data_obj || {}).path3">
                      <img :src="(data_obj || {}).path3" class="w-100" />
                    </div>
                  </div>
                  <div class="col-12 col-md-5">
                    <div class="d-grid">
                      <button
                        v-if="!confirm_mode && !chk_app_checked('m_photo')"
                        type="button"
                        @click="clickUpFileBtn(100, 3)"
                        class="btn btn-light btn-outline-secondary"
                      >
                        <font-awesome-icon :icon="['fas', 'file-arrow-up']" />
                        &nbsp;ファイルを選択する
                      </button>
                    </div>
                    <input
                      type="file"
                      class="form-control"
                      :id="'file_up_input_100_path3'"
                      @change="selectedFile($event, 100, 3, false)"
                      accept="image/*"
                      style="display: none"
                    />
                    <div v-if="get_target_com_msg('m_photo')" class="py-2">
                      <p class="alert alert-danger">
                        <font-awesome-icon
                          :icon="['fa', 'exclamation-triangle']"
                          class="me-2"
                        />{{
                          get_target_com_msg('m_photo')
                        }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    住所<span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <div class="input-group mb-2">
                      <span class="">郵便番号</span>
                    </div>
                    <div class="input-group mb-2">
                      <input
                        type="text"
                        :class="input_class('m_address')"
                        v-model="data_obj.postal_cd"
                        :readonly="input_readonly('m_address')"
                      />
                      <button
                        type="button"
                        class="btn btn-light btn-outline-secondary"
                        v-if="!confirm_mode && !chk_app_checked('m_address')"
                        @click="get_address_param"
                      >
                        <font-awesome-icon :icon="['fas', 'search']" />
                      </button>
                    </div>

                    <div class="input-group mb-2">
                      <span class="">都道府県</span>
                    </div>
                    <div class="input-group mb-2">
                      <p
                        class=""
                        v-if="confirm_mode || chk_app_checked('m_address')"
                      >
                        {{ get_list_label(data_obj.pref_cd, pref_cd_list) }}
                      </p>
                      <bs-select
                        v-else
                        :options="pref_cd_list"
                        :nullOption="false"
                        v-model="data_obj.pref_cd"
                      ></bs-select>
                    </div>

                    <div class="input-group mb-2">
                      <span class=""
                        >市区町村番地<span class="required-label">*</span></span
                      >
                    </div>
                    <div class="input-group mb-2">
                      <input
                        type="text"
                        :class="input_class('m_address')"
                        v-model="data_obj.address_1"
                        :readonly="input_readonly('m_address')"
                      />
                    </div>
                    <div class="input-group mb-2">
                      <span class="">建物名</span>
                    </div>
                    <div class="input-group">
                      <input
                        type="text"
                        :class="input_class('m_address')"
                        v-model="data_obj.address_2"
                        :readonly="input_readonly('m_address')"
                      />
                    </div>
                    <div v-if="get_target_com_msg('m_address')" class="py-2">
                      <p class="alert alert-danger">
                        <font-awesome-icon
                          :icon="['fa', 'exclamation-triangle']"
                          class="me-2"
                        />{{
                          get_target_com_msg('m_address')
                        }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    連絡先電話番号1<span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <input
                      type="tel"
                      :class="input_class('m_tel_1')"
                      v-model="data_obj.tel_1"
                      :readonly="input_readonly('m_tel_1')"
                      placeholder="例: 03-1234-5678"
                    />
                    <div v-if="get_target_com_msg('m_tel_1')" class="py-2">
                      <p class="alert alert-danger">
                        <font-awesome-icon
                          :icon="['fa', 'exclamation-triangle']"
                          class="me-2"
                        />{{
                          get_target_com_msg('m_tel_1')
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    連絡先電話番号2
                  </h4>
                  <div class="col-12 col-md-8">
                    <input
                      type="tel"
                      :class="input_class('m_tel_2')"
                      v-model="data_obj.tel_2"
                      :readonly="input_readonly('m_tel_2')"
                      placeholder="例: 03-1234-5678"
                    />
                    <div v-if="get_target_com_msg('m_tel_2')" class="py-2">
                      <p class="alert alert-danger">
                        <font-awesome-icon
                          :icon="['fa', 'exclamation-triangle']"
                          class="me-2"
                        />{{
                          get_target_com_msg('m_tel_2')
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    メールアドレス<span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <input
                      type="text"
                      :class="input_class('m_mail_1')"
                      v-model="data_obj.mail_1"
                      :readonly="input_readonly('m_mail_1')"
                    />
                  </div>
                </div>
                <div class="row pb-2 mb-2 d-none">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    メールアドレス２
                  </h4>
                  <div class="col-12 col-md-8">
                    <div class="input-group mb-2">
                      <input
                        type="text"
                        :class="input_class('')"
                        v-model="data_obj.mail_2"
                        :readonly="input_readonly('')"
                      />
                    </div>
                    <div class="input-group">
                      <span class="input-group-text">確認</span>
                      <input
                        type="text"
                        :class="input_class('')"
                        v-model="data_obj.mail_2_chk"
                        :readonly="input_readonly('')"
                      />
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    メールマガジン配信希望<span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-4">
                    <p
                      class=""
                      v-if="confirm_mode || chk_app_checked('m_mail_flg')"
                    ></p>
                    <bs-select
                      v-else
                      :options="request_type_cd_list"
                      :nullOption="false"
                      v-model="data_obj.mail_flg"
                    ></bs-select>
                  </div>
                </div>

                <!-- 本人確認書類 -->
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    本人確認書類種類<span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8 mb-2">
                    <p
                      class=""
                      v-if="confirm_mode || chk_app_checked('m_identify_type')"
                    >
                      {{
                        get_list_label(
                          data_obj.m_path_type,
                          identify_type_cd_list
                        )
                      }}
                    </p>
                    <bs-select
                      v-else
                      :options="identify_type_cd_list"
                      :nullOption="false"
                      v-model="data_obj.m_path_type"
                    ></bs-select>
                    <div
                      v-if="get_target_com_msg('m_identify_type')"
                      class="py-2"
                    >
                      <p class="alert alert-danger">
                        <font-awesome-icon
                          :icon="['fa', 'exclamation-triangle']"
                          class="me-2"
                        />{{
                          get_target_com_msg('m_identity_type')
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <h4 class="title04 page__h4">ファイル</h4>
                  </div>
                  <div class="col-12 col-md-8" v-if="data_obj.m_path_type">
                    <div
                      class="w-100"
                      v-for="k in data_obj.m_path_type == 20 ? [1, 2] : [1]"
                      :key="k"
                    >
                      <div
                        v-if="data_obj.m_path_type == 20"
                        class="input-group mb-2"
                      >
                        <span v-if="k === 1">表</span>
                        <span v-if="k === 2">裏面</span>
                      </div>
                      <div class="row mb-1" v-if="(data_obj || {})['path' + k]">
                        <div
                          class="col-xl-2 col-3"
                          v-if="
                            [0].includes(
                              application_obj.application_check_type
                            ) >= 0 && (data_obj || {})['path' + k]
                          "
                        >
                          <img
                            :src="(data_obj || {})['path' + k]"
                            class="w-100"
                          />
                        </div>
                        <div
                          class="col-12"
                          v-else-if="
                            [100, 120, 200, 600].includes(
                              application_obj.application_check_type
                            ) >= 0 && (data_obj || {})['path' + k]
                          "
                        >
                          送信済
                        </div>
                        <div class="col-12" v-else>-</div>
                        <div
                          class="col-12 col-md-10 mb-1"
                          v-if="!chk_app_checked('m_idcard_photo')"
                        >
                          <div class="d-grid">
                            <button
                              v-if="!confirm_mode"
                              type="button"
                              @click="clickUpFileBtn(400, k)"
                              class="btn btn-light btn-outline-secondary"
                            >
                              <font-awesome-icon
                                :icon="['fas', 'file-arrow-up']"
                              />
                              &nbsp;ファイルを選択する
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-1" v-else>
                        <div
                          class="col-xl-2 col-3"
                          v-if="
                            [0].includes(
                              application_obj.application_check_type
                            ) >= 0 && (data_obj || {})['path' + k]
                          "
                        >
                          <span
                            v-if="k == 1 && data_obj.m_path_type == 20"
                          ></span>
                          <span v-else-if="k == 1">表</span>
                          <span v-else-if="k == 2">表面</span>
                        </div>
                        <div
                          class="col-12"
                          v-else-if="
                            [100, 120, 200, 600].includes(
                              application_obj.application_check_type
                            ) >= 0 && (data_obj || {})['path' + k]
                          "
                        >
                          送信済
                        </div>
                        <div class="col-12" v-else>-</div>
                        <div
                          class="col-12 col-md-10 mb-1"
                          v-if="!chk_app_checked('m_idcard_photo')"
                        >
                          <div class="d-grid mb-1">
                            <button
                              v-if="!confirm_mode"
                              type="button"
                              @click="clickUpFileBtn(400, k)"
                              class="btn btn-light btn-outline-secondary"
                            >
                              <font-awesome-icon
                                :icon="['fas', 'file-arrow-up']"
                              />
                              &nbsp;ファイルを選択する
                            </button>
                          </div>
                        </div>
                      </div>
                      <input
                        type="file"
                        class="form-control"
                        :id="'file_up_input_400_path' + k"
                        @change="selectedFile($event, 400, k, false)"
                        accept="image/*"
                        style="display: none"
                      />
                    </div>
                    <div
                      v-if="get_target_com_msg('m_idcard_photo')"
                      class="py-2"
                    >
                      <p class="alert alert-danger">
                        <font-awesome-icon
                          :icon="['fa', 'exclamation-triangle']"
                          class="me-2"
                        />{{
                          get_target_com_msg('m_idcard_photo')
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-8" v-else>
                    書類の種類を選択してください
                  </div>
                </div>

                <!-- サーキットライセンス(3時間走行証明用) -->
                <div class="row pb-2 mb-2" v-if="rd_3hour_frm_disp">
                  <div class="col-12 mb-2">
                    <h4 class="title04 page__h4">
                      サーキットライセンスについて
                    </h4>
                  </div>
                  <div class="col-12">
                    <div
                      class="row mb-2"
                      v-for="variant in get_selected_variants_filter(
                        {
                          join_3hour: 1,
                        },{},
                        1
                      )"
                      :key="variant"
                    >
                      <h4 class="col-12 col-md-4 title04 page__h4">
                        {{ variant.license_label }}
                      </h4>
                      <div class="col-12 col-md-8">
                        <div class="input-group mb-2">
                          <span class="">公認サーキット</span
                          ><span class="required-label">*</span>
                        </div>
                        <div class="input-group mb-2">
                          <input
                            v-if="confirm_mode"
                            :value="
                              get_list_label(
                                variant.join_3hour_circuit_id,
                                circuit_list
                              )
                            "
                            :class="input_class('m_rd_3hour')"
                            :readonly="input_readonly('m_rd_3hour')"
                          />
                          <bs-select
                            v-else
                            :options="circuit_list"
                            :nullOption="true"
                            v-model="variant.join_3hour_circuit_id"
                          ></bs-select>
                        </div>
                        <div class="input-group mb-2">
                          <span class="">ライセンス番号</span
                          >
                          <span class="required-label" v-if="!chk_3hour_circuit_mfj(variant.join_3hour_circuit_id)">*</span>
                        </div>
                        <div class="input-group mb-2">
                          <input
                            type="text"
                            :class="input_class('m_rd_3hour')"
                            v-model="variant.join_3hour_circuit_license_no"
                            :readonly="input_readonly('m_rd_3hour')"
                          />
                        </div>
                        <div class="input-group mb-2">
                          <span class="">ライセンス証</span
                          >
                          <span class="required-label" v-if="!chk_3hour_circuit_mfj(variant.join_3hour_circuit_id)">*</span>
                        </div>
                        <div class="mb-2">
                          <div class="col-12 col-md-3 mb-2 mb-md-0">
                            <div class="" v-if="(data_obj || {}).path10">
                              <img
                                :src="(data_obj || {}).path10"
                                class="w-100"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-md-5">
                            <div class="d-grid">
                              <button
                                v-if="!confirm_mode"
                                type="button"
                                @click="clickUpFileBtn(100, 10)"
                                class="btn btn-light btn-outline-secondary"
                              >
                                <font-awesome-icon
                                  :icon="['fas', 'file-arrow-up']"
                                />
                                &nbsp;ファイルを選択する
                              </button>
                            </div>
                            <input
                              type="file"
                              class="form-control"
                              :id="'file_up_input_100_path10'"
                              @change="selectedFile($event, 100, 10, false)"
                              accept="image/*"
                              style="display: none"
                            />
                          </div>
                        </div>
                        <div class="input-group mb-2">
                          <span class="">サーキットライセンス講習会専用申請書、3時間走行証明書、競技会成績証明書</span>
                          <span class="required-label" v-if="chk_3hour_circuit_mfj(variant.join_3hour_circuit_id)">*</span>
                        </div>
                        <div class="mb-2">
                          <div class="col-12 col-md-3 mb-2 mb-md-0">
                            <div class="" v-if="(data_obj || {}).path11">
                              <img
                                :src="(data_obj || {}).path11"
                                class="w-100"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-md-5">
                            <div class="d-grid">
                              <button
                                v-if="!confirm_mode"
                                type="button"
                                @click="clickUpFileBtn(100, 11)"
                                class="btn btn-light btn-outline-secondary"
                              >
                                <font-awesome-icon
                                  :icon="['fas', 'file-arrow-up']"
                                />
                                &nbsp;ファイルを選択する
                              </button>
                            </div>
                            <input
                              type="file"
                              class="form-control"
                              :id="'file_up_input_100_path11'"
                              @change="selectedFile($event, 100, 11, false)"
                              accept="image/*"
                              style="display: none"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 講習会の受講について -->
                <div
                  class="row pb-2 mb-2"
                  v-if="
                    join_seminar_web_frm_disp || join_seminar_circuit_frm_disp
                  "
                >
                  <div class="col-12 mb-2">
                    <h4 class="title04 page__h4">講習会の受講について</h4>
                  </div>
                  <div class="col-12 mb-2">
                    <div class="row" v-for="license_id in get_license_selected_variants_filter(
                          {
                            join_seminar_web: 1,
                            join_seminar_circuit: 1,
                          }
                        )"
                        :key="license_id">
                      <div
                        class="row mb-2"
                        v-for="variant in get_selected_variants_filter(
                          {
                            join_seminar_web: 1,
                            join_seminar_circuit: 1,
                          },
                          {
                            license_id: license_id,
                          },
                          1
                        )"
                        :key="variant"
                      >
                      <!-- {{ license_id }}
                      {{ variant }} -->
                        <h4 class="col-12 col-md-4 title04 page__h4">
                          {{ variant.license_label }}
                        </h4>
                        <div class="col-12 col-md-8" v-if="confirm_mode">
                          <div class="input-group mb-2">
                            <input
                              v-if="confirm_mode"
                              :value="
                                (variant.join_seminar_type=='web')? 'Web講習会を受講する': '対面講習会を受講済'
                              "
                              :class="input_class('m_rd_3hour')"
                              :readonly="input_readonly('m_rd_3hour')"
                            />
                          </div>
                          <div
                            class="input-group mb-2"
                            v-if="variant.join_seminar_type == 'circuit'"
                          >
                            <p class="d-block">
                              <span class="">受講した施設名</span><br>
                              {{ variant.join_seminar_place }}
                            </p>
                            <p class="d-block">
                              <span class="">受講日</span><br>
                              {{ variant.join_seminar_date }}
                            </p>
                          </div>
                          <div
                            class="input-group mb-2"
                            v-if="variant.join_seminar_type == 'web'"
                          >
                            <p>
                              Web講習会を受講される場合は、申請承認、お支払いの後、マイページから講習会動画を受講して下さい。
                            </p>
                          </div>
                        </div>
                        <div class="col-12 col-md-8" v-else>
                          <div class="input-group mb-2">
                            <button
                              type="button"
                              :class="
                                variant.join_seminar_type == 'web'
                                  ? 'btn btn-warning'
                                  : 'btn btn-light btn-outline-secondary'
                              "
                              v-if="variant.join_seminar_web == 1"
                              @click="
                                attach_join_seminar_type(
                                  variant.variant_id,
                                  'web'
                                )
                              "
                            >
                              Web講習会を受講する
                            </button>
                            <span
                              class="input-group-text"
                              v-if="
                                variant.join_seminar_web == 1 &&
                                variant.join_seminar_circuit == 1
                              "
                              >or.</span
                            >
                            <button
                              type="button"
                              :class="
                                variant.join_seminar_type == 'circuit'
                                  ? 'btn btn-warning'
                                  : 'btn btn-light btn-outline-secondary'
                              "
                              v-if="variant.join_seminar_circuit == 1"
                              @click="
                                attach_join_seminar_type(
                                  variant.variant_id,
                                  'circuit'
                                )
                              "
                            >
                              対面講習会を受講済
                            </button>
                          </div>
                          <div
                            class="input-group mb-2"
                            v-if="variant.join_seminar_type == 'circuit'"
                          >
                            <span class="">受講した施設名</span>
                          </div>
                          <div
                            class="input-group mb-2"
                            v-if="variant.join_seminar_type == 'circuit'"
                          >
                            <input
                              type="text"
                              v-model="variant.join_seminar_place"
                              class="form-control"
                            />
                          </div>
                          <div
                            class="input-group mb-2"
                            v-if="variant.join_seminar_type == 'circuit'"
                          >
                            <span class="">受講日</span>
                          </div>
                          <div
                            class="input-group mb-2"
                            v-if="variant.join_seminar_type == 'circuit'"
                          >
                            <input
                              type="date"
                              v-model="variant.join_seminar_date"
                              class="form-control"
                            />
                          </div>
                          <div
                            class="input-group mb-2"
                            v-if="variant.join_seminar_type == 'web'"
                          >
                            <p>
                              Web講習会を受講される場合は、申請承認、お支払いの後、マイページから講習会動画を受講して下さい。
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" v-if="false">
                    <div
                      class="row mb-2"
                      v-for="variant in get_selected_variants_filter(
                        {
                          join_seminar_web: 1,
                          join_seminar_circuit: 1,
                        }, {},
                        false
                      )"
                      :key="variant"
                    >
                      <h4 class="col-12 col-md-4 title04 page__h4">
                        {{ variant.license_label }}/{{ variant.license_id }}
                      </h4>
                      <div class="col-12 col-md-8">
                        <div class="input-group mb-2">
                          <button
                            type="button"
                            :class="
                              variant.join_seminar_type == 'web'
                                ? 'btn btn-warning'
                                : 'btn btn-light btn-outline-secondary'
                            "
                            v-if="variant.join_seminar_web == 1"
                            @click="
                              attach_join_seminar_type(
                                variant.variant_id,
                                'web'
                              )
                            "
                          >
                            Web講習会を受講する
                          </button>
                          <span
                            class="input-group-text"
                            v-if="
                              variant.join_seminar_web == 1 &&
                              variant.join_seminar_circuit == 1
                            "
                            >or.</span
                          >
                          <button
                            type="button"
                            :class="
                              variant.join_seminar_type == 'circuit'
                                ? 'btn btn-warning'
                                : 'btn btn-light btn-outline-secondary'
                            "
                            v-if="variant.join_seminar_circuit == 1"
                            @click="
                              attach_join_seminar_type(
                                variant.variant_id,
                                'circuit'
                              )
                            "
                          >
                            対面講習会を受講済
                          </button>
                        </div>
                        <div
                          class="input-group mb-2"
                          v-if="variant.join_seminar_type == 'circuit'"
                        >
                          <span class="">受講した施設名</span>
                        </div>
                        <div
                          class="input-group mb-2"
                          v-if="variant.join_seminar_type == 'circuit'"
                        >
                          <input
                            type="text"
                            v-model="variant.join_seminar_place"
                            class="form-control"
                          />
                        </div>
                        <div
                          class="input-group mb-2"
                          v-if="variant.join_seminar_type == 'circuit'"
                        >
                          <span class="">受講日</span>
                        </div>
                        <div
                          class="input-group mb-2"
                          v-if="variant.join_seminar_type == 'circuit'"
                        >
                          <input
                            type="date"
                            v-model="variant.join_seminar_date"
                            class="form-control"
                          />
                        </div>
                        <div
                          class="input-group mb-2"
                          v-if="variant.join_seminar_type == 'web'"
                        >
                          <p>
                            Web講習会を受講される場合は、申請承認、お支払いの後、マイページから講習会動画を受講して下さい。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 過去のライセンス保有 -->
              <div class="container mb-3">
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    過去のライセンス保有
                  </h4>
                  <div class="col-12 col-md-8">
                    <input
                      v-if="confirm_mode"
                      :value="
                        get_list_label(
                          data_obj.past_license_chk,
                          past_license_chk_list
                        )
                      "
                      :class="input_class('m_rd_3hour')"
                      :readonly="input_readonly('m_rd_3hour')"
                    />
                    <bs-select
                      v-else
                      :options="past_license_chk_list"
                      :nullOption="false"
                      v-model="data_obj.past_license_chk"
                      :readonly="input_readonly('past_license_chk')"
                    ></bs-select>
                  </div>
                </div>
                <div
                  class="row pb-2 mb-2"
                  v-if="data_obj.past_license_chk == 1"
                >
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    過去のライセンスNo
                  </h4>
                  <div class="col-12 col-md-8">
                    <input
                      type="text"
                      :class="input_class('parent_kana')"
                      v-model="data_obj.past_license_no"
                      :readonly="input_readonly('parent_kana')"
                    />
                  </div>
                </div>
              </div>

              <!-- 親権者 -->
              <div class="container mb-3" v-if="parent_frm_disp">
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    親権者:姓 <span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <input
                      type="text"
                      :class="input_class('parent_name')"
                      v-model="data_obj.parent_name_1"
                      :readonly="input_readonly('parent_name')"
                    />
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    親権者:名 <span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <input
                      type="text"
                      :class="input_class('parent_name')"
                      v-model="data_obj.parent_name_2"
                      :readonly="input_readonly('parent_name')"
                    />
                  </div>
                </div>
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    親権者:セイ<span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <input
                      type="text"
                      :class="input_class('parent_kana')"
                      v-model="data_obj.parent_yomi_1"
                      :readonly="input_readonly('parent_kana')"
                    />
                  </div>
                </div>
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    親権者:メイ<span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <input
                      type="text"
                      :class="input_class('parent_kana')"
                      v-model="data_obj.parent_yomi_2"
                      :readonly="input_readonly('parent_kana')"
                    />
                  </div>
                </div>
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    親権者:生年月日<span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <div class="mb-2">
                      <span class="title04 page__h4">西暦</span>
                      <bs-select
                        :options="birth_date_year_list"
                        :nullOption="false"
                        v-model="data_obj.parent_birth_date_year"
                      ></bs-select>
                    </div>
                    <div class="mb-2">
                      <span class="">年</span>
                      <bs-select
                        :options="birth_date_month_list"
                        :nullOption="false"
                        v-model="data_obj.parent_birth_date_month"
                      ></bs-select>
                    </div>
                    <div class="mb-2">
                      <span class="">月</span>
                      <bs-select
                        :options="
                          birth_date_day_list(
                            data_obj.parent_birth_date_year,
                            data_obj.parent_birth_date_month
                          )
                        "
                        :nullOption="false"
                        v-model="data_obj.parent_birth_date_day"
                      ></bs-select>
                    </div>
                    <div class="mb-2">
                      <span class="">日</span>
                    </div>
                    <div class="mb-2 d-none">
                      <span class="title04 page__h4">和暦</span>
                    </div>
                    <div class="mb-2 d-none">
                      <input
                        type="text"
                        class="form-control-plaintext"
                        :value="
                          get_wareki(
                            data_obj.parent_birth_date_year,
                            data_obj.parent_birth_date_month,
                            data_obj.parent_birth_date_day
                          )
                        "
                        readonly="true"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="get_target_com_msg('parent_birth_date')"
                  class="py-2"
                >
                  <p class="alert alert-danger">
                    <font-awesome-icon
                      :icon="['fa', 'exclamation-triangle']"
                      class="me-2"
                    />{{
                      get_target_com_msg('parent_birth_date')
                    }}
                  </p>
                </div>
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">性別</h4>
                  <div class="col-12 col-md-8">
                    <p
                      class=""
                      v-if="confirm_mode || chk_app_checked('parent_sex_type')"
                    >
                      {{
                        get_list_label(data_obj.parent_sex_type, sex_type_list)
                      }}
                    </p>
                    <bs-select
                      v-else
                      :options="sex_type_list"
                      :nullOption="false"
                      v-model="data_obj.parent_sex_type"
                    ></bs-select>
                  </div>
                </div>

                <!-- 親権者確認書類 -->
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    親権者確認書類種類<span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8 mb-2">
                    <p
                      class=""
                      v-if="
                        confirm_mode || chk_app_checked('parent_identify_type')
                      "
                    >
                      {{
                        get_list_label(
                          data_obj.parent_path_type,
                          identify_type_cd_list
                        )
                      }}
                    </p>
                    <bs-select
                      v-else
                      :options="identify_type_cd_list"
                      :nullOption="false"
                      v-model="data_obj.parent_path_type"
                    ></bs-select>
                    <div
                      v-if="get_target_com_msg('parent_identify_type')"
                      class="py-2"
                    >
                      <p class="alert alert-danger">
                        <font-awesome-icon
                          :icon="['fa', 'exclamation-triangle']"
                          class="me-2"
                        />{{
                          get_target_com_msg('parent_identify_type')
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <h4 class="title04 page__h4">ファイル</h4>
                  </div>
                  <div class="col-12 col-md-8" v-if="data_obj.parent_path_type">
                    <div
                      class="w-100"
                      v-for="k in data_obj.parent_path_type == 20
                        ? [4, 5]
                        : [4]"
                      :key="k"
                    >
                      <div class="row mb-1" v-if="(data_obj || {})['path' + k]">
                        <div
                          class="col-xl-2 col-3"
                          v-if="
                            [0].includes(
                              application_obj.application_check_type
                            ) >= 0 && (data_obj || {})['path' + k]
                          "
                        >
                          <img
                            :src="(data_obj || {})['path' + k]"
                            class="w-100"
                          />
                        </div>
                        <div
                          class="col-12"
                          v-else-if="
                            [100, 120, 200, 600].includes(
                              application_obj.application_check_type
                            ) >= 0 && (data_obj || {})['path' + k]
                          "
                        >
                          送信済
                        </div>
                        <div class="col-12" v-else>-</div>
                        <div
                          class="col-12 col-md-10 mb-1"
                          v-if="!chk_app_checked('parent_idcard_photo')"
                        >
                          <div class="d-grid">
                            <button
                              v-if="!confirm_mode"
                              type="button"
                              @click="clickUpFileBtn(450, k)"
                              class="btn btn-light btn-outline-secondary"
                            >
                              <font-awesome-icon
                                :icon="['fas', 'file-arrow-up']"
                              />
                              &nbsp;ファイルを選択する
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-1" v-else>
                        <div
                          class="col-xl-2 col-3"
                          v-if="
                            [0].includes(
                              application_obj.application_check_type
                            ) >= 0 && (data_obj || {})['path' + k]
                          "
                        >
                          <span
                            v-if="k == 4 && data_obj.parent_path_type == 20"
                          ></span>
                          <span v-else-if="k == 4">表</span>
                          <span v-else-if="k == 5">表面</span>
                        </div>
                        <div
                          class="col-12"
                          v-else-if="
                            [100, 120, 200, 600].includes(
                              application_obj.application_check_type
                            ) >= 0 && (data_obj || {})['path' + k]
                          "
                        >
                          送信済
                        </div>
                        <div class="col-12" v-else>-</div>
                        <div
                          class="col-12 col-md-10 mb-1"
                          v-if="!chk_app_checked('parent_idcard_photo')"
                        >
                          <div class="d-grid mb-1">
                            <button
                              v-if="!confirm_mode"
                              type="button"
                              @click="clickUpFileBtn(450, k)"
                              class="btn btn-light btn-outline-secondary"
                            >
                              <font-awesome-icon
                                :icon="['fas', 'file-arrow-up']"
                              />
                              &nbsp;ファイルを選択する
                            </button>
                          </div>
                        </div>
                      </div>
                      <input
                        type="file"
                        class="form-control"
                        :id="'file_up_input_450_path' + k"
                        @change="selectedFile($event, 450, k, false)"
                        accept="image/*"
                        style="display: none"
                      />
                    </div>
                    <div
                      v-if="get_target_com_msg('parent_idcard_photo')"
                      class="py-2"
                    >
                      <p class="alert alert-danger">
                        <font-awesome-icon
                          :icon="['fa', 'exclamation-triangle']"
                          class="me-2"
                        />{{
                          get_target_com_msg('parent_idcard_photo')
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-8" v-else>
                    書類の種類を選択してください
                  </div>
                </div>

                <!-- 親権者:MFJライセンスNo -->
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    親権者:MFJライセンスNo
                  </h4>
                  <div class="col-12 col-md-8">
                    <input
                      type="text"
                      :class="input_class('parent_mfj_license')"
                      v-model="data_obj.parent_mfj_license"
                      :readonly="input_readonly('parent_mfj_license')"
                    />
                  </div>
                </div>

                <!-- 親権者:顔写真 -->
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    親権者:顔写真<span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-3 mb-2 mb-md-0">
                    <div class="" v-if="(data_obj || {}).path6">
                      <img :src="(data_obj || {}).path6" class="w-100" />
                    </div>
                  </div>
                  <div class="col-12 col-md-5">
                    <div class="d-grid">
                      <button
                        v-if="!confirm_mode && !chk_app_checked('parent_photo')"
                        type="button"
                        @click="clickUpFileBtn(150, 6)"
                        class="btn btn-light btn-outline-secondary"
                      >
                        <font-awesome-icon :icon="['fas', 'file-arrow-up']" />
                        &nbsp;ファイルを選択する
                      </button>
                    </div>
                    <input
                      type="file"
                      class="form-control"
                      :id="'file_up_input_150_path6'"
                      @change="selectedFile($event, 150, 6, false)"
                      accept="image/*"
                      style="display: none"
                    />
                    <div v-if="get_target_com_msg('parent_photo')" class="py-2">
                      <p class="alert alert-danger">
                        <font-awesome-icon
                          :icon="['fa', 'exclamation-triangle']"
                          class="me-2"
                        />{{
                          get_target_com_msg('parent_photo')
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container px-0">
            <cart-view
              :selected_variants="selected_variants"
              :race_sports_year="race_sports_year_obj"
            ></cart-view>
          </div>
          <div class="container mt-5">
            <div class="row justify-content-center">
              <div
                class="col-12 col-md-4 mb-3 mb-md-2 text-center"
                 v-if="(this.application_obj || {}).application_check_type != 600"
              >
                <button
                  class="button03 w-100"
                  @click="back('profile')"
                  v-if="page_mode == 'confirm'"
                >
                  <font-awesome-icon
                    :icon="['fas', 'chevron-left']"
                    class="mx-2"
                  />
                  <span class="button03__text">戻る</span>
                </button>
                <button class="button03 w-100" @click="back('license_sel')" v-else>
                  <font-awesome-icon
                    :icon="['fas', 'chevron-left']"
                    class="mx-2"
                  />
                  <span class="button03__text">戻る</span>
                </button>
              </div>
              <div
                class="text-center col-6 offset-0 order-2 order-md-1 col-md-3 mb-2"
                v-else
              >
              </div>
              <div
                class="text-center col-12 order-1 order-md-2 col-md-6 offset-md-0 mb-2"
              >
                <button
                  class="button04 w-100"
                  @click="chk_app()"
                  v-if="
                    page_mode == 'confirm' &&
                    (this.application_obj || {}).application_check_type == 600
                  "
                >
                  <span class="button__text">
                    <span class="button04__text">再申請する</span>
                    <font-awesome-icon
                      :icon="['fas', 'arrow-rotate-right']"
                      class="mx-2"
                    />
                  </span>
                </button>
                <button
                  class="button04 w-100"
                  @click="chk_app()"
                  :disabled="!formIsValid || page_mode !== 'confirm'"
                  v-else-if="page_mode == 'confirm'"
                >
                  <span class="button__text">
                    <span class="button04__text">新規申込を申請する</span>
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      class="mx-2"
                    />
                  </span>
                </button>

                <button
                  class="button03 w-100"
                  @click="app_check_confirm()"
                  v-else-if="validationMessage"
                  :disabled="!formIsValid"
                >
                  <span class="button__text">
                    <span class="button03__text">申込内容を確認する</span>
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      class="mx-2"
                    />
                  </span>
                </button>
                <button
                  class="button04 w-100"
                  @click="app_check_confirm()"
                  v-else
                  :disabled="!formIsValid"
                >
                  <span class="button__text">
                    <span class="button04__text">申込内容を確認する</span>
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      class="mx-2"
                    />
                  </span>
                </button>

                <div class="box02 mt-3" v-if="validationMessage">
                  <h2 class="box02__title">
                    <font-awesome-icon
                      :icon="['fas', 'circle-exclamation']"
                      class="me-2"
                    />
                  </h2>
                  <div
                    v-html="validationMessage"
                    class="d-flex flex-column gap-2"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 決済 - 事前レンダリングが必要（v-show） -->
        <div v-show="charge_mode">
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-exclamation-lg"></i>&nbsp;承認
              </div>
              <p>ライセンス取得申請が承認されました。</p>
              <p>ライセンス申請料金の支払いを行って下さい。</p>
              <p>{{ message }}</p>
            </div>
          </div>

          <!-- 支払い内容 -->
          <div class="mb-3">
            <div class="mb-2 pb-2">支払い内容</div>
            <div class="row mb-2 pb-2 border-bottom">
              <div class="col">内訳</div>
              <div class="col">金額</div>
            </div>
            <div
              class="row mb-2 pb-2 border-bottom"
              v-for="order in sales_obj.sales_orders"
              :key="order"
            >
              <div class="col-6">
                {{ order.sub_label }} ({{ order.sports_year }}年度)
              </div>
              <div class="col-6">{{ number_format(order.list_price) }}円</div>
            </div>
            <div class="row">
              <div class="col">合計</div>
              <div class="col">{{ number_format(sales_obj.amount) }}円</div>
            </div>
          </div>

          <!-- 支払い方法 -->
          <div class="mb-3">
            <!-- {{ member_obj }} -->
            <div class="container mb-4 pb-4 border-bottom">
              <div class="row">
                <div class="col" v-for="type in charge_type_list" :key="type">
                  <div class="d-grid">
                    <button
                      type="button"
                      :class="get_class_charge_type(type.key)"
                      @click="sel_charge_type(type.key)"
                    >
                      {{ type.label }}で支払う
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <!-- {{ get_order }} -->
              <div class="row" v-show="selected_charge_type == 'creditcard'">
                <h3 class="title02 page__h2">クレジットカード</h3>
                <div class="col-10 offset-1 col-lg-8 offset-lg-2 mb-10">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-8 mb-10 text-center">
                      ご利用可能カードブランド
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 mb-10 text-center">
                      <img :src="assets_url + '/base/creditcard_checkout_logo.png'" class="w-75">
                    </div>
                  </div>
                  <!-- <img :src="(data_obj || {}).path3" class="w-100" /> -->
                </div>
                <!-- {{ get_payer }}<hr>
                {{ get_merchant }}<hr>
                {{ get_order }} -->
                <div class="col-10 offset-1 col-lg-8 offset-lg-2">
                  <!-- {{ credit_result_obj }} -->
                  <label for="card-number">カード番号:</label>
                  <div id="card-number" class="card-form__div mb-2" />
                  <label for="expiry-month">有効期限:</label>
                  <div id="expiry-month" class="card-form__div mb-2" />
                  <label for="security-code">セキュリティコード:</label>
                  <div id="security-code" class="card-form__div mb-2" />
                </div>
              </div>
              <div class="row" v-show="selected_charge_type == 'cash'">
                <h3 class="title02 page__h2">コンビニ支払い</h3>
                <KonbiniForm :on-submit="charge_cash" :ini_payer="get_payer" />
                <h2>コンビニ支払い情報</h2>
                <ul v-if="has_receipt_data" class="list-group">
                  <li
                    v-for="(value, key) in receipt"
                    :key="key"
                    class="list-group-item d-flex justify-content-between"
                  >
                    <span class="fw-bold text-capitalize">{{ key }}</span>
                    <span class="text-muted">{{ value }}</span>
                  </li>
                </ul>
                <h2>各社のご案内</h2>
                <ul>
                  <li v-for="(link, index) in links" :key="index">
                    <a :href="link.href" target="_blank">{{ link.text }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="container">
              <div class="row  mb-12" v-if="selected_charge_type == 'creditcard'">
                <div class="row mb-2">
                  <div class="col-4 offset-4">
                    <div class="d-grid" v-if="charge_btn_display">
                      <button
                        type="button"
                        @click="charge_credit"
                        class="btn btn-primary"
                        :disabled="charge_btn_disabled"
                      >
                        決済する
                      </button>
                    </div>
                    <div class="d-grid" v-else>--</div>
                  </div>
                </div>
                <div class="col-10 offset-1 col-lg-8 offset-lg-2">
                  <div class="box  text-start">

                    <h5 class="title06 page__h6">クレジットカード決済ができない件について</h5>
                    <p>
                    MFJでは、みなさまに安心してWEB会員サービスをご利用いただくために「3Dセキュア（本人認証サービス）」（以下、3Dセキュア）を導入しております。<br>
                    3Ｄセキュアとは、カード決済の際に、必要に応じて「本人確認パスワード＊」等の入力により本人確認を行うものです。<br>
                    この3Dセキュアの設定がお済でないクレジットカードはお使いいただけない場合がございます。<br>
                    その場合は、他のクレジットカードをご利用いただくか、コンビニ決済をご利用いただけますようお願いいたします。<br>
                    ※3Dセキュアにつきましては、各クレジットカード会社へお問合せ頂きますようお願いいたします。
                    </p>
                  </div>
                </div>
              </div>
            </div>
                
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .page__inputGroupInput {
    display: flex;
    align-items: center;
  }

  .input-group-text {
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
  }

  .required-label {
    color: red;
  }

  .next-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 44px; /* タップしやすい最小の高さ */
  }

  .button-text {
    display: inline-block;
    white-space: nowrap;
  }

  .input-group {
    position: relative;
  }

  .input-group-addon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .input-group:not(.has-validation) > .dropdown-toggle,
  .input-group:not(.has-validation) > .form-floating > .form-control,
  .input-group:not(.has-validation) > .form-floating > .form-select,
  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
      .form-floating
    ) {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }
  .card-form__div {
    border: 1px solid #ccc;
    border-radius: 5px;
  }
</style>
<script>
  import { mapState } from 'vuex'
  import { usePaymentPage } from '@ootbteam/mulpay-pinia'
  import StepView from './StepView.vue'
  import CartView from './CartView.vue'
  import Common from '../../../common/common'
  import BsSelect from '../../atoms/BsSelect.vue'
  import config from '../../../const'
  // import client from './../../../api/client'
  import moment from 'moment'
  import axios from 'axios'
  import KonbiniForm from '@/components/forms/KonbiniForm.vue'
  import { toRaw } from 'vue'
  import {
    cardInputStyle,
    cvcInputStyle,
    expiryInputStyle
  } from '@/utils/cardstyle'

  export default {
    name: 'RegistFormView',
    data: function () {
      return {
        regist_token: this.$route.query.token,
        page_mode: 'license',
        update_result: false,
        update_error: false,
        message_class: 'info',
        formIsValid: false,
        validationMessage: '',
        message: '',
        enquate_sheet_obj: [],
        enquate_answers: {},
        // 支払い
        merchant_obj: {},
        charge_btn_disabled: false,
        selected_charge_type: 'creditcard',
        charge_type_list: [
          {
            key: 'creditcard',
            label: 'クレジットカード',
          },
          {
            key: 'cash',
            label: 'コンビニ',
          },
        ],
        credit_card_obj: {
          cardno: '222222222222',
          expire_y: '2027',
          expire_m: '4',
          securitycode: '123',
          holdername: '',
          tokennumber: '1',
        },
        credit_result_obj: {},
        member_obj: {},
        application_key_obj: {},

        license_cd_obj: [],

        target_step: ['kiyaku', 'mail'],
        past_license_chk_list: [
          {
            key: 0,
            label: '保有していない',
          },
          {
            key: 1,
            label: '保有していた',
          },
        ],
        regist_obj: {},
        application_obj: {},
        draft_order_obj: [],
        sales_obj: {},
        data_obj: {},
        communication_obj: {},
        communication_checked: [],

        post_mail_address: '',

        // now_year: parseInt(Common.getNowYear()),
        //
        sex_type_obj: [],
        pref_cd_obj: [],
        circuit_obj: [],
        identify_type_cd_obj: [],
        member_document_cd_obj: [],
        race_sports_year_obj: [],
        term_year_obj: [],
        term_date_obj: [],
        now_date_obj: [],
        // select license用の選択内容保存変数
        license_sel_frm_mode: 'year',
        selected_year: [],
        chk_license_category: {
          1: false, //競技
          2: false, //ピットクルー
          4: false, //役員
          5: false, //講師
          3: false, //エンジョイ
        },
        selected_package_flg: 0,
        selected_variants: [],
        have_variants: [],
        first_select_main: false,
        first_select_sub: false,
        selected_variants_license_category: {
          1: [],
          2: [],
          3: [],
          4: [],
          5: [],
        },
        selected_variants_insurance: [], //保険
        selected_variants_commission: [], //手数料

        selected_license_category_no: '',

        nonsel_license_category: [],

        article_variant_obj: [],
        article_obj: [],
        receipt: undefined,
        links: [
          {
            code: 'SEVEN_ELEVEN',
            text: 'セブンイレブン',
            href: 'https://static.mul-pay.jp/customer-convenience-store/seveneleven/index.html',
          },
          {
            code: 'LAWSON',
            text: 'ローソンの「Loppi」を使ってお支払い（お客様番号が11桁の場合）',
            href: 'https://static.mul-pay.jp/customer-convenience-store/lawson/11.html',
          },
          {
            code: 'LAWSON',
            text: 'ローソンの「Loppi」を使ってお支払い（お客様番号が14桁の場合）',
            href: 'https://static.mul-pay.jp/customer-convenience-store/lawson/14.html',
          },
          {
            code: 'FAMILYMART',
            text: 'ファミリーマートの「マルチコピー機」を使ってお支払い（第1番号11桁の場合）',
            href: 'https://www.family.co.jp/services/application/receipt.html',
          },
          {
            code: 'FAMILYMART',
            text: 'ファミリーマートの「マルチコピー機」を使ってお支払い（第2番号12桁の場合）',
            href: 'https://www.family.co.jp/services/application/receipt.html',
          },
          {
            code: 'MINISTOP',
            text: 'ミニストップの「Loppi」を使ってお支払い（お客様番号が11桁の場合）',
            href: 'https://static.mul-pay.jp/customer-convenience-store/ministop/11.html',
          },
          {
            code: 'MINISTOP',
            text: 'ミニストップの「Loppi」を使ってお支払い（お客様番号が14桁の場合）',
            href: 'https://static.mul-pay.jp/customer-convenience-store/ministop/14.html',
          },
          // {
          //   code: 'DAILYYAMAZAKI',
          //   text: 'デイリーヤマザキの「POSレジ」を使ってお支払い',
          //   href: 'https://static.mul-pay.jp/customer-convenience-store/dailyyamazaki/index.html',
          // },
          {
            code: 'SEICOMART',
            text: 'セイコーマートの「POSレジ」を使ってお支払い',
            href: 'https://static.mul-pay.jp/customer-convenience-store/seicomart/',
          },
        ],
        isPasswordVisible: false,
        birth_date_validate: false,
        isLoading: false, // ローディング状態を追加,

        assets_url: config.BASE_ASSETS_URL,

      }
    },
    setup() {
      const {
        handlePaymentPageProps,
        setPaymentPayload,
        setCardPaymentPayload,
        cardPayment,
        cashPayment,
        generateSimpleRandomString,
        setElements,
        clear,
      } = usePaymentPage()

      return {
        handlePaymentPageProps,
        setPaymentPayload,
        setCardPaymentPayload,
        cardPayment,
        cashPayment,
        generateSimpleRandomString,
        setElements,
        clear,
      }
    },
    components: {
      KonbiniForm,
      StepView,
      CartView,
      BsSelect,
    },
    methods: {
      async app_check_confirm() {
        this.isLoading = true
        try {
          await new Promise(resolve => setTimeout(resolve, 500))

          const params = {
            login_id: (this.data_obj || {}).login_id,
          }
          const check = await this.nonloginPosts_S(
            config.BASE_API_URL + '/member/lists',
            params
          )
            .then(async (response) => {
              // レスポンスの検証
              if (
                typeof response?.data?.body === 'object' &&
                response?.data?.body.length > 0
              ) {
                return true
              } else {
                return false
              }
            })
            .catch(async (err) => {
              console.log(err)
              return false
            })
          
          const params_2 = {
            migration_key: 'login_id',
            migration_value: (this.data_obj || {}).login_id,
          }
          const check_2 = await this.nonloginPosts_S(
            config.BASE_API_URL + '/member_migration_item/lists',
            params_2
          )
            .then(async (response) => {
              // レスポンスの検証
              if (
                typeof response?.data?.body === 'object' &&
                response?.data?.body.length > 0
              ) {
                return true
              } else {
                return false
              }
            })
            .catch(async (err) => {
              console.log(err)
              return false
            })
          if (check || check_2) {
            alert('ログインIDが重複しています')
            return
          } else {
            this.proceed('confirm')
            return
          }
        } finally {
          this.isLoading = false
        }
      },
      validateForm() {
        const requiredFields = [
          // { key: 'race_sports_year', label: '年度' },
          {
            key: 'login_id',
            label: 'ログインID',
            errorMessage:
              'ログインIDは8〜24文字の半角英数字でなければなりません。',
          },
          {
            key: 'login_pass',
            label: 'パスワード',
            errorMessage:
              'パスワードは8〜24文字で、英大文字、英小文字、数字、記号（!?@#%^&*）から3種類以上を組み合わせて入力してください。',
          },
          { key: 'name_1', label: '姓' },
          { key: 'name_2', label: '名' },
          {
            key: 'yomi_1',
            label: 'セイ',
            errorMessage: 'セイは全角カナで入力してください。',
          },
          {
            key: 'yomi_2',
            label: 'メイ',
            errorMessage: 'メイは全角カナで入力してください。',
          },
          // { key: 'birth_date', label: '生年月日 西暦', errorMessage: '生年月日を入力して下さい'},
          { key: 'sex_type', label: '性別' },
          {
            key: 'path3',
            label: '顔写真',
            errorMessage: '顔写真はJPEG、JPG、PNG形式のみ許可されています。',
          },
          { key: 'postal_cd', label: '郵便番号' },
          { key: 'pref_cd', label: '都道府県' },
          { key: 'address_1', label: '市区町村番地' },
          { key: 'tel_1', label: '連絡先電話番号1' },
          { key: 'mail_1', label: 'メールアドレス' },
          { key: 'mail_flg', label: 'メールマガジン配信希望' },
          {
            key: 'path1',
            label: '本人確認書類種類',
            errorMessage:
              '本人確認書類種類はJPEG、JPG、PNG形式のみ許可されています。',
          },
        ]
        // m_path_typeが20の場合、path2を必須項目に追加
        if (this.data_obj.m_path_type === '20') {
          requiredFields.push({
            key: 'path2',
            label: '本人確認書類種類裏面',
            errorMessage:
              '本人確認書類種類はJPEG、JPG、PNG形式のみ許可されています。',
          })
        }

        // サーキットライセンス(3時間走行証明用)
        if (this.rd_3hour_frm_disp) {
          requiredFields.push(
            {
              key: 'join_3hour_circuit_id',
              label: '公認サーキット',
              source: 'variant',
            }
          )
          // MFJの場合
          if(!this.chk_3hour_circuit_mfj_total){
            requiredFields.push(
              {
                key: 'join_3hour_circuit_license_no',
                label: 'ライセンス番号',
                source: 'variant',
              },
              {
                key: 'path10',
                label: 'ライセンス証',
                errorMessage:
                  'ライセンス証はJPEG、JPG、PNG形式のみ許可されています。',
              },
            )
          }
          else{
            requiredFields.push(
              {
                key: 'path11',
                label: '提出書類',
                errorMessage:
                  '提出書類はJPEG、JPG、PNG形式のみ許可されています。',
              }
            )
          }
        }
        const variants = this.get_selected_variants_filter({ join_3hour: 1 }, {}, 1)
        const rawVariants = toRaw(variants)
        const filteredVariant = rawVariants.find(
          (variant) => variant.join_3hour === '1'
        )

        // 生年月日
        // const birthDate = moment(
        //           this.data_obj.birth_date_year +
        //             '-' +
        //             this.data_obj.birth_date_month +
        //             '-' +
        //             this.data_obj.birth_date_day
        //         ).format('YYYY-MM-DD')

        // 必須項目のバリデーション処理
        const emptyFields = requiredFields.filter((field) => {
          let value

          if (field.source === 'variant') {
            value = filteredVariant?.[field.key]
          // } else if (field.key === 'birth_date'){
          //   value = birthDate
          } else {
            value = this.data_obj?.[field.key]
          }
          return value === '' || value == null
        })

        // バリデーションチェックの追加
        const loginId = this.data_obj.login_id || ''
        
        // ログインIDとパスワードのバリデーション
        // const loginIdValid = /^[a-zA-Z0-9]{8,30}$/.test(loginId)
        const loginIdValid = /^[a-zA-Z0-9!?@#%^&*]{8,24}$/.test(loginId)
        // const loginIdValid = /^[a-zA-Z0-9]{8,30}$/.test(loginId)

        const loginPass = this.data_obj.login_pass || ''
        
        // パスワードの条件をチェック
        const hasUpperCase = /[A-Z]/.test(loginPass)
        const hasLowerCase = /[a-z]/.test(loginPass)
        const hasNumber = /[0-9]/.test(loginPass)
        const hasSymbol = /[!?@#%^&*]/.test(loginPass)
        const isValidLength = loginPass.length >= 8 && loginPass.length <= 24

        // 3種類以上の文字種を含むかチェック
        const characterTypesCount = [hasUpperCase, hasLowerCase, hasNumber, hasSymbol]
          .filter(Boolean)
          .length

        const loginPassValid = isValidLength && characterTypesCount >= 3
        // const loginPassValid =
        //   /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])[a-zA-Z0-9!@#$%^&*.]{8,24}$/.test(
        //     loginPass
        //   )
        // 生年月日のバリデーション
        // const birthDateValid = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(birthDate)
        // const birthDateValid2 = new Date(birthDate);
        
        // カナのバリデーション処理
        const yomi1 = this.data_obj.yomi_1 || '';
        const yomi2 = this.data_obj.yomi_2 || '';
        const kanaRegex = /^[ァ-ヶー]+$/;

        // 顔写真のバリデーション
        const allowedExtensions = ['jpeg', 'jpg', 'png']
        const fileFields = ['path1', 'path2', 'path3', 'path10']

        this.validationMessage = [] // エラーメッセージを配列として初期化

        // ファイル拡張子のバリデーション
        fileFields.forEach((fieldKey) => {
          let filePath

          const field = requiredFields.find((f) => f.key === fieldKey)
          if (field?.source === 'variant') {
            filePath = filteredVariant?.[fieldKey]
          } else {
            filePath = this.data_obj?.[fieldKey]
          }
          if (filePath) {
            const extension = filePath.split('.').pop().toLowerCase()
            if (!allowedExtensions.includes(extension)) {
              const field = requiredFields.find((f) => f.key === fieldKey)
              if (field?.errorMessage) {
                this.validationMessage.push(field.errorMessage)
              }
            }
          }
        })

        // ログインIDとパスワードのバリデーションメッセージ
        if (loginId && !loginIdValid) {
          this.validationMessage.push(
            requiredFields.find((field) => field.key === 'login_id')
              .errorMessage
          )
        }
        if (loginPass && !loginPassValid) {
          this.validationMessage.push(
            requiredFields.find((field) => field.key === 'login_pass')
              .errorMessage
          )
        }
        // 生年月日のバリデーションメッセージ
        // if (birthDate && (!birthDateValid || isNaN(birthDateValid2))) {
        //   this.validationMessage.push(
        //     requiredFields.find((field) => field.key === 'birth_date')
        //       .errorMessage
        //   )
        //   this.birth_date_validate = true
        //   console.log('birth_date_validate trueになるはず', this.birth_date_validate)
        // }else{
        //   this.birth_date_validate = false
        //   console.log('birth_date_validate false??', this.birth_date_validate, this.validationMessage)
        //   console.log('birth_date_validate false??birthDate', birthDate)
        // }

        // カナのバリデーションチェック
        if (yomi1 && !kanaRegex.test(yomi1)) {
          this.validationMessage.push(requiredFields.find(field => field.key === 'yomi_1').errorMessage);
        }
        if (yomi2 && !kanaRegex.test(yomi2)) {
          this.validationMessage.push(requiredFields.find(field => field.key === 'yomi_2').errorMessage);
        }

        // 講習会ONの場合
        if(
          this.join_seminar_web_frm_disp || this.join_seminar_circuit_frm_disp
        ){
          const tmps = []
          if(
            typeof this.get_license_selected_variants_filter({
                            join_seminar_web: 1,
                            join_seminar_circuit: 1,
                          }) === 'object'
          ){
            this.get_license_selected_variants_filter({
              join_seminar_web: 1,
              join_seminar_circuit: 1,
            }).forEach(license_id => {
              if(
                typeof this.get_selected_variants_filter(
                  {
                  join_seminar_web: 1,
                  join_seminar_circuit: 1,
                  }, {
                    license_id: license_id,
                  }, 1
                ) === 'object'
              ){
                this.get_selected_variants_filter({
                  join_seminar_web: 1,
                  join_seminar_circuit: 1,
                }, {
                  license_id: license_id,
                }, 1).forEach(variant => {
                  if(
                    variant.join_seminar_type == 'web'
                  ){
                    tmps.push(variant)
                  }
                  else if(
                    variant.join_seminar_type == 'circuit'
                    && variant.join_seminar_place != ''
                    && variant.join_seminar_date != ''
                  ){
                    tmps.push(variant)
                  }
                })
              }
            })
            if(
              this.get_license_selected_variants_filter({
                join_seminar_web: 1,
                join_seminar_circuit: 1,
              }).length > tmps.length
            ){
              this.validationMessage.push('講習会に必要な項目が選択・入力されていません');
            }
          }
        }

        this.formIsValid =
          emptyFields.length === 0 && this.validationMessage.length === 0

        if (!this.formIsValid) {
          const emptyFieldLabels = emptyFields
            .map((field) => field.label)
            .join('、')
          if (this.validationMessage.length > 0) {
            const errorMessages = this.validationMessage.join('<br>')
            this.validationMessage = `${errorMessages}<br>以下の必須項目を入力してください: ${emptyFieldLabels}`
          } else {
            this.validationMessage = `以下の必須項目を入力してください: ${emptyFieldLabels}`
          }
        } else {
          this.validationMessage = ''
        }
      },
      formatPhoneNumber(phoneNumber) {
        // 数字以外の文字を削除
        const cleaned = ('' + phoneNumber).replace(/\D/g, '')

        // 適切な形式に整形
        const match = cleaned.match(/^(\d{2,3})(\d{3,4})(\d{4})$/)
        if (match) {
          return `${match[1]}-${match[2]}-${match[3]}`
        }

        return phoneNumber // マッチしない場合は元の値を返す
      },

      validatePhoneNumber(phoneNumber) {
        // 日本の電話番号の一般的な形式をチェック
        const phoneRegex = /^(0\d{1,4}-\d{1,4}-\d{4})$/
        return phoneRegex.test(phoneNumber)
      },

      async update_sales_order_id(to_type){
        const sales_id = (
          (this.sales_obj || {}).id
        )? (this.sales_obj || {}).id: false
        if(!sales_id) {
          alert('データがありません')
          this.selected_charge_type = 'creditcard'
          return 
        }
        
        const order_id = (
          (this.sales_obj || {}).charge_order_id
        )? (this.sales_obj || {}).charge_order_id: false
        if(!order_id) {
          alert('決済IDがありません')
          this.selected_charge_type = 'creditcard'
          return 
        }
        let up_order_id = order_id
        // クレジット->コンビニ
        if(
          to_type == 'cash'
          && this.selected_charge_type == 'creditcard'
        ){
          if(
            !(this.sales_obj || {}).charge_order_id.endsWith('K')
          ){
            up_order_id = order_id + 'K'
          }
        }
        // コンビニ->クレジット
        else if(
          to_type == 'creditcard'
          && this.selected_charge_type == 'cash'
        ){
          if(
            (this.sales_obj || {}).charge_order_id.endsWith('K')
          ){
            up_order_id = order_id.slice(0, -1)
          }
        }
        else{
          this.selected_charge_type = to_type
          return
        }

        this.selected_charge_type = to_type
        // sales update
        const sales_url_path =
        sales_id > 0
            ? '/sales/edit/' + sales_id
            : false
        const sales_params = {
          charge_order_id: up_order_id,
        }
        const chk = (sales_url_path)? await this.nonloginPosts_I(
            config.BASE_API_URL + sales_url_path,
            sales_params
          ): false
        if(chk){
          return up_order_id
        }
        return chk
      },

      async sel_charge_type(charge_type) {
        this.isLoading = true
        try {
          await new Promise(resolve => setTimeout(resolve, 500))
        
          await this.update_sales_order_id(charge_type)
          await this.loadRegistData()
        } finally {
          this.isLoading = false
        }
      },
      async charge_cash({ name, nameKana, phoneNumber, code }) {
        this.charge_btn_disabled = true
        const payer_tmp = {...this.get_payer}
        payer_tmp.name = name
        payer_tmp.nameKana = nameKana
        payer_tmp.phones = [
              {
                type: 'MOBILE',
                countryCode: '81',
                number: phoneNumber,
              },
            ]
        const payload = {
          payer: payer_tmp,
          merchant: this.get_merchant,
          order: this.get_order,

          // payer: {
          //   name: name,
          //   nameKana: nameKana,
          //   phones: [
          //     {
          //       type: 'MOBILE',
          //       countryCode: '81',
          //       number: phoneNumber,
          //     },
          //   ],
          // },
          // merchant: {
          //   name: 'テスト会社',
          //   nameKana: 'テストストア',
          //   nameAlphabet: 'teststore',
          //   nameShort: 'テスト会社',
          //   contactName: 'テスト会社サポート窓口',
          //   contactEmail: 'test@example.com',
          //   contactPhone: '090-1234-5678',
          //   contactOpeningHours: '09:00-18:00',
          //   contactUrl: 'http://localhost:3000/',
          //   callbackUrl:
          //     config.BASE_FRONTEND_URL + '/mfj_renewal/app/regist/3ds/',
          //   webhookUrl: config.BASE_API_URL + '/payment/webhookkonbini/',
          // },
          // order: {
          //   orderId: this.generateSimpleRandomString(20),
          //   amount: '2000',
          // },
          cashOptions: {
            konbiniCode: code,
            // 支払い期限日数
            paymentPeriod: '10',
          },
        }
        console.log('testtest payload', payload)

        // バリデーション制約上の必要な情報を設定
        await this.setPaymentPayload(payload).catch(alert)

        /**
         * コンビニ決済の支払い完了通知は、決済代行会社からリクエストが送信（以下、webhook）されるため、情報の引き継ぎが課題になる。
         * `webhook`は、ステートレスなリクエストになるため、
         * コンビニ決済の要求時に必要な情報をAPIのリクエストに乗せて、データベースに更新する。
         */
        const formToken = this.$route.query.token
        const formTokenId = (this.application_obj || {}).id
        const formSalesId = (this.sales_obj || {}).id
        console.log('formToken formTokenId formSalesId', formToken, formTokenId, formSalesId)
        await this.cashPayment(
          formToken,
          formTokenId,
          formSalesId,
          async (payload) => {
            // コンビニ支払い番号を表示
            console.log('testtest payload cashPayment', payload)
            const { cashResult } = payload
            this.receipt = cashResult.konbiniPaymentInformation
            console.log('testtest receipt', this.receipt)
            const chk = await this.update_sales_receipt(formSalesId)
            if(chk){
              await this.update_app_check(420)
            }
          }
        ).catch(async (error) => {
          alert(error)
          await this.update_app_check(400)
          await this.loadRegistData()
        })
      },
      async charge_credit() {
        this.isLoading = true
        try {
          await new Promise(resolve => setTimeout(resolve, 500))
          this.charge_btn_disabled = true

          // バリデーション制約上の必要な情報を設定
          await this.setCardPaymentPayload({
            payer: this.get_payer,
            merchant: this.get_merchant,
            order: this.get_order,
          }).catch(alert)
          await this.update_app_check(403)

          if (this.selected_charge_type === 'creditcard') {
            console.log('selected_charge_type=creditcard cardPayment before')
            await this.cardPayment((payload) => {
              console.log(
                'selected_charge_type=creditcard cardPayment payload',
                payload
              )
              const { redirectInformation, orderReference } = payload

                // リロード問題を回避するための暫定対処コード
                if(!orderReference?.accessId) {
                //   alert(payload)
                    alert(payload + '\n3Dセキュア決済が失敗しました。カード情報をご確認下さい。')
                  return;
                }

              localStorage.setItem('accessId', orderReference.accessId)
              localStorage.setItem('formToken', this.$route.query.token)
              localStorage.setItem('formTokenId', (this.application_obj || {}).id)
              localStorage.setItem('formSalesId', (this.sales_obj || {}).id)
              location.href = redirectInformation.redirectUrl
            }).catch(async (error) => {
              let error_json = error?.message
              if(
                  this.is_json(error_json)
              ){
                  const errorCode = []
                  const parsedError = JSON.parse(error_json)
                  if(parsedError){
                      for(const key in parsedError){
                          if(parsedError[key].legacyCode && !errorCode.includes(parsedError[key].legacyCode)){
                              errorCode.push(parsedError[key].legacyCode)
                          }
                      }
                  }
                  const error_msg = 'カード決済エラーです。' + (Array.isArray(errorCode) && errorCode.length > 0)? 'エラーコード:' + errorCode.join(","): ''
                  alert(error_msg + 'カード情報を確認して下さい。')
                  // console.log(errorCode)
              }else{
                  alert('カード決済エラーです。カード情報を確認して下さい。')
              }
              // alert('カード決済エラーです。カード情報を確認して下さい。')
              // console.log(error)
              // await this.update_app_check(400)
              // await this.loadRegistData()
            })
          } else {
            // コンビニ決済のコードを移動したため、この分岐は不要です
          }
        } finally {
          this.isLoading = false
        }
      },
      // ライセンス・保険登録処理
      async member_license_update() {
        const member = { ...this.member_obj }

        const up_license =
          typeof member.member_license === 'object' ? member.member_license : []
        const up_insurance =
          typeof member.insurance === 'object' ? member.insurance : []
        const join_license_tmp = []
        const join_license_tmps = {}
        // fix=1が存在していればtrueにする
        let fix_flg = false
        // sales_ordersからライセンス・保険を設定
        if (typeof this.sales_obj.sales_orders === 'object') {
          this.sales_obj.sales_orders.forEach((order) => {
            if(
              !join_license_tmps[order.sports_year]
            ){ 
              join_license_tmps[order.sports_year] = []
            }
            // const start = moment({
            //   years: parseInt(order.sports_year),
            //   months: 3,
            //   days: 1,
            // }).format('YYYY-MM-DD')
            const start = moment().add(1, 'd').format('YYYY-MM-DD')
            const start_num = parseInt(moment().add(1, 'd').format('YYYYMMDD'))
            const end = moment({
              years: parseInt(order.sports_year) + 1,
              months: 2,
              days: 31,
            }).format('YYYY-MM-DD')
            if (order.license_id > 0) {
              const seminar_type = this.draft_order_obj?.find(draft => (
                  draft.application_id == order.application_id && draft.variant_id == order.variant_id)
                )
                ? this.draft_order_obj?.find(draft => (
                  draft.application_id == order.application_id && draft.variant_id == order.variant_id))?.join_seminar_type
                : false
              const license_start = 
                  parseInt(order.sports_year + '0401') > start_num
                  ? order.sports_year + '-04-01'
                  : start
              const fixed = (order.join_3hour == 1)
                  ? 1
                  : (seminar_type == 'circuit')
                  ? 1
                  : (seminar_type == 'web')
                  ? 0
                  : (seminar_type != 'circuit' && seminar_type != 'web')
                  ? 1
                  : 1
              fix_flg = (fixed==1)? true: fix_flg
              up_license.push({
                id: 0,
                stat: 1,
                m_id: member.id,
                fixed: fixed,
                payment_flg: this.selected_charge_type == 'creditcard' ? 1 : 0,
                license_cd: order.license_id,
                license_year: order.sports_year,
                license_term_start_date: license_start,
                license_term_end_date: end,
              })
              join_license_tmp.push(parseInt(order.license_id))
              join_license_tmps[order.sports_year].push(parseInt(order.license_id))
              // 基本的にピットクルーが付帯ライセンスなので、講習はなしと考えて良いはず
              if (
                order.sub_license_id &&
                parseInt(order.sub_license_id) > 0 &&
                // !join_license_tmp.includes(parseInt(order.sub_license_id))
                join_license_tmps[order.sports_year] && 
                !join_license_tmps[order.sports_year].includes(parseInt(order.sub_license_id))
              ) {
                // const sub_license = this.license_cd_obj?.find(
                //   (license) => license.id == order.sub_license_id
                // )?
              const sub_start = 
                  parseInt(order.sports_year + '0401') > start_num
                  ? order.sports_year + '-04-01'
                  : start
                
                up_license.push({
                  id: 0,
                  stat: 1,
                  m_id: member.id,
                  // PITピットクルーは講習なしなのでfixさせる
                  fixed: 1,
                  payment_flg:
                    this.selected_charge_type == 'creditcard' ? 1 : 0,
                  license_cd: order.sub_license_id,
                  license_year: order.sports_year,
                  license_term_start_date: sub_start,
                  license_term_end_date: end,
                })
                join_license_tmp.push(parseInt(order.sub_license_id))
                join_license_tmps[order.sports_year].push(parseInt(order.sub_license_id))
              }
            } else if (order.license_id == 0 && order.article_cateogry == 1) {
              // 保険期間は、fix_flgが1の場合は決済日(この処理実行日)の翌日がスタートとなる
              const insurance_start = 
                  parseInt(order.sports_year + '0401') > start_num
                  ? order.sports_year + '-04-01'
                  : start
              up_insurance.push({
                id: 0,
                stat: 1,
                fixed: (fix_flg)? 1: 0,
                m_id: member.id,
                insurance_type: order.variant_sort,
                sports_year: order.sports_year,
                start_date: insurance_start,
                end_date: end,
              })
            }
          })
        }
        member.member_license = up_license
        member.insurance = up_insurance
        console.log('member_license_update draft_order_obj', this.draft_order_obj)
        console.log('member_license_update sales_obj.sales_orders', this.sales_obj.sales_orders)
        console.log('member_license_update license', up_license)
        console.log('member_license_update insurance', up_insurance)
        // return true
        await this.nonloginPosts_I(
          config.BASE_API_URL + '/member/edit/' + member.id,
          member
        )
          .then(async (response) => {
            console.log('member_license_update response', response)
          })
          .catch(async (err) => {
            console.log(err)
            // this.member_obj = {};
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async update_sales_receipt(formSalesId) {
        // sales update
        const sales_url_path =
          formSalesId > 0
            ? '/sales/konbini_set/' + formSalesId
            : false
        const sales_params = {
          sales_type: 'regist',
          charge_type: 'KONBINI',
          cash_konbini_code: this.receipt?.konbiniCode,
          cash_receipt_number: this.receipt?.receiptNumber,
          cash_confirmation_number: this.receipt?.confirmationNumber,
        }
        const chk = (sales_url_path)? await this.nonloginPosts_I(
            config.BASE_API_URL + sales_url_path,
            sales_params
          ): false
        return chk
      },
      async update_app_check(check_type) {
        const post_url_path =
          this.application_obj.id > 0
            ? '/member_application/edit/' + this.application_obj.id
            : '/member_application/add'

        const params = {
          m_id: (this.member_obj || {}).id ? (this.member_obj || {}).id : 0,
          application_check_type: check_type,
        }
        await this.nonloginPosts_I(config.BASE_API_URL + post_url_path, params)
          .then(async (response) => {
            console.log('update_app_check', response)
          })
          .catch(async (err) => {
            console.log(err)
          })
          .finally(async () => {
            // await this.go_app();
            if (check_type != 403) await this.loadRegistData()
            this.charge_btn_disabled = false
            this.$forceUpdate()
          })
      },

      // sel_license系
      async back_license_sel(frm_mode, no) {
        this.license_sel_frm_mode = frm_mode
        this.selected_license_category_no = no
      },
      async attach_join_seminar_type(variant_id, seminar_type) {
        const variants = [...this.selected_variants]
        const idx =
          typeof variants === 'object'
            ? variants.findIndex((vr) => vr.variant_id == variant_id)
            : false
        console.log(
          'attach_join_seminar_type',
          variant_id,
          variants,
          idx,
          seminar_type
        )
        if (idx !== false) {
          variants[idx].join_seminar_type = seminar_type
        }
        console.log('attach_join_seminar_type2', variants, idx)
        this.selected_variants = variants
      },
      async attach_license_sel_package() {
        this.nonsel_license_category = []
        this.selected_variants = []
        this.have_variants = []
        if (this.selected_year.length >= 2) {
          this.selected_year = []
          this.selected_package_flg = 0
        } else {
          this.selected_year = [0, 1]
          this.selected_package_flg = 1
        }
      },
      async attach_license_sel_year(year_add) {
        this.nonsel_license_category = []
        this.selected_variants = []
        this.have_variants = []
        this.selected_package_flg = 0
        if (this.selected_year.includes(parseInt(year_add))) {
          this.selected_year = []
        } else {
          this.selected_year = [parseInt(year_add)]
          await this.next_license_sel_year()
          await this.attach_license_sel_category_wrap(1, 1)
        }
      },
      async next_license_sel_year() {
        this.license_sel_frm_mode = 'category_1'
      },
      // 未使用
      async attach_commission_sel_year(year_add) {
        if (
          typeof this.get_article_variant_commision === 'object' &&
          this.get_article_variant_commision.find(
            (variant) => variant.sports_year_add == year_add
          )
        ) {
          const variant_id = this.get_article_variant_commision.find(
            (variant) => variant.sports_year_add == year_add
          ).variant_id
          await this.sel_variant_year(variant_id, year_add, false)
        }
      },
      // 未使用
      async del_commission_sel_year(year_add) {
        if (
          typeof this.get_article_variant_commision === 'object' &&
          this.get_article_variant_commision.find(
            (variant) => variant.sports_year_add == year_add
          )
        ) {
          const variant_id = this.get_article_variant_commision.find(
            (variant) => variant.sports_year_add == year_add
          ).variant_id
          await this.sel_variant_year(variant_id, year_add, false)
        }
      },
      async go_license_category(next_step) {
        if (next_step == 10) {
          this.license_sel_frm_mode = 'insurance'
          // xxxinsuranceを選択するときの処理
          // await this.go_insurance();
        } else {
          this.license_sel_frm_mode = 'category_' + next_step
        }
        this.selected_license_category_no = next_step
      },
      async next_license_category() {
        if (this.selected_license_category_no == 3) {
          this.license_sel_frm_mode = 'insurance'
        } else if (this.selected_license_category_no < 5) {
          const cnt =
            this.selected_license_category_no == 2
              ? this.selected_license_category_no + 2
              : this.selected_license_category_no + 1
          this.license_sel_frm_mode = 'category_' + cnt
        } else if (this.selected_license_category_no == 5) {
          this.license_sel_frm_mode = 'category_3'
        }
        this.selected_license_category_no = ''
      },
      // 選択or再選択のとき、それに伴う選択した項目をクリアする処理
      async attach_license_sel_category_wrap_2(sel_cate, no) {
        // ライセンス上書き用
        // let wrap_variant = [];
        const under_license_category = {
          1: [2, 3, 4, 5],
          2: [3, 4, 5],
          4: [3, 5],
          5: [3],
          3: [],
        }
        const wrap_variant = this.selected_variants.filter((variant) => {
          if (
            typeof under_license_category[sel_cate] === 'object' &&
            under_license_category[sel_cate].includes(
              parseInt(variant.license_category)
            )
          ) {
            return true
          }
          return false
        })
        this.selected_variants = wrap_variant
        this.have_variants = wrap_variant

        await this.attach_license_sel_category_wrap(sel_cate, no)
      },
      async attach_license_sel_category_wrap(sel_cate, no) {
        if (sel_cate == 0) {
          // 1,2,4,5,3
        }
        await this.attach_license_sel_category(sel_cate, no)
      },
      async attach_license_sel_category(sel_cate, no) {
        // sel_cate:0は申請しない場合の処理
        if (sel_cate == 0) {
          if (no == 3) {
            this.license_sel_frm_mode = 'insurance'
            // xxxinsuranceを選択するときの処理
            // await this.go_insurance();
          } else if (no < 5) {
            const cnt = no == 2 ? no + 2 : no + 1
            this.license_sel_frm_mode = 'category_' + cnt
          } else if (no == 5) {
            this.license_sel_frm_mode = 'category_3'
          } else {
            this.license_sel_frm_mode = 'year'
          }
          this.chk_license_category[no] = sel_cate
        } else {
          if (no > 0 && no <= 5) {
            this.license_sel_frm_mode = 'category_' + no
          }
          this.chk_license_category[no] = sel_cate
        }
      },
      async attach_nonsel_license_category(sel_cate) {
        const tmp = [...this.nonsel_license_category]
        if (tmp.includes(sel_cate)) {
          const idx = tmp.indexOf(sel_cate)
          tmp.splice(idx, 1)
        } else {
          tmp.push(sel_cate)
          await this.attach_clear_license_category(sel_cate)
        }
        this.nonsel_license_category = tmp
      },
      async attach_clear_license_category(sel_cate) {
        const sel_vari = [...this.selected_variants].filter(
          (variant) => {
            // ピットクルーを解除する場合、エンジョイがあればそちらも同時に解除
            if(
              parseInt(sel_cate) == 2
              && [2, 3].includes(parseInt(variant.license_category))
            ){
              return false
            }
            else if(variant.license_category != sel_cate){
              return true
            }
            console.log('attach_clear_license_category sel_cate throgh', sel_cate)
            return false
          }
        )

        const sel_have = [...this.have_variants].filter(
          (variant) => {
            // ピットクルーを解除する場合、エンジョイがあればそちらも同人い解除
            if(
              parseInt(sel_cate) == 2
              && [2, 3].includes(parseInt(variant.license_category))
            ){
              return false
            }
            else if(variant.license_category != sel_cate){
              return true
            }
            return false
          }
        )
        this.selected_variants = sel_vari
        this.have_variants = sel_have

        // 保険必要ライセンスを選択している&保険を選択していない
        // 保険商品が既に入ってるかチェック
        const have_insurance_variant = sel_vari.find(
          (sel_var) => sel_var.category == 1
        )
          ? true
          : false
        // 保険必要競技か否か
        const insurance_flg = sel_vari.find(
          (sel_var) => sel_var.insurance_flg == 1
        )
          ? true
          : false
        if (insurance_flg == false && have_insurance_variant == true) {
          await this.attach_clear_insurance_commission()
        }
      },
      async attach_license_sel_variant(sel_variant, no) {
        console.log('attach_license_sel_variant', sel_variant, no)
      },

      async answer_enquate() {
        this.isLoading = true
        try {
          await new Promise(resolve => setTimeout(resolve, 500))
          const params = {}
        if (
          typeof this.enquate_sheet_obj === 'object' &&
          this.enquate_sheet_obj.length > 0
        ) {
          params.member_enquate = []
          await Promise.all(
            this.enquate_sheet_obj.map(async (sheet) => {
              if (
                typeof sheet === 'object' &&
                typeof sheet.enquate_data === 'object'
              )
                await Promise.all(
                  sheet.enquate_data.map(async (enquate) => {
                    const answers = {
                      id: 0,
                      stat: 1,
                      m_id: parseInt((this.regist_obj || {}).m_id),
                      sheet_id: sheet.id,
                      enquate_id: enquate.id,
                      value:
                        enquate.enquate_type == 400
                          ? JSON.stringify(enquate.answers)
                          : enquate.answers,
                    }
                    params.member_enquate.push({ ...answers })
                  })
                )
            })
          )
        } else {
          params.member_enquate = false
        }

        let result = false

        console.log('answer_enquate params', params)
        await this.nonloginPosts_I(
          config.BASE_API_URL + '/member/edit/' + (this.regist_obj || {}).m_id,
          params
        )
          .then(async (response) => {
            console.log('answer_enquate response', response)
            result = response.data && response.data.status == 200 ? true : false
          })
          .catch(async (err) => {
            console.log('err', err)
            return false
          })
          .finally(async () => {
            if (result) {
              this.regist_obj.fixed = '1'
              await this.update_regist()
              await this.update_app_check(440)
            }
            this.$forceUpdate()
          })
        } finally {
          this.isLoading = false
        }
      },
      async loadMerchantObj(target_merchant) {
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/merchant/' + target_merchant,
          {}
        )
          .then(async (response) => {
            console.log('loadMerchantObj', response)
            this.merchant_obj =
              response.data && response.data.body ? response.data.body : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.merchant_obj = {}
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadEnquateSheetObj() {
        const params = {}
        params['stat'] = [1]
        params['id'] = 1
        console.log('loadEnquateSheetObj params', params)
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/enquate_sheet/lists',
          params
        )
          .then(async (response) => {
            console.log('loadEnquateSheetObj response', response)
            this.enquate_sheet_obj =
              response.data && response.data.body && response.data.body
                ? response.data.body.map((sheet) => {
                    const enquate_data = sheet.enquate_data.map((enquate) => {
                      // 複数選択（400）の場合は配列として初期化
                      enquate.answers = enquate.enquate_type == 400 ? [] : ''
                      // 回答を保持するオブジェクトも初期化
                      this.enquate_answers[enquate.id] =
                        enquate.enquate_type == 400 ? [] : ''
                      return enquate
                    })
                    sheet.enquate_data = enquate_data
                    return sheet
                  })
                : []
          })
          .catch(async (err) => {
            console.log(err)
            this.enquate_sheet_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadMemberObj(m_id) {
        const params = {}
        params['stat'] = [1]
        params['id'] = m_id
        console.log('loadMemberObj params', params)
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/member/lists',
          params
        )
          .then(async (response) => {
            console.log('loadMemberObj response', response)
            this.member_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.member_obj = {}
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadArticleVariant() {
        const params = {}
        params['stat'] = [1]
        params['article_stat'] = [1]
        params['article_category_ids'] = [1, 2, 3]
        // params['article_']
        // カテゴリ
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/article_variant/lists',
          params
        )
          .then(async (response) => {
            console.log('loadArticleVariant response', response)
            this.article_variant_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.article_variant_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadArticle() {
        const params = {}
        params['stat'] = [1]
        params['category_ids'] = [1, 2, 3]
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/article/lists',
          params
        )
          .then(async (response) => {
            console.log('loadArticle response', response)
            this.article_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.article_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async sel_variant_year(variant_id, year_key, parent_flg) {
        console.log('sel_variant_year variant_id', variant_id);
        const variant = this.article_variant_obj.find(
          (vr) => vr.id == variant_id
        )
        // console.log('sel_variant_year variant', variant);
        // const variant_insurance_flg = variant.insurance_flg
        //   ? variant.insurance_flg
        //   : 0
        const draft_orders =
          typeof this.draft_order_obj === 'object'
            ? this.draft_order_obj.find(
                (draft_order) => draft_order.variant_id == variant_id
              )
            : false
        const tmp_variant = {
          variant_id: variant_id,
          price: variant.default_price,
          license_id: variant.license_id,
          sub_license_id: variant.sub_license_id,
          license_event_cd: variant.license_event_cd,
          license_label: variant.license_label,
          article_label: variant.article_label,
          sub_label: variant.sub_label,
          description: variant.description,
          sports_year_add: variant.sports_year_add,
          category: variant.category,
          license_category: variant.license_category,
          insurance_flg: variant.insurance_flg,
          join_3hour: variant.join_3hour,
          join_seminar_web: variant.join_seminar_web,
          join_seminar_circuit: variant.join_seminar_circuit,
          join_parent_frm: variant.join_parent_frm,
          join_parent_accept: variant.join_parent_accept,
          movie_no: variant.movie_no,
          movie_sec_num: variant.movie_sec_num,
          sports_year: this.get_list_label(year_key, this.race_sports_year_obj),

          draft_order_id: draft_orders ? draft_orders.id : 0,

          join_3hour_circuit_id: draft_orders
            ? draft_orders.join_3hour_circuit_id
            : '',
          join_3hour_circuit_license_no: draft_orders
            ? draft_orders.join_3hour_circuit_license_no
            : '',
          join_3hour_circuit_license_file: draft_orders
            ? draft_orders.join_3hour_circuit_license_file
            : '',

          join_3hour_circuit_chk_req_date: draft_orders
            ? draft_orders.join_3hour_circuit_chk_req_date
            : '',
          join_3hour_circuit_chk_req_manage_id: draft_orders
            ? draft_orders.join_3hour_circuit_chk_req_manage_id
            : '',
          join_3hour_circuit_chk_ans_signature: draft_orders
            ? draft_orders.join_3hour_circuit_chk_ans_signature
            : '',
          join_3hour_circuit_chk_ans_data_1: draft_orders
            ? draft_orders.join_3hour_circuit_chk_ans_data_1
            : '',
          join_3hour_circuit_chk_ans_data_2: draft_orders
            ? draft_orders.join_3hour_circuit_chk_ans_data_2
            : '',
          join_3hour_circuit_chk_ans_date: draft_orders
            ? draft_orders.join_3hour_circuit_chk_ans_date
            : '',

          join_discount_check_file: draft_orders
            ? draft_orders.join_discount_check_file
            : '',
          join_seminar_type: draft_orders ? draft_orders.join_seminar_type : '',
          join_seminar_place: draft_orders
            ? draft_orders.join_seminar_place
            : '',
          join_seminar_date: draft_orders ? draft_orders.join_seminar_date : '',
        }

        // 保険必要が既に入ってるかチェック
        // const before_insurance_flg = this.selected_variants.find(
        //   (sel_var) => sel_var.insurance_flg != 0
        // )
        //   ? true
        //   : false
        if (parent_flg == false && this.get_license_sel_variant(variant_id, year_key)) {
          const tmp_sel_v = [...this.selected_variants]
          const tmp_hav_v = [...this.have_variants]
          const idx = tmp_sel_v.findIndex(
            (vr) =>
              vr.variant_id == variant_id && vr.sports_year_add == year_key
          )
          tmp_sel_v.splice(idx, 1)
          this.selected_variants = tmp_sel_v
          const idxx = tmp_hav_v.findIndex(
            (vr) =>
              vr.variant_id == variant_id && vr.sports_year_add == year_key
          )
          tmp_hav_v.splice(idxx, 1)
          this.have_variants = tmp_hav_v
        } else if (
          parent_flg == false && 
          variant.license_id > 0 && this.get_license_sel_variant_f(variant_id, year_key, variant.license_id)
        ) {
          const tmp_sel_v = [...this.selected_variants]
          const tmp_hav_v = [...this.have_variants]
          const idx = tmp_sel_v.findIndex(
            (vr) =>
              vr.license_id == variant.license_id && vr.sports_year_add == year_key
          )
          tmp_sel_v.splice(idx, 1)
          this.selected_variants = tmp_sel_v
          const idxx = tmp_hav_v.findIndex(
            (vr) =>
              vr.license_id == variant.license_id && vr.sports_year_add == year_key
          )
          tmp_hav_v.splice(idxx, 1)
          this.have_variants = tmp_hav_v
        } else{
          this.selected_variants.push(tmp_variant)
          this.have_variants.push(tmp_variant)
        }
      },

      async attach_clear_insurance_commission() {
        const sel_vari = [...this.selected_variants].filter(
          (variant) => variant.category != 1 && variant.category != 2
        )
        const sel_have = [...this.have_variants].filter(
          (variant) => variant.category != 1 && variant.category != 2
        )
        this.selected_variants = sel_vari
        this.have_variants = sel_have
      },
      async attach_insurance() {
        // variantの保険部分取得
        // 競技ライセンスには保険・手数料が必須となるため
        const variants = this.article_variant_filter_lc_and_year_and_acate(1)

        await Promise.all(
          this.selected_year.map(async (year) => {
            await Promise.all(
              variants
                .filter((variant) => variant.sports_year_add == year)
                .map(async (variant) => {
                  return await this.sel_variant_year(
                    variant.variant_id,
                    variant.sports_year_add,
                    false
                  )
                })
            )
          })
        )
        
        // 親権者がいる場合
        if(this.parent_frm_disp){   
          // 親権者の保険を取得
          const parent_variants = this.get_article_variant_insurance
          .filter((variant) => {
            if (
              this.selected_year.includes(
                parseInt(variant.sports_year_add)
              ) &&
              (variant.over_age == 0 ||
                (variant.over_age >= 15 && variant.over_age <= 64)
              ) &&
              (variant.under_age == 0 ||
                (variant.under_age >= 15 && variant.over_age <= 64)
              ) &&
              parseInt(this.get_now_date_num) >=
                parseInt(variant.start_date_num) &&
              parseInt(this.get_now_date_num) <=
                parseInt(variant.end_date_num) &&
              ((parseInt(this.selected_package_flg) == 0 &&
                parseInt(variant.special_cd) != 100) ||
                (parseInt(this.selected_package_flg) == 1 &&
                  parseInt(variant.special_cd) == 100))
            ) {
              return true
            }
            return false
          })
          // 親権者チェック
          await Promise.all(
            this.selected_year.map(async (year) => {
              await Promise.all(
                parent_variants
                  .filter((variant) => variant.sports_year_add == year)
                  .map(async (variant) => {
                    return await this.sel_variant_year(
                      variant.variant_id,
                      variant.sports_year_add,
                      true
                    )
                  })
              )
            })
          )
        }
      },
      async attach_commission() {
        // variantの手数料部分取得
        // 競技ライセンスには保険・手数料が必須となるため
        const variants = this.article_variant_filter_lc_and_year_and_acate(2)
        await Promise.all(
          this.selected_year.map(async (year) => {
            await Promise.all(
              variants
                .filter((variant) => variant.sports_year_add == year)
                .map(async (variant) => {
                  return await this.sel_variant_year(
                    variant.variant_id,
                    variant.sports_year_add,
                    false
                  )
                })
            )
          })
        )

        // 親権者がいる場合
        if(this.parent_frm_disp){   
          // 親権者の手数料を取得
          const parent_variants = this.get_article_variant_commision
          .filter((variant) => {
            if (
              this.selected_year.includes(
                parseInt(variant.sports_year_add)
              ) &&
              (variant.over_age == 0 ||
                (variant.over_age > 8 && variant.over_age <= 64)
              ) &&
              (variant.under_age == 0 ||
                (variant.under_age > 8 && variant.over_age <= 99)
              ) &&
              parseInt(this.get_now_date_num) >=
                parseInt(variant.start_date_num) &&
              parseInt(this.get_now_date_num) <=
                parseInt(variant.end_date_num) &&
              ((parseInt(this.selected_package_flg) == 0 &&
                parseInt(variant.special_cd) != 100) ||
                (parseInt(this.selected_package_flg) == 1 &&
                  parseInt(variant.special_cd) == 100))
            ) {
              return true
            }
            return false
          })
          // 親権者チェック
          await Promise.all(
            this.selected_year.map(async (year) => {
              await Promise.all(
                parent_variants
                  .filter((variant) => variant.sports_year_add == year)
                  .map(async (variant) => {
                    return await this.sel_variant_year(
                      variant.variant_id,
                      variant.sports_year_add,
                      true
                    )
                  })
              )
            })
          )
        }
      },
      async sel_license(license_id, license_category) {
        // selected_package_flg
        if (
          typeof this.selected_year === 'object' &&
          this.selected_year.length > 0
        ) {
          // license_category1,2,4,5,3の順で戻るとそれ以降の選択をクリアする
          const selvar = [...this.selected_variants]
          console.log('sel_license license_id ', license_id);
          console.log('sel_license selvar ', selvar);

          // 1(競技ライセンス)を選択解除した時、全部消す処理を実装
          if(
            license_category == 1 &&
            selvar.filter((v) =>
              v.license_category == license_category
            )?.length != 1 && 
            selvar.find((v) =>
              v.license_id == license_id
            )
          ){
            if (
              window.confirm(
                'そのライセンスを解除すると、選択内容がクリアされます。よろしいですか？'
              )
            ) {
              this.selected_variants = []
              this.have_variants = []
              return true
            } else {
              return true
            }
          }
          // ピットクルー(PIT)解除時、エンジョイ(ENJOY)を保持していたら同時に消す
          // (※)注意↓のPITに選択処理も合わせて確認
          // (※)attach_nonsel_license_categoryとattach_clear_license_categoryのmethodも注意
          else if(
            license_category == 2 && 
            selvar.find((v) =>
              v.license_category == license_category
            ) && 
            selvar.find((v) =>
              [3].includes(parseInt(v.license_category))
            )
          ){
            if(
              window.confirm('エンジョイライセンスはピットクルー自動付帯のため、同時に解除されます')
            ){
              this.selected_variants = [...selvar.filter(
                (v) => ![2, 3].includes(parseInt(v.license_category))
              )]
              this.have_variants = [...selvar.filter(
                (v) => ![2, 3].includes(parseInt(v.license_category))
              )]
            }
            return true
          }
          // エンジョイ(ENJOY)解除時、ピットクルー(PIT)保持していたらアラートを出す
          else if(
            license_category == 3 && 
            selvar.find((v) =>
              v.license_category == license_category
            ) &&
            selvar.find((v) =>
              v.license_category == 2
            )
          ){
            alert('エンジョイライセンスはピットクルー自動付帯のため、解除できません。')
            return true
          }
          // 競技役員・講師解除時、競技役員・講師を保持していたらアラートを出して消す
          else if(
            [4, 5].includes(parseInt(license_category)) && 
            selvar.find((v) =>
              v.license_category == license_category
            ) && 
            selvar.find((v) =>
              v.license_id == license_id
            )
          ){
            if(
              window.confirm('競技役員・講師ライセンスを解除するとクリアし、料金が再計算されます')
            ){
              this.selected_variants = [...selvar.filter(
                (v) => ![4, 5].includes(parseInt(v.license_category))
              )]
              this.have_variants = [...selvar.filter(
                (v) => ![4, 5].includes(parseInt(v.license_category))
              )]
            }
            return true
          }

          const tmpvar = 
            // 1(競技ライセンス)を新たに選択した時、その他のカテゴリ(2,4,5,3)がある場合はそれらを消す
            license_category == 1 &&
            selvar.find((v) =>
              [2, 4, 5, 3].includes(parseInt(v.license_category))
            )
            ? [...selvar].filter(
                (v) => ![2, 4, 5, 3].includes(parseInt(v.license_category))
              )
            : // 2(ピット(PIT)クルー)を選択した時、4,5,3を消す
              // (※pitクルー単体を選択した場合、ENJOY(エンジョイ)を自動で選択させているのに注意)
            license_category == 2 &&
              selvar.find((v) =>
                // [4, 5, 3].includes(parseInt(v.license_category))
                [4, 5].includes(parseInt(v.license_category))
              )
            ? [...selvar].filter(
                // (v) => ![4, 5, 3].includes(parseInt(v.license_category))
                (v) => ![4, 5].includes(parseInt(v.license_category))
              )
            : // 4(競技役員)を選択した時、
            license_category == 4 &&
              (
              // 5(講師)に加えピットクルー(PIT)&エンジョイ(ENJOY)もあれば5のみ
              // selvar.find((v) =>
              //   [5, 3, 2].includes(parseInt(v.license_category))
              // )
              // || 
              // 5(講師)もあれば削除
              selvar.find((v) =>
                [5].includes(parseInt(v.license_category))
              )
              )
            ? [...selvar].filter(
                (v) => ![5].includes(parseInt(v.license_category))
              )
            // : // 5を選択した時はなにもしない
            // license_category == 5 &&
            //   (
            //   selvar.find((v) => [3].includes(parseInt(v.license_category)))
            //   )
            // ? [...selvar].filter(
            //     (v) => ![3].includes(parseInt(v.license_category))
            //   )
            : false
          // console.log('sel_license license_id ', license_id)
          // console.log('sel_license license_category ', license_category)
          // console.log('sel_license tmpvar ', tmpvar)

          if (
            tmpvar !== false &&
            window.confirm(
              'そのライセンスを選択すると、以降の選択内容がクリアされます。よろしいですか？'
            )
          ) {
            this.selected_variants = [...tmpvar]
            this.have_variants = [...tmpvar]
          } else if (tmpvar !== false) {
            return true
          }

          if (this.chk_nonsel_license_category(license_category)) {
            await this.attach_nonsel_license_category(license_category)
          }
          // first_select_main,first_select_sub
          // これらでsubとmainで最初に選んだのを変更する時のおかしな動きを

          const years = [...this.selected_year]
          // const variants = this.article_variant_obj.filter(variant => variant.license_id == license_id);
          const variants = [
            ...this.article_variant_filter_lc_and_year_and_lcate(
              license_category
            ),
          ].filter((variant) => variant.license_id == license_id)
          console.log('sel_license sel_variant_year variant_id variants check', variants)
          if (this.selected_package_flg > 0) {
            await Promise.all(
              variants.map(async (variant) => {
                await Promise.all(
                  variant.package_variant_ids.map(
                    async (variant_id, index_y) => {
                      return await this.sel_variant_year(variant_id, index_y, false)
                    }
                  )
                )
              })
            )
          } else {
            await Promise.all(
              years.map(async (year) => {
                await Promise.all(
                  variants
                    .filter((variant) => variant.sports_year_add == year)
                    .map(async (variant) => {
                      console.log(
                        'sel_license filter variants variant',
                        variant
                      )
                      console.log('sel_license variants year', year)
                      return await this.sel_variant_year(
                        variant.variant_id,
                        variant.sports_year_add,
                        false
                      )
                    })
                )
              })
            )
          }
          // 保険必要ライセンスを選択している&保険を選択していない
          // 保険商品が既に入ってるかチェック
          const have_insurance_variant = this.selected_variants.find(
            (sel_var) => sel_var.category == 1
          )
            ? true
            : false
          // 保険必要競技か否か
          const insurance_flg = this.selected_variants.find(
            (sel_var) => sel_var.insurance_flg == 1
          )
            ? true
            : false
          // console.log(
          //   'chk sel_license have_insurance_variant',
          //   have_insurance_variant
          // )
          // console.log(
          //   'chk sel_license selected_variants.filter(v=>v.category==1',
          //   this.selected_variants.filter((v) => v.category == 1)
          // )
          // console.log('chk sel_license insurance_flg', insurance_flg)
          // console.log(
          //   'chk sel_license selected_variants.filter(v=>v.insurance_flg==1',
          //   this.selected_variants.filter((v) => v.insurance_flg == 1)
          // )
          // 保険・手数料追加処理
          if (insurance_flg == true && !have_insurance_variant) {
            await this.attach_insurance()
            await this.attach_commission()
          } else if (insurance_flg == false && have_insurance_variant == true) {
            await this.attach_clear_insurance_commission()
          }
          // pitクルー単体を選択した場合、ENJOY(エンジョイ)を自動で選択させる
          // license_category:2 license_class:4
          if(
            parseInt(license_category) == 2 && 
            typeof this.article_variant_filter_lc_and_year_and_lcate(3) === 'object'
            && this.article_variant_filter_lc_and_year_and_lcate(3).length > 0 
            && this.article_variant_filter_lc_and_year_and_lcate(3)[0]
          ){
            await this.sel_license(this.article_variant_filter_lc_and_year_and_lcate(3)[0].license_id, 3)
          }
          this.$forceUpdate()
        }
      },

      // 未使用
      async sel_variant(variant_id) {
        if (
          typeof this.selected_year === 'object' &&
          this.selected_year.length > 0
        ) {
          const years = [...this.selected_year]
          await Promise.all(
            years.map(async (year) => {
              return await this.sel_variant_year(variant_id, year, false)
            })
          )
        }
      },

      async selectedFile(e, type_cd, path, variant_id) {
        this.isLoading = true
        try {
          await new Promise(resolve => setTimeout(resolve, 500))

          e.preventDefault()
          const files = [...e.target.files]
          if(files.length === 0) return;
          // let ups = [];
          files.forEach(async (file) => {
            const form = new FormData()
            form.append('file', file)
            await this.nonloginPosts_R(
              config.BASE_API_URL + '/upload/member_assets',
              form
            )
              .then((res) => {
                console.log(res)
                if (
                  res &&
                  res.status == 200 &&
                  path != false &&
                  variant_id == false
                ) {
                  this.data_obj['path' + path] = res.data.body.file_path
                    ? res.data.body.file_path
                    : ''
                } else if (
                  res &&
                  res.status == 200 &&
                  path == false &&
                  variant_id != false
                ) {
                  const v_index = this.selected_variants.findIndex(
                    (variant) => variant.variant_id == variant_id
                  )
                  const tmp_variant = v_index
                    ? this.selected_variants[v_index]
                    : false
                  if (tmp_variant) {
                    tmp_variant[type_cd] = res.data.body.file_path
                      ? res.data.body.file_path
                      : ''
                    this.selected_variants[v_index] = tmp_variant
                  }
                }
              })
              .catch((error) => {
                console.log(error)
              })
              .finally(() => {
                if (path != false && variant_id == false) {
                  document.getElementById(
                    'file_up_input_' + type_cd + '_path' + path
                  ).value = ''
                } else if (path == false && variant_id != false) {
                  document.getElementById(
                    'file_up_input_' + type_cd + '_variant_' + variant_id
                  ).value = ''
                }
                e.target.value = '';
                this.$forceUpdate()
              })
          })
        } finally {
          this.isLoading = false
        }
      },
      async clickUpFileBtn(type, path) {
        document
          .getElementById('file_up_input_' + type + '_path' + path)
          .click()
      },
      async clickUpFileVBtn(type, variant_id) {
        document
          .getElementById('file_up_input_' + type + '_variant_' + variant_id)
          .click()
      },
      async postal_cd_chk() {
        const tmp = (this.data_obj || {}).postal_cd
          ? (this.data_obj || {}).postal_cd.match(/([0-9-])/g)
          : ''
        // console.log('postal_cd_chk tmp', typeof tmp, tmp);
        this.data_obj.postal_cd =
          tmp != null && typeof tmp === 'object' ? tmp.join('') : ''
        // console.log('postal_cd_chk postal_cd', this.app_form_obj.postal_cd);
      },
      async get_address_param() {
        const postal_cd = this.data_obj.postal_cd
        await this.get_address(postal_cd)
      },
      async get_address(postal_cd) {
        await this.basicGets_O(
          'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + postal_cd
        )
          .then((res) => {
            if (
              typeof res === 'object' &&
              typeof res.status === 'number' &&
              res.status == 200 &&
              typeof res.data === 'object' &&
              typeof res.data.results === 'object' &&
              typeof res.data.results[0] === 'object'
            ) {
              this.data_obj.pref_cd = res.data.results[0].prefcode
                // parseInt(res.data.results[0].prefcode) < 10
                //   ? '0' + res.data.results[0].prefcode
                //   : res.data.results[0].prefcode
              this.data_obj.address_1 =
                res.data.results[0].address2 + res.data.results[0].address3
              this.data_obj.address_2 = ''
            } else {
              alert('住所データがありません')
            }
          })
          .catch((error) => {
            console.log(error)
            alert('住所データがありません.')
          })
          .finally(() => {
            this.$forceUpdate()
          })
      },
      async chk_birth_date() {
        this.isLoading = true
        try {
          if (
            !this.data_obj.birth_date_year ||
            !this.data_obj.birth_date_month ||
            !this.data_obj.birth_date_day
          ) {
            alert('生年月日を入力してください')
            this.isLoading = false
            return true
          }
          const birthYear = Number(this.data_obj.birth_date_year);
          const birthMonth = String(Number(this.data_obj.birth_date_month)).padStart(2, '0');
          const birthDay = String(Number(this.data_obj.birth_date_day)).padStart(2, '0');
          // const birthYear = parseInt(this.data_obj.birth_date_year, 10);
          // const birthMonth = parseInt(this.data_obj.birth_date_month, 10);
          // const birthDay = parseInt(this.data_obj.birth_date_day, 10);

          
          // if (!birthYear || !birthMonth || !birthDay) {
          //   alert('生年月日を入力してください')
          //   this.isLoading = false
          //   return true
          // }
          // if (birthMonth < 1 || birthMonth > 12 || birthDay < 1 || birthDay > 31) {
          //     alert("生年月日が正しくありません。");
          //     this.isLoading = false
          //     return;
          // }
          const chk_date_str = `${birthYear}-${birthMonth}-${birthDay}`;
          const birthDateValid = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(chk_date_str);

          // `moment` を使って有効な日付かを確認
          const momentDate = moment(chk_date_str, 'YYYY-MM-DD', true);
          const birthDateValid2 = momentDate.isValid();

          // 生年月日のバリデーションメッセージ
          if (!chk_date_str || !birthDateValid || !birthDateValid2) {
            alert('生年月日が正しくありません');
            this.isLoading = false
            return true;
          }

          // 画面遷移の直前でisLoadingをfalseにする処理を削除
          await this.proceed('license_sel')
          // ページ遷移後にローディングを解除
          setTimeout(() => {
            this.isLoading = false
          }, 500)

        } catch (error) {
          console.error(error)
          this.isLoading = false
        }
      },
      async proceed_birthdate_check(mode, useLoading = true){
        if(
          this.isBirthDateValid
        ){
          await this.proceed(mode, useLoading)
        }
        else{
          alert('生年月日データが不正です。生年月日データを入力し直して下さい')
          await this.proceed('license')
        }
      },
      async proceed(mode, useLoading = true) { // useLoadingパラメータを追加
        if (useLoading) {
          this.isLoading = true
        }
        try {
          await new Promise(resolve => setTimeout(resolve, 500))

          this.page_mode = mode
          if (mode == 'license_sel') {
            this.message = 'ライセンスを選択してください。'
          } else if (mode == 'profile') {
            this.message = '個人情報を入力してください。'

            const tmp = { ...this.data_obj }
            tmp.race_sports_year = this.selected_year[0]
            tmp.mail_1 = (this.regist_obj || {}).mail_address
            this.data_obj = tmp
            console.log('proceed', this.data_obj, tmp)
          }
          Common.moveToTop()
        } finally {
          if (useLoading) {
            this.isLoading = false
          }
        }
      },
      async back(mode) {
        this.isLoading = true
        try {
          await new Promise(resolve => setTimeout(resolve, 500))

          this.message_class = 'info'
          this.page_mode = mode
          if (mode == 'license_sel') {
            this.message = 'ライセンスを選択してください。'
          } else if (mode == 'form' || mode == 'profile') {
            this.message = '個人情報を入力してください。'
          } else if (mode == 'license') {
            this.have_variants = []
            this.selected_variants = []
            this.selected_year = []
            this.message = '下記項目を選択、入力してください。'
          }
          Common.moveToTop()
        } finally {
          this.isLoading = false
        }
      },
      async chk_app() {
        this.isLoading = true
        try {
          const loadingPromise = new Promise(resolve => setTimeout(resolve, 500))

          if (!this.formIsValid) {
            await loadingPromise
            alert(this.validationMessage)
            this.isLoading = false
            return
          }

          await loadingPromise
          console.log('chk_app', this.data_obj)
          await this.update_regist()
          await this.update_app()
          await this.proceed('mfj_check', false) // proceedを直接呼び出し、ローディング制御はスキップ
          return true
        } catch (error) {
          console.error(error)
          this.isLoading = false
        }
      },
      async go_app() {
        this.proceed('mfj_check')
        return true
      },
      async update_app() {
        console.log('component methods', this.data_obj)

        const post_url_path =
          this.application_obj.id > 0
            ? '/member_application/edit/' + this.application_obj.id
            : '/member_application/add'

        const params = {
          application_check_type:
            (this.application_obj || {}).application_check_type == 600
              ? 120
              : 100,
          application_type: 1000,
          m_id: 0,
          regist_id: this.regist_obj.id,
          // application_type: this.upd_obj.application_check_type,
          // application_check_type: this.upd_obj.application_check_type,
          // items: [],
        }

        const item_column = {
          login_id: 'mfj_member_data@login_id',
          login_pass: 'mfj_member_data@login_pass',
          sports_year: 'mfj_outdb_data@sports_year',
          // mfj_member_data@birth_date="生年月日"
          birth_date: 'mfj_member_data@birth_date',
          // birth_date_year: split_date_1[0],
          // birth_date_month: split_date_1[1],
          // birth_date_day: split_date_1[2],
          // mfj_outdb_data@drive_license_chk="運転免許証の有無"
          drive_license_chk: 'mfj_outdb_data@drive_license_chk',
          // mfj_member_data@name_1="姓"
          name_1: 'mfj_member_data@name_1',
          // mfj_member_data@name_2="名"
          name_2: 'mfj_member_data@name_2',
          // mfj_member_data@yomi_1="セイ"
          yomi_1: 'mfj_member_data@yomi_1',
          // mfj_member_data@yomi_2="メイ"
          yomi_2: 'mfj_member_data@yomi_2',
          // mfj_outdb_data@drive_license_no="運転免許証No"
          drive_license_no: 'mfj_outdb_data@drive_license_no',
          // mfj_outdb_data@rf_circuit_name="サーキット名"
          circuit_id: 'mfj_member_circuit_license@circuit_id',
          circuit_license_no: 'mfj_member_circuit_license@circuit_license_no',
          // mfj_outdb_data@rf_circuit_name="サーキット名"
          // rf_circuit_name: 'mfj_outdb_data@rf_circuit_name',
          // mfj_outdb_data@rf_license_no="ライセンス番号"
          // rf_license_no: 'mfj_outdb_data@rf_license_no',
          // mfj_member_profile@sex_type="性別"
          sex_type: 'mfj_member_data@sex_type',
          // mfj_member_profile@tel_2="携帯・日中連絡先"
          tel_2: 'mfj_member_profile@tel_2',
          // tel_2_0: tel_2[0],
          // tel_2_1: tel_2[1],
          // tel_2_2: tel_2[2],
          // mfj_member_profile@tel_1="自宅電話番号"
          tel_1: 'mfj_member_profile@tel_1',
          // tel_1_0: tel_1[0],
          // tel_1_1: tel_1[1],
          // tel_1_2: tel_1[2],
          teen_chk: 'mfj_outdb_data@teen_chk',
          parent_name_1: 'mfj_member_parent@name_1',
          parent_name_2: 'mfj_member_parent@name_2',
          // mfj_member_parent@yomi_1="セイ"
          parent_yomi_1: 'mfj_member_parent@yomi_1',
          // mfj_member_parent@yomi_2="メイ"
          parent_yomi_2: 'mfj_member_parent@yomi_2',
          // mfj_member_parent@birth_date="生年月日"
          parent_birth_date: 'mfj_member_parent@birth_date',
          parent_sex_type: 'mfj_member_parent@sex_type',
          // parent_birth_date_year: split_date_p[0],
          // parent_birth_date_month: split_date_p[1],
          // parent_birth_date_day: split_date_p[2],
          // mfj_outdb_data@parent_drive_license_chk="運転免許証の有無"
          parent_parent_drive_license_chk:
            'mfj_outdb_data@parent_drive_license_chk',
          // mfj_outdb_data@parent_drive_license_no="運転免許証No"
          parent_parent_drive_license_no:
            'mfj_outdb_data@parent_drive_license_no',
          // mfj_member_profile@postal_cd="郵便番号"
          postal_cd: 'mfj_member_profile@postal_cd',
          // mfj_member_profile@pref_ccd="都道府県"
          pref_cd: 'mfj_member_profile@pref_cd',
          // mfj_member_profile@address_1="市区町村・番地"
          address_1: 'mfj_member_profile@address_1',
          // mfj_member_profile@address_2="建物名・室号"
          address_2: 'mfj_member_profile@address_2',
          // mfj_outdb_data@parent_drive_license_chk="運転免許証の有無"
          parent_drive_license_chk: 'mfj_outdb_data@parent_drive_license_chk',
          // mfj_outdb_data@web_training_id="Web講習終了ID"
          web_training_id: 'mfj_outdb_data@web_training_id',
          // mfj_member_profile@mail_1="メールアドレス1"
          mail_1: 'mfj_member_profile@mail_1',
          // mfj_member_profile@mail_2="メールアドレス2"
          mail_2: 'mfj_member_profile@mail_2',
          mail_2_chk: 'mfj_member_profile@mail_2_chk',
          // mfj_member_profile@mail_flg="メール配信"
          mail_flg: 'mfj_member_profile@mail_flg',
          m_path_type: 'mfj_outdb_data@m_path_type',
          parent_path_type: 'mfj_outdb_data@parent_path_type',
          parent_mfj_license: 'mfj_member_parent@license_no',

          // 過去ライセンス保有
          past_license_chk: 'mfj_outdb_data@past_license_chk',
          // 過去ライセンスNo
          past_license_no: 'mfj_outdb_data@past_license_no',

          path1: 'mfj_member_assets@path1',
          path2: 'mfj_member_assets@path2',
          path3: 'mfj_member_assets@path3',
          path4: 'mfj_member_assets@path4',
          path5: 'mfj_member_assets@path5',
          path6: 'mfj_member_assets@path6',
          path10: 'mfj_member_assets@path10',
          path11: 'mfj_member_assets@path11',
        }
        const items = []
        Object.keys(item_column).forEach((item_key) => {
          // console.log('item_column_chk item_key', item_key, [
          //   this.data_obj.birth_date_year,
          //   parseInt(this.data_obj.birth_date_month) - 1,
          //   this.data_obj.birth_date_day,
          // ])

        const birthYear = Number(this.data_obj.birth_date_year);
        const birthMonth = String(Number(this.data_obj.birth_date_month)).padStart(2, '0');
        const birthDay = String(Number(this.data_obj.birth_date_day)).padStart(2, '0');
        const chk_date_str = `${birthYear}-${birthMonth}-${birthDay}`;

        const PbirthYear = Number(this.data_obj.birth_date_year);
        const PbirthMonth = String(Number(this.data_obj.birth_date_month)).padStart(2, '0');
        const PbirthDay = String(Number(this.data_obj.birth_date_day)).padStart(2, '0');
        const Pchk_date_str = `${PbirthYear}-${PbirthMonth}-${PbirthDay}`;
        
          const target_application_value =
            item_key == 'tel_1'
              ? this.data_obj.tel_1
              : item_key == 'tel_2'
              ? this.data_obj.tel_2
              : item_key == 'parent_birth_date'
              ? moment(Pchk_date_str).format('YYYY-MM-DD')
              : // ) ? this.data_obj.parent_birth_date_year + '-' + this.data_obj.parent_birth_date_month + '-' + this.data_obj.parent_birth_date_day
              item_key == 'birth_date'
              ? moment(chk_date_str).format('YYYY-MM-DD')
              : this.data_obj[item_key]
          items.push({
            id: this.get_target_app_item(item_column[item_key])
              ? (this.get_target_app_item(item_column[item_key]) || {}).id
              : 0,
            application_id: this.application_obj.id,
            application_key: item_column[item_key],
            application_value: target_application_value,
          })
        })
        // tel_1
        // parent_birth_date
        console.log('items', items)
        // birth_date
        params.items =
          typeof items === 'object' && items.length > 0 ? items : false
        
        // draft_order
        const draft_orders = []
        if (
          typeof this.selected_variants === 'object' &&
          this.selected_variants.length > 0
        ) {
          this.selected_variants.forEach((variant) => {
            draft_orders.push({
              id: variant.draft_order_id,
              stat: 1,
              application_id: 0,
              variant_id: variant.variant_id,
              sports_year: variant.sports_year,
              unit_price: variant.price,
              num: 1,
              list_price: parseInt(variant.price) * 1,
              join_3hour_circuit_id: variant.join_3hour_circuit_id,
              join_3hour_circuit_license_no:
                variant.join_3hour_circuit_license_no,
              join_3hour_circuit_license_file:
                variant.join_3hour_circuit_license_file,
              join_discount_check_file: variant.join_discount_check_file,
              join_seminar_type: variant.join_seminar_type,
              join_seminar_place: variant.join_seminar_place,
              join_seminar_date: variant.join_seminar_date,
            })
          })
        }
        console.log('draft_orders', draft_orders)
        params.draft_orders = draft_orders

        await this.nonloginPosts_I(config.BASE_API_URL + post_url_path, params)
          .then(async (response) => {
            console.log('update', response)
          })
          .catch(async (err) => {
            console.log(err)
          })
          .finally(async () => {
            await this.go_app()
            this.$forceUpdate()
          })
      },
      async update_regist() {
        const params = this.regist_obj
        return await this.nonloginPosts_I(
          config.BASE_API_URL + '/member_regist/edit/' + this.regist_obj.id,
          params
        )
          .then(async (response) => {
            console.log('update_regist!!!', response)
            if (response.data && response.data.status == 200) {
              return true
            } else {
              return false
            }
          })
          .catch(async (err) => {
            console.log('err', err)
            return false
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadRegistData() {
        const params = {}
        params['stat'] = [1]
        params['token'] = this.$route.query.token
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/member_regist/lists',
          params
        )
          .then(async (response) => {
            console.log('loadRegistData!!!', response)
            if (response.data && response.data.status == 200) {
              this.regist_obj =
                response.data && response.data.body && response.data.body[0]
                  ? response.data.body[0]
                  : {}
              // if(this.regist_obj && this.regist_obj.app_data){
              // await this.loadApplicationObj(this.regist_obj.app_data.id);
              // }
              console.log(
                'loadRegistData!!! app_data',
                this.regist_obj.app_data
              )
              await this.setRegistObj()
            } else {
              this.page_mode = 'error'
              this.message_class = 'danger'
            console.log('errorR', response)
            }
          })
          .catch(async (err) => {
            this.page_mode = 'error'
            this.message_class = 'danger'
            this.message = err
            console.log('error123', err)
          })
          .finally(async () => {
            await this.setApplicationObj(false)
            await this.setDraftOrderObj()
            await this.setSalesObj()
            // await this.setRegistObj()
            this.$forceUpdate()
          })
      },
      async setRegistObj() {
        this.application_obj =
          this.regist_obj && this.regist_obj.app_data
            ? this.regist_obj.app_data
            : {}

        this.communication_obj =
          this.application_obj &&
          this.application_obj.communications &&
          this.application_obj.communications[0]
            ? this.application_obj.communications[0]
            : {}
        this.communication_checked =
          this.application_obj && this.application_obj.checked
            ? this.application_obj.checked
            : []
        console.log(
          'loadRegistData!!! (this.regist_obj || {}).mail_address',
          (this.regist_obj || {}).mail_address
        )
        console.log(
          'loadRegistData!!! application_value',
          (this.get_target_app_item('mfj_member_profile@mail_1') || {})
            .application_value
        )
        console.log(
          'loadRegistData!!! typeof this.application_obj ',
          typeof this.application_obj
        )
        console.log(
          'loadRegistData!!! typeof this.application_obj.items ',
          typeof this.application_obj.items
        )
        if (
          (this.regist_obj || {}).mail_address &&
          ((this.get_target_app_item('mfj_member_profile@mail_1') || {})
            .application_value == '' ||
            (this.get_target_app_item('mfj_member_profile@mail_1') || {})
              .application_value == null) &&
          typeof this.application_obj === 'object' &&
          typeof this.application_obj.items === 'object'
        ) {
          const items = this.application_obj.items
          console.log('loadRegistData regist mail in')
          const idx = items.findIndex(
            (item) => item.application_key == 'mfj_member_profile@mail_1'
          )
          if (items[idx]) {
            items[idx].application_value = (this.regist_obj || {}).mail_address
          }
          this.application_obj.items = items
        }
        //決済エラーで戻ってきた場合
        if ((this.application_obj || {}).application_check_type == 403) {
          // ここでもう一度appをチェックして、決済もチェックして問題ないか確認させる
          const sales = (
          typeof (this.application_obj || {}).sales === 'object' &&
              Object.keys(this.application_obj.sales).length > 0
          )? { ...(this.application_obj || {}).sales }: false
          const sales_id = (sales)? sales.id: 0
          const sparams = {};
          sparams['stat'] = [1];
          sparams['id'] = sales_id;
          const success_check = await this.basicPosts_S(
              config.BASE_API_URL + '/sales/success_check',
              sparams
          )
          .then(
              async (response) => {
                  console.log('success_check response', response)
                  return (response?.data?.body)? response.data.body: false
              }
          )
          .catch(
              async (err) => {
              console.log(err);
              return false
          })
          console.log('403 check success_check', success_check);
          if(!success_check){
            await this.update_app_check(400)
            this.page_mode = 'charge'
            this.message_class = 'danger'
            await this.loadMemberObj(parseInt((this.regist_obj || {}).m_id))
          }else if(success_check){
              const chkTYpe = (sales.charge_type == 'CREDIT')
              ? 423
              : (sales.charge_type == 'KONBINI')?
                  426
              : 423;
              await this.update_app_check(chkTYpe)
              return
          }


          // await this.update_app_check(400)
          // this.page_mode = 'charge'
          // this.message_class = 'danger'
          // await this.loadMemberObj(parseInt((this.regist_obj || {}).m_id))
        } else if (
          (this.application_obj || {}).application_check_type == 423 || 
          (this.application_obj || {}).application_check_type == 426
        ) {
          await this.loadMemberObj(parseInt((this.regist_obj || {}).m_id))
          await this.setSalesObj()
          await this.setDraftOrderObj()
          await this.member_license_update()
          await this.update_app_check(430)
          return true
        } else if (
          (this.application_obj || {}).application_check_type == 100 ||
          (this.application_obj || {}).application_check_type == 120 ||
          (this.application_obj || {}).application_check_type == 200 ||
          (this.application_obj || {}).application_check_type == 300 ||
          (this.application_obj || {}).application_check_type == 340
        ) {
          this.page_mode = 'mfj_check'
        } else if (
          (this.application_obj || {}).application_check_type == 500 &&
          parseInt((this.regist_obj || {}).m_id) > 0
        ) {
          this.page_mode = 'success'
          this.message_class = 'success'
        } else if (
          (this.application_obj || {}).application_check_type == 420 &&
          (this.regist_obj || {}).m_id > 0
        ) {
          this.page_mode = 'cash_unpaid'
          this.message_class = 'success'
        } else if (
          (this.application_obj || {}).application_check_type == 430 &&
          (this.regist_obj || {}).m_id > 0
        ) {
          this.page_mode = 'enquate_check'
          this.message_class = 'success'
        } else if (
          (this.application_obj || {}).application_check_type == 440 &&
          (this.regist_obj || {}).m_id > 0
        ) {
          this.page_mode = 'mfj_approval'
          this.message_class = 'success'
        } else if (
          (this.application_obj || {}).application_check_type == 500 &&
          (this.regist_obj || {}).m_id == 0
        ) {
          this.page_mode = 'mfj_approval'
          this.message_class = 'success'
        }
        // test用
        else if (
          (this.application_obj || {}).application_check_type == 601 &&
          (this.regist_obj || {}).m_id == 0
        ) {
          this.page_mode = 'license_sel'
          this.message_class = 'info'
          // this.message = '申請データに不備があります。' + this.communication_obj.comment;
        } else if (
          (this.application_obj || {}).application_check_type == 600 &&
          (this.regist_obj || {}).m_id == 0
        ) {
          this.page_mode = 'profile'
          this.message_class = 'danger'
          this.message =
            '申請データに不備があります。' + this.communication_obj.comment
        } else if ((this.application_obj || {}).application_check_type == 900) {
          this.page_mode = 'mfj_reject'
          this.message_class = 'danger'
        } else if (
          (this.application_obj || {}).application_check_type == 400 &&
          parseInt((this.regist_obj || {}).m_id) > 0
        ) {
          this.page_mode = 'charge'
          this.message_class = 'success'
          await this.loadMemberObj(parseInt((this.regist_obj || {}).m_id))
        } else {
          this.message =
            '下記項目を選択、入力して取得可能なライセンスから選択してください。'
        }
        this.$forceUpdate()
      },
      async setSalesObj() {
        if (
          typeof (this.application_obj || {}).sales === 'object' &&
          Object.keys(this.application_obj.sales).length > 0
        ) {
          const tmps = { ...(this.application_obj || {}).sales }
          this.sales_obj = { ...tmps }
          console.log(
            'setSalesObj sales_obj',
            this.sales_obj
          )
          if(
            this.sales_obj?.cash_konbini_code && this.sales_obj.cash_receipt_number && this.sales_obj.cash_confirmation_number
          ){
            const receipt_tmp = {
              konbiniCode: this.sales_obj?.cash_konbini_code,
              receiptNumber: this.sales_obj?.cash_receipt_number,
              confirmationNumber: this.sales_obj?.cash_confirmation_number
            }
            this.receipt = {...receipt_tmp}
          }

          if(
            (this.sales_obj || {}).charge_order_id.endsWith('K')
          ){
            this.selected_charge_type = 'cash'
          }
          this.$forceUpdate()
        }
      },
      async setDraftOrderObj() {
        if (
          typeof (this.application_obj || {}).draft_orders === 'object' &&
          Object.keys(this.application_obj.draft_orders).length > 0
        ) {
          const tmps = [...(this.application_obj || {}).draft_orders]
          this.draft_order_obj = [...tmps]
          // 年度
          const tmp_year = []

          await Promise.all(
            tmps.map(async (draft_order) => {
              if (
                tmp_year.includes(
                  parseInt(
                    this.get_list_key(
                      draft_order.sports_year,
                      this.race_sports_year_obj
                    )
                  )
                ) === false
              ) {
                tmp_year.push(
                  parseInt(
                    this.get_list_key(
                      draft_order.sports_year,
                      this.race_sports_year_obj
                    )
                  )
                )
              }
              return await this.sel_variant_year(
                draft_order.variant_id,
                draft_order.sports_year,
                false
              )
            })
          )
          this.selected_year = tmp_year
          this.attach_license_sel_category(0, 3)
          this.$forceUpdate()
        }
      },
      async setApplicationObj(test_created_flg) {
        const tmps =
          typeof this.application_obj === 'object' &&
          (this.application_obj || {}).id
            ? this.application_obj
            : {
                id: 0,
                application_check_type: '',
                application_type: '',
                application_date: '',
              }
        this.application_obj = tmps

        const tel_1 = []
        const tel_2 = []

        const tel_1_cnt =
          typeof (
            (this.get_target_app_item('mfj_member_profile@tel_1') || {})
              .application_value || ''
          ).split('-') === 'object'
            ? (
                (this.get_target_app_item('mfj_member_profile@tel_1') || {})
                  .application_value || ''
              ).length
            : 0
        const tel_2_cnt =
          typeof (
            (this.get_target_app_item('mfj_member_profile@tel_2') || {})
              .application_value || ''
          ).split('-') === 'object'
            ? (
                (this.get_target_app_item('mfj_member_profile@tel_2') || {})
                  .application_value || ''
              ).length
            : 0
        for (let i = 0; i < 3; i++) {
          if (tel_1_cnt <= i) {
            tel_1.push('')
          } else {
            tel_1.push(
              (
                (this.get_target_app_item('mfj_member_profile@tel_1') || {})
                  .application_value || ''
              ).split('-')[i]
            )
          }
          if (tel_2_cnt <= i) {
            tel_2.push('')
          } else {
            tel_2.push(
              (
                (this.get_target_app_item('mfj_member_profile@tel_2') || {})
                  .application_value || ''
              ).split('-')[i]
            )
          }
        }

        // const regex1 = /^[0-9]{4}[/-](0[1-9]|1[0-2])[/-](0[1-9]|[12][0-9]|3[01])/;
        const date_1_obj = (
          this.get_target_app_item('mfj_member_data@birth_date') || {}
        ).application_value
          ? moment(
              (this.get_target_app_item('mfj_member_data@birth_date') || {})
                .application_value
            )
          : false
        const date_p_obj = (
          this.get_target_app_item('mfj_member_parent@birth_date') || {}
        ).application_value
          ? moment(
              (this.get_target_app_item('mfj_member_parent@birth_date') || {})
                .application_value
            )
          : false

        const split_date_1 = date_1_obj
          ? [
              date_1_obj.format('Y'),
              date_1_obj.format('M'),
              date_1_obj.format('D'),
            ]
          : this.get_base_env == 'development'? ['2000', '2', '29']: ['2000', '', '']
        const split_date_p = date_p_obj
          ? [
              date_p_obj.format('Y'),
              date_p_obj.format('M'),
              date_p_obj.format('D'),
            ]
          : ['', '', '']

        if(test_created_flg){
          // test
          const test_login_id = Math.random().toString(32).substring(2) + '1234'
          const tmp = {
            login_id: test_login_id,
            login_pass: test_login_id + '!',
            sports_year: '2024',
            birth_date: this.data_obj.birth_date,
            birth_date_year: this.data_obj.birth_date_year,
            birth_date_month: this.data_obj.birth_date_month,
            birth_date_day: this.data_obj.birth_date_day,
            name_1: '石破',
            name_2: '茂',
            yomi_1: 'イシバ',
            yomi_2: 'シゲル',
            // drive_license_no: '',
            past_license_chk: 0,
            past_license_no: '',
            sex_type: 'male',
            tel_1: '09067675657',
            tel_2: '',
            teen_chk: '',
            postal_cd: '6128433',
            pref_cd: '27',
            address_1: '京都府京都市伏見区',
            address_2: '',
            web_training_id: '',
            mail_1: this.data_obj.mail_1,
            // mail_2: '',
            // mail_2_chk: '',
            mail_flg: 1,
            m_path_type: 10,
            path1: '----.jpg',
            path2: '----.jpg',
            path3: '----.jpg',
          }
          this.data_obj = tmp
        }else{
          this.data_obj = {
            login_id: (this.get_target_app_item('mfj_member_data@login_id') || {})
              .application_value,
            login_pass: (
              this.get_target_app_item('mfj_member_data@login_pass') || {}
            ).application_value,
            sports_year: (
              this.get_target_app_item('mfj_outdb_data@sports_year') || {}
            ).application_value,
            // mfj_member_data@birth_date="生年月日"
            birth_date: (
              this.get_target_app_item('mfj_member_data@birth_date') || {}
            ).application_value,
            birth_date_year: split_date_1[0],
            birth_date_month: split_date_1[1],
            birth_date_day: split_date_1[2],
            // mfj_outdb_data@drive_license_chk="運転免許証の有無"
            drive_license_chk: (
              this.get_target_app_item('mfj_outdb_data@drive_license_chk') || {}
            ).application_value,
            // mfj_member_data@name_1="姓"
            name_1: (this.get_target_app_item('mfj_member_data@name_1') || {})
              .application_value,
            // mfj_member_data@name_2="名"
            name_2: (this.get_target_app_item('mfj_member_data@name_2') || {})
              .application_value,
            // mfj_member_data@yomi_1="セイ"
            yomi_1: (this.get_target_app_item('mfj_member_data@yomi_1') || {})
              .application_value,
            // mfj_member_data@yomi_2="メイ"
            yomi_2: (this.get_target_app_item('mfj_member_data@yomi_2') || {})
              .application_value,
            // mfj_outdb_data@drive_license_no="運転免許証No"
            drive_license_no: (
              this.get_target_app_item('mfj_outdb_data@drive_license_no') || {}
            ).application_value,

            past_license_chk: (
              this.get_target_app_item('mfj_outdb_data@past_license_chk') || {}
            ).application_value
              ? (
                  this.get_target_app_item('mfj_outdb_data@past_license_chk') ||
                  {}
                ).application_value
              : 0,
            past_license_no: (
              this.get_target_app_item('mfj_outdb_data@past_license_no') || {}
            ).application_value,
            // circuit_id: (this.get_target_app_item('mfj_member_circuit_license@circuit_id') || {}).application_value,
            // circuit_license_no: (this.get_target_app_item('mfj_member_circuit_license@circuit_license_no') || {}).application_value,

            // mfj_outdb_data@rf_circuit_name="サーキット名"
            // rf_circuit_name: (this.get_target_app_item('mfj_outdb_data@rf_circuit_name') || {}).application_value,
            // // mfj_outdb_data@rf_license_no="ライセンス番号"
            // rf_license_no: (this.get_target_app_item('mfj_outdb_data@rf_license_no') || {}).application_value,

            // mfj_member_profile@sex_type="性別"
            sex_type: (this.get_target_app_item('mfj_member_data@sex_type') || {})
              .application_value,
            // mfj_member_profile@tel_2="携帯・日中連絡先"
            tel_2:
              (this.get_target_app_item('mfj_member_profile@tel_2') || {})
                .application_value || '',
            // mfj_member_profile@tel_1="自宅電話番号"
            tel_1:
              (this.get_target_app_item('mfj_member_profile@tel_1') || {})
                .application_value || '',
            // mfj_outdb_data@tel_1="自宅電話番号"
            // tel_1_: (this.get_target_app_item('mfj_outdb_data@tel_1') || {}).application_value,
            // mfj_outdb_data@teen_chk="親権者"
            teen_chk: (this.get_target_app_item('mfj_outdb_data@teen_chk') || {})
              .application_value,
            // mfj_member_parent@teen_chk="親権者"
            // teen_chk_: (this.get_target_app_item('mfj_member_parent@teen_chk') || {}).application_value,
            // mfj_member_parent@name_1="姓"

            parent_name_1: (
              this.get_target_app_item('mfj_member_parent@name_1') || {}
            ).application_value,
            // mfj_member_parent@name_2="名"
            parent_name_2: (
              this.get_target_app_item('mfj_member_parent@name_2') || {}
            ).application_value,
            // mfj_member_parent@yomi_1="セイ"
            parent_yomi_1: (
              this.get_target_app_item('mfj_member_parent@yomi_1') || {}
            ).application_value,
            // mfj_member_parent@yomi_2="メイ"
            parent_yomi_2: (
              this.get_target_app_item('mfj_member_parent@yomi_2') || {}
            ).application_value,
            // mfj_member_parent@birth_date="生年月日"
            parent_birth_date: (
              this.get_target_app_item('mfj_member_parent@birth_date') || {}
            ).application_value,
            parent_birth_date_year: split_date_p[0],
            parent_birth_date_month: split_date_p[1],
            parent_birth_date_day: split_date_p[2],
            parent_sex_type: (
              this.get_target_app_item('mfj_member_parent@sex_type') || {}
            ).application_value,
            // mfj_outdb_data@parent_drive_license_chk="運転免許証の有無"
            parent_parent_drive_license_chk: (
              this.get_target_app_item(
                'mfj_outdb_data@parent_drive_license_chk'
              ) || {}
            ).application_value,
            // mfj_outdb_data@parent_drive_license_no="運転免許証No"
            parent_parent_drive_license_no: (
              this.get_target_app_item(
                'mfj_outdb_data@parent_drive_license_no'
              ) || {}
            ).application_value,
            // mfj_member_profile@postal_cd="郵便番号"
            postal_cd: (
              this.get_target_app_item('mfj_member_profile@postal_cd') || {}
            ).application_value,
            // mfj_member_profile@pref_ccd="都道府県"
            pref_cd: (
              this.get_target_app_item('mfj_member_profile@pref_cd') || {}
            ).application_value,
            // mfj_member_profile@address_1="市区町村・番地"
            address_1: (
              this.get_target_app_item('mfj_member_profile@address_1') || {}
            ).application_value,
            // mfj_member_profile@address_2="建物名・室号"
            address_2: (
              this.get_target_app_item('mfj_member_profile@address_2') || {}
            ).application_value,
            // mfj_outdb_data@parent_drive_license_chk="運転免許証の有無"
            parent_drive_license_chk: (
              this.get_target_app_item(
                'mfj_outdb_data@parent_drive_license_chk'
              ) || {}
            ).application_value,
            // mfj_outdb_data@web_training_id="Web講習終了ID"
            web_training_id: (
              this.get_target_app_item('mfj_outdb_data@web_training_id') || {}
            ).application_value,
            // mfj_member_profile@mail_1="メールアドレス1"
            mail_1: (this.get_target_app_item('mfj_member_profile@mail_1') || {})
              .application_value,
            // mfj_member_profile@mail_2="メールアドレス2"
            mail_2: (this.get_target_app_item('mfj_member_profile@mail_2') || {})
              .application_value,
            mail_2_chk: (
              this.get_target_app_item('mfj_member_profile@mail_2_chk') || {}
            ).application_value,
            // mfj_member_profile@mail_flg="メール配信"
            mail_flg: (
              this.get_target_app_item('mfj_member_profile@mail_flg') || {}
            ).application_value,

            m_path_type: (
              this.get_target_app_item('mfj_outdb_data@m_path_type') || {}
            ).application_value,
            parent_path_type: (
              this.get_target_app_item('mfj_outdb_data@parent_path_type') || {}
            ).application_value,

            path1: (this.get_target_app_item('mfj_member_assets@path1') || {})
              .application_value,
            path2: (this.get_target_app_item('mfj_member_assets@path2') || {})
              .application_value,
            path3: (this.get_target_app_item('mfj_member_assets@path3') || {})
              .application_value,
            path4: (this.get_target_app_item('mfj_member_assets@path4') || {})
              .application_value,
            path5: (this.get_target_app_item('mfj_member_assets@path5') || {})
              .application_value,
            path6: (this.get_target_app_item('mfj_member_assets@path6') || {})
              .application_value,

            path10: (this.get_target_app_item('mfj_member_assets@path10') || {})
              .application_value,
            path11: (this.get_target_app_item('mfj_member_assets@path11') || {})
              .application_value,
          }
          
        }
      },
      async loadCircuitList() {
        const params = {}
        params['stat'] = [1]
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/circuit/lists',
          params
        )
          .then(async (response) => {
            console.log('loadCircuitList response', response)
            this.circuit_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.circuit_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadRaceSportsYear() {
        const params = {}
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/race_sports_year/lists',
          params
        )
          .then(async (response) => {
            console.log('loadRaceSportsYear response', response)
            this.race_sports_year_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.race_sports_year_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadTermYear() {
        const params = {}
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/term_year/lists',
          params
        )
          .then(async (response) => {
            console.log('loadTermYear response', response)
            this.term_year_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.term_year_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadTermDate() {
        const params = {}
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/term_date/lists',
          params
        )
          .then(async (response) => {
            console.log('loadTermDate response', response)
            this.term_date_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.term_date_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadNowDate() {
        const params = {}
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/now_date/lists',
          params
        )
          .then(async (response) => {
            console.log('loadNowDate response', response)
            this.now_date_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.now_date_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadLicenseCdList() {
        const params = {}
        params['stat'] = [1]
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/license_cd/lists',
          params
        )
          .then(async (response) => {
            console.log('loadLicenseCdList response', response)
            this.license_cd_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.license_cd_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      // back(){
      // }
      // パスワード表示切り替え
      togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible
      },
    },
    computed: {
      isBirthDateValid() {
        const year = parseInt(this.data_obj.birth_date_year, 10);
        const month = parseInt(this.data_obj.birth_date_month, 10);
        const day = parseInt(this.data_obj.birth_date_day, 10);

        return year && month && day && month >= 1 && month <= 12 && day >= 1 && day <= 31;
      },
      // 選択肢の表示on/off
      // chk_sports_license
      // chk_pit_license
      // chk_enjoy_license
      // 競技ライセンスボタン表示
      disp_key_btn_category_1: function () {
        // 年度さえ選択されていれば表示ON
        return typeof this.selected_year === 'object' &&
          this.selected_year.length > 0
          ? true
          : false
      },
      disp_key_btn_category_2_2024: function(){
        if(
          this.get_term_year == 2024 && 
          this.selected_package_flg == 0 && 
          // this.selected_year.find(year => parseInt(year) === 0) === 0 && 
          (
            // this.selected_year.includes(parseInt(0)) === false || 
            (this.selected_year.find(year => parseInt(year) === 0) === 0 && this.chk_sports_license)
          ) && 
          this.disp_key_btn_category_1
        ){
          return true
        }
        return false
      },
      // PITクルーライセンスボタン表示
      // ptn:OFF/競技が選択されているのでOFF/競技が申請されていないのでON
      // ※2024年度のみ特殊で、競技と合わせて申請できる
      disp_key_btn_category_2: function () {
        if(
          this.disp_key_btn_category_2_2024
        ){
          return true
        }
        else{
          // 競技が選択されていればNG
          return this.disp_key_btn_category_1 &&
            this.chk_nonsel_license_category(1) &&
            !this.chk_sports_license
            ? true
            : false
        }
      },
      // 競技役員ライセンスボタン表示
      disp_key_btn_category_4: function () {
        // 年度さえ選択されていれば表示ON
        return this.chk_sports_license ||
          (this.disp_key_btn_category_2 &&
            (this.chk_pit_license || this.chk_nonsel_license_category(2)))
          ? true
          : (this.chk_sel_have_license_category(4))
          ? true
          : false
      },
      // 講師ライセンスボタン表示
      disp_key_btn_category_5: function () {
        // 年度さえ選択されていれば表示ON
        return this.disp_key_btn_category_4 &&
          ((this.chk_nonsel_license_category(4) && !this.chk_officer_license) ||
            this.chk_officer_license)
          ? true
          : (this.chk_sel_have_license_category(5))
          ? true
          : false
      },
      // エンジョイライセンスボタン表示
      disp_key_btn_category_3: function () {
        // 年度さえ選択されていれば表示ON
        return this.disp_key_btn_category_5 &&
          ((this.chk_nonsel_license_category(5) &&
            !this.chk_instructor_license) ||
            this.chk_instructor_license)
          ? true
          : (this.chk_sel_have_license_category(3))
          ? true
          : false
      },
      ...mapState({}),
      get_payer: function () {
        const payer_obj = {}
        // payer_obj.name =
          // (this.data_obj || {}).name_1 + (this.data_obj || {}).name_2
        payer_obj.name =
          (this.data_obj || {}).yomi_1 + (this.data_obj || {}).yomi_2
        payer_obj.nameKana =
          (this.data_obj || {}).yomi_1 + (this.data_obj || {}).yomi_2
        payer_obj.phoneNumber = (this.data_obj || {}).tel_1
        const phone_tmp = {}
        phone_tmp.number = (this.data_obj || {}).tel_1
        phone_tmp.countryCode = '81'
        if (
          typeof (this.data_obj || {}).tel_1 === 'string' &&
          ((this.data_obj || {}).tel_1.startsWith('090') ||
            (this.data_obj || {}).tel_1.startsWith('080') ||
            (this.data_obj || {}).tel_1.startsWith('070'))
        ) {
          phone_tmp.type = 'MOBILE'
        } else {
          phone_tmp.type = 'MOBILE'
        }
        payer_obj.phones = []
        payer_obj.phones.push(phone_tmp)
        console.log('testtest payer_obj', payer_obj)
        return payer_obj
      },
      get_merchant: function () {
        const merchant_obj = { ...this.merchant_obj }
        merchant_obj.callbackUrl = config.GMO_CALLBACK_URL + '/regist/3ds/'
        merchant_obj.webhookUrl = config.GMO_WEBHOOK_URL
        // merchant_obj.callbackUrl = 
          // config.BASE_FRONTEND_URL + '/mfj_renewal/app/regist/3ds/'
        // merchant_obj.webhookUrl = config.BASE_FRONTEND_URL

        return merchant_obj
      },
      get_order: function () {
        const order = {
          orderId: (this.sales_obj || {}).charge_order_id,
          amount: (this.sales_obj || {}).amount,
          currency: 'JPY',
          // clientFields: [],
          // items: [],
        }
        if (
          typeof (this.sales_obj || {}).sales_orders === 'object' &&
          (this.sales_obj || {}).sales_orders.length > 0
        ) {
          order.items = []
          this.sales_obj.sales_orders.map((sales_order) => {
            order.items.push({
              name: sales_order.sub_label,
              quantity: sales_order.num,
              type: 'DIGITAL',
              price: sales_order.list_price,
            })
          })
        }
        return order
      },
      check_age: function(){
        if(
          this.get_sports_age(0) !== false 
          && this.get_school_age(0) !== false 
          && !isNaN(this.get_school_age(0))
          && this.get_sports_age(0) > 0 
          && this.get_school_age(0) > 0
        ){
          return true
        }
        return false
      },
      get_sports_age: function(){
        const now_year = parseInt(this.get_now_date_num_y)
        const now_mon = parseInt(this.get_now_date_num_m)
        const birth_year = parseInt(this.data_obj?.birth_date_year)
        return function(year_add){
          // 4-12月は年度に応じて
          if(birth_year > 0 && now_mon>=4 && now_mon<=12){
            return (now_year + year_add) - birth_year
          }
          else if(birth_year > 0 && now_mon<=3 && now_mon>=1){
            return (now_year) - birth_year
          }
          return 0
        }
      },
      get_school_age: function () {
        const term_year = parseInt(this.get_term_year)
        const birth_date = moment([
            this.data_obj.birth_date_year,
            parseInt(this.data_obj.birth_date_month) - 1,
            this.data_obj.birth_date_day,
          ]).format('YYYY-MM-DD')
        return function(year_add){
          const set_year = parseInt(term_year) + parseInt(year_add)
          const birth = new Date(birth_date)
          const target = new Date(set_year + '-04-01')
          let age = target.getFullYear() - birth.getFullYear()
          const monthDiff = target.getMonth() - birth.getMonth()
          const dayDiff = target.getDate() - birth.getDate()
          if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--
          }
          return parseInt(age)
        }
      },
      get_age: function () {
        if (
          this.data_obj.birth_date_year &&
          this.data_obj.birth_date_month &&
          this.data_obj.birth_date_day
        ) {
          const birth_date = moment([
            this.data_obj.birth_date_year,
            parseInt(this.data_obj.birth_date_month) - 1,
            this.data_obj.birth_date_day,
          ]).format('YYYY-MM-DD')
          return this.calc_sports_age(birth_date)
        }
        return ''
      },
      charge_btn_display: function () {
        if (this.charge_mode && (this.member_obj || {}).id > 0) {
          return true
        }
        return false
      },
      credit_expire_y_list: function () {
        const nowY = parseInt(Common.getNowYear())
        const maxY = nowY + 15
        const tmps = []
        for (let y = nowY; y <= maxY; y++) {
          tmps.push({
            key: y,
            label: y,
          })
        }
        return tmps
      },
      enquate_sheet_list: function () {
        return this.enquate_sheet_obj
      },

      get_class_charge_type: function () {
        const selected_charge_type = this.selected_charge_type
        return function (charge_type) {
          return selected_charge_type == charge_type
            ? 'btn btn-warning'
            : 'btn btn-light btn-outline-secondary'
        }
      },
      
      // params: いずれか一致
      // r_params: 全一致
      // limit: 取得数
      get_selected_variants_filter: function () {
        const selected_variants = this.selected_variants
        return function (params, r_params, limit) {
          if (typeof selected_variants === 'object') {
            const tmpss = selected_variants.filter((variant) => {
              let ret = false
              if (typeof params === 'object') {
                Object.keys(params).forEach((key) => {
                  if (variant[key] == params[key]) {
                    ret = true
                  }
                })
              }
              return ret
            })
            // console.log('get_selected_variants_filter r_params', r_params)
            const tmps = (typeof r_params === 'object' && Object.keys(r_params).length > 0)? tmpss.filter((variant) => {
              let ret = true
              Object.keys(r_params).forEach((key) => {
                if (variant[key] != r_params[key]) {
                  ret = false
                }
              })
              return ret
            }): tmpss
            if (
              limit !== false &&
              typeof tmps === 'object' &&
              tmps.length >= limit
            ) {
              return tmps.splice(0, limit)
            } else {
              return tmps
            }
          }
          return false
        }
      },
      get_license_selected_variants_filter: function(){
        const get_filter = this.get_selected_variants_filter
        return function(params){
          const tmps = []
          get_filter(params, {}, false).forEach(variant => {
            if(variant.license_id > 0 && !tmps.includes(parseInt(variant.license_id))){
              tmps.push(parseInt(variant.license_id))
            }
          })
          return tmps
        }
      },
      get_term_year: function () {
        return typeof this.term_year_obj === 'object' &&
          typeof this.term_year_obj[0] === 'object' &&
          this.term_year_obj[0]['label']
          ? parseInt(this.term_year_obj[0]['label'])
          : parseInt(Common.getNowYear())
      },
      now_year: function(){
        return this.get_now_date_num_y
      },
      get_now_date: function () {
        return typeof this.now_date_obj === 'object' &&
          typeof this.now_date_obj[0] === 'object'
          ? this.now_date_obj[0]
          : false
      },
      get_now_date_num: function () {
        return typeof this.get_now_date === 'object'
          ? this.get_now_date.date_num
          : parseInt(moment().format('YYYYMMDD'))
      },
      get_now_date_num_y: function () {
        return typeof this.get_now_date === 'object'
          ? this.get_now_date.y
          : parseInt(moment().format('YYYY'))
      },
      get_now_date_num_m: function () {
        return typeof this.get_now_date === 'object'
          ? this.get_now_date.m
          : parseInt(moment().format('M'))
      },
      get_now_date_num_d: function () {
        return typeof this.get_now_date === 'object'
          ? this.get_now_date.m
          : parseInt(moment().format('D'))
      },
      get_term_date: function () {
        return typeof this.term_date_obj === 'object' &&
          typeof this.term_date_obj[0] === 'object'
          ? this.term_date_obj[0]
          : false
      },
      get_term_date_param: function () {
        const term_date = this.get_term_date
        return function (param) {
          if (term_date) {
            return parseInt(term_date[param])
          }
          return 0
        }
      },

      get_article_variant_insurance: function () {
        return this.article_variant_obj.filter(
          (variant) => variant.category == 1
        )
      },
      get_article_variant_insurance_age: function () {
        return this.article_variant_obj.filter(
          (variant) => variant.category == 1
        )
      },
      get_article_variant_commision: function () {
        return this.article_variant_obj.filter(
          (variant) => variant.category == 2
        )
      },
      get_article_variant_commision_year: function () {
        return this.article_variant_obj.filter(
          (variant) =>
            variant.category == 2 &&
            this.selected_year.includes(parseInt(variant.sports_year))
        )
      },
      chk_sub_license: function () {
        const selected_variants = this.selected_variants
        return function(license_id){
          if(
            Array.isArray(selected_variants) && selected_variants.find(variant => variant.sub_license_id == license_id)
          ){
            return true
          }
          return false
        }
      },
      chk_sports_license: function () {
        if (
          this.selected_variants.find(
            (variant) => variant.license_category == 1
          )
        ) {
          return true
        }
        return false
      },
      chk_pit_license: function () {
        if (
          this.selected_variants.find(
            (variant) => variant.license_category == 2
          )
        ) {
          return true
        }
        return false
      },
      chk_enjoy_license: function () {
        if (
          this.selected_variants.find(
            (variant) => variant.license_category == 3
          )
        ) {
          return true
        }
        return false
      },
      chk_officer_license: function () {
        if (
          this.selected_variants.find(
            (variant) => variant.license_category == 4
          )
        ) {
          return true
        }
        return false
      },
      chk_instructor_license: function () {
        if (
          this.selected_variants.find(
            (variant) => variant.license_category == 5
          )
        ) {
          return true
        }
        return false
      },

      article_variant_filter_year: function(){
        const get_now_date_num = this.get_now_date_num;
        const selected_package_flg = this.selected_package_flg;
        // const get_age = this.get_age;
        return this.article_variant_obj
          .filter(variant => {
              const target_age = this.get_sports_age(parseInt(variant.sports_year_add))
              if(
                  this.selected_year.includes(parseInt(variant.sports_year_add))
                  && (
                      variant.over_age == 0
                      || (
                          variant.over_age > 0
                          && variant.over_age <= target_age
                      )
                  )
                  && (
                      variant.under_age == 0
                      || (
                          variant.under_age > 0
                          && variant.under_age >= target_age
                      )
                  )
                  && parseInt(get_now_date_num) >= parseInt(variant.start_date_num)
                  && parseInt(get_now_date_num) <= parseInt(variant.end_date_num)
                  && (
                      (
                          parseInt(selected_package_flg) == 0
                          && parseInt(variant.special_cd) != 100
                      ) || (
                          parseInt(selected_package_flg) == 1
                          && parseInt(variant.special_cd) == 100
                      )
                  )
              ){
                  return true;
              }
              return false;
          });
      },
      _article_variant_filter_year_: function () {
        // const term_year_add = this.get_term_date_param('year_add')
        // const term_md = this.get_term_date_param('md')
        // const term_year = this.get_term_year
        return (
          this.article_variant_obj
            .filter((variant) => {
              if (
                this.selected_year.includes(
                  parseInt(variant.sports_year_add)
                ) &&
                // 年齢制限
                // 設定値以上の年齢
                // 設定しない(over_age==0)
                (variant.over_age == 0 ||
                  // 設定する(over_age>0)
                  (variant.over_age > 0 &&
                    (
                      // 競技ライセンス商品の場合、競技年齢
                      (variant.over_age <=
                      this.get_sports_age(variant.sports_year_add) && ![1,2].includes(parseInt(variant.category)))
                      || 
                      // 保険商品の場合、保険期間年度開始時の年齢
                      (variant.over_age <=
                      this.get_school_age(variant.sports_year_add) && ![3, 4, 5].includes(parseInt(variant.category)))
                    )
                  )
                ) &&
                // 設定値以下の年齢
                // 設定しない(under_age==0)
                (variant.under_age == 0 ||
                  // 設定する(under_age>0)
                  (variant.under_age > 0 &&
                    (
                      (variant.under_age >=
                      this.get_sports_age(variant.sports_year_add) && ![1,2].includes(parseInt(variant.category)))
                      ||
                      (variant.under_age >=
                      this.get_school_age(variant.sports_year_add) && ![3, 4, 5].includes(parseInt(variant.category)))
                    )
                  )
                ) &&
                parseInt(this.get_now_date_num) >=
                  parseInt(variant.start_date_num) &&
                parseInt(this.get_now_date_num) <=
                  parseInt(variant.end_date_num) &&
                ((parseInt(this.selected_package_flg) == 0 &&
                  parseInt(variant.special_cd) != 100) ||
                  (parseInt(this.selected_package_flg) == 1 &&
                    parseInt(variant.special_cd) == 100))
              ) {
                return true
              }
              return false
            })
        )
      },
      article_variant_filter_year_and_have_variants: function () {
        const variant_obj = this.article_variant_filter_year
        return variant_obj
          .filter((variant) => {
            if (
              variant.get_condition_flg == 0 ||
              (variant.get_condition_flg == 1 &&
                typeof variant.get_condition === 'object' &&
                variant.get_condition.find((cond) => {
                  if (
                    this.have_variants.find(
                      (vrnt) => vrnt.license_id == cond.license_id
                    )
                  ) {
                    return true
                  }
                  return false
                }))
            ) {
              return true
            }
            return false
          })

          .filter((variant) => {
            const license_cd = this.license_cd_obj.find(
              (license) => license.id == variant.license_id
            )
            console.log(
              'license_id license_id license_id ',
              license_cd,
              variant,
              this.license_cd_obj
            )
            if (!license_cd) {
              return true
            } else if (
              typeof license_cd.license_exclude !== 'object' ||
              (typeof license_cd.license_exclude === 'object' &&
                license_cd.license_exclude.length <= 0)
            ) {
              return true
            } else if (
              typeof license_cd.license_exclude === 'object' &&
              license_cd.license_exclude.find((license_exclude) => {
                if (
                  this.have_variants.find(
                    (vrnt) => vrnt.license_id == license_exclude.target_id
                  )
                ) {
                  return true
                }
                return false
              })
            ) {
              return false
            } else {
              return true
            }
          })

          .sort((a, b) => {
            // if(parseInt(a.sort) < parseInt(b.sort)){
            if (parseInt(a.default_price) < parseInt(b.default_price)) {
              return -1
            } else {
              return 1
            }
          })
      },

      article_variant_filter_lc_and_year: function () {
        const tmps = []
        const variant_obj = this.article_variant_filter_year_and_have_variants
        variant_obj.forEach((variant) => {
          if (
            !tmps.find((tmp) => tmp.license_id == variant.license_id) &&
            // variants 配列じゃない
            (typeof this.selected_variants !== 'object' ||
              // variants 配列 だけど空
              (typeof this.selected_variants === 'object' &&
                this.selected_variants.length <= 0) ||
              // variants 配列で既に何か入ってる
              (typeof this.selected_variants === 'object' &&
                this.selected_variants.length > 0 &&
                // そして、一致している
                (!this.selected_variants.find(
                  (selv) => selv.license_id == variant.license_id
                ) ||
                  (this.selected_variants.find(
                    (selv) => selv.license_id == variant.license_id
                  ) &&
                    this.selected_variants.find(
                      (selv) =>
                        selv.license_id == variant.license_id &&
                        variant.id == selv.variant_id
                    )))))
          ) {
            const price_list = [0, 0]
            price_list[variant.sports_year_add] = variant.default_price
            // price_list[variant.sports_year_add] = parseInt(variant.variant_id)
            const package_variant_ids = [0, 0]
            package_variant_ids[variant.sports_year_add] = variant.id
            const package_special_cd = [0, 0]
            package_special_cd[variant.sports_year_add] = variant.special_cd
            tmps.push({
              variant_id: variant.id,
              license_label: variant.license_label,
              license_id: variant.license_id,
              license_event_cd: variant.license_event_cd,
              license_category: variant.license_category,
              category: variant.category,
              default_price: variant.default_price,
              price_list: price_list,
              package_variant_ids: package_variant_ids,
              package_special_cd: package_special_cd,
              sports_year_add: variant.sports_year_add,
              sort: variant.sort,
            })
          } else if (tmps.find((tmp) => tmp.license_id == variant.license_id)) {
            const tmpsIndex = tmps.findIndex(
              (tmp) => tmp.license_id == variant.license_id
            )
            if(
              this.selected_variants.find(sval => sval.variant_id == variant.variant_id)
            ){
              tmps[tmpsIndex].price_list[variant.sports_year_add] = parseInt(this.selected_variants.find(sval => sval.variant_id == variant.variant_id)?.price)
            }
            else if (
              !this.selected_variants.find(sval => sval.variant_id == variant.variant_id) && 
              parseInt(variant.default_price) > 0 
              && tmps[tmpsIndex].price_list[variant.sports_year_add] == 0
              // && parseInt(tmps[tmpsIndex].price_list[variant.sports_year_add]) > parseInt(variant.default_price)
            ) {
              tmps[tmpsIndex].price_list[variant.sports_year_add] =
                parseInt(variant.default_price)
                // parseInt(variant.variant_id)
            }
            if (
              parseInt(variant.id) > 0 &&
              tmps[tmpsIndex].package_variant_ids[variant.sports_year_add] ==
                false
            ) {
              tmps[tmpsIndex].package_variant_ids[variant.sports_year_add] =
                variant.id
            }
          }
        })

        //
        const tmptmp =
          this.selected_package_flg > 0
            ? tmps.filter((tmp) => {
                if (
                  tmp.package_variant_ids.length > 1 &&
                  tmp.package_variant_ids.filter((vid) => parseInt(vid) > 0)
                    .length > 1
                ) {
                  return true
                }
                return false
              })
            : tmps

        return [...tmptmp]
      },
      chk_article_variant_filter_lc_and_year_and_lcate: function () {
        const get_func = this.article_variant_filter_lc_and_year_and_lcate
        return function (category_id) {
          if (
            typeof get_func(category_id) === 'object' &&
            get_func(category_id).length > 0
          ) {
            return true
          }
          return false
        }
      },
      article_variant_filter_lc_and_year_and_lcate: function () {
        const tmps = this.article_variant_filter_lc_and_year
        // console.log('article_variant_filter_lc_and_year_and_lcate tmps', tmps)
        return function (category_id) {
          // console.log(
          //   'article_variant_filter_lc_and_year_and_lcate function category_id',
          //   category_id
          // )
          // console.log(
          //   'article_variant_filter_lc_and_year_and_lcate function tmps',
          //   tmps
          // )
          // return tmps.filter(tmp => tmp.license_category == category_id);
          return tmps
            .filter((tmp) => tmp.license_category == category_id)
            .sort((a, b) => {
              // const priorityA = this.selected_variants?.find(variant => variant.variant_id == a.variant_id)
              // const priorityB = this.selected_variants?.find(variant => variant.variant_id == b.variant_id)
              // if(typeof priorityA === 'object' && typeof priorityB === 'object'){
              //   return -1
              // }
              // if(typeof priorityA !== 'object' && typeof priorityB === 'object'){
              //   return 1
              // }
              if (parseInt(a.sort) < parseInt(b.sort)) {
                return -1
              } else {
                return 1
              }
            })
        }
      },
      chk_article_variant_filter_lc_and_year_and_acate: function () {
        const get_func = this.article_variant_filter_lc_and_year_and_acate
        return function (category_id) {
          if (
            typeof get_func(category_id) === 'object' &&
            get_func(category_id).length > 0
          ) {
            return true
          }
          return false
        }
      },
      article_variant_filter_lc_and_year_and_acate: function () {
        const tmps = this.article_variant_filter_year
        console.log('article_variant_filter_lc_and_year_and_acate tmps', tmps)
        return function (category_id) {
          console.log(
            'article_variant_filter_lc_and_year_and_acate function category_id',
            category_id
          )
          console.log(
            'article_variant_filter_lc_and_year_and_acate function tmps',
            tmps.filter((tmp) => tmp.category == category_id)
          )
          return tmps.filter((tmp) => tmp.category == category_id)
        }
      },
      article_variant_filter_sel: function () {
        const variant_obj = this.article_variant_obj
        return function (category_no) {
          if (category_no == '') {
            return false
          } else {
            return variant_obj.filter(
              (variant) => variant.license_category == category_no
            )
          }
        }
      },
      article_variant_filter: function () {
        if (this.selected_license_category_no == '') {
          return false
        } else {
          return this.article_variant_obj.filter(
            (variant) =>
              variant.license_category == this.selected_license_category_no
          )
        }
      },
      article_variant_filter_target_license_category: function () {
        const article_variant = this.article_variant_obj
        return function (category_id) {
          return article_variant.filter(
            (variant) => variant.license_category == category_id
          )
        }
      },
      get_license_sel_package_class: function () {
        return this.selected_year.length >= 2
          ? 'checkbox02__label me-3 mb-3'
          : 'checkbox02__label me-3 mb-3 active'
      },
      get_license_sel_year_class: function () {
        const selected = this.selected_year
        return function (year_add) {
          if (selected.includes(year_add)) {
            return 'checkbox02__label me-3 mb-3'
          } else {
            return 'checkbox02__label me-3 mb-3 active'
          }
        }
      },
      // ※22
      get_variant: function(){
        const variants = this.article_variant_obj
        return function(variant_id, year_add){
          return variants.find(variant => variant.variant_id == variant_id && parseInt(variant.sports_year_add) == parseInt(year_add))
        }
      },
      get_selected_variants: function(){
        const selected_variants = this.selected_variants
        return function(license_id){
          if(
            selected_variants.find(variant => variant.license_id == license_id)
          ){
            return [
              selected_variants.find(variant => variant.license_id == license_id && parseInt(variant.sports_year_add) == parseInt(0)),
              selected_variants.find(variant => variant.license_id == license_id && parseInt(variant.sports_year_add) == parseInt(1))
            ]
          }
          return false
        }
      },
      chk_license_sel_variant: function () {
        const selected_variants = this.selected_variants
        return function (license_id, license_event_cd, license_category) {
          // 同じライセンスを持っている
          if (
            typeof selected_variants === 'object' &&
            selected_variants.find(
              (variant) =>
                variant.license_id == license_id &&
                variant.license_category == license_category
            )
          ) {
            return 1
          }
          // すでに同じ競技種目のライセンスを持っている
          if (
            typeof selected_variants === 'object' &&
            selected_variants.find(
              (variant) =>
                variant.license_event_cd == license_event_cd &&
                variant.license_category == license_category
            )
          ) {
            return 10
          }
          return false
        }
      },
      get_class_variant_license: function () {
        const check = this.chk_license_sel_variant
        return function (license_id, license_event_cd, license_category) {
          const chk = check(license_id, license_event_cd, license_category)
          if (chk == 10) {
            return 'checkbox02__label me-3 mb-3 '
          } else if (chk == 1) {
            return 'checkbox02__label me-3 mb-3 '
          }
          return 'checkbox02__label me-3 mb-3 active'
        }
      },
      // 下の強化版-同一ライセンスをチェック
      get_license_sel_variant_f: function () {
        const selected = this.selected_variants
        return function (variant_id, year_key, license_id) {
          if (
            typeof selected === 'object' &&
            selected.find(
              (select) =>
                (select.variant_id == variant_id || select.license_id == license_id) &&
                parseInt(select.sports_year_add) == parseInt(year_key)
            )
          ) {
            return true
          }
          return false
        }
      },
      get_license_sel_variant: function () {
        const selected = this.selected_variants
        return function (variant_id, year_key) {
          if (
            typeof selected === 'object' &&
            selected.find(
              (select) =>
                select.variant_id == variant_id &&
                parseInt(select.sports_year_add) == parseInt(year_key)
            )
          ) {
            return true
          }
          return false
        }
      },
      get_license_sel_variant_class: function () {
        const check = this.get_license_sel_variant
        return function (variant_id) {
          if (check(variant_id)) {
            return 'btn btn-warning'
          }
          return 'btn btn-light btn-outline-secondary'
        }
      },
      chk_license_sel_category: function () {
        const selected = this.chk_license_category
        return function (sel_cate, no) {
          const target = selected[no]
          if (target !== false && target === sel_cate) {
            return true
          } else {
            return false
          }
        }
      },
      get_class_nonsel_license_category: function () {
        const chk_nonsel_license_category = this.chk_nonsel_license_category
        return function (sel_cate) {
          const target = chk_nonsel_license_category(sel_cate)
          if (target) {
            return 'checkbox02__label me-3 mb-3'
          } else {
            return 'checkbox02__label me-3 mb-3 active'
          }
        }
      },
      chk_nonsel_license_category: function () {
        const nonsel_license_category = this.nonsel_license_category
        return function (category_id) {
          if (nonsel_license_category.indexOf(category_id) >= 0) {
            return true
          }
          return false
        }
      },
      get_class_license_sel_category: function () {
        const chk_license_sel_category = this.chk_license_sel_category
        return function (sel_cate, no) {
          const target = chk_license_sel_category(sel_cate, no)
          if (target) {
            return 'checkbox02__label me-3 mb-3'
          } else {
            return 'checkbox02__label me-3 mb-3 active'
          }
        }
      },
      chk_disabled_license_sel_category: function () {
        const frm_mode = this.license_sel_frm_mode
        return function (cnt) {
          if ('category_' + cnt == frm_mode) {
            return true
          }
          return false
        }
      },
      disabled_license_sel_category: function () {
        const chkchk = this.chk_disabled_license_sel_category
        return function (cnt) {
          if (!chkchk(cnt)) {
            // return 'disabled'
            return false
          }
          return false
        }
      },

      // 競技orエンジョイの場合必須
      chk_insurance_sports: function () {
        if (
          typeof this.selected_variants === 'object' &&
          this.selected_variants.find((variant) => {
            if (
              // variant.license_category == 1 ||
              // variant.license_category == 3
              variant.category == 1
            ) {
              return true
            }
            return false
          })
        ) {
          return true
        }
        return false
      },
      chk_mimai_sports: function () {
        if (
          typeof this.selected_variants === 'object' &&
          this.selected_variants.find((variant) => {
            if (
              variant.license_category == 4 ||
              variant.license_category == 5
            ) {
              return true
            }
            return false
          })
        ) {
          return true
        }
        return false
      },
      get_insurance_sports: function () {
        return true
      },
      test_insurance_123: function () {
        if (
          this.selected_variants_license_category[1].length > 0 ||
          this.selected_variants_license_category[2].length > 0 ||
          this.selected_variants_license_category[3].length > 0
        ) {
          return true
        }
        return false
      },
      test_insurance_45: function () {
        // 講師,役員(4,5)
        if (
          this.selected_variants_license_category[4].length > 0 ||
          this.selected_variants_license_category[5].length > 0
        ) {
          return true
        }
        return false
      },

      // 3時間証明表示
      rd_3hour_frm_disp: function () {
        if (
          typeof this.selected_variants === 'object' &&
          this.selected_variants.find((variant) => variant.join_3hour == 1)
        ) {
          return true
        }
        return false
      },
      // Web講習
      join_seminar_web_frm_disp: function () {
        if (
          typeof this.selected_variants === 'object' &&
          this.selected_variants.find(
            (variant) => variant.join_seminar_web == 1
          )
        ) {
          return true
        }
        return false
      },
      // 対面講習
      join_seminar_circuit_frm_disp: function () {
        if (
          typeof this.selected_variants === 'object' &&
          this.selected_variants.find(
            (variant) => variant.join_seminar_circuit == 1
          )
        ) {
          return true
        }
        return false
      },
      // 親権者
      parent_frm_disp: function () {
        if (
          typeof this.selected_variants === 'object' &&
          this.selected_variants.find((variant) => variant.join_parent_frm == 1)
        ) {
          return true
        }
        return false
      },
      // 参加承諾書
      parent_accept_disp: function () {
        if (
          typeof this.selected_variants === 'object' &&
          this.selected_variants.find(
            (variant) => parseInt(variant.join_parent_accept) >= 1
          )
        ) {
          return true
        }
        return false
      },
      chk_app_checked: function () {
        const checked = this.communication_checked
        return function (key) {
          if (checked.includes(key)) {
            return true
          }
          return false
        }
      },

      get_list_label: function () {
        return function (key, lists) {
          if (
            typeof lists === 'object' &&
            typeof lists.find((list) => list.key == key) === 'object'
          ) {
            return lists.find((list) => list.key == key).label
          }
          return key
        }
      },
      get_list_key: function () {
        return function (label, lists) {
          if (
            typeof lists === 'object' &&
            typeof lists.find((list) => list.label == label) === 'object'
          ) {
            return lists.find((list) => list.label == label).key
          }
          return label
        }
      },
      circuit_list: function () {
        const lists = [...this.circuit_obj]
        return lists
          .map((list) => {
            list.key = list.id
            // list.class_label = this.get_list_param('license_class', list.license_class, 'label');
            // list.category_label = this.get_category_label('license_category', list.license_category, 'label');
            return list
          })
          .sort((a, b) => {
            if (parseInt(a.sort) < parseInt(b.sort)) {
              return -1
            } else {
              return 1
            }
          })
      },
      get_target_com_item: function () {
        const communication = this.communication_obj
        return function (app_chk_cd) {
          if (
            typeof communication === 'object' &&
            typeof communication.items === 'object'
          ) {
            return communication.items.find(
              (com) => com.application_check_cd == app_chk_cd
            )
          }
          return {
            item_comment: '',
            check_flg: 0,
          }
        }
      },
      get_target_com_msg: function () {
        const communication = this.get_target_com_item
        return function (app_chk_cd) {
          if ((communication(app_chk_cd) || {}).item_comment) {
            return (communication(app_chk_cd) || {}).item_comment
          }
          return false
        }
      },
      get_target_app_item: function () {
        const application = this.application_obj
        return function (app_key) {
          if (
            typeof application === 'object' &&
            typeof application.items === 'object'
          ) {
            return application.items.find(
              (app) => app.application_key == app_key
            )
          }
          return {
            application_value: '',
          }
        }
      },
      get_wareki: function () {
        const regex1 =
          /^[0-9]{4}[/-](0[1-9]|1[0-2])[/-](0[1-9]|[12][0-9]|3[01])/
        const moment_func = moment
        return function (year, month, day) {
          const birth_date = moment_func([
            year,
            parseInt(month) - 1,
            day,
          ]).format('YYYY-MM-DD')
          if (regex1.test(birth_date)) {
            console.log('get_wareki', regex1.test(birth_date), birth_date)
            const tmp = new Date(birth_date).toLocaleDateString(
              'ja-JP-u-ca-japanese',
              {
                dateStyle: 'long',
              }
            )
            if (typeof tmp === 'string' && tmp.indexOf('年') > 0) {
              return tmp.substring(0, tmp.indexOf('年')) + '年'
            }
          }
          return ''
        }
      },
      get_birth_date: function () {
        const year = this.birth_date_year
        const month = this.birth_date_month
        const day = this.birth_date_day

        const moment_date = moment(year, parseInt(month) - 1, day)

        return moment_date.format('YYYY-MM-DD')
      },
      birth_date_year_list: function () {
        let now = 1920
        let limit = parseInt(Common.getNowYear()) + 1
        const tmps = []
        for (let i = now; i < 1970; i++) {
          tmps.push({
            key: i,
            label: i,
          })
        }
        tmps.push({
          key: '',
          label: '選択して下さい',
        })
        for (let i = 1970; i < limit; i++) {
          tmps.push({
            key: i,
            label: i,
          })
        }
        return tmps
      },
      birth_date_month_list: function () {
        const tmps = []
        for (let i = 1; i <= 12; i++) {
          tmps.push({
            key: i,
            label: i,
          })
        }
        return tmps
      },
      birth_date_day_list: function () {
        // const moment_date = moment();
        const moment_func = moment
        // const year = this.birth_date_year;
        // const month = this.birth_date_month;
        return function (year, month) {
          let max = 31
          if (year != '' && month != '') {
            const moment = moment_func([year, parseInt(month) - 1, 1])
            max = moment.daysInMonth()
          }
          const tmps = []
          for (let i = 1; i <= max; i++) {
            tmps.push({
              key: i,
              label: i,
            })
          }
          return tmps
        }
      },
      message_class_style: function () {
        const message_class = this.message_class
        return function (target) {
          if (target == 'border') {
            return (
              'text-center py-2 bs_fs_5 mb-2 border-bottom border-' +
              message_class
            )
          } else if (target == 'alert') {
            return 'alert alert-' + message_class
          }
        }
      },
      sex_type_list: function () {
        return this.sex_type_obj
      },
      request_type_cd_list: function () {
        return this.request_type_cd_obj
      },
      race_sports_year_list: function () {
        return this.race_sports_year_obj
      },
      selected_year_label: function () {
        // const tmps = [];
        const tmps = this.race_sports_year_list.filter((year) =>
          this.selected_year.includes(parseInt(year.key))
        )
        return tmps
      },
      get_year_label: function(){
        const race_sports_year_list = this.race_sports_year_list
        return function(year_key){
          return race_sports_year_list.find(year => year.key == year_key)? race_sports_year_list.find(year => year.key == year_key)?.label: ''
        }
      },
      pref_cd_list: function () {
        const lists = [...this.pref_cd_obj]
        return lists.sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },
      member_document_cd_list: function () {
        return this.member_document_cd_obj
      },
      identify_type_cd_list: function () {
        return this.identify_type_cd_obj
      },
      application_type_list: function () {
        return this.application_type_obj
      },
      application_check_type_list: function () {
        return this.application_check_type_obj
      },
      application_list: function () {
        return this.application_obj
      },
      application_filter: function () {
        const app_list = this.application_list
        return function (app_type, app_chk_list) {
          return app_list.filter((app) => {
            if (
              app.application_type == app_type &&
              app_chk_list.includes(app.application_check_type) !== false
            ) {
              return true
            }
            return false
          })
        }
      },
      error_mode: function () {
        return this.page_mode == 'error' ? true : false
      },
      license_mode: function () {
        return this.page_mode == 'license' ? true : false
      },
      license_sel_mode: function () {
        return this.page_mode == 'license_sel' ? true : false
      },
      profile_mode: function () {
        return this.page_mode == 'profile' ? true : false
      },
      confirm_mode: function () {
        return this.page_mode == 'confirm' ? true : false
      },
      check_mode: function () {
        return this.page_mode == 'mfj_check' ? true : false
      },
      charge_mode: function () {
        return this.page_mode == 'charge' ? true : false
      },
      cash_unpaid_mode: function () {
        return this.page_mode == 'cash_unpaid' ? true : false
      },
      enquate_mode: function () {
        return this.page_mode == 'enquate_check' ? true : false
      },
      approval_mode: function () {
        return this.page_mode == 'mfj_approval' ? true : false
      },
      success_mode: function () {
        return this.page_mode == 'success' ? true : false
      },
      reject_mode: function () {
        return this.page_mode == 'mfj_reject' ? true : false
      },

      license_sel_year_frm: function () {
        return this.license_sel_frm_mode == 'year' ? true : false
      },
      license_sel_category_frm: function () {
        const license_sel_frm_mode = this.license_sel_frm_mode
        return function (category_id) {
          return license_sel_frm_mode == 'category_' + category_id
            ? true
            : false
        }
      },
      license_sel_insurance_frm: function () {
        return this.license_sel_frm_mode == 'insurance' ? true : false
      },

      license_sel_year_frm_disp: function () {
        if (
          this.license_sel_year_frm ||
          this.license_sel_category_frm(1) ||
          this.license_sel_category_frm(2) ||
          this.license_sel_category_frm(3) ||
          this.license_sel_category_frm(4) ||
          this.license_sel_category_frm(5) ||
          this.license_sel_insurance_frm_disp
        ) {
          return true
        }
        return false
      },
      license_flow_category_btn_disp: function () {
        const chk_sports_license = this.chk_sports_license
        const selected_year = this.selected_year
        return function (category_id) {
          if (
            category_id == 1 &&
            typeof selected_year === 'object' &&
            selected_year.length > 0
          ) {
            return true
          } else if (category_id == 2 && chk_sports_license === false) {
            return true
          } else if (category_id == 3 && chk_sports_license === false) {
            return true
          } else if (category_id == 4 && chk_sports_license === true) {
            return true
          } else if (category_id == 2 && chk_sports_license === false) {
            return true
          }
        }
      },
      chk_sel_have_license_category: function(){
        const selvar = this.selected_variants
        const selhav = this.have_variants
        return function(license_category){
          if(
            selvar.find(variant => variant.license_category == license_category)
            && 
            selhav.find(variant => variant.license_category == license_category)
          ){
            return true
          }
          return false
        }
      },
      license_sel_category_frm_disp: function () {
        const disp_key_btn_category = [
          false,
          this.disp_key_btn_category_1,
          this.disp_key_btn_category_2,
          this.disp_key_btn_category_3,
          this.disp_key_btn_category_4,
          this.disp_key_btn_category_5,
        ]
        return function (category_id) {
          if (disp_key_btn_category[category_id]) {
            return true
          }
          return false
          // if (
          //   (category_id == 3 &&
          //     (this.license_sel_category_frm(3) ||
          //       this.license_sel_insurance_frm_disp)) ||
          //   (category_id == 1 &&
          //     (this.license_sel_category_frm(1) ||
          //       this.license_sel_category_frm(2) ||
          //       this.license_sel_category_frm(4) ||
          //       this.license_sel_category_frm(5) ||
          //       this.license_sel_category_frm(3) ||
          //       this.license_sel_insurance_frm_disp)) ||
          //   (category_id == 2 &&
          //     (this.license_sel_category_frm(2) ||
          //       this.license_sel_category_frm(4) ||
          //       this.license_sel_category_frm(5) ||
          //       this.license_sel_category_frm(3) ||
          //       this.license_sel_insurance_frm_disp)) ||
          //   (category_id == 4 &&
          //     (this.license_sel_category_frm(5) ||
          //       this.license_sel_category_frm(4) ||
          //       this.license_sel_category_frm(3) ||
          //       this.license_sel_insurance_frm_disp)) ||
          //   (category_id == 5 &&
          //     (this.license_sel_category_frm(3) ||
          //       this.license_sel_category_frm(5) ||
          //       this.license_sel_insurance_frm_disp))
          // ) {
          //   return true
          // }
          // return false
        }
      },
      license_sel_insurance_frm_disp: function () {
        if (this.license_sel_insurance_frm) {
          return true
        }
        return false
      },
      license_sel_next_btn_disp: function () {
        if (
          typeof this.selected_variants === 'object' &&
          this.selected_variants.length > 0
        ) {
          return true
        }
        return false
      },

      // license_sel_category_1_frm: function(){
      //     return (this.license_sel_frm_mode == 'category_1')? true: false;
      // },
      // license_sel_category_2_frm: function(){
      //     return (this.license_sel_frm_mode == 'category_2')? true: false;
      // },
      // license_sel_category_3_frm: function(){
      //     return (this.license_sel_frm_mode == 'category_3')? true: false;
      // },
      // license_sel_category_4_frm: function(){
      //     return (this.license_sel_frm_mode == 'category_4')? true: false;
      // },
      // license_sel_category_5_frm: function(){
      //     return (this.license_sel_frm_mode == 'category_5')? true: false;
      // },
      // license_sel_insurance_frm: function(){
      //     return (this.license_sel_frm_mode == 'insurance')? true: false;
      // },

      next_btn_year: function () {
        return this.selected_year.length > 0 && this.license_sel_year_frm
          ? true
          : false
      },
      // カテゴリ1=競技ライセンス選択
      go_btn_category_disp_1: function () {
        if (
          this.license_sel_category_frm(1) &&
          this.selected_variants.find(
            (variant) => variant.license_category == 1
          )
        ) {
          return true
        }
        return false
      },
      go_btn_category_disp: function () {
        const license_sel_category_frm = this.license_sel_category_frm
        const selected_variants = this.selected_variants
        return function (category) {
          if (
            license_sel_category_frm(category) &&
            selected_variants.find(
              (variant) => variant.license_category == category
            )
          ) {
            return true
          } else {
            return false
          }
        }
      },
      next_btn_category: function () {
        if (this.license_sel_category_frm(this.selected_license_category_no)) {
          return true
        }
        return false
      },

      input_class: function () {
        const page_mode = this.page_mode
        const checked = this.chk_app_checked
        return function (key) {
          if (page_mode == 'confirm' || key == 'm_mail_1') {
            return 'form-control-plaintext'
          } else if (key != '' && checked(key)) {
            return 'form-control-plaintext'
          } else {
            return 'form-control'
          }
        }
      },
      input_readonly: function () {
        const page_mode = this.page_mode
        const checked = this.chk_app_checked
        return function (key) {
          if (page_mode == 'confirm' || key == 'm_mail_1') {
            return true
          } else if (checked(key)) {
            return true
          } else {
            return false
          }
        }
      },
      has_receipt_data: function () {
        return this.receipt !== undefined
      },
      application_key_list: function(){
        const key_obj = this.application_key_obj
        return function(type){
          if(
            typeof key_obj[type] === 'object'
          ){
            return key_obj[type]
          }
          return []
        }
      },
      get_app_list_label: function () {
        // const lists = this.application_key_list(1000)
        const get_list = this.get_list_label
        const lists = {
          m_name: '氏名(姓・名)',
          m_yomi: 'ヨミ(セイ・メイ)',
          m_birth_date: '生年月日',
          m_sex_type: '性別',
          m_photo: '顔写真',
          m_idcard_photo: '身分証明書'
        }
        return function (key) {
          const target_list = []
          Object.keys(lists).forEach(list_key => {
            target_list.push({
              key: list_key,
              label: lists[list_key]
            })
          })
          return get_list(key, target_list)
        }
      },
      is_application_comment_item: function(){
        if(
          typeof this.communication_obj === 'object' && typeof this.communication_obj.items === 'object'
          && this.communication_obj.items.length > 0 && this.communication_obj.items.find(item => item.comment != '')
        ){
          return true
        }
        return false
      },
      chk_3hour_circuit_mfj_total: function(){
        if(this.selected_variants.find(variant => this.chk_3hour_circuit_mfj(variant.join_3hour_circuit_id))){
          return true
        }
        return false
      },
      chk_3hour_circuit_mfj: function(){
        const circuit_list = this.circuit_list
        return function(circuit_id){
          if(
            typeof circuit_list === 'object' && circuit_list.find(circuit => circuit.key == circuit_id)
            && circuit_list.find(circuit => circuit.key == circuit_id)?.circuit_cd && typeof circuit_list.find(circuit => circuit.key == circuit_id)?.circuit_cd === 'string' && circuit_list.find(circuit => circuit.key == circuit_id)?.circuit_cd.startsWith('MFJ')
          ){
            return true
          }
          return false
        }
      },
    },

    async created() {
      await this.loadNowDate()
      console.log(
        'mmounted created',
        config.BASE_API_URL,
        process.env.NODE_ENV,
        process.env.VUE_APP_GMO_MP_TOKEN_PUB_KEY_MFJ,
        config.GMO_MP_TOKEN_PUB_KEY_MFJ
      )
      await this.loadCircuitList()
      this.pref_cd_obj = await this.loadBasicConfigObj('pref')
      this.sex_type_obj = await this.loadBasicConfigObj('sex_type')
      this.request_type_cd_obj = await this.loadBasicConfigObj(
        'request_type_cd'
      )
      this.identify_type_cd_obj = await this.loadBasicConfigObj(
        'identify_type_cd'
      )
      this.application_key_obj = await this.loadBasicConfigObj(
        'application_key'
      )
      await this.loadMerchantObj('mfj')
      await this.loadLicenseCdList()
      await this.loadArticle()
      await this.loadArticleVariant()
      await this.loadTermYear()
      await this.loadTermDate()
      await this.loadRaceSportsYear()
      await this.setApplicationObj(false)
      await this.loadEnquateSheetObj()
      // await this.loadRegistData()
      await this.loadRegistData()
      if(
        this.get_base_env == 'development'
        || this.$route.query.test == 1
      ){
        await this.setApplicationObj(true)
      }
      else{
        await this.setApplicationObj(false)
      }
      this.validateForm() // 初期バリデーションを実行

    },
    mounted: async function () {
      // MpTokenに必要な情報を設定
      const mulpay = await this.handlePaymentPageProps({
        instance: axios.create({
          baseURL: config.BASE_API_URL,
          headers: {
            'Content-Type': 'application/json',
          },
        }),
        apiKey: config.GMO_MP_TOKEN_API_KEY_MFJ,
        pubKey: config.GMO_MP_TOKEN_PUB_KEY_MFJ,
        productionMode: config.GMO_SHOP_OP_MODE,
      })

      const elements = mulpay.createElements()

      const cardNumberInputElement = elements.create('cardNumber', {
        style: cardInputStyle,
        placeholder: 'Card Number',
      })
      const expiryInputElement = elements.create('cardExpiry', {
        style: expiryInputStyle,
      })
      const cvcInputElement = elements.create('cardCvc', {
        style: cvcInputStyle,
      })

      const cardNumberWrapperElement = document.getElementById('card-number')
      const cardExpiryWrapperElement = document.getElementById('expiry-month')
      const cardCvcWrapperElement = document.getElementById('security-code')

      cardNumberInputElement.mount(cardNumberWrapperElement)
      expiryInputElement.mount(cardExpiryWrapperElement)
      cvcInputElement.mount(cardCvcWrapperElement)

      this.setElements(
        cardNumberInputElement,
        expiryInputElement,
        cvcInputElement
      )
    },
    unmounted() {
      this.clear()
    },
    props: {},
    watch: {
      data_obj: {
        deep: true,
        handler() {
          this.validateForm()
        },
      },
      'data_obj.tel_1': function (newVal) {
        this.data_obj.tel_1 = this.formatPhoneNumber(newVal)
      },
      'data_obj.tel_2': function (newVal) {
        this.data_obj.tel_2 = this.formatPhoneNumber(newVal)
      },
      // variantsの監視
      selected_variants: {
        handler() {
          this.validateForm()
        },
        deep: true,
      },
    },
  }
</script>

import { createApp } from 'vue'
import App from './App.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import router from './router'
import store from './store'
import { createPinia } from 'pinia'

import gv from './mixin/globalValiables'
import ga from './mixin/globalActions'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

import './assets/base/base.css'

import './validate/validate'

import 'feather-icons'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBarcode,
  faCircleExclamation,
  faChevronRight,
  faCircleChevronDown,
  faChevronLeft,
  faFileAlt,
  faTrash,
  faMagnifyingGlass,
  faXmark,
  faEnvelope,
  faReply,
  faPlus,
  faFileArrowUp,
  faEye,
  faEyeSlash,
  faCircleCheck,
  faComment,
  faTriangleExclamation,
  faPaperPlane
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './assets/css/style.css'
import './assets/css/style-color.css'

library.add(
  faBarcode, faCircleExclamation, faChevronRight, faCircleChevronDown, faChevronLeft, 
  faFileAlt, faTrash, faMagnifyingGlass, faXmark, faEnvelope, faReply, faPlus, faFileArrowUp,faEye,
  faEyeSlash, faCircleCheck, faComment, faTriangleExclamation, faPaperPlane
)

const pinia = createPinia()
const app = createApp(App)
app.use(router)
app.use(store)
app.mixin(ga)
app.mixin(gv)
app.use(VueAxios, axios)
app.use(pinia)

// Font Awesome コンポーネントのグローバル登録
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')

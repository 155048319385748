<template>
  <div class="login">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 w-100 mb-32">
          <div class="text-end">
            <a :href="get_base_asset + '/manual/club/mfj_club_send_pass_20250303.pdf'">公認クラブ パスワード発行手順マニュアル</a>
          </div>
          <div class="text-end">
            <a :href="get_base_asset + '/manual/club/mfj_club_mypage_20250303.pdf'">公認クラブ MYページ操作マニュアル</a>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 w-100 mb-32" v-if="mfj_phase_club_001">
          <div class="alert alert-danger wysiwyg">
            <h5 class="title text-center">ご注意下さい</h5>
            <p class="text-center py-0 mb-3">
              旧システムで使用していたパスワードでは、ログインすることができません。<br>
              初めてログインを行う前に下のリンクからパスワードの発行を行ってログインして下さい。<br>
              ※パスワードは、ご登録いただいている代表者メールアドレス宛にお送りいたします。<br>
              ※クラブコードは旧システムと同じものをご入力下さい。
            </p>
            <div class="row">
              <div class="col-12">
                <div class="d-grid">
                  <router-link class="btn btn-danger btn-lg text-white" to="/cpasswd/passcd">パスワード発行はこちら</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-lg-6 w-100 mb-32" v-else>
          <div class="alert alert-danger wysiwyg">
            <p class="text-center py-0 mb-3">
              現在準備中です。<br>
              2025/3/3（月）13:00から提供を開始します。
            </p>
          </div>
        </div>
        <div class="col-md-8 col-lg-6 w-100">
          <h2 class="title login__title text-center">
            クラブマイページログイン
          </h2>
          <form @submit.prevent="handleSubmit">
            <bs-message-block
              :show="showError"
              :message="errorMessage"
            ></bs-message-block>
            <ul class="login__formList">
              <li class="login__formListItem">
                <label class="label login__label" for="user">クラブコード</label>
                <div class="inputWrapper">
                  <input 
                    class="input login__input" 
                    id="user" 
                    type="text" 
                    v-model="login_id"
                    :disabled="open_check"
                    placeholder="クラブコードを入力"
                  />
                </div>
              </li>
              <li class="login__formListItem">
                <label class="label login__label" for="password">パスワード</label>
                <div class="inputWrapper">
                  <input 
                    class="input login__input" 
                    id="password" 
                    :type="isPasswordVisible ? 'text' : 'password'"
                    v-model="login_pass"
                    :disabled="open_check"
                    placeholder="パスワードを入力"
                  >
                  <span
                    class="input-group-addon"
                    @click="togglePasswordVisibility"
                  >
                    <font-awesome-icon
                      :icon="
                        isPasswordVisible
                          ? ['fas', 'eye']
                          : ['fas', 'eye-slash']
                      "
                    />
                  </span>
                </div>
              </li>
            </ul>
            <p class="login__button">
              <button class="button02" type="submit" :disabled="open_check">
                <span class="button02__text">公認クラブマイページへログインする</span>
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="mx-2"
                />
              </button>
            </p>
          </form>
          <div class="login__box" v-show="!open_check">
            <div class="login__boxIcon">
              <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
            </div>
            <ul class="login__boxUl">
              <li class="login__boxLi">
                ※パスワードを忘れてしまった方は、<router-link
                  class="login__boxLink"
                  to="/cpasswd/passcd"
                  >こちら</router-link
                >から再設定手続きを行なってください。
              </li>
              <li class="login__boxLi">
                ※ クラブコード.郵便番号ともに忘れてしまった場合は、<a
                  class="login__boxLink"
                  href="https://www.mfj.or.jp/other/about/contact/"
                  >こちら</a
                >からお問い合わせ下さい。
              </li>
              <li class="login__boxLi">
                ※ 公認クラブを新たに申請する場合は、<router-link
                  class="login__boxLink"
                  to="/club_regist/add"
                  >こちら</router-link
                >からお申込みください。
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  @import '../../assets/css/style-color03.css';

.input-group {
  position: relative;
}

.input-group-addon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
.inputWrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 400px;
}
</style>

<script>
  import BsMessageBlock from '../atoms/BsMessageBlock.vue'

  export default {
    name: 'BsClubLoginForm',
    components: {
      BsMessageBlock,
    },
    data() {
      return {
        login_id: '',
        login_pass: '',
        showError: false,
        errorMessage: '',
        isLoading: false,
        isPasswordVisible: false,
      }
    },
    computed: {
      open_check(){
        return this.mfj_phase_club_001? false: true
        // return (this.get_base_env == 'development' || this.get_base_env == 'staging')?  false: true
      },
      isSubmitDisabled() {
        return !this.login_id || !this.login_pass || this.isLoading
      },
    },
    methods: {
      // パスワード表示切り替え
      togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible
      },
      handleSubmit() {
        if (this.isSubmitDisabled) {
          this.showError = true
          this.errorMessage = 'ログイン情報を入力してください'
          return
        }

        this.isLoading = true
        this.showError = false
        this.errorMessage = ''

        this.onlogin({ login_id: this.login_id, login_pass: this.login_pass })
          .catch(() => {
            this.showError = true
            this.errorMessage =
              'ログインに失敗しました。正しいログインIDとパスワードを入力してください。'
          })
          .finally(() => {
            this.isLoading = false
          })
      },
    },
    props: {
      onlogin: {
        type: Function,
        required: true,
      },
    },
  }
</script>

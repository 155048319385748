<template>
  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1">
          <div class="container mb-2 py-3">
            <h5>活動種目管理</h5>
          </div>
          <div class="container py-3">
            <p class="alert alert-warning">
              {{ message }}
            </p>
          </div>
          <!-- {{ license_event_relation_obj }} -->
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1">
          <h5 class="bs_fs_7">種目一覧</h5>
        </div>
      </div>
    </div>
    <div class="container mb-4">
      <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1">
          <div class="row">
            <div
              class="col-6 col-md-4 col-lg-3 mb-2"
              v-for="license_event in license_event_list"
              :key="license_event"
            >
              <div class="d-grid">
                <button
                  :class="license_event_class(license_event.id)"
                  @click="toggleLicenseEvent(license_event.id)"
                  :disabled="chkSelectedLimit(license_event.id)"
                >
                  {{ license_event.label }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1">
          <div class="row">
            <div class="col-2 col-md-2">
              <div class="d-grid">
                <button
                  class="btn btn-light btn-outline-secondary"
                  @click="back_data()"
                >
                  戻る
                </button>
              </div>
            </div>
            <div class="col-6 offset-1 col-md-4 offset-md-2">
              <div class="d-grid">
                <button class="btn btn-primary" @click="update">更新</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.375rem;
    /* border-bottom: solid 1px #999; */
  }
</style>

<script>
  import { mapState } from 'vuex'
  // import BsPageTitle from '../../molecules/BsPageTitle.vue'
  // import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
  // import BsSelect from '../../atoms/BsSelect.vue'
  // import BsCheckBox from '../../atoms/BsCheckBox.vue'
  // import '../../assets/bootstrap/css/sc.css';
  // import BsSelect from '../../atoms/BsSelect.vue'
  import config from '../../../const'

  export default {
    name: 'ClubLicenseEventView',
    data: function () {
      return {
        targetid: this.$store.state.cauth.userId,
        btnLabel: '',
        message:
          '主活動種目を1種目は必ず選択して下さい。2種目まで選択可能です。',
        data_obj: {},
        license_event_form_obj: {
          id: 0,
          stat: 1,
          c_id: this.$store.state.cauth.userId,
          license_event_id: 0,
          license_event_cd: '',
        },
        license_event_relation_obj: [],
        license_event_cd_obj: [],
      }
    },
    components: {
      // BsSelect,
      //   BsMsgAlert,
      // BsCheckBox,
    },
    methods: {
      async toggleLicenseEvent(license_event_id) {
        const tmps = [...this.license_event_relation_obj]
        if (
          this.license_event_relation_obj.find(
            (obj) => obj.license_event_id == license_event_id
          )
        ) {
          const index = this.license_event_relation_obj.findIndex(
            (obj) => obj.license_event_id == license_event_id
          )
          tmps.splice(index, 1)
        } else {
          const tmp = { ...this.license_event_form_obj }
          tmp.license_event_id = license_event_id
          tmp.license_event_cd = this.license_event_list.find(
            (list) => list.id == license_event_id
          )
            ? this.license_event_list.find(
                (list) => list.id == license_event_id
              ).license_event_cd
            : ''
          tmps.push(tmp)
        }
        this.license_event_relation_obj = tmps
      },
      async update() {
        const params = {
          license_event: this.license_event_relation_obj,
        }
        await this.basicPosts_I(
          config.BASE_API_URL + '/club/edit/' + this.$store.state.cauth.userId,
          params
        )
          .then(async (response) => {
            console.log('update', response)
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            await this.loadLicenseEventRelationObj()
            this.message = '更新しました'
            this.$forceUpdate()
          })
        // params['c_id'] = this.$store.state.cauth.userId;
      },
      async loadConfigObj(param) {
        const params = {}
        await this.basicPosts_S(
          config.BASE_API_URL + '/config/' + param,
          params
        )
          .then(async (response) => {
            console.log('loadConfigObj response', response)
            this[param + '_obj'] =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this[param + '_obj'] = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadClubObj() {
        const params = {}
        params['stat'] = [1]
        params['id'] = this.$store.state.cauth.userId
        await this.basicPosts_S(config.BASE_API_URL + '/club/lists', params)
          .then(async (response) => {
            console.log('loadClubObj', response)
            this.data_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            // await this.setClubObj();
            this.$forceUpdate()
          })
      },
      async loadLicenseEventCdObj() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(
          config.BASE_API_URL + '/license_event_cd/lists',
          params
        )
          .then(async (response) => {
            console.log('loadLicenseEventCdObj', response)
            this.license_event_cd_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.license_event_cd_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadLicenseEventRelationObj() {
        const params = {}
        params['stat'] = [1]
        params['c_id'] = this.$store.state.cauth.userId
        console.log('loadLicenseEventRelationObj params', params)
        await this.basicPosts_S(
          config.BASE_API_URL + '/club_license_event_relation/lists',
          params
        )
          .then(async (response) => {
            console.log('loadLicenseEventRelationObj', response)
            this.license_event_relation_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.application_obj = []
          })
          .finally(async () => {
            // await this.setClubObj();
            this.$forceUpdate()
          })
      },
      back_input() {
        this.change_mode = false
      },
      back() {
        this.$router.push({ path: '/' })
      },
      back_data() {
        this.$router.push({ path: '/club_edit/data' })
      },
    },
    computed: {
      ...mapState({}),
      chkSelectedLimit: function () {
        const relation = this.license_event_relation_obj
        return function (license_event_id) {
          if (
            typeof relation === 'object' &&
            !relation.find((obj) => obj.license_event_id == license_event_id) &&
            relation.length >= 2
          ) {
            return true
          }
          return false
        }
      },
      license_event_class: function () {
        const relation = this.license_event_relation_obj
        return function (license_event_id) {
          if (
            relation.find((obj) => obj.license_event_id == license_event_id)
          ) {
            return 'btn btn-warning'
          } else {
            return 'btn btn-light btn-outline-secondary'
          }
        }
      },
      license_event_list: function () {
        const lists = [...this.license_event_cd_obj]
        return lists.sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },
      m_relation_list: function () {
        const m_relation_obj = this.m_relation_obj
        return m_relation_obj
          .sort((a, b) => {
            if (parseInt(a.sort) < parseInt(b.sort)) {
              return -1
            } else {
              return 1
            }
          })
          .map((rel) => {
            rel.search_sec_cd = ''
            return rel
          })
        // return this.m_relation_obj;
      },
      chk_m_rel: function () {
        const m_relation_list = this.m_relation_list
        return function (index) {
          if (
            typeof m_relation_list === 'object' &&
            typeof m_relation_list[index] === 'object'
          ) {
            return m_relation_list[index]
          }
          return false
        }
      },
      chk_m_rel_m_id: function () {
        const chk_m_rel = this.chk_m_rel
        return function (index) {
          if (chk_m_rel(index)) {
            return chk_m_rel(index).m_id
          }
          return false
        }
      },
      input_class_no: function () {
        const chk_m_rel_m_id = this.chk_m_rel_m_id
        return function (index) {
          if (chk_m_rel_m_id(index) == 0) {
            return 'form-control'
          } else {
            return 'form-control-plaintext'
          }
        }
      },
      input_class: function () {
        const chk_m_rel_m_id = this.chk_m_rel_m_id
        return function (index) {
          if (chk_m_rel_m_id(index) == 0) {
            return 'form-control-plaintext'
          } else {
            return 'form-control-plaintext'
          }
        }
      },
      input_readonly_no: function () {
        const chk_m_rel_m_id = this.chk_m_rel_m_id
        return function (index) {
          if (chk_m_rel_m_id(index) == 0) {
            return false
          } else {
            return true
          }
        }
      },
      input_readonly: function () {
        const chk_m_rel_m_id = this.chk_m_rel_m_id
        return function (index) {
          if (chk_m_rel_m_id(index) == 0) {
            return true
          } else {
            return true
          }
        }
      },
    },
    async created() {
      await this.loadLicenseEventCdObj()
      await this.loadClubObj()
      await this.loadLicenseEventRelationObj()
    },
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
    props: {},
  }
</script>

<template>
  <section class="page">
    <div class="container">
      <div class="d-none d-md-block" v-if="!finish_mode">
        <step-view :step_mode="page_mode"></step-view>
      </div>
      <div v-else>
        <div class="container">
          <div class="box02 mb-32">
            <h2 class="box02__title">
              <font-awesome-icon
                :icon="['fas', 'circle-exclamation']"
                class="me-2"
              />承認完了
            </h2>
            <p class="">
              公認クラブ申請が承認されました。登録されたパスワードを使ってログインを行って下さい。
            </p>
          </div>
          <div class="row">
            <div class="col-10 col-sm-8 col-md-6 offset-1 offset-sm-2 offset-md-3">
              <div class="d-grid">
                <router-link :class="'btn btn-success btn-lg'" to="/clogin">ログインページへ</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          error_mode ||
          reject_mode ||
          approval_mode ||
          success_mode ||
          check_mode
        "
      >
        <div class="container">
          <div class="box02 mb-32">
            <h2 class="box02__title">
              <font-awesome-icon
                :icon="['fas', 'circle-exclamation']"
                class="me-2"
              />
              {{ messageTitle }}
            </h2>
            <div class="d-flex flex-column gap-2">
              <template v-if="error_mode">
                <p>{{ message || 'エラーが発生しました' }}</p>
              </template>
              <template v-else-if="reject_mode">
                <p>承認されませんでした。</p>
              </template>
              <template v-else-if="approval_mode">
                <p>承認されました。</p>
                <p>決済へ進んでください。</p>
              </template>
              <template v-else-if="success_mode">
                <p>承認されています。</p>
                <p>マイページへ進んでください</p>
              </template>
              <template v-else-if="check_mode">
                <p>
                  現在MFJ事務局にて申請内容を確認中です。<br />
                  もうしばらくお待ち下さい。
                </p>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="profile_mode || confirm_mode || remand_mode">
        <div class="container">
          <div class="box02 mb-32">
            <h2 class="box02__title">
              <font-awesome-icon
                :icon="['fas', 'circle-exclamation']"
                class="me-2"
              />
              {{
                confirm_mode ? '公認クラブ情報の確認' : '公認クラブ情報の入力'
              }}
            </h2>
            <div class="d-flex flex-column gap-2">
              <p>
                {{
                  confirm_mode
                    ? '入力内容をご確認いただき、間違いなければ、申請をお願いします。'
                    : remand_mode
                    ? '申請内容に不備がありました。確認して下さい'
                    : '下記の項目を入力してください。'
                }}
              </p>
              <template v-if="remand_mode">
                <p v-if="application_obj.comment">
                  {{ application_obj.comment }}
                </p>
                <template v-if="Array.isArray(application_obj.items) && application_obj.items.find(item => item.message)">
                  <p v-for="item in application_obj.items.filter(item => item.message)" :key="item.id">
                    {{ item.message }}
                  </p>
                </template>
              </template>
            </div>
          </div>

          <div class="mb-5">
            <div class="" style="position: relative; z-index: 1">
              <div class="container mb-3">
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">年度</h4>
                  <div class="col-12 col-md-8">
                    <p v-if="confirm_mode" class="form-control-plaintext">
                      {{ data_obj?.term_year }}
                    </p>
                    <input
                      v-else
                      type="text"
                      class="form-control-plaintext"
                      :value="data_obj?.term_year"
                      readonly="true"
                    />
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">ログインID</h4>
                  <div class="col-12 col-md-8">
                    <p v-if="confirm_mode" class="form-control-plaintext">
                      {{ data_obj.login_id }}
                    </p>
                    <input
                      v-else
                      type="text"
                      :class="[
                        'form-control',
                        { 'is-invalid': v$?.data_obj?.login_id?.$error },
                      ]"
                      v-model="data_obj.login_id"
                      @input="validateField('login_id')"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="v$?.data_obj?.login_id?.$error"
                    >
                      {{ getErrorMessage('login_id') }}
                    </div>
                  </div>
                </div>

                <!-- パスワード入力欄を追加 -->
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">パスワード</h4>
                  <div class="col-12 col-md-8 position-relative">
                    <p v-if="confirm_mode" class="form-control-plaintext">
                      ••••••••
                    </p>
                    <input
                      v-else
                      :type="isPasswordVisible1 ? 'text' : 'password'"
                      :class="[
                        'form-control',
                        { 'is-invalid': v$?.data_obj?.login_pass?.$error && v$?.data_obj?.login_pass?.$dirty },
                      ]"
                      v-model="data_obj.login_pass"
                      placeholder="8文字以上32文字以下"
                      @blur="validateField('login_pass')"
                    />
                    <span
                      v-if="profile_mode"
                      class="input-group-addon"
                      @click.stop="togglePasswordVisibility1"
                    >
                      <font-awesome-icon
                        :icon="
                          isPasswordVisible1
                            ? ['fas', 'eye']
                            : ['fas', 'eye-slash']
                        "
                      />
                    </span>
                    <div
                      class="invalid-feedback"
                      v-if="v$?.data_obj?.login_pass?.$error && v$?.data_obj?.login_pass?.$dirty"
                    >
                      {{ getErrorMessage('login_pass') }}
                    </div>
                  </div>
                </div>

                <!-- パスワード確認入力欄を追加 -->
                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    パスワード(確認)
                  </h4>
                  <div class="col-12 col-md-8 position-relative">
                    <p v-if="confirm_mode" class="form-control-plaintext">
                      ••••••••
                    </p>
                    <input
                      v-else
                      :type="isPasswordVisible2 ? 'text' : 'password'"
                      :class="[
                        'form-control',
                        {
                          'is-invalid':
                            v$?.data_obj?.login_pass_confirmation?.$error,
                        },
                      ]"
                      v-model="data_obj.login_pass_confirmation"
                      placeholder="パスワードを再入力してください"
                    />
                    <span
                      v-if="profile_mode"
                      class="input-group-addon"
                      @click.stop="togglePasswordVisibility2"
                    >
                      <font-awesome-icon
                        :icon="
                          isPasswordVisible2
                            ? ['fas', 'eye']
                            : ['fas', 'eye-slash']
                        "
                      />
                    </span>
                    <div
                      class="invalid-feedback"
                      v-if="v$?.data_obj?.login_pass_confirmation?.$error"
                    >
                      {{ getErrorMessage('login_pass_confirmation') }}
                    </div>
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">クラブ名</h4>
                  <div class="col-12 col-md-8">
                    <p v-if="confirm_mode" class="form-control-plaintext">
                      {{ data_obj.name_1 }}
                    </p>
                    <input
                      v-else
                      type="text"
                      :class="[
                        'form-control',
                        { 'is-invalid': v$?.data_obj?.name_1?.$error },
                      ]"
                      v-model="data_obj.name_1"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="v$?.data_obj?.name_1?.$error"
                    >
                      {{ getErrorMessage('name_1') }}
                    </div>
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    クラブ名(カナ)
                  </h4>
                  <div class="col-12 col-md-8">
                    <p v-if="confirm_mode" class="form-control-plaintext">
                      {{ data_obj.yomi_1 }}
                    </p>
                    <input
                      v-else
                      type="text"
                      :class="[
                        'form-control',
                        { 'is-invalid': v$?.data_obj?.yomi_1?.$error },
                      ]"
                      v-model="data_obj.yomi_1"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="v$?.data_obj?.yomi_1?.$error"
                    >
                      {{ getErrorMessage('yomi_1') }}
                    </div>
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    代表者氏名（姓）
                  </h4>
                  <div class="col-12 col-md-8">
                    <p v-if="confirm_mode" class="form-control-plaintext">
                      {{ data_obj.name_2 }}
                    </p>
                    <input
                      v-else
                      type="text"
                      :class="[
                        'form-control',
                        { 'is-invalid': v$?.data_obj?.name_2?.$error },
                      ]"
                      v-model="data_obj.name_2"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="v$?.data_obj?.name_2?.$error"
                    >
                      {{ getErrorMessage('name_2') }}
                    </div>
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    代表者氏名（名）
                  </h4>
                  <div class="col-12 col-md-8">
                    <p v-if="confirm_mode" class="form-control-plaintext">
                      {{ data_obj.name_3 }}
                    </p>
                    <input
                      v-else
                      type="text"
                      :class="[
                        'form-control',
                        { 'is-invalid': v$?.data_obj?.name_3?.$error },
                      ]"
                      v-model="data_obj.name_3"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="v$?.data_obj?.name_3?.$error"
                    >
                      {{ getErrorMessage('name_3') }}
                    </div>
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    代表者氏名（セイ）
                  </h4>
                  <div class="col-12 col-md-8">
                    <p v-if="confirm_mode" class="form-control-plaintext">
                      {{ data_obj.yomi_2 }}
                    </p>
                    <input
                      v-else
                      type="text"
                      :class="[
                        'form-control',
                        { 'is-invalid': v$?.data_obj?.yomi_2?.$error },
                      ]"
                      v-model="data_obj.yomi_2"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="v$?.data_obj?.yomi_2?.$error"
                    >
                      {{ getErrorMessage('yomi_2') }}
                    </div>
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    代表者氏名（メイ）
                  </h4>
                  <div class="col-12 col-md-8">
                    <p v-if="confirm_mode" class="form-control-plaintext">
                      {{ data_obj.yomi_3 }}
                    </p>
                    <input
                      v-else
                      type="text"
                      :class="[
                        'form-control',
                        { 'is-invalid': v$?.data_obj?.yomi_3?.$error },
                      ]"
                      v-model="data_obj.yomi_3"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="v$?.data_obj?.yomi_3?.$error"
                    >
                      {{ getErrorMessage('yomi_3') }}
                    </div>
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    連絡先電話番号1
                  </h4>
                  <div class="col-12 col-md-8">
                    <p v-if="confirm_mode" class="form-control-plaintext">
                      {{ data_obj.tel_1 }}
                    </p>
                    <input
                      v-else
                      type="tel"
                      :class="[
                        'form-control',
                        { 'is-invalid': v$?.data_obj?.tel_1?.$error },
                      ]"
                      v-model="data_obj.tel_1"
                      placeholder="例: 03-1234-5678"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="v$?.data_obj?.tel_1?.$error"
                    >
                      {{ getErrorMessage('tel_1') }}
                    </div>
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    連絡先電話番号2
                  </h4>
                  <div class="col-12 col-md-8">
                    <p v-if="confirm_mode" class="form-control-plaintext">
                      {{ data_obj.tel_2 }}
                    </p>
                    <input
                      v-else
                      type="tel"
                      :class="[
                        'form-control',
                        { 'is-invalid': v$?.data_obj?.tel_2?.$error },
                      ]"
                      v-model="data_obj.tel_2"
                      placeholder="例: 03-1234-5678"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="v$?.data_obj?.tel_2?.$error"
                    >
                      {{ getErrorMessage('tel_2') }}
                    </div>
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">住所</h4>
                  <div class="col-12 col-md-8">
                    <div class="input-group mb-2">
                      <span class="">郵便番号</span>
                    </div>
                    <div class="input-group mb-2">
                      <p v-if="confirm_mode" class="form-control-plaintext">
                        {{ data_obj.postal_cd }}
                      </p>
                      <div class="input-group mb-2" v-else>
                        <input
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid': v$?.data_obj?.postal_cd?.$error,
                          }"
                          v-model="data_obj.postal_cd"
                          @input="postal_cd_chk"
                        />
                        <button
                          type="button"
                          class="btn btn-light btn-outline-secondary"
                          @click="get_address_param"
                        >
                          <font-awesome-icon :icon="['fas', 'search']" />
                        </button>
                      </div>
                      <div
                        class="invalid-feedback"
                        v-if="v$?.data_obj?.postal_cd?.$error"
                      >
                        {{ getErrorMessage('postal_cd') }}
                      </div>
                    </div>

                    <div class="input-group mb-2">
                      <span class="">都道府県</span>
                    </div>
                    <div class="input-group mb-2">
                      <p v-if="confirm_mode" class="form-control-plaintext">
                        {{ pref_label }}
                      </p>
                      <select
                        v-else
                        :class="[
                          'form-select',
                          { 'is-invalid': v$?.data_obj?.pref_cd?.$error },
                        ]"
                        v-model.trim="data_obj.pref_cd"
                        :disabled="confirm_mode"
                        @change="onPrefectureChange"
                      >
                        <option value="">選択してください</option>
                        <option
                          v-for="pref in pref_cd_obj"
                          :key="pref.code"
                          :value="pref.code ? pref.code.toString() : ''"
                        >
                          {{ pref.label }}
                        </option>
                      </select>
                      <div
                        class="invalid-feedback"
                        v-if="v$?.data_obj?.pref_cd?.$error"
                      >
                        {{ getErrorMessage('pref_cd') }}
                      </div>
                    </div>

                    <!-- 市区町村番地 -->
                    <div class="input-group mb-2">
                      <span class="">市区町村番地</span>
                    </div>
                    <div class="input-group mb-2">
                      <p v-if="confirm_mode" class="form-control-plaintext">
                        {{ data_obj.address_1 }}
                      </p>
                      <input
                        v-else
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': v$?.data_obj?.address_1?.$error,
                        }"
                        v-model="data_obj.address_1"
                        :readonly="confirm_mode"
                      />
                      <div
                        class="invalid-feedback"
                        v-if="v$?.data_obj?.address_1?.$error"
                      >
                        {{ getErrorMessage('address_1') }}
                      </div>
                    </div>

                    <div class="input-group mb-2">
                      <span class="">建物名</span>
                    </div>
                    <div class="input-group">
                      <p v-if="confirm_mode" class="form-control-plaintext">
                        {{ data_obj.address_2 }}
                      </p>
                      <input
                        v-else
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': v$?.data_obj?.address_2?.$error,
                        }"
                        v-model="data_obj.address_2"
                      />
                      <div
                        class="invalid-feedback"
                        v-if="v$?.data_obj?.address_2?.$error"
                      >
                        {{ getErrorMessage('address_2') }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    メールアドレス
                  </h4>
                  <div class="col-12 col-md-8">
                    <p v-if="confirm_mode" class="form-control-plaintext">
                      {{ data_obj.mail_1 }}
                    </p>
                    <input
                      v-else
                      type="email"
                      :class="[
                        'form-control',
                        { 'is-invalid': v$?.data_obj?.mail_1?.$error },
                      ]"
                      v-model="data_obj.mail_1"
                      readonly
                    />
                    <small class="form-text text-muted">
                      送信したメールアドレスを表示しています
                    </small>
                    <div
                      class="invalid-feedback"
                      v-if="v$?.data_obj?.mail_1?.$error"
                    >
                      {{ getErrorMessage('mail_1') }}
                    </div>
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    活動種目
                    <span class="text-danger">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <div class="input-group mb-2">
                      <span class="">活動種目を選択してください</span>
                    </div>
                    <div v-if="confirm_mode" class="form-control-plaintext">
                      {{ getSelectedEventLabels() }}
                    </div>
                    <div v-else class="d-flex flex-column gap-2">
                      <div class="d-flex flex-wrap gap-2">
                        <button
                          v-for="event in event_cd_obj"
                          :key="event.id"
                          type="button"
                          class="btn btn-light btn-outline-secondary"
                          :class="{
                            active: isEventSelected(event.id),
                            'is-invalid': v$?.data_obj?.event_relation?.$error,
                          }"
                          @click="toggleEvent(event)"
                        >
                          {{ event.label }}
                        </button>
                      </div>
                      <div
                        class="invalid-feedback d-block"
                        v-if="v$?.data_obj?.event_relation?.$error"
                      >
                        {{ getErrorMessage('event_relation') }}
                      </div>
                      <div
                        class="form-text"
                        v-if="data_obj.event_relation?.length > 0"
                      >
                        選択された種目: {{ getSelectedEventLabels() }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row pb-2 mb-2">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    チームホームページ
                  </h4>
                  <div class="col-12 col-md-8">
                    <p v-if="confirm_mode" class="form-control-plaintext">
                      {{ data_obj.website_url_1 }}
                    </p>
                    <input
                      v-else
                      type="url"
                      :class="[
                        'form-control',
                        { 'is-invalid': v$?.data_obj?.website_url_1?.$error },
                      ]"
                      v-model="data_obj.website_url_1"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="v$?.data_obj?.website_url_1?.$error"
                    >
                      {{ getErrorMessage('website_url_1') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-4 mb-3 mb-md-2 text-center">
              <button
                class="button03 w-100"
                @click="back(confirm_mode ? 'profile' : '')"
              >
                <font-awesome-icon
                  :icon="['fas', 'chevron-left']"
                  class="mx-2"
                />
                <span class="button03__text">戻る</span>
              </button>
            </div>
            <div
              class="text-center col-12 order-1 order-md-2 col-md-6 offset-md-0 mb-2"
            >
              <button
                class="button04 w-100"
                @click="confirm_mode ? proceed() : confirmForm()"
              >
                <span class="button__text">
                  <span class="button04__text">{{
                    confirm_mode ? '登録する' : '確認する'
                  }}</span>
                  <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                    class="mx-2"
                  />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.375rem;
  }

  .button03--selected {
    background-color: #007bff !important;
    color: white !important;
    border-color: #0056b3 !important;
  }

  /* ホバー時のスタイル */
  .button03--selected:hover {
    background-color: #0056b3 !important;
    border-color: #004085 !important;
  }

  /* アクティブ時のスタイル */
  .button03--selected:active {
    background-color: #004085 !important;
    border-color: #002752 !important;
  }

  .btn-activity {
    padding: 0.5rem;
    width: 100%;
    text-align: center;
    transition: all 0.3s ease;
  }

  .btn-outline-secondary:hover {
    background-color: #e9ecef;
    border-color: #6c757d;
  }

  .is-invalid {
    border-color: #dc3545;
  }

  .text-danger {
    color: #dc3545;
  }

  /* .input-group {
    flex-wrap: wrap;
  }

  .input-group .btn {
    flex: 0 0 auto;
    margin: 0.25rem;
  } */

  .btn.active {
    background-color: #0d6efd;
    border-color: #0d6efd;
    color: white;
  }

  .btn-outline-secondary:hover {
    background-color: #e9ecef;
  }

  .btn.is-invalid {
    border-color: #dc3545;
  }

  .input-group-addon {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
  }

  /* エラー表示時のアイコン位置調整 */
  .is-invalid + .input-group-addon {
    top: 30%;
  }

  /* エラー時の背景画像を無効化 */
  .form-control.is-invalid, .was-validated .form-control:invalid {
    background-image: none !important;
    padding-right: 0.75rem !important;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import StepView from './StepView.vue'
  // import BsSelect from '../../atoms/BsSelect.vue'
  import config from '../../../const'
  import { useVuelidate } from '@vuelidate/core'
  import {
    required,
    email,
    minLength,
    maxLength,
    helpers,
  } from '@vuelidate/validators'

  // バリデーションルールの定義
  const CUSTOM_VALIDATORS = {
    katakana: helpers.regex(/^[ァ-ヶー\s]*$/),
    tel: helpers.regex(/^\d{2,4}-\d{2,4}-\d{4}$/),
    postal: helpers.regex(/^\d{3}-\d{4}$/),
  }

  // ヘッダートークンキーの定義を追加
  const HEADER_TOKEN_KEY_USER = 'X-MFJ-USER-TOKEN'

  // 定数の外部化
  const formatToTwoDigits = (value) => value.toString().padStart(2, '0')
  const isValidPrefCode = (value) =>
    value && value.length === 2 && /^\d{2}$/.test(value)

  export default {
    name: 'ClubRegistFormView',
    components: {
      StepView,
      // BsSelect,
    },

    setup() {
      // バリデーションメッセージを setup 内で定義
      const VALIDATION_MESSAGES = {
        required: '必須項目です',
        minLength: '文字数が不足しています',
        maxLength: '文字数が超過しています',
        email: '有効なメールアドレスを入力してください',
        tel: '電話番号の形式が正しくありません',
        postal: '郵便番号の形式が正しくありません',
        katakana: 'カタカナで入力してください',
        sameAsPassword: 'パスワードが一致しません',
        pref_cd: '都道府県を選択してください',
        passwordFormat: 'パスワードは8〜30文字の半角英数字と記号を含む必要があります',
      }

      const rules = {
        data_obj: {
          login_id: {
            required,
            minLength: minLength(4),
            maxLength: maxLength(32),
          },
          name_1: { required },
          name_2: { required },
          name_3: { required },
          yomi_1: {
            required,
            // katakana: CUSTOM_VALIDATORS.katakana,
          },
          yomi_2: {
            required,
            katakana: CUSTOM_VALIDATORS.katakana,
          },
          yomi_3: {
            required,
            katakana: CUSTOM_VALIDATORS.katakana,
          },
          tel_1: {
            required,
            // tel: CUSTOM_VALIDATORS.tel,
          },
          tel_2: { tel: CUSTOM_VALIDATORS.tel },
          postal_cd: {
            required,
            // postal: CUSTOM_VALIDATORS.postal,
          },
          pref_cd: {
            required,
            validPrefCode: isValidPrefCode,
          },
          address_1: { required },
          // address_2: { required },
          mail_1: {
            required,
            email,
          },
          event_relation: {
            required,
            minLength: minLength(1),
          },
          login_pass: {
            required,
            passwordFormat: (value) => {
              if (!value) return true
              return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])[a-zA-Z0-9!@#$%^&*.]{8,30}$/.test(value)
            }
          },
          login_pass_confirmation: {
            required,
            sameAsPassword: (value, formData) => {
              console.log('パスワード:', formData.login_pass)
              console.log('確認用パスワード:', value)
              return value === formData.login_pass
            },
          },
        },
      }

      return {
        rules,
        VALIDATION_MESSAGES,
      }
    },

    data() {
      return {
        isPasswordVisible1: false,
        isPasswordVisible2: false,
        v$: useVuelidate(),
        page_mode: 'profile',
        error_mode: false,
        reject_mode: false,
        approval_mode: false,
        success_mode: false,
        update_result: false,
        update_error: false,
        message_class: 'info',
        pref_label: '',
        message: '',
        data_obj: {
          login_id: '',
          login_pass: '',
          login_pass_confirmation: '',
          name_1: '',
          name_2: '',
          name_3: '',
          yomi_1: '',
          yomi_2: '',
          yomi_3: '',
          tel_1: '',
          tel_2: '',
          postal_cd: '',
          pref_cd: '',
          address_1: '',
          address_2: '',
          mail_1: '',
          website_url_1: '',
          event_relation: [],
          term_year: '',
        },

        regist_obj: {},
        event_cd_obj: [],
        pref_cd_obj: [],
        application_data: {
          c_id: 0,
          application_check_type: 100,
          application_type: 21000,
          items: [],
        },
        application_obj: {},
        regist_id: 0,
        registMailAddress: '', // 登録済みメールアドレス用
        
      }
    },
    methods: {
      
      async loadRegistData() {
        try {
          const headers = {
            [HEADER_TOKEN_KEY_USER]: 'NON_LOGIN_ACCESS_TOKEN',
          }

          const params = {
            token: this.$route.query.token,
            stat: [1],
          }

          const response = await this.nonloginPosts_S(
            `${config.BASE_API_URL}/club_regist/lists`,
            params,
            headers
          )
          console.log('loadRegistData', response)
          // レスポンスデータをパースして確認
          let responseData
          try {
            responseData =
              typeof response.data === 'string'
                ? JSON.parse(
                    response.data.substring(response.data.indexOf('{'))
                  )
                : response.data
          } catch (e) {
            console.error('レスポンスデータのパースエラー:', e)
            throw new Error('データの形式が不正です')
          }

          if (responseData?.status === 200 && responseData.body?.[0]) {
            const registData = responseData.body[0]
            if (registData?.mail_address) {
              this.registMailAddress = registData.mail_address
              this.data_obj.mail_1 = this.registMailAddress
              console.log('設定されたメールアドレス:', this.registMailAddress)
              console.log('設定されたメールアドレス1234!!!:', this.data_obj.mail_1)
            }
            if(registData?.app_data && typeof registData.app_data === 'object'){
              this.page_mode = (registData.app_data?.application_check_type == 600)
              ? 'remand'
              : (registData.app_data?.application_check_type == 120 || registData.app_data?.application_check_type == 100)
              ? 'mfj_check'
              : (registData.app_data?.application_check_type == 500)
              ? 'finish'
              : 'profile'
              this.application_obj = {...registData.app_data}
              console.log('ここが正常', this.page_mode)
            }
            else{
              if(
                this.get_base_env == 'development'
              ){
                const test_login_id = Math.random().toString(32).substring(2) + '1234'
                this.data_obj = {
                  login_id: test_login_id,
                  login_pass: test_login_id + '!',
                  login_pass_confirmation: test_login_id + '!',
                  name_1: 'クラブ' + test_login_id + '',
                  name_2: 'つくば',
                  name_3: 'たろう',
                  yomi_1: 'クラブカナ',
                  yomi_2: 'ツクバ',
                  yomi_3: 'タロウ',
                  tel_1: '090-6767-5656',
                  tel_2: '',
                  postal_cd: '631-0153',
                  pref_cd: '13',
                  address_1: 'address 124',
                  address_2: '',
                  mail_1: this.registMailAddress,
                  website_url_1: 'https://test.com',
                  event_relation: [],
                  term_year: this.term_year,
                }
              }
            }
            if (
              registData?.app_data && typeof registData.app_data === 'object'
              && Array.isArray(registData?.app_data?.items)
            ){
              const tmps = {...this.data_obj}
              registData.app_data.items.forEach(item => {
                if(item.application_key && item.application_key.indexOf("@") !== -1 && item.application_key.split('@').length > 1){
                  const parts = item.application_key.split('@')
                  if(tmps[parts[1]] && parts[1] == 'event_relation'){
                    tmps[parts[1]] = (this.is_json(item.application_value))
                      ? JSON.parse(item.application_value)
                      : Array.isArray(item.application_value)
                      ? item.application_value
                      : item.application_value
                  }
                  else if(tmps[parts[1]] !== undefined){
                    tmps[parts[1]] = item.application_value
                  }
                }
              })
              this.data_obj = tmps
              this.data_obj.login_pass_confirmation = ''
            }
            this.regist_id = registData?.id
          } else {
            console.warn('データが見つかりませんでした')
            this.error_mode = true
            this.message_class = 'warning'
            this.message = 'データが見つかりませんでした'
          }
        } catch (error) {
          console.error('loadRegistData error:', error)
          this.error_mode = true
          this.message_class = 'danger'
          this.message = 'エラーが発生しました'
        }
      },
      // 郵便番号検索
      async searchAddress() {
        if (!this.data_obj.postal_cd) return

        try {
          const response = await this.nonloginPosts_S(
            `${config.BASE_API_URL}/zipcode/search`,
            { zipcode: this.data_obj.postal_cd }
          )

          if (response.data?.status === 200 && response.data.body[0]) {
            const address = response.data.body[0]
            console.log('address', address)
            this.data_obj.pref_cd = address.pref_cd
            this.data_obj.address_1 = address.address || ''
          }
        } catch (error) {
          this.handleError(error)
        }
      },
      // バリデーション関連
      async validateForm() {
        const result = await this.v$.$validate()
        if (!result) {
          console.log('Validation errors:', this.v$.$errors)
          return false
        }
        return true
      },
      // エラーハンドリング
      handleError(error) {
        this.message_class = 'danger'
        this.message = error.response?.data?.message || 'エラーが発生しました'
        this.update_error = true
        console.error('Error:', error)
        // エラー発生時も画面上部へスクロール
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      },
      // データ読み込み
      async loadInitialData() {
        try {
          // 年度情報の取得
          const termYearResponse = await this.nonloginPosts_S(
            `${config.BASE_API_URL}/term_year/club_continue_lists`,
            {}
          )
          if (termYearResponse.data?.status === 200) {
            this.term_year = termYearResponse.data.body[0]?.label || ''
          }

          // 申請種別の取得
          const configResponse = await this.nonloginPosts_S(
            `${config.BASE_API_URL}/config/application_key`,
            {}
          )
          if (configResponse.data?.status === 200) {
            this.application_keys = configResponse.data.body
          }

          // イベントコードの取得
          await this.loadEventCdObj()

          // 都道府県データの取得
          this.pref_cd_obj = await this.loadBasicConfigObj('pref')
          console.log('都道府県データ取得完了:', this.pref_cd_obj)
        } catch (error) {
          console.error('データ取得エラー:', error)
          this.handleError(error)
        }
      },
      // フォーム送信
      async proceed() {
        const isValid = await this.validateForm()
        if (!isValid) {
          return
        }

        try {
          const post_url = (typeof this.application_obj === 'object' && this.application_obj?.id > 0)
          ? `${config.BASE_API_URL}/club_application/edit/${this.application_obj.id}`
          : `${config.BASE_API_URL}/club_application/add`
          const applicationData = this.createApplicationData()
          applicationData.regist_id = this.regist_id
          const response = await this.nonloginPosts_I(
            post_url,
            applicationData
          )

          if (response.data?.status === 200) {
            this.page_mode = 'mfj_check'
            this.update_result = true
          }
        } catch (error) {
          this.handleError(error)
        }
      },
      // 申請データの作成
      createApplicationData() {
        const items = [
          { key: 'mfj_club_data@login_id', value: this.data_obj.login_id },
          { key: 'mfj_club_data@login_pass', value: this.data_obj.login_pass },
          { key: 'mfj_club_data@name_1', value: this.data_obj.name_1 },
          { key: 'mfj_club_data@name_2', value: this.data_obj.name_2 },
          { key: 'mfj_club_data@name_3', value: this.data_obj.name_3 },
          { key: 'mfj_club_data@yomi_1', value: this.data_obj.yomi_1 },
          { key: 'mfj_club_data@yomi_2', value: this.data_obj.yomi_2 },
          { key: 'mfj_club_data@yomi_3', value: this.data_obj.yomi_3 },
          {
            key: 'mfj_outdb_data@deligate_license_no',
            value: this.data_obj.deligate_license_no,
          },
          { key: 'mfj_club_profile@postal_cd', value: this.data_obj.postal_cd },
          { key: 'mfj_club_profile@pref_cd', value: this.data_obj.pref_cd },
          { key: 'mfj_club_profile@address_1', value: this.data_obj.address_1 },
          { key: 'mfj_club_profile@address_2', value: this.data_obj.address_2 },
          { key: 'mfj_club_profile@tel_1', value: this.data_obj.tel_1 },
          { key: 'mfj_club_profile@tel_2', value: this.data_obj.tel_2 },
          { key: 'mfj_club_profile@mail_1', value: this.data_obj.mail_1 },
          { key: 'mfj_club_profile@mail_2', value: this.data_obj.mail_2 },
          {
            key: 'mfj_club_profile@website_url_1',
            value: this.data_obj.website_url_1,
          },
          {
            key: 'mfj_club_profile@website_url_2',
            value: this.data_obj.website_url_2,
          },
          {
            key: 'mfj_outdb_data@event_relation',
            value: JSON.stringify(this.data_obj.event_relation),
          },
        ]

        const params = (typeof this.application_obj === 'object' && this.application_obj?.id > 0)
          ? {
            application_check_type: 120, // 基本は100、再申請時は120
          }
          : {
            c_id: 0, // 新規登録時は0
            regist_id: this.regist_id, // registのIDを指定
            application_check_type: 100, // 基本は100、再申請時は120
            application_type: 21000, // 固定値
          }

        return {...params, ...{
          items: items
            .filter((item) => item.value != null && item.value !== '')
            .map((item) => ({
              stat: 1, // 固定値
              application_id: 0, // 新規登録時は0
              application_key: item.key,
              application_value: item.value,
            })),
        }}
      },
      // 電話番号フォーマット
      formatPhoneNumber(value) {
        if (!value) return ''
        return value.replace(/[^\d-]/g, '')
      },
      back(mode) {
        if (mode === 'profile') {
          this.page_mode = 'profile'
          // 確認モードをリセット
          this.v$.$reset()
        }
      },
      async loadEventCdObj() {
        try {
          const response = await this.nonloginPosts_S(
            `${config.BASE_API_URL}/event_cd/lists`,
            { stat: [1] }
          )
          if (response.data?.status === 200) {
            this.event_cd_obj = response.data.body || []
            console.log('Loaded events:', this.event_cd_obj)
          }
        } catch (error) {
          console.error('Error loading events:', error)
          this.event_cd_obj = []
        }
      },
      // 入力値が変更されたときの処理
      async validateField(fieldName) {
        console.log(
          `Validating field: ${fieldName}, value:`,
          this.data_obj[fieldName]
        )

        if (fieldName === 'login_pass') {
          await this.v$.data_obj.login_pass.$validate()
          // パスワードが変更された場合、確認用パスワードも再検証
          if (this.data_obj.login_pass_confirmation) {
            await this.v$.data_obj.login_pass_confirmation.$validate()
          }
        }

        if (fieldName === 'login_pass_confirmation') {
          await this.v$.data_obj.login_pass_confirmation.$validate()
        }

        if (fieldName === 'pref_cd') {
          console.log(
            '都道府県バリデーション - 現在の値:',
            this.data_obj.pref_cd
          )
          if (!this.data_obj.pref_cd) {
            console.log('都道府県が未選択です')
          }
        }
        console.log('this.v$', this.v$)
        console.log(`this.v$.data_obj[${fieldName}]`, this.v$?.data_obj?.[fieldName])
        await this.v$?.data_obj[fieldName]?.$touch()
        console.log(
          `Validation result for ${fieldName}:`,
          this.v$?.data_obj[fieldName]?.$error
        )
      },
      // 郵便番号のフォーマットチェック
      async postal_cd_chk() {
        const tmp = (this.data_obj || {}).postal_cd
          ? (this.data_obj || {}).postal_cd.match(/([0-9-])/g)
          : ''
        this.data_obj.postal_cd =
          tmp != null && typeof tmp === 'object' ? tmp.join('') : ''
      },
      // 郵便番号から住所検索
      async get_address_param() {
        const postal_cd = this.data_obj.postal_cd
        await this.get_address(postal_cd)
      },
      // 住所検索API呼び出し
      async get_address(postal_cd) {
        try {
          const response = await this.fetchAddressData(postal_cd)
          if (this.isValidAddressResponse(response)) {
            this.updateAddressData(response.data.results[0])
          } else {
            this.showAddressError()
          }
        } catch (error) {
          this.handleAddressError(error)
        }
      },
      async fetchAddressData(postal_cd) {
        return await this.basicGets_O(
          `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postal_cd}`
        )
      },
      isValidAddressResponse(response) {
        return response?.status === 200 && response.data?.results?.[0]
      },
      updateAddressData(result) {
        const prefCode = formatToTwoDigits(result.prefcode)
        console.log('郵便番号検索による都道府県コード:', prefCode)

        this.data_obj.pref_cd = prefCode
        this.data_obj.address_1 = result.address2 + result.address3
        this.data_obj.address_2 = ''

        this.$nextTick(() => this.validateField('pref_cd'))
      },
      showAddressError() {
        alert('住所データがありません')
      },
      handleAddressError(error) {
        console.error('住所検索エラー:', error)
        alert('住所データがありません.')
      },
      showSuccessMessage() {
        this.message = '登録が完了しました'
        this.message_class = 'success'
      },
      showErrorMessage(message) {
        this.message = message || 'エラーが発生しました'
        this.message_class = 'danger'
      },
      // 活動種目の選択状態をチェック
      isEventSelected(eventCode) {
        return (
          Array.isArray(this.data_obj.event_relation) &&
          this.data_obj.event_relation.some((code) => parseInt(code) === parseInt(eventCode))
        )
      },
      // 活動種目の選択/解除
      toggleEvent(event) {
        if (this.confirm_mode) return


        // event.event_cd を使用
        // event.id
        const eventCode = parseInt(event.id)
        if (!eventCode) {
          console.error('Invalid event code:', event)
          return
        }

        // 配列が未初期化の場合は初期化
        if (!Array.isArray(this.data_obj.event_relation)) {
          this.data_obj.event_relation = []
        }

        const now_count = Array.isArray(this.data_obj?.event_relation)
          ? this.data_obj.event_relation.length: 0

        const currentIndex = this.data_obj.event_relation.findIndex(
          (code) => parseInt(code) === eventCode
        )

        if(currentIndex === -1 && now_count >= 2){
          alert('2件まで申請できます')
          return
        }
        if (currentIndex === -1) {
          // 選択されていない場合は追加
          this.data_obj.event_relation = [
            ...this.data_obj.event_relation,
            eventCode,
          ]
        } else {
          // 既に選択されている場合は削除
          this.data_obj.event_relation = this.data_obj.event_relation.filter(
            (code) => parseInt(code) !== eventCode
          )
        }

        console.log('Event toggled:', eventCode)
        console.log('Current selection:', this.data_obj.event_relation)
      },
      // エラーメッセージの取得
      getErrorMessage(fieldName) {
        const field = this.v$?.data_obj[fieldName]
        if (!field?.$error) return ''

        if (
          fieldName === 'event_relation' &&
          field.$errors[0]?.$validator === 'minLength'
        ) {
            return '少なくとも1つの活動種目を選択してください'
        }

        const firstError = field.$errors[0]
        return (
          this.VALIDATION_MESSAGES[firstError.$validator] ||
          'エラーが発生しています'
        )
      },
      // 選択された活動種目のラベルを取得
      getSelectedEventLabels() {
        if (
          !Array.isArray(this.data_obj.event_relation) ||
          this.data_obj.event_relation.length === 0
        ) {
          return ''
        }
        return this.filteredEvents.map((event) => event.label).join('、')
      },
      // 都道府県選択時の新しいハンドラーメソッド
      onPrefectureChange(event) {
        const value = event.target.value
        console.log('選択された都道府県コード:', value)

        this.data_obj.pref_cd = value ? formatToTwoDigits(value) : ''
        console.log('設定された都道府県コード:', this.data_obj.pref_cd)
        this.pref_label = this.pref_cd_obj.find(
          (pref) => pref.code === value
        )?.label
        console.log('設定された都道府県ラベル:', this.pref_label)
        this.validateField('pref_cd')
      },
      // 都道府県データ読み込みメソッドを追加
      async loadPrefectureData() {
        try {
          const prefData = await this.loadBasicConfigObj('pref')
          if (Array.isArray(prefData)) {
            this.pref_cd_obj = this.transformPrefectureData(prefData)
            console.log('都道府県データ変換後:', this.pref_cd_obj)
          }
        } catch (error) {
          console.error('都道府県データ読み込みエラー:', error)
          this.handleError(error)
        }
      },
      transformPrefectureData(prefData) {
        return prefData.map((pref) => ({
          ...pref,
          code: formatToTwoDigits(pref.key),
        }))
      },
      async confirmForm() {
        try {
          // バリデーションチェック
          const isValid = await this.v$.$validate()
          console.log('バリデーション結果:', isValid)

          if (!isValid) {
            console.log('バリデーションエラー:', this.v$.$errors)
            return
          }

          // 確認画面モードに切り替え
          this.page_mode = 'confirm'

          // 都道府県名を設定
          const selectedPref = this.pref_cd_obj.find(
            (pref) => pref.code === this.data_obj.pref_cd
          )
          if (selectedPref) {
            this.pref_label = selectedPref.label
          }

          // 画面上部にスクロール
          window.scrollTo({ top: 0, behavior: 'smooth' })
        } catch (error) {
          console.error('確認画面遷移エラー:', error)
          this.handleError(error)
        }
      },
      // 戻るボタンの処理
      backForm() {
        // 入力画面に戻る
        this.page_mode = 'member'
        // 画面上部にスクロール
        window.scrollTo({ top: 0, behavior: 'smooth' })
      },
      // 登録処理
      async submitApplication() {
        try {
          // フォームのバリデーション
          const isValid = await this.v$.$validate()
          if (!isValid) {
            console.log('バリデーションエラー:', this.v$.$errors)
            return
          }

          // 送信するデータの作成
          const applicationData = this.createApplicationData()
          applicationData.regist_id = this.regist_id
          const params = {
            iprm: applicationData,
          }

          console.log('送信データ:', params)

          // APIリクエスト
          const response = await this.nonloginPosts_I(
            `${config.BASE_API_URL}/club_application/add`,
            params
          )

          if (response.data?.status === 200) {
            this.showSuccessMessage()
            this.page_mode = 'mfj_check'
            window.scrollTo({ top: 0, behavior: 'smooth' })
          } else {
            this.showErrorMessage(
              response.data?.message || '登録に失敗しました'
            )
          }
        } catch (error) {
          console.error('送信エラー:', error)
          this.handleError(error)
        }
      },
      // パスワード表示切り替え
      togglePasswordVisibility1() {
        this.isPasswordVisible1 = !this.isPasswordVisible1
      },
      togglePasswordVisibility2() {
        this.isPasswordVisible2 = !this.isPasswordVisible2
      },
    },
    computed: {
      ...mapState([]),

      // ページモード関連の計算プロパティ
      profile_mode() {
        return this.page_mode === 'profile'
      },

      confirm_mode() {
        return this.page_mode === 'confirm'
      },

      remand_mode() {
        return this.page_mode === 'remand'
      },


      finish_mode() {
        return this.page_mode === 'finish'
      },
      check_mode() {
        return this.page_mode === 'mfj_check'
      },

      input_class() {
        return this.page_mode === 'confirm'
          ? 'form-control-plaintext'
          : 'form-control'
      },

      input_readonly() {
        return this.page_mode === 'confirm'
      },

      formValidation() {
        return {
          isValid: () => {
            // バリデーションロジック
            return true
          },
          errors: {},
        }
      },

      isFormValid() {
        return !this.v$.$error
      },

      event_list() {
        const lists = [...this.event_cd_obj]
        return lists.sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },

      message_class_style() {
        const message_class = this.message_class
        return function (target) {
          if (target == 'border') {
            return (
              'text-center py-2 bs_fs_5 mb-2 border-bottom border-' +
              message_class
            )
          } else if (target == 'alert') {
            return 'alert alert-' + message_class
          }
        }
      },

      // 都道府県リストの生成
      pref_cd_list() {
        const lists = [...this.pref_cd_obj]
        return lists.sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },

      // 選択された活動種目のみをフィルタリング
      filteredEvents() {
        if (
          !Array.isArray(this.event_cd_obj) ||
          !Array.isArray(this.data_obj.event_relation)
        ) {
          return []
        }
        return this.event_cd_obj.filter(
          (event) =>
            event &&
            event.id &&
            this.data_obj.event_relation.includes(parseInt(event.id))
        )
      },

      messageTitle() {
        if (this.error_mode) return 'エラー'
        if (this.reject_mode) return '承認拒否'
        if (this.approval_mode) return '承認'
        if (this.success_mode) return '承認'
        if (this.check_mode) return 'MFJ確認中'
        return ''
      },
    },
    watch: {
      data_obj: {
        deep: true,
        handler() {
          this.v$.$reset()
        },
      },
      // 'data_obj.tel_1': function (newVal) {
      //   this.data_obj.tel_1 = this.formatPhoneNumber(newVal)
      // },
      // 'data_obj.tel_2': function (newVal) {
      //   this.data_obj.tel_2 = this.formatPhoneNumber(newVal)
      // },
      'data_obj.login_pass': function () {
        this.v$?.data_obj?.login_pass_confirmation?.$touch()
      },
      'data_obj.login_pass_confirmation': function () {
        this.v$?.data_obj?.login_pass_confirmation?.$touch()
      },
      'data_obj.pref_cd': {
        handler(newVal) {
          console.log('都道府県ウォッチャー - 新しい値:', newVal)
          if (newVal !== undefined) {
            this.validateField('pref_cd')
          }
        },
      },
    },
    async created() {
      try {
        // 登録データを最初に読み込む
        await this.loadRegistData()
        await this.loadPrefectureData() // 都道府県データを読み込む

        const [termYearResponse, configResponse] = await Promise.all([
        // this.nonloginPosts_S(`${config.BASE_API_URL}/term_year/club_continue_lists`, {}),
          this.nonloginPosts_S(`${config.BASE_API_URL}/term_year/lists`, {}),
          this.nonloginPosts_S(
            `${config.BASE_API_URL}/config/application_key`,
            {}
          ),
        ])

        if (termYearResponse.data?.status === 200) {
          this.data_obj.term_year = termYearResponse.data.body[0]?.label || ''
        }

        if (configResponse.data?.status === 200) {
          this.application_keys = configResponse.data.body
        }

        await this.loadEventCdObj()
      } catch (error) {
        console.error('初期化エラー:', error)
        this.handleError(error)
      }
    },
    mounted() {
      this.v$ = useVuelidate(this.rules, this)
    },
    props: {},
  }
</script>

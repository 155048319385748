<template>
  <section class="page">
    <div class="container">
      <h3 class="title02 page__h2">ログインID変更</h3>
      <div class="box box--center mb-24">
        <p>{{ message }}</p>
      </div>
      <div class="mb-40">
        <div class="mb-32">
          <div class="row gy-24">
            <div class="col-md-6">
              <h4 class="title08 d-block mb-24">現在のログインID</h4>
              <div class="page__inputGroup">
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <span class="page__inputLabel page__inputGroupLabel">ログインID</span>
                    <span class="showData page__inputGroupInput">{{ data_obj.login_id }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h4 class="title08 d-block mb-24">変更するログインID</h4>
              <div class="page__inputGroup">
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel" for="input041">ログインID</label>
                    <input type="text" v-model="change_login_id" :class="input_class" :readonly="input_readonly" />
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel" for="input042">確認</label>
                    <input type="text" v-model="confirm_login_id" :class="input_class" :readonly="input_readonly" />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex flex-column flex-md-row mt-32 justify-content-center column-gap-24">
            <button
              class="button03"
              @click="back_input"
              v-if="change_mode"
            >
              <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
              <span class="button03__text">
                戻る
              </span>
            </button>
            <button
              class="button03"
              @click="back"
              v-else
            >
              <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
              <span class="button03__text">
                戻る
              </span>
            </button>

              <button
                v-if="change_mode"
                type="button"
                @click="update"
                class="button04"
              >
                <span class="button04__text">
                  更新する
                </span>
                <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
              </button>
              <button
                v-else
                type="button"
                @click="confirm_chk"
                class="button04"
              >
                <span class="button04__text">
                  確認する
                </span>
                <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
              </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.375rem;
    border-bottom: solid 1px #999;
  }
  .form-control-plaintext {
    border-bottom: solid 1px #999;
  }
</style>
<script>
  import { mapState } from 'vuex'
  // import BsPageTitle from '../../molecules/BsPageTitle.vue'
  // import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
  // import BsSelect from '../../atoms/BsSelect.vue'
  // import BsCheckBox from '../../atoms/BsCheckBox.vue'
  // import '../../assets/bootstrap/css/sc.css';
  import config from '../../../const'

  export default {
    name: 'OwnerLoginIdView',
    data: function () {
      return {
        targetid: this.$store.state.oauth.userId,
        btnLabel: '',
        data_obj: {},
        change_login_id: '',
        confirm_login_id: '',
        change_mode: false,
        message: 'ログインIDを変更します。',
      }
    },
    components: {
      //   BsPageTitle,
      //   BsSelect,
      //   BsMsgAlert,
      // BsCheckBox,
    },
    methods: {
      async loadOwnerObj() {
        const params = {}
        params['stat'] = [1]
        params['id'] = this.$store.state.oauth.userId
        await this.basicPosts_S(config.BASE_API_URL + '/owner/lists', params)
          .then(async (response) => {
            console.log('loadOwnerObj', response)
            this.data_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async confirm_chk() {
        if (this.change_login_id == '' || this.confirm_login_id == '') {
          alert('変更するログインIDを入力して下さい')
          return true
        } else if (
          this.change_login_id != '' &&
          this.confirm_login_id != '' &&
          this.change_login_id != this.confirm_login_id
        ) {
          alert('入力されたログインIDが一致しません')
          return true
        } else if (
          !/^[a-zA-Z0-9]{8,30}$/.test(
            this.change_login_id
          )
        ) {
          alert('ログインIDは8〜30文字の半角英数字を含む必要があります。')
          return true
        }

        const params = {}
        params['stat'] = [1]
        params['not_ids'] = [this.$store.state.oauth.userId]
        params['login_id'] = this.change_login_id
        await this.basicPosts_S(config.BASE_API_URL + '/owner/check', params)
          .then(async (response) => {
            console.log('confirm_chk', response)

            if (
              typeof response === 'object' &&
              typeof response.data === 'object' &&
              response.data.status == '200' &&
              response.data.body == true
            ) {
              console.log('confirm_chk 更新できない')
              this.message = '更新できません。別のIDを入力してください。'
              alert('入力していただいたログインIDでは更新できません')
            } else {
              this.message = '更新できます。'
              console.log('confirm_chk 更新できる', response)
              this.change_mode = true
            }
            // this.data_obj = (response.data && response.data.body && response.data.body[0])? response.data.body[0]: {};
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async update() {
        console.log('component methods', this.data_obj)
        const params = {}
        params.login_id = this.change_login_id
        await this.basicPosts_I(
          config.BASE_API_URL + '/owner/edit/' + this.$store.state.oauth.userId,
          params
        )
          .then(async (response) => {
            console.log('update', response)
            if (
              typeof response === 'object' &&
              typeof response.data === 'object' &&
              response.data.status == '200'
            ) {
              this.message = '更新しました'
            } else {
              this.message = '更新できませんでした。'
            }
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
            this.message = '更新できませんでした。'
          })
          .finally(async () => {
            this.$forceUpdate()
          })

        //   return this.$store.dispatch('updateBasic', {data: this.data_obj, target: 'owner'})
        //     .then(() => {
        //       this.alertMessage = this.btnLabel + 'しました';
        //       this.alertClass = 'alert-success';
        //     })
        //     .catch((err) => {
        //       Promise.reject(err);
        //       this.alertMessage = this.btnLabel + 'できませんでした';
        //       this.alertClass = 'alert-danger';
        //     })
        //     .finally(async () => {
        //       await this.loadStaff();
        //     })
      },
      back_input() {
        this.change_mode = false
      },
      back() {
        this.$router.go(-1)
      },
    },
    computed: {
      ...mapState({}),
      input_class: function () {
        if (this.change_mode) {
          return 'form-control-plaintext'
        } else {
          return 'form-control'
        }
      },
      input_readonly: function () {
        if (this.change_mode) {
          return true
        } else {
          return false
        }
      },
    },

    async created() {
      await this.loadOwnerObj()
    },
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
    props: {},
  }
</script>

<template>
  <section class="page">
    <div class="container">
      <h3 class="title02 page__h2">ライセンス取得履歴</h3>
      <div class="box box--center mb-24">
        <p>{{ message }}</p>
      </div>
      <div class="mb-40">
        <table class="table table-striped">
          <thead>
            <tr>
              <th><h4 class="title06">年度</h4></th>
              <th><h4 class="title06">ライセンス</h4></th>
              <th><h4 class="title06">開始日</h4></th>
              <th><h4 class="title06">終了日</h4></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="license in license_list" :key="license">
              <td>{{ license.license_year }}</td>
              <td>{{ license.license_label }}</td>
              <td>{{ license.license_term_start_date }}</td>
              <td>{{ license.license_term_end_date }}</td>
              <!-- <td>{{ get_label_list(app.application_type, application_type_list) }}</td>
              <td>{{ get_label_list(app.application_check_type, application_check_type_list) }}</td> -->
              <!-- <td>ライセンス/ライセンス</td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mb-40" v-if="false">
        <table class="table table-striped">
          <thead>
            <tr>
              <th><h4 class="title06">申請日時</h4></th>
              <th><h4 class="title06">年度</h4></th>
              <th><h4 class="title06">申請種別</h4></th>
              <th><h4 class="title06">状態</h4></th>
              <th><h4 class="title06">ライセンス</h4></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="app in application_list" :key="app.application_date_date">
              <td>{{ app.application_date_date }}</td>
              <td>2024</td>
              <td>{{ get_label_list(app.application_type, application_type_list) }}</td>
              <td>{{ get_label_list(app.application_check_type, application_check_type_list) }}</td>
              <td>ライセンス/ライセンス</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import config from '../../../const'

export default {
  name: 'UserLicenseHistoryView',
  data: function () {
    return {
      targetid: this.$store.state.auth.userId,
      btnLabel: '',
      data_obj: {},
      form_obj: {},
      change_login_id: '',
      confirm_login_id: '',
      change_mode: true,
      message: 'ライセンス取得履歴です',
      pref_obj: [],
      sex_type_obj: [],
      request_type_cd_obj: [],
      application_obj: [],
      application_type_obj: [],
      application_check_type_obj: [],
      shipping_address_obj: [],
      member_license: [],
    }
  },
  components: {
  },
  methods: {
    async loadConfigObj(param) {
      const params = {}
      await this.basicPosts_S(
        config.BASE_API_URL + '/config/' + param,
        params
      )
        .then(async (response) => {
          console.log('loadConfigObj response', response)
          this[param + '_obj'] =
            response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          this[param + '_obj'] = []
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    async loadApplicationTypeList() {
      const params = {}
      await this.basicPosts_S(
        config.BASE_API_URL + '/config/application_type',
        params
      )
        .then(async (response) => {
          console.log('loadApplicationTypeList response', response)
          this.application_type_obj =
            response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          this.application_type_obj = []
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    async loadApplicationCheckTypeList() {
      const params = {}
      await this.basicPosts_S(
        config.BASE_API_URL + '/config/application_check_type',
        params
      )
        .then(async (response) => {
          console.log('loadAffiliationTypeList response', response)
          this.application_check_type_obj =
            response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          this.application_check_type_obj = []
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    async loadPrefList() {
      const params = {}
      params['stat'] = [1]
      await this.basicPosts_S(
        config.BASE_API_URL + '/area_pref/lists',
        params
      )
        .then(async (response) => {
          console.log('loadPrefList response', response)
          this.pref_obj =
            response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          this.pref_obj = []
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    async loadShippingObj() {
      const params = {}
      params['stat'] = [1]
      params['m_id'] = this.$store.state.auth.userId
      params['pagegroup'] = 'mfj_member_shipping_address.id'
      await this.basicPosts_S(
        config.BASE_API_URL + '/member_shipping/lists',
        params
      )
        .then(async (response) => {
          console.log('loadShippingObj', response)
          this.shipping_address_obj =
            response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          this.shipping_address_obj = []
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    async loadUserObj() {
      const params = {}
      params['stat'] = [1]
      params['id'] = this.$store.state.auth.userId
      await this.basicPosts_S(config.BASE_API_URL + '/member/lists', params)
        .then(async (response) => {
          console.log('loadUserObj', response)
          this.data_obj =
            response.data && response.data.body && response.data.body[0]
              ? response.data.body[0]
              : {}
        })
        .catch(async (err) => {
          console.log(err)
          this.data_obj = []
        })
        .finally(async () => {
          await this.setUserObj()
          this.$forceUpdate()
        })
    },
    async loadApplicationObj() {
      const params = {}
      params['stat'] = [1]
      params['m_id'] = this.$store.state.auth.userId
      params['application_types'] = [1000, 1300, 1400]

      await this.basicPosts_S(
        config.BASE_API_URL + '/member_application/lists',
        params
      )
        .then(async (response) => {
          console.log('loadApplicationObj', response)
          this.application_obj =
            response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          this.application_obj = []
        })
        .finally(async () => {
          await this.setUserObj()
          this.$forceUpdate()
        })
    },
    async setUserObj() {
      if (this.data_obj.tel_1 == '--') {
        this.data_obj.tel_1 = ''
      }
      if (this.data_obj.tel_2 == '--') {
        this.data_obj.tel_2 = ''
      }
      if (
        typeof this.data_obj.postal_cd === 'string' &&
        this.data_obj.postal_cd.indexOf('-') < 0 &&
        this.data_obj.postal_cd.length > 3
      ) {
        const start_code = this.data_obj.postal_cd.substr(0, 3)
        const end_code = this.data_obj.postal_cd.substr(3)
        this.data_obj.postal_cd = start_code + '-' + end_code
      }

      this.form_obj = {
        name_1: this.data_obj.name_1,
        name_2: this.data_obj.name_2,
        yomi_1: this.data_obj.yomi_1,
        yomi_2: this.data_obj.yomi_2,
        birth_date: this.data_obj.birth_date,
        sex_type: this.data_obj.sex_type,
        sex_type_label: this.data_obj.sex_type_label,
        postal_cd: this.data_obj.postal_cd,
        pref_cd: this.data_obj.pref_cd,
        address_1: this.data_obj.address_1,
        address_2: this.data_obj.address_2,
        tel_1: this.data_obj.tel_1,
        tel_2: this.data_obj.tel_2,
        member_assets: this.data_obj.member_assets,
      }

      this.member_license = this.data_obj?.member_license
    },
    async confirm_chk() {
      this.change_mode = true
    },
    async update() {
      console.log('component methods', this.data_obj)

      const params = {
        name_1: this.form_obj.name_1,
        name_2: this.form_obj.name_2,
        yomi_1: this.form_obj.yomi_1,
        yomi_2: this.form_obj.yomi_2,
        birth_date: this.form_obj.birth_date,
        profile: [],
        member_assets: this.form_obj.member_assets,
      }
      let profiles
      let upd_profile = {
        id: 0,
        m_id: this.$store.state.auth.userId,
        postal_cd: '',
        pref_cd: '',
        address_1: '',
        address_2: '',
        tel_1: '',
        tel_2: '',
      }
      if (
        typeof this.data_obj === 'object' &&
        typeof this.data_obj.profile === 'object' &&
        Object.keys(this.data_obj.profile).length > 0
      ) {
        profiles = [...this.data_obj.profile]
        upd_profile = profiles[0]
      } else {
        profiles.push({ ...upd_profile })
      }
      if (this.form_obj.postal_cd) {
        upd_profile.postal_cd = this.form_obj.postal_cd
      }
      if (this.form_obj.pref_cd) {
        upd_profile.pref_cd = this.form_obj.pref_cd
      }
      if (this.form_obj.address_1) {
        upd_profile.address_1 = this.form_obj.address_1
      }
      if (this.form_obj.address_2) {
        upd_profile.address_2 = this.form_obj.address_2
      }
      if (this.form_obj.tel_1) {
        upd_profile.tel_1 = this.form_obj.tel_1
      }
      if (this.form_obj.tel_2) {
        upd_profile.tel_2 = this.form_obj.tel_2
      }

      profiles[0] = upd_profile

      params.profile = profiles

      await this.basicPosts_I(
        config.BASE_API_URL + '/member/edit/' + this.$store.state.auth.userId,
        params
      )
        .then(async (response) => {
          console.log('update', response)
        })
        .catch(async (err) => {
          console.log(err)
          this.data_obj = []
        })
        .finally(async () => {
          await this.loadUserObj()
          this.message = '更新しました'
          this.$forceUpdate()
        })
    },
    back_input() {
      this.change_mode = false
    },
    back() {
      this.$router.push({ path: '/' })
    },
  },
  computed: {
    license_list: function(){
      const tmps = [...this.member_license]
      return tmps.sort((a, b) => {
        if (parseInt(a.license_year) < parseInt(b.license_year)) {
          return -1
        } else if (parseInt(a.license_category) < parseInt(b.license_category)) {
          return -1
        } else{
          return 1
        }
      })
    },
    ...mapState({}),
    get_label_list: function () {
      return function (code, lists) {
        if (
          typeof lists === 'object' &&
          lists.find((list) => list.key == code)
        ) {
          return (lists.find((list) => list.key == code) || {}).label
        }
        return code
      }
    },
    get_assets: function () {
      const form_obj = this.form_obj
      return function (type_cd, sort_key) {
        if (
          typeof form_obj === 'object' &&
          typeof form_obj.member_assets === 'object' &&
          form_obj.member_assets.find((asset) => asset.type == type_cd) !==
          false &&
          typeof form_obj.member_assets.filter(
            (asset) => asset.type == type_cd
          ) === 'object' &&
          typeof form_obj.member_assets.filter(
            (asset) => asset.type == type_cd
          )[sort_key] === 'object'
        ) {
          return form_obj.member_assets.filter(
            (asset) => asset.type == type_cd
          )[sort_key]
        }
        return {}
      }
    },
    get_sex_label: function () {
      const sex_type_list = this.sex_type_list
      return function (key) {
        if (typeof sex_type_list.find((sex) => sex.key == key) === 'object') {
          return sex_type_list.find((sex) => sex.key == key).label
        }
        return key
      }
    },
    get_pref_label: function () {
      const pref_list = this.pref_list
      return function (key) {
        if (typeof pref_list.find((pref) => pref.key == key) === 'object') {
          return pref_list.find((pref) => pref.key == key).label
        }
        return key
      }
    },
    get_request_cd_label: function () {
      const lists = this.request_type_list
      return function (key) {
        if (typeof lists.find((list) => list.key == key) === 'object') {
          return lists.find((list) => list.key == key).label
        }
        return key
      }
    },
    sex_type_list: function () {
      return this.sex_type_obj
    },
    request_type_list: function () {
      return this.request_type_cd_obj
    },
    pref_list: function () {
      const lists = [...this.pref_obj]
      return lists.sort((a, b) => {
        if (parseInt(a.sort) < parseInt(b.sort)) {
          return -1
        } else {
          return 1
        }
      })
    },
    application_type_list: function () {
      return this.application_type_obj
    },
    application_check_type_list: function () {
      return this.application_check_type_obj
    },
    application_list: function () {
      return this.application_obj
    },
    application_filter: function () {
      const app_list = this.application_list
      return function (app_type, app_chk_list) {
        return app_list.filter((app) => {
          if (
            app.application_type == app_type &&
            app_chk_list.includes(app.application_check_type) !== false
          ) {
            return true
          }
          return false
        })
      }
    },
    get_target_application: function () {
      const member_application = this.application_list
      return function (app_type) {
        return member_application.find(
          (app) => app.application_type == app_type
        )
      }
    },
    get_target_application_btn_class: function () {
      const get_func = this.get_target_application
      const application_btn_class = this.application_btn_class
      return function (app_type) {
        const filtered = get_func(app_type)
        console.log('get_target_application_btn_class', app_type, filtered)
        return application_btn_class(
          (filtered || {}).application_check_type
            ? filtered.application_check_type
            : false
        )
      }
    },

    application_btn_class: function () {
      return function (check_type) {
        let base = 'btn btn-sm '
        base +=
          check_type == 100
            ? 'btn-light btn-outline-secondary'
            : check_type == 200
              ? 'btn-light btn-outline-secondary'
              : check_type == 500
                ? 'btn-success'
                : check_type == 600
                  ? 'btn-warning'
                  : check_type == 900
                    ? 'btn-danger'
                    : check_type == 990
                      ? 'btn-danger'
                      : 'btn-light btn-outline-secondary'
        return base
      }
    },
    application_btn_label: function () {
      const get_func = this.get_target_application
      const application_type_list = this.application_type_list
      return function (app_type) {
        const application_type = application_type_list.find(
          (type) => type.key == app_type
        )
        const type_label = (application_type || {}).label

        const filtered = get_func(app_type)
        let chk_label = false
        if (typeof filtered === 'object' && filtered.application_check_type) {
          chk_label =
            filtered.application_check_type == 100
              ? '申請中です'
              : filtered.application_check_type == 200
                ? '確認中です'
                : filtered.application_check_type == 500
                  ? '承認されました'
                  : filtered.application_check_type == 600
                    ? '差し戻しがあります。'
                    : filtered.application_check_type == 900
                      ? '不許可です'
                      : filtered.application_check_type == 990
                        ? '編集禁止です'
                        : '変更して下さい'
        }
        return chk_label ? chk_label : type_label
      }
    },
    shipping_address_list: function () {
      return this.shipping_address_obj.filter((shipping, index) => {
        if (typeof shipping === 'object' && index == 0) {
          return true
        }
      })
    },
    get_member_data: function () {
      return {
        name_1: this.data_obj.name_1,
        name_2: this.data_obj.name_2,
        yomi_1: this.data_obj.yomi_1,
        yomi_2: this.data_obj.yomi_2,
        birth_date: this.data_obj.birth_date,
        postal_cd: this.data_obj.birth_date,
        pref_cd: this.data_obj.birth_date,
        address_1: this.data_obj.address_1,
        address_2: this.data_obj.address_2,
        tel_1: this.data_obj.tel_1,
        tel_2: this.data_obj.tel_2,
        mail_1: this.data_obj.mail_1,
        mail_2: this.data_obj.mail_2,
        mail_flg: this.data_obj.mail_flg,
      }
    },
    input_class: function () {
      if (this.change_mode) {
        return 'form-control-plaintext'
      } else {
        return 'form-control'
      }
    },
    input_readonly: function () {
      if (this.change_mode) {
        return true
      } else {
        return false
      }
    },
  },
  async created() {
    await this.loadConfigObj('request_type_cd')
    await this.loadConfigObj('sex_type')
    await this.loadConfigObj('application_type')
    await this.loadConfigObj('application_check_type')
    await this.loadApplicationCheckTypeList()
    await this.loadApplicationTypeList()
    await this.loadShippingObj()
    await this.loadPrefList()
    await this.loadUserObj()
    await this.loadApplicationObj()
    await this.setUserObj()
  },
  mounted: function () {
  },
  props: {},
}
</script>

import config from '../const'
import axios from 'axios'
export default {
  name: 'globalActions',
  computed: {
    get_auth: function () {
      return this.get_load_type == 'owner'
        ? this.$store.state.oauth
        : this.get_load_type == 'club'
        ? this.$store.state.cauth
        : this.get_load_type == 'member'
        ? this.$store.state.auth
        : this.$store.state.auth
    },
    get_header_key: function () {
      return this.get_load_type == 'owner'
        ? config.BASE_API_OWNER_HEADER_KEY
        : this.get_load_type == 'club'
        ? config.BASE_API_CLUB_HEADER_KEY
        : this.get_load_type == 'member'
        ? config.BASE_API_USER_HEADER_KEY
        : config.BASE_API_USER_HEADER_KEY
    },
    axiosHeader: function () {
      return { [this.get_header_key]: (this.get_auth || {}).token }
    },
    cHeader: function () {
      return { headers: this.axiosHeader }
    },
    axiosHeaderNonToken: function () {
      return { [config.BASE_API_USER_HEADER_KEY]: 'NON_LOGIN_ACCESS_TOKEN' }
    },
    nonloginHeader: function () {
      return { headers: this.axiosHeaderNonToken }
    },
    gmoHeader: function(){
      return { headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + config.GMO_AUTH_BASIC_KEY
      } }
    },
  },
  methods: {
    setParams(params, mode) {
      const params_ = new URLSearchParams()
      if (params) {
        const keys = Object.keys(params)
        for (let i = 0; i < keys.length; i++) {
          if (
            keys[i] == 'p'
            // || keys[i].indexOf('page') >= 0
          ) {
            params_.append(keys[i], params[keys[i]])
          } else if (
            params[keys[i]] !== null &&
            typeof params[keys[i]] === 'object'
          ) {
            params_.append(
              mode + '[' + keys[i] + ']',
              JSON.stringify(params[keys[i]])
            )
          } else {
            params_.append(mode + '[' + keys[i] + ']', params[keys[i]])
          }
        }
      }
      return params_
    },
    setParams_S(params) {
      return this.setParams(params, 'sprm')
    },
    setParams_I(params) {
      return this.setParams(params, 'iprm')
    },
    async basicGets_O(post_url) {
      const res = await this.basicGets(post_url, false)
      // console.log('globalActionsTest basicGets_O res', res);
      return res
    },
    async nonloginPosts_S(post_url, params) {
      const res = await this.nonloginPosts(
        post_url,
        this.setParams_S(params),
        true
      )
      // console.log('globalActionsTest nonloginPosts_S res', res);
      return res
    },
    async nonloginPosts_I(post_url, params) {
      const res = await this.nonloginPosts(
        post_url,
        this.setParams_I(params),
        true
      )
      // console.log('globalActionsTest nonloginPosts_I res', res);
      return res
    },
    async nonloginPosts_R(post_url, params) {
      const res = await this.nonloginPosts(post_url, params, true)
      // console.log('globalActionsTest basicPosts_R res', res);
      return res
    },
    async basicPosts_R(post_url, params) {
      const res = await this.basicPosts(post_url, params, true)
      // console.log('globalActionsTest basicPosts_R res', res);
      return res
    },
    async basicPosts_S(post_url, params) {
      const res = await this.basicPosts(
        post_url,
        this.setParams_S(params),
        true
      )
      // console.log('globalActionsTest basicPosts_S res', res);
      return res
    },
    async basicPosts_I(post_url, params) {
      // console.log('globalActionsTest basicPosts_I start');
      const res = await this.basicPosts(
        post_url,
        this.setParams_I(params),
        true
      ).finally(async () => {
        // console.log('globalActionsTest basicPosts_I finally');
        await this.scrollToAction()
      })

      // console.log('globalActionsTest basicPosts_I return ', res);
      return res
    },
    async nonloginPosts(post_url, params, inner) {
      return await axios
        .post(
          post_url,
          // this.setParams_S(params),
          params,
          inner ? this.nonloginHeader : {}
        )
        .then(async (response) => {
          if (
            typeof response === 'object' &&
            typeof response.data === 'object' &&
            parseInt(response.data.status) === 401
          ) {
            // this.$router.push({ path: '/logout' });
          }
          // console.log('globalActionsTest nonloginPosts response', response);
          return response
        })
        .catch(async (err) => {
          // console.log('globalActionsTest nonloginPosts err', err);
          throw err
        })
        .finally(async () => {
          // console.log('globalActionsTest nonloginPosts finally');
          // return true;
        })
    },
    async basicPosts(post_url, params, inner) {
      console.log('basicPosts', this.$store.state)
      return await axios
        .post(
          post_url,
          // this.setParams_S(params),
          params,
          inner ? this.cHeader : {}
        )
        .then(async (response) => {
          console.log('globalActionsTest basicPosts response', response);
          if (
            typeof response === 'object' &&
            typeof response.data === 'object' &&
            parseInt(response.data.status) === 401
          ) {
            console.log('globalActionsTest basicPosts get_logout', this.get_logout);
            this.$router.push({ path: '/' + this.get_logout })
          }
          return response
        })
        .catch(async (err) => {
          // console.log('globalActionsTest basicPosts err', err);
          throw err
        })
        .finally(async () => {
          // console.log('globalActionsTest basicPosts finally');
          // return true;
        })
    },
    async basicGets(post_url, inner) {
      console.log('basicGets', post_url, inner)
      return await axios
        .get(
          post_url,
          // this.setParams_S(params),
          // params,
          inner ? this.cHeader : {}
        )
        .then(async (response) => {
          if (
            inner === true &&
            typeof response === 'object' &&
            typeof response.data === 'object' &&
            parseInt(response.data.status) === 401
          ) {
            this.$router.push({ path: '/logout' })
          }
          // console.log('globalActionsTest basicPosts response', response);
          return response
        })
        .catch(async (err) => {
          // console.log('globalActionsTest basicPosts err', err);
          throw err
        })
        .finally(async () => {
          // console.log('globalActionsTest basicPosts finally');
          // return true;
        })
    },
    async outServPosts(post_url, params){
      return await axios
        .post(
          (post_url)? post_url: config.GMO_AUTH_BASIC_URL,
          // this.setParams_S(params),
          params,
          this.gmoHeader
        )
        .then(async (response) => {
          console.log('outServPosts basicPosts response', response);
          if (
            typeof response === 'object' &&
            typeof response.data === 'object' &&
            typeof response.data.body === 'object'
          ) {
            return response
          }
          return false
        })
        .catch(async (err) => {
          // console.log('globalActionsTest basicPosts err', err);
          throw err
        })
        .finally(async () => {
          // console.log('globalActionsTest basicPosts finally');
          // return true;
        })
    },
    async scrollToAction() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    async readAsTextReader(file) {
      const reader = new FileReader()
      return new Promise((resolve, reject) => {
        reader.onloadstart = (ev) => {
          console.log(`onloadstart: total=${ev.loaded}/${ev.loaded}`)
        }

        reader.onloadend = (ev) => {
          console.log(`onloadend: total=${ev.loaded}/${ev.loaded}`)
        }

        reader.onprogress = (ev) => {
          console.log(`onprogress: total=${ev.loaded}/${ev.loaded}`)
        }

        reader.onerror = () => {
          reader.abort()
          reject('Unknown error occurred during reading the file')
        }

        reader.onload = () => {
          console.log('onload')
          resolve(reader.result)
        }

        reader.readAsText(file)
      })
    },
    async loadBasicConfigObj(param) {
      console.log('loadConfigObj param', param)
      const params = {}
      return await this.nonloginPosts_S(
        config.BASE_API_URL + '/config/' + param,
        params
      )
        .then(async (response) => {
          console.log('loadConfigObj response', response)
          return response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          return false
        })
        .finally(async () => {
          // this.$forceUpdate();
        })
    },
  },
}

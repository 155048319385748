<template>
  <section class="py-5">
    <div class="container">
      <div class="row mb-5">
        <div class="col-10 offset-1 col-xl-8 offset-xl-2">
          <div class="row">
            <div class="col-12">
              <div class="mb-2 py-3">
                <h5 class="title">パスワード再設定</h5>
              </div>
              <div class="py-3">
                <p class="alert alert-warning" v-html="message">
                </p>
              </div>
            </div>
          </div>
          <div class="row" v-if="passwd_mode == 'put_passcd'">
            <div class="col-12 text-start mb-3">
              <label for="inputEmail" class="d-lg-none mb-2"
                >クラブコード</label
              >
              <div class="input-group">
                <label
                  for="inputEmail"
                  class="d-none d-lg-flex input-group-text text-center"
                  style="width: 40%"
                  >クラブコード</label
                >
                <input
                  type="text"
                  v-model="login_id"
                  class="form-control"
                  placeholder="クラブコード"
                />
              </div>
            </div>
            <div class="col-12 text-start mb-3">
              <label for="inputBirthDate" class="d-lg-none mb-2"
                >郵便番号</label
              >
              <div class="input-group">
                <label
                  for="inputEmail"
                  class="d-none d-lg-flex input-group-text text-center"
                  style="width: 40%"
                  >郵便番号</label
                >
                <input
                  type="text"
                  v-model="postal_cd"
                  class="form-control"
                  placeholder="郵便番号"
                />
              </div>
            </div>
            <div class="col-12 text-start mb-3 d-none">
              <label for="inputBirthDate" class="d-lg-none mb-2"
                >メールアドレス</label
              >
              <div class="input-group">
                <label
                  for="inputEmail"
                  class="d-none d-lg-flex input-group-text text-center"
                  style="width: 40%"
                  >メールアドレス</label
                >
                <input
                  type="mail"
                  v-model="mail"
                  class="form-control"
                  placeholder="メールアドレス"
                />
              </div>
            </div>
            <div class="col-12 text-start">
              <div class="row">
                <div class="col-4">
                  <button
                    type="button"
                    class="btn btn-light btn-outline-secondary"
                    @click="back(0)"
                  >
                    ログインへ戻る
                  </button>
                </div>
                <div class="col-4">
                  <div class="d-grid">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="next_mode('chk')"
                    >
                      次へ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else-if="passwd_mode == 'changed_passwd'">
            <div class="col-12 text-start">
              <div class="row">
                <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                  <div class="d-grid">
                    <button
                      type="button"
                      class="btn btn-light btn-outline-secondary"
                      @click="back(0)"
                    >
                      ログインへ戻る
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else-if="passwd_mode == 'chk_passcd'">
            <div class="col-12 text-start mb-3">
              <label for="inputEmail" class="d-lg-none mb-2">確認No.</label>
              <div class="input-group">
                <label
                  for="inputEmail"
                  class="d-none d-lg-flex input-group-text text-center"
                  style="width: 40%"
                  >確認No.</label
                >
                <input type="text" v-model="passcd[0]" class="form-control" />
                <input type="text" v-model="passcd[1]" class="form-control" />
                <input type="text" v-model="passcd[2]" class="form-control" />
                <input type="text" v-model="passcd[3]" class="form-control" />
              </div>
            </div>
            <div class="col-12 text-start">
              <div class="row">
                <div class="col-4">
                  <button
                    type="button"
                    class="btn btn-light btn-outline-secondary"
                    @click="back('put')"
                  >
                    戻る
                  </button>
                </div>
                <div class="col-4">
                  <div class="d-grid">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="next_mode('chg')"
                    >
                      次へ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else-if="passwd_mode == 'chg_passwd'">
            <div class="col-12 text-start mb-3">
              <label for="inputPasswd" class="d-lg-none mb-2">パスワード</label>
              <div class="input-group">
                <label
                  for="inputPasswd"
                  class="d-none d-lg-flex input-group-text text-center"
                  style="width: 30%"
                  >パスワード</label
                >
                <input
                  type="text"
                  v-model="passwd"
                  class="form-control"
                  placeholder="パスワード"
                />
              </div>
            </div>
            <div class="col-12 text-start mb-3">
              <label for="inputBirthDate" class="d-lg-none mb-2"
                >パスワード(確認)</label
              >
              <div class="input-group">
                <label
                  for="inputEmail"
                  class="d-none d-lg-flex input-group-text text-center"
                  style="width: 30%"
                  >パスワード(確認)</label
                >
                <input
                  type="text"
                  v-model="passwd_chk"
                  class="form-control"
                  placeholder="パスワード(確認)"
                />
              </div>
            </div>
            <div class="col-12 text-start">
              <div class="row">
                <div class="col-4">
                  <button
                    type="button"
                    class="btn btn-light btn-outline-secondary"
                    @click="back('chk')"
                  >
                    戻る
                  </button>
                </div>
                <div class="col-4">
                  <div class="d-grid">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="next_mode('fin')"
                    >
                      変更
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else-if="passwd_mode == 'fin_passwd'">
            <div class="col-12 text-start">
              <div class="row">
                <div class="col-4"></div>
                <div class="col-4">
                  <div class="d-grid">
                    <button
                      type="button"
                      class="btn btn-light btn-outline-secondary"
                      @click="back(0)"
                    >
                      ログインへ戻る
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.375rem;
    border-bottom: solid 1px #999;
  }
  .form-control-plaintext {
    border-bottom: solid 1px #999;
  }
</style>
<script>
  import { mapState } from 'vuex'
  // import BsPageTitle from '../../molecules/BsPageTitle.vue'
  // import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
  // import BsSelect from '../../atoms/BsSelect.vue'
  // import BsCheckBox from '../../atoms/BsCheckBox.vue'
  // import '../../assets/bootstrap/css/sc.css';
  import config from '../../../const'
  // import moment from "moment"
  // import Common from '../../../common/common'

  export default {
    name: 'PassCdView',
    data: function () {
      return {
        btnLabel: '',
        passcd: ['', '', '', ''],
        mail: '',
        chk_passcd_md5: '',
        passwd: '',
        passwd_chk: '',
        login_id: '',
        postal_cd: '',
        passwd_mode: 'put_passcd',
        message: 'パスワードを再設定します。<br>クラブコード、登録された郵便番号を入力して下さい。<br>わからない方は<a href="https://www.mfj.or.jp/other/about/contact/">こちら</a>からお問い合わせ下さい。',
      }
    },
    components: {
      //   BsPageTitle,
      //   BsSelect,
      //   BsMsgAlert,
      // BsCheckBox,
    },
    methods: {
      async back(target) {
        if (target == 0) {
          // this.$router.go(-1)
          this.$router.push({ path: '/clogin' })
        } else if (target == 'put') {
          this.passwd_mode = 'put_passcd'
        } else if (target == 'chk') {
          this.passwd_mode = 'chk_passcd'
        } else if (target == 'chg') {
          this.passwd_mode = 'chg_passwd'
        }
      },
      async next_mode(target) {
        if (target == 'chk') {
          if (!this.login_id || !this.postal_cd) {
            alert('クラブコード\n郵便番号を入力して下さい')
            return false
          }
          await this.sendPassCd().then((response) => {
            console.log(
              'next_mode sendPassCd response',
              response.data.body.status
            )
            if (
              response &&
              response.data &&
              response.data.body &&
              response.data.body.status == 200
            ) {
              this.message =
                // '登録いただいているアドレス宛てに確認No.を送信しました。確認Noを入力欄に入力後、パスワードの再設定をお願いします。'
                '登録いただいているアドレス宛にパスワードを送信しました。ログイン画面からパスワードを入力してログインして下さい。'
              this.passwd_mode = 'changed_passwd'
              // this.passwd_mode = 'chk_passcd'
            } else {
              this.message = 'パスワード発行エラー'
            }
          })
        } 
        // else if (target == 'chg') {
        //   if (!this.login_id || !this.postal_cd) {
        //     alert('クラブコード\n郵便番号を入力して下さい')
        //     return false
        //   } else if (
        //     !this.passcd[0] ||
        //     !this.passcd[1] ||
        //     !this.passcd[2] ||
        //     !this.passcd[3]
        //   ) {
        //     alert('確認Noを入力して下さい')
        //     return false
        //   }

        //   await this.chkPassCd().then((response) => {
        //     console.log(
        //       'next_mode sendPassCd response',
        //       response.data.body.status
        //     )
        //     if (
        //       response &&
        //       response.data &&
        //       response.data.body &&
        //       response.data.body.status == 200
        //     ) {
        //       this.message = 'パスワードを入力して変更して下さい。'
        //       this.passwd_mode = 'chg_passwd'
        //     } else {
        //       this.message = 'パスコード確認エラー'
        //     }
        //   })
        // } else if (target == 'fin') {
        //   if (!this.login_id || !this.postal_cd) {
        //     alert('クラブコード\n郵便番号を入力して下さい')
        //     return false
        //   } else if (
        //     !this.passcd[0] ||
        //     !this.passcd[1] ||
        //     !this.passcd[2] ||
        //     !this.passcd[3]
        //   ) {
        //     alert('確認Noを入力して下さい')
        //     return false
        //   } else if (!this.passwd || !this.passwd_chk) {
        //     alert('パスワードを入力して下さい')
        //     return false
        //   } else if (this.passwd != this.passwd_chk) {
        //     alert('確認用パスワードと一致しません')
        //     return false
        //   }

        //   await this.chgPassWd().then((response) => {
        //     console.log(
        //       'next_mode sendPassCd response',
        //       response.data.body.status
        //     )
        //     if (
        //       response &&
        //       response.data &&
        //       response.data.body &&
        //       response.data.body.status == 200
        //     ) {
        //       this.message =
        //         'パスワードを変更しました。ログイン画面からログインをお願いします。'
        //       this.passwd_mode = 'fin_passwd'
        //     } else {
        //       this.message = 'パスワード変更エラー'
        //     }
        //   })
        // }
      },
      async chgPassWd() {
        const params = {}
        params['stat'] = [1]
        params['login_id_club_no'] = this.login_id
        params['postal_cd'] = this.postal_cd
        params['pass_cd'] = this.passcd.join('')
        params['pass_wd'] = this.passwd
        return await this.nonloginPosts_S(
          config.BASE_API_URL + '/clubs/chg_pass_wd',
          params
        )
          .then(async (response) => {
            console.log('chgPassWd!!!', response)
            this.chk_passcd_md5 = ''
            // this.data_obj = (response.data && response.data.body && response.data.body[0])? response.data.body[0]: {};
            return response
          })
          .catch(async (err) => {
            console.log(err)
            this.chk_passcd_md5 = ''
            // this.data_obj = [];
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async chkPassCd() {
        const params = {}
        params['stat'] = [1]
        params['login_id_club_no'] = this.login_id
        params['postal_cd'] = this.postal_cd
        params['pass_cd'] = this.passcd.join('')
        return await this.nonloginPosts_S(
          config.BASE_API_URL + '/clubs/chk_pass_cd',
          params
        )
          .then(async (response) => {
            console.log('chkPassCd!!!', response)
            this.chk_passcd_md5 = ''
            // this.data_obj = (response.data && response.data.body && response.data.body[0])? response.data.body[0]: {};
            return response
          })
          .catch(async (err) => {
            console.log(err)
            this.chk_passcd_md5 = ''
            // this.data_obj = [];
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async sendPassCd() {
        const params = {}
        params['stat'] = [1]
        params['login_id_club_no'] = this.login_id
        params['postal_cd'] = this.postal_cd
        return await this.nonloginPosts_S(
          config.BASE_API_URL + '/clubs/send_pass_cd',
          params
        )
          .then(async (response) => {
            console.log('sendPassCd!!!', response)
            this.chk_passcd_md5 = ''
            // this.data_obj = (response.data && response.data.body && response.data.body[0])? response.data.body[0]: {};
            return response
          })
          .catch(async (err) => {
            console.log(err)
            this.chk_passcd_md5 = ''
            // this.data_obj = [];
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      back_input() {
        this.change_mode = false
      },
      // back(){
      // }
    },
    computed: {
      ...mapState({}),
      input_class: function () {
        if (this.change_mode) {
          return 'form-control-plaintext'
        } else {
          return 'form-control'
        }
      },
      input_readonly: function () {
        if (this.change_mode) {
          return true
        } else {
          return false
        }
      },
    },

    async created() {
      // await this.loadUserObj();
    },
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
    props: {},
  }
</script>

<template>
  <section class="py-5">
    <step-view :step_mode="page_mode"></step-view>
    <div class="container">
      <div class="row">
        <div
          class="text-start col-10 offset-1 col-xl-6 offset-xl-3"
          v-if="kiyaku_mode"
        >
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-check"></i
                >&nbsp;以下の会員規約に同意してください。
              </div>
              <p>
                {{ message }}
              </p>
            </div>
          </div>
          <div class="mb-5">
            <div class="border border-secondary bg-light rounded-2 py-3 px-4">
              <p class="m-0">規約〜</p>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="text-center col-10 offset-1 col-xl-6 offset-xl-3">
                <button class="btn btn-primary" @click="proceed('mail')">
                  規約に同意して進む
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-start col-10 offset-1 col-xl-6 offset-xl-3"
          v-else-if="mail_mode"
        >
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-check"></i>&nbsp;メールアドレスの確認。
              </div>
              <p>
                {{ message }}
              </p>
            </div>
          </div>
          <div class="mb-5">
            <div class="border border-secondary bg-light rounded-2 py-3 px-4">
              <div class="form-row">
                <label for="email" class="form-label">メールアドレス</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="post_mail_address"
                  placeholder="touroku@mfj.or.jp"
                />
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div
                class="text-center col-6 offset-0 order-2 order-md-1 col-md-3 mb-2"
              >
                <button
                  class="btn btn-light btn-outline-secondary"
                  @click="back('kiyaku')"
                >
                  戻る
                </button>
              </div>
              <div
                class="text-center col-10 offset-1 order-1 order-md-2 col-md-6 offset-md-0 mb-2"
              >
                <button class="btn btn-primary" @click="send_pass_cd()">
                  メールアドレス宛に送信する
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-start col-10 offset-1 col-xl-6 offset-xl-3"
          v-else-if="passcd_mode"
        >
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-check"></i>&nbsp;確認Noの入力
              </div>
              <p>
                {{ message }}
              </p>
            </div>
          </div>
          <div class="mb-5">
            <div class="border border-secondary bg-light rounded-2 py-3 px-4">
              <div class="form-row">
                <label for="inputEmail" class="d-lg-none mb-2">確認No.</label>
                <div class="input-group">
                  <label
                    for="inputEmail"
                    class="d-none d-lg-flex input-group-text text-center"
                    style="width: 40%"
                    >確認No.</label
                  >
                  <input type="text" v-model="passcd[0]" class="form-control" />
                  <input type="text" v-model="passcd[1]" class="form-control" />
                  <input type="text" v-model="passcd[2]" class="form-control" />
                  <input type="text" v-model="passcd[3]" class="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div
                class="text-center col-6 offset-0 order-2 order-md-1 col-md-3 mb-2"
              >
                <button
                  class="btn btn-light btn-outline-secondary"
                  @click="back('mail')"
                >
                  戻る
                </button>
              </div>
              <div
                class="text-center col-10 offset-1 order-1 order-md-2 col-md-6 offset-md-0 mb-2"
              >
                <button class="btn btn-primary" @click="chk_pass_cd()">
                  進む
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.375rem;
    border-bottom: solid 1px #999;
  }
  .form-control-plaintext {
    border-bottom: solid 1px #999;
  }
</style>
<script>
  import { mapState } from 'vuex'
  import StepView from './StepView.vue'
  // import BsPageTitle from '../../molecules/BsPageTitle.vue'
  // import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
  // import BsSelect from '../../atoms/BsSelect.vue'
  // import BsCheckBox from '../../atoms/BsCheckBox.vue'
  // import '../../assets/bootstrap/css/sc.css';
  import config from '../../../const'

  export default {
    name: 'RegistAddView',
    data: function () {
      return {
        page_mode: 'kiyaku',
        update_result: false,
        update_error: false,
        regist_obj: {},
        data_obj: {},
        message_class: 'info',
        message: '',
        target_step: ['kiyaku', 'mail'],
        post_mail_address: '',
        passcd: ['', '', '', ''],
      }
    },
    components: {
      StepView,
      //   BsSelect,
      //   BsMsgAlert,
      // BsCheckBox,
    },
    methods: {
      proceed(mode) {
        this.page_mode = mode
        this.message = 'メールアドレスを入力して送信してください。'
      },
      back(mode) {
        this.message_class = 'info'
        this.page_mode = mode
      },
      async send_pass_cd() {
        if (!this.post_mail_address) {
          this.message_class = 'danger'
          this.message = 'メールアドレスは必須です。入力してください。'
          this.$forceUpdate()
          return true
        }
        const params = {}
        params['regist_mail_address'] = this.post_mail_address
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/owner_regist/send_pass_cd',
          params
        )
          .then(async (response) => {
            console.log('loadRegistData!!!', response)
            if (
              response.data &&
              response.data.status == 200 &&
              response.data.body &&
              response.data.body.status == 200
            ) {
              this.update_result = true
              this.update_error = false
              this.page_mode = 'passcd'
              this.message = '確認Noを入力してください'
              this.message_class = 'info'
              this.data_obj =
                response.data && response.data.body && response.data.body
                  ? response.data.body
                  : {}
            } else if (
              response.data &&
              response.data.status == 200 &&
              response.data.body &&
              response.data.body.status != 200
            ) {
              this.update_error = true
              this.message_class = 'danger'
              this.message = response.data.body.message
                ? response.data.body.message
                : 'メールアドレス宛に送信できませんでした。'
            } else {
              this.update_error = true
              this.message_class = 'danger'
              this.message = 'メールアドレス宛に送信できませんでした。。'
            }
          })
          .catch(async (err) => {
            this.update_result = false
            this.update_error = true
            this.message = err
            console.log('error', err)
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async chk_pass_cd() {
        if (!this.post_mail_address) {
          this.message_class = 'danger'
          this.message = 'メールアドレスは必須です。入力してください。'
          this.$forceUpdate()
          return true
        } else if (
          !this.passcd[0] ||
          !this.passcd[1] ||
          !this.passcd[2] ||
          !this.passcd[3]
        ) {
          alert('確認Noを入力して下さい')
          return false
        }
        const params = {}
        params['regist_mail_address'] = this.post_mail_address
        params['pass_cd'] = this.passcd.join('')
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/owner_regist/chk_pass_cd',
          params
        )
          .then(async (response) => {
            console.log('loadRegistData!!!', response)
            if (
              response.data &&
              response.data.status == 200 &&
              response.data.body &&
              response.data.body.status == 200
            ) {
              this.update_result = true
              this.update_error = false
              // this.page_mode = 'passcd';
              // this.message = 'パスコードを入力してください';
              // this.data_obj = (response.data && response.data.body && response.data.body)? response.data.body: {};
              // redirectさせる
              if (
                response.data &&
                response.data.body &&
                response.data.body.token
              ) {
                this.go_form(response.data.body.token)
              } else {
                alert('error')
              }
            } else if (
              response.data &&
              response.data.status == 200 &&
              response.data.body &&
              response.data.body.status != 200
            ) {
              this.update_error = true
              this.message_class = 'danger'
              this.message = response.data.body.message
                ? response.data.body.message
                : '確認Noが確認できませんでした。'
            } else {
              this.update_error = true
              this.message_class = 'danger'
              this.message = '確認Noが確認できませんでした。。'
            }
          })
          .catch(async (err) => {
            this.update_result = false
            this.update_error = true
            this.message = err
            console.log('error', err)
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async go_form(token) {
        this.$router.push({
          path: '/owner_regist/form',
          query: {
            token: token,
          },
        })
      },
      async loadRegistData() {
        const params = {}
        params['stat'] = [1]
        //   params['application_check_type'] = 100;
        //   params['application_type'] = 2500;
        params['token'] = this.$route.query.token
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/owner_regist/lists',
          params
        )
          .then(async (response) => {
            console.log('loadRegistData!!!', response)
            if (response.data && response.data.status == 200) {
              this.regist_obj =
                response.data && response.data.body && response.data.body[0]
                  ? response.data.body[0]
                  : {}
              if ((this.regist_obj || {}).step != 'add') {
                console.log('not add redirect')
              }
            } else {
              this.message = '規約を確認し、同意して申請を進めてください'
            }
          })
          .catch(async (err) => {
            this.message = err
            console.log('error', err)
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      // back(){
      // }
    },
    computed: {
      ...mapState({}),
      message_class_style: function () {
        const message_class = this.message_class
        return function (target) {
          if (target == 'border') {
            return (
              'text-center py-2 bs_fs_5 mb-2 border-bottom border-' +
              message_class
            )
          } else if (target == 'alert') {
            return 'alert alert-' + message_class
          }
        }
      },
      kiyaku_mode: function () {
        return this.page_mode == 'kiyaku' ? true : false
      },
      mail_mode: function () {
        return this.page_mode == 'mail' ? true : false
      },
      passcd_mode: function () {
        return this.page_mode == 'passcd' ? true : false
      },
    },

    async created() {
      await this.loadRegistData()
    },
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
    props: {},
  }
</script>

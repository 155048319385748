import TemplateView from '../components/templates/BsTemplateView.vue'
import Login from '../components/templates/BsLoginView.vue'
import Logout from '../components/templates/BsLogoutView.vue'
import Error from '../components/templates/BsErrorView.vue'
// import LogoutAll from '../components/templates/BsLogoutAllView.vue'

import OwnerLogin from '../components/templates/BsOwnerLoginView.vue'
import OwnerLogout from '../components/templates/BsOwnerLogoutView.vue'
import ClubLogin from '../components/templates/BsClubLoginView.vue'
import ClubLogout from '../components/templates/BsClubLogoutView.vue'
// import Passwd from '../components/templates/BsPasswdView.vue'

import RequestLicense from '../components/templates/Request/LicenseView.vue'
import Request3ds from '../components/templates/Request/CreditCard3dsForm.vue'
// import RequestContinue from '../components/templates/Request/ContinueView.vue'
// import RequestAdd from '../components/templates/Request/AddView.vue'
import ClubRequestContinue from '../components/templates/ClubRequest/ContinueView.vue'
import ClubRequestAdd from '../components/templates/ClubRequest/AddView.vue'
import OwnerRequestContinue from '../components/templates/OwnerRequest/ContinueView.vue'
import OwnerRequestAdd from '../components/templates/OwnerRequest/AddView.vue'

import RequestPromotion from '../components/templates/Request/PromotionView.vue'

import RegistAdd from '../components/templates/Regist/AddView.vue'
import RegistForm from '../components/templates/Regist/FormView.vue'
import RegistCharge from '../components/templates/Regist/ChargeView.vue'
import Regist3ds from '../components/templates/Regist/CreditCard3dsForm.vue'
import RegistResult from '../components/templates/Regist/ResultView.vue'

import MigrationAdd from '../components/templates/Migration/AddView.vue'
import MigrationForm from '../components/templates/Migration/FormView.vue'

import OwnerRegistAdd from '../components/templates/OwnerRegist/AddView.vue'
import OwnerRegistForm from '../components/templates/OwnerRegist/FormView.vue'
import OwnerRegistCharge from '../components/templates/OwnerRegist/ChargeView.vue'
import OwnerRegistResult from '../components/templates/OwnerRegist/ResultView.vue'

import ClubRegistAdd from '../components/templates/ClubRegist/AddView.vue'
import ClubRegistForm from '../components/templates/ClubRegist/FormView.vue'
import ClubRegistCharge from '../components/templates/ClubRegist/ChargeView.vue'
import ClubRegistResult from '../components/templates/ClubRegist/ResultView.vue'

import PasswdPasscd from '../components/templates/Passwd/PassCdView.vue'
import MailAddressCheck from '../components/templates/MailAddress/CheckView.vue'
import MailAddressClubCheck from '../components/templates/MailAddress/ClubCheckView.vue'
import MailAddressOwnerCheck from '../components/templates/MailAddress/OwnerCheckView.vue'

import PasswdClubPasscd from '../components/templates/Passwd/ClubPassCdView.vue'
import PasswdOwnerPasscd from '../components/templates/Passwd/OwnerPassCdView.vue'

// import PagesList from '../components/templates/BsDataListView.vue'
import DashBoard from '../components/templates/BsDashBoardView.vue'

import SeminarData from '../components/templates/User/DataView.vue'

import UserData from '../components/templates/User/DataView.vue'
import UserName from '../components/templates/User/NameView.vue'
import UserPhoto from '../components/templates/User/PhotoView.vue'
import UserAddress from '../components/templates/User/AddressView.vue'
import UserShipping from '../components/templates/User/ShippingView.vue'
import UserTel from '../components/templates/User/TelView.vue'
import UserMail from '../components/templates/User/MailView.vue'
import UserLoginId from '../components/templates/User/LoginIdView.vue'
import UserPassWd from '../components/templates/User/PassWdView.vue'
import UserLicenseHistory from '../components/templates/User/LicenseHistoryView.vue'
import UserLicenseSeminar from '../components/templates/User/LicenseSeminarView.vue'
import UserInformationList from '../components/templates/User/InformationList.vue'
import UserInformationData from '../components/templates/User/InformationData.vue'

import JoinClub from '../components/templates/User/JoinClubView.vue'

import OwnerMypage from '../components/templates/BsOwnerMypageView.vue'
import OwnerData from '../components/templates/Owner/DataView.vue'
import OwnerLicenseNo from '../components/templates/Owner/LicenseNoView.vue'
import OwnerName from '../components/templates/Owner/NameView.vue'
import OwnerNameD from '../components/templates/Owner/NameDView.vue'
import OwnerPhoto from '../components/templates/Owner/PhotoView.vue'
import OwnerAddress from '../components/templates/Owner/AddressView.vue'
import OwnerShipping from '../components/templates/Owner/ShippingView.vue'
import OwnerTel from '../components/templates/Owner/TelView.vue'
import OwnerMail from '../components/templates/Owner/MailView.vue'
import OwnerLoginId from '../components/templates/Owner/LoginIdView.vue'
import OwnerPassWd from '../components/templates/Owner/PassWdView.vue'
import OwnerInformationList from '../components/templates/Owner/InformationList.vue'
import OwnerInformationData from '../components/templates/Owner/InformationData.vue'
import OwnerSearchMember from '../components/templates/Owner/SearchMember.vue'
import OwnerEventLink from '../components/templates/Event/LinkView.vue'

import ClubMypage from '../components/templates/BsClubMypageView.vue'
import ClubData from '../components/templates/Club/DataView.vue'
import ClubMember from '../components/templates/Club/MemberView.vue'
import ClubName from '../components/templates/Club/NameView.vue'
import ClubNameD from '../components/templates/Club/NameDView.vue'
import ClubPhoto from '../components/templates/Club/PhotoView.vue'
import ClubAddress from '../components/templates/Club/AddressView.vue'
import ClubShipping from '../components/templates/Club/ShippingView.vue'
import ClubTel from '../components/templates/Club/TelView.vue'
import ClubLicenseEvent from '../components/templates/Club/LicenseEventView.vue'
import ClubEvent from '../components/templates/Club/EventView.vue'
import ClubWebSite from '../components/templates/Club/WebSiteVIew.vue'
import ClubMail from '../components/templates/Club/MailView.vue'
import ClubLoginId from '../components/templates/Club/LoginIdView.vue'
import ClubPassWd from '../components/templates/Club/PassWdView.vue'
import ClubInformationList from '../components/templates/Club/InformationList.vue'
import ClubInformationData from '../components/templates/Club/InformationData.vue'

export default [
  {
    path: '/',
    name: 'base',
    component: TemplateView,
    meta: {
      requiresAuth: true,
      auth_type: 'member',
    },
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: DashBoard,
      },
    ],
  },
  {
    path: '/edit',
    name: 'edit',
    component: TemplateView,
    meta: {
      requiresAuth: true,
      auth_type: 'member',
    },
    children: [
      {
        path: '/edit/data',
        name: 'edit_data',
        component: UserData,
      },
      {
        path: '/edit/name',
        name: 'edit_name',
        component: UserName,
      },
      {
        path: '/edit/photo',
        name: 'edit_photo',
        component: UserPhoto,
      },
      {
        path: '/edit/address',
        name: 'edit_address',
        component: UserAddress,
      },
      {
        path: '/edit/shipping',
        name: 'edit_shipping',
        component: UserShipping,
      },
      {
        path: '/edit/tel',
        name: 'edit_tel',
        component: UserTel,
      },
      {
        path: '/edit/login_id',
        name: 'edit_login_id',
        component: UserLoginId,
      },
      {
        path: '/edit/passwd',
        name: 'edit_passwd',
        component: UserPassWd,
      },
      {
        path: '/edit/mail',
        name: 'edit_mail',
        component: UserMail,
      },
      {
        path: '/license/history',
        name: 'license_history',
        component: UserLicenseHistory,
      },
      {
        path: '/license/seminar',
        name: 'license_seminar',
        component: UserLicenseSeminar,
      },
      {
        path: '/information/list',
        name: 'information_list',
        component: UserInformationList,
      },
      {
        path: '/information/data',
        name: 'information_data',
        component: UserInformationData,
      },
    ],
  },
  {
    path: '/seminar',
    name: 'seminar',
    component: TemplateView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/seminar/:id',
        name: 'seminar_detail',
        component: SeminarData,
      },
    ],
  },
  {
    path: '/request',
    name: 'request',
    component: TemplateView,
    meta: {
      requiresAuth: true,
      auth_type: 'member',
    },
    children: [
      {
        path: '/request/license',
        name: 'request_license',
        component: RequestLicense,
      },
      // 3DS
      {
        path: '/request/3ds',
        name: 'request_3ds',
        component: Request3ds,
      },
      // 3DS
      {
        path: '/request/promotion',
        name: 'request_promotion',
        component: RequestPromotion,
      },
      // {
      //   path: '/request/continue',
      //   name: 'request_continue',
      //   component: RequestContinue,
      // },
      // {
      //   path: '/request/add',
      //   name: 'request_add',
      //   component: RequestAdd,
      // },
    ],
  },
  {
    path: '/join',
    name: 'join',
    component: TemplateView,
    meta: {
      requiresAuth: true,
      auth_type: 'member',
    },
    children: [
      {
        path: '/join/club',
        name: 'join_club',
        component: JoinClub,
      },
    ],
  },

  // owner
  {
    path: '/',
    name: 'owner_base',
    component: TemplateView,
    meta: {
      requiresAuth: true,
      auth_type: 'owner',
    },
    children: [
      {
        path: '/owner/mypage',
        name: 'owner_mypage',
        component: OwnerMypage,
      },
    ],
  },
  {
    path: '/ologin',
    name: 'ologin',
    meta: {
      requiresAuth: false,
      auth_type: 'logout',
    },
    component: OwnerLogin,
  },
  {
    path: '/ologout',
    name: 'ologout',
    component: OwnerLogout,
  },
  {
    path: '/opasswd',
    name: 'opasswd',
    meta: {
      requiresAuth: false,
      auth_type: 'logout',
    },
    component: TemplateView,
    children: [
      {
        path: '/opasswd/passcd',
        name: 'opasswd_passcd',
        component: PasswdOwnerPasscd,
      },
    ],
  },
  {
    path: '/owner_edit',
    name: 'owner_edit',
    component: TemplateView,
    meta: {
      requiresAuth: true,
      auth_type: 'owner',
    },
    children: [
      {
        path: '/owner_edit/data',
        name: 'owner_data',
        component: OwnerData,
      },
      {
        path: '/owner_edit/name',
        name: 'owner_edit_name',
        component: OwnerName,
      },
      {
        path: '/owner_edit/named',
        name: 'owner_edit_named',
        component: OwnerNameD,
      },
      {
        path: '/owner_edit/license_no',
        name: 'owner_edit_license_no',
        component: OwnerLicenseNo,
      },
      {
        path: '/owner_edit/photo',
        name: 'owner_edit_photo',
        component: OwnerPhoto,
      },
      {
        path: '/owner_edit/address',
        name: 'owner_edit_address',
        component: OwnerAddress,
      },
      {
        path: '/owner_edit/shipping',
        name: 'owner_edit_shipping',
        component: OwnerShipping,
      },
      {
        path: '/owner_edit/tel',
        name: 'owner_edit_tel',
        component: OwnerTel,
      },
      {
        path: '/owner_edit/login_id',
        name: 'owner_edit_login_id',
        component: OwnerLoginId,
      },
      {
        path: '/owner_edit/passwd',
        name: 'owner_edit_passwd',
        component: OwnerPassWd,
      },
      {
        path: '/owner_edit/mail',
        name: 'owner_edit_mail',
        component: OwnerMail,
      },
      {
        path: '/oinformation/list',
        name: 'oinformation_list',
        component: OwnerInformationList,
      },
      {
        path: '/oinformation/data',
        name: 'oinformation_data',
        component: OwnerInformationData,
      },
      {
        path: '/event/link',
        name: 'oevent_link',
        component: OwnerEventLink,
      },
    ],
  },
  {
    path: '/owner_search',
    name: 'owner_search',
    component: TemplateView,
    meta: {
      requiresAuth: true,
      auth_type: 'owner',
    },
    children: [
      {
        path: '/owner_search/member',
        name: 'owner_search_member',
        component: OwnerSearchMember,
      },
    ],
  },
  {
    path: '/owner_request',
    name: 'owner_request',
    component: TemplateView,
    meta: {
      requiresAuth: true,
      auth_type: 'owner',
    },
    children: [
      {
        path: '/owner_request/continue',
        name: 'owner_request_continue',
        component: OwnerRequestContinue,
      },
      {
        path: '/owner_request/add',
        name: 'owner_request_add',
        component: OwnerRequestAdd,
      },
    ],
  },

  // club
  {
    path: '/',
    name: 'club_base',
    component: TemplateView,
    meta: {
      requiresAuth: true,
      auth_type: 'club',
    },
    children: [
      {
        path: '/club/mypage',
        name: 'club_mypage',
        component: ClubMypage,
      },
    ],
  },
  {
    path: '/clogin',
    name: 'clogin',
    meta: {
      requiresAuth: false,
      auth_type: 'logout',
    },
    component: ClubLogin,
  },
  {
    path: '/clogout',
    name: 'clogout',
    meta: {
      requiresAuth: false,
      auth_type: 'logout',
    },
    component: ClubLogout,
  },
  {
    path: '/cpasswd',
    name: 'cpasswd',
    meta: {
      requiresAuth: false,
      auth_type: 'logout',
    },
    component: TemplateView,
    children: [
      {
        path: '/cpasswd/passcd',
        name: 'cpasswd_passcd',
        component: PasswdClubPasscd,
      },
    ],
  },
  {
    path: '/club_edit',
    name: 'club_edit',
    component: TemplateView,
    meta: {
      requiresAuth: true,
      auth_type: 'club',
    },
    children: [
      {
        path: '/club_edit/data',
        name: 'club_data',
        component: ClubData,
      },
      {
        path: '/club_edit/member',
        name: 'club_edit_member',
        component: ClubMember,
      },
      {
        path: '/club_edit/name',
        name: 'club_edit_name',
        component: ClubName,
      },
      {
        path: '/club_edit/named',
        name: 'club_edit_named',
        component: ClubNameD,
      },
      {
        path: '/club_edit/photo',
        name: 'club_edit_photo',
        component: ClubPhoto,
      },
      {
        path: '/club_edit/address',
        name: 'club_edit_address',
        component: ClubAddress,
      },
      {
        path: '/club_edit/shipping',
        name: 'club_edit_shipping',
        component: ClubShipping,
      },
      {
        path: '/club_edit/tel',
        name: 'club_edit_tel',
        component: ClubTel,
      },
      {
        path: '/club_edit/license_event',
        name: 'club_edit_license_event',
        component: ClubLicenseEvent,
      },
      {
        path: '/club_edit/event',
        name: 'club_edit_event',
        component: ClubEvent,
      },
      {
        path: '/club_edit/website',
        name: 'club_edit_website',
        component: ClubWebSite,
      },
      {
        path: '/club_edit/login_id',
        name: 'club_edit_login_id',
        component: ClubLoginId,
      },
      {
        path: '/club_edit/passwd',
        name: 'club_edit_passwd',
        component: ClubPassWd,
      },
      {
        path: '/club_edit/mail',
        name: 'club_edit_mail',
        component: ClubMail,
      },
      {
        path: '/cinformation/list',
        name: 'cinformation_list',
        component: ClubInformationList,
      },
      {
        path: '/cinformation/data',
        name: 'cinformation_data',
        component: ClubInformationData,
      },
    ],
  },
  {
    path: '/club_request',
    name: 'club_request',
    component: TemplateView,
    meta: {
      requiresAuth: true,
      auth_type: 'club',
    },
    children: [
      {
        path: '/club_request/continue',
        name: 'club_request_continue',
        component: ClubRequestContinue,
      },
      {
        path: '/club_request/add',
        name: 'club_request_add',
        component: ClubRequestAdd,
      },
    ],
  },
  // member migration
  {
    path: '/migration',
    name: 'migration',
    meta: {
      requiresAuth: false,
      auth_type: 'logout',
    },
    component: TemplateView,
    children: [
      // 規約とメールアドレス入力
      {
        path: '/migration/add',
        name: 'migration_add',
        component: MigrationAdd,
      },
      // メールチェックー入力、申請
      {
        path: '/migration/form',
        name: 'migration_form',
        component: MigrationForm,
      },
    ],
  },

  // member regist
  {
    path: '/regist',
    name: 'regist',
    meta: {
      requiresAuth: false,
      auth_type: 'logout',
    },
    component: TemplateView,
    children: [
      // 規約とメールアドレス入力
      {
        path: '/regist/add',
        name: 'regist_add',
        component: RegistAdd,
      },
      // メールチェックーライセンス選択、入力、申請
      {
        path: '/regist/form',
        name: 'regist_form',
        component: RegistForm,
      },
      // 決済
      {
        path: '/regist/charge',
        name: 'regist_charge',
        component: RegistCharge,
      },
      // 3DS
      {
        path: '/regist/3ds',
        name: 'regist_3ds',
        component: Regist3ds,
      },
      // 結果
      {
        path: '/regist/result',
        name: 'regist_result',
        component: RegistResult,
      },
      // 講習会
    ],
  },
  // owner regist
  {
    path: '/owner_regist',
    name: 'owner_regist',
    meta: {
      requiresAuth: false,
      auth_type: 'logout',
    },
    component: TemplateView,
    children: [
      // 規約とメールアドレス入力
      {
        path: '/owner_regist/add',
        name: 'owner_regist_add',
        component: OwnerRegistAdd,
      },
      // メールチェックーライセンス選択、入力、申請
      {
        path: '/owner_regist/form',
        name: 'owner_regist_form',
        component: OwnerRegistForm,
      },
      // 決済
      {
        path: '/owner_regist/charge',
        name: 'owner_regist_charge',
        component: OwnerRegistCharge,
      },
      // 結果
      {
        path: '/owner_regist/result',
        name: 'owner_regist_result',
        component: OwnerRegistResult,
      },
      // 講習会
    ],
  },
  // club regist
  {
    path: '/club_regist',
    name: 'club_regist',
    meta: {
      requiresAuth: false,
      auth_type: 'logout',
    },
    component: TemplateView,
    children: [
      // 規約とメールアドレス入力
      {
        path: '/club_regist/add',
        name: 'club_regist_add',
        component: ClubRegistAdd,
      },
      // メールチェックーライセンス選択、入力、申請
      {
        path: '/club_regist/form',
        name: 'club_regist_form',
        component: ClubRegistForm,
      },
      // 決済
      {
        path: '/club_regist/charge',
        name: 'club_regist_charge',
        component: ClubRegistCharge,
      },
      // 結果
      {
        path: '/club_regist/result',
        name: 'club_regist_result',
        component: ClubRegistResult,
      },
      // 講習会
    ],
  },

  // user
  {
    path: '/login',
    name: 'login',
    meta: {
      requiresAuth: false,
      auth_type: 'logout',
    },
    component: Login,
  },
  // {
  //   path: '/logout',
  //   name: 'logoutall',
  //   meta: {
  //     requiresAuth: false,
  //     auth_type: 'logout',
  //   },
  //   component: LogoutAll,
  // },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      requiresAuth: false,
      auth_type: 'logout',
    },
    component: Logout,
  },
  {
    path: '/passwd',
    name: 'passwd',
    meta: {
      requiresAuth: false,
      auth_type: 'logout',
    },
    component: TemplateView,
    children: [
      {
        path: '/passwd/passcd',
        name: 'passwd_passcd',
        component: PasswdPasscd,
      },
    ],
  },
  {
    path: '/mailaddress',
    name: 'mailaddress',
    meta: {
      requiresAuth: false,
      auth_type: 'logout',
    },
    component: TemplateView,
    children: [
      {
        path: '/mailaddress/check',
        name: 'mailaddress_check',
        component: MailAddressCheck,
      },
      {
        path: '/mailaddress/ccheck',
        name: 'mailaddress_ccheck',
        component: MailAddressClubCheck,
      },
      {
        path: '/mailaddress/ocheck',
        name: 'mailaddress_ocheck',
        component: MailAddressOwnerCheck,
      },
    ],
  },
  {
    path: '/error',
    name: 'error',
    component: Logout,
  },
  {
    path: '/errors',
    name: 'error',
    component: Error,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: './logout',
  },
]

<template>
  <section class="py-5">
    <div class="container">
      <div class="row">
        <!-- {{ this.$route.query.token }} -->
        <div class="text-start col-10 offset-1 col-xl-6 offset-xl-3">
          <div class="alert alert-danger" v-if="update_error">
            <div
              class="text-center py-2 bs_fs_5 mb-2 border-bottom border-danger"
            >
              <i class="bi bi-exclamation-diamond"></i
              >&nbsp;メールアドレスの変更に失敗しました。
            </div>
            <p class="py-2 px-5">
              {{ message }}
            </p>
          </div>
          <div class="alert alert-success" v-else-if="update_result">
            <div
              class="text-center py-2 bs_fs_5 mb-2 border-bottom border-success"
            >
              <i class="bi bi-exclamation-diamond"></i
              >&nbsp;メールアドレスを変更しました。
            </div>
            <p class="py-2 px-5"></p>
          </div>
          <div
            class="alert alert-info"
            v-else-if="!update_result && !update_error"
          >
            <div
              class="text-center py-2 bs_fs_5 mb-2 border-bottom border-info"
            >
              <i class="bi bi-exclamation-diamond"></i>&nbsp;メールアドレス変更
            </div>
            <p class="py-2 px-5">
              {{ message }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="text-center col-10 offset-1 col-xl-6 offset-xl-3">
          <router-link to="/" class="btn btn-light btn-outline-secondary"
            >戻る</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.375rem;
    border-bottom: solid 1px #999;
  }
  .form-control-plaintext {
    border-bottom: solid 1px #999;
  }
</style>
<script>
  import { mapState } from 'vuex'
  // import BsPageTitle from '../../molecules/BsPageTitle.vue'
  // import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
  // import BsSelect from '../../atoms/BsSelect.vue'
  // import BsCheckBox from '../../atoms/BsCheckBox.vue'
  // import '../../assets/bootstrap/css/sc.css';
  import config from '../../../const'

  export default {
    name: 'CheckView',
    data: function () {
      return {
        update_result: false,
        update_error: false,
        passwd_mode: 'chk_application',
        message: 'メールアドレスの変更を行っています。',
      }
    },
    components: {
      //   BsPageTitle,
      //   BsSelect,
      //   BsMsgAlert,
      // BsCheckBox,
    },
    methods: {
      async loadChangeMailAddressObj() {
        const params = {}
        params['stat'] = [1]
        params['application_check_type'] = 100
        params['application_type'] = 22500
        params['update_token'] = this.$route.query.token
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/club_application_item/mailaddress_check',
          params
        )
          .then(async (response) => {
            console.log('loadOwnerObj!!!', response)
            if (response.data && response.data.status == 200) {
              this.update_result = true
              this.update_error = false
              this.data_obj =
                response.data && response.data.body && response.data.body[0]
                  ? response.data.body[0]
                  : {}
            } else {
              this.update_error = true
              this.message = '変更申請が見つかりませんでした。'
            }
          })
          .catch(async (err) => {
            this.update_result = false
            this.update_error = true
            this.message = err
            console.log('error', err)
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      // back(){
      // }
    },
    computed: {
      ...mapState({}),
    },

    async created() {
      await this.loadChangeMailAddressObj()
    },
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
    props: {},
  }
</script>

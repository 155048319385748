<template>
  <div id="home" @click="closeMenuOnOutsideClick" class="bs-template-user-view">
    <BsHeaderView @toggle="menuToggle" :attachLogo="goHome" :isMenuOpen="menuDisplay"></BsHeaderView>
    <div class="container-fluid main-content">
      <div class="row row-eq-height">
        <main class="col-12">
          <router-view></router-view>
        </main>
      </div>
    </div>
    <nav :class="['navi', {'navi--isOpen': menuDisplay}]" id="navi" @click.stop>
      <ul class="navi__ul">
        <li class="navi__li" v-for="menu in menu_list" :key="menu.label">
          <!--  -->
          <template v-if="menu.path=='/user/school'">
            <a :href="'https://docs.google.com/forms/d/e/1FAIpQLSdI5RzsRp9qWDbLhbwhb6SCuxlTaaftuvASNbeAwaC3qn5G1A/viewform'" class="navi__a" target="_blank">
              {{ menu.label }}
              <font-awesome-icon
                :icon="['fas', 'chevron-right']"
                class="mx-2"
              ></font-awesome-icon>
            </a>
          </template>
          <router-link v-else class="navi__a" :to="menu.path" @click="closeMenu">
          {{ menu.label }}
          <font-awesome-icon
            :icon="['fas', 'chevron-right']"
            class="mx-2"
          ></font-awesome-icon>
          </router-link>
        </li>
      </ul>
    </nav>
    <BsFooterView></BsFooterView>
  </div>
</template>

<script>
  import BsHeaderView from '../organisms/BsHeaderView.vue'
  import BsFooterView from '../organisms/BsFooterView.vue'
  import { mapState } from 'vuex'
  import config from '../../const'

  export default {
    name: 'templateOwnerView',
    data: function () {
      return {
        menuDisplay: false,
      }
    },
    computed: {
      ...mapState({}),
      menu_list() {
        return config.HEAD_MENU_USER_LIST.filter(
          (menu) => menu.menu_sort !== false
        ).sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },
      mainClass() {
        return this.menuDisplay ? 'col-12 col-md-9' : 'col-12'
      },
      sideMenuClass() {
        return 'col-12 col-md-3 ff_noto_sans fw-bold bs_fs_7 text-center side-menu'
      },
    },
    components: {
      BsHeaderView,
      BsFooterView,
    },
    methods: {
      goHome(){},
      menuToggle() {
        this.menuDisplay = !this.menuDisplay
        this.$emit('toggle'); 
      },
      closeMenu() {
        this.menuDisplay = false
      },
      closeMenuOnOutsideClick(event) {
        if (
          this.menuDisplay &&
          !event.target.closest('.side-menu') &&
          !event.target.closest('.naviButton')
        ) {
          this.closeMenu()
        }
      },
    },
  }
</script>

<style scoped>
  @import '../../assets/bootstrap/css/dashboard.css';
  @import '../../assets/css/style-color.css';

  .bs-template-user-view .main-content {
    padding-top: 72px;
    min-height: calc(100vh - 48.8px);
    padding-bottom: 80px;
  }

  .bs-template-user-view .side-menu {
    background-color: var(--color02, #ffffff);
    border-left: 1px solid var(--color07, #dee2e6);
    position: fixed;
    right: -250px;
    top: 72px;
    bottom: 0;
    width: 250px;
    overflow-y: auto;
    z-index: 1000;
    transition: right 0.3s ease-in-out;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  }

  .bs-template-user-view .side-menu.show {
    right: 0;
  }

  @media (max-width: 767px) {
    .bs-template-user-view .side-menu {
      width: 100%;
      right: -100%;
    }
  }

  .bs-template-user-view .menu-item {
    padding: 15px;
    border-bottom: 1px solid var(--color07);
    transition: background-color 0.2s ease;
    background-color: var(--color03);
  }

  .bs-template-user-view .menu-item:hover {
    background-color: var(--color03);
  }

  .bs-template-user-view .menu-item a {
    color: var(--color02);
    text-decoration: none;
    display: block;
  }

  .bs-template-user-view .menu-item.active {
    background-color: var(--color03);
  }

  .bs-template-user-view .menu-item.active a {
    color: var(--color02);
  }

  /* オーバーレイ効果（オプション） */
  .bs-template-user-view .side-menu::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
  }

  .bs-template-user-view .side-menu.show::before {
    opacity: 1;
  }
</style>

<template>
  <section class="page">
    <div class="container">
      <h2 class="title page__h1">個人情報変更申請</h2>
      <div class="box box--center mb-24">
        <p v-if="(this.application_obj || {}).application_check_type == 0">
          {{ message }}
        </p>
        <p v-else-if="
          (this.application_obj || {}).application_check_type == 100
        ">
          現在変更申請中です。
        </p>
        <p v-else-if="
          (this.application_obj || {}).application_check_type == 200
        ">
          現在変更申請を確認中です。
        </p>
        <p v-else-if="
          (this.application_obj || {}).application_check_type == 500
        ">
          承認されました。
        </p>
        <p v-else-if="
          (this.application_obj || {}).application_check_type == 600
        ">
          差し戻しがあります。内容を確認して再申請をお願いします。
        </p>
        <p v-else>変更申請フォームです</p>
      </div>
      <h3 class="title02 page__h2">氏名</h3>
      <form class="mb-40">
        <div class="mb-32">
          <div class="row gy-24">
            <div class="col-md-6">
              <h4 class="title08 d-block mb-24">現在の登録内容</h4>
              <div class="page__inputGroup">
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <span class="page__inputLabel page__inputGroupLabel">姓</span>
                    <span class="showData page__inputGroupInput">{{ form_obj.name_1 }}</span>
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <span class="page__inputLabel page__inputGroupLabel">名</span>
                    <span class="showData page__inputGroupInput">{{ form_obj.name_2 }}</span>
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <span class="page__inputLabel page__inputGroupLabel">セイ</span>
                    <span class="showData page__inputGroupInput">{{ form_obj.yomi_1 }}</span>
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <span class="page__inputLabel page__inputGroupLabel">メイ</span>
                    <span class="showData page__inputGroupInput">{{ form_obj.yomi_2 }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h4 class="title08 d-block mb-24">変更する申請内容</h4>
              <div class="page__inputGroup">
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel" for="input011">姓</label>
                    <span v-if="input_readonly" class="showData fa-18">{{ (app_form_obj || {}).name_1 }}</span>
                    <template v-else>
                      <input class="input page__inputGroupInput" id="input011" v-model="(app_form_obj || {}).name_1"
                      :class="input_class" :readonly="input_readonly" placeholder="姓を入力" />
                    </template>
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <span class="page__inputLabel page__inputGroupLabel">名</span>
                    <span class="page__inputText">変更できません</span>
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel" for="input012">セイ</label>
                    <span v-if="input_readonly" class="showData fa-18">{{ (app_form_obj || {}).yomi_1 }}</span>
                    <template v-else>
                      <input class="input page__inputGroupInput" id="input012" v-model="(app_form_obj || {}).yomi_1"
                      :class="input_class" :readonly="input_readonly" placeholder="セイを入力" />
                    </template>
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <span class="page__inputLabel page__inputGroupLabel">メイ</span>
                    <span class="page__inputText">変更できません</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-32">
          <div class="row gy-24">
            <div class="col-md-6">
              <h4 class="title08 d-block mb-24">提出ファイル種別</h4>
              <div class="page__inputGroup">
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel">証明書</label>
                    <span class="showData page__inputGroupInput">
                      <input v-if="change_mode" type="text" :class="input_class" :readonly="input_readonly"
                        :value="get_document_label((app_form_obj || {}).path_type)" />
                      <bs-select v-else :options="member_document_cd_list" :nullOption="false"
                        v-model="(app_form_obj || {}).path_type" :readonly="input_readonly"></bs-select>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h4 class="title08 d-block mb-24">提出ファイル選択</h4>
              <div class="page__inputGroup">
                <div class="page__inputGroupCol" v-for="k in app_form_obj.path_type == 20 ? [1, 2] : [1]" :key="k">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel">
                      <span v-if="k == 1 && app_form_obj.path_type == 20">表面</span>
                      <span v-else-if="k == 1">表</span>
                      <span v-else-if="k == 2">裏面</span>
                    </label>
                    <span class="showData page__inputGroupInput">
                      <div v-if="(app_form_obj || {})['path' + k]">
                        <img v-if="application_obj.application_check_type == 0" :src="(app_form_obj || {})['path' + k]"
                          class="w-100 mb-2" />
                        <div v-else>ファイル送信済です。</div>
                        <button v-if="!change_mode" type="button" @click="clickUpFileBtn(400, k)"
                          class="btn btn-light btn-outline-secondary">
                          <i class="bi bi-file-arrow-up-fill"></i>&nbsp;ファイルを再選択する
                        </button>
                      </div>
                      <div v-else>
                        <div v-if="application_obj.application_check_type != 0">ファイル送信済です。</div>
                        <button v-else-if="!change_mode" type="button" @click="clickUpFileBtn(400, k)"
                          class="btn btn-light btn-outline-secondary">
                          <i class="bi bi-file-arrow-up-fill"></i>&nbsp;ファイルを選択する
                        </button>
                      </div>
                      <input type="file" class="form-control" :id="'file_up_input_400_' + k"
                        @change="selectedFile($event, 400, k)" style="display: none" />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="d-flex mt-32 justify-content-center column-gap-24 button__container">
        <button v-if="change_mode && chk_editable" type="button" @click="back_input" class="button03 back__button">
          <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
          <span class="button03__text">戻る</span>
        </button>
        <button v-else type="button" @click="back" class="button03 back__button">
          <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
          <span class="button03__text">戻る</span>
        </button>


        <div v-if="chk_editable">
          <button v-if="change_mode" type="button" @click="update_app" class="button04">
            <span class="button04__text">申請</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </button>
          <button v-else type="button" @click="confirm_chk" class="button04">
            <span class="button04__text">確認</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </button>
        </div>
        <div v-if="(this.application_obj || {}).application_check_type == 100" 
             class="col-4 offset-4 offset-lg-0 col-lg-4 order-3 order-lg-3 text-end cancel__button__container">
          <button class="button03" @click="cancel">
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
            <span class="button03__text">変更を取りやめる</span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import BsSelect from '../../atoms/BsSelect.vue'
import config from '../../../const'

export default {
  name: 'UserNameView',
  data: function () {
    return {
      targetid: this.$store.state.auth.userId,
      btnLabel: '',
      data_obj: {},
      form_obj: {},
      app_form_obj: {},
      change_login_id: '',
      confirm_login_id: '',
      change_mode_value: false,
      base_message:
        '姓のみ変更できます。変更申請をする場合は、新しい姓とそれを証明する書類を添付して申請して下さい。',
      message: '',
      pref_obj: [],
      request_type_cd_obj: [],
      application_obj: {},
      application_type_obj: [],
      application_check_type_obj: [],
      member_document_cd_obj: [],
    }
  },
  components: {
    BsSelect,

  },
  methods: {
    clickUpFileBtn(type, k) {
      const fileInput = document.getElementById(`file_up_input_${type}_${k}`);
      if (fileInput) {
        fileInput.click();
      } else {
        console.error(`File input element not found: file_up_input_${type}_${k}`);
      }
    },
    async cancel() {
      if (window.confirm('変更を取りやめます。よろしいですか？')) {
        const post_url_path =
          this.application_obj.id > 0
            ? '/member_application/edit/' + this.application_obj.id
            : '/member_application/add'
        const params = {
          m_id: this.$store.state.auth.userId,
          stat: 9,
        }
        await this.basicPosts_I(config.BASE_API_URL + post_url_path, params)
          .then(async (response) => {
            console.log('update', response)
          })
          .catch(async (err) => {
            console.log(err)
          })
          .finally(async () => {
            await this.loadApplicationObj()
            this.change_mode_value = false
            this.message = '変更申請をキャンセルしました'
            this.$forceUpdate()
          })
      }
    },
    async get_address() {
      const postal_cd = this.form_obj.postal_cd
      await this.basicGets_O(
        'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + postal_cd
      )
        .then((res) => {
          if (
            typeof res === 'object' &&
            typeof res.status === 'number' &&
            res.status == 200 &&
            typeof res.data === 'object' &&
            typeof res.data.results === 'object' &&
            typeof res.data.results[0] === 'object'
          ) {
            this.form_obj.pref_cd =
              parseInt(res.data.results[0].prefcode) < 10
                ? '0' + res.data.results[0].prefcode
                : res.data.results[0].prefcode
            this.form_obj.address_1 =
              res.data.results[0].address2 + res.data.results[0].address3
            this.form_obj.address_2 = ''
          } else {
            alert('電話番号データがありません')
          }
        })
        .catch((error) => {
          console.log(error)
          alert('住所データがありません.')
        })
        .finally(() => {
          this.$forceUpdate()
        })
    },
    async selectedFile(e, type_cd, path) {
      e.preventDefault()
      const files = [...e.target.files]
      // let ups = [];
      files.forEach(async (file) => {
        const form = new FormData()
        form.append('file', file)
        await this.basicPosts_R(
          config.BASE_API_URL + '/upload/member_assets',
          form
        )
          .then((res) => {
            console.log(res)
            if (res && res.status == 200) {
              this.app_form_obj['path' + path] = res.data.body.file_path
                ? res.data.body.file_path
                : ''
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            document.getElementById(
              'file_up_input_' + type_cd + '_path' + path
            ).value = ''
            this.$forceUpdate()
          })
      })
    },
    // async clickUpFileBtn(type, path) {
    //   document
    //     .getElementById('file_up_input_' + type + '_path' + path)
    //     .click()
    // },
    async loadConfigObj(param) {
      const params = {}
      await this.basicPosts_S(
        config.BASE_API_URL + '/config/' + param,
        params
      )
        .then(async (response) => {
          console.log('loadConfigObj response', response)
          this[param + '_obj'] =
            response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          this[param + '_obj'] = []
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    async loadPrefList() {
      const params = {}
      params['stat'] = [1]
      await this.basicPosts_S(
        config.BASE_API_URL + '/area_pref/lists',
        params
      )
        .then(async (response) => {
          console.log('loadPrefList response', response)
          this.pref_obj =
            response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          this.pref_obj = []
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    async loadUserObj() {
      const params = {}
      params['stat'] = [1]
      params['id'] = this.$store.state.auth.userId
      // params['pagegroup'] = 'mfj_member_data.id'
      await this.basicPosts_S(config.BASE_API_URL + '/member/lists', params)
        .then(async (response) => {
          console.log('loadUserObj', response)
          this.data_obj =
            response.data && response.data.body && response.data.body[0]
              ? response.data.body[0]
              : {}
        })
        .catch(async (err) => {
          console.log(err)
          this.data_obj = {}
        })
        .finally(async () => {
          await this.setUserObj()
          this.$forceUpdate()
        })
    },
    async setUserObj() {
      this.form_obj = {
        name_1: (this.data_obj || {}).name_1,
        name_2: (this.data_obj || {}).name_2,
        yomi_1: (this.data_obj || {}).yomi_1,
        yomi_2: (this.data_obj || {}).yomi_2,
        birth_date: (this.data_obj || {}).birth_date,
        postal_cd: (this.data_obj || {}).postal_cd,
        pref_cd: (this.data_obj || {}).pref_cd,
        address_1: (this.data_obj || {}).address_1,
        address_2: (this.data_obj || {}).address_2,
        tel_1: (this.data_obj || {}).tel_1,
        tel_2: (this.data_obj || {}).tel_2,
        member_assets: (this.data_obj || {}).member_assets,
      }
    },
    async loadApplicationObj() {
      const params = {}
      params['stat'] = [1]
      params['m_id'] = this.$store.state.auth.userId
      params['application_type'] = 2100
      params['not_application_check_types'] = [500, 900]
      await this.basicPosts_S(
        config.BASE_API_URL + '/member_application/lists',
        params
      )
        .then(async (response) => {
          console.log('loadApplicationObj', response)
          this.application_obj =
            response.data && response.data.body && response.data.body[0]
              ? response.data.body[0]
              : {}
        })
        .catch(async (err) => {
          console.log(err)
          this.application_obj = {}
        })
        .finally(async () => {
          await this.setApplicationObj()
          this.$forceUpdate()
        })
    },
    async setApplicationObj() {
      const tmps =
        typeof this.application_obj === 'object' &&
          Object.keys(this.application_obj).length > 0
          ? this.application_obj
          : {
            id: 0,
            application_check_type: '',
            application_type: '',
            application_date: '',
          }
      this.application_obj = tmps

      this.app_form_obj = {
        name_1: (this.get_target_app_item('mfj_member_data@name_1') || {})
          .application_value
          ? (this.get_target_app_item('mfj_member_data@name_1') || {})
            .application_value
          : '',
        name_1_message: (
          this.get_target_app_item('mfj_member_data@name_1') || {}
        ).message
          ? (this.get_target_app_item('mfj_member_data@name_1') || {}).message
          : '',
        yomi_1: (this.get_target_app_item('mfj_member_data@yomi_1') || {})
          .application_value
          ? (this.get_target_app_item('mfj_member_data@yomi_1') || {})
            .application_value
          : '',
        yomi_1_message: (
          this.get_target_app_item('mfj_member_data@yomi_1') || {}
        ).message
          ? (this.get_target_app_item('mfj_member_data@yomi_1') || {}).message
          : '',
        path1: (this.get_target_app_item('mfj_member_assets@path1') || {})
          .application_value
          ? (this.get_target_app_item('mfj_member_assets@path1') || {})
            .application_value
          : '',
        path2: (this.get_target_app_item('mfj_member_assets@path2') || {})
          .application_value
          ? (this.get_target_app_item('mfj_member_assets@path2') || {})
            .application_value
          : '',
        path_message: (
          this.get_target_app_item('mfj_member_assets@path') || {}
        ).message
          ? (this.get_target_app_item('mfj_member_assets@path') || {}).message
          : '',
        path_type: (
          this.get_target_app_item('mfj_outdb_data@path_type') || {}
        ).application_value
          ? (this.get_target_app_item('mfj_outdb_data@path_type') || {})
            .application_value
          : '',
      }
    },
    async confirm_chk() {
      if (!this.app_form_obj.path1) {
        alert('証明する書類ファイルを添付して下さい。')
        return true
      } else if (!this.app_form_obj.path_type) {
        alert('証明する書類ファイルの種類を選択して下さい。')
        return true
      } else if (!this.app_form_obj.name_1 || !this.app_form_obj.yomi_1) {
        alert('変更する名前を入力して下さい。')
        return true
      }
      this.change_mode_value = true
    },
    async update_app() {
      console.log('component methods', this.data_obj)

      const post_url_path =
        this.application_obj.id > 0
          ? '/member_application/edit/' + this.application_obj.id
          : '/member_application/add'

      const params = {
        application_check_type: 100,
        application_type: 2100,
        m_id: this.$store.state.auth.userId,
        // application_type: this.upd_obj.application_check_type,
        // application_check_type: this.upd_obj.application_check_type,
        // items: [],
      }

      const item_column = {
        name_1: 'mfj_member_data@name_1',
        yomi_1: 'mfj_member_data@yomi_1',
        path1: 'mfj_member_assets@path1',
        path2: 'mfj_member_assets@path2',
        path_type: 'mfj_outdb_data@path_type',
      }
      const items = []
      Object.keys(item_column).forEach((item_key) => {
        items.push({
          id: this.get_target_app_item(item_column[item_key])
            ? (this.get_target_app_item(item_column[item_key]) || {}).id
            : 0,
          application_id: this.application_obj.id,
          application_key: item_column[item_key],
          application_value: this.app_form_obj[item_key],
        })
      })
      params.items =
        typeof items === 'object' && items.length > 0 ? items : false

      await this.basicPosts_I(config.BASE_API_URL + post_url_path, params)
        .then(async (response) => {
          console.log('update', response)
        })
        .catch(async (err) => {
          console.log(err)
        })
        .finally(async () => {
          await this.loadApplicationObj()
          this.change_mode_value = false
          this.message = '更新しました'
          this.$forceUpdate()
        })
    },
    back_input() {
      this.change_mode_value = false
    },
    back() {
      this.$router.go(-1)
    },
    getBackButtonText() {
      return this.change_mode && this.chk_editable ? '入力画面に戻る' : '戻る';
    },

    getMainActionText() {
      return this.change_mode ? '申請する' : '確認する';
    },
    handleBackButton() {
      if (this.change_mode && this.chk_editable) {
        this.back_input();
      } else {
        this.back();
      }
    },

    handleMainAction() {
      if (this.chk_editable) {
        if (this.change_mode) {
          this.update_app();
        } else {
          this.confirm_chk();
        }
      }
    },
  },
  computed: {
    ...mapState({}),
    change_mode: function () {
      return this.chk_editable ? this.change_mode_value : true
    },
    chk_editable: function () {
      if (
        typeof this.application_obj !== 'object' ||
        (typeof this.application_obj === 'object' &&
          this.application_obj.id == 0)
      ) {
        return true
      }
      return [0, 600].includes(
        parseInt((this.application_obj || {}).application_check_type)
      )
    },
    get_assets: function () {
      const form_obj = this.form_obj
      return function (type_cd, sort_key) {
        if (
          typeof form_obj === 'object' &&
          typeof form_obj.member_assets === 'object' &&
          form_obj.member_assets.find((asset) => asset.type == type_cd) !==
          false &&
          typeof form_obj.member_assets.filter(
            (asset) => asset.type == type_cd
          ) === 'object' &&
          typeof form_obj.member_assets.filter(
            (asset) => asset.type == type_cd
          )[sort_key] === 'object'
        ) {
          // console.log('get_assets', form_obj.member_assets.filter(asset => asset.type == type_cd));
          return form_obj.member_assets.filter(
            (asset) => asset.type == type_cd
          )[sort_key]
        }
        return {}
      }
    },
    get_document_label: function () {
      const doc_list = this.member_document_cd_list
      return function (key) {
        if (typeof doc_list.find((doc) => doc.key == key) === 'object') {
          return doc_list.find((doc) => doc.key == key).label
        }
        return key
      }
    },
    get_pref_label: function () {
      const pref_list = this.pref_list
      return function (key) {
        if (typeof pref_list.find((pref) => pref.key == key) === 'object') {
          return pref_list.find((pref) => pref.key == key).label
        }
        return key
      }
    },
    get_request_cd_label: function () {
      const lists = this.request_type_list
      return function (key) {
        if (typeof lists.find((list) => list.key == key) === 'object') {
          return lists.find((list) => list.key == key).label
        }
        return key
      }
    },
    request_type_list: function () {
      return this.request_type_cd_obj
    },
    pref_list: function () {
      const lists = [...this.pref_obj]
      return lists.sort((a, b) => {
        if (parseInt(a.sort) < parseInt(b.sort)) {
          return -1
        } else {
          return 1
        }
      })
    },
    application_type_list: function () {
      return this.application_type_obj
    },
    application_check_type_list: function () {
      return this.application_check_type_obj
    },
    member_document_cd_list: function () {
      return this.member_document_cd_obj
    },

    get_target_app_item: function () {
      const application = this.application_obj
      return function (app_key) {
        if (
          typeof application === 'object' &&
          typeof application.items === 'object'
        ) {
          return application.items.find(
            (app) => app.application_key == app_key
          )
        }
      }
    },

    application_filter: function () {
      const app_list = this.application_list
      return function (app_type, app_chk_list) {
        return app_list.filter((app) => {
          if (
            app.application_type == app_type &&
            app_chk_list.includes(app.application_check_type) !== false
          ) {
            return true
          }
          return false
        })
      }
    },
    get_target_application: function () {
      const member_application = this.application_list
      return function (app_type) {
        return member_application.find(
          (app) => app.application_type == app_type
        )
      }
    },
    get_target_application_btn_class: function () {
      const get_func = this.get_target_application
      const application_btn_class = this.application_btn_class
      return function (app_type) {
        const filtered = get_func(app_type)
        console.log('get_target_application_btn_class', app_type, filtered)
        return application_btn_class(
          (filtered || {}).application_check_type
            ? filtered.application_check_type
            : false
        )
      }
    },

    get_member_data: function () {
      return {
        name_1: this.data_obj.name_1,
        name_2: this.data_obj.name_2,
        yomi_1: this.data_obj.yomi_1,
        yomi_2: this.data_obj.yomi_2,
        birth_date: this.data_obj.birth_date,
        postal_cd: this.data_obj.birth_date,
        pref_cd: this.data_obj.birth_date,
        address_1: this.data_obj.address_1,
        address_2: this.data_obj.address_2,
        tel_1: this.data_obj.tel_1,
        tel_2: this.data_obj.tel_2,
        mail_1: this.data_obj.mail_1,
        mail_2: this.data_obj.mail_2,
        mail_flg: this.data_obj.mail_flg,
      }
    },
    input_class: function () {
      if (
        this.change_mode ||
        ((this.application_obj || {}).application_check_type > 0 &&
          (this.application_obj || {}).application_check_type != 600)
      ) {
        return 'form-control-plaintext'
      } else {
        return 'form-control'
      }
    },
    input_readonly: function () {
      if (
        this.change_mode ||
        ((this.application_obj || {}).application_check_type > 0 &&
          (this.application_obj || {}).application_check_type != 600)
      ) {
        return true
      } else {
        return false
      }
    },
  },
  async created() {
    this.message = this.base_message
    await this.loadConfigObj('request_type_cd')
    await this.loadConfigObj('member_document_cd')
    await this.loadConfigObj('application_type')
    await this.loadConfigObj('application_check_type')
    await this.loadPrefList()
    await this.setUserObj()
    await this.loadUserObj()
    await this.setApplicationObj()
    await this.loadApplicationObj()
  },
  mounted: function () {
    // console.log('tags_list', this.tags_list);
  },
  props: {},
}
</script>

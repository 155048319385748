<template>
  <header class="header" id="header" :style="bg_color_setting">
    <div class="container" style="min-height: auto !important;">
      <div class="header__inner">
        <h1 class="header__logo">
          <span class="header__logoLink" @click="logo_link()"
            ><img
              :src="require('@/assets/img/header_img_01.png')"
              alt="Motorcycle Federation of Japan"
          /></span>
        </h1>
        <div class="header__right">
          <p class="header__logout">
            <router-link
              :to="logoutPath"
              class="button"
              aria-current="page"
            >
              ログアウト
              <font-awesome-icon
                :icon="['fas', 'chevron-right']"
                class="mx-2"
              ></font-awesome-icon>
            </router-link>
          </p>
          <p>
            <button
              class="naviButton"
              :class="{ 'naviButton--isOpen': isMenuOpen }"
              id="naviButton"
              type="button"
              @click="toggleMenu"
            >
              <span class="naviButton__span">{{
                isMenuOpen ? '×' : 'Menu'
              }}</span>
            </button>
          </p>
        </div>
      </div>
    </div>
  </header>
</template>
<style scoped>
  .navbar .form-control {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    padding-left: 0.25rem !important;
    border-radius: 0.25rem !important;
    border: 1px solid #ced4da !important;
  }
  .naviButton__span {
    font-size: 24px;
    line-height: 1;
  }

  @media (max-width: 991.98px) {
    .offcanvas-collapse {
      position: fixed;
      top: 76px; /* ナビバーの高さ */
      bottom: 0;
      left: 100%;
      width: 100%;
      padding-right: 1rem;
      padding-left: 1rem;
      overflow-y: auto;
      background-color: #343a40;
      transition: visibility 0.3s ease-in-out,
        -webkit-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out,
        -webkit-transform 0.3s ease-in-out;
    }
    .offcanvas-collapse.open {
      visibility: visible;
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }
</style>

<script>
  import config from '../../const'
  export default {
    name: 'BsHeaderView',
    data: function () {
      return {
        auth: this.$store.state.auth,
        login: false,
      }
    },
    props: {
      isMenuOpen: {
        type: Boolean,
        default: false,
      },
      attachLogo:{
        type: Function,
      }
    },
    computed: {
      bg_color_setting(){
        if(this.get_base_env !== 'production'){
          return 'background-color:#CCC;'
        }
        return ''
      },
      logoutPath() {
        let type = 'member';

        // tokenの存在でタイプを判断
        if (this.$store.state.oauth && this.$store.state.oauth.token) {
          type = 'owner';
        } else if (this.$store.state.cauth && this.$store.state.cauth.token) {
          type = 'club';
        } else if (this.$store.state.auth && this.$store.state.auth.token) {
          type = 'member';
        }
        
        switch(type) {
          case 'owner':
            return '/ologout';
          case 'club':
            return '/clogout';
          case 'member':
          default:
            return '/logout';
        }
      },
      menu_class() {
        return function (menu) {
          const mb = 'd-block py-3 text-center text-white text-decoration-none'
          if ((menu || {}).style_class) {
            return mb + ' ' + (menu || {}).style_class
          }
          return mb + ' bg_mfj_red'
        }
      },
      menu_list() {
        return config.HEAD_MENU_USER_LIST.filter(
          (menu) => menu.menu_sort !== false
        ).sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },
      auth_label() {
        if (!this.$store.state.auth) {
          return '-'
        } else if (this.$store.state.auth.label) {
          return this.$store.state.auth.label
        } else {
          return 'm'
        }
      },
      //   ...mapState(['count']),
      authLogin() {
        // validを使ってログイン処理の可否、progressは後述
        if (!this.$store.state.auth || !this.$store.state.auth.token) {
          return false
        } else {
          return true
        }
      },
      header_class() {
        if (this.front_view) {
          return 'not-header'
        }
        return 'header'
      },
    },
    components: {
      // BsSelect,
    },
    methods: {
      logo_link(){
        this.attachLogo()
      },
      toggleMenu() {
        this.$emit('toggle')
        // this.$refs.navBody.classList.toggle('nav--open');
      },
    },
  }
</script>

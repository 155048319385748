const cardInputStyle = {
  base: {
    fontFamily: "'Noto Sans Japanese', sans-serif",
    '::placeholder': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    fontSize: '1rem',
    padding: '0.375rem',
    lineHeight: '28px',
  },
  invalid: {
    color: '#9e2146',
  },
  complete: {
    color: '#21279e',
  },
}

// カード情報入力フォーム(有効期限)のスタイルを設定する
const expiryInputStyle = {
  base: {
    fontFamily: "'Noto Sans Japanese', sans-serif",
    '::placeholder': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    fontSize: '1rem',
    padding: '0.375rem',
    lineHeight: '28px',
  },
  invalid: {
    color: '#9e2146',
  },
  complete: {
    color: '#21279e',
  },
}
// カード情報入力フォーム(セキュリティコード)のスタイルを設定する
const cvcInputStyle = {
  base: {
    fontFamily: "'Noto Sans Japanese', sans-serif",
    '::placeholder': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    fontSize: '1rem',
    padding: '0.1875rem 0.375rem 0.375rem 0.1875rem',
    lineHeight: '28px',
  },
  invalid: {
    color: '#9e2146',
  },
  complete: {
    color: '#21279e',
  },
}
const holderNameInputStyle = {
  base: {
    fontFamily: "'Noto Sans Japanese', sans-serif",
    '::placeholder': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    fontSize: '1rem',
    padding: '0.375rem',
    lineHeight: '28px',
  },
  invalid: {
    color: '#9e2146',
  },
  complete: {
    color: '#21279e',
  },
}
export { cardInputStyle, expiryInputStyle, cvcInputStyle, holderNameInputStyle }

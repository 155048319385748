<template>
  <section class="py-5">
    <div class="d-none d-md-block">
      <step-view :step_mode="page_mode"></step-view>
    </div>
    <div class="w-100">
      <div class="col-12 col-xl-10 offset-xl-1">
        <!-- 規約同意モード -->
        <div v-if="kiyaku_mode">
          <div class="box02 mb-32">
            <div>
              <div>
                <h2 class="box02__title">
                  <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
                  以下の会員規約に同意してください。
                </h2>
              </div>
            </div>
          </div>

          <div class="mb-5">
            <div class="border border-secondary bg-light rounded-2 py-3 px-4">
              <p class="m-0">
                サービス利用規約<br />
                <br />
                このサービス利用規約（以下「本規約」といいます。）は、一般財団法人日本モーターサイクルスポーツ協会（以下「MFJ」といいます。）が提供する会員WEBサービス（以下「本サービス」といいます。）を利用する方（以下「会員」といいます。）に適用されるものとします。入会手続を完了した時点で、会員は本規約に同意したものとみなされます。<br />
                <br />
                第1条（会員資格）<br />
                1.
                会員とは、所定の手続を完了し、MFJが入会を承認した方とします。<br />
                2.
                会員は、本規約及びMFJが定めるルールに従って本サービスを利用するものとします。<br />
                <br />
                第2条（利用目的）<br />
                本サービスは、会員に対し、2輪競技者の競技会参加サポートを目的として提供されます。<br />
                <br />
                第3条（会員の義務）<br />
                1.
                会員は、登録時に虚偽の情報を提供してはならないものとします。<br />
                2.
                会員は、ID及びパスワードを適切に管理し、第三者に譲渡又は貸与してはなりません。<br />
                3.
                会員は、MFJ又は他の会員、第三者の権利を侵害する行為や法令に違反する行為を行ってはなりません。<br />
                4.
                会員は、自身のIDやパスワードが漏洩した、又はその恐れがあると判明した場合、速やかにMFJへ報告し、MFJからの指示に従うものとします。<br />
                <br />
                第4条（禁止事項）<br />
                1. 会員は、以下の行為を行ってはならないものとします。<br />
                2. 本サービスの運営を妨害する行為<br />
                3. 本サービスを通じて提供される情報を不正に利用する行為<br />
                4. 他の会員又は第三者への誹謗中傷や迷惑行為<br />
                5. MFJの財産、権利、知的財産を侵害する行為<br />
                6.
                他の会員のプライバシーを侵害する行為（例：個人情報の無断収集・公開）<br />
                7. 不正アクセス又はシステムへの侵入行為<br />
                <br />
                第5条（個人情報の取り扱い）<br />
                1.
                MFJは、会員の個人情報を「プライバシーポリシー」に従って適切に取り扱います。<br />
                2.
                会員の個人情報は、本サービスの提供及び会員サポートのために利用されます。<br />
                3.
                会員が5年以上にわたり年度継続せず、情報更新も行われない場合、MFJは当該会員の情報を適切な手続により本サービスから破棄することができるものとします。この場合、MFJは個別の通知を行う義務を負わないものとします。<br />
                <br />
                第6条（サービスの変更・中断・終了）<br />
                1.
                MFJは、必要と判断した場合、事前に通知することなく本サービスの内容を変更又は終了することができるものとします。<br />
                2.
                天災、システム障害、その他の不可抗力により本サービスの提供が一時的に中断される場合があります。<br />
                <br />
                第7条（退会）<br />
                1.
                会員は、MFJ所定の手続を行うことで、いつでも本サービスから退会することができます。<br />
                2.
                退会後も、会員は在籍中に行った行為に関する責任を負うものとします。<br />
                <br />
                第8条（免責事項）<br />
                1.
                MFJは、会員が本サービスを利用することによって生じた損害に対し、一切の責任を負わないものとします。ただし、MFJの故意又は過失による場合はこの限りではありません。<br />
                2.
                本サービス内で提供される情報や助言について、MFJはその正確性や信頼性を保証しません。<br />
                3.
                本サービスには、外部のウェブサイトへのリンクが含まれることがありますが、MFJはそれらの外部サイトの内容や正確性、信頼性について一切の責任を負いません。会員が外部サイトを利用する場合は、自己の責任において行うものとします。<br />
                <br />
                第9条（規約の変更）<br />
                MFJは、必要に応じて本規約を変更することができるものとし、変更後の規約はMFJウェブサイトに掲載した時点で効力を生じるものとします。<br />
                <br />
                第10条（準拠法及び管轄）<br />
                このウェブサイトの利用並びに本ご利用条件の解釈及び適用は、他に別段の定めのない限り、日本国法に準拠するものとします。また、本サービスの利用に関わる全ての紛争については、他に別段の定めのない限り、東京地方裁判所を第一審の専属管轄裁判所とするものとします。<br />
              </p>
            </div>
          </div>

          <div class="container my-4">
            <div class="d-flex flex-column flex-md-row">
              <button
                class="button03 mb-2 mb-md-0 me-md-2 flex-grow-1"
                @click="$router.go(-1)"
              >
                <font-awesome-icon
                  :icon="['fas', 'chevron-left']"
                  class="mx-2"
                />
                <span class="button03__text">戻る</span>
              </button>
              <button class="button04 flex-grow-1" v-if="mfj_phase_club_001" @click="proceed('mail')">
                <span class="button04__text">規約に同意して進む</span>
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="mx-2"
                />
              </button>
            </div>
          </div>
        </div>

        <!-- メール入力モード -->
        <div
          class="text-start col-10 offset-1 col-xl-6 offset-xl-3"
          v-else-if="mail_mode"
        >
          <form @submit.prevent="submitForm" novalidate>
            <div class="box02 mb-32">
              <h2 class="box02__title">
                <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
                {{ message }}
              </h2>
            </div>
            
            <div class="mb-5">
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel" for="email">
                    メールアドレス
                  </label>
                  <input
                    type="email"
                    class="input page__inputGroupInput"
                    id="email"
                    v-model="post_mail_address"
                    placeholder="touroku@mfj.or.jp"
                    @input="validateEmail"
                  />
                </p>
              </div>
            </div>

            <div class="container mb-4">
              <div
                class="d-flex flex-column flex-md-row mt-32 justify-content-center align-items-center gap-3 gap-md-4 w-100"
              >
                <button class="button03 w-100" @click="back('kiyaku')">
                  <font-awesome-icon
                    :icon="['fas', 'chevron-left']"
                    class="mx-2"
                  />
                  <span class="button03__text">戻る</span>
                </button>
                <button class="button04 w-100" @click="send_pass_cd()">
                  <span class="button04__text">送信する</span>
                  <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                    class="mx-2"
                  />
                </button>
              </div>
            </div>
          </form>
        </div>

        <!-- パスコード入力モード -->
        <div class="col-12 col-xl-10 offset-xl-1" v-else-if="passcd_mode">
          <div class="mb-3">
            <div class="box02 mb-32">
              <h2 class="box02__title">
                <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
                確認Noの入力
              </h2>
            </div>
          </div>
          
          <div class="mb-5">
            <div class="page__inputGroupCol">
              <p class="page__inputGroupInner">
                <label class="label page__inputGroupLabel" for="inputEmail">
                  確認No.
                </label>
                <input
                  type="text"
                  class="input page__inputGroupInput"
                  id="inputEmail"
                  v-model="passcd"
                  placeholder="例 0123"
                />
              </p>
              <div
                v-if="isPasscdSubmitted && passcdError"
                class="text-danger mt-2"
              >
                {{ passcdError }}
              </div>
            </div>

            <div class="container mb-4">
              <div
                class="d-flex flex-column flex-md-row mt-32 justify-content-center align-items-center gap-3 gap-md-4 w-100"
              >
                <button class="button03 w-100" @click="back('mail')">
                  <font-awesome-icon
                    :icon="['fas', 'chevron-left']"
                    class="mx-2"
                  />
                  <span class="button03__text">戻る</span>
                </button>
                <button class="button04 w-100"
                 @click="chk_pass_cd()">
                  <span class="button04__text">進む</span>
                  <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                    class="mx-2"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.375rem;
    border-bottom: solid 1px #999;
  }
  .form-control-plaintext {
    border-bottom: solid 1px #999;
  }
</style>
<script>
  import { mapState } from 'vuex'
  import StepView from './StepView.vue'
  import config from '../../../const'
  // メールアドレスのフォーマットをチェックする正規表現
const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/


  export default {
    name: 'ClubRegistAddView',
    data: function () {
      return {
        page_mode: 'kiyaku',
        update_result: false,
        update_error: false,
        regist_obj: {},
        data_obj: {},
        message_class: 'info',
        message: '',
        target_step: ['kiyaku', 'mail'],
        post_mail_address: '',
        passcd: '',
        isPasscdSubmitted: false,
        passcdError: '',
      }
    },
    components: {
      StepView,
    },
    methods: {
      proceed(mode) {
        this.page_mode = mode
        this.message = 'メールアドレスを入力して送信してください。'
      },
      back(mode) {
        this.message_class = 'info'
        this.page_mode = mode
      },
      async send_pass_cd() {
        if (!this.post_mail_address) {
          this.message_class = 'danger'
          this.message = 'メールアドレスは必須です。入力してください。'
          this.$forceUpdate()
          return true
        }
        const params = {}
        params['regist_mail_address'] = this.post_mail_address
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/club_regist/send_pass_cd',
          params
        )
          .then(async (response) => {
            console.log('loadClubRegistData!!!', response)
            if (
              response.data &&
              response.data.status == 200 &&
              response.data.body &&
              response.data.body.status == 200
            ) {
              this.message_class = 'info'
              this.update_result = true
              this.update_error = false
              this.page_mode = 'passcd'
              this.message = '確認Noを入力してください'
              this.data_obj =
                response.data && response.data.body && response.data.body
                  ? response.data.body
                  : {}
            } else if (
              response.data &&
              response.data.status == 200 &&
              response.data.body &&
              response.data.body.status != 200
            ) {
              this.update_error = true
              this.message_class = 'danger'
              this.message = response.data.body.message
                ? response.data.body.message
                : 'メールアドレス宛に送信できませんでした。'
            } else {
              this.update_error = true
              this.message_class = 'danger'
              this.message = 'メールアドレス宛に送信できませんでした。。'
            }
          })
          .catch(async (err) => {
            this.update_result = false
            this.update_error = true
            this.message = err
            console.log('error', err)
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async chk_pass_cd() {
        if (!this.post_mail_address) {
          this.message_class = 'danger'
          this.message = 'メールアドレスは必須です。入力してください。'
          this.$forceUpdate()
          return true
        }

        if (!REGEX_EMAIL.test(this.post_mail_address)) {
          this.message_class = 'danger'
          this.message = '有効なメールアドレスを入力してください。'
          this.$forceUpdate()
          return true
        }

        if (!this.passcd || this.passcd.length !== 4) {
          alert('4桁の確認Noを入力して下さい')
          return false
        }

        const params = {}
        params['regist_mail_address'] = this.post_mail_address
        params['pass_cd'] = this.passcd

        try {
          const response = await this.nonloginPosts_S(
            config.BASE_API_URL + '/club_regist/chk_pass_cd',
            params
          )

          console.log('loadClubRegistData!!!', response)
          if (
            response.data &&
            response.data.status == 200 &&
            response.data.body &&
            response.data.body.status == 200
          ) {
            this.update_result = true
            this.update_error = false
            if (response.data.body.token) {
              this.go_form(response.data.body.token)
            } else {
              alert('error')
            }
          } else if (
            response.data &&
            response.data.status == 200 &&
            response.data.body &&
            response.data.body.status != 200
          ) {
            this.update_error = true
            this.message_class = 'danger'
            this.message = response.data.body.message || '確認Noが確認できませんでした。'
          } else {
            this.update_error = true
            this.message_class = 'danger'
            this.message = '確認Noが確認できませんでした。'
          }
        } catch (err) {
          this.update_result = false
          this.update_error = true
          this.message = err
          console.log('error', err)
        } finally {
          this.$forceUpdate()
        }
      },
      async go_form(token) {
        this.$router.push({
          path: '/club_regist/form',
          query: {
            token: token,
          },
        })
      },
      async loadClubRegistData() {
        const params = {}
        params['stat'] = [1]
        params['token'] = this.$route.query.token
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/club_regist/lists',
          params
        )
          .then(async (response) => {
            console.log('loadClubRegistData!!!', response)
            if (response.data && response.data.status == 200) {
              this.regist_obj =
                response.data && response.data.body && response.data.body[0]
                  ? response.data.body[0]
                  : {}
              if ((this.regist_obj || {}).step != 'add') {
                console.log('not add redirect')
              }
            } else {
              this.message = '規約を確認し、同意して申請を進めてください'
            }
          })
          .catch(async (err) => {
            this.message = err
            console.log('error', err)
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
    },
    computed: {
      ...mapState({}),
      message_class_style: function () {
        const message_class = this.message_class
        return function (target) {
          if (target == 'border') {
            return (
              'text-center py-2 bs_fs_5 mb-2 border-bottom border-' +
              message_class
            )
          } else if (target == 'alert') {
            return 'alert alert-' + message_class
          }
        }
      },
      kiyaku_mode: function () {
        return this.page_mode == 'kiyaku' ? true : false
      },
      mail_mode: function () {
        return this.page_mode == 'mail' ? true : false
      },
      passcd_mode: function () {
        return this.page_mode == 'passcd' ? true : false
      },
    },

    async created() {
      await this.loadClubRegistData()
    },
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
    props: {},
  }
</script>

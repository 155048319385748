<template>
  <nav aria-label="Page navigation">
    <ul class="pagination">
      <li v-if="pager.prev" @click="goToPage(pager.prev.page)" class="page-item">
        <a class="page-link">
          <font-awesome-icon icon="fa-chevron-left" />
        </a>
      </li>
      <li v-for="(page, index) in visiblePages" :key="index" 
          :class="['page-item', page.behavior, { active: page.behavior === 'active' }]" 
          @click="goToPage(page.pageNum)">
        <a class="page-link">{{ page.label }}</a>
      </li>
      <li v-if="pager.next" @click="goToPage(pager.next.page)" class="page-item">
        <a class="page-link">
          <font-awesome-icon icon="fa-chevron-right" />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    pager: {
      type: Object,
      required: true
    }
  },
  computed: {
    visiblePages() {
      if (!this.pager.center || !Array.isArray(this.pager.center)) {
        return [];
      }

      const maxVisible = 3;
      const start = Math.max(0, this.pager.center.findIndex(page => page.behavior === 'active') - Math.floor(maxVisible / 2));
      
      if (this.pager.center.length <= maxVisible) {
        return this.pager.center;
      }
      
      return this.pager.center.slice(start, start + maxVisible);
    }
  },
  methods: {
    goToPage(pageNum) {
      this.$emit('to_click_event', pageNum);
    }
  }
}
</script>

<style scoped>
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
}

.page-item {
  margin: 0 0.2rem;
}

.page-link {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 3em;
  height: 3em;
  padding: 0 1em;
  border: 1.5px solid var(--color03);
  font-size: 1rem;
  color: var(--color03);
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 5px;
}

.page-link:hover {
  background-color: var(--color03);
  border-color:var(--color03);
  color: white;
}

.page-item.active .page-link {
  background-color: var(--color03);
  border-color:var(--color03);
  color: white;
}

.page-item.active .page-link:hover {
  background-color: var(--color03);
  border-color:var(--color03);
  color: white;
}


.page-item.disabled .page-link {
  color: var(--color03);
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
</style>

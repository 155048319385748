<template>
  <section class="py-5">
    <step-view :step_mode="page_mode"></step-view>
    <div class="container">
      <div class="row">
        <div
          class="text-start col-10 offset-1 col-xl-6 offset-xl-3"
          v-if="charge_mode"
        >
          <div class="mb-3">
            <div class="alert alert-info">
              <div
                class="text-center py-2 bs_fs_5 mb-2 border-bottom border-info"
              >
                <i class="bi bi-check"></i
                >&nbsp;クレジットカード情報を入力してください。
              </div>
            </div>
          </div>
          <div class="mb-5">
            <div class="border border-secondary bg-light rounded-2 py-3 px-4">
              <div class="container m-0">
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">カード番号</div>
                  <div class="col-8">
                    <div class="input-group">
                      <input type="text" class="form-control" value="" />
                      <input type="text" class="form-control" value="" />
                      <input type="text" class="form-control" value="" />
                      <input type="text" class="form-control" value="" />
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">利用期限</div>
                  <div class="col-8">
                    <div class="input-group">
                      <span class="input-group-text">月</span>
                      <bs-select></bs-select>
                      <span class="input-group-text">年</span>
                      <bs-select></bs-select>
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">名義</div>
                  <div class="col-8">
                    <input type="text" class="form-control" value="" />
                  </div>
                </div>
                <div class="row pb-0">
                  <div class="col-4">CVS</div>
                  <div class="col-4">
                    <input type="text" class="form-control" value="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="text-center col-10 offset-1 col-xl-6 offset-xl-3">
                <button class="btn btn-primary" @click="proceed('mail')">
                  規約に同意して進む
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.375rem;
    border-bottom: solid 1px #999;
  }
  .form-control-plaintext {
    border-bottom: solid 1px #999;
  }
</style>
<script>
  import { mapState } from 'vuex'
  import StepView from './StepView.vue'
  // import BsPageTitle from '../../molecules/BsPageTitle.vue'
  // import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
  import BsSelect from '../../atoms/BsSelect.vue'
  // import BsCheckBox from '../../atoms/BsCheckBox.vue'
  // import '../../assets/bootstrap/css/sc.css';
  import config from '../../../const'

  export default {
    name: 'RegistAddView',
    data: function () {
      return {
        page_mode: 'charge',
        update_result: false,
        update_error: false,
        data_obj: {},
        message: '',
      }
    },
    components: {
      StepView,
      BsSelect,
      //   BsMsgAlert,
      // BsCheckBox,
    },
    methods: {
      proceed(mode) {
        this.page_mode = mode
      },
      back(mode) {
        this.page_mode = mode
      },
      async update_regist() {
        const params = {}
        params['stat'] = [1]
        //   params['application_check_type'] = 100;
        //   params['application_type'] = 2500;
        params['update_token'] = this.$route.query.token
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/regist/lists',
          params
        )
          .then(async (response) => {
            console.log('loadRegistData!!!', response)
            if (response.data && response.data.status == 200) {
              this.update_result = true
              this.update_error = false
              this.data_obj =
                response.data && response.data.body && response.data.body[0]
                  ? response.data.body[0]
                  : {}
            } else {
              this.update_error = true
              this.message = '変更申請が見つかりませんでした。'
            }
          })
          .catch(async (err) => {
            this.update_result = false
            this.update_error = true
            this.message = err
            console.log('error', err)
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadRegistData() {
        const params = {}
        params['stat'] = [1]
        //   params['application_check_type'] = 100;
        //   params['application_type'] = 2500;
        params['token'] = this.$route.query.token
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/regist/lists',
          params
        )
          .then(async (response) => {
            console.log('loadRegistData!!!', response)
            if (response.data && response.data.status == 200) {
              this.data_obj =
                response.data && response.data.body && response.data.body[0]
                  ? response.data.body[0]
                  : {}
              if ((this.data_obj || {}).step != 'add') {
                console.log('not add redirect')
              }
            } else {
              this.message = '規約を確認し、同意して申請を進めてください'
            }
          })
          .catch(async (err) => {
            this.update_result = false
            this.update_error = true
            this.message = err
            console.log('error', err)
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      // back(){
      // }
    },
    computed: {
      ...mapState({}),
      charge_mode: function () {
        return this.page_mode == 'charge' ? true : false
      },
    },

    async created() {
      await this.loadRegistData()
    },
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
    props: {},
  }
</script>

<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: 'App',
    data: function () {
      return {
        auth: this.$store.state.auth,
        oauth: this.$store.state.oauth,
        cauth: this.$store.state.cauth,
        path: this.$route.path,
      }
    },
    async created() {
      console.log('app created page', this.$route.path)
    },
    async mounted() {
      console.log('vue app mounted')
      await this.setApp()
    },
    components: {},
    methods: {
      async setApp() {
        const load_type = this.get_load_type
        console.log('setApp created load_type', load_type, this.$store.state.auth)
        
        const load_auth =
          load_type == 'owner'
            ? this.$store.state.oauth
            : load_type == 'club'
            ? this.$store.state.cauth
            : (load_type == 'member' && typeof this.$store.state.auth === 'object' && Object.keys(this.$store.state.auth).length > 0)
            ? this.$store.state.auth
            // : this.$store.state.auth
            : {
              label: null,
              level: null,
              relations: [],
              token: null,
              type: null,
              userId: null
            }
        const token_label =
          load_type == 'owner'
            ? 'otoken'
            : load_type == 'club'
            ? 'ctoken'
            : load_type == 'member'
            ? 'token'
            : 'token'

        const logoutPath =
          load_type == 'owner'
            ? '/ologout'
            : load_type == 'club'
            ? '/clogout'
            : load_type == 'member'
            ? '/logout'
            : '/logout'
        console.log('setApp logoutPath', logoutPath)

        if (
          (load_auth || {}).token == null ||
          (load_auth || {}).token == 'null' ||
          (load_auth || {}).token == undefined ||
          (load_auth || {}).token == 'undefined' ||
          (load_auth || {}).token == ''
        ) {
          const ls_token = localStorage.getItem(token_label)
          console.log('ls_token', ls_token, token_label)
          if (typeof ls_token === 'string' && ls_token.length > 0) {
            console.log('App ls_token login', ls_token)
            await this.loadAccount(ls_token)
          } else {
            console.log(
              'App ls_token login?',
              this.path,
              logoutPath,
              load_type,
              token_label,
              load_auth
            )
          }
        } else if (
          ((load_auth || {}).userId == null ||
            (load_auth || {}).userId == 'null' ||
            (load_auth || {}).userId == false ||
            (load_auth || {}).userId == 'false' ||
            (load_auth || {}).userId == undefined ||
            (load_auth || {}).userId == 'undefined') &&
          (load_auth || {}).token != null &&
          (load_auth || {}).token != 'null' &&
          (load_auth || {}).token != undefined &&
          (load_auth || {}).token != 'undefined' &&
          (load_auth || {}).token != ''
        ) {
          console.log('app created login tokenだけ', (load_auth || {}).token)
          await this.loadAccount((load_auth || {}).token)
        } else {
          console.log(
            'app check page getMyData!!! loginしてる',
            load_auth || {}
          )
        }
        console.log('app check page getMyData!!! logout', load_auth || {})
      },

      async loadAccount(tokenval) {
        console.log('loadAccount ', tokenval)
        const loginInfo = {
          token: tokenval,
        }

        const load_type = this.get_load_type
        console.log('loadAccount load_type', load_type)

        const tokenLogin =
          load_type == 'owner'
            ? 'otokenLogin'
            : load_type == 'club'
            ? 'ctokenLogin'
            : load_type == 'member'
            ? 'tokenLogin'
            : 'tokenLogin'
        console.log('loadAccount tokenLogin', tokenLogin)

        const logoutPath =
          load_type == 'owner'
            ? '/ologout'
            : load_type == 'club'
            ? '/clogout'
            : load_type == 'member'
            ? '/logout'
            : '/logout'
        console.log('loadAccount logoutPath', logoutPath)

        return this.$store
          .dispatch(tokenLogin, loginInfo)
          .then((response) => {
            console.log('login success', loginInfo, response)
            if (!response || !response.token || !response.userId) {
              this.$router.go({ path: logoutPath, force: true })
            }
          })
          .catch((err) => {
            console.log('handleLogin err', err)
            this.$router.go({ path: logoutPath, force: true })
            this.throwReject(err)
          })
          .finally(() => {
            console.log('handleLogin finally')
            this.$router.go({ path: '/', force: true })
          })
      },
    },
    computed: {},
  }
</script>

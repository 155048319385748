<template>
    <div class="myPage">
      <div class="container">
        <div class="row pt-32 pb-32">
          <h2 class="text-center title07">
            <span class="title07__span">ライセンス会員検索</span>
          </h2>
        </div>
        <div class="row">
          <div class="col-10 offset-1 col-sm-8 offset-sm-2">
            <p class="alert alert-info">
              会員検索です。
            </p>
          </div>
        </div>
        <!-- 検索フォーム -->
        <div class="row mb-32">
          <div class="col-12 mb-10 col-sm-8 offset-sm-2">
            <div class="">
              <h4 class="title05 page__h5">ライセンスNo.</h4>
            </div>
            <div class="row">
              <div class="col-6 col-sm-4 col-md-3 py-10 mb-10" v-for="(license_no, index) in license_nos" :key="index">
                <div>
                  <input type="text" v-model="license_nos[index]" class="form-control">
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-10 col-sm-8 offset-sm-2">
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="">
                    <h4 class="title05 page__h5">氏名(セイ・メイ)</h4>
                  </div>
                  <div class="col-6 py-10 mb-10">
                    <div class="input-group">
                      <input type="text" v-model="name_1" class="input page__inputGroupInput" placeholder="姓orセイ">
                    </div>
                  </div>
                  <div class="col-6 py-10 mb-10">
                    <div class="input-group">
                      <input type="text" v-model="name_2" class="input page__inputGroupInput" placeholder="名orメイ">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="">
                  <h4 class="title05 page__h5">都道府県</h4>
                </div>
                <div class="py-10 mb-10">
                  <div class="input-group">
                    <bs-select
                      :options="cre_sel_list(pref_list)"
                      :nullOption="true"
                      v-model="pref_cd"
                      :className="'form-select'"
                      class=" mb-12"></bs-select>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="">
                  <h4 class="title05 page__h5">年度</h4>
                </div>
                <div class="py-10 mb-10">
                  <div class="input-group">
                    <bs-select
                      :options="cre_term_year_list"
                      :nullOption="true"
                      v-model="sel_term_year"
                      :className="'form-select'"
                      class=" mb-12"></bs-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-10 col-sm-8 offset-sm-2">
            <div class="row">
              <div class="col-6">
                <div class="">
                  <h4 class="title05 page__h5">種目</h4>
                </div>
                <div class="input-group">
                  <bs-select
                    :options="cre_sel_list(license_event_cd_list)"
                    :nullOption="true"
                    v-model="license_event_cd"
                    :className="'form-select'"
                    class=" mb-12"></bs-select>
                </div>
              </div>
              <div class="col-6">
                <div class="">
                  <h4 class="title05 page__h5">ライセンス</h4>
                </div>
                <div class="input-group">
                  <bs-select
                    :options="cre_sel_list(license_cd_list)"
                    :nullOption="true"
                    v-model="license_cd"
                    :className="'form-select'"
                    class=" mb-12"></bs-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-32">
          <div class="col-8 offset-2 col-sm-6 offset-sm-3 col-md-4 offset-md-4">
            <div class="d-grid">
              <button class="btn btn-primary" @click="search_member">会員検索</button>
            </div>
          </div>
          <div class="col-2">
            <div class="d-grid">
              <button class="btn btn-light btn-outline-secondary" @click="clear">クリア</button>
            </div>
          </div>
        </div>
        <div class="row mb-32">
          <div class="col-10 offset-1 col-sm-10 offset-sm-1">
            <div class="py-2 text-end">
              {{ sel_term_year }}年度
            </div>
            <table class="page__table w-100">
              <thead>
                <tr>
                  <th class="page__tableTh text-center" style="width: 15%;">ライセンスNO</th>
                  <th class="page__tableTh text-center" style="width: 12%;">氏名</th>
                  <th class="page__tableTh text-center" style="width: 12%;">都道府県</th>
                  <th class="page__tableTh text-center" style="width: 27%;">ライセンス</th>
                  <th class="page__tableTh text-center" style="width: 24%;">保険</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="member in members_obj" :key="member">
                  <td class="page__tableTd text-center">{{ member?.license_no }}</td>
                  <td class="page__tableTd text-center">{{ member?.name_label }}</td>
                  <td class="page__tableTd text-center">{{ get_list_label(member?.pref_cd, pref_list) }}</td>
                  <td class="page__tableTd text-center">
                    <span class="me-2 badge bg-light border text-dark" v-for="license in member?.license_list" :key="license">{{ get_list_label(license, license_cd_short_list) }}</span>
                  </td>
                  <td class="page__tableTd text-center">{{ member?.insurance_term }}</td>
                </tr>
              </tbody>
            </table>
            <div class="py-2 text-end">
              全{{ all_count }}件
            </div>
          </div>
        </div>
        <div class="mb-32">
          <bs-page-nav
            :pager_key="'owner_member_search'"
            @to_click_event="pageAction"
            :pager="pager"
          ></bs-page-nav>
        </div>
  <!-- {{ members_obj }} -->
      </div>
    </div>
  </template>
  
  <script>
    import config from '../../../const'
    import BsSelect from '../../atoms/BsSelect.vue'
    import BsPageNav from '../../molecules/BsPageNav.vue'
    import Common from '../../../common/common'
  
    export default {
      name: 'OwnerSearchMember',
      data: function () {
        return {
          license_nos: [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
          ], 
          name_1: '',
          name_2: '',
          pref_cd: '',
          license_event_cd: '',
          license_cd: '',
          sel_term_year: '',

          members:[],
          members_obj: [],
          tmp_nos: [],
          pref_obj: [],
          license_cd_obj: [],
          license_event_cd_obj: [],

          term_year_obj: [],
          page: 0,
          pager: {},
          all_count: 0,
        }
      },
      props: {},
      components: {
        BsSelect,
        BsPageNav
      },
      computed: {
        get_term_year: function(){
            return (
                typeof this.term_year_obj === 'object' 
                && typeof this.term_year_obj[0] === 'object'
                && this.term_year_obj[0]['label']
            )? parseInt(this.term_year_obj[0]['label']): parseInt(Common.getNowYear()); 
        },
        cre_term_year_list: function(){
          const start = this.get_term_year + 1
          const last = 2010
          const tmps = []
          for(let i=start;i>=last;i--){
            tmps.push({
              key: i,
              label: i + '年度'
            })
          }
          return tmps
        },
        cre_sel_list: function () {
          return function(lists){
            const def_tmp = [{
              key: '',
              label: '選択してください'
            }]
            const tmps = def_tmp.concat(lists)
            // console.log('cre_sel_list', tmps, lists.length, def_tmp)
            // return Object.assign(def_tmp, [...lists])
            return tmps
          }
        },
        pref_list: function () {
          return this.pref_obj
        },
        license_event_cd_list: function(){
          return typeof this.license_event_cd_obj === 'object'? [...this.license_event_cd_obj]: []
        },
        license_cd_list: function(){
          if(this.license_event_cd){
            return  typeof this.license_cd_obj === 'object'? [...this.license_cd_obj].filter(license_cd => license_cd.license_event_cd==this.license_event_cd): []
          }
          return typeof this.license_cd_obj === 'object'? [...this.license_cd_obj]: []
        },
        license_cd_short_list: function(){
          const short_tmps = [...this.license_cd_obj]
          const tmps = []
          if(
            typeof short_tmps === 'object'
          ){
            short_tmps.forEach(license_cd => {
              tmps.push({
                key: license_cd.id,
                label: license_cd.short_label
              })
            })
          }
          return tmps
        },
        get_list_label: function () {
          return function (key, lists) {
            if (
              typeof lists === 'object' &&
              typeof lists.find((list) => parseInt(list.key) == parseInt(key)) === 'object'
            ) {
              return lists.find((list) => parseInt(list.key) == parseInt(key)).label
            }
            return key
          }
        },
      },
  
      async created() {
        await this.loadTermYear()
        await this.loadConfigObj('pref')
        await this.loadLicenseEventCdList()
        await this.loadLicenseCdList()
        // for(let i=0;i<12;i++){
        //   this.license_nos.push('')
        // }
        this.tmp_nos = [...this.license_nos]
        this.sel_term_year = this.get_term_year
      },
  
      methods: {
        async clear() {
          this.sel_term_year = ''
          // this.page = 0
          this.name_1 = ''
          this.name_2 = ''
          this.yomi_1 = ''
          this.yomi_2 = ''
          this.pref_cd = ''
          this.license_cd = ''
          this.license_event_cd = ''
          const tmps = ['','','','','','','','','','','','','','','','']
          this.license_nos = tmps
          // this.members_obj = []
        },
        async loadTermYear(){
          const params = {};
          await this.basicPosts_S(
              config.BASE_API_URL + '/term_year/lists',
              params
          )
          .then(
              async (response) => {
                  console.log('loadTermYear response', response);
                  this.term_year_obj = (response.data && response.data.body)? response.data.body: [];
              }
          )
          .catch(
              async (err) => {
              console.log(err);
              this.term_year_obj = [];
          })
          .finally(async () => {
              this.$forceUpdate();
          });
        },
        async loadConfigObj(param) {
          const params = {}
          await this.basicPosts_S(
            config.BASE_API_URL + '/config/' + param,
            params
          )
            .then(async (response) => {
              console.log('loadConfigObj response', response)
              this[param + '_obj'] =
                response.data && response.data.body ? response.data.body : []
            })
            .catch(async (err) => {
              console.log(err)
              this[param + '_obj'] = []
            })
            .finally(async () => {
              this.$forceUpdate()
            })
        },

        async loadLicenseEventCdList() {
          const params = {}
          params['stat'] = [1]
          await this.basicPosts_S(
            config.BASE_API_URL + '/license_event_cd/lists',
            params
          )
            .then(async response => {
              console.log('loadLicenseEventCdList response', response)
              this.license_event_cd_obj =
                response.data && response.data.body ? response.data.body : []
            })
            .catch(async err => {
              console.log(err)
              this.license_event_cd_obj = []
            })
            .finally(async () => {
              this.$forceUpdate()
            })
        },
        async loadLicenseCdList() {
          const params = {}
          params['stat'] = [1]
          await this.basicPosts_S(
            config.BASE_API_URL + '/license_cd/lists',
            params
          )
            .then(async (response) => {
              console.log('loadLicenseCdList response', response)
              this.license_cd_obj =
                response.data && response.data.body ? response.data.body : []
            })
            .catch(async (err) => {
              console.log(err)
              this.license_cd_obj = []
            })
            .finally(async () => {
              this.$forceUpdate()
            })
        },
        async pageAction(page) {
          this.page = page
          await this.loadMember()
        },
        async search_member(){
          this.page = 0
          await this.loadMember()
        },
        async loadMember(){
          const params = {
            term_year: this.sel_term_year,
            p: 0,
          }
          params['p'] = this.page
          params['stat'] = [1]
          if(this.license_nos.length > 0){
            const tmps = [];
            this.license_nos.map(license => {
              if(
                license!=""
              ){
                tmps.push(license)
              }
            })
            params['license_nos'] = tmps.length > 0? tmps: false
          }
          if(this.name_1){
            params['name_1'] = this.name_1
          }
          if(this.name_2){
            params['name_2'] = this.name_2
          }
          if(this.pref_cd){
            params['pref_cd'] = this.pref_cd
          }
          if(this.license_cd){
            params['license_cd'] = this.license_cd
          }
          else if(this.license_event_cd){
            const tmps = []
            this.license_cd_list.map(license => {
              tmps.push(license.key)
            })
            params['license_cds'] = tmps
          }
          // params['']
          try {
            const response = await this.basicPosts_S(
              `${config.BASE_API_URL}/member/owner_search`,
              params
            )
            console.log('search_member params', params)
            console.log('search_member response', response)
            if(
              response?.status==200 && response.data?.status==200
            ){
              this.members_obj = response.data?.body || []
            }
            else{
              alert('検索エラー')
              this.members_obj = []
            }
            this.pager = response.data?.pager || {}
            this.all_count = response.data?.all_count || 0
          } catch (err) {
            console.error('Load search_member error:', err)
            this.members_obj = []
          } finally {
            this.$forceUpdate()
          }
          
        },
      },
    }
  </script>
  
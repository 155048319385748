<template>
    <div class="myPage">
      <div class="container">
        <div class="row pt-32 pb-32">
          <h2 class="text-center title07">
            <span class="title07__span" v-if="information_obj?.category_type==3">特典情報</span>
            <span class="title07__span" v-else>新着情報</span>
          </h2>
        </div>
  <!-- {{ information_obj }} -->
        <!-- 記事コンテナ -->
        <div class="article-container">
          <!-- 日付とカテゴリー -->
          <div class="article-meta">
            <p class="article-date">
              {{ (information_obj || {}).set_date_date_val }}
            </p>
            <p class="article-category">
              {{ (information_obj || {}).category_type_label }}
            </p>
          </div>
  
          <!-- タイトル -->
          <h3 class="article-title">
            {{ (information_obj || {}).subject }}
          </h3>
  
          <!-- ヘッダー画像 -->
          <div
            class="article-header-image"
            v-if="information_obj && information_obj.header_image_path"
          >
            <img :src="information_obj.header_image_path" alt="ヘッダー画像" />
          </div>
  
          <!-- 本文 -->
          <div class="article-body markdown-content" v-html="parsedBody"></div>
  
          <!-- 添付ファイル -->
          <div
            class="article-attachments"
            v-if="information_obj.assets && information_obj.assets.length"
          >
            <h4>添付ファイル</h4>
            <div class="attachment-list">
              <a
                v-for="(asset, index) in information_obj.assets"
                :key="index"
                :href="asset.path"
                target="_blank"
                class="attachment-link"
              >
                <i class="bi bi-file-earmark"></i>
                {{ asset.label }}
              </a>
            </div>
          </div>
        </div>
  
        <!-- 戻るボタン -->
        <div class="text-center mt-4">
          <router-link class="button02" to="/oinformation/list">
            <font-awesome-icon
              :icon="['fas', 'chevron-left']"
              class="mx-2 align-items-center"
            />
            <span class="button03__text">戻る</span>
          </router-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import config from '../../../const'
    import { marked } from 'marked'
    import DOMPurify from 'dompurify'
  
    export default {
      name: 'OwnerInformationDataView',
      data: function () {
        return {
          BASE_ASSETS_URL: config.BASE_ASSETS_URL,
          information_category_type_obj: [],
          information_obj: {},
        }
      },
      props: {},
      components: {},
      computed: {
        information_category_type_list: function () {
          return this.information_category_type_obj
        },
        parsedBody() {
          if (!this.information_obj?.body) return ''
          const rawHtml = marked(this.information_obj.body)
          return DOMPurify.sanitize(rawHtml)
        }
      },
  
      async created() {
        if (this.$route.query.no) {
          this.targetid = this.$route.query.no
        } else {
          this.$router.push({
            path: '/oinformation/list',
          })
        }
        await this.loadConfigObj('information_category_type')
        await this.loadInformationObj()
        marked.setOptions({
          breaks: true,
          gfm: true
        })
      },
  
      methods: {
        async loadConfigObj(param) {
          const params = {}
          await this.basicPosts_S(
            config.BASE_API_URL + '/config/' + param,
            params
          )
            .then(async (response) => {
              console.log('loadConfigObj response', response)
              this[param + '_obj'] =
                response.data && response.data.body ? response.data.body : []
            })
            .catch(async (err) => {
              console.log(err)
              this[param + '_obj'] = []
            })
            .finally(async () => {
              this.$forceUpdate()
            })
        },
        async loadInformationObj() {
          const params = {}
          params['stat'] = [1]
          params['id'] = this.targetid
          params['target_type'] = 3
          try {
            const response = await this.basicPosts_S(
              `${config.BASE_API_URL}/information/lists`,
              params
            )
            console.log('loadInformationObj params', params)
            console.log('loadInformationObj response', response)
  
            this.information_obj = response.data?.body?.[0] || {}
            console.log(
              'this.information_obj',
              this.information_obj.header_image_path
            )
          } catch (err) {
            console.error('Load information error:', err)
            this.information_obj = {}
          } finally {
            this.$forceUpdate()
          }
        },
      },
    }
  </script>
  
  <style scoped>
  .article-container {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .article-meta {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    gap: 1rem;
  }
  
  .article-date {
    color: #6c757d;
    font-size: 0.9rem;
    margin: 0;
  }
  
  .article-category {
    background: #e9ecef;
    padding: 0.25rem 0.75rem;
    border-radius: 4px;
    font-size: 0.9rem;
    margin: 0;
  }
  
  .article-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #333;
    text-align: left;
  }
  
  .article-header-image {
    margin-bottom: 2rem;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .article-header-image img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .article-body {
    color: #333;
    line-height: 1.8;
    margin-bottom: 2rem;
  }
  
  .markdown-content :deep(h1) {
    font-size: 1.8rem;
    font-weight: 600;
    margin: 2rem 0 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #e9ecef;
  }
  
  .markdown-content :deep(h2) {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1.5rem 0 1rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid #e9ecef;
  }
  
  .markdown-content :deep(p) {
    margin-bottom: 1rem;
  }
  
  .markdown-content :deep(ul),
  .markdown-content :deep(ol) {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
  }
  
  .markdown-content :deep(ul > li) {
    margin-bottom: 0.5rem;
    list-style-type: disc;
  }
  
  .markdown-content :deep(ol > li) {
    margin-bottom: 0.5rem;
    list-style-type: decimal;
  }
  
  .markdown-content :deep(a) {
    color: #007bff;
    text-decoration: none;
  }
  
  .markdown-content :deep(a:hover) {
    text-decoration: underline;
  }
  
  .markdown-content :deep(strong) {
    font-weight: 600;
    color: #2c3e50;
  }
  
  .article-attachments {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #e9ecef;
  }
  
  .article-attachments h4 {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    color: #495057;
  }
  
  .attachment-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .attachment-link {
    display: inline-flex;
    align-items: center;
    color: #007bff;
    text-decoration: none;
    padding: 0.5rem;
    background: #f8f9fa;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .attachment-link i {
    margin-right: 0.5rem;
  }
  
  .attachment-link:hover {
    background: #e9ecef;
    text-decoration: none;
  }
  
  /* レスポンシブ対応 */
  @media (max-width: 768px) {
    .article-container {
      padding: 1.5rem;
    }
  
    .article-title {
      font-size: 1.3rem;
    }
  
    .markdown-content :deep(h1) {
      font-size: 1.5rem;
    }
  
    .markdown-content :deep(h2) {
      font-size: 1.3rem;
    }
  }
  </style>
  
<template>
  <section class="py-5">
    <!-- ローディング表示 -->
    <div class="loading-overlay" v-if="isLoading">
      <div class="spinner-border text-danger" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="d-none d-md-block">
      <step-view :step_mode="page_mode"></step-view>
    </div>
    <div class="w-100">

      <div
        class="text-start col-10 offset-1 col-xl-8 offset-xl-2"
        v-if="kiyaku_mode"
      >
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 w-100 mb-32">
            <div class="text-end">
              <a :href="get_base_asset + '/manual/mfj_member_migration_250203.pdf'">「MFJ-WEB会員サービス 利用開始手続き」 マニュアル</a>
            </div>
          </div>
        </div>
        <div class="box02 mb-32">
          <div>
            <div>
              <h2 class="box02__title">
                <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
                以下の内容をご確認いただき、利用開始手続きを進めてください。
              </h2>
            </div>
          </div>
        </div>

        <div class="mb-5">
          <div class="border border-secondary bg-light rounded-2 py-3 px-4">
            <div class="mt-4 mb-3">
              <h5 class="mb-3 fw-bold">サービス利用規約</h5>

              <p>このサービス利用規約（以下「本規約」といいます。）は、一般財団法人日本モーターサイクルスポーツ協会（以下「MFJ」といいます。）が提供する会員WEBサービス（以下「本サービス」といいます。）を利用する方（以下「会員」といいます。）に適用されるものとします。入会手続を完了した時点で、会員は本規約に同意したものとみなされます。</p>
              <br/>
              <div class="mt-4">
                <p>第1条（会員資格）</p>
                <ol class="numbered-list">
                  <li>会員とは、所定の手続を完了し、MFJが入会を承認した方とします。</li>
                  <li>会員は、本規約及びMFJが定めるルールに従って本サービスを利用するものとします。</li>
                </ol>
              </div>
              <br/>
              <div class="mt-4">
                <p>第2条（利用目的）</p>
                <p>本サービスは、会員に対し、2輪競技者の競技会参加サポートを目的として提供されます。</p>
              </div>
              <br/>
              <div class="mt-4">
                <p>第3条（会員の義務）</p>
                <ol class="numbered-list">
                  <li>会員は、登録時に虚偽の情報を提供してはならないものとします。</li>
                  <li>会員は、ID及びパスワードを適切に管理し、第三者に譲渡又は貸与してはなりません。</li>
                  <li>会員は、MFJ又は他の会員、第三者の権利を侵害する行為や法令に違反する行為を行ってはなりません。</li>
                  <li>会員は、自身のIDやパスワードが漏洩した、又はその恐れがあると判明した場合、速やかにMFJへ報告し、MFJからの指示に従うものとします。</li>
                </ol>
              </div>
              <br/>
              <div class="mt-4">
                <p>第4条（禁止事項）</p>
                <ol class="numbered-list">
                  <li>会員は、以下の行為を行ってはならないものとします。</li>
                  <li>本サービスの運営を妨害する行為</li>
                  <li>本サービスを通じて提供される情報を不正に利用する行為</li>
                  <li>他の会員又は第三者への誹謗中傷や迷惑行為</li>
                  <li>MFJの財産、権利、知的財産を侵害する行為</li>
                  <li>他の会員のプライバシーを侵害する行為（例：個人情報の無断収集・公開）</li>
                  <li>不正アクセス又はシステムへの侵入行為</li>
                </ol>
              </div>
              <br/>
              <div class="mt-4">
                <p>第5条（個人情報の取り扱い）</p>
                <ol class="numbered-list">
                  <li>MFJは、会員の個人情報を「プライバシーポリシー」に従って適切に取り扱います。</li>
                  <li>会員の個人情報は、本サービスの提供及び会員サポートのために利用されます。</li>
                  <li>会員が5年以上にわたり年度継続せず、情報更新も行われない場合、MFJは当該会員の情報を適切な手続により本サービスから破棄することができるものとします。この場合、MFJは個別の通知を行う義務を負わないものとします。</li>
                </ol>
              </div>
              <br/>
              <div class="mt-4">
                <p>第6条（サービスの変更・中断・終了）</p>
                <ol class="numbered-list">
                  <li>MFJは、必要と判断した場合、事前に通知することなく本サービスの内容を変更又は終了することができるものとします。</li>
                  <li>天災、システム障害、その他の不可抗力により本サービスの提供が一時的に中断される場合があります。</li>
                </ol>
              </div>
              <br/>
              <div class="mt-4">
                <p>第7条（退会）</p>
                <ol class="numbered-list">
                  <li>会員は、MFJ所定の手続を行うことで、いつでも本サービスから退会することができます。</li>
                  <li>退会後も、会員は在籍中に行った行為に関する責任を負うものとします。</li>
                </ol>
              </div>
              <br/>
              <div class="mt-4">
                <p>第8条（免責事項）</p>
                <ol class="numbered-list">
                  <li>MFJは、会員が本サービスを利用することによって生じた損害に対し、一切の責任を負わないものとします。ただし、MFJの故意又は過失による場合はこの限りではありません。</li>
                  <li>本サービス内で提供される情報や助言について、MFJはその正確性や信頼性を保証しません。</li>
                  <li>本サービスには、外部のウェブサイトへのリンクが含まれることがありますが、MFJはそれらの外部サイトの内容や正確性、信頼性について一切の責任を負いません。会員が外部サイトを利用する場合は、自己の責任において行うものとします。</li>
                </ol>
              </div>
              <br/>
              <div class="mt-4">
                <p>第9条（規約の変更）</p>
                <p>MFJは、必要に応じて本規約を変更することができるものとし、変更後の規約はMFJウェブサイトに掲載した時点で効力を生じるものとします。</p>
              </div>
              <br/>
              <div class="mt-4">
                <p>第10条（準拠法及び管轄）</p>
                <p>このウェブサイトの利用並びに本ご利用条件の解釈及び適用は、他に別段の定めのない限り、日本国法に準拠するものとします。また、本サービスの利用に関わる全ての紛争については、他に別段の定めのない限り、東京地方裁判所を第一審の専属管轄裁判所とするものとします。</p>
              </div>
            </div>
            <h5 class="mb-3 fw-bold">利用手続きを始める前にご用意していただくもの</h5>
            <ul class="bullet-list ps-3">
              <li>電子ライセンス用の顔写真データ</li>
              <li>本人確認書類のスキャンデータや写真データ</li>
              <li>マイナンバーカード(写真面)/運転免許証(表裏)/パスポート/住⺠票のいずれか1点</li>
              <li>MFJに登録いただいているメールアドレスでメールを受信することができない場合は、お手続きを進めることができませんので受信可能なメールアドレスを1つご用意下さい。</li>
            </ul>
          </div>
        </div>        
        <div class="row">
          <div class="col-3">
            <p class="text-center mt-32">
              <!-- <button class="button03 w-100" @click="back('national')">
                <font-awesome-icon
                  :icon="['fas', 'chevron-left']"
                  class="mx-2"
                />
                <span class="button03__text">戻る</span>
              </button> -->
            </p>
          </div>
          <div class="col-6 process__container">
            <p class="text-center mt-32">
              <button class="button04" @click="proceed('check')">
                <span class="button04__text">同意して進む</span>
                <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
              </button>
            </p>
          </div>
        </div>
      </div>
      <div
        class="text-start col-10 offset-1 col-xl-8 offset-xl-2"
        v-else-if="check_mode"
      >
        <form @submit.prevent="submitForm">
          <div class="mb-5">
            <div class="border border-secondary bg-light rounded-2 py-3 px-4">
              <p class="m-0">
                MFJライセンスNo.と生年月日を入力して「確認する」とクリックして次のページに進んで下さい。<br>
                MFJライセンスNo.と生年月日の組み合わせがMFJの記録と異なる場合は、次のページに進めませんのでご注意下さい。
                <!-- MFJライセンスNo.と生年月日を入力し、「確認メールを送信する」をクリックすると、確認メールが送信されます。<br>
                利用申請を進めるには、確認メールに記載された4桁の確認コードが必要です。<br>
                確認メールが届かない場合、受信拒否設定や迷惑メールとして分類されている可能性がありますのでお手元の迷惑メールフォルダおよび設定をご確認下さい。<br>
                また、登録されたアドレスは次の画面で確認ができます。 -->
              </p>
            </div>
          </div>

          <div class="mb-1">
            <div class="page__inputGroupCol">
              <p class="page__inputGroupInner">
                <label class="label page__inputGroupLabel" for="LicenseNo"
                  >ライセンスNo</label
                >
                <input
                  type="text"
                  class="input page__inputGroupInput"
                  id="LicenseNo"
                  v-model="post_license_no"
                  placeholder="ライセンスNo"
                  @input="validateLicenseNo"
                />
              </p>
              <div v-if="licenseNoError" class="text-danger mt-2">
                {{ licenseNoError }}
              </div>
            </div>
          </div>
          <div class="mb-5">
            <div class="page__inputGroupCol">
              <p class="page__inputGroupInner">
                <label class="label page__inputGroupLabel" for="BirthDate"
                  >生年月日</label
                >
                  <bs-select
                    :options="birth_date_year_list"
                    :nullOption="'生年'"
                    v-model="post_birth_date_y"
                  ></bs-select>
                  <bs-select
                    :options="birth_date_month_list"
                    :nullOption="'月'"
                    v-model="post_birth_date_m"
                  ></bs-select>
                  <bs-select
                    :options="birth_date_day_list(post_birth_date_y, post_birth_date_m)"
                    :nullOption="'日'"
                    v-model="post_birth_date_d"
                  ></bs-select>
              </p>
              <div v-if="birthDateError" class="text-danger mt-2">
                {{ birthDateError }}
              </div>
            </div>
          </div>
          <div class="container mb-4">
            <div
              class="d-flex flex-column flex-md-row mt-32 justify-content-center align-items-center gap-3 gap-md-4 w-100"
            >
              <button class="button03 w-100" @click="back('kiyaku')">
                <font-awesome-icon
                  :icon="['fas', 'chevron-left']"
                  class="mx-2"
                />
                <span class="button03__text">戻る</span>
              </button>
              <button class="button04 w-100" @click="chk_license_no_and_birth_date()">
                <span class="button04__text">進む</span>
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="mx-2"
                />
              </button>
            </div>
          </div>
        </form>
      </div><div
        class="text-start col-10 offset-1 col-xl-8 offset-xl-2"
        v-else-if="mail_mode"
      >
        <form @submit.prevent="submitForm">
          <div class="container mb-5" v-if="get_migration_mail">
            <div class="box02__title">
              <p v-for="mail in get_migration_mail" :key="mail">
                {{ mail_mask(mail.migration_value) }}
              </p>
              <p class="mb-2">上のアドレス宛に確認メールを送信します。<br>
                もし上記アドレスが受信できない場合は以下のボタンからメールアドレスを登録して下さい。</p>
              <div class="text-center">
                <a @click="toggle_update_mail_chk_flg" class="btn btn-warning btn-sm" v-text="(update_mail_chk_flg)? '変更しない': 'メールアドレスの変更'"></a>
              </div>
            </div>
          </div>
          <div class="container mb-5" v-else>
            <div class="box02__title">
              <p class="mb-2">有効なメールアドレスが登録されていません。以下のボタンからメールアドレスを登録して下さい。</p>
              <div class="text-center">
                <a @click="toggle_update_mail_chk_flg" class="btn btn-warning btn-sm" v-text="(update_mail_chk_flg)? '変更しない': 'メールアドレスの変更'"></a>
              </div>
            </div>
          </div>
          <div class="box02 mb-32">
            <h2 class="box02__title">
              <p v-html="message"></p>
            </h2>
          </div>
          <div class="mb-5" v-if="update_mail_chk_flg">
            <div class="page__inputGroupCol">
              <p class="page__inputGroupInner">
                <label class="label page__inputGroupLabel" for="email"
                  >新しいメールアドレス</label
                >
                <input
                  type="email"
                  class="input page__inputGroupInput"
                  id="email"
                  v-model="post_mail_address"
                  placeholder="touroku@mfj.or.jp"
                  @input="validateEmail"
                />
              </p>
              <div v-if="emailError" class="text-danger mt-2">
                {{ emailError }}
              </div>
            </div>
          </div>
          <div class="mb-5" v-if="false">
            <div class="page__inputGroupCol">
              <p class="page__inputGroupInner">
                <label class="label page__inputGroupLabel" for="email"
                  >メールアドレス</label
                >
                <input
                  type="email"
                  class="input page__inputGroupInput"
                  id="email"
                  v-model="post_mail_address"
                  placeholder="touroku@mfj.or.jp"
                  @input="validateEmail"
                />
              </p>
              <div v-if="emailError" class="text-danger mt-2">
                {{ emailError }}
              </div>
            </div>
          </div>
          <div class="container mb-4">
            <div
              class="d-flex flex-column flex-md-row mt-32 justify-content-center align-items-center gap-3 gap-md-4 w-100"
            >
              <button class="button03 w-100" @click="back('check')">
                <font-awesome-icon
                  :icon="['fas', 'chevron-left']"
                  class="mx-2"
                />
                <span class="button03__text">戻る</span>
              </button>
              <button class="button04 w-100" @click="update_mail_chk()"  v-if="update_mail_chk_flg">
                <span class="button04__text">新しいメールアドレスに送信する</span>
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="mx-2"
                />
              </button>
              <button class="button04 w-100" @click="send_pass_cd()"  v-else>
                <span class="button04__text">送信する</span>
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="mx-2"
                />
              </button>
            </div>
          </div>
        </form>
      </div>

      <div
        class="text-start col-10 offset-1 col-xl-6 offset-xl-3"
        v-else-if="passcd_mode"
      >
        <div class="mb-3">
          <div class="box02 mb-32">
            <h2 class="box02__title">
              <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
              確認Noの入力
            </h2>
          </div>
        </div>
        <div class="mb-5">
          <div class="page__inputGroupCol">
            <p class="page__inputGroupInner">
              <label class="label page__inputGroupLabel" for="inputEmail"
                >確認No.</label
              >
              <input
                type="text"
                class="input page__inputGroupInput"
                id="inputEmail"
                v-model="passcd"
                placeholder="例 0123"
              />
            </p>
            <div
              v-if="isPasscdSubmitted && passcdError"
              class="text-danger mt-2"
            >
              {{ passcdError }}
            </div>
          </div>
          <div class="container mb-4">
            <div
              class="d-flex flex-column flex-md-row mt-32 justify-content-center align-items-center gap-3 gap-md-4 w-100"
            >
              <button class="button03 w-100" @click="back('check')">
                <font-awesome-icon
                  :icon="['fas', 'chevron-left']"
                  class="mx-2"
                />
                <span class="button03__text">戻る</span>
              </button>
              <button class="button04 w-100" @click="chk_pass_cd()">
                <span class="button04__text">進む</span>
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="mx-2"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.375rem;
    border-bottom: solid 1px #999;
  }

  .form-control-plaintext {
    border-bottom: solid 1px #999;
    border-bottom: solid 1px #999;
  }

  .numbered-list {
  counter-reset: item;
  list-style: none;
  padding-left: 1.5em;
  }
  .numbered-list li {
    counter-increment: item;
    margin-bottom: 0.5em;
    position: relative;
  }
  .numbered-list li::before {
    content: counter(item) ".";
    position: absolute;
    left: -1.5em;
  }
  .bullet-list {
  list-style: none;
  padding-left: 1.5em;
  }
  .bullet-list li {
    position: relative;
    margin-bottom: 0.5em;
  }
  .bullet-list li::before {
    content: "・";
    position: absolute;
    left: -1em;
  }
</style>
<script>
  import { mapState } from 'vuex'
  import StepView from './StepView.vue'
  import BsSelect from '../../atoms/BsSelect.vue'
  import config from '../../../const'
  import moment from 'moment'
  import Common from '../../../common/common'
  // メールアドレスのフォーマットをチェックする正規表現
  const REGEX_EMAIL =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // ライセンスNoのフォーマットをチェックする正規表現
  const REGEX_LICENSENO =
    /^[0-9]{6}$/
  const REGEX_BIRTHDATE =
    /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/

  export default {
    name: 'MigrationAddView',
    data: function () {
      return {
        page_mode: 'kiyaku',
        update_result: false,
        update_error: false,
        migration_obj: {},
        message_class: 'info',
        message: '',
        target_step: ['kiyaku', 'mail'],
        post_license_no: '',
        post_birth_date_y: '',
        post_birth_date_m: '',
        post_birth_date_d: '',
        post_mail_address: [],

        update_mail_chk_flg: false,
        
        passcd: '',
        isValidLicenseNo: false,
        isValidEmail: false,
        email: '',
        isFormSubmitted: false,
        isPasscdSubmitted: false,
        isLoading: false,
      }
    },
    components: {
      StepView,
      BsSelect,
    },
    methods: {
      proceed(mode) {
        this.page_mode = mode
        this.message = '確認して下さい'
      },
      back(mode) {
        this.message_class = 'info'
        this.page_mode = mode
      },
      async toggle_update_mail_chk_flg(){
        this.update_mail_chk_flg = (
          this.update_mail_chk_flg
        )? false: true
      },
      async chk_license_no_and_birth_date(){
        this.isLoading = true
        try {
          await new Promise(resolve => setTimeout(resolve, 500))
          
          this.isFormSubmitted = true
          if (!this.post_license_no) {
            this.message_class = 'danger'
            this.message = 'ライセンスNoを入力して送信してください。'
            this.licenseNoError = 'ライセンスNoを入力して送信してください。'
            this.$forceUpdate()
            return
          }
          if (!this.isValidLicenseNo) {
            this.message_class = 'danger'
            this.message = '有効なライセンスNoを入力してください。'
            this.licenseNoError = '有効なライセンスNoを入力してください。'
            this.$forceUpdate()
            return
          }
          const params = {}
          params['license_no'] = this.post_license_no
          params['birth_date'] = this.post_birth_date
          let result = false
          await this.nonloginPosts_S(
            config.BASE_API_URL + '/member_migration/lists',
            params
          )
          .then(async (response) => {
            console.log('chk_license_no_and_birth_date!!!', response)
            if (
              response.data &&
              response.data.status == 200 &&
              response.data.body &&
              response.data.body[0]
            ) {
              this.page_mode = 'mail'
              // this.message = 'メールアドレスを確認してください'
              this.message = '確認メールが届かない場合や送信先メールアドレスが表示されていない場合は、「メールアドレス変更」をクリックして、メールアドレスを変更して申請をご継続下さい。<br>メールアドレスを変更すると、MFJによる提出書類の確認が完了するまで新システムの利用ができない点にご注意ください。<br>※登録済みメールアドレスで申請を行うと即時に利用可能となります。'
              // this.proceed('mail')
              this.migration_obj = response.data.body[0]
              result = true
            } else {
              this.message_class = 'danger'
              this.message = 'データが存在しません'
              this.migration_obj = {}
              alert(this.message)
            }
            console.log('chk_license_no_and_birth_date ??', this.message );
          })
          .catch(async (err) => {
            this.message = err
            this.migration_obj = {}
            console.log('error', err)
          })
          .finally(async () => {
            this.$forceUpdate()
            console.log('chk_license_no_and_birth_date finally', this.migration_obj );
            if(result ){
              // this.get_migration_mail
            }
            // if(result) await this.send_pass_cd();
          })
        } finally {
          this.isLoading = false
        }
      },
      async update_mail_chk(){
        this.isLoading = true
        try {
          await new Promise(resolve => setTimeout(resolve, 500))
          
          if(!this.update_mail_chk_flg){
            alert('エラー')
            return
          }
          if (this.emailError) {
            // computed プロパティを使用
            this.message_class = 'danger'
            this.message = this.emailError
            this.$forceUpdate()
            console.log('send_pass_cd func1');
            return
          }
          if (!this.post_mail_address) {
            this.message_class = 'danger'
            this.message = 'メールアドレスを入力して送信してください。'
            this.emailError = 'メールアドレスを入力してください。'
            this.$forceUpdate()
            console.log('update_mail_chk func2');
            return
          }
          if (!this.isValidEmail) {
            this.message_class = 'danger'
            this.message = '有効なメールアドレスを入力してください。'
            this.emailError = '有効なメールアドレスを入力してください。'
            this.$forceUpdate()
            console.log('update_mail_chk func3');
            return
          }
          const params = {...this.migration_obj}
          if(params?.token || params?.token == null) delete params.token
          if(params?.pass_cd || params?.pass_cd == null) delete params.pass_cd
          params.chg_mail_flg = 1
          if(typeof params.items !== 'object') params.items = []
          if(
            !params.items.find(item => (item.migration_key == 'up_mail_address' && item.migration_value == this.post_mail_address))
          ){
            params.items.push({
              id: 0,
              stat: 1,
              migration_id: (this.migration_obj || {}).id,
              license_no: (this.migration_obj || {}).license_no,
              migration_key: 'up_mail_address',
              migration_value: this.post_mail_address
            })
          }
          const url = config.BASE_API_URL + '/member_migration/edit/' + (this.migration_obj || {}).id
          console.log('update_mail_chk url', url)
          console.log('update_mail_chk params', params)
          let result = false
          await this.nonloginPosts_I(
            url,
            params
          )
          .then(async (response) => {
            console.log('update_mail_chk!!!', response)
            if (
              response.data &&
              response.data.status == 200
            ) {
              result = true
            }
          })
          .catch(async (err) => {
            this.message = err
            console.log('error', err)
          })
          .finally(async () => {
            this.$forceUpdate()
            if(result) await this.send_pass_cd()
          })
        } finally {
          this.isLoading = false
        }
      },
      async send_pass_cd() {
        this.isLoading = true
        try {
          await new Promise(resolve => setTimeout(resolve, 500))
          
          console.log('send_pass_cd func')
          this.isFormSubmitted = true // フォーム送信状態を true に設定
          const params = {}
          params['id'] = (this.migration_obj||{}).id
          
          console.log('send_pass_cd params', params, this.migration_obj);
          await this.nonloginPosts_S(
            config.BASE_API_URL + '/member_migration/send_pass_cd',
            params
          )
            .then(async (response) => {
              console.log('send_pass_cd!!!', response)
              if (
                response.data &&
                response.data.status == 200 &&
                response.data.body &&
                response.data.body.status == 200
              ) {
                this.update_result = true
                this.update_error = false
                this.page_mode = 'passcd'
                this.message = '確認Noを入力してください'
                // this.data_obj =
                //   response.data && response.data.body && response.data.body
                //     ? response.data.body
                //     : {}
              } else if (
                response.data &&
                response.data.status == 200 &&
                response.data.body &&
                response.data.body.status != 200
              ) {
                this.update_error = true
                this.message_class = 'danger'
                this.message = response.data.body.message
                  ? response.data.body.message
                  : 'メールアドレス宛に送信できませんでした。'
              } else {
                this.update_error = true
                this.message_class = 'danger'
                this.message = 'メールアドレス宛に送信できませんでした。。'
              }
            })
            .catch(async (err) => {
              this.update_result = false
              this.update_error = true
              this.message = err
              console.log('error', err)
            })
            .finally(async () => {
              this.$forceUpdate()
            })
        } finally {
          this.isLoading = false
        }
      },
      async chk_pass_cd() {
        this.isLoading = true
        try {
          await new Promise(resolve => setTimeout(resolve, 500))
          
          if (!this.passcd || this.passcd.length !== 4) {
            alert('4桁の確認Noを入力して下さい')
            return false
          }

          const params = {}
          params['id'] = (this.migration_obj || {}).id
          params['pass_cd'] = this.passcd

          const response = await this.nonloginPosts_S(
            config.BASE_API_URL + '/member_migration/chk_pass_cd',
            params
          )

          console.log('chk_pass_cd!!!', response)
          if (
            response.data &&
            response.data.status == 200 &&
            response.data.body &&
            response.data.body.status == 200
          ) {
            this.update_result = true
            this.update_error = false
            if (response.data.body.token) {
              this.go_form(response.data.body.token)
            } else {
              alert('error')
            }
          } else if (
            response.data &&
            response.data.status == 200 &&
            response.data.body &&
            response.data.body.status != 200
          ) {
            this.update_error = true
            this.message_class = 'danger'
            this.message =
              response.data.body.message || '確認Noが確認できませんでした。'
          } else {
            this.update_error = true
            this.message_class = 'danger'
            this.message = '確認Noが確認できませんでした。'
          }
        } catch (err) {
          this.update_result = false
          this.update_error = true
          this.message = err
          console.log('error', err)
        } finally {
          this.isLoading = false
        }
      },
      async go_form(token) {
        this.$router.push({
          path: '/migration/form',
          query: {
            token: token,
          },
        })
      },
      async loadMigrationData() {
        const params = {}
        params['stat'] = [1]
        //   params['application_check_type'] = 100;
        //   params['application_type'] = 2500;
        params['token'] = this.$route.query.token
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/member_migration/lists',
          params
        )
          .then(async (response) => {
            console.log('loadMigrationData!!!', response)
            if (response.data && response.data.status == 200) {
              this.migration_obj =
                response.data && response.data.body && response.data.body[0]
                  ? response.data.body[0]
                  : {}
              if ((this.migration_obj || {}).step != 'add') {
                console.log('not add redirect')
              }
            } else {
              // this.message = '規約を確認し、同意して申請を進めてください'
              this.message = '申請の流れを確認し、申請を進めて下さい。'
            }
          })
          .catch(async (err) => {
            this.message = err
            console.log('error', err)
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      validateLicenseNo() {
        this.isValidLicenseNo = REGEX_LICENSENO.test(this.post_license_no)
        this.message = '' // メッセージをクリア
      },
      validateEmail() {
        this.isValidEmail = REGEX_EMAIL.test(this.post_mail_address)
        this.message = '' // メッセージをクリア
      },
    },
    computed: {
      emailError() {
        if (!this.isFormSubmitted) {
          return '' // フォームが送信されていない場合は空文字を返す
        }
        if (!this.post_mail_address) {
          return 'メールアドレスは必須です。'
        }
        if (!REGEX_EMAIL.test(this.post_mail_address)) {
          return '有効なメールアドレスを入力してください。'
        }
        return ''
      },
      licenseNoError() {
        if (!this.isFormSubmitted) {
          return '' // フォームが送信されていない場合は空文字を返す
        }
        if (!this.post_license_no) {
          return 'ライセンスNoは必須です。'
        }
        if (!REGEX_LICENSENO.test(this.post_license_no)) {
          return '有効なライセンスNoを入力してください。'
        }
        return ''
      },
      birthDateError() {
        if (!this.isFormSubmitted) {
          return '' // フォームが送信されていない場合は空文字を返す
        }
        if (!this.post_birth_date_y) {
          return '生年月日は必須です。'
        }
        if (!this.post_birth_date_m) {
          return '生年月日は必須です。'
        }
        if (!this.post_birth_date_d) {
          return '生年月日は必須です。'
        }
        if (!REGEX_BIRTHDATE.test(this.post_birth_date)) {
          return '有効な生年月日を入力してください。'
        }
        return ''
      },
      passcdError() {
        if (!this.passcd) {
          return '確認Noは必須です。'
        }
        if (this.passcd.length !== 4 || !/^\d{4}$/.test(this.passcd)) {
          return '確認Noは4桁の数字で入力してください。'
        }
        return ''
      },
      birth_date_year_list: function () {
        let now = 1920
        let limit = parseInt(Common.getNowYear()) + 1
        const tmps = []
        for (let i = now; i < 1970; i++) {
          tmps.push({
            key: i,
            label: i,
          })
        }
        tmps.push({
          key: '',
          label: '選択して下さい',
        })
        for (let i = 1970; i < limit; i++) {
          tmps.push({
            key: i,
            label: i,
          })
        }
        return tmps
      },
      birth_date_month_list: function () {
        const tmps = []
        for (let i = 1; i <= 12; i++) {
          tmps.push({
            key: i,
            label: i,
          })
        }
        return tmps
      },
      birth_date_day_list: function () {
        // const moment_date = moment();
        const moment_func = moment
        // const year = this.birth_date_year;
        // const month = this.birth_date_month;
        return function (year, month) {
          let max = 31
          if (year != '' && month != '') {
            const moment = moment_func([year, parseInt(month) - 1, 1])
            max = moment.daysInMonth()
          }
          const tmps = []
          for (let i = 1; i <= max; i++) {
            tmps.push({
              key: i,
              label: i,
            })
          }
          return tmps
        }
      },
      post_birth_date: function(){
        let date = (this.post_birth_date_y && this.post_birth_date_y > 1900)? this.post_birth_date_y: '0000';
        date += '-'
        if(
          parseInt(this.post_birth_date_m) < 10
        ){
          date += '0'
        }
        date += this.post_birth_date_m
        date += '-'
        if(
          parseInt(this.post_birth_date_d) < 10
        ){
          date += '0'
        }
        date += this.post_birth_date_d
        return date
      },
      get_migration_mail: function(){
        if(
          typeof this.migration_obj === 'object'
          && typeof this.migration_obj.items === 'object'
          && this.migration_obj.items.find(obj => (obj.migration_key == 'mail_address' || obj.migration_key == 'up_mail_address'))
        ){
          return this.migration_obj.items.filter(obj => (obj.migration_key == 'mail_address' || obj.migration_key == 'up_mail_address'));
        }
        return false;
      },
      mail_mask: function(){
        return function(mail_addres){
          if(typeof mail_addres !== 'string') return ''
          let account = '';
          let domain = '';
          if(
            mail_addres.indexOf('@') > 0
          ){
            const adr = mail_addres.split('@');
            for(let i=0;i<adr[0].length;i++){
              if(i<2){
                account += adr[0].charAt(i)
              }else{
                account += '*'
              }
            }
            for(let i=0;i<adr[1].length;i++){
              if(i<2){
                domain += adr[1].charAt(i)
              }else{
                domain += '*'
              }
            }
          }
          return account + '@' + domain
        }
      },
      ...mapState({}),
      message_class_style: function () {
        const message_class = this.message_class
        return function (target) {
          if (target == 'border') {
            return (
              'text-center py-2 bs_fs_5 mb-2 border-bottom border-' +
              message_class
            )
          } else if (target == 'alert') {
            return 'alert alert-' + message_class
          }
        }
      },
      national_mode: function () {
        return this.page_mode == 'national' ? true : false
      },
      kiyaku_mode: function () {
        return this.page_mode == 'kiyaku' ? true : false
      },
      check_mode: function(){
        return this.page_mode == 'check' ? true : false
      },
      mail_mode: function () {
        return this.page_mode == 'mail' ? true : false
      },
      passcd_mode: function () {
        return this.page_mode == 'passcd' ? true : false
      },
    },

    async created() {
      // await this.loadMigrationData()
    },
    watch: {
      post_mail_address() {
        this.validateEmail()
      },
    },
    mounted: function () {
      this.validateEmail()
    },
    props: {},
  }
</script>

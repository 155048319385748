<template>
  <section class="page">
    <div class="container">
      <h2 class="title page__h1">個人情報変更申請</h2>
      <div class="box box--center mb-24">
        <p>
          {{ message }}
        </p>
      </div>

      <h3 class="title02 page__h2">氏名</h3>
      <div class="mb-40">
        <div class="page__inputGroup row">
          <div class="page__inputGroupCol col-md-6">
            <p class="page__inputGroupInner">
              <label class="label page__inputGroupLabel">姓</label>
              <span class="showData page__inputGroupInput">{{ form_obj.name_1 }}</span>
            </p>
          </div>
          <div class="page__inputGroupCol col-md-6">
            <p class="page__inputGroupInner">
              <label class="label page__inputGroupLabel">名</label>
              <span class="showData page__inputGroupInput">{{ form_obj.name_2 }}</span>
            </p>
          </div>
        </div>

        <div class="page__inputGroup row">
          <div class="page__inputGroupCol col-md-6">
            <p class="page__inputGroupInner">
              <label class="label page__inputGroupLabel">セイ</label>
              <span class="showData page__inputGroupInput">{{ form_obj.yomi_1 }}</span>
            </p>
          </div>
          <div class="page__inputGroupCol col-md-6">
            <p class="page__inputGroupInner">
              <label class="label page__inputGroupLabel">メイ</label>
              <span class="showData page__inputGroupInput">{{ form_obj.yomi_2 }}</span>
            </p>
          </div>
        </div>

        <p class="text-center mt-32">
          <router-link class="button04" to="/edit/name" :class="get_target_application_btn_class(2100)">
            <span class="button04__text">{{ application_btn_label(2100) }}</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </router-link>
        </p> 
      </div>

      <h3 class="title02 page__h2">個人情報</h3>
      <div class="mb-40">
        <div class="row">
          <div class="col-md-6">
            <div class="page__inputGroup">
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel">生年月日</label>
                  <span class="showData page__inputGroupInput">
                    <input type="date" v-model="form_obj.birth_date" :class="input_class" :readonly="input_readonly" />
                  </span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel">性別</label>
                  <span class="showData page__inputGroupInput">
                    <input type="text" :value="get_sex_label(form_obj.sex_type)" :class="input_class"
                      :readonly="input_readonly" />
                  </span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel">写真</label>
                  <span class="showData page__inputGroupInput">
                    <div class="w-100" v-if="typeof get_assets(100, 0) === 'object'">
                      <img :src="(get_assets(100, 0) || {}).path" class="w-100" />
                    </div>
                    <button v-if="!change_mode" type="button" @click="clickUpFileBtn()"
                      class="btn btn-light btn-outline-secondary">
                      <font-awesome-icon :icon="['fas', 'file-alt']" class="mx-2" />
                      <span>ファイルを選択する</span>
                    </button>
                    <input type="file" class="form-control" id="file_up_input_100" @change="selectedFile($event, 100)"
                      style="display: none" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <p class="text-center mt-32"> -->
          <!-- <router-link class="button04" to="/edit/photo" :class="get_target_application_btn_class(2200)">
            <span class="button04__text">{{ application_btn_label(2200) }}</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </router-link> -->
          <!-- {{ dev_msg('※年度更新時のみ') }} -->
        <!-- </p> -->
      </div>

      <h3 class="title02 page__h2">登録住所</h3>
      <div class="mb-40">
        <div class="row">
          <div class="col-md-6">
            <div class="page__inputGroup">
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel">郵便番号</label>
                  <span class="showData page__inputGroupInput">
                    <div class="input-group">
                      <input type="text" v-model="form_obj.postal_cd" :class="input_class" :readonly="input_readonly" />
                      <button v-if="!change_mode" type="button" class="btn btn-light btn-outline-secondary"
                        @click="get_address()">
                        検索
                      </button>
                    </div>
                  </span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel">都道府県</label>
                  <span class="showData page__inputGroupInput">
                    <div class="input-group">
                      <input v-if="change_mode" type="text" :class="input_class" :readonly="input_readonly"
                        :value="get_pref_label(form_obj.pref_cd)" />
                      <bs-select v-else :options="pref_list" :nullOption="false" v-model="form_obj.pref_cd"
                        :readonly="input_readonly"></bs-select>
                    </div>
                  </span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel">市区町村・番地</label>
                  <span class="showData page__inputGroupInput">
                    <input type="text" v-model="form_obj.address_1" :class="input_class" :readonly="input_readonly" />
                  </span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel">建物名・室号</label>
                  <span class="showData page__inputGroupInput">
                    <input type="text" v-model="form_obj.address_2" :class="input_class" :readonly="input_readonly" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <p class="text-center mt-32">
          <router-link class="button04" to="/edit/address" :class="get_target_application_btn_class(2300)">
            <span class="button04__text">{{ application_btn_label(2300) }}</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </router-link>
        </p>
      </div>


      <h3 class="title02 page__h2">登録電話番号</h3>
      <div class="mb-40">
        <div class="row">
          <div class="col-md-6">
            <div class="page__inputGroup">
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel">電話番号</label>
                  <span class="showData page__inputGroupInput">
                    <input type="text" v-model="form_obj.tel_1" :class="input_class" :readonly="input_readonly" />
                  </span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel">携帯番号</label>
                  <span class="showData page__inputGroupInput">
                    <input type="text" v-model="form_obj.tel_2" :class="input_class" :readonly="input_readonly" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <p class="text-center mt-32">
          <router-link class="button04" to="/edit/tel" :class="get_target_application_btn_class(2400)">
            <span class="button04__text">{{ application_btn_label(2400) }}</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </router-link>
        </p>
      </div>

      <!-- {{ dev_msg('配送先住所を追記') }}
      {{ dev_msg('申請→事務局確認はは名前だけ') }} -->

      <h3 class="title02 page__h2">配送先住所を追加</h3>
      <div class="mb-40" v-for="shipping in shipping_address_list" :key="shipping">
        <div class="row">
          <div class="col-md-6">
            <div class="page__inputGroup">
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel">郵便番号</label>
                  <span class="showData page__inputGroupInput">
                    <div class="input-group">
                      <input type="text" v-model="shipping.postal_cd" :class="input_class" :readonly="input_readonly" />
                      <button v-if="!change_mode" type="button" class="btn btn-light btn-outline-secondary"
                        @click="get_address()">
                        検索
                      </button>
                    </div>
                  </span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel">都道府県</label>
                  <span class="showData page__inputGroupInput">
                    <div class="input-group">
                      <input v-if="change_mode" type="text" :class="input_class" :readonly="input_readonly"
                        :value="get_pref_label(shipping.pref_cd)" />
                      <bs-select v-else :options="pref_list" :nullOption="false" v-model="shipping.pref_cd"
                        :readonly="input_readonly"></bs-select>
                    </div>
                  </span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel">市区町村・番地</label>
                  <span class="showData page__inputGroupInput">
                    <input type="text" v-model="shipping.address_1" :class="input_class" :readonly="input_readonly" />
                  </span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel">建物名・室号</label>
                  <span class="showData page__inputGroupInput">
                    <input type="text" v-model="shipping.address_2" :class="input_class" :readonly="input_readonly" />
                  </span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner">
                  <label class="label page__inputGroupLabel">電話番号</label>
                  <span class="showData page__inputGroupInput">
                    <input type="text" v-model="shipping.tel_1" :class="input_class" :readonly="input_readonly" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <p class="text-center mt-32">
          <router-link class="button04" to="/edit/shipping" :class="get_target_application_btn_class(2340)">
            <span class="button04__text">{{ application_btn_label(2340) }}</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </router-link>
        </p>
      </div>
      <div v-if="Object.keys(shipping_address_list).length <= 0">
        <p class="text-center mt-32">
          <router-link class="button04" to="/edit/shipping" :class="get_target_application_btn_class(2340)">
            <span class="button04__text">配送先住所を追加</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </router-link>
        </p>
      </div>
      <!-- <div class="mt-40">
        --確認用--
        {{ data_obj }}
        <button @click="download_card">card</button>
      </div> -->
      <div class="mt-40">
        <div class="col-3 col-lg-4 text-start cancel__button">
          <button class="button03" @click="back" v-if="change_mode">
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
            <span class="button03__text">
              戻る
            </span>
          </button>
          <button class="button03" @click="back" v-else>
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
            <span class="button03__text">
              戻る
            </span>
          </button>
        </div>
        <div class="col-6 col-lg-4 d-none">
          <div class="d-grid">
            <button v-if="change_mode" type="button" @click="update" class="btn btn-warning">
              <span class="button03__text">
                申請する
              </span>
              <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
            </button>
            <button v-else type="button" @click="confirm_chk" class="btn btn-light btn-outline-secondary">
              <span class="button03__text">
                確認する
              </span>
              <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import BsSelect from '../../atoms/BsSelect.vue'
import config from '../../../const'

export default {
  name: 'UserDataView',
  data: function () {
    return {
      targetid: this.$store.state.auth.userId,
      btnLabel: '',
      data_obj: {},
      form_obj: {},
      change_login_id: '',
      confirm_login_id: '',
      change_mode: true,
      message:
        '登録された情報を変更する場合は、各項目から変更申請を行って下さい。',
      pref_obj: [],
      sex_type_obj: [],
      request_type_cd_obj: [],
      application_obj: [],
      application_type_obj: [],
      application_check_type_obj: [],
      shipping_address_obj: [],
    }
  },
  components: {
    BsSelect,
    //   BsMsgAlert,
    // BsCheckBox,
  },
  methods: {
    async download_card(){
      const params = {};
      // params['member_token'] = this.$store.state.auth.userId
      params['member_token'] =  this.$store.state.auth.token
      params['pagegroup'] = 'mfj_member_data.id'
      console.log('download_card params', params);
      await this.basicPosts_S(config.BASE_API_URL + '/member/output_card', params)
      .then(
          (response) => {
              console.log('download_card then', response);
              // window.open(config.BASE_API_URL + '/member/download?file=' + response.data.body, '_blank');
          })
      .catch(
          (err) => {
          Promise.reject(err);
          console.log('output error ', err);
          }
      );
    },
    async get_address() {
      const postal_cd = this.form_obj.postal_cd
      await this.basicGets_O(
        'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + postal_cd
      )
        .then((res) => {
          if (
            typeof res === 'object' &&
            typeof res.status === 'number' &&
            res.status == 200 &&
            typeof res.data === 'object' &&
            typeof res.data.results === 'object' &&
            typeof res.data.results[0] === 'object'
          ) {
            this.form_obj.pref_cd =
              parseInt(res.data.results[0].prefcode) < 10
                ? '0' + res.data.results[0].prefcode
                : res.data.results[0].prefcode
            this.form_obj.address_1 =
              res.data.results[0].address2 + res.data.results[0].address3
            this.form_obj.address_2 = ''
          } else {
            alert('住所データがありません')
          }
        })
        .catch((error) => {
          console.log(error)
          alert('住所データがありません.')
        })
        .finally(() => {
          this.$forceUpdate()
        })
    },
    async selectedFile(e, type_cd) {
      e.preventDefault()
      const files = [...e.target.files]
      // let ups = [];
      files.forEach(async (file) => {
        const form = new FormData()
        form.append('file', file)
        await this.basicPosts_R(
          config.BASE_API_URL + '/upload/member_assets',
          form
        )
          .then((res) => {
            console.log(res)
            if (res && res.status == 200) {
              const asset_tmp = {
                id: 0,
                stat: 1,
                m_id: this.$store.state.auth.userId,
                type: 0,
                path: '',
              }
              const tmps =
                typeof this.form_obj.member_assets === 'object'
                  ? [...this.form_obj.member_assets]
                  : []
              const asset = tmps.find((tmp) => tmp.type == type_cd)
                ? tmps.find((tmp) => tmp.type == type_cd)
                : asset_tmp
              asset.type = type_cd
              asset.path = res.data.body.file_path
                ? res.data.body.file_path
                : ''

              if (tmps.find((tmp) => tmp.id == asset.id)) {
                const fIndex = tmps.findIndex((tmp) => tmp.id == asset.id)
                tmps[fIndex] = asset
                this.form_obj.member_assets = tmps
              } else {
                this.form_obj.member_assets = [asset].concat(tmps)
              }
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            document.getElementById('file_up_input_' + type_cd).value = ''
            this.$forceUpdate()
          })
      })
    },
    async clickUpFileBtn() {
      document.getElementById('file_up_input_100').click()
    },
    async loadConfigObj(param) {
      const params = {}
      await this.basicPosts_S(
        config.BASE_API_URL + '/config/' + param,
        params
      )
        .then(async (response) => {
          console.log('loadConfigObj response', response)
          this[param + '_obj'] =
            response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          this[param + '_obj'] = []
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    async loadPrefList() {
      const params = {}
      params['stat'] = [1]
      await this.basicPosts_S(
        config.BASE_API_URL + '/area_pref/lists',
        params
      )
        .then(async (response) => {
          console.log('loadPrefList response', response)
          this.pref_obj =
            response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          this.pref_obj = []
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    async loadShippingObj() {
      const params = {}
      params['stat'] = [1]
      params['m_id'] = this.$store.state.auth.userId
      params['pagegroup'] = 'mfj_member_shipping_address.id'
      await this.basicPosts_S(
        config.BASE_API_URL + '/member_shipping/lists',
        params
      )
        .then(async (response) => {
          console.log('loadShippingObj', response)
          this.shipping_address_obj =
            response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          this.shipping_address_obj = []
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    async loadUserObj() {
      const params = {}
      params['stat'] = [1]
      params['id'] = this.$store.state.auth.userId
      await this.basicPosts_S(config.BASE_API_URL + '/member/lists', params)
        .then(async (response) => {
          console.log('loadUserObj', response)
          this.data_obj =
            response.data && response.data.body && response.data.body[0]
              ? response.data.body[0]
              : {}
        })
        .catch(async (err) => {
          console.log(err)
          this.data_obj = []
        })
        .finally(async () => {
          await this.setUserObj()
          this.$forceUpdate()
        })
    },
    async loadApplicationObj() {
      const params = {}
      params['stat'] = [1]
      params['m_id'] = this.$store.state.auth.userId
      params['not_application_check_types'] = [500, 900]
      await this.basicPosts_S(
        config.BASE_API_URL + '/member_application/lists',
        params
      )
        .then(async (response) => {
          console.log('loadApplicationObj', response)
          this.application_obj =
            response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          this.application_obj = []
        })
        .finally(async () => {
          await this.setUserObj()
          this.$forceUpdate()
        })
    },
    async setUserObj() {
      if (this.data_obj.tel_1 == '--') {
        this.data_obj.tel_1 = ''
      }
      if (this.data_obj.tel_2 == '--') {
        this.data_obj.tel_2 = ''
      }
      if (
        typeof this.data_obj.postal_cd === 'string' &&
        this.data_obj.postal_cd.indexOf('-') < 0 &&
        this.data_obj.postal_cd.length > 3
      ) {
        const start_code = this.data_obj.postal_cd.substr(0, 3)
        const end_code = this.data_obj.postal_cd.substr(3)
        this.data_obj.postal_cd = start_code + '-' + end_code
      }

      this.form_obj = {
        name_1: this.data_obj.name_1,
        name_2: this.data_obj.name_2,
        yomi_1: this.data_obj.yomi_1,
        yomi_2: this.data_obj.yomi_2,
        birth_date: this.data_obj.birth_date,
        sex_type: this.data_obj.sex_type,
        sex_type_label: this.data_obj.sex_type_label,
        postal_cd: this.data_obj.postal_cd,
        pref_cd: this.data_obj.pref_cd,
        address_1: this.data_obj.address_1,
        address_2: this.data_obj.address_2,
        tel_1: this.data_obj.tel_1,
        tel_2: this.data_obj.tel_2,
        member_assets: this.data_obj.member_assets,
        // mail_1: this.data_obj.mail_1,
        // mail_2: this.data_obj.mail_2,
        // mail_flg: this.data_obj.mail_flg,
      }
    },
    async confirm_chk() {
      this.change_mode = true
    },
    async update() {
      console.log('component methods', this.data_obj)

      const params = {
        name_1: this.form_obj.name_1,
        name_2: this.form_obj.name_2,
        yomi_1: this.form_obj.yomi_1,
        yomi_2: this.form_obj.yomi_2,
        birth_date: this.form_obj.birth_date,
        profile: [],
        member_assets: this.form_obj.member_assets,
      }
      let profiles
      let upd_profile = {
        id: 0,
        m_id: this.$store.state.auth.userId,
        postal_cd: '',
        pref_cd: '',
        address_1: '',
        address_2: '',
        tel_1: '',
        tel_2: '',
      }
      if (
        typeof this.data_obj === 'object' &&
        typeof this.data_obj.profile === 'object' &&
        Object.keys(this.data_obj.profile).length > 0
      ) {
        profiles = [...this.data_obj.profile]
        upd_profile = profiles[0]
      } else {
        profiles.push({ ...upd_profile })
      }
      if (this.form_obj.postal_cd) {
        upd_profile.postal_cd = this.form_obj.postal_cd
      }
      if (this.form_obj.pref_cd) {
        upd_profile.pref_cd = this.form_obj.pref_cd
      }
      if (this.form_obj.address_1) {
        upd_profile.address_1 = this.form_obj.address_1
      }
      if (this.form_obj.address_2) {
        upd_profile.address_2 = this.form_obj.address_2
      }
      if (this.form_obj.tel_1) {
        upd_profile.tel_1 = this.form_obj.tel_1
      }
      if (this.form_obj.tel_2) {
        upd_profile.tel_2 = this.form_obj.tel_2
      }

      profiles[0] = upd_profile

      params.profile = profiles

      await this.basicPosts_I(
        config.BASE_API_URL + '/member/edit/' + this.$store.state.auth.userId,
        params
      )
        .then(async (response) => {
          console.log('update', response)
        })
        .catch(async (err) => {
          console.log(err)
          this.data_obj = []
        })
        .finally(async () => {
          await this.loadUserObj()
          this.message = '更新しました'
          this.$forceUpdate()
        })
    },
    back_input() {
      this.change_mode = false
    },
    back() {
      this.$router.push({ path: '/' })
    },
  },
  computed: {
    ...mapState({}),
    get_assets: function () {
      const form_obj = this.form_obj
      return function (type_cd, sort_key) {
        if (
          typeof form_obj === 'object' &&
          typeof form_obj.member_assets === 'object' &&
          form_obj.member_assets.find((asset) => asset.type == type_cd) !==
          false &&
          typeof form_obj.member_assets.filter(
            (asset) => asset.type == type_cd
          ) === 'object' &&
          typeof form_obj.member_assets.filter(
            (asset) => asset.type == type_cd
          )[sort_key] === 'object'
        ) {
          return form_obj.member_assets.filter(
            (asset) => asset.type == type_cd
          )[sort_key]
        }
        return {}
      }
    },
    get_sex_label: function () {
      const sex_type_list = this.sex_type_list
      return function (key) {
        if (typeof sex_type_list.find((sex) => sex.key == key) === 'object') {
          return sex_type_list.find((sex) => sex.key == key).label
        }
        return key
      }
    },
    get_pref_label: function () {
      const pref_list = this.pref_list
      return function (key) {
        if (typeof pref_list.find((pref) => pref.key == key) === 'object') {
          return pref_list.find((pref) => pref.key == key).label
        }
        return key
      }
    },
    get_request_cd_label: function () {
      const lists = this.request_type_list
      return function (key) {
        if (typeof lists.find((list) => list.key == key) === 'object') {
          return lists.find((list) => list.key == key).label
        }
        return key
      }
    },
    sex_type_list: function () {
      return this.sex_type_obj
    },
    request_type_list: function () {
      return this.request_type_cd_obj
    },
    pref_list: function () {
      const lists = [...this.pref_obj]
      return lists.sort((a, b) => {
        if (parseInt(a.sort) < parseInt(b.sort)) {
          return -1
        } else {
          return 1
        }
      })
    },
    application_type_list: function () {
      return this.application_type_obj
    },
    application_check_type_list: function () {
      return this.application_check_type_obj
    },
    application_list: function () {
      return this.application_obj
    },
    application_filter: function () {
      const app_list = this.application_list
      return function (app_type, app_chk_list) {
        return app_list.filter((app) => {
          if (
            app.application_type == app_type &&
            app_chk_list.includes(app.application_check_type) !== false
          ) {
            return true
          }
          return false
        })
      }
    },
    get_target_application: function () {
      const member_application = this.application_list
      return function (app_type) {
        return member_application.find(
          (app) => app.application_type == app_type
        )
      }
    },
    get_target_application_btn_class: function () {
      const get_func = this.get_target_application
      const application_btn_class = this.application_btn_class
      return function (app_type) {
        const filtered = get_func(app_type)
        console.log('get_target_application_btn_class', app_type, filtered)
        return application_btn_class(
          (filtered || {}).application_check_type
            ? filtered.application_check_type
            : false
        )
      }
    },

    application_btn_class: function () {
      return function (check_type) {
        let base = 'btn btn-sm '
        base +=
          check_type == 100
            ? 'btn-light btn-outline-secondary'
            : check_type == 200
              ? 'btn-light btn-outline-secondary'
              : check_type == 500
                ? 'btn-success'
                : check_type == 600
                  ? 'btn-warning'
                  : check_type == 900
                    ? 'btn-danger'
                    : check_type == 990
                      ? 'btn-danger'
                      : 'btn-light btn-outline-secondary'
        return base
      }
    },
    application_btn_label: function () {
      const get_func = this.get_target_application
      const application_type_list = this.application_type_list
      // const application_check_type_list = this.application_check_type_list;
      return function (app_type) {
        const application_type = application_type_list.find(
          (type) => type.key == app_type
        )
        const type_label = (application_type || {}).label

        const filtered = get_func(app_type)
        let chk_label = false
        if (typeof filtered === 'object' && filtered.application_check_type) {
          chk_label =
            filtered.application_check_type == 100
              ? '申請中です'
              : filtered.application_check_type == 200
                ? '確認中です'
                : filtered.application_check_type == 500
                  ? '承認されました'
                  : filtered.application_check_type == 600
                    ? '差し戻しがあります。'
                    : filtered.application_check_type == 900
                      ? '不許可です'
                      : filtered.application_check_type == 990
                        ? '編集禁止です'
                        : '変更して下さい'
        }
        return chk_label ? chk_label : type_label
      }
    },
    shipping_address_list: function () {
      return this.shipping_address_obj.filter((shipping, index) => {
        if (typeof shipping === 'object' && index == 0) {
          return true
        }
      })
    },
    get_member_data: function () {
      return {
        name_1: this.data_obj.name_1,
        name_2: this.data_obj.name_2,
        yomi_1: this.data_obj.yomi_1,
        yomi_2: this.data_obj.yomi_2,
        birth_date: this.data_obj.birth_date,
        postal_cd: this.data_obj.birth_date,
        pref_cd: this.data_obj.birth_date,
        address_1: this.data_obj.address_1,
        address_2: this.data_obj.address_2,
        tel_1: this.data_obj.tel_1,
        tel_2: this.data_obj.tel_2,
        mail_1: this.data_obj.mail_1,
        mail_2: this.data_obj.mail_2,
        mail_flg: this.data_obj.mail_flg,
      }
    },
    input_class: function () {
      if (this.change_mode) {
        return 'form-control-plaintext'
      } else {
        return 'form-control'
      }
    },
    input_readonly: function () {
      if (this.change_mode) {
        return true
      } else {
        return false
      }
    },
  },
  async created() {
    await this.loadConfigObj('request_type_cd')
    await this.loadConfigObj('sex_type')
    await this.loadConfigObj('application_type')
    await this.loadConfigObj('application_check_type')
    await this.loadShippingObj()
    await this.loadPrefList()
    await this.loadUserObj()
    await this.loadApplicationObj()
    await this.setUserObj()
  },
  mounted: function () {
    // console.log('tags_list', this.tags_list);
  },
  props: {},
}
</script>

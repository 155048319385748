<template>
    <footer class="footer" id="footer">
      <div class="footer__copy">
        <div class="container">
          <p>©2020 Motorcycle Federation of Japan All RightsReserved.</p>
        </div>
      </div>
    </footer>
</template>

<script>
  // import Vue from 'vue'

  import { mapState } from 'vuex'
  export default {
    name: 'BsFooterView',
    data: function () {
      return {
        disp_note: false,
        disp_jancode: false,
        //   count: this.$store.state.count
      }
    },
    props: {
      front_view: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState({}),
      footer_class() {
        if (this.front_view) {
          return 'not-footer bg-dark'
        }
        return 'footer bg-dark'
      },
    },
    components: {},
    methods: {},
  }
</script>

<template>
  <div class="theme-owner">
    <div class="d-flex flex-column min-vh-100">
      <header class="header" id="header">
        <div class="container">
          <div class="header__inner">
            <h1 class="header__logo">
              <a class="header__logoLink" href="/mfj_renewal/app/">
                <img
                  :src="require('@/assets/img/header_img_01.png')"
                  alt="Motorcycle Federation of Japan"
              /></a>
            </h1>
          </div>
        </div>
      </header>
      <main
        id="main"
        class="flex-grow-1 d-flex align-items-center justify-content-center py-5"
      >
        <BsLoginForm v-bind:onlogin="handleLogin"></BsLoginForm>
      </main>
      <footer class="footer" id="footer">
        <div class="footer__login">
          <div class="container">
            <ul class="footer__loginUl">
              <li class="footer__loginLi">
                <router-link to="/login" class="text-decoration-none"
                  >会員ログイン</router-link
                >
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="mx-2"
                />
              </li>
              <li class="footer__loginLi">
                <router-link to="/ologin" class="text-decoration-none"
                  >主催者ログイン</router-link
                >
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="mx-2"
                />
              </li>
              <li class="footer__loginLi" v-if="mfj_phase_club_001">
                <router-link to="/clogin" class="text-decoration-none"
                  >公認クラブログイン</router-link
                >
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="mx-2"
                />
              </li>
            </ul>
          </div>
        </div>
        <div class="footer__copy">
          <div class="container">
            <p>©2020 Motorcycle Federation of Japan All RightsReserved.</p>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
  import BsLoginForm from '../molecules/BsOwnerLoginForm.vue'

  export default {
    name: 'BsOwnerLoginView',
    data: function () {
      return {
        login_id: '',
        login_pass: '',
        error: false,
      }
    },
    computed: {
      disableLoginAction() {
        // validを使ってログイン処理の可否、progressは後述
        return false
      },
    },
    components: {
      BsLoginForm,
    },
    methods: {
      handleLogin(authInfo) {
        console.log('handleLogin owner')
        let result = false
        return this.$store
          .dispatch('ologin', authInfo)
          .then(async (response) => {
            console.log('login success', response)
            // this.$router.push({ path: '/' })
            if (response && parseInt(response.userId) > 0) {
              result = true
            }
          })
          .catch((err) => {
            console.log('handleLogin owner err', err)
            this.throwReject(err)
          })
          .finally(async () => {
            if (result) {
              console.log('handleLogin owner finally login ')
              this.$router.push({ path: '/owner/mypage' })
            } else {
              console.log('handleLogin owner finally logout')
              this.$router.push({ path: '/ologin' })
            }
          })
      },
      throwReject(err) {
        return Promise.reject(err)
      },
    },
    created() {
      console.log('created template route name', this.$route.name)
    },
  }
</script>

<style>
  @import '../../assets/css/style-color02.css';
  .header,
  .footer {
    flex-shrink: 0;
  }

  #main {
    overflow-y: auto;
    width: 100%;
  }

  .login__title {
    margin-bottom: 2rem;
  }

  .login__box {
    margin-top: 2rem;
  }
</style>

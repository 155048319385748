<template>
  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1">
          <div class="container mb-2 py-3">
            <h2 class="title page__h1">Web講習受講</h2>
          </div>
          <div class="container mb-2 py-3">
            <h5></h5>
          </div>
          <div class="box box--center mb-24">
            <template v-if="page_mode === 'test_fault'">
              <p class="message">テスト結果：不合格</p>
              <p class="message">ライセンスを申請するにはテストに合格していただく必要があります。</p>
              <p class="message">お手数ですが、再度テストにチャレンジしてください。</p>
            </template>
            <template v-else>
              <p class="message">{{ message }}</p>
            </template>
          </div>
          <!-- {{ page_mode }} -->
          <div v-if="movie_mode">
            <div class="container">
              <section id="firstContents" class="video-sect">
                <div class="video-area">
                  <div class="row mb-3">
                    <video ref="video" class="video" control
                      :src="`${BASE_ASSETS_URL}/seminar/${target_license.movie_no}.mp4`" @ended="handleVideoEnd('test')"
                      @contextmenu.prevent>
                    </video>
                  </div>
                  <button class="video-area__button play" id="playBtn" @click="togglePlayPause">
                    <img :src="`${BASE_ASSETS_URL}/seminar/play.svg`" alt="PLAY" style="width: 80px; height: 80px;">
                  </button>
                  <button class="video-area__button pause hide" id="pauseBtn" @click="togglePlayPause">
                    <img :src="`${BASE_ASSETS_URL}/seminar/pause.svg`" alt="PAUSE" style="width: 80px; height: 80px;">
                  </button>
                  <div class="video-time"><span id="currentTime">00:00</span>/<span id="totalTime">00:00</span></div>
                  <button class="size-change" id="sizeChange" @click="toggleFullScreen"><span></span></button>
                  <input type="range" id="seekBar" class="seek-bar" min="0" max="100" value="0">
                </div>
                <p>再生位置の移動（進み・戻し）は、一度視聴した範囲内でのみご利用いただけます。</p>
              </section>
              <!-- <div class="row">
                              <div class="col">
                                  <div class="d-grid">
                                      <button 
                                      @click="go_page_mode('movie_end')" 
                                      class="btn btn-light btn-outline-secondary"
                                      >(test)視聴が終わったと仮定して進むボタン</button>
                                  </div>
                              </div>
                          </div> -->
            </div>
          </div>
          <!-- <div v-if="movie_end_mode">
                      <div class="container">
                          <div class="row">
                              <div class="col-3 offset-3">
                                  <div class="d-grid">
                                      <button @click="go_page_mode('movie')" class="btn btn-light btn-outline-secondary">もう一度見る</button>
                                  </div>
                              </div>
                              <div class="col-3">
                                  <div class="d-grid">
                                      <button @click="go_page_mode('test')" class="btn btn-light btn-outline-secondary">テストにすすむ</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div> -->
          <div v-if="test_mode">
            <div class="container" v-if="(target_license || {}).license_test.length > 0">
              <div 
                class="online-test"
                :class="{ checked: license_test.answered }"
                v-for="(license_test, index) in get_license_test_random" 
                :key="license_test"
              >
                <div class="question-number">
                  {{ index + 1 }}/{{ Number(target_license.qestions_num_0) + Number(target_license.qestions_num_1) }}
                </div>
                <div class="question-text">
                  <p>{{ license_test.label }}</p>
                </div>
                <div class="options-container">
                  <div class="option" v-for="(option, optionIndex) in license_test.options" :key="option.id">
                    <template v-if="Number(license_test.answer_type) === 0">
                      <input 
                        class="radio__input" 
                        type="radio" 
                        :id="`choice${index + 1}_${optionIndex}`" 
                        :name="`choice${index + 1}`" 
                        :value="option.id" 
                        @change="test_answer(option.id, license_test.id, license_test.answer_type)"
                      >
                    </template>
                    <template v-else>
                      <input
                        class="radio__input" 
                        type="checkbox" 
                        :id="`choice${index + 1}_${optionIndex}`" 
                        :name="`choice${index + 1}`" 
                        :value="option.id" 
                        @change="test_answer(option.id, license_test.id, license_test.answer_type)"
                      >
                    </template>
                    <label :for="`choice${index + 1}_${optionIndex}`">
                      {{ option.label }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="submit-area">
                <p class="submit-area__rest">
                  解答率
                  <span class="submit-area__rest--detail">
                    <span id="checkedResult">{{ test_answer_cnt }}</span>
                    /{{ Number(target_license.qestions_num_0) + Number(target_license.qestions_num_1) }}
                  </span>
                </p>
                <button 
                  type="submit" 
                  class="button04" 
                  :class="{ disabled: !allQuestionsAnswered }" 
                  id="submit" 
                  name="my_submit" 
                  value="answer_check"
                  @click="submitAnswers"
                >
                  解答を提出する
                </button>
                <div 
                  id="playAgain" 
                  class="video-button"
                  @click="showVideo"
                >
                  講習動画を再視聴
                </div>
                <div 
                  id="videoWrap" 
                  class="detail-wrap"
                  :class="{ open: isVideoVisible }"
                >
                  <div 
                    class="detail-wrap__close closeDetail" 
                    id="closePause"
                    @click="hideVideo"
                  >×</div>
                  <video 
                    :src="`${BASE_ASSETS_URL}/seminar/${target_license.movie_no}.mp4`" 
                    id="popupVideo"
                    class="popup-video" 
                    playsinline="" 
                    controls="" 
                    controlslist="nodownload"
                  ></video>
                </div>
              </div>
            </div>
            <div class="container" v-else>
              <div class="submit-area">
                <p class="submit-area__rest">解答率<span class="submit-area__rest--detail"><span
                      id="checkedResult">0</span>/10</span></p>
                <button class="btn btn-light btn-outline-secondary w-30">解答を提出する</button>
                <button @click="showVideo" class="btn btn-light btn-outline-secondary w-30">講習動画を再視聴</button>
                <div id="videoWrap" class="detail-wrap" :class="{ open: isVideoVisible }">
                  <div class="detail-wrap__close closeDetail" id="closePause" @click="hideVideo">×</div>
                  <video :src="`${BASE_ASSETS_URL}/seminar/${target_license.movie_no}.mp4`" id="popupVideo"
                    class="popup-video" playsinline="" controls="" controlslist="nodownload"></video>
                </div>
              </div>
            </div>
          </div>
          <div v-if="test_fault_mode">
            <div class="text-center mb-24">
              <p class="fs-1 fw-bold">
                正解数: 
                <span id="checkedResult">{{ test_correct_cnt }}</span>
                 / {{ Number(target_license.qestions_num_0) + Number(target_license.qestions_num_1) }}
                </p>
            </div>
            <div class="container">
              <div class="d-flex mt-32 justify-content-center column-gap-24">
                <button 
                  @click="go_page_mode('movie')" 
                  class="button03">もう一度見る
                </button>
                <button 
                  @click="go_replay_test" 
                  class="button04">再度テストを行う
                </button>
              </div>
            </div>
          </div>
          <div v-if="finish_mode">
            <div class="container">
              <div class="row">
                <div class="col-4 offset-4">
                  <div class="d-grid">
                    <button @click="go_home" class="btn btn-light btn-outline-secondary">戻る</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.online-test {
  box-shadow: 2px 2px 4px rgba(158, 158, 158, 0.6);
  margin: 42px 0 0;
  padding: 28px 20px 30px;
  border: 5px solid #fff;
  transition: .15s .5s;
}

.online-test.checked {
  border: 5px solid #CB000B;
  box-shadow: none;
}

.test-container {
  border: 2px solid #CB000B;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
}

.question-number {
  color: #CB000B;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.question-text {
  font-size: 18px;
  margin-bottom: 30px;
}

.options-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.option input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #999;
  border-radius: 50%;
  margin: 0;
  position: relative;
}

.option input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #999;
  border-radius: 50%;
  margin: 0;
  position: relative;
}

.option input[type="radio"]:checked {
  border-color: #CB000B;
}

.option input[type="checkbox"]:checked {
  border-color: #CB000B;
}

.radio__input:checked:before {
  display: none;
}

.option input[type="radio"]:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-48%, -53%);
  width: 12px;
  height: 12px;
  background-color: #CB000B;
  border-radius: 50%;
}

.option input[type="checkbox"]:checked:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-48%, -53%);
  width: 12px;
  height: 12px;
  background-color: #CB000B;
  border-radius: 50%;
}

.option label {
  font-size: 16px;
  cursor: pointer;
}

.input-group>.form-control-plaintext {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  padding: 0.375rem;
  border-bottom: solid 1px #999;
}

.form-control-plaintext {
  border-bottom: solid 1px #999;
}

.video-sect.full {
  max-width: 100%;
  width: 100%;
  position: fixed;
  background: #000;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
  margin: 0;
  overflow: auto;
  color: #fff;
  z-index: 9999;
}

.video-sect p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.video-area {
  position: relative;
  max-width: 960px;
  margin: 0 auto;
}

.video-area__button.hide {
  display: none;
}

.video-area__button.pause {
  opacity: 0;
  transition: .3s;
}

.video-area__button:hover {
  opacity: 1;
  opacity: .5;
  /* ホバー時に不透明度を100%にする */
}

.video-area__button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .5;
}

.video-time {
  color: #fff;
  position: absolute;
  bottom: 15px;
  left: 10px;
}

.size-change {
  position: absolute;
  bottom: 15px;
  right: 10px;
  width: 30px;
  height: 30px;
  color: #fff;
  background: transparent;
  border: none;
}

.size-change:before,
.size-change:after {
  top: 0;
  border-bottom: none;
}

.size-change:before,
.size-change:after,
.size-change span:before,
.size-change span:after {
  content: "";
  display: block;
  position: absolute;
  width: 30%;
  height: 30%;
  border: 2px solid;
  transition: .6s;
}

.size-change:before,
.size-change span:before {
  left: 0;
  border-right: none;
}

.size-change span:before,
.size-change span:after {
  bottom: 0;
  border-top: none;
}

.size-change:after,
.size-change span:after {
  right: 0;
  border-left: none;
}

.size-change span:before,
.size-change span:after {
  bottom: 0;
  border-top: none;
}

.size-change:before,
.size-change:after {
  top: 0;
  border-bottom: none;
}

.size-change.return:before,
.size-change.return:after,
.size-change.return span:before,
.size-change.return span:after {
  transform: rotate(180deg);
}

.seek-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  height: 8px;
  width: 100%;
  background: #000;
  border-radius: 0px;
  border: none;
  overflow: hidden;
}

/* 進行中の部分のスタイル */
.seek-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  /* サムのサイズ */
  height: 15px;
  /* サムのサイズ */
  background: #CB000B;
  /* サムの色 */
}

.seek-bar::-moz-range-thumb {
  width: 15px;
  /* サムのサイズ */
  height: 15px;
  /* サムのサイズ */
  background: #CB000B;
  /* サムの色 */
}

/* 進行中の部分のスタイル */
.seek-bar::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #CB000B 0%, #CB000B var(--progress), #000 var(--progress), #000 100%);
}

.seek-bar::-moz-range-track {
  background: linear-gradient(to right, #CB000B 0%, #CB000B var(--progress), #000 var(--progress), #000 100%);
}

/* テストモード再視聴 */
.search-wrap,
.detail-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-wrap.open {
  z-index: 2;
  visibility: visible;
}

.search-wrap__close,
.detail-wrap__close {
  text-align: right;
  position: fixed;
  top: 0;
  left: 0;
  border: none;
  display: block;
  width: 100%;
  height: 0;
  padding: 0 0 100vh;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 40px;
  z-index: 1;
}

.popup-video {
  z-index: 10;
  max-width: 100%;
  width: 950px;
  padding: 0;
}

.submit-area {
  font-size: 16px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 60px;
  left: 0;
  padding: .5em 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  gap: .4em;
}

/* レスポンシブ */
@media (min-width: 768px) {
  .submit-area {
    gap: 20px;
    width: calc(100% + 20px);
    margin: 0 -10px;
  }
}

.submit-area {
  position: sticky;
  bottom: 0;
  padding: 15px;
  margin-top: 30px;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
  z-index: 100;
}

.submit-area__rest {
  margin: 0;
  width: calc(20% - 1.2em / 3);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 992px) {
  .submit-area__rest {
    width: 100%;
  }
}

.submit-area__rest--detail {
  margin-left: 8px;
  font-size: 24px;
  color: #CB000B;
}

.detail-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.detail-wrap.open {
  display: flex;
}

.detail-wrap__close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.popup-video {
  max-width: 90%;
  max-height: 80vh;
}

.video-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 48px;
  color: var(--color03);
  background: var(--color02);
  border: 1px var(--color03) solid;
  border-radius: 9999px;
  font-weight: bold;
  font-family: inherit;
  font-size: 16px;
  line-height: 1.7em;
  text-decoration: none;
  cursor: pointer;
}

/* ホバー効果を追加する場合 */
.video-button:hover {
  color: var(--color02);
  background: var(--color03);
  transition: all 0.3s ease;
}
</style>

<script>
import { mapState } from 'vuex'
// import BsPageTitle from '../../molecules/BsPageTitle.vue'
// import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
// import BsSelect from '../../atoms/BsSelect.vue'
// import BsCheckBox from '../../atoms/BsCheckBox.vue'
// import '../../assets/bootstrap/css/sc.css';
// import BsSelect from '../../atoms/BsSelect.vue'
import moment from "moment"
import config from '../../../const'

export default {
  name: 'UserDataView',
  data: function () {
    return {
      targetid: this.$store.state.auth.userId,
      btnLabel: '',
      data_obj: {},
      member_license_obj: [],
      target_member_license_obj: {},
      target_license_obj: {},
      member_license_id: 0,
      license_cd_obj: [],
      license_test_step: 0,
      test_correct_cnt: 0,
      test_answer_cnt: 0,
      license_test_random_obj: [],
      page_mode: 'movie',
      change_mode: true,
      message: 'ライセンス取得のためのWeb動画講習です。',
      totalTimeSet: false,
      lastWatchedTime: 0,
      isVideoVisible: false,
      BASE_ASSETS_URL: config.BASE_ASSETS_URL,
    }
  },
  components: {
    // BsSelect,
    //   BsMsgAlert,
    // BsCheckBox,
  },
  methods: {
    async handleVideoEnd(mode) {
      this.page_mode = mode;
      this.lastWatchedTime = 0;
      this.license_test_step = 0;
      await this.shuffle_test();
    },
    togglePlayPause() {
      const video = this.$refs.video;
      const playBtn = document.getElementById('playBtn');
      const pauseBtn = document.getElementById('pauseBtn');

      if (video.paused) {
        video.play();
        playBtn.classList.add('hide');
        pauseBtn.classList.remove('hide');
      } else {
        video.pause();
        playBtn.classList.remove('hide');
        pauseBtn.classList.add('hide');
      }
    },
    toggleFullScreen() {
      const videoSect = document.getElementById('firstContents');
      const sizeChangeButton = document.getElementById('sizeChange');

      // フルスクリーンのトグル
      videoSect.classList.toggle('full');
      sizeChangeButton.classList.toggle('return');
    },
    updateTime() {
      const video = this.$refs.video;
      const currentTimeElement = document.getElementById('currentTime');
      const seekBar = document.getElementById('seekBar');

      // 現在の時間を更新
      const currentMinutes = Math.floor(video.currentTime / 60);
      const currentSeconds = Math.floor(video.currentTime % 60);
      currentTimeElement.textContent = `${String(currentMinutes).padStart(2, '0')}:${String(currentSeconds).padStart(2, '0')}`;

      // シークバーの値を更新
      if (video.duration > 0 && !isNaN(video.duration)) {
        const progress = (video.currentTime / video.duration) * 100;
        seekBar.style.setProperty('--progress', `${progress}%`);
        seekBar.value = progress;
      }

      // 最後に視聴した時間を更新
      this.lastWatchedTime = Math.max(this.lastWatchedTime, video.currentTime);
    },
    setTotalTime() {
      const video = this.$refs.video;
      const totalTimeElement = document.getElementById('totalTime');

      const totalMinutes = Math.floor(video.duration / 60);
      const totalSeconds = Math.floor(video.duration % 60);
      totalTimeElement.textContent = `${String(totalMinutes).padStart(2, '0')}:${String(totalSeconds).padStart(2, '0')}`;

      // シークバーのmaxを設定
      const seekBar = document.getElementById('seekBar');
      seekBar.max = 100; // シークバーの最大値を100に設定

    },
    seekVideo() {
      const video = this.$refs.video;
      const seekBar = document.getElementById('seekBar');

      // シークバーの値を元に新しい再生位置を計算
      const newTime = (seekBar.value / 100) * video.duration;

      // 最後に視聴した位置より先に進めないよう制限
      if (newTime > this.lastWatchedTime) {
        seekBar.value = (this.lastWatchedTime / video.duration) * 100;
        video.currentTime = this.lastWatchedTime;
        return;
      }

      // 動画の再生位置を更新
      video.currentTime = newTime;

      // シークバーの進行状況を更新
      const progress = (video.currentTime / video.duration) * 100;
      seekBar.style.setProperty('--progress', `${progress}%`);
    },
    showVideo() {
      this.isVideoVisible = true;
    },
    hideVideo() {
      this.isVideoVisible = false;
    },
    async test_answer(option_id, test_id, answer_type) {
      const test = this.get_license_test_random.find(test => test.id === test_id);
      
      if (!test) return;

      // 単一選択の場合
      if (answer_type === "0") {
        // 以前の選択肢を解除し、新しい選択肢を追加
        test.selectedOptions = [option_id];
        test.answered = true;
      }
      // 複数選択の場合
      else if (answer_type === "1") {
        const index = test.selectedOptions.indexOf(option_id);
        if (index === -1) {
          test.selectedOptions.push(option_id); // 新しい選択肢を追加
        } else {
          test.selectedOptions.splice(index, 1); // 既存の選択肢を削除
        }
        
        test.answered = test.selectedOptions.length > 0; // 回答済みフラグを更新
      }
      
      // 回答数を更新
      this.updateAnsweredCount();
    },
    updateAnsweredCount() {
      // 回答した問題の数をカウント
      this.test_answer_cnt = this.get_license_test_random.filter(test => test.answered).length;

      // 正解数をリセット
      this.test_correct_cnt = 0;

      // 各問題の正解判定
      this.get_license_test_random.forEach(test => {
        const correctOptions = test.options.filter(option => option.correct === "1").map(option => option.id);
        const selectedOptions = test.selectedOptions || [];

        // 正解の選択肢がすべて選ばれているか確認
        const allCorrectSelected = correctOptions.every(optionId => selectedOptions.includes(optionId));
        
        // 不正解の選択肢が含まれていないか確認
        const hasIncorrectSelected = selectedOptions.some(optionId => {
          const option = test.options.find(opt => opt.id === optionId);
          return option ? option.correct !== "1" : false;
        });

        // すべての正解が選ばれ、かつ不正解が含まれていない場合に正解とする
        if (allCorrectSelected && !hasIncorrectSelected) {
          this.test_correct_cnt++;
        }
      });
    },
    // 提出ボタンクリック時の処理
    async submitAnswers() {
      // 全問回答されていない場合は処理を中断
      if (!this.allQuestionsAnswered) {
        return;
      }
      // 合格基準を満たしているかチェック
      if (this.test_correct_cnt >= this.target_license.qestions_pass_num) {
        await this.finish_seminar();
      } else {
        // 不合格の場合、選択をリセット
        this.get_license_test_random.forEach(test => {
          test.answered = false;
          test.selectedOptions = [];  // 選択をリセット
        });
        
        // テスト不合格モードに移行
        await this.go_page_mode('test_fault');
      }
    },
    async finish_seminar() {
      const params = { ...this.data_obj }
      const mIndex = params.member_license.findIndex(license => license.id == this.member_license_id)
      params.member_license[mIndex].fixed = 1
      const start = moment().add(1, 'd').format('YYYY-MM-DD')
      params.member_license[mIndex].license_term_start_date = start
      if(params.member_license[mIndex].license_cd){
        params.member_license.map(license => {
          if(
            license.license_cd == params.member_license[mIndex].license_cd
          ){
            license.fixed = 1
          }
          return license
        })
      }

      // 保険内容チェック
      const iIndex = Array.isArray(params.insurance)? params.insurance.findIndex(insu => insu.sports_year == params.member_license[mIndex]?.license_year): false;
      if(
        iIndex !== false && 
        params?.insurance[iIndex] && 
        params.insurance[iIndex]?.fixed && 
        params.insurance[iIndex].fixed == 0
      ){
        params.insurance[iIndex].fixed = 1
        params.insurance[iIndex].start_date = start
      }

      await this.basicPosts_I(
        config.BASE_API_URL + '/member/edit/' + this.$store.state.auth.userId,
        params
      )
        .then(
          async (response) => {
            console.log('update', response);
          }
        )
        .catch(
          async (err) => {
            console.log(err);
            this.data_obj = {};
          })
        .finally(async () => {
          await this.loadUserObj();
          await this.go_page_mode('finish');
          this.message = '動画視聴・テスト合格し、ライセンスが有効になりました。';
          this.$forceUpdate();
        });
    },
    async go_page_mode(mode) {
      this.page_mode = mode;

      if (mode === 'movie') {
        await this.$nextTick();
        const video = this.$refs.video;

        if (video) {
          video.currentTime = 0;

          // 動画を再読み込み
          await video.load();

          // メタデータが読み込まれた後に総時間を設定
          video.addEventListener('loadedmetadata', () => {
            this.setTotalTime();
          }, { once: true });

          // timeupdateイベントを設定
          video.addEventListener('timeupdate', this.updateTime);
          const seekBar = document.getElementById('seekBar');
          if (seekBar) {
            seekBar.addEventListener('input', this.seekVideo);
          }
        }
      }
    },
    async go_test() {
      this.page_mode = 'movie_end';
    },
    async go_replay_test() {
      this.test_correct_cnt = 0;  // カウントをリセット
      this.test_answer_cnt = 0;  // カウントをリセット
      this.get_license_test_random.forEach(test => {
        test.answered = false;
      });
      this.license_test_step = 0;
      await this.shuffle_test();
      this.page_mode = 'test';
    },
    async go_home() {
      this.$router.push({ path: '/' });
    },
    async loadConfigObj(param) {
      const params = {};
      await this.basicPosts_S(
        config.BASE_API_URL + '/config/' + param,
        params
      )
        .then(
          async (response) => {
            console.log('loadConfigObj response', response);
            this[param + '_obj'] = (response.data && response.data.body) ? response.data.body : [];
          }
        )
        .catch(
          async (err) => {
            console.log(err);
            this[param + '_obj'] = [];
          })
        .finally(async () => {
          this.$forceUpdate();
        });
    },
    async loadLicenseCdList() {
      const params = {};
      params['stat'] = [1];
      await this.nonloginPosts_S(
        config.BASE_API_URL + '/license_cd/lists',
        params
      )
        .then(
          async (response) => {
            console.log('loadLicenseCdList response', response);
            this.license_cd_obj = (response.data && response.data.body) ? response.data.body : [];
          }
        )
        .catch(
          async (err) => {
            console.log(err);
            this.license_cd_obj = [];
          })
        .finally(async () => {
          this.$forceUpdate();
        });
    },
    async loadUserObj() {
      const params = {};
      params['stat'] = [1];
      params['id'] = this.$store.state.auth.userId;
      await this.basicPosts_S(
        config.BASE_API_URL + '/member/lists',
        params
      )
        .then(
          async (response) => {
            console.log('loadUserObj', response);
            this.data_obj = (response.data && response.data.body && response.data.body[0]) ? response.data.body[0] : {};
          }
        )
        .catch(
          async (err) => {
            console.log(err);
            this.data_obj = [];
          })
        .finally(async () => {
          await this.setUserObj();
          this.$forceUpdate();
        });
    },
    async setUserObj() {
      this.member_license_obj = this.data_obj.member_license;
      // tokenのmember_license_idを取得
      this.target_member_license_obj = (typeof this.member_license_obj === 'object') ? this.member_license_obj.find(ml => ml.id == this.member_license_id) : false;
      this.target_license_obj = (
        typeof this.target_member_license_obj === 'object'
        && this.target_member_license_obj.license_cd > 0
        && typeof this.license_cd_obj === 'object'
      ) ? this.license_cd_obj.find(license_cd => license_cd.id == this.target_member_license_obj.license_cd)
        : false;
      this.shuffle_test();
      if(
        (this.target_member_license_obj?.movie_viewed == 1 && this.target_member_license_obj?.fixed == 0)
        || 
        this.$route.query?.skip == 1
      ){
        this.page_mode = 'test'
      }
    },
    async confirm_chk() {
      // if(
      //     this.change_login_id == '' || this.confirm_login_id == ''
      // ){
      //     alert('変更するログインIDを入力して下さい');
      //     return true;
      // }
      // else if(
      //     this.change_login_id != '' && this.confirm_login_id != ''
      //     && this.change_login_id != this.confirm_login_id
      // ){
      //     alert('入力されたログインIDが一致しません');
      //     return true;

      // }
      this.change_mode = true;

    },
    back_input() {
      this.change_mode = false;
    },
    back() {
      this.$router.push({ path: '/' });
    },
    shuffleArray(array) {
      // if (!Array.isArray(array) || array.length === 0) {
      //     console.error("無効な配列です");
      //     return array;
      // }
      // // const array = [...ary]

      // for (let i = array.length - 1; i > 0; i--) {
      //     const j = Math.floor(Math.random() * (i + 1));
      //     if (typeof array[i] !== typeof array[j]) {
      //         console.error("異なる型の要素があります");
      //         continue;
      //     }
      //     [array[i], array[j]] = [array[j], array[i]];
      // }
      // return array;
      for (let i = (array.length - 1); 0 < i; i--) {
        // 0〜(i+1)の範囲で値を取得
        let r = Math.floor(Math.random() * (i + 1));

        // 要素の並び替えを実行
        let tmp = array[i];
        array[i] = array[r];
        array[r] = tmp;
      }
      return array;
    },
    async shuffle_test() {
      if (!this.target_license?.license_test) return;

      // 基礎問題と応用問題を分離
      const basicQuestions = this.target_license.license_test.filter(test => test.test_type === "0");
      const advancedQuestions = this.target_license.license_test.filter(test => test.test_type === "1");

      // 指定された問題数を使用
      const basicCount = this.target_license.qestions_num_0;  // 基礎問題数
      const advancedCount = this.target_license.qestions_num_1;  // 応用問題数

      // 基礎問題と応用問題をランダムに選択
      const selectedBasic = this.shuffleArray(basicQuestions).slice(0, basicCount);
      const selectedAdvanced = this.shuffleArray(advancedQuestions).slice(0, advancedCount);

      // 選択した問題を結合し、シャッフル
      const selectedQuestions = this.shuffleArray([...selectedBasic, ...selectedAdvanced]);

      // 各問題の選択肢をシャッフルし、状態を初期化
      const tmps = selectedQuestions.map(test => ({
        ...test,
        options: this.shuffleArray([...(test.options || [])]),
        selectedOptions: [],
        answered: false
      }));
      
      this.license_test_random_obj = tmps;
    },
  },
  computed: {
    ...mapState({
    }),
    allQuestionsAnswered() {
      return this.get_license_test_random.every(test => test.answered) || false;
    },
    member_license: function () {
      if (
        typeof this.member_license_obj === 'object'
      ) {
        return this.member_license_obj
      }
      return {}
    },
    target_member_license: function () {
      if (
        typeof this.target_member_license_obj === 'object'
      ) {
        return this.target_member_license_obj
      }
      return {}
    },
    target_license: function () {
      if (typeof this.target_license_obj === 'object') {
        return this.target_license_obj;
      }
      return {};
    },
    get_license_test_random: function () {
      return this.license_test_random_obj;
    },
    get_license: function () {
      const license_list = this.license_cd_obj;
      return function (license_cd) {
        return license_list.find(license => license.id = license_cd);
      }
    },
    get_label_list: function () {
      return function (code, lists) {
        if (
          typeof lists === 'object'
          && lists.find(list => list.key == code)
        ) {
          return (lists.find(list => list.key == code) || {}).label
        }
        return code;
      }
    },

    get_member_data: function () {
      return {
        name_1: this.data_obj.name_1,
        name_2: this.data_obj.name_2,
        yomi_1: this.data_obj.yomi_1,
        yomi_2: this.data_obj.yomi_2,
        birth_date: this.data_obj.birth_date,
        postal_cd: this.data_obj.birth_date,
        pref_cd: this.data_obj.birth_date,
        address_1: this.data_obj.address_1,
        address_2: this.data_obj.address_2,
        tel_1: this.data_obj.tel_1,
        tel_2: this.data_obj.tel_2,
        mail_1: this.data_obj.mail_1,
        mail_2: this.data_obj.mail_2,
        mail_flg: this.data_obj.mail_flg,
      }
    },

    movie_mode: function () {
      return (this.page_mode == 'movie') ? true : false;
    },
    movie_end_mode: function () {
      return (this.page_mode == 'movie_end') ? true : false;
    },
    test_mode: function () {
      return (this.page_mode == 'test') ? true : false;
    },
    test_fault_mode: function () {
      return (this.page_mode == 'test_fault') ? true : false;
    },
    finish_mode: function () {
      return (this.page_mode == 'finish') ? true : false;
    },

    input_class: function () {
      if (
        this.change_mode
      ) {
        return 'form-control-plaintext';
      }
      else {
        return 'form-control';
      }
    },
    input_readonly: function () {
      if (
        this.change_mode
      ) {
        return true;
      }
      else {
        return false;
      }
    },
  },
  async created() {
    if (
      this.$route.query.tkn
    ) {
      this.member_license_id = this.$route.query.tkn;
    }
    else {
      this.$router.push({
        path: '/',
      });
    }
    await this.loadLicenseCdList();
    await this.loadUserObj();
    await this.setUserObj();

  },
  mounted() {
    const video = this.$refs.video;

    // イベントリスナーを設定
    video.addEventListener('timeupdate', this.updateTime);
    video.addEventListener('loadedmetadata', this.setTotalTime);
    video.addEventListener('ended', () => {
      this.handleVideoEnd('test');
    });

    const seekBar = document.getElementById('seekBar');
    if (seekBar) {
      seekBar.addEventListener('input', this.seekVideo);
    }
  },
  props: {
  }
}
</script>
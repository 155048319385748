<template>
  <div id="home" @click="closeMenuOnOutsideClick" class="bs-template-user-view">
    <BsHeaderView @toggle="menuToggle" :attachLogo="goHome" :isMenuOpen="menuDisplay"></BsHeaderView>
    <div class="container-fluid" style="min-height: 100%; padding-bottom: 80px; padding-top: 100px">
      <div>
        <main>
          <router-view></router-view>
        </main>
        <nav :class="['navi', {'navi--isOpen': menuDisplay}]" id="navi" @click.stop>
          <ul class="navi__ul">
            <li class="navi__li" v-for="menu in menu_list" :key="menu.label">
              <router-link class="navi__a" :to="menu.path" @click="closeMenu">
              {{ menu.label }}
              <font-awesome-icon
                :icon="['fas', 'chevron-right']"
                class="mx-2"
              ></font-awesome-icon>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <BsFooterView></BsFooterView>
  </div>
</template>
<script>
  // import Vue from 'vue'
  import BsHeaderView from '../organisms/BsHeaderView.vue'
  import BsFooterView from '../organisms/BsFooterView.vue'
  // import BsSideMenuView from '../organisms/BsSideMenuView.vue'
  import { mapState } from 'vuex'
  import config from '../../const'
  // import axios from 'axios'
  export default {
    name: 'templateClubView',
    data: function () {
      return {
        menuDisplay: false,
      }
    },
    computed: {
      ...mapState({}),
      menu_class() {
        return function (menu) {
          const mb = 'mb-1'
          if ((menu || {}).style_class) {
            return mb + ' ' + (menu || {}).style_class
          }
          return mb + ' bg_mfj_orange'
        }
      },
      menu_list() {
        return config.HEAD_MENU_CLUB_LIST.filter(
          (menu) => menu.menu_sort !== false
        ).sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },
      mainDisplayClass() {
        if (this.menuDisplay) {
          return 'col-md-12 ms-sm-auto col-lg-12 px-md-4'
        } else {
          return 'col-md-12 ms-sm-auto col-lg-12 px-md-4'
        }
      },
    },
    components: {
      BsHeaderView,
      BsFooterView,
      // BsSideMenuView,
    },
    created() {},
    methods: {
      menuToggle() {
        this.menuDisplay = !this.menuDisplay
        this.$emit('toggle'); 
      },
      closeMenu() {
        this.menuDisplay = false
      },
      closeMenuOnOutsideClick(event) {
        if (
          this.menuDisplay &&
          !event.target.closest('.side-menu') &&
          !event.target.closest('.naviButton')
        ) {
          this.closeMenu()
        }
      },
    },
  }
</script>

<style scoped>
  @import '../../assets/bootstrap/css/dashboard.css';
  @import '../../assets/css/style-color03.css';
</style>

<template>
  <form class="mb-4" @submit.prevent="handleFormSubmit">
    <!-- ローディング表示 -->
    <div class="loading-overlay" v-if="isLoading">
      <div class="spinner-border text-danger" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    
    <div class="row g-12">
      <div class="col-md-12">
        <label for="konbiniCode" class="form-label">コンビニコード</label>
        <!-- <input
          id="konbiniCode"
          type="text"
          v-model="code"
          placeholder="LAWSON"
          required
          class="form-control"
          :class="{ 'is-invalid': errors.code }"
        /> -->
        <select
          id="konbiniCode"
          v-model="code"
          required
          class="form-control"
          :class="{ 'is-invalid': errors.code }"
        >
          <option disabled value="">選択してください</option>
          <option value="SEVEN_ELEVEN">セブンイレブン</option>
          <option value="LAWSON">ローソン</option>
          <option value="FAMILYMART">ファミリーマート</option>
          <option value="MINISTOP">ミニストップ</option>
          <option value="SEICOMART">セイコーマート</option>
        </select>
        <div v-if="errors.code" class="invalid-feedback">
          {{ errors.code }}
        </div>
      </div>
      <div class="col-md-12">
        <label for="name" class="form-label">氏名</label>
        <input
          id="name"
          type="text"
          v-model="name"
          placeholder="氏名"
          required
          class="form-control"
          :class="{ 'is-invalid': errors.name }"
        />
        <div v-if="errors.name" class="invalid-feedback">
          {{ errors.name }}
        </div>
      </div>
      <div class="col-md-12">
        <label for="nameKana" class="form-label">フリガナ</label>
        <input
          id="nameKana"
          type="text"
          v-model="nameKana"
          placeholder="フリガナ"
          required
          class="form-control"
          :class="{ 'is-invalid': errors.nameKana }"
        />
        <div v-if="errors.nameKana" class="invalid-feedback">
          {{ errors.nameKana }}
        </div>
      </div>
      <div class="col-md-12">
        <label for="phoneNumber" class="form-label">電話番号</label>
        <input
          id="phoneNumber"
          type="text"
          v-model="phoneNumber"
          placeholder="080-xxxx-xxxx"
          required
          class="form-control"
          :class="{ 'is-invalid': errors.phoneNumber }"
        />
        <div v-if="errors.phoneNumber" class="invalid-feedback">
          {{ errors.phoneNumber }}
        </div>
      </div>
    </div>
    <button 
      type="submit" 
      class="btn btn-primary mt-3"
      :disabled="isLoading"
    >
      {{ isLoading ? '処理中...' : 'コンビニ払い決済する' }}
    </button>
  </form>
</template>

<script>
  import { onUnmounted, watch, ref } from 'vue'
  import { useForm } from 'vee-validate'
  import { toTypedSchema } from '@vee-validate/zod'
  import { z } from 'zod'

  export default {
    props: {
      ini_payer: {
        type: Object,
        default: () => {}
      },
      onSubmit: {
        type: Function,
        required: true,
      },
    },
    setup(props) {
      const isLoading = ref(false)
      const initialValues = {
        code: 'LAWSON',
        name: '',
        nameKana:  '',
        phoneNumber:  '',
      }

      const getUtf8Bytes = (str) => {
        return new TextEncoder().encode(str).length
      }

      const schema = z.object({
        code: z.enum([
          'SEVEN_ELEVEN',
          'LAWSON',
          'FAMILYMART',
          'MINISTOP',
          // 'DAILYYAMAZAKI',
          'SEICOMART',
        ]),
        name: z
          .string()
          .min(1, '氏名は必須です。')
          .refine(
            (value) => {
              return getUtf8Bytes(value) <= 45
            },
            {
              message: '設定値できるサイズを超えています。',
            }
          ),
        nameKana: z
          .string()
          .min(1, 'フリガナは必須です。')
          // eslint-disable-next-line no-irregular-whitespace
          .refine((value) => /^[ァ-ヶー　]*$/.test(value), {
            message: 'カタカナを入力してください。',
          })
          .refine(
            (value) => {
              return getUtf8Bytes(value) <= 60
            },
            {
              message: '設定値できるサイズを超えています。',
            }
          ),
        phoneNumber: z.string().refine(
          (value) => {
            const isValidFormat = /^[0-9-]+$/.test(value)
            const isValidLength = value.length <= 13
            return isValidFormat && isValidLength
          },
          {
            message: '使用できるのは数字とハイフンのみで、最大13文字まです。',
          }
        ),
      })

      const { defineField, errors, handleSubmit, resetForm } = useForm({
        validationSchema: toTypedSchema(schema),
        initialValues: {
          ...initialValues,
        },
      })

      const [code] = defineField('code')
      const [name] = defineField('name')
      const [nameKana] = defineField('nameKana')
      const [phoneNumber] = defineField('phoneNumber')
      // 親コンポーネントからの ini_payer 更新を監視
      watch(() => props.ini_payer, (newPayer) => {
        // ini_payerが更新されたときにinitialValuesを更新
        if (newPayer) {
          resetForm({
            values: {
              code: 'LAWSON',
              name: newPayer.name || '',
              nameKana: newPayer.nameKana || '',
              phoneNumber: newPayer.phoneNumber || '',
            }
          })
        }
      }, { immediate: true })  // コンポーネントがマウントされた時点でも更新
      async function onSuccess(values) {
        isLoading.value = true
        try {
          await props.onSubmit(values)
        } finally {
          isLoading.value = false
        }
      }

      const handleFormSubmit = handleSubmit(onSuccess)

      onUnmounted(() => {
        resetForm({
          values: { ...initialValues },
        })
      })

      return {
        isLoading,
        code,
        name,
        nameKana,
        phoneNumber,
        errors,
        handleFormSubmit,
      }
    },
  }
</script>

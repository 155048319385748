<template>
  <div id="login" class="text-center container">
    <h1 class="h4 mb-3 font-weight-normal">ログアウト</h1>
    <div class="alert alert-warning">
      {{ logout_msg }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ClubLogout',
    data: function () {
      return {
        logout_msg: 'ログアウトします。',
      }
    },
    methods: {
      handleLogout(authInfo) {
        console.log('handleLogout')
        return this.$store
          .dispatch('clogout', authInfo)
          .then((res) => {
            console.log('logout success', res, authInfo)
            this.$router.push({ path: '/clogin' })
          })
          .catch((err) => {
            this.throwReject(err)
            console.log('logout error', err)
          })
      },
      throwReject(err) {
        return Promise.reject(err)
      },
    },
    beforeCreate() {
      console.log('c beforeCreate')
    },
    created() {
      console.log('created')
    },
    beforeMount() {
      console.log('beforeMount')
    },
    mounted() {
      this.handleLogout()
      console.log('mounted logout')
    },
  }
</script>

<style scoped>
  /* @import "../../assets/bootstrap/css/signin.css"; */
</style>

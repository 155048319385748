function getToday() {
  // const now = new Date();
  return getNowYm(true) + '-' + getDay(true)
}
function getTomorrow() {
  var date = new Date()
  date.setDate(date.getDate() + 1)
  const day =
    parseInt(date.getDate()) < 10 ? '0' + date.getDate() : date.getDate()

  return date.getFullYear() + '-' + (parseInt(date.getMonth()) + 1) + '-' + day
}
function getTime() {
  const now = new Date()
  return now.getHours() + ':' + now.getMinutes()
}
function getTimeHMS() {
  const now = new Date()
  return now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds()
}
function getMonth(strSw, param) {
  const diff = 1 + param
  const now = new Date()
  let month = now.getMonth() + diff
  if (strSw == true) {
    if (month < 10) {
      month = '0' + month
    }
  }
  return month
}
function getYear() {
  var date = new Date()
  return date.getFullYear()
}
function getDay(strSw) {
  const now = new Date()
  let day = now.getDate()
  if (strSw == true) {
    if (day < 10) {
      day = '0' + day
    }
  }
  return day
}
function getNowYear() {
  const now = new Date()
  return now.getFullYear()
}
function getNowYm(strSw) {
  const now = new Date()
  return now.getFullYear() + '-' + getMonth(strSw, 0)
}
function getDayLast() {
  const now = new Date()
  // console.log('now.getFullYear', getMonth(false));
  console.log('getMonth(false)', getMonth(false, 0))
  const lastDay = new Date(now.getFullYear(), getMonth(false, 0), 0)
  console.log(lastDay.getDate)
  return lastDay.getDate()
}
function getDateAf(p) {
  var date = new Date()
  date.setDate(date.getDate() + p)
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var day = date.getDate()
  // console.log('getDate', year, month, day, p);
  return String(year) + '年' + String(month) + '月' + String(day) + '日'
}
function getDatePeriod(p) {
  var date = new Date()
  date.setDate(date.getDate() + p)
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var day = date.getDate()
  // console.log('getDate', year, month, day, p);
  return String(year) + '.' + String(month) + '.' + String(day) + ''
}
function moveToTop_old() {
  const duration = 10 // 移動速度（1秒で終了）
  const interval = 25 // 0.025秒ごとに移動
  const step = -window.scrollY / Math.ceil(duration / interval) // 1回に移動する距離
  const timer = setInterval(() => {
    window.scrollBy(0, step) // スクロール位置を移動

    if (window.scrollY <= 0) {
      clearInterval(timer)
    }
  }, interval)
}
function moveToTop() {
  const duration = 100; // 0.1秒でスクロール
  const start = window.scrollY;
  const startTime = performance.now();

  function scrollStep(currentTime) {
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1); // 0から1の範囲で進捗を計算
    const easeOut = 1 - Math.pow(1 - progress, 3);    // イージング効果（ease-out）

    window.scrollTo(0, start * (1 - easeOut));

    if (progress < 1) {
      requestAnimationFrame(scrollStep);
    }
  }

  requestAnimationFrame(scrollStep);
}
function array_key_exists(key, search) {
  console.log('array_key_exists', key, search)
  if (
    !search ||
    (search.constructor !== Array && search.constructor !== Object)
  ) {
    return false
  }
  console.log('array_key_exists 2', key in search)
  return key in search
}

export default {
  getToday,
  getTime,
  getTimeHMS,
  getMonth,
  getDay,
  getNowYear,
  getNowYm,
  getDayLast,
  moveToTop,
  array_key_exists,
  getDateAf,
  getTomorrow,
  getYear,
  getDatePeriod,
  moveToTop_old
}

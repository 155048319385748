```src/components/templates/User/MailView.vue
<template>
  <section class="page">
    <div class="container">
      <h3 class="title02 page__h2">メールアドレス変更</h3>
      <div class="box box--center mb-24">
        <p v-if="(this.application_obj || {}).application_check_type == 0">
          {{ message }}
        </p>
        <p
          v-else-if="(this.application_obj || {}).application_check_type == 100"
        >
          メールが送信されました。<br />
          メールを受信して、内容を確認して下さい。<br />
          受信されない場合は、メールの再送を行って下さい。
        </p>
        <p
          v-else-if="(this.application_obj || {}).application_check_type == 200"
        >
          現在変更申請を確認中です。
        </p>
        <p
          v-else-if="(this.application_obj || {}).application_check_type == 500"
        >
          承認されました。
        </p>
        <p
          v-else-if="(this.application_obj || {}).application_check_type == 600"
        >
          差し戻しがあります。内容を確認して再申請をお願いします。
        </p>
        <p v-else>変更申請フォームです</p>
      </div>
      <form class="mb-40">
        <div class="row gy-24">
          <div class="col-md-6 d-none d-md-block">
            <h4 class="title08 d-block mb-24">現在の登録内容</h4>
          </div>
          <div class="col-md-6 d-none d-md-block">
            <h4 class="title08 d-block mb-24">変更申請内容</h4>
          </div>
        </div>
        <div class="row my-2 pb-2">
          <div class="col-12 mb-1 col-lg-2 pt-2">
            <span class="page__inputLabel page__inputGroupLabel"
              >メールアドレス1</span
            >

          </div>
          <div class="col-12 mb-1 d-lg-none">
            <i class="bi bi-caret-right-fill me-2"></i>登録内容
          </div>
          <div class="col-12 mb-1 col-lg-4">
            <span class="showData page__inputGroupInput">{{
              form_obj.mail_1
            }}</span>
          </div>
          <div class="col-12 mb-1 d-lg-none">
            <i class="bi bi-caret-right-fill me-2"></i>変更申請内容
          </div>
          <div class="col-12 mb-1 col-lg-6">
            <div class="mb-1" v-if="chk_editable">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="chk_mail_1"
                id="chk_mail_1"
                :disabled="change_mode"
              />
              <label class="form-check-label ms-2" for="chk_mail_1">
                メールアドレス1を変更する
              </label>
            </div>
            <div class="mb-1" v-else-if="chk_mail_1">
              メールアドレス1が正しく受信できるか確認中です。メールを受信して確認して下さい。
            </div>
            <div v-if="chk_mail_1" class="mb-2">
              <label for="change_mail_1" class="mb-1">▼変更アドレス</label>
              <input
                type="mail"
                v-model="change_mail_1"
                :class="input_class"
                :readonly="input_readonly"
                placeholder="メールアドレス"
                id="change_mail_1"
              />
            </div>
            <div v-if="chk_mail_1 && chk_editable">
              <label for="confirm_mail_1" class="mb-1">▼確認</label>
              <input
                type="mail"
                v-model="confirm_mail_1"
                :class="input_class"
                :readonly="input_readonly"
                placeholder="確認"
                id="confirm_mail_1"
              />
            </div>
          </div>
        </div>
        <div class="row mb-2 pb-2">
            <div class="col-12 mb-1 col-lg-2 pt-2">
              <span class="page__inputLabel page__inputGroupLabel"
                >メールアドレス2</span
              >
          </div>
          <div class="col-12 mb-1 d-lg-none">
            <i class="bi bi-caret-right-fill me-2"></i>登録内容
          </div>
          <div class="col-12 mb-1 col-lg-4">
            <span class="showData page__inputGroupInput">{{
              form_obj.mail_2
            }}</span>
          </div>
          <div class="col-12 mb-1 d-lg-none">
            <i class="bi bi-caret-right-fill me-2"></i>変更申請内容
          </div>
          <div class="col-12 mb-1 col-lg-6">
            <div class="mb-1" v-if="chk_editable">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="chk_mail_2"
                id="chk_mail_2"
                :disabled="change_mode"
              />
              <label class="form-check-label ms-2" for="chk_mail_2">
                メールアドレス2を変更する
              </label>
            </div>
            <div class="mb-1" v-else-if="chk_mail_2">
              メールアドレス2が正しく受信できるか確認中です。メールを受信して確認して下さい。
            </div>
            <div v-if="chk_mail_2" class="mb-2">
              <label for="change_mail_2" class="mb-1">▼変更アドレス</label>
              <input
                type="mail"
                v-model="change_mail_2"
                :class="input_class"
                :readonly="input_readonly"
                placeholder="メールアドレス"
                id="change_mail_2"
              />
            </div>
            <div v-if="chk_mail_2 && chk_editable">
              <label for="confirm_mail_2" class="mb-1">▼確認</label>
              <input
                type="mail"
                v-model="confirm_mail_2"
                :class="input_class"
                :readonly="input_readonly"
                placeholder="確認"
                id="confirm_mail_2"
              />
            </div>
          </div>
        </div>
        <div class="row mb-2 pb-2">
          <div class="col-12 mb-1 col-lg-2 pt-2">
              <span class="page__inputLabel page__inputGroupLabel"
                >配信希望</span
              >

          </div>
          <div class="col-12 mb-1 d-lg-none">
            <i class="bi bi-caret-right-fill me-2"></i>登録内容
          </div>
          <div class="col-12 mb-1 col-lg-4">
            <span class="showData page__inputGroupInput">{{
              get_request_cd_label(form_obj.mail_flg)
            }}</span>
          </div>
          <div class="col-12 mb-1 d-lg-none">
            <i class="bi bi-caret-right-fill me-2"></i>変更申請内容
          </div>
          <div class="col-12 mb-1 col-lg-6">
            <span
              v-if="change_mode"
              :class="input_class"
            >{{ get_request_cd_label((app_form_obj || {}).mail_flg) }}</span>
            <bs-select
              v-else
              :options="request_type_list"
              :value="(app_form_obj || {}).mail_flg"
              v-model="(app_form_obj || {}).mail_flg"
              :readonly="input_readonly"
            ></bs-select>
          </div>
        </div>
      </form>
      <div
        class="d-flex flex-column flex-md-row mt-32 justify-content-center column-gap-24"
      >
        <button
          class="button03 mb-2"
          @click="back_input"
          v-if="change_mode && chk_editable"
        >
          <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
          <span class="button03__text"> 入力画面に戻る </span>
        </button>
        <button class="button03 mb-2" @click="back" v-else>
          <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
          <span class="button03__text"> 戻る </span>
        </button>

        <div class="d-grid mb-2" v-if="chk_editable">
          <button
            v-if="change_mode"
            type="button"
            @click="update_app(0)"
            class="button04"
          >
            <span class="button04__text"> 申請する </span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </button>
          <button v-else type="button" @click="confirm_chk" class="button04">
            <span class="button04__text"> 確認する </span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </button>
        </div>
        <div class="d-grid mb-2" v-if="chk_resendable">
          <button type="button" @click="update_app(1)" class="button04">
            <span class="button04__text"> 再送信する </span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </button>
        </div>
        <div
          v-if="(this.application_obj || {}).application_check_type == 100"
          class="d-grid my-2"
        >
          <button class="button03" @click="cancel">
            <font-awesome-icon
              :icon="['fas', 'circle-exclamation']"
              class="mx-2"
            />
            <span class="button03__text"> 変更を取りやめる </span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { mapState } from 'vuex'
  import BsSelect from '../../atoms/BsSelect.vue'
  import config from '../../../const'

  export default {
    name: 'UserDataView',
    data: function () {
      return {
        targetid: this.$store.state.auth.userId,
        btnLabel: '',
        data_obj: {},
        form_obj: {},
        application_obj: {},
        app_form_obj: {},
        change_mail_1: '',
        change_mail_2: '',
        confirm_mail_1: '',
        confirm_mail_2: '',
        chk_mail_1: false,
        chk_mail_2: false,
        change_mode_value: false,
        base_message:
          'メールアドレス情報を変更します。変更申請内容に入力して「確認する」を押してください。メールアドレス2を削除する場合は、「メールアドレス2を変更する」を選択し、入力欄を空のまま進めてください。',
        message: '',
        request_type_cd_obj: [],
      }
    },
    components: {
      BsSelect,
    },
    methods: {
      async loadConfigObj(param) {
        const params = {}
        await this.basicPosts_S(
          config.BASE_API_URL + '/config/' + param,
          params
        )
          .then(async (response) => {
            console.log('loadConfigObj response', response)
            this[param + '_obj'] =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this[param + '_obj'] = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadPrefList() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(
          config.BASE_API_URL + '/area_pref/lists',
          params
        )
          .then(async (response) => {
            console.log('loadPrefList response', response)
            this.pref_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.pref_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadUserObj() {
        const params = {}
        params['stat'] = [1]
        params['id'] = this.$store.state.auth.userId
        // params['pagegroup'] = 'mfj_member_data.id'
        await this.basicPosts_S(config.BASE_API_URL + '/member/lists', params)
          .then(async (response) => {
            console.log('loadUserObj', response)
            this.data_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            await this.setUserObj()
            this.$forceUpdate()
          })
      },
      async setUserObj() {
        this.form_obj = {
          mail_1: (this.data_obj || {}).mail_1,
          mail_2: (this.data_obj || {}).mail_2,
          mail_flg: (this.data_obj || {}).mail_flg,
        }
      },
      async loadApplicationObj() {
        const params = {}
        params['stat'] = [1]
        params['m_id'] = this.$store.state.auth.userId
        params['application_type'] = 2500
        params['not_application_check_types'] = [500, 900]
        await this.basicPosts_S(
          config.BASE_API_URL + '/member_application/lists',
          params
        )
          .then(async (response) => {
            console.log('loadApplicationObj', response)
            this.application_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.application_obj = {}
          })
          .finally(async () => {
            await this.setApplicationObj()
            this.$forceUpdate()
          })
      },
      async setApplicationObj() {
        const tmps =
          typeof this.application_obj === 'object' &&
          Object.keys(this.application_obj).length > 0
            ? this.application_obj
            : {
                id: 0,
                application_check_type: '',
                application_type: '',
                application_date: '',
              }
        this.application_obj = tmps

        this.app_form_obj = {
          mail_1: (this.get_target_app_item('mfj_member_profile@mail_1') || {})
            .application_value,
          mail_1_message: (
            this.get_target_app_item('mfj_member_profile@mail_1') || {}
          ).message,
          mail_2: (this.get_target_app_item('mfj_member_profile@mail_2') || {})
            .application_value,
          mail_2_message: (
            this.get_target_app_item('mfj_member_profile@mail_2') || {}
          ).message,
          mail_flg: (
            this.get_target_app_item('mfj_member_profile@mail_flg') || {}
          ).application_value,
          mail_flg_message: (
            this.get_target_app_item('mfj_member_profile@mail_flg') || {}
          ).message,
        }
        console.log('setApplicationObj', this.app_form_obj)
        if (this.app_form_obj.mail_1) {
          this.change_mail_1 = this.app_form_obj.mail_1
          this.chk_mail_1 = true
          console.log(
            'setApplicationObj mal_1',
            this.change_mail_1,
            this.chk_mail_1
          )
        }
        if (this.app_form_obj.mail_2) {
          this.change_mail_2 = this.app_form_obj.mail_2
          this.chk_mail_2 = true
          console.log(
            'setApplicationObj mal_2',
            this.change_mail_2,
            this.chk_mail_2
          )
        }
        if (!this.app_form_obj.mail_flg) {
          this.app_form_obj.mail_flg = '10'
        }
      },
      async confirm_chk() {
        console.log('confirm_chk', this.app_form_obj.mail_flg)
        if (
          this.chk_mail_1 == false &&
          this.chk_mail_2 == false &&
          Number(this.form_obj.mail_flg) === Number(this.app_form_obj.mail_flg)
        ) {
          alert('変更する内容を選択/入力して下さい。')
          return true
        } else if (
          !this.app_form_obj.mail_flg ||
          this.app_form_obj.mail_flg == ''
        ) {
          alert('配信希望を選択して下さい。')
          return true
        }
        if (this.chk_mail_1 == true) {
          if (this.change_mail_1 == '') {
            alert('メールアドレス1を入力して下さい')
            return true
          } else if (this.change_mail_1 != this.confirm_mail_1) {
            alert('メールアドレス1が一致しません')
            return true
          }
        }
        if (this.chk_mail_2 == true) {
          if (this.change_mail_2 != this.confirm_mail_2) {
            alert('メールアドレス2が一致しません')
            return true
          }
        }
        if (
          this.chk_mail_1 == true &&
          this.chk_mail_2 == true &&
          this.change_mail_2 != '' &&
          this.change_mail_1 === this.change_mail_2
        ) {
          alert('メールアドレス1とメールアドレス2が同じです。別のアドレスを入力して下さい。');
          return true;
        }
        if (
          this.chk_mail_2 == true && 
          this.change_mail_2 != '' &&
          this.form_obj.mail_1 === this.change_mail_2
        ) {
          alert('現在のメールアドレス1とメールアドレス2が同じです。別のアドレスを入力して下さい。');
            return true
        }
        this.app_form_obj.mail_1 = this.change_mail_1
        this.app_form_obj.mail_2 = this.change_mail_2
        this.change_mode_value = true
        this.message =
          '『申請する』を押すと、変更申請したアドレス宛に疎通確認のためのメールをお送りします。そのメールに記載されたURLにアクセスして、変更を確定して下さい。'
      },
      async cancel() {
        if (window.confirm('変更を取りやめます。よろしいですか？')) {
          const post_url_path =
            this.application_obj.id > 0
              ? '/member_application/edit/' + this.application_obj.id
              : '/member_application/add'
          const params = {
            m_id: this.$store.state.oauth.userId,
            stat: 9,
          }
          await this.basicPosts_I(config.BASE_API_URL + post_url_path, params)
            .then(async (response) => {
              console.log('update', response)
            })
            .catch(async (err) => {
              console.log(err)
            })
            .finally(async () => {
              await this.loadApplicationObj()
              this.change_mode_value = false
              this.app_form_obj.mail_flg = '10'
              this.message = '変更申請をキャンセルしました'
              this.$forceUpdate()
            })
        }
      },
      async update_app(flg) {
        console.log('component methods', this.data_obj)

        const post_url_path =
          this.application_obj.id > 0
            ? '/member_application/mail_edit/' + this.application_obj.id
            : '/member_application/mail_add'

        const params = {
          application_check_type: 100,
          application_type: 2500,
          m_id: this.$store.state.auth.userId,
          chk_mail_1: this.chk_mail_1 ? true : false,
          chk_mail_2: this.chk_mail_2 ? true : false,
        }

        const item_column = {
          mail_1: 'mfj_member_profile@mail_1',
          mail_2: 'mfj_member_profile@mail_2',
          mail_flg: 'mfj_member_profile@mail_flg',
        }
        const items = []
        Object.keys(item_column).forEach((item_key) => {
          // chk_mail_2 が false のとき mail_2 は更新対象にしない
          if (item_key === 'mail_2' && !this.chk_mail_2) {
            return 
          }

          items.push({
            id: this.get_target_app_item(item_column[item_key])
              ? (this.get_target_app_item(item_column[item_key]) || {}).id
              : 0,
            application_id: this.application_obj.id,
            application_key: item_column[item_key],
            application_value: this.app_form_obj[item_key],
          })
        })
        params.items =
          typeof items === 'object' && items.length > 0 ? items : false

        await this.basicPosts_I(config.BASE_API_URL + post_url_path, params)
          .then(async (response) => {
            console.log('update', response)
          })
          .catch(async (err) => {
            console.log(err)
          })
          .finally(async () => {
            await this.loadApplicationObj()
            this.change_mode_value = false
            if (flg == 1) {
              alert('再送信しました')
            }
            this.message = '更新しました'
            this.$forceUpdate()
          })
      },
      async update() {
        const params = {}
        let profiles
        let upd_profile = {
          id: 0,
          m_id: this.$store.state.auth.userId,
          mail_1: '',
          mail_2: '',
          mail_flg: '',
        }
        if (
          typeof this.data_obj === 'object' &&
          typeof this.data_obj.profile === 'object' &&
          Object.keys(this.data_obj.profile).length > 0
        ) {
          profiles = [...this.data_obj.profile]
          upd_profile = profiles[0]
        } else {
          profiles.push({ ...upd_profile })
        }
        if (this.chk_mail_1) {
          upd_profile.mail_1 = this.change_mail_1
        }
        if (this.chk_mail_2) {
          upd_profile.mail_2 = this.change_mail_2
        }
        upd_profile.mail_flg = this.form_obj.mail_flg
          ? this.form_obj.mail_flg
          : 0

        profiles[0] = upd_profile

        params.profile = profiles

        await this.basicPosts_I(
          config.BASE_API_URL + '/member/edit/' + this.$store.state.auth.userId,
          params
        )
          .then(async (response) => {
            console.log('update', response)
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            await this.loadUserObj()
            this.message = '申請しました'
            this.$forceUpdate()
          })
      },
      back_input() {
        this.message = this.base_message
        this.change_mode_value = false
      },
      back() {
        this.$router.go(-1)
      },
    },
    computed: {
      ...mapState({}),
      change_mode: function () {
        return this.chk_editable ? this.change_mode_value : true
      },
      chk_editable: function () {
        if (
          typeof this.application_obj !== 'object' ||
          (typeof this.application_obj === 'object' &&
            this.application_obj.id == 0)
        ) {
          return true
        }
        return [0, 600].includes(
          parseInt((this.application_obj || {}).application_check_type)
        )
      },
      chk_resendable: function () {
        return [100].includes(
          parseInt((this.application_obj || {}).application_check_type)
        )
      },
      get_pref_label: function () {
        const pref_list = this.pref_list
        return function (key) {
          if (typeof pref_list.find((pref) => pref.key == key) === 'object') {
            return pref_list.find((pref) => pref.key == key).label
          }
          return key
        }
      },
      get_request_cd_label: function () {
        const lists = this.request_type_list
        return function (key) {
          if (typeof lists.find((list) => list.key == key) === 'object') {
            return lists.find((list) => list.key == key).label
          } else if (key == 0) {
            return '未設定'
          }
          return key
        }
      },
      request_type_list: function () {
        return this.request_type_cd_obj
      },
      pref_list: function () {
        const lists = [...this.pref_obj]
        return lists.sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },
      application_type_list: function () {
        return this.application_type_obj
      },
      application_check_type_list: function () {
        return this.application_check_type_obj
      },

      get_target_app_item: function () {
        const application = this.application_obj
        return function (app_key) {
          if (
            typeof application === 'object' &&
            typeof application.items === 'object'
          ) {
            return application.items.find(
              (app) => app.application_key == app_key
            )
          }
        }
      },

      application_filter: function () {
        const app_list = this.application_list
        return function (app_type, app_chk_list) {
          return app_list.filter((app) => {
            if (
              app.application_type == app_type &&
              app_chk_list.includes(app.application_check_type) !== false
            ) {
              return true
            }
            return false
          })
        }
      },

      get_member_data: function () {
        return {
          mail_1: this.data_obj.mail_1,
          mail_2: this.data_obj.mail_2,
          mail_flg: this.data_obj.mail_flg,
        }
      },
      input_class: function () {
        if (
          this.change_mode ||
          ((this.application_obj || {}).application_check_type > 0 &&
            (this.application_obj || {}).application_check_type != 600)
        ) {
          return 'showData fa-18 w-100'
        } else {
          return 'form-control'
        }
      },
      input_readonly: function () {
        if (
          this.change_mode ||
          ((this.application_obj || {}).application_check_type > 0 &&
            (this.application_obj || {}).application_check_type != 600)
        ) {
          return true
        } else {
          return false
        }
      },
    },
    async created() {
      this.message = this.base_message
      await this.loadConfigObj('request_type_cd')
      await this.loadPrefList()
      await this.setUserObj()
      await this.loadUserObj()
      await this.setApplicationObj()
      await this.loadApplicationObj()
    },
    mounted: function () {
    },
    props: {},
  }
</script>

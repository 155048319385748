<template>
  <div class="container my-5">
    <div class="row" v-if="success_mode || error_mode"></div>
    <div v-else>
      <ul class="status">
        <li
          v-for="(step, index) in steps"
          :key="index"
          class="status__li"
          :class="{ 'status__li--isCurrent': isCurrentStep(step.mode) }"
        >
          <span class="status__number">{{ index + 1 }}</span>
          <span class="status__title">{{ step.text }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ClubRegistAddView',
  data: function () {
    return {
      steps: [
        { mode: 'kiyaku', text: '利用規約の確認' },
        { mode: 'mail', text: 'メールアドレスの確認' },
        { mode: 'profile', text: 'クラブ情報の入力' },
        // { mode: 'member', text: 'クラブメンバーの登録' },
        { mode: 'confirm', text: '申請内容の確認' },
        // { mode: 'member_check', text: 'クラブメンバーの承認' },
        { mode: 'mfj_check', text: 'MFJ確認' }
      ]
    }
  },
  components: {
  },
  methods: {
    isCurrentStep(mode) {

      return (
        this.step_mode === mode ||
        (mode === 'mail' && this.step_mode === 'passcd')
      )
    }
  },
  props: {
    step_mode: {
      type: String,
      default: 'kiyaku'
    }
  },
  computed: {
    ...mapState({}),
    success_mode: function () {
      return this.step_mode == 'success'
    },
    error_mode: function () {
      return this.step_mode == 'error'
    }
  },

  async created() {},
  mounted: function () {
    // console.log('tags_list', this.tags_list);
  },
}
</script>

<style scoped>
@import '../../../assets/css/style-color03.css';
.status {
  display: flex;
  justify-content: center;


  list-style: none;
  margin: 0;
  padding: 0;

}

.status__li {
  position: relative;
  width: 172px;
  padding-inline: 20px;
  text-align: center;
}

.status__li:after {
  position: absolute;
  top: 10px;
  right: -6px;
  font-size: 18px;
  content: '>';
}

.status__li:last-of-type:after {
  content: none;
}

.status__number {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border: 1px solid var(--color01);
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
}

.status__title {
  font-size: 14px;
}

.status__li--isCurrent {
  font-weight: bold;
}

.status__li--isCurrent .status__number {
  background-color: var(--color01);
  color: var(--color02);
}
</style>

<template>
  <section class="page">
    <div class="container">
      <h3 class="title02 page__h2">パスワード再設定</h3>
      <div class="box box--center mb-24">
        <p>{{ message }}</p>
      </div>

      <form class="m-40">
        <div class="row" v-if="passwd_mode == 'put_passcd'">
          <div class="col-12 mb-3">
            <label class="label page__inputLabel"
              >ログインID or ライセンスNo</label
            >
            <div class="input-group">
              <input
                type="text"
                v-model="login_id"
                class="form-control"
                placeholder="ログインID"
              />
            </div>
          </div>

          <div class="col-12 mb-3">
            <label class="label page__inputLabel">生年月日</label>
            <div
              class="birth-date-group d-flex flex-column flex-md-row gap-24 align-items-center"
            >
              <div class="birth-date-group1">
                <bs-select
                  :options="birth_date_year_list"
                  v-model="birth_date_year"
                  :null-option="false"
                  :classParam="'form-select'"
                />
                <span class="page__inputLabel">年</span>
              </div>
              <div class="birth-date-group1">
                <bs-select
                  :options="birth_date_month_list"
                  v-model="birth_date_month"
                  :null-option="true"
                  :classParam="'form-select'"
                />
                <span class="page__inputLabel">月</span>
                <bs-select
                  :options="
                    birth_date_day_list(birth_date_year, birth_date_month)
                  "
                  v-model="birth_date_day"
                  :null-option="true"
                  :classParam="'form-select'"
                />
                <span class="page__inputLabel">日</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-else-if="passwd_mode == 'chk_passcd'">
          <div class="col-12 mb-3">
            <label class="label page__inputLabel">確認No.</label>
            <div class="input-group justify-content-center">
              <input
                type="text"
                v-model="passcd[0]"
                class="form-control text-center"
                maxlength="1"
              />
              <input
                type="text"
                v-model="passcd[1]"
                class="form-control text-center"
                maxlength="1"
              />
              <input
                type="text"
                v-model="passcd[2]"
                class="form-control text-center"
                maxlength="1"
              />
              <input
                type="text"
                v-model="passcd[3]"
                class="form-control text-center"
                maxlength="1"
              />
            </div>
          </div>
        </div>

        <div class="row" v-else-if="passwd_mode == 'chg_passwd'">
          <div class="col-12 mb-3">
            <label class="label page__inputLabel">パスワード</label>
            <div class="input-group">
              <input
                type="password"
                v-model="passwd"
                class="form-control"
                placeholder="パスワード"
              />
            </div>
          </div>

          <div class="col-12 mb-3">
            <label class="label page__inputLabel">パスワード(確認)</label>
            <div class="input-group">
              <input
                type="password"
                v-model="passwd_chk"
                class="form-control"
                placeholder="パスワード(確認)"
              />
            </div>
          </div>
        </div>

        <div
          class="d-flex flex-column flex-md-row justify-content-center column-gap-24 gap-24 mt-24"
        >
          <button
            type="button"
            class="button03"
            @click="
              back(
                passwd_mode == 'put_passcd'
                  ? 0
                  : passwd_mode == 'chk_passcd'
                  ? 'put'
                  : 'chk'
              )
            "
          >
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
            <span class="button03__text">
              {{ passwd_mode == 'put_passcd' ? 'ログインへ戻る' : '戻る' }}
            </span>
          </button>

          <button
            v-if="passwd_mode != 'fin_passwd'"
            type="button"
            class="button04"
            @click="
              next_mode(
                passwd_mode == 'put_passcd'
                  ? 'chk'
                  : passwd_mode == 'chk_passcd'
                  ? 'chg'
                  : 'fin'
              )
            "
          >
            <span class="button04__text">
              {{ passwd_mode == 'chg_passwd' ? '変更' : '次へ' }}
            </span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </button>

          <button v-else type="button" class="button03" @click="back(0)">
            <span class="button03__text">ログインへ戻る</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<style scoped>
  .input-group input[type='text'].text-center {
    max-width: 60px;
  }
  .page__inputLabel {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  .birth-date-group {
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
    align-items: center !important;
  }
  .birth-date-group1 {
    display: flex !important;
    flex-direction: row !important;
    gap: 1rem !important;
    width: 100% !important;
    align-items: center !important;
  }

  /* 生年月日入力欄のスタイル */
  @media screen and (max-width: 767px) {
    .birth-date-group {
      display: flex !important;
      flex-direction: column !important;
    }

    .birth-date-group1 {
      display: flex !important;
      flex-direction: row !important;
    }
  }
</style>
<script>
  import { mapState } from 'vuex'
  // import BsPageTitle from '../../molecules/BsPageTitle.vue'
  // import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
  import BsSelect from '../../atoms/BsSelect.vue'
  // import BsCheckBox from '../../atoms/BsCheckBox.vue'
  // import '../../assets/bootstrap/css/sc.css';
  import config from '../../../const'
  import moment from 'moment'
  import Common from '../../../common/common'

  export default {
    name: 'PassCdView',
    data: function () {
      return {
        btnLabel: '',
        passcd: ['', '', '', ''],
        mail: '',
        chk_passcd_md5: '',
        passwd: '',
        passwd_chk: '',
        login_id: '',
        birth_date: '',
        birth_date_year: '',
        birth_date_month: '',
        birth_date_day: '',
        passwd_mode: 'put_passcd',
        message: 'パスワードを再設定します。',
      }
    },
    components: {
      //   BsPageTitle,
      BsSelect,
      //   BsMsgAlert,
      // BsCheckBox,
    },
    methods: {
      async back(target) {
        if (target == 0) {
          this.$router.go(-1)
        } else if (target == 'put') {
          this.passwd_mode = 'put_passcd'
        } else if (target == 'chk') {
          this.passwd_mode = 'chk_passcd'
        } else if (target == 'chg') {
          this.passwd_mode = 'chg_passwd'
        }
      },
      async next_mode(target) {
        if (target == 'chk') {
          if (
            !this.login_id ||
            !this.birth_date_year ||
            !this.birth_date_month ||
            !this.birth_date_day
          ) {
            this.message = 'ログインIDorライセンスNo\n生年月日を入力して下さい'
            return false
          }
          await this.sendPassCd().then((response) => {
            console.log(
              'next_mode sendPassCd response',
              response.data.body.status
            )
            if (
              response &&
              response.data &&
              response.data.body &&
              response.data.body.status == 200
            ) {
              this.message =
                '登録いただいているアドレス宛てに確認No.を送信しました。確認Noを入力欄に入力後、パスワードの再設定をお願いします。'
              this.passwd_mode = 'chk_passcd'
            } else {
              this.message = 'パスコード発行エラー'
            }
          })
        } else if (target == 'chg') {
          if (
            !this.login_id ||
            !this.birth_date_year ||
            !this.birth_date_month ||
            !this.birth_date_day
          ) {
            this.message = 'ログインIDorライセンスNo\n生年月日を入力して下さい'
            return false
          } else if (
            !this.passcd[0] ||
            !this.passcd[1] ||
            !this.passcd[2] ||
            !this.passcd[3]
          ) {
            this.message = '確認Noを入力して下さい'
            return false
          }

          await this.chkPassCd().then((response) => {
            console.log(
              'next_mode sendPassCd response',
              response.data.body.status
            )
            if (
              response &&
              response.data &&
              response.data.body &&
              response.data.body.status == 200
            ) {
              this.message = 'パスワードを入力して変更して下さい。'
              this.passwd_mode = 'chg_passwd'
            } else {
              this.message = 'パスコード確認エラー'
            }
          })
        } else if (target == 'fin') {
          if (
            !this.login_id ||
            !this.birth_date_year ||
            !this.birth_date_month ||
            !this.birth_date_day
          ) {
            this.message = 'ログインIDorライセンスNo\n生年月日を入力して下さい'
            return false
          } else if (
            !this.passcd[0] ||
            !this.passcd[1] ||
            !this.passcd[2] ||
            !this.passcd[3]
          ) {
            this.message = '確認Noを入力して下さい'
            return false
          } else if (!this.passwd || !this.passwd_chk) {
            this.message = 'パスワードを入力して下さい'
            return false
          } else if (this.passwd != this.passwd_chk) {
            this.message = '確認用のパスワードと一致しません'
            return false
          }

          // パスワードバリデーション追加
          const passwdValid =
            /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])[a-zA-Z0-9!@#$%^&*.]{8,30}$/.test(
              this.passwd
            )
          if (!passwdValid) {
            this.message =
              'パスワードは8〜30文字の半角英数字と記号(!@#$%^&*.)を含む必要があります'
            return false
          }

          await this.chgPassWd().then((response) => {
            console.log(
              'next_mode sendPassCd response',
              response.data.body.status
            )
            if (
              response &&
              response.data &&
              response.data.body &&
              response.data.body.status == 200
            ) {
              this.message =
                'パスワードを変更しました。ログイン画面からログインをお願いします。'
              this.passwd_mode = 'fin_passwd'
            } else {
              this.message = 'パスワード変更エラー'
            }
          })
        }
      },
      async chgPassWd() {
        const params = {}
        params['stat'] = [1]
        params['login_id_license_no'] = this.login_id
        params['birth_date_year'] = this.birth_date_year
        params['birth_date_month'] = this.birth_date_month
        params['birth_date_day'] = this.birth_date_day
        params['pass_cd'] = this.passcd.join('')
        params['pass_wd'] = this.passwd
        return await this.nonloginPosts_S(
          config.BASE_API_URL + '/user/chg_pass_wd',
          params
        )
          .then(async (response) => {
            console.log('chgPassWd!!!', response)
            this.chk_passcd_md5 = ''
            // this.data_obj = (response.data && response.data.body && response.data.body[0])? response.data.body[0]: {};
            return response
          })
          .catch(async (err) => {
            console.log(err)
            this.chk_passcd_md5 = ''
            // this.data_obj = [];
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async chkPassCd() {
        const params = {}
        params['stat'] = [1]
        params['login_id_license_no'] = this.login_id
        params['birth_date_year'] = this.birth_date_year
        params['birth_date_month'] = this.birth_date_month
        params['birth_date_day'] = this.birth_date_day
        params['pass_cd'] = this.passcd.join('')
        return await this.nonloginPosts_S(
          config.BASE_API_URL + '/user/chk_pass_cd',
          params
        )
          .then(async (response) => {
            console.log('chkPassCd!!!', response)
            this.chk_passcd_md5 = ''
            // this.data_obj = (response.data && response.data.body && response.data.body[0])? response.data.body[0]: {};
            return response
          })
          .catch(async (err) => {
            console.log(err)
            this.chk_passcd_md5 = ''
            // this.data_obj = [];
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async sendPassCd() {
        const params = {}
        params['stat'] = [1]
        params['login_id_license_no'] = this.login_id
        params['birth_date_year'] = this.birth_date_year
        params['birth_date_month'] = this.birth_date_month
        params['birth_date_day'] = this.birth_date_day
        return await this.nonloginPosts_S(
          config.BASE_API_URL + '/user/send_pass_cd',
          params
        )
          .then(async (response) => {
            console.log('sendPassCd!!!', response)
            this.chk_passcd_md5 = ''
            // this.data_obj = (response.data && response.data.body && response.data.body[0])? response.data.body[0]: {};
            return response
          })
          .catch(async (err) => {
            console.log(err)
            this.chk_passcd_md5 = ''
            // this.data_obj = [];
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadUserObj() {
        const params = {}
        params['stat'] = [1]
        params['id'] = this.$store.state.auth.userId
        await this.basicPosts_S(config.BASE_API_URL + '/member/lists', params)
          .then(async (response) => {
            console.log('loadUserObj!!!', response)
            this.data_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async confirm_chk() {
        if (this.change_login_pass == '' || this.confirm_login_pass == '') {
          alert('変更するログインパスを入力して下さい')
          return true
        } else if (
          this.change_login_id != '' &&
          this.confirm_login_id != '' &&
          this.change_login_id != this.confirm_login_id
        ) {
          alert('入力されたログインパスが一致しません')
          return true
        }

        this.message = '更新できます。'
        this.change_mode = true
      },
      async update() {
        console.log('component methods', this.data_obj)
        const params = {}
        params.login_pass = this.change_login_pass
        await this.basicPosts_I(
          config.BASE_API_URL + '/member/edit/' + this.$store.state.auth.userId,
          params
        )
          .then(async (response) => {
            console.log('update', response)
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      back_input() {
        this.change_mode = false
      },
      // back(){
      // }
    },
    computed: {
      ...mapState({}),
      birth_date_year_list: function () {
        let now = 1920
        let limit = parseInt(Common.getNowYear()) + 1
        const tmps = []
        for (let i = now; i < 1970; i++) {
          tmps.push({
            key: i,
            label: i,
          })
        }
        tmps.push({
          key: '',
          label: '選択して下さい',
        })
        for (let i = 1970; i < limit; i++) {
          tmps.push({
            key: i,
            label: i,
          })
        }
        return tmps
      },
      birth_date_month_list: function () {
        const tmps = []
        for (let i = 1; i <= 12; i++) {
          tmps.push({
            key: i,
            label: i,
          })
        }
        return tmps
      },
      birth_date_day_list: function () {
        // const moment_date = moment();
        const moment_func = moment
        return function (year, month) {
          let max = 31
          if (year != '' && month != '') {
            const moment = moment_func([year, parseInt(month) - 1, 1])
            max = moment.daysInMonth()
          }
          const tmps = []
          for (let i = 1; i <= max; i++) {
            tmps.push({
              key: i,
              label: i,
            })
          }
          return tmps
        }
      },
      input_class: function () {
        if (this.change_mode) {
          return 'form-control-plaintext'
        } else {
          return 'form-control'
        }
      },
      input_readonly: function () {
        if (this.change_mode) {
          return true
        } else {
          return false
        }
      },
    },

    async created() {
      // await this.loadUserObj();
    },
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
    props: {},
  }
</script>

<template>
  <div class="container my-5">
    <div class="row" v-if="success_mode || error_mode"></div>
    <div v-else>
      <ul class="status">
        <li
          v-for="(step, index) in steps"
          :key="index"
          class="status__li"
          :class="{ 'status__li--isCurrent': isCurrentStep(step.mode) }"
        >
          <span class="status__number">{{ index + 1 }}</span>
          <span class="status__title">{{ step.text }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'MigrationStepView',
    data: function () {
      return {
        steps: [
          { mode: 'kiyaku', text: '利用規約と事前準備物の確認' },
          { mode: 'check', text: 'ライセンスNo.の入力' },
          { mode: 'mail', text: '確認メールの送信確認' },
          { mode: 'passcd', text: '確認No.の入力' },
          { mode: 'profile', text: 'ログインIDなどの入力' },
          { mode: 'confirm', text: '申請内容の確認' },
          { mode: 'result', text: '完了' },
        ],
      }
    },
    components: {},
    methods: {
      isCurrentStep(mode) {
        return (
          this.step_mode === mode ||
          (mode === 'license' && this.step_mode === 'license_sel') ||
          (mode === 'charge' && this.step_mode === 'mfj_approval')
        )
      },
    },
    props: {
      step_mode: {
        type: String,
        default: 'kiyaku',
      },
    },
    computed: {
      ...mapState({}),
      kiyaku_mode: function () {
        return this.step_mode == 'kiyaku' ? true : false
      },
      check_mode: function () {
        return this.step_mode == 'check' ? true : false
      },
      mail_mode: function () {
        return this.step_mode == 'mail' ? true : false
      },
      passcd_mode: function () {
        return this.step_mode == 'passcd' ? true : false
      },
      license_mode: function () {
        return this.step_mode == 'license' || this.step_mode == 'license_sel'
          ? true
          : false
      },
      profile_mode: function () {
        return this.step_mode == 'profile' ? true : false
      },
      confirm_mode: function () {
        return this.step_mode == 'confirm' ? true : false
      },
      mfj_check_mode: function () {
        return this.step_mode == 'mfj_check' ? true : false
      },
      charge_mode: function () {
        return this.step_mode == 'charge' ? true : false
      },
      reject_mode: function () {
        return this.step_mode == 'mfj_reject' ? true : false
      },
      approval_mode: function () {
        return this.step_mode == 'mfj_approval' ? true : false
      },
      success_mode: function () {
        return this.step_mode == 'success' ? true : false
      },
      error_mode: function () {
        return this.step_mode == 'error' ? true : false
      },
    },

    async created() {},
    mounted: function () {},
  }
</script>

<style scoped>
.status {
  justify-content: center;
}
</style>

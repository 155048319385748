export default Object.freeze({
  BASE_ENV: process.env.NODE_ENV,
  BASE_APP_URL: process.env.VUE_APP_APP_URL,
  BASE_SERVICE_URL: 
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SEVICE_URL + ''
      : process.env.VUE_APP_SEVICE_URL,
  BASE_API_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_API_SERVER + ''
      : process.env.VUE_APP_API_SERVER,
  BASE_ASSETS_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_API_ASSETS
      : process.env.VUE_APP_API_ASSETS,
  BASE_API_USER_HEADER_KEY: 'mfj-user-token',
  BASE_API_CLUB_HEADER_KEY: 'mfj-club-token',
  BASE_API_OWNER_HEADER_KEY: 'mfj-owner-token',
  BASE_FRONTEND_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_FRONTEND_URL + ''
      : process.env.VUE_APP_FRONTEND_URL + '',

  GMO_CALLBACK_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_GMO_CALLBACK_URL + ''
      : process.env.VUE_APP_GMO_CALLBACK_URL + '',
  GMO_WEBHOOK_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_GMO_WEBHOOK_URL + ''
      : process.env.VUE_APP_GMO_WEBHOOK_URL + '',
  GMO_AUTH_BASIC_KEY:
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_GMO_AUTH_BASIC_KEY + ''
      : process.env.VUE_APP_GMO_AUTH_BASIC_KEY + '',
  GMO_AUTH_BASIC_URL:
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_GMO_AUTH_BASIC_URL + ''
      : process.env.VUE_APP_GMO_AUTH_BASIC_URL + '',
  GMO_SHOP_OP_MODE: 
    process.env.NODE_ENV === 'production' 
    ? true 
    : false,
  GMO_SHOP_ID_MFJ:
    process.env.NODE_ENV === 'production' 
    ? process.env.VUE_APP_GMO_SHOP_ID_MFJ 
    : process.env.VUE_APP_GMO_SHOP_ID_MFJ,
  GMO_MP_TOKEN_API_KEY_MFJ: 
    process.env.NODE_ENV === 'production' 
    ? process.env.VUE_APP_GMO_MP_TOKEN_API_KEY_MFJ 
    : process.env.VUE_APP_GMO_MP_TOKEN_API_KEY_MFJ,
  GMO_MP_TOKEN_PUB_KEY_MFJ: 
    process.env.NODE_ENV === 'production' 
    ? process.env.VUE_APP_GMO_MP_TOKEN_PUB_KEY_MFJ 
    : process.env.VUE_APP_GMO_MP_TOKEN_PUB_KEY_MFJ,

  GMO_SHOP_ID_IMEDIA:
    process.env.NODE_ENV === 'production' 
    ? process.env.VUE_APP_GMO_SHOP_ID_IMEDIA 
    : process.env.VUE_APP_GMO_SHOP_ID_IMEDIA,
  GMO_MP_TOKEN_API_KEY_IMEDIA: 
    process.env.NODE_ENV === 'production' 
    ? process.env.VUE_APP_GMO_MP_TOKEN_API_KEY_IMEDIA 
    : process.env.VUE_APP_GMO_MP_TOKEN_API_KEY_IMEDIA,
  GMO_MP_TOKEN_PUB_KEY_IMEDIA: 
    process.env.NODE_ENV === 'production' 
    ? process.env.VUE_APP_GMO_MP_TOKEN_PUB_KEY_IMEDIA 
    : process.env.VUE_APP_GMO_MP_TOKEN_PUB_KEY_IMEDIA,
    

  GMO_SHOP_ID:
    process.env.NODE_ENV === 'production' ? 'tshop00068209' : 'tshop00068209',
  GMO_MP_TOKEN_API_KEY:
    process.env.NODE_ENV === 'production'
      ? 'MDJlNTdiOTU2NjRmZTkzOWY3NWUzMTg4ZTU3M2ZjNmY4NGI3ZTRjMTBiMmMxOTM5YTlhNzcxMTA4NWNmYjVjYXRzaG9wMDAwNjkxMzA='
      : 'MDJlNTdiOTU2NjRmZTkzOWY3NWUzMTg4ZTU3M2ZjNmY4NGI3ZTRjMTBiMmMxOTM5YTlhNzcxMTA4NWNmYjVjYXRzaG9wMDAwNjkxMzA=',
  GMO_MP_TOKEN_PUB_KEY:
    process.env.NODE_ENV === 'production'
      ? 'MIIBojANBgkqhkiG9w0BAQEFAAOCAY8AMIIBigKCAYEAmH1plxcoaYapI8vAVomqnCMULrePiKky9ejPLo52egtr6zM3MhbugstEAYDhuSnzRWz2SASv45V5OLfApvGpImhexYZimkApzFlcehDl/64c4wxti1XWps4SH5OhbE0oLvbU9hsh5jmienA6mtfwDla3YQzlAbeKmY15vAUXX6MBKxSkFl+2bDtUJRj+0T7YEyMeO5ABzad8ifVWyX32EYY2VXGpioT/wNYJvK1w03/1ikuiIY0RldaVtiKJlLoeEIq/v4NJk957FDFNxgAyj1bGeoN8rqlFZLc8S/8jZX6+ao/yBuuaeRMGwnlJioPvGmqDOkx9kGJzHNoQrbIEgx1sW8ae4EBu11pWJb32mGc9fY/wASqrbpyT55PnqFsY/89k+qSXrKv//jVMKs8/2ld2LoGwXz8pavQCivSQeECOzWS8zA91vjnTjtOstDWqmAxB+Rn+mF73qg5W0Wiv9gqZfcZqQWI7u/ZkxFmvVctDh3p+Ggdo0xWOXrueiTEzAgMBAAE='
      : 'MIIBojANBgkqhkiG9w0BAQEFAAOCAY8AMIIBigKCAYEAmH1plxcoaYapI8vAVomqnCMULrePiKky9ejPLo52egtr6zM3MhbugstEAYDhuSnzRWz2SASv45V5OLfApvGpImhexYZimkApzFlcehDl/64c4wxti1XWps4SH5OhbE0oLvbU9hsh5jmienA6mtfwDla3YQzlAbeKmY15vAUXX6MBKxSkFl+2bDtUJRj+0T7YEyMeO5ABzad8ifVWyX32EYY2VXGpioT/wNYJvK1w03/1ikuiIY0RldaVtiKJlLoeEIq/v4NJk957FDFNxgAyj1bGeoN8rqlFZLc8S/8jZX6+ao/yBuuaeRMGwnlJioPvGmqDOkx9kGJzHNoQrbIEgx1sW8ae4EBu11pWJb32mGc9fY/wASqrbpyT55PnqFsY/89k+qSXrKv//jVMKs8/2ld2LoGwXz8pavQCivSQeECOzWS8zA91vjnTjtOstDWqmAxB+Rn+mF73qg5W0Wiv9gqZfcZqQWI7u/ZkxFmvVctDh3p+Ggdo0xWOXrueiTEzAgMBAAE=',


  HEAD_MENU_USER_LIST: [
    {
      menu_sort: 10,
      path: '/',
      label: 'HOME',
      name: 'dashboard',
    },
    {
      menu_sort: false,
      path: false,
      label: 'お知らせ',
      name: '',
    },
    {
      menu_sort: 14,
      path: '/information/list',
      label: '新着情報',
      name: 'information_list',
    },
    {
      menu_sort: false,
      path: '/information/data',
      label: '新着情報',
      name: 'information_data',
    },
    {
      menu_sort: 20,
      path: '/edit/data',
      label: '個人情報変更申請',
      name: 'edit_data',
    },
    {
      menu_sort: false,
      path: '/edit/address',
      label: '住所変更',
      name: 'edit_address',
    },
    {
      menu_sort: false,
      path: '/edit/name',
      label: 'お名前変更',
      name: 'edit_name',
    },
    {
      menu_sort: false,
      path: '/edit/photo',
      label: '写真変更',
      name: 'edit_photo',
    },
    {
      menu_sort: false,
      path: '/edit/tel',
      label: '電話番号変更',
      name: 'edit_tel',
    },
    {
      menu_sort: false,
      path: '/edit/shipping',
      label: '配送先変更',
      name: 'edit_shipping',
    },
    {
      menu_sort: 30,
      path: '/request/license',
      label: '追加・継続申請',
      name: '',
    },
    {
      menu_sort: false,
      path: '/request/3ds',
      label: '追加・継続3Dセキュア',
      name: '',
    },
    {
      menu_sort: false,
      // menu_sort: 40,
      path: '/request/add',
      label: '追加申請',
      name: '',
    },
    {
      menu_sort: 32,
      path: '/request/promotion',
      label: '昇降格申請',
      name: '',
    },
    {
      menu_sort: false,
      path: '/join/club',
      label: '公認クラブ参加',
      name: 'join_club',
    },
    {
      menu_sort: 43,
      path: '/license/history',
      label: 'ライセンス取得履歴 照会',
      name: '',
    },
    {
      menu_sort: false,
      path: '/license/seminar',
      label: 'ライセンス承認Web動画',
      name: '',
    },
    {
      menu_sort: 50,
      path: '/edit/mail',
      label: 'メールアドレス変更',
      name: 'edit_mail',
    },
    {
      menu_sort: 60,
      path: '/edit/login_id',
      label: 'ログインID変更',
      name: 'edit_login_id',
    },
    {
      menu_sort: 70,
      path: '/edit/passwd',
      label: 'パスワード変更',
      name: 'edit_passwd',
    },
    {
      menu_sort: false,
      path: false,
      label: '参戦履歴/獲得ポイント 照会',
      name: '',
    },
    {
      menu_sort: false,
      path: '/login',
      label: 'ログイン',
      name: 'login',
    },
    {
      menu_sort: false,
      path: '/club/mypage',
      label: '公認クラブ',
      name: 'club_mypage',
      style_class: 'bg_mfj_orange text-dark',
    },
    {
      menu_sort: false,
      path: '/owner/mypage',
      label: '主催者会員',
      name: 'owner_mypage',
      style_class: 'bg_mfj_blue',
    },
    {
      menu_sort: 82,
      path: '/user/school',
      label: '学校公欠文書請求',
      name: 'user_school',
    },
    {
      menu_sort: 99,
      path: '/logout',
      label: 'ログアウト',
      name: 'logout',
      style_class: 'bg_mfj_gray_01',
    },
  ],
  HEAD_MENU_OWNER_LIST: [
    {
      menu_sort: false,
      path: '/ologin',
      label: 'ログイン',
      name: 'ologin',
    },
    {
      menu_sort: 10,
      path: '/owner/mypage',
      label: 'HOME',
      name: 'owner_mypage',
    },
    {
      menu_sort: 14,
      path: '/oinformation/list',
      label: '新着情報',
      name: 'oinformation_list',
    },
    {
      menu_sort: false,
      path: '/oinformation/data',
      label: '新着情報',
      name: 'oinformation_data',
    },
    {
      menu_sort: false,
      path: '/owner_edit/data',
      label: 'レース',
      name: 'owner_data',
    },
    {
      menu_sort: 20,
      path: '/owner_search/member',
      label: 'ライセンス会員検索',
      name: 'owner_search_member',
    },
    {
      menu_sort: 25,
      path: '/event/link',
      label: 'イベント管理',
      name: 'owner_data',
    },
    {
      menu_sort: false,
      // menu_sort: 30,
      path: '/owner_edit/data',
      label: '登録情報変更',
      name: 'owner_data',
    },
    {
      menu_sort: false,
      path: '/owner_request/continue',
      label: '継続申請',
      name: 'owner_data',
    },
    {
      menu_sort: false,
      // menu_sort: 40,
      path: '/owner_edit/mail',
      label: 'メールアドレス変更',
      name: 'owner_edit_mail',
    },
    {
      menu_sort: false,
      // menu_sort: 50,
      path: '/owner_edit/login_id',
      label: 'ログインID変更',
      name: 'owner_edit_login_id',
    },
    {
      menu_sort: 60,
      path: '/owner_edit/passwd',
      label: 'パスワード変更',
      name: 'owner_edit_passwd',
    },
    {
      menu_sort: false,
      path: '/owner_edit/address',
      label: '住所変更',
      name: 'owner_edit_address',
    },
    {
      menu_sort: false,
      path: '/owner_edit/tel',
      label: '電話番号変更',
      name: 'owner_edit_tel',
    },
    {
      menu_sort: false,
      path: '/owner_edit/name',
      label: '名称変更',
      name: 'owner_edit_name',
    },
    {
      menu_sort: false,
      path: '/owner_edit/named',
      label: '代表者・責任者変更',
      name: 'owner_edit_named',
    },
    {
      menu_sort: false,
      path: '/owner_edit/photo',
      label: '画像変更',
      name: 'owner_edit_photo',
    },
    {
      menu_sort: false,
      path: '/owner_edit/shipping',
      label: '配送先変更',
      name: 'owner_edit_shipping',
    },
    {
      menu_sort: false,
      path: '/owner_edit/license_no',
      label: 'ライセンスNo変更',
      name: 'owner_edit_license_no',
    },
    {
      menu_sort: false,
      path: '/',
      label: 'ライセンス会員',
      name: 'dashboard',
      style_class: 'bg_mfj_red',
    },
    {
      menu_sort: false,
      // menu_sort: 92,
      path: '/club/mypage',
      label: '公認クラブ',
      name: 'club_mypage',
      style_class: 'bg_mfj_orange text-dark',
    },

    {
      menu_sort: 99,
      path: '/ologout',
      label: 'ログアウト',
      name: 'ologout',
      style_class: 'bg_mfj_gray_01',
    },
  ],
  HEAD_MENU_CLUB_LIST: [
    {
      menu_sort: 10,
      path: '/club/mypage',
      label: 'HOME',
      name: 'club_mypage',
    },
    {
      menu_sort: 14,
      path: '/cinformation/list',
      label: '新着情報',
      name: 'cinformation_list',
    },
    {
      menu_sort: false,
      path: '/cinformation/data',
      label: '新着情報',
      name: 'cinformation_data',
    },
    {
      menu_sort: 20,
      path: '/club_edit/member',
      label: 'クラブ員設定',
      name: 'club_edit_member',
    },
    {
      menu_sort: 30,
      path: '/club_edit/data',
      label: '登録情報変更',
      name: 'club_data',
    },
    {
      menu_sort: 33,
      path: '/club_request/continue',
      label: '継続申請',
      name: 'club_request_continue',
    },
    {
      menu_sort: 40,
      path: '/club_edit/mail',
      label: 'メールアドレス変更',
      name: 'club_edit_mail',
    },
    {
      menu_sort: 50,
      path: '/club_edit/login_id',
      label: 'ログインID変更',
      name: 'club_edit_login_id',
    },
    {
      menu_sort: 60,
      path: '/club_edit/passwd',
      label: 'パスワード変更',
      name: 'club_edit_passwd',
    },
    {
      menu_sort: false,
      path: '/club_edit/address',
      label: '住所変更',
      name: 'club_edit_address',
    },
    {
      menu_sort: false,
      path: '/club_edit/tel',
      label: '電話番号変更',
      name: 'club_edit_tel',
    },
    {
      menu_sort: false,
      path: '/club_edit/license_event',
      label: '活動種目変更変更',
      name: 'club_edit_license_event',
    },
    {
      menu_sort: false,
      path: '/club_edit/event',
      label: '活動種目変更',
      name: 'club_edit_event',
    },
    {
      menu_sort: false,
      path: '/club_edit/website',
      label: 'WebSite変更',
      name: 'club_edit_website',
    },
    {
      menu_sort: false,
      path: '/club_edit/name',
      label: '名称変更',
      name: 'club_edit_name',
    },
    {
      menu_sort: false,
      path: '/club_edit/named',
      label: '代表者・責任者変更',
      name: 'club_edit_named',
    },
    {
      menu_sort: false,
      path: '/club_edit/photo',
      label: '画像変更',
      name: 'club_edit_photo',
    },
    {
      menu_sort: false,
      path: '/club_edit/shipping',
      label: '配送先変更',
      name: 'club_edit_shipping',
    },
    {
      menu_sort: false,
      path: '/login',
      label: 'ライセンス会員',
      name: 'login',
      style_class: 'bg_mfj_red text-white',
    },
    {
      menu_sort: false,
      path: '/ologin',
      label: '主催者会員',
      name: 'clogin',
      style_class: 'bg_mfj_blue text-white',
    },
    {
      menu_sort: 99,
      path: '/clogout',
      label: 'ログアウト',
      name: 'clogout',
      style_class: 'bg_mfj_gray_01',
    },

    {
      menu_sort: false,
      path: '/clogin',
      label: 'ログイン',
      name: 'clogin',
    },
  ],

  YESORNO_TYPE_LIST: [
    {
      label: 'なし',
      key: '0',
    },
    {
      label: 'あり',
      key: '1',
    },
  ],
  REQUIRE_TYPE_LIST: [
    {
      label: '必須ではない',
      key: '0',
    },
    {
      label: '必須',
      key: '1',
    },
  ],
  DISCOUNT_TYPE: {
    1: {
      label: '割引',
      key: '1',
    },
    2: {
      label: '値引',
      key: '2',
    },
  },
  BASIC_STAT_LIST: [
    // 0: {
    //     value: 0,
    //     label: '非表示'
    // },
    {
      value: 1,
      label: '表示',
    },
    {
      value: 9,
      label: '削除済',
    },
  ],
  PREF_CD_LIST: [
    {
      label: '北海道',
      key: '01',
    },
    {
      label: '青森県',
      key: '02',
    },
    {
      label: '岩手県',
      key: '03',
    },
    {
      label: '宮城県',
      key: '04',
    },
    {
      label: '秋田県',
      key: '05',
    },
    {
      label: '山形県',
      key: '06',
    },
    {
      label: '福島県',
      key: '07',
    },
    {
      label: '茨城県',
      key: '08',
    },
    {
      label: '栃木県',
      key: '09',
    },
    {
      label: '群馬県',
      key: '10',
    },
    {
      label: '埼玉県',
      key: '11',
    },
    {
      label: '千葉県',
      key: '12',
    },
    {
      label: '東京都',
      key: '13',
    },
    {
      label: '神奈川県',
      key: '14',
    },
    {
      label: '新潟県',
      key: '15',
    },
    {
      label: '富山県',
      key: '16',
    },
    {
      label: '石川県',
      key: '17',
    },
    {
      label: '福井県',
      key: '18',
    },
    {
      label: '山梨県',
      key: '19',
    },
    {
      label: '長野県',
      key: '20',
    },
    {
      label: '岐阜県',
      key: '21',
    },
    {
      label: '静岡県',
      key: '22',
    },
    {
      label: '愛知県',
      key: '23',
    },
    {
      label: '三重県',
      key: '24',
    },
    {
      label: '滋賀県',
      key: '25',
    },
    {
      label: '京都府',
      key: '26',
    },
    {
      label: '大阪府',
      key: '27',
    },
    {
      label: '兵庫県',
      key: '28',
    },
    {
      label: '奈良県',
      key: '29',
    },
    {
      label: '和歌山県',
      key: '30',
    },
    {
      label: '鳥取県',
      key: '31',
    },
    {
      label: '島根県',
      key: '32',
    },
    {
      label: '岡山県',
      key: '33',
    },
    {
      label: '広島県',
      key: '34',
    },
    {
      label: '山口県',
      key: '35',
    },
    {
      label: '徳島県',
      key: '36',
    },
    {
      label: '香川県',
      key: '37',
    },
    {
      label: '愛媛県',
      key: '38',
    },
    {
      label: '高知県',
      key: '39',
    },
    {
      label: '福岡県',
      key: '40',
    },
    {
      label: '佐賀県',
      key: '41',
    },
    {
      label: '長崎県',
      key: '42',
    },
    {
      label: '熊本県',
      key: '43',
    },
    {
      label: '大分県',
      key: '44',
    },
    {
      label: '宮崎県',
      key: '45',
    },
    {
      label: '鹿児島県',
      key: '46',
    },
    {
      label: '沖縄県',
      key: '47',
    },
  ],

  // "1": {
  //     "label": "北海道",
  //     "key": "01"
  // },
  // "2": {
  //     "label": "青森県",
  //     "key": "02"
  // },
  // "3": {
  //     "label": "岩手県",
  //     "key": "03"
  // },
  // "4": {
  //     "label": "宮城県",
  //     "key": "04"
  // },
  // "5": {
  //     "label": "秋田県",
  //     "key": "05"
  // },
  // "6": {
  //     "label": "山形県",
  //     "key": "06"
  // },
  // "7": {
  //     "label": "福島県",
  //     "key": "07"
  // },
  // "8": {
  //     "label": "茨城県",
  //     "key": "08"
  // },
  // "9": {
  //     "label": "栃木県",
  //     "key": "09"
  // },
  // "10": {
  //     "label": "群馬県",
  //     "key": "10"
  // },
  // "11": {
  //     "label": "埼玉県",
  //     "key": "11"
  // },
  // "12": {
  //     "label": "千葉県",
  //     "key": "12"
  // },
  // "13": {
  //     "label": "東京都",
  //     "key": "13"
  // },
  // "14": {
  //     "label": "神奈川県",
  //     "key": "14"
  // },
  // "15": {
  //     "label": "新潟県",
  //     "key": "15"
  // },
  // "16": {
  //     "label": "富山県",
  //     "key": "16"
  // },
  // "17": {
  //     "label": "石川県",
  //     "key": "17"
  // },
  // "18": {
  //     "label": "福井県",
  //     "key": "18"
  // },
  // "19": {
  //     "label": "山梨県",
  //     "key": "19"
  // },
  // "20": {
  //     "label": "長野県",
  //     "key": "20"
  // },
  // "21": {
  //     "label": "岐阜県",
  //     "key": "21"
  // },
  // "22": {
  //     "label": "静岡県",
  //     "key": "22"
  // },
  // "23": {
  //     "label": "愛知県",
  //     "key": "23"
  // },
  // "24": {
  //     "label": "三重県",
  //     "key": "24"
  // },
  // "25": {
  //     "label": "滋賀県",
  //     "key": "25"
  // },
  // "26": {
  //     "label": "京都府",
  //     "key": "26"
  // },
  // "27": {
  //     "label": "大阪府",
  //     "key": "27"
  // },
  // "28": {
  //     "label": "兵庫県",
  //     "key": "28"
  // },
  // "29": {
  //     "label": "奈良県",
  //     "key": "29"
  // },
  // "30": {
  //     "label": "和歌山県",
  //     "key": "30"
  // },
  // "31": {
  //     "label": "鳥取県",
  //     "key": "31"
  // },
  // "32": {
  //     "label": "島根県",
  //     "key": "32"
  // },
  // "33": {
  //     "label": "岡山県",
  //     "key": "33"
  // },
  // "34": {
  //     "label": "広島県",
  //     "key": "34"
  // },
  // "35": {
  //     "label": "山口県",
  //     "key": "35"
  // },
  // "36": {
  //     "label": "徳島県",
  //     "key": "36"
  // },
  // "37": {
  //     "label": "香川県",
  //     "key": "37"
  // },
  // "38": {
  //     "label": "愛媛県",
  //     "key": "38"
  // },
  // "39": {
  //     "label": "高知県",
  //     "key": "39"
  // },
  // "40": {
  //     "label": "福岡県",
  //     "key": "40"
  // },
  // "41": {
  //     "label": "佐賀県",
  //     "key": "41"
  // },
  // "42": {
  //     "label": "長崎県",
  //     "key": "42"
  // },
  // "43": {
  //     "label": "熊本県",
  //     "key": "43"
  // },
  // "44": {
  //     "label": "大分県",
  //     "key": "44"
  // },
  // "45": {
  //     "label": "宮崎県",
  //     "key": "45"
  // },
  // "46": {
  //     "label": "鹿児島県",
  //     "key": "46"
  // },
  // "47": {
  //     "label": "沖縄県",
  //     "key": "47"
  // }
  // }
})

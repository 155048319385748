<template>
  <h2 class="mt-32 mb-32">3Dセキュアの処理を実行しています...</h2>
  <p>戻るなどの操作は控えて下さい</p>
</template>


<script>
    import { usePaymentPage } from '@ootbteam/mulpay-pinia'
    import axios from 'axios'
    import config from '../../../const'

    // const { handlePaymentPageProps, capturePayment } = usePaymentPage()

export default {
    name: 'request_3ds',
    setup() {
      const {
        handlePaymentPageProps, capturePayment,
      } = usePaymentPage()

      return {
        handlePaymentPageProps,
        capturePayment,
      }
    },
    mounted: async function () {
      const accessId = localStorage.getItem('accessId')
      const formTokenId = localStorage.getItem('formTokenId')
      const formSalesId = localStorage.getItem('formSalesId')
      // const formPayload = localStorage.getItem('formPayload')
      // console.log('formSalesId', formSalesId);
      // MpTokenに必要な情報を設定
      await this.handlePaymentPageProps({
            instance: axios.create({
            baseURL: config.BASE_API_URL,
            headers: {
            'Content-Type': 'application/json',
            },
        }),
        apiKey: config.GMO_MP_TOKEN_API_KEY_MFJ,
        pubKey: config.GMO_MP_TOKEN_PUB_KEY_MFJ,
        productionMode: config.GMO_SHOP_OP_MODE,
      })
      await this.capturePayment(accessId, async (resp) => {
        const { orderReference, creditResult, tds2Result } = resp
        // console.log('capturePayment orderReference', orderReference)
        // console.log('capturePayment creditResult', creditResult)

        const { status } = orderReference
        const { tds2TransResult } = tds2Result

        if (tds2TransResult !== "Y" && tds2TransResult !== "A") {
          alert("3Dセキュア認証できませんでした。決済は失敗しました。");
          location.href = config.BASE_APP_URL + `/request/license`
          return
        } else if (status !== "CAPTURE") {
          alert("決済は完了していません。再試行してください。");
          location.href = config.BASE_APP_URL + `/request/license`;
          return
        }
        // if (tds2TransResult !== 'Y' || status !== 'CAPTURE') {
        // // if(status !== 'CAPTURE'){
        //   alert('3DS認証できませんでした。決済は失敗しました。')
        //   location.href = config.BASE_APP_URL + `/request/license`
        //   return
        // }

        // 申請データを更新
        const app_url_path =
          formTokenId > 0
            ? '/member_application/edit/' + formTokenId
            : '/member_application/add'
  
        const app_params = {
          application_check_type: 423,
        }
        // 更新
        const app_chk = await this.basicPosts_I(
          config.BASE_API_URL + app_url_path,
          app_params,
        )
        console.log('capturePayment resp', resp)
  
        // 売上データを更新
        const sales_url_path =
          formSalesId > 0
            ? '/sales/charge_finish/' + formSalesId
            : '/sales/add'
        const sales_params = {
          sales_type: 'request',
          charge_type: orderReference.chargeType,
          payment_value: orderReference.amount,
          sales_date: 'now()',
          charge_result: JSON.stringify(resp),
          charge_access_id: orderReference.accessId,
          charge_transaction_id: creditResult.nwTransactionId,
          charge_forward: creditResult.forwardedAcquirerCode,
        }
  
        const chk = (app_chk)
          ? await this.basicPosts_I(
            config.BASE_API_URL + sales_url_path,
            sales_params
          )
          : false
  
        if(chk){
          alert('認証しました。承認処理を進めます。ページが自動で移動します。OKを押して下さい。')
          // location.href = `/mfj_renewal/app/request/license`
        }else if(app_chk) {
          alert('決済処理が失敗しました。決済のみ完了している可能性があります。')
          // location.href = `/mfj_renewal/app/request/license`
        }else{
          alert('決済が失敗しました。')
          // location.href = `/mfj_renewal/app/request/license`
        }
        location.href = config.BASE_APP_URL + `/request/license`
      })
      .then(() => {
        
      })
      .catch(async (err) => {
        console.log('capture err', err)
        alert('決済が失敗しました。')
        const app_params = {
          application_check_type: 403,
        }
        const app_url_path =
          formTokenId > 0
            ? '/member_application/edit/' + formTokenId
            : '/member_application/add'
        const app_chk = this.basicPosts_I(
          config.BASE_API_URL + app_url_path,
          app_params,
        )
        console.log('capture app_chk', app_chk)
        location.href = config.BASE_APP_URL + `/request/license`
        // location.href = `/mfj_renewal/app/request/license`
        // console.log('globalActionsTest basicPosts err', err);
        throw err
      })
    },
    unmounted() {
      localStorage.removeItem('accessId')
      localStorage.removeItem('formTokenId')
      localStorage.removeItem('formSalesId')
    },
}
</script>

<template>
  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1">
          <div class="container mb-2 py-3">
            <h5 class="title">公認クラブ参加承諾</h5>
          </div>
          <div class="container mb-3 py-3">
            <p class="box">
              {{ message }}
            </p>
          </div>
          <div class="container mb-5">
            <div class="row mb-2">
              <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                <div class="page__inputGroupCol col-md-12">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel">公認クラブ名</label>
                    <span class="showData page__inputGroupInput">
                      {{ club_obj.name_1 }}
                    </span>
                  </p>
                </div>
                <div class="page__inputGroupCol col-md-12">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel">代表者</label>
                    <span class="showData page__inputGroupInput">
                      {{ get_club_d_name }}
                    </span>
                  </p>
                </div>
                <div class="page__inputGroupCol col-md-12">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel">連絡先電話番号</label>
                    <span class="showData page__inputGroupInput">
                      {{ get_club_tel }}
                    </span>
                  </p>
                </div>
                <div class="page__inputGroupCol col-md-12" v-if="club_obj.mail_1 || club_obj.mail_2">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel">連絡先メール</label>
                    <span class="showData page__inputGroupInput" v-if="club_obj.mail_1">
                      {{ club_obj.mail_1 }}
                    </span>
                    <span class="showData page__inputGroupInput" v-else-if="club_obj.mail_2">
                      {{ club_obj.mail_2 }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <template v-if="m_relation_obj?.stat==0 && m_relation_obj?.reject_flg==0">
            <div class="container mb-32">
              <div class="row mb-3">
                <div class="col-10 offset-1 col-sm-6 offset-sm-3">
                  <div class="d-grid">
                    <button class="button02" @click="join_club">
                      参加承諾する
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="container mb-32">
              <div class="row mb-3">
                <div class="col-10 offset-1 col-sm-6 offset-sm-3">
                </div>
              </div>
            </div>
            <div class="container mb-32">
              <div class="row mb-3">
                <div class="col-10 offset-1 col-sm-6 offset-sm-3">
                  <p class="box">
                    参加を辞退する場合は下の辞退するボタンを押して下さい
                  </p>
                </div>
              </div>
            </div>
            <div class="container mb-32">
              <div class="row mb-3">
                <div class="col-10 offset-1 col-sm-6 offset-sm-3">
                  <div class="d-grid">
                    <button class="button03" @click="reject_club">
                      参加を辞退する
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.375rem;
    border-bottom: solid 1px #999;
  }
  .form-control-plaintext {
    border-bottom: solid 1px #999;
  }
</style>
<script>
  import { mapState } from 'vuex'
  import config from '../../../const'

  export default {
    name: 'UserDataView',
    data: function () {
      return {
        targetid: this.$store.state.auth.userId,
        targetcid: 0,
        btnLabel: '',
        data_obj: {},
        club_obj: {},
        m_relation_obj: {},
        message:
          '招待された公認クラブに参加する場合は、承認ボタンを押してください。辞退する場合は辞退を押してください。',
      }
    },
    components: {},
    methods: {
      async join_club() {
        const params = { ...this.m_relation_obj }
        params['stat'] = 1
        params['join'] = 1
        await this.basicPosts_I(
          config.BASE_API_URL +
            '/club_m_relation/join_edit/' +
            this.m_relation_obj.id,
          params
        )
          .then(async (response) => {
            console.log('update', response)
            if (response && response.data && response.data.status == '200') {
              this.message = '参加承諾しました'
            } else {
              this.message = '参加できませんでした'
            }
          })
          .catch(async (err) => {
            console.log(err)
            this.message = '参加できませんでした'
          })
          .finally(async () => {
            this.$forceUpdate()
            return true
          })
      },
      async reject_club() {
        const params = { ...this.m_relation_obj }
        params['reject_flg'] = 1
        await this.basicPosts_I(
          config.BASE_API_URL +
            '/club_m_relation/reject_edit/' +
            this.m_relation_obj.id,
          params
        )
          .then(async (response) => {
            console.log('update', response)
          })
          .catch(async (err) => {
            console.log(err)
          })
          .finally(async () => {
            this.$forceUpdate()
            return true
          })
      },
      async loadUserObj() {
        const params = {}
        params['stat'] = [1]
        params['id'] = this.$store.state.auth.userId
        params['pagegroup'] = 'mfj_member_data.id'
        await this.basicPosts_S(config.BASE_API_URL + '/member/lists', params)
          .then(async (response) => {
            console.log('loadUserObj', response)
            this.data_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = {}
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadClubObj() {
        const params = {}
        params['stat'] = [1]
        params['id'] = this.targetcid
        console.log('loadClubRelationObj params', params)
        await this.basicPosts_S(config.BASE_API_URL + '/club/lists', params)
          .then(async (response) => {
            console.log('loadClubRelationObj', response)
            this.club_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.club_obj = {}
          })
          .finally(async () => {
            console.log('loadClubRelationObj finally')
            this.$forceUpdate()
          })
      },
      async loadClubRelationObj() {
        const params = {}
        params['stat'] = [0, 1]
        // params['reject_flg'] = 0
        params['rel_token'] = this.$route.query.tkn
        console.log('loadClubRelationObj params', params)
        await this.basicPosts_S(
          config.BASE_API_URL + '/club_m_relation/lists',
          params
        )
          .then(async (response) => {
            console.log('loadClubRelationObj', response)
            this.m_relation_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
            this.targetcid = this.m_relation_obj.c_id
          })
          .catch(async (err) => {
            console.log(err)
            this.m_relation_obj = {}
          })
          .finally(async () => {
            console.log('loadClubRelationObj finally')
            if(
              this.m_relation_obj?.stat==0 && this.m_relation_obj?.reject_flg ==1
            ){
              this.message = '参加辞退した招待です。'
            }
            else if(
              this.m_relation_obj?.stat==1 && this.m_relation_obj?.reject_flg == 0
            ){
              this.message = '参加承諾済の招待です。'
            }
            this.$forceUpdate()
          })
      },
      back_input() {
        this.change_mode = false
      },
      back() {
        this.$router.push({ path: '/' })
      },
    },
    computed: {
      ...mapState({}),
      get_club_d_name: function () {
        let d_name = ''
        if (this.club_obj.name_2) {
          d_name += this.club_obj.name_2
        }
        if (this.club_obj.name_3) {
          d_name += this.club_obj.name_3
        }
        return d_name
      },
      get_club_tel: function () {
        if (this.club_obj.tel_1 == '--') {
          return ''
        }
        return this.club_obj.tel_1
      },
    },
    async created() {
      await this.loadUserObj()
      await this.loadClubRelationObj()
      await this.loadClubObj()
    },
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
    props: {},
  }
</script>

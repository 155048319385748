<template>
  <section class="page">
    <div class="container">
      <h2 class="title page__h1">活動種目管理</h2>
      <div class="box box--center mb-24" v-if="(this.application_obj || {}).application_check_type == 100">
        <p>変更申請中です</p>
      </div>
      <div v-else class="box box--center mb-24">
        <p>{{ message }}</p>
      </div>
<!-- {{ application_obj }} -->
      <div class="row">
        <div class="col-12">
          <h4 class="title08 d-block mb-24">種目一覧</h4>
          <div class="event-grid">
            <div v-for="event in event_list" :key="event" class="event-grid__item">
              <button
                :class="['button-event', event_class(event.id)]"
                @click="toggleEvent(event.id)"
                :disabled="chkSelectedLimit(event.id)"
              >
                {{ event.label }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex mt-32 justify-content-center column-gap-24">
        <button type="button" @click="back_data" class="button03">
          <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
          <span class="button03__text">戻る</span>
        </button>

        <button v-if="(this.application_obj || {}).application_check_type == 100" type="button" class="button04" @click="cancel">
          <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
          <span class="button04__text">変更を取りやめる</span>
        </button>
        <button v-else type="button" @click="update_app" class="button04">
          <span class="button04__text">申請する</span>
          <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
        </button>
      </div>
    </div>
  </section>
</template>

<style scoped>
.event-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 32px;
}

.event-grid__item {
  width: 100%;
}

.button-event {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #dee2e6;
  background: #fff;
  cursor: pointer;
  transition: all 0.2s;
}

.button-event.btn-warning {
  background: #ffc107;
  border-color: #ffc107;
  color: #000;
}

.button-event.btn-light {
  background: #f8f9fa;
  border-color: #dee2e6;
  color: #6c757d;
}

.button-event:hover {
  opacity: 0.8;
}

.button-event:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>


<script>
  import { mapState } from 'vuex'
  // import BsPageTitle from '../../molecules/BsPageTitle.vue'
  // import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
  // import BsSelect from '../../atoms/BsSelect.vue'
  // import BsCheckBox from '../../atoms/BsCheckBox.vue'
  // import '../../assets/bootstrap/css/sc.css';
  // import BsSelect from '../../atoms/BsSelect.vue'
  import config from '../../../const'

  export default {
    name: 'ClubEventView',
    data: function () {
      return {
        targetid: this.$store.state.cauth.userId,
        btnLabel: '',
        message:
          '主活動種目を1種目は必ず選択して下さい。',
        data_obj: {},
        event_form_obj: {
          id: 0,
          stat: 1,
          c_id: this.$store.state.cauth.userId,
          event_id: 0,
          event_cd: '',
        },
        event_relation_obj: [],
        event_cd_obj: [],
        term_year: '',
        application_obj: {},
      }
    },
    components: {
      // BsSelect,
      //   BsMsgAlert,
      // BsCheckBox,
    },
    methods: {
      async cancel() {
        if (window.confirm('変更を取りやめます。よろしいですか？')) {
          const post_url_path =
            this.application_obj.id > 0
              ? '/club_application/edit/' + this.application_obj.id
              : '/club_application/add'
          const params = {
            c_id: this.$store.state.cauth.userId,
            stat: 9,
          }
          await this.basicPosts_I(config.BASE_API_URL + post_url_path, params)
            .then(async (response) => {
              console.log('update', response)
            })
            .catch(async (err) => {
              console.log(err)
            })
            .finally(async () => {
              await this.loadApplicationObj()
              this.change_mode_value = false
              this.message = '変更申請をキャンセルしました'
              this.$forceUpdate()
            })
        }
      },
      async toggleEvent(event_id) {
        const tmps = [...this.event_relation_obj]
        if (this.event_relation_obj.find((obj) => obj.event_id == event_id)) {
          const index = this.event_relation_obj.findIndex(
            (obj) => obj.event_id == event_id
          )
          tmps.splice(index, 1)
        } else {
          
        const now_count = Array.isArray(this.event_relation_obj)
          ? this.event_relation_obj.length: 0

          if(now_count >= 2){
            alert('2件まで申請できます')
            return
          }

          const tmp = { ...this.event_form_obj }
          tmp.event_id = event_id
          tmp.event_cd = this.event_list.find((list) => list.id == event_id)
            ? this.event_list.find((list) => list.id == event_id).event_cd
            : ''
          tmps.push(tmp)
        }
        this.event_relation_obj = tmps
      },

      async loadApplicationObj() {
        const params = {}
        params['stat'] = [1]
        params['c_id'] = this.$store.state.cauth.userId
        params['application_type'] = 23100
        params['not_application_check_types'] = [500, 900]
        await this.basicPosts_S(
          config.BASE_API_URL + '/club_application/lists',
          params
        )
          .then(async (response) => {
            console.log('loadApplicationObj', response)
            this.application_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.application_obj = {}
          })
          .finally(async () => {
            await this.setApplicationObj()
            this.$forceUpdate()
          })
      },
      async setApplicationObj() {
        const tmps =
          typeof this.application_obj === 'object' &&
            Object.keys(this.application_obj).length > 0
            ? this.application_obj
            : {
              id: 0,
              application_check_type: '',
              application_type: '',
              application_date: '',
            }
        this.application_obj = tmps

        this.app_form_obj = {
          event_relation: (this.get_target_app_item('mfj_outdb_data@event_relation') || {})
            .application_value
            ? (this.get_target_app_item('mfj_outdb_data@event_relation') || {})
              .application_value
            : '',
        }
      },
      async update_app(){

        const post_url_path =
        this.application_obj.id > 0
          ? '/club_application/edit/' + this.application_obj.id
          : '/club_application/add'

      const params = {
        application_check_type: 100,
        application_type: 23100,
        c_id: this.$store.state.cauth.userId,
        // application_type: this.upd_obj.application_check_type,
        // application_check_type: this.upd_obj.application_check_type,
        // items: [],
      }

      const item_column = {
        event_relation: 'mfj_outdb_data@event_relation'
      }
      const event_relation = []
      this.event_relation_obj.map(relation => {
        event_relation.push(relation.event_id)
      })

      const items = []
      Object.keys(item_column).forEach((item_key) => {
        items.push({
          id: this.get_target_app_item(item_column[item_key])
            ? (this.get_target_app_item(item_column[item_key]) || {}).id
            : 0,
          application_id: this.application_obj.id,
          application_key: item_column[item_key],
          application_value: JSON.stringify(event_relation),
          // application_value: JSON.stringify(this.app_form_obj[item_key]),
        })
      })
      params.items =
        typeof items === 'object' && items.length > 0 ? items : false
      await this.basicPosts_I(config.BASE_API_URL + post_url_path, params)
        .then(async (response) => {
          console.log('update', response)
        })
        .catch(async (err) => {
          console.log(err)
        })
        .finally(async () => {
          await this.loadApplicationObj()
          this.change_mode_value = false
          this.message = '申請しました'
          this.$forceUpdate()
        })

      },
      async update() {
        const params = {
          event_relation: this.event_relation_obj,
        }
        await this.basicPosts_I(
          config.BASE_API_URL + '/club/edit/' + this.$store.state.cauth.userId,
          params
        )
          .then(async (response) => {
            console.log('update', response)
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            await this.loadEventRelationObj()
            this.message = '更新しました'
            this.$forceUpdate()
          })
        // params['c_id'] = this.$store.state.cauth.userId;
      },
      async loadConfigObj(param) {
        const params = {}
        await this.basicPosts_S(
          config.BASE_API_URL + '/config/' + param,
          params
        )
          .then(async (response) => {
            console.log('loadConfigObj response', response)
            this[param + '_obj'] =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this[param + '_obj'] = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadClubObj() {
        const params = {}
        params['stat'] = [1]
        params['id'] = this.$store.state.cauth.userId
        await this.basicPosts_S(config.BASE_API_URL + '/club/lists', params)
          .then(async (response) => {
            console.log('loadClubObj', response)
            this.data_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            // await this.setClubObj();
            this.$forceUpdate()
          })
      },
      async loadEventCdObj() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(config.BASE_API_URL + '/event_cd/lists', params)
          .then(async (response) => {
            console.log('loadEventCdObj', response)
            this.event_cd_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.event_cd_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadEventRelationObj() {
        const params = {}
        params['stat'] = [1]
        params['event_year'] = this.term_year
        params['c_id'] = this.$store.state.cauth.userId
        console.log('loadEventRelationObj params', params)
        await this.basicPosts_S(
          config.BASE_API_URL + '/club_event_relation/lists',
          params
        )
          .then(async (response) => {
            console.log('loadEventRelationObj', response)
            this.event_relation_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.application_obj = []
          })
          .finally(async () => {
            // await this.setClubObj();
            this.$forceUpdate()
          })
      },
      back_input() {
        this.change_mode = false
      },
      back() {
        this.$router.push({ path: '/' })
      },
      back_data() {
        this.$router.push({ path: '/club_edit/data' })
      },
    },
    computed: {
      get_target_app_item: function () {
        const application = this.application_obj
        return function (app_key) {
          if (
            typeof application === 'object' &&
            typeof application.items === 'object'
          ) {
            return application.items.find(
              (app) => app.application_key == app_key
            )
          }
        }
      },
      ...mapState({}),
      chkSelectedLimit: function () {
        const relation = this.event_relation_obj
        return function (event_id) {
          if (
            typeof relation === 'object' &&
            !relation.find((obj) => obj.event_id == event_id) &&
            relation.length >= 2
          ) {
            return false
          }
          return false
        }
      },
      event_class: function () {
        const relation = this.event_relation_obj
        return function (event_id) {
          if (relation.find((obj) => obj.event_id == event_id)) {
            return 'btn btn-warning'
          } else {
            return 'btn btn-light btn-outline-secondary'
          }
        }
      },
      event_list: function () {
        const lists = [...this.event_cd_obj]
        return lists.sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },
      m_relation_list: function () {
        const m_relation_obj = this.m_relation_obj
        return m_relation_obj
          .sort((a, b) => {
            if (parseInt(a.sort) < parseInt(b.sort)) {
              return -1
            } else {
              return 1
            }
          })
          .map((rel) => {
            rel.search_sec_cd = ''
            return rel
          })
        // return this.m_relation_obj;
      },
      chk_m_rel: function () {
        const m_relation_list = this.m_relation_list
        return function (index) {
          if (
            typeof m_relation_list === 'object' &&
            typeof m_relation_list[index] === 'object'
          ) {
            return m_relation_list[index]
          }
          return false
        }
      },
      chk_m_rel_m_id: function () {
        const chk_m_rel = this.chk_m_rel
        return function (index) {
          if (chk_m_rel(index)) {
            return chk_m_rel(index).m_id
          }
          return false
        }
      },
      input_class_no: function () {
        const chk_m_rel_m_id = this.chk_m_rel_m_id
        return function (index) {
          if (chk_m_rel_m_id(index) == 0) {
            return 'form-control'
          } else {
            return 'form-control-plaintext'
          }
        }
      },
      input_class: function () {
        const chk_m_rel_m_id = this.chk_m_rel_m_id
        return function (index) {
          if (chk_m_rel_m_id(index) == 0) {
            return 'form-control-plaintext'
          } else {
            return 'form-control-plaintext'
          }
        }
      },
      input_readonly_no: function () {
        const chk_m_rel_m_id = this.chk_m_rel_m_id
        return function (index) {
          if (chk_m_rel_m_id(index) == 0) {
            return false
          } else {
            return true
          }
        }
      },
      input_readonly: function () {
        const chk_m_rel_m_id = this.chk_m_rel_m_id
        return function (index) {
          if (chk_m_rel_m_id(index) == 0) {
            return true
          } else {
            return true
          }
        }
      },
    },
    async created() {
      
      try {
        // 年度情報の取得
        const termYearResponse = await this.nonloginPosts_S(
          `${config.BASE_API_URL}/term_year/lits`,
          {}
        )
        if (termYearResponse.data?.status === 200) {
          this.term_year = termYearResponse.data.body[0]?.label || ''
        }
        await this.loadEventCdObj()
        await this.loadClubObj()
        await this.loadEventRelationObj()
        await this.setApplicationObj()
        await this.loadApplicationObj()
      } catch (error) {
        console.error('データ取得エラー:', error)
        this.handleError(error)
      }
      
    },
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
    props: {},
  }
</script>

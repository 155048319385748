<template>
  <div class="box login__error" v-if="show">
    <FontAwesomeIcon :icon="['fas', 'circle-exclamation']" />
    <p>{{ message }}</p>
  </div>
</template>

<script>
  export default {
    name: 'BsMessageBlock',
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      message: {
        type: String,
        default: 'エラーが発生しました。',
      },
    },
  }
</script>

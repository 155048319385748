import * as types from './mutation-types'

export default {
  [types.AUTH_LOGIN](state, payload) {
    console.log('AUTH_LOGIN', payload)
    state.auth = payload
  },
  [types.AUTH_LOGOUT](state, payload) {
    state.auth = payload
    state.auth = ''
  },

  [types.OAUTH_LOGIN](state, payload) {
    console.log('OAUTH_LOGIN', payload)
    state.oauth = payload
  },
  [types.OAUTH_LOGOUT](state, payload) {
    state.oauth = payload
    state.oauth = ''
  },
  [types.CAUTH_LOGIN](state, payload) {
    console.log('OAUTH_LOGIN', payload)
    state.cauth = payload
  },
  [types.CAUTH_LOGOUT](state, payload) {
    state.cauth = payload
    state.cauth = ''
  },

  [types.FETCH_ALL_SEARCHPARAM](state, payload) {
    console.log('loadSearchParam FETCH_ALL_SEARCHPARAM payload', payload)
    if (payload) {
      // const prmObj = {}
      state.apidata.search_param[payload[1]] = null
      let payloadVal = ''
      if (typeof payload[0] === 'object') {
        const payloadObj = {}
        Object.keys(payload[0]).forEach((key) => {
          if (typeof payload[0][key] === 'object') {
            payloadObj[key] = JSON.stringify(payload[0][key])
          } else {
            payloadObj[key] = payload[0][key]
          }
        })
        // const tmp = {};
        // Object.keys(payload[0]).forEach(key => {
        //   tmp[key] = payload[0][key];
        // })
        payloadVal = payloadObj
      } else {
        payloadVal = payload[0]
      }
      state.apidata.search_param[payload[1]] = payloadVal
      // Object.keys(payload).forEach((key) => {
      //   // prmObj[key] = payload[key];
      //   state.apidata.search_param[payload[0]] = null;
      //   // state.apidata.search_param[key] = prmObj[key];
      //   // state.apidata.search_param[key] = payload[key];
      // });
    }
    console.log(
      'loadSearchParam FETCH_ALL_SEARCHPARAM payload add ',
      state.apidata.search_param
    )
  },

  [types.FETCH_ALL_PAGER](state, payload) {
    console.log('FETCH_ALL_PAGER payload', payload)
    if (payload) {
      Object.keys(payload).forEach((key) => {
        state.apidata.pager[key] = payload[key]
      })
    }
    // state.apidata.pager[payload[1]] = payload[0]
  },

  [types.FETCH_ALL_BASICLIST](state, payload) {
    console.log('FETCH_ALL_BASICLIST payload', payload)
    // console.log('FETCH_ALL_BASICLIST target', target)
    if (
      typeof payload === 'object' &&
      typeof payload[0] === 'object' &&
      Object.keys(payload[0]).length > 0
    ) {
      state.apidata[payload[1]] = payload[0]
    } else {
      state.apidata[payload[1]] = []
    }
  },
  [types.UPDATE_BASIC](state, payload) {
    const data = payload['data']
    const target = payload['target']
    console.log('UPDATE_BASIC', state.apidata[target], payload)
    if (state.apidata[target]) {
      for (let i = 0; i < state.apidata[target].length; i++) {
        const list = state.apidata[target][i]
        console.log('target_list list list', list, list.id, data)
        if (list.id !== data.id) {
          continue
        }

        Object.keys(data).forEach((key) => {
          list[key] = data[key]
        })
        // list.code = target.data.code
        // list.label = target.data.label
        // list.list_price = target.data.list_price
        // list.purchase_price = target.data.purchase_price
        console.log('state.basic.lists[i]', state.apidata[target], list)

        state.apidata[target][i] = list
        break
      }
    }
  },
}


<template>
  <div class="mb-40">
    <!-- <h3 class="title02 page__h2">詳細内容</h3> -->
    <details class="accordion" open="open" href="">
      <summary class="accordion__button">
        年会費内訳
        <font-awesome-icon
          :icon="['fas', 'circle-chevron-down']"
          class="circle-chevron-down fa-solid fa-chevron-right"
        />
      </summary>
      <div class="accordion__contents">
        <table class="page__table">
          <tbody>
            <tr>
              <th class="page__tableTh">お支払い項目</th>
              <th class="page__tableTh d-none d-md-table-cell">お支払い額</th>
            </tr>
            <template v-for="year_add in selected_year_add" :key="year_add">
              <template
                v-for="variant in get_variant_from_category_and_year(1, year_add)"
                :key="variant"
              >
                <tr>
                  <template
                    v-if="chk_variant_from_category_and_year(1, year_add)"
                  >
                    <td class="page__tableTd">
                      【{{ variant.sports_year }}年度】競技ライセンス {{ variant.sub_label }}
                    </td>
                  </template>
                  <td class="page__tableTd text-center">
                    {{ number_format(variant.price) }}円
                  </td>
                </tr>
              </template>
              <template
                v-for="variant in get_variant_from_category_and_year(2, year_add)"
                :key="variant"
              >
                <tr>
                  <template
                    v-if="chk_variant_from_category_and_year(2, year_add)"
                  >
                    <td class="page__tableTd">
                      【{{ variant.sports_year }}年度】ピットクルーライセンス {{ variant.sub_label }}
                    </td>
                  </template>
                  <td class="page__tableTd text-center">
                    {{ number_format(variant.price) }}円
                  </td>
                </tr>
              </template>
              <template
                v-for="variant in get_variant_from_category_and_year(4, year_add)"
                :key="variant"
              >
                <tr>
                  <template
                    v-if="chk_variant_from_category_and_year(4, year_add)"
                  >
                    <td class="page__tableTd">
                      【{{ variant.sports_year }}年度】競技役員 {{ variant.sub_label }}
                    </td>
                  </template>
                  <td class="page__tableTd text-center">
                    {{ number_format(variant.price) }}円
                  </td>
                </tr>
              </template>
              <template
                v-for="variant in get_variant_from_category_and_year(5, year_add)"
                :key="variant"
              >
                <tr>
                  <template
                    v-if="chk_variant_from_category_and_year(5, year_add)"
                  >
                    <td class="page__tableTd">【{{ variant.sports_year }}年度】講師 {{ variant.sub_label }}</td>
                  </template>
                  <td class="page__tableTd text-center">
                    {{ number_format(variant.price) }}円
                  </td>
                </tr>
              </template>
              <template
                v-for="variant in get_variant_from_category_and_year(3, year_add)"
                :key="variant"
              >
                <tr>
                  <template
                    v-if="chk_variant_from_category_and_year(3, year_add)"
                  >
                    <td class="page__tableTd">
                      【{{ variant.sports_year }}年度】エンジョイ {{ variant.sub_label }}
                    </td>
                  </template>
                  <td class="page__tableTd text-center">
                    {{ number_format(variant.price) }}円
                  </td>
                </tr>
              </template>
              <template
                v-for="variant in get_variant_from_article_category_and_year(
                  1,
                  year_add
                )"
                :key="variant"
              >
                <tr>
                  <template
                    v-if="chk_variant_from_article_category_and_year(1, year_add)"
                  >
                    <td class="page__tableTd">
                      【{{ variant.sports_year }}年度】{{ variant.sub_label }}
                    </td>
                  </template>
                  <td class="page__tableTd text-center">
                    {{ number_format(variant.price) }}円
                  </td>
                </tr>
              </template>
              <template
                v-for="variant in get_variant_from_article_category_and_year(
                  2,
                  year_add
                )"
                :key="variant"
              >
                <tr>
                  <template
                    v-if="chk_variant_from_article_category_and_year(2, year_add)"
                  >
                    <td class="page__tableTd">
                      【{{ variant.sports_year }}年度】{{ variant.sub_label }}
                    </td>
                  </template>
                  <td class="page__tableTd text-center">
                    {{ number_format(variant.price) }}円
                  </td>
                </tr>
              </template>
            </template>
            <tr>
              <td class="page__tableTd page__tableTd--bold">合計</td>
              <td class="page__tableTd text-center page__tableTd--bold">
                {{ number_format(total_price) }}円
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </details>
  </div>
</template>

<style scoped></style>
<script>
  import { mapState } from 'vuex'

  import Common from '../../../common/common'

  export default {
    name: 'RegistCartView',
    data: function () {
      return {
        category_param: [1, 2, 3, 4, 5],
      }
    },
    components: {},
    methods: {},
    props: {
      wrap_style: {
        type: String,
        default: 'position:sticky;bottom:0;z-index:99;',
      },
      race_sports_year: {
        type: Array,
        default: () => [],
      },

      draft_orders: {
        type: Array,
        default: () => [],
      },
      selected_variants: {
        type: Array,
        default: () => [],
      },
      step_mode: {
        type: String,
        default: 'kiyaku',
      },
    },
    computed: {
      ...mapState({}),
      get_now_year: function () {
        return Common.getNowYear()
      },
      get_variants_calc: function(){
        if(
          typeof this.draft_orders === 'object' && this.draft_orders.length > 0
        ){
          return this.selected_variants.map(variant => {
            if(
              this.draft_orders.find(order => order.variant_id == variant.variant_id)
            ){
              variant.price = this.draft_orders.find(order => order.variant_id == variant.variant_id).list_price
            }
            return variant
          })
        }
        return this.selected_variants
      },
      get_list_label: function () {
        return function (key, lists) {
          if (
            typeof lists === 'object' &&
            typeof lists.find((list) => list.key == key) === 'object'
          ) {
            return lists.find((list) => list.key == key).label
          }
          return key
        }
      },
      get_list_key: function () {
        return function (label, lists) {
          if (
            typeof lists === 'object' &&
            typeof lists.find((list) => list.label == label) === 'object'
          ) {
            return lists.find((list) => list.label == label).key
          }
          return label
        }
      },
      total_price: function () {
        let price = 0
        if (typeof this.selected_year_add === 'object') {
          this.selected_year_add.forEach((year_add) => {
            for (let i = 1; i <= 5; i++) {
              if (
                typeof this.get_variant_from_category_and_year(i, year_add) ===
                'object'
              ) {
                this.get_variant_from_category_and_year(i, year_add).forEach(
                  (variant) => {
                    price += parseInt(variant.price)
                  }
                )
              }
            }
            [1, 2].forEach((i) => {
              if (
                typeof this.get_variant_from_article_category_and_year(
                  i,
                  year_add
                ) === 'object'
              ) {
                this.get_variant_from_article_category_and_year(
                  i,
                  year_add
                ).forEach((variant) => {
                  price += parseInt(variant.price)
                })
              }
            })
          })
        }
        return price
      },
      get_selected_variants_commission: function () {
        const lists = [...this.selected_variants_commission]
        if (typeof lists === 'object' && lists.length > 0) {
          return lists.sort((a, b) => {
            if (parseInt(a.sports_year) < parseInt(b.sports_year)) {
              return -1
            } else {
              return 1
            }
          })
        } else {
          return false
        }
      },
      chk_variant_from_article_category_and_year: function () {
        const get_func = this.get_variant_from_article_category_and_year
        return function (category_id, year_add) {
          const get = get_func(category_id, year_add)
          if (typeof get === 'object' && get.length > 0) {
            return true
          }
          return false
        }
      },
      get_variant_from_article_category_and_year: function () {
        const selected_variants = this.selected_variants
        return function (category_id, year_add) {
          return selected_variants.filter((sel_vari) => {
            if (
              sel_vari.category == category_id &&
              sel_vari.sports_year_add == year_add
            ) {
              return true
            }
            return false
          })
        }
      },
      chk_variant_from_category_and_year: function () {
        const get_func = this.get_variant_from_category_and_year
        return function (category_id, year_add) {
          const get = get_func(category_id, year_add)
          if (typeof get === 'object' && get.length > 0) {
            return true
          }
          return false
        }
      },
      get_variant_from_category_and_year: function () {
        const selected_variants = this.selected_variants
        return function (category_id, year_add) {
          return selected_variants.filter((sel_vari) => {
            if (
              sel_vari.license_category == category_id &&
              sel_vari.sports_year_add == year_add
            ) {
              return true
            }
            return false
          })
        }
      },
      get_variant_from_category: function () {
        const selected_variants_license_category =
          this.selected_variants_license_category
        return function (category_id) {
          if (
            (selected_variants_license_category || {})[category_id] &&
            typeof (selected_variants_license_category || {})[category_id] ===
              'object' &&
            (selected_variants_license_category || {})[category_id].length > 0
          ) {
            return selected_variants_license_category[category_id]
          } else {
            return false
          }
        }
      },
      selected_variants_license_category: function () {
        const tmps = {}
        this.category_param.forEach((param) => {
          tmps[param] =
            typeof this.selected_variants === 'object'
              ? this.selected_variants.filter(
                  (variant) => variant.license_category == param
                )
              : []
        })
        return tmps
      },
      selected_variants_commission: function () {
        return this.selected_variants.filter((variant) => variant.category == 2)
      },
      selected_variants_insurance: function () {
        return this.selected_variants.filter((variant) => variant.category == 1)
      },
      selected_year: function () {
        const years = []
        this.selected_variants.map((variant) => {
          if (!years.includes(parseInt(variant.sports_year))) {
            years.push(parseInt(variant.sports_year))
          }
        })
        return years
      },
      selected_year_add: function () {
        const years = []
        this.selected_variants.map((variant) => {
          if (!years.includes(parseInt(variant.sports_year_add))) {
            years.push(parseInt(variant.sports_year_add))
          }
        })
        return years
      },

      kiyaku_mode: function () {
        return this.step_mode == 'kiyaku' ? true : false
      },
      mail_mode: function () {
        return this.step_mode == 'mail' ? true : false
      },
      license_mode: function () {
        return this.step_mode == 'license' || this.step_mode == 'license_sel'
          ? true
          : false
      },
      profile_mode: function () {
        return this.step_mode == 'profile' ? true : false
      },
      confirm_mode: function () {
        return this.step_mode == 'confirm' ? true : false
      },
      mfj_check_mode: function () {
        return this.step_mode == 'mfj_check' ? true : false
      },
      charge_mode: function () {
        return this.step_mode == 'charge' ? true : false
      },
      success_mode: function () {
        return this.step_mode == 'success' ? true : false
      },
      error_mode: function () {
        return this.step_mode == 'error' ? true : false
      },
    },

    async created() {},
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
  }
</script>

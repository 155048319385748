import store from '../store'
import config from '../const'

export const authorizeToken = (to, from, next) => {
  console.log('authorizeToken!!!!!', to, from, next)
  const login_path = config.HEAD_MENU_USER_LIST.filter(
    (list) => ['/club/mypage', '/owner/mypage'].includes(list.path) == false
  ).find((list) => list.path == to.path)
    ? '/login'
    : config.HEAD_MENU_OWNER_LIST.filter(
        (list) => ['/club/mypage', '/'].includes(list.path) == false
      ).find((list) => list.path == to.path)
    ? '/ologin'
    : config.HEAD_MENU_CLUB_LIST.filter(
        (list) => ['/', '/owner/mypage'].includes(list.path) == false
      ).find((list) => list.path == to.path)
    ? '/clogin'
    : '/login'
console.log('auth login_path ここで', login_path);

  const dashboard_path = config.HEAD_MENU_USER_LIST.filter(
    (list) => ['/club/mypage', '/owner/mypage'].includes(list.path) == false
  ).find((list) => list.path == to.path)
    ? '/'
    : config.HEAD_MENU_OWNER_LIST.filter(
        (list) => ['/club/mypage', '/'].includes(list.path) == false
      ).find((list) => list.path == to.path)
    ? '/owner/mypage'
    : config.HEAD_MENU_CLUB_LIST.filter(
        (list) => ['/', '/owner/mypage'].includes(list.path) == false
      ).find((list) => list.path == to.path)
    ? '/club/mypage'
    : '/'

  const auth = config.HEAD_MENU_USER_LIST.filter(
    (list) => ['/club/mypage', '/owner/mypage'].includes(list.path) == false
  ).find((list) => list.path == to.path)
    ? store.state.auth
    : config.HEAD_MENU_OWNER_LIST.filter(
        (list) => ['/club/mypage', '/'].includes(list.path) == false
      ).find((list) => list.path == to.path)
    ? store.state.oauth
    : config.HEAD_MENU_CLUB_LIST.filter(
        (list) => ['/', '/owner/mypage'].includes(list.path) == false
      ).find((list) => list.path == to.path)
    ? store.state.cauth
    : false

  // マッチしたルートでrequireAuthが付与しているのでログイン必須
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // マッチしたルートにおいて、メタフィールドに`requiresAuth`が付与されている場合は
    // ログインした際に付与される認証トークンがあるかどうかチェックする
    // 注意:
    // このアプリケーションでは簡略化のため`auth.token`があるかどうかのみで
    // ログイン済みであるかどうかチェックしているが、
    // 本来ならば付与された認証トークンをバックエンドのAPI経由などで検証すべき
    console.log('router.beforeEach', to.path, from.path)

    // ログインしていないのでログイン画面へ
    if (!auth || !(auth || {}).token) {
      console.log('login_path', login_path, 'へとぶ')
      next({ path: login_path })
    // ログインしているので次へ
    } else {
      next()
    }
  }
  // ログインが必要ないページだけれどもtokenはある
  else if (to.path == login_path && (auth || {}).token) {
    console.log(
      'router.beforeEach 2',
      to.path,
      dashboard_path,
      (auth || {}).token
    )
    next({ path: dashboard_path })
  } else if(
    (to.path == '/ologin' && from.path == '/ologout') || 
    (to.path == '/clogin' && from.path == '/clogout') || 
    (to.path == '/login' && from.path == '/logout') 
  ){
    console.log('logout to login')
    // this.$router.push({path: to.path})
    // next({path: to.path})
    window.location.href = to.path
  } else {
    console.log('simple next', to.path, from.path);
    next()
  }
}

<template>
  <section class="page">
    <div class="container" v-if="mfj_user_chk">
      <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1">
          <!-- <h2 class="title page__h1">昇降格申請({{ get_term_year }}年度)</h2> -->
          <h2 class="title page__h1">昇降格申請</h2>
          <div class="box box--center mb-24">
            <p v-html="message">
            </p>
          </div>
          <!-- {{ yakuin_point_obj }}<br> -->
          <!-- {{ license_promotion_conditions_obj }}<hr> -->
          <!-- {{ chk_not_sports_license_promotion_filter({from_license_cd: 35}) }} -->
          <div class="mb-40" v-for="license_category in [4]" :key="license_category">
            <template v-if="chk_member_license_filter({license_category: license_category, license_year: get_term_year, fixed: 1,license_to_id: 0})">
              <h3 class="title02 page__h2">
                {{ get_list_label(license_category, license_category_obj) }}ライセンス
              </h3>
              <template v-for="license in get_member_license_filter({
                license_category: license_category, 
                license_year: get_term_year,
                fixed: 1,
                license_to_id: 0
                })" :key="license.id">
                <div class="row mb-24 border-bottom" v-if="license_category == 4 && chk_not_sports_license_promotion_filter({
                  from_license_cd: license.license_cd
                  })">
                  <template v-for="condition in get_not_sports_license_promotion_filter({
                    from_license_cd: license.license_cd
                  })" :key="condition.id">
                    <div class="col-12 col-lg-6">
                      <div class="page__inputGroup row">
                        <div class="col-12 mb-10 text-center">
                          <h5 class="title04 page__h4">{{ license.license_label }}</h5>
                          <p>▼</p>
                          <!-- <p class="page__h6">↓&nbsp;必要昇格ポイント:{{ get_up_promotion_point(license.license_cd) }}<span v-if="license_category==4">Pt</span></p> -->
                        </div>
                        <div class="col-12 mb-10 text-center">
                          <h5 class="title04 page__h4">{{ get_list_label(condition.to_license_cd, license_cd_obj) }}</h5>
                          <!-- <p class="page__h6">↓&nbsp;必要昇格ポイント:{{ get_up_promotion_point(license.license_cd) }}<span v-if="license_category==4">Pt</span></p> -->
                        </div>
                        <div class="col-12 mb-10 text-center">
                          <p class="page__text">
                            現在の実務ポイントは
                            <span class="title04 page__h4 me-2">{{ get_yakuin_point(license.exchange_column) }}</span><span class="title05 page__h5" v-if="license_category==4">ポイント</span>です。
                          </p>
                          <p class="page__text">昇格に必要なポイントは<span class="title04 page__h4 me-2">{{ get_up_promotion_point(license.license_cd) }}</span><span class="title05 page__h5" v-if="license_category==4">ポイント</span>です。</p>
                        </div>
                        <!-- <div class="page__inputGroupCol col-md-12">
                          <p class="page__inputGroupInner">
                            <label class="label page__inputGroupLabel">昇格先ライセンス</label>
                            <span class="showData page__inputGroupInput">{{ get_list_label(condition.to_license_cd, license_cd_obj) }}</span>
                          </p>
                        </div> -->
                        <!-- <div class="page__inputGroupCol col-md-12">
                          <p class="page__inputGroupInner">
                            <label class="label page__inputGroupLabel" v-if="license_category==4">必要昇格ポイント</label>
                            <span class="showData page__inputGroupInput">
                              {{ get_up_promotion_point(license.license_cd) }}<span v-if="license_category==4">Pt</span>
                            </span>
                          </p>
                        </div> -->
                        <!-- <div class="page__inputGroupCol col-md-12">
                          <p class="page__inputGroupInner">
                            <label class="label page__inputGroupLabel" v-if="license_category==4">現在の実務ポイント</label>
                            <span class="showData page__inputGroupInput">
                              {{ get_yakuin_point(license.exchange_column) }}<span v-if="license_category==4">Pt</span>
                            </span>
                          </p>
                        </div> -->
                        <div class="page__inputGroupCol col-md-12">
                            <div class="d-grid" v-if="check_up_promotion(get_yakuin_point(license.exchange_column), license.license_cd)">
                              <button class="button04" @click="change_yakuin(condition.id, get_yakuin_point(license.exchange_column))">昇格する</button>
                            </div>
                            <div class="d-grid" v-else>
                              <button class="button03">昇格できません</button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="row mb-24 border-bottom" v-else-if="license_category == 1 && chk_license_promotion_filter({
                  license_category: license_category, fixed: 0, from_license_cd: license.license_cd
                  })">
                  <template v-for="promotion in get_license_promotion_filter({
                    license_category: license_category, fixed: 0, from_license_cd: license.license_cd
                  })" :key="promotion.id">
                    <!--  -->
                    <div class="col-12 col-lg-6">
                      <div class="page__inputGroup row">
                        <div class="col-12">
                          <h5 class="title04 page__h4">{{ license.license_label }}</h5>
                        </div>
                        <div class="page__inputGroupCol col-md-12">
                          <p class="page__inputGroupInner">
                            <label class="label page__inputGroupLabel">昇格ライセンス</label>
                            <span class="showData page__inputGroupInput">{{ promotion.to_license_label }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container pt-5">
      <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
          <div class="box">
            招待選手はライセンス昇格申請はできません
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
// import BsSelect from '../../atoms/BsSelect.vue'
import config from '../../../const'
import Common from '../../../common/common'

export default {
  name: 'RequestPromotionView',
  data: function () {
    return {
      targetid: this.$store.state.auth.userId,
      btnLabel: '',
      data_obj: {},
      form_obj: {},
      member_license_promotion_obj: [],
      message:
        '昇格できるライセンスはボタンが押せるようになっています。<br>「昇格する」から昇格申請を行って下さい。',
      term_year_obj: [],

      pref_obj: [],
      license_promotion_conditions_obj: [],
      license_cd_obj: [],
      promotion_type_obj: [],
      license_category_obj: [],
      application_obj: [],
      application_type_obj: [],
      application_check_type_obj: [],
      shipping_address_obj: [],
      exchange_license_point_column: {
        33: 'rd_level_1',
        34: 'rd_level_2',
        35: 'rd_level_3',
        39: 'mx_level_1',
        40: 'mx_level_2',
        41: 'mx_level_3',
        45: 'tr_level_1',
        46: 'tr_level_2',
        47: 'tr_level_3',
        42: 'sm_level_1',
        43: 'sm_level_2',
        44: 'sm_level_3'
      },
      yakuin_point_obj: {}
    }
  },
  components: {
    // BsSelect,
    //   BsMsgAlert,
    // BsCheckBox,
  },
  methods: {
    async confirm_yakuin(condition_id, yakuin_point){
      console.log(condition_id, yakuin_point)
    },
    async change_yakuin(condition_id, yakuin_point){
      console.log('change_yakuin condition_id, yakuin_point', condition_id, yakuin_point)
      const condition = this.license_promotion_conditions_list.find(condition => condition.id == condition_id)
      if(!condition){
        alert('昇格データがありません')
        return
      }
      // 何日に昇格するかの日付(役員は次の日)
      // from to のライセンスを指定する
      // 明日の日付を取得
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      // YYYY-MM-DD 形式に変換
      const tomorrowStr = tomorrow.getFullYear() + '-' +
                          String(tomorrow.getMonth() + 1).padStart(2, '0') + '-' +
                          String(tomorrow.getDate()).padStart(2, '0');
      const confimr_message = tomorrowStr + 'より' + condition.label + 'に昇格します。よろしいですか？'
      if(window.confirm(confimr_message)){
        const params = {
          stat: 1,
          m_id: this.$store.state.auth.userId,
          fixed: 1,
          application_flg: 1,
          condition_id: condition_id,
          to_license_cd: condition.to_license_cd,
          from_license_cd: condition.from_license_cd,
          license_year: this.get_term_year,
          promotion_type: condition.promotion_type,
          promotion_value: yakuin_point,
        }
        console.log('change_yakuin params', params)
        try {
          const check = await this.basicPosts_I(
            config.BASE_API_URL + '/member_license_promotion/change_yakuin',
            params
          )
          .then(async (response) => {
            // レスポンスの検証
            if (
              typeof response?.data === 'object' &&
              response?.data?.status == 200
            ) {
              return true
            } else {
              return false
            }
          })
          .catch(async (err) => {
            console.log(err)
            return false
          })
          if (check) {
            this.message = '昇格しました。マイページ/ライセンス取得履歴で確認して下さい'
            // alert('昇格しました')
          } else {
            this.message = '昇格操作中にエラーが発生しました'
          }
        } catch (err) {
            this.message = '昇格操作中にエラーが発生しました'
        } finally {
          await this.loadUserObj();
          this.$forceUpdate()
        }
      }
      return
    },
    async setUserLicensePromotion(){
      if(
        this.yakuin_point_obj.license_no == this.data_obj.license_no
      ){
        this.data_obj.member_license.map(async (license) => {
          if(
            // 昇降格マスタに存在している
            this.license_promotion_conditions_obj.find(condition => condition.from_license_cd == license.license_cd)
            && 
            // 昇降格ライセンスデータにある
            this.chk_license_promotion_filter({from_license_cd: license.license_cd})
          ){
            // fix:0 application_flg:0の場合はまだ昇格申請前
            if(
              this.chk_license_promotion_filter({from_license_cd: license.license_cd})
            ){
              console.log('');
            }
            // fix:0 && application_flg:1の場合は昇格申請後、ライセンス設定前
            // fix:1の場合は昇格後
            // const terget_promotion = this.get_func({from_license_cd: license.license_cd})
          }
          // 昇格
          else if(
            // 昇降格マスタに存在している
            this.license_promotion_conditions_obj.find(condition => condition.from_license_cd == license.license_cd)
          ){
            // 登録しておく必要がある
            await this.insertMemberLicensePromotion(this.license_promotion_conditions_obj.find(condition => condition.from_license_cd == license.license_cd))
          }
        })
      }
    },
    async insertMemberLicensePromotion(promotion_data){
      const {prom_type, from_cd, to_cd} = promotion_data
      // const params = (Array.isArray(license_promotion_conditions_obj))? [...this.license_promotion_conditions_obj]: []
      const params = {
        id: 0,
        stat: 1,
        m_id: this.$store.state.auth.userId,
        // fixed,application_flg,payment_flgは0
        to_license_cd: to_cd,
        from_license_cd: from_cd,
        license_year: this.get_term_year,
        promotion_type: prom_type,
        promotion_value: ''
      }
      await this.basicPosts_I(
        config.BASE_API_URL + '/member_promotion_data/add',
        params
      )
      .then(async (response) => {
        console.log('insertMemberLicensePromotion', response)
        if(
          response?.data?.status == 200
        ){
          await this.loadMemberLicensePromotionList()
        }else{
          alert('error')
        }
        // this.yakuin_point_obj = (response?.data?.body && response?.data.body[0])? response.data.body[0]: {}
      })
      .catch(async (err) => {
        console.log(err)
      })
      .finally(async () => {
        this.$forceUpdate()
      })

    },
    // 暫定
    async loadYakuinPointObj(){
      const params = {
        license_year_lower: this.get_term_year,
        license_no: this.data_obj?.license_no
      }
      await this.basicPosts_S(
        config.BASE_API_URL + '/member_yakuin_point/single',
        params
      )
      .then(async (response) => {
        console.log('loadYakuinPointObj', response)
        this.yakuin_point_obj = (response?.data?.body && response?.data.body[0])? response.data.body[0]: {}
      })
      .catch(async (err) => {
        console.log(err)
        this.yakuin_point_obj = {}
      })
      .finally(async () => {
        this.$forceUpdate()
      })
    },
    async loadTermYear() {
      const params = {}
      await this.basicPosts_S(
        config.BASE_API_URL + '/term_year/lists',
        params
      )
        .then(async (response) => {
          console.log('loadTermYear response', response)
          this.term_year_obj =
            response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          this.term_year_obj = []
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    async loadConfigObj(param) {
      const params = {}
      await this.basicPosts_S(
        config.BASE_API_URL + '/config/' + param,
        params
      )
        .then(async (response) => {
          console.log('loadConfigObj response', response)
          this[param + '_obj'] =
            response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          this[param + '_obj'] = []
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    async loadLicensePromotionList() {
      const params = {}
      params['promotion_id'] = 1
      params['stat'] = [1]
      await this.basicPosts_S(
        config.BASE_API_URL + '/license_promotion_conditions/lists',
        params
      )
      .then(async (response) => {
        console.log('loadLicensePromotionList response', response)
        this.license_promotion_conditions_obj =
          response.data && response.data.body ? response.data.body : []
      })
      .catch(async (err) => {
        console.log(err)
        this.license_promotion_conditions_obj = []
      })
      .finally(async () => {
        this.$forceUpdate()
      })
    },
    async loadLicenseCdList() {
      const params = {}
      params['stat'] = [1]
      await this.basicPosts_S(
        config.BASE_API_URL + '/license_cd/lists',
        params
      )
        .then(async (response) => {
          console.log('loadLicenseCdList response', response)
          this.license_cd_obj =
            response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          this.license_cd_obj = []
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    async loadMemberLicensePromotionList() {
      const params = {}
      params['stat'] = [1]
      // params['fixed'] = 0
      params['m_id'] = this.$store.state.auth.userId
      await this.basicPosts_S(config.BASE_API_URL + '/member_license_promotion/lists', params)
        .then(async (response) => {
          console.log('loadMemberLicensePromotionList', response)
          this.member_license_promotion_obj =
            response.data && response.data.body
              ? response.data.body
              : {}
        })
        .catch(async (err) => {
          console.log(err)
          this.member_license_promotion_obj = []
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    async loadUserObj() {
      const params = {}
      params['stat'] = [1]
      params['id'] = this.$store.state.auth.userId
      await this.basicPosts_S(config.BASE_API_URL + '/member/lists', params)
        .then(async (response) => {
          console.log('loadUserObj', response)
          this.data_obj =
            response.data && response.data.body && response.data.body[0]
              ? response.data.body[0]
              : {}
          const tmpl = (Array.isArray(this.data_obj?.member_license))? this.data_obj.member_license.map((license) => {
            // 暫定処理
            if(
              this.exchange_license_point_column[license.license_cd]
            ){
              license.exchange_column = this.exchange_license_point_column[license.license_cd]
            }
            else{
              license.exchange_column = false
            }
            return license
          }): []
          this.data_obj.member_license = tmpl
        })
        .catch(async (err) => {
          console.log(err)
          this.data_obj = []
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    async loadApplicationObj() {
      const params = {}
      params['stat'] = [1]
      params['m_id'] = this.$store.state.auth.userId
      params['not_application_check_types'] = [500, 900]
      await this.basicPosts_S(
        config.BASE_API_URL + '/member_application/lists',
        params
      )
        .then(async (response) => {
          console.log('loadApplicationObj', response)
          this.application_obj =
            response.data && response.data.body ? response.data.body : []
        })
        .catch(async (err) => {
          console.log(err)
          this.application_obj = []
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    async confirm_chk() {
      this.change_mode = true
    },
    async update() {
      console.log('component methods', this.data_obj)

      const params = {
        name_1: this.form_obj.name_1,
        name_2: this.form_obj.name_2,
        yomi_1: this.form_obj.yomi_1,
        yomi_2: this.form_obj.yomi_2,
        birth_date: this.form_obj.birth_date,
        profile: [],
        member_assets: this.form_obj.member_assets,
      }
      let profiles
      let upd_profile = {
        id: 0,
        m_id: this.$store.state.auth.userId,
        postal_cd: '',
        pref_cd: '',
        address_1: '',
        address_2: '',
        tel_1: '',
        tel_2: '',
      }
      if (
        typeof this.data_obj === 'object' &&
        typeof this.data_obj.profile === 'object' &&
        Object.keys(this.data_obj.profile).length > 0
      ) {
        profiles = [...this.data_obj.profile]
        upd_profile = profiles[0]
      } else {
        profiles.push({ ...upd_profile })
      }
      if (this.form_obj.postal_cd) {
        upd_profile.postal_cd = this.form_obj.postal_cd
      }
      if (this.form_obj.pref_cd) {
        upd_profile.pref_cd = this.form_obj.pref_cd
      }
      if (this.form_obj.address_1) {
        upd_profile.address_1 = this.form_obj.address_1
      }
      if (this.form_obj.address_2) {
        upd_profile.address_2 = this.form_obj.address_2
      }
      if (this.form_obj.tel_1) {
        upd_profile.tel_1 = this.form_obj.tel_1
      }
      if (this.form_obj.tel_2) {
        upd_profile.tel_2 = this.form_obj.tel_2
      }

      profiles[0] = upd_profile

      params.profile = profiles

      await this.basicPosts_I(
        config.BASE_API_URL + '/member/edit/' + this.$store.state.auth.userId,
        params
      )
        .then(async (response) => {
          console.log('update', response)
        })
        .catch(async (err) => {
          console.log(err)
          this.data_obj = []
        })
        .finally(async () => {
          await this.loadUserObj()
          this.message = '更新しました'
          this.$forceUpdate()
        })
    },
    back_input() {
      this.change_mode = false
    },
    back() {
      this.$router.push({ path: '/' })
    },
  },
  computed: {
    mfj_user_chk: function(){
      if(
        typeof this.data_obj === 'object' && 
        this.data_obj?.affiliation == 'other'
      ){
        return false
      }
      return true
    },
    get_term_year: function () {
      return typeof this.term_year_obj === 'object' &&
        typeof this.term_year_obj[0] === 'object' &&
        this.term_year_obj[0]['label']
        ? parseInt(this.term_year_obj[0]['label'])
        : parseInt(Common.getNowYear())
    },
    get_yakuin_point: function(){
      const yakuin_obj = this.yakuin_point_obj
      return function(column){
        return (typeof yakuin_obj === 'object' && yakuin_obj[column])? yakuin_obj[column]: 0
      }
    },
    ...mapState({}),
    get_up_promotion_point: function(){
      const conditions_obj = this.license_promotion_conditions_obj
      return function(license_cd){
        if(
          conditions_obj.find(condition => condition.from_license_cd == license_cd)
        ){
          return (conditions_obj.find(condition => condition.from_license_cd == license_cd))? conditions_obj.find(condition => condition.from_license_cd == license_cd).condition_value: 0
        }
        return false
      }
    },
    check_up_promotion:function(){
      const conditions_obj = this.license_promotion_conditions_obj
      return function(point, license_cd){
        if(
          conditions_obj.find(condition => parseInt(point) >= parseInt(condition.condition_value) && condition.from_license_cd == license_cd)
        ){
          return true
        }
        return false
      }
    },
    chk_member_license_filter: function(){
      const get_func = this.get_member_license_filter
      return  function(conditions){
        if(
          typeof get_func(conditions) === 'object'
          && get_func(conditions).length > 0
        ){
          return true
        }
        return false
      }
    },
    get_member_license_filter:function(){
      const member_license = this.data_obj?.member_license
      return function(conditions){
        return (typeof member_license === 'object' && Array.isArray(member_license))? member_license.filter(license => {
          let chk = true
          if(typeof conditions === 'object'){
            Object.keys(conditions).map(cond_key => {
              if(
                !license[cond_key] || (
                  license[cond_key] && license[cond_key] != conditions[cond_key]
                )
              ){
                chk = false
              }
            })
          }
          return chk
        }): []
      }
    },
    chk_not_sports_license_promotion_filter:function(){
      const get_func = this.get_not_sports_license_promotion_filter
      return  function(conditions){
        if(
          typeof get_func(conditions) === 'object'
          && get_func(conditions).length > 0
        ){
          return true
        }
        return false
      }},
    get_not_sports_license_promotion_filter:function(){
      const conditions_obj = this.license_promotion_conditions_list
      return function(conditions){
        return (typeof conditions_obj === 'object')? conditions_obj.filter(promotion_condition => {
          let chk = true
          if(typeof conditions === 'object'){
            Object.keys(conditions).map(cond_key => {
              if(
                !promotion_condition[cond_key] || (
                  promotion_condition[cond_key] && promotion_condition[cond_key] != conditions[cond_key]
                )
              ){
                chk = false
              }
            })
          }
        return chk
      }): []
    }},
    chk_license_promotion_filter: function(){
      const get_func = this.get_license_promotion_filter
      return  function(conditions){
        if(
          typeof get_func(conditions) === 'object'
          && get_func(conditions).length > 0
        ){
          return true
        }
        return false
      }
    },
    get_license_promotion_filter: function(){
      const member_license_promotion_obj = this.member_license_promotion_obj
      return function(conditions){
        return (typeof member_license_promotion_obj === 'object')? member_license_promotion_obj.filter(promotion => {
          let chk = true
          if(typeof conditions === 'object'){
            Object.keys(conditions).map(cond_key => {
              if(
                !promotion[cond_key] || (
                promotion[cond_key] && promotion[cond_key] != conditions[cond_key]
                )
              ){
                chk = false
              }
            })
          }
          return chk
        }): []
      }
    },

    get_list_label: function () {
      return function (key, lists) {
        if (
          typeof lists === 'object' &&
          typeof lists.find((list) => list.key == key) === 'object'
        ) {
          return lists.find((list) => list.key == key).label
        }
        return key
      }
    },

    application_type_list: function () {
      return this.application_type_obj
    },
    application_check_type_list: function () {
      return this.application_check_type_obj
    },
    application_list: function () {
      return this.application_obj
    },
    application_filter: function () {
      const app_list = this.application_list
      return function (app_type, app_chk_list) {
        return app_list.filter((app) => {
          if (
            app.application_type == app_type &&
            app_chk_list.includes(app.application_check_type) !== false
          ) {
            return true
          }
          return false
        })
      }
    },
    get_target_application: function () {
      const member_application = this.application_list
      return function (app_type) {
        return member_application.find(
          (app) => app.application_type == app_type
        )
      }
    },
    get_target_application_btn_class: function () {
      const get_func = this.get_target_application
      const application_btn_class = this.application_btn_class
      return function (app_type) {
        const filtered = get_func(app_type)
        console.log('get_target_application_btn_class', app_type, filtered)
        return application_btn_class(
          (filtered || {}).application_check_type
            ? filtered.application_check_type
            : false
        )
      }
    },
    license_promotion_conditions_list: function(){
      return this.license_promotion_conditions_obj.filter(condition => {
        if(
          typeof this.data_obj.member_license === 'object'
          && this.data_obj.member_license.find(license => license.license_cd == condition.to_license_cd && license.license_year >= this.get_term_year)
        ){
          return false
        }
        return true
      })
    },
  },
  async created() {
    await this.loadTermYear()
    await this.loadConfigObj('promotion_type_cd')
    await this.loadConfigObj('license_category')
    await this.loadConfigObj('sex_type')
    await this.loadConfigObj('application_type')
    await this.loadConfigObj('application_check_type')
    await this.loadLicenseCdList()
    await this.loadLicensePromotionList()
    await this.loadUserObj()
    await this.loadApplicationObj()
    await this.loadYakuinPointObj()
    // await this.setUserLicensePromotion()
    await this.loadMemberLicensePromotionList()
  },
  mounted: function () {
    // console.log('tags_list', this.tags_list);
  },
  props: {},
}
</script>

<template>
  <section class="page">
    <!-- ローディング表示 -->
    <div class="loading-overlay" v-if="isLoading">
      <div class="spinner-border text-danger" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="container">
      <div class="d-none d-md-block">
        <step-view :step_mode="page_mode"></step-view>
      </div>

      <!-- エラーメッセージ -->
      <div class="w-100">
        <div v-if="error_mode">
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-exclamation-lg"></i>&nbsp;エラー
              </div>
              <p>URLエラー</p>
            </div>
          </div>
        </div>
        <div v-else-if="reject_mode">
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-exclamation-lg"></i>&nbsp;承認拒否
              </div>
              <p>承認されませんでした。</p>
            </div>
          </div>
        </div>
        <div v-else-if="approval_mode">
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-exclamation-lg"></i>&nbsp;承認
              </div>
              <p>承認されました。</p>
              <p>
                <router-link to="/">マイページ</router-link>へ進んでください。
              </p>
            </div>
          </div>
        </div>
        <div v-else-if="success_mode">
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-exclamation-lg"></i>&nbsp;承認
              </div>
              <p>新システムの利用手続きが完了いたしました。</p>
              <p>ログインIDおよびパスワードは下記の通りに設定されていますので正常にログインが可能かご確認下さい。万一、ログインができないなどありましたら問い合わせ窓口よりお知らせ下さい。</p>
              <p>ログインは以下のリンクからお進みください</p>
              <div class="mt-2">
                <router-link to="/">マイページログイン</router-link>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="container mb-3">
              <div class="row pb-2 mb-2">
                <h4 class="col-12 col-md-4 title04 page__h4">
                  ログインID 
                </h4>
                <div class="col-12 col-md-8">
                  <input
                    type="text"
                    :class="input_class"
                    v-model="(data_obj || {}).login_id"
                    :readonly="input_readonly"
                  />
                </div>
              </div>
              <div class="row pb-2 mb-2">
                <h4 class="col-12 col-md-4 title04 page__h4">
                  ログインパスワード 
                </h4>
                <div class="col-12 col-md-8">
                  <input
                    type="text"
                    :class="input_class"
                    value="***********"
                    :readonly="input_readonly"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="check_mode">
          <div class="box02 mb-32">
            <h2 class="box02__title">
              <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
              MFJ確認中
            </h2>
            <p>
              利用手続きの際、メールアドレスを変更された場合は、MFJによる提出書類の確認が完了するまで新システムの利用ができません。<br />
              申請より３営業日程度お待ち下さい<br>
              確認完了のお知らせが届いた後、ログインをお試し下さい。
            </p>
          </div>
        </div>

        <!-- 個人情報の入力 -->
        <div v-else-if="profile_mode || confirm_mode">
          <div class="box02 mb-32">
            <h2 class="box02__title">
              <font-awesome-icon
                :icon="['fas', 'circle-exclamation']"
                class="me-2"
              />
              個人情報の入力
            </h2>
            <div class="d-flex flex-column gap-2">
              <p>
                以下の項目のうち、<span class="required-label">*</span
                >マークの付いた項目は必須入力項目です。
              </p>
              <p>
                必須項目をすべて入力しないと、申請を完了することができません。
              </p>
            </div>
          </div>
<!-- {{ data_obj }} -->
          <div class="mb-5">
            <div class="" style="position: relative; z-index: 1">
              <div class="container mb-3">
                <div class="row pb-2 mb-3">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    ログインID <span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8">
                    <div class="mb-2">
                      <input
                      type="text"
                      :class="input_class"
                      v-model="(data_obj || {}).login_id"
                      :readonly="input_readonly"
                    />
                    </div>
                    <div class="alert alert-info">
                      新しいシステムのログインに必要なIDです。<br>
                      お好きな文字列をご指定いただけますが以下の制約がありますのでご注意下さい。<br>
                      1.全て半角文字<br>
                      2.英大文字／英小文字／数字／記号（!@#%^&*）を組み合わせて8文字以上<br>
                      3.他で使用されていない
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-3">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    ログインパスワード <span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8" v-if="!confirm_mode">
                    <div class="mb-2  input-group">
                      <input
                        :type="isPasswordVisible ? 'text' : 'password'"
                        :class="input_class"
                        v-model="data_obj.login_pass"
                        :readonly="input_readonly"
                      />
                      <span 
                        v-if="profile_mode" 
                        class="input-group-addon" 
                        @click="togglePasswordVisibility"
                      >
                        <font-awesome-icon :icon="isPasswordVisible ? ['fas', 'eye'] : ['fas', 'eye-slash']" />
                      </span>
                    </div>
                    <div class="alert alert-info">
                      新しいシステムのログインに必要なパスワードです。<br>
                      お好きな文字列をご指定いただけますが以下の制約がありますのでご注意下さい。<br>
                      1.全て半角文字<br>
                      2.英大文字／英小文字／数字／記号（!@#%^&*）の内3種類を組み合わせて8文字以上24文字以内<br>
                      例）Mfj2024! ※英大文字＋英小文字＋数字＋記号の組み合わせで8文字
                    </div>
                  </div>
                  <div class="col-12 col-md-8 input-group" v-else-if="confirm_mode">
                    <input
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :class="input_class"
                      value="********"
                      :readonly="input_readonly"
                    />
                    <span 
                      v-if="profile_mode" 
                      class="input-group-addon" 
                      @click="togglePasswordVisibility"
                    >
                      <font-awesome-icon :icon="isPasswordVisible ? ['fas', 'eye'] : ['fas', 'eye-slash']" />
                    </span>
                  </div>
                </div>

                <div class="row pb-2 mb-3">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    顔写真<span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-3 mb-2 mb-md-0">
                    <div class="" v-if="(data_obj || {}).photo_type_1">
                      <img :src="(data_obj || {}).photo_type_1" class="w-100" />
                    </div>
                  </div>
                  <div class="col-12 col-md-5 mb-2">
                    <div class="d-grid">
                      <button
                        v-if="!confirm_mode"
                        type="button"
                        @click="clickUpFileBtn(100, 1)"
                        class="btn btn-light btn-outline-secondary"
                      >
                        <font-awesome-icon :icon="['fas', 'file-arrow-up']" />
                        &nbsp;ファイルを選択する
                      </button>
                    </div>
                    <input
                      type="file"
                      class="form-control"
                      :id="'file_up_input_100_path1'"
                      @change="selectedFile($event, 100, 1, false)"
                      accept="image/*"
                      style="display: none"
                    />
                  </div>
                  <div class="col-md-8 col-12 offset-md-4">
                    <div class="alert alert-info">
                      <div class="row">
                        <div class="col-12 col-md-9">
                          デジタルライセンスで使用する顔写真です。<br>
                          お好きな写真をご使用いただけますが、以下の制約がありますのでご注意下さい。<br>
                          1.縦長（ポートレート）の写真<br>
                          2.会員本人様単独で正面の写真<br>
                          3.撮影から6ヶ月以内の写真<br>
                          4.サングラスや帽子などで顔が隠されていない写真 ※メガネは問題ありません
                        </div>
                        <div class="col-6 offset-3 offset-md-0 col-md-3">
                          <img
                            :src="require('@/assets/img/m_photo_.png')"
                            alt="写真"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 本人確認書類 -->
                <div class="row pb-2 mb-3">
                  <h4 class="col-12 col-md-4 title04 page__h4">
                    本人確認書類種類<span class="required-label">*</span>
                  </h4>
                  <div class="col-12 col-md-8 mb-2">
                    <p
                      class=""
                      v-if="confirm_mode"
                    >
                      {{
                        get_list_label(
                          (data_obj || {}).identify_type,
                          identify_type_cd_list
                        )
                      }}
                    </p>
                    <bs-select
                      v-else
                      :options="identify_type_cd_list"
                      :nullOption="false"
                      v-model="data_obj.identify_type"
                    ></bs-select>
                  </div>
                  <div class="col-12 col-md-4">
                    <h4 class="title04 page__h4">ファイル</h4>
                  </div>
                  <div class="col-12 col-md-8" v-if="(data_obj || {}).identify_type">
                    <div
                      class="w-100"
                      v-for="k in (data_obj || {}).identify_type == 20 ? [2, 3] : [2]"
                      :key="k"
                    >
                      <div class="row mb-1" v-if="(data_obj || {})['photo_type_' + k]">
                        <div
                          class="col-xl-2 col-3"
                        >
                          <img
                            :src="(data_obj || {})['photo_type_' + k]"
                            class="w-100"
                          />
                        </div>
                        <div
                          class="col-12 col-md-10 mb-1"
                        >
                          <div class="d-grid">
                            <button
                              v-if="!confirm_mode"
                              type="button"
                              @click="clickUpFileBtn(100, k)"
                              class="btn btn-light btn-outline-secondary"
                            >
                              <font-awesome-icon
                                :icon="['fas', 'file-arrow-up']"
                              />
                              &nbsp;ファイルを再選択する
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-1" v-else>
                        <div
                          class="col-xl-2 col-3"
                        >
                          <span
                            v-if="data_obj.identify_type != 20"
                          >書類画像</span>
                          <span v-else-if="k == 2">表面</span>
                          <span v-else-if="k == 3">裏面</span>
                        </div>
                        <div
                          class="col-12 col-md-10 mb-1"
                        >
                          <div class="d-grid mb-1">
                            <button
                              v-if="!confirm_mode"
                              type="button"
                              @click="clickUpFileBtn(100, k)"
                              class="btn btn-light btn-outline-secondary"
                            >
                              <font-awesome-icon
                                :icon="['fas', 'file-arrow-up']"
                              />
                              &nbsp;ファイルを選択する
                            </button>
                          </div>
                        </div>
                      </div>
                      <input
                        type="file"
                        class="form-control"
                        :id="'file_up_input_100_path' + k"
                        @change="selectedFile($event, 100, k, false)"
                        accept="image/*"
                        style="display: none"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-8" v-else>
                    書類の種類を選択してください
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container mt-5">
            <div class="row">
              <div
                class="text-center col-6 offset-0 order-2 order-md-1 col-md-3 mb-2"
              >
                <button
                  class="button03"
                  @click="back('profile')"
                  v-if="page_mode == 'confirm'"
                >
                  <font-awesome-icon
                    :icon="['fas', 'chevron-left']"
                    class="mx-2"
                  />
                  <span class="button03__text">戻る</span>
                </button>
                <button class="button03" @click="back('license_sel')" v-else>
                  <font-awesome-icon
                    :icon="['fas', 'chevron-left']"
                    class="mx-2"
                  />
                  <span class="button03__text">戻る</span>
                </button>
              </div>
              <div
                class="text-center col-10 offset-1 order-1 order-md-2 col-md-6 offset-md-0 mb-2"
              >
                <button
                  class="button04"
                  @click="update_migration()"
                  :disabled="!formIsValid || page_mode !== 'confirm'"
                  v-if="page_mode == 'confirm'"
                >
                  <span class="button__text">
                    <span class="button04__text">WEBサービスの利用を申請する</span>
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      class="mx-2"
                    />
                  </span>
                </button>

                <button class="button03" @click="proceed('confirm')" v-else-if="!formIsValid" :disabled="!formIsValid">
                  <span class="button__text">
                    <span class="button03__text">申請に必要な項目を入力して下さい</span>
                  </span>
                </button>
                <button class="button04" @click="proceed('confirm')" v-else :disabled="!formIsValid">
                  <span class="button__text">
                    <span class="button04__text">申請内容を確認する</span>
                    <font-awesome-icon
                      :icon="['fas', 'chevron-right']"
                      class="mx-2"
                    />
                  </span>
                </button>

                <div class="box02 mt-3" v-if="validationMessage">
                  <h2 class="box02__title">
                    <font-awesome-icon
                      :icon="['fas', 'circle-exclamation']"
                      class="me-2"
                    />
                  </h2>
                  <div
                    v-html="validationMessage"
                    class="d-flex flex-column gap-2"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .page__inputGroupInput {
    display: flex;
    align-items: center;
  }

  .input-group-text {
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
  }

  .required-label {
    color: red;
  }

  .next-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 44px; /* タップしやすい最小の高さ */
  }

  .button-text {
    display: inline-block;
    white-space: nowrap;
  }
  .input-group {
    position: relative;
  }

  .input-group-addon {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
  }

  .input-group:not(.has-validation)>.dropdown-toggle,
  .input-group:not(.has-validation)>.form-floating>.form-control,
  .input-group:not(.has-validation)>.form-floating>.form-select,
  .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
  }
</style>
<script>
  import { mapState } from 'vuex'
  import StepView from './StepView.vue'
  import Common from '../../../common/common'
  import BsSelect from '../../atoms/BsSelect.vue'
  import config from '../../../const'
  import moment from 'moment'

  export default {
    name: 'MigrationFormView',
    data: function () {
      return {
        page_mode: 'profile',
        update_result: false,
        update_error: false,
        formIsValid: false,
        validationMessage: '',
        message: '',
        message_class: 'info',

        data_obj: {},
        migration_obj: {},

        target_step: ['kiyaku', 'mail'],
        pref_cd_obj: [],
        identify_type_cd_obj: [],
        member_document_cd_obj: [],
        isPasswordVisible: false,
        isLoading: false,
      }
    },
    components: {
      StepView,
      BsSelect,
    },
    methods: {
      validateForm() {
        const requiredFields = [
          { key: 'login_id', label: 'ログインID', errorMessage: 'ログインIDは8〜24文字の半角英数字と記号（!?@#%^&*）で入力してください。'},
          { key: 'login_pass', label: 'パスワード', errorMessage:
          'パスワードは8〜24文字で、英大文字、英小文字、数字、記号（!?@#%^&*）から3種類以上を組み合わせて入力してください。', },
          { key: 'photo_type_1', label: '顔写真' },
          { key: 'identify_type', label: '本人確認書類種類' },
          // { key: 'photo_type_2', label: '本人確認書類' },
          // { key: 'photo_type_3', label: '本人確認書類' },
        ]
        if(
          this.data_obj?.identify_type == '20'
        ){
          requiredFields.push({
            key: 'photo_type_2',
            label: '本人確認書類表面'
          })
          requiredFields.push({
            key: 'photo_type_3',
            label: '本人確認書類裏面'
          })
        }else{
          requiredFields.push({
            key: 'photo_type_2',
            label: '本人確認書類'
          })
        }

        this.validationMessage = [] // エラーメッセージを配列として初期化

        // バリデーションチェックの追加
        const loginId = this.data_obj.login_id || ''
        const loginPass = this.data_obj.login_pass || ''

        // ログインIDのバリデーション
        const loginIdValid = /^[a-zA-Z0-9!?@#%^&*]{8,24}$/.test(loginId)
        
        // パスワードの条件をチェック
        const hasUpperCase = /[A-Z]/.test(loginPass)
        const hasLowerCase = /[a-z]/.test(loginPass)
        const hasNumber = /[0-9]/.test(loginPass)
        const hasSymbol = /[!?@#%^&*]/.test(loginPass)
        const isValidLength = loginPass.length >= 8 && loginPass.length <= 24

        // 3種類以上の文字種を含むかチェック
        const characterTypesCount = [hasUpperCase, hasLowerCase, hasNumber, hasSymbol]
          .filter(Boolean)
          .length

        const loginPassValid = isValidLength && characterTypesCount >= 3
        
        // ログインIDとパスワードのバリデーションメッセージ
        if (loginId && !loginIdValid) {
          this.validationMessage.push(
            requiredFields.find((field) => field.key === 'login_id')
              .errorMessage
          )
        }
        if (loginPass && !loginPassValid) {
          this.validationMessage.push(
            requiredFields.find((field) => field.key === 'login_pass')
              .errorMessage
          )
        }

        const emptyFields = requiredFields.filter((field) => {
          if (field.key in this.data_obj) {
            return (
              this.data_obj[field.key] === '' ||
              this.data_obj[field.key] == null
            )
          }
          return true
        })

        this.formIsValid = emptyFields.length === 0 && this.validationMessage.length === 0

        if (!this.formIsValid) {
          const emptyFieldLabels = emptyFields
            .map((field) => field.label)
            .join('、')
          if (this.validationMessage.length > 0) {
            const errorMessages = this.validationMessage.join('<br>')
            this.validationMessage = `${errorMessages}<br>以下の必須項目を入力してください: ${emptyFieldLabels}`
          } else {
            this.validationMessage = `以下の必須項目を入力してください: ${emptyFieldLabels}`
          }
        } else {
          this.validationMessage = ''
        }
      },
      async update_migration(){
        this.isLoading = true
        try {
          await new Promise(resolve => setTimeout(resolve, 500))
          const post_url = config.BASE_API_URL + '/member_migration/edit/' + this.migration_obj.id
          // const params = {...this.migration_obj}
          const params = {
            ...this.migration_obj,
            items: [...(this.migration_obj.items || [])] // itemsも新しい配列にする
          };

          
          if(params.chg_mail_flg == 1) {
            params.step = 7
            params.finish = 0
          } else {
            params.finish = (this.migration_obj?.step != 10 )? 1: 0
            params.step = 10
          }
          const items = [...params.items]
          
          await Promise.all(
            Object.keys(this.data_obj).map(async (key) => {
              if(items.find(item => item.migration_key == key)) {
                const findex = items.findIndex(item => item.migration_key == key)
                const tmp = {...items[findex]}
                tmp.migration_value = this.data_obj[key]
                items[findex] = tmp
              } else {
                items.push({
                  id: 0,
                  stat: 1,
                  migration_id: (this.migration_obj || {}).id,
                  license_no: (this.migration_obj || {}).license_no,
                  migration_key: key,
                  migration_value: this.data_obj[key]
                })
              }
            })
          );
          params.items = items;

          const response = await this.nonloginPosts_I(post_url, params)
          console.log('update_migration response:', response) // デバッグ用

          if (response.data && response.data.status === 200) {
            // レスポンスデータを migration_obj に設定
            if (response.data.body) {
              this.migration_obj = response.data.body
            }

            // stepの値に基づいてページモードを設定
            if (this.migration_obj.step === 7) {
              this.page_mode = 'mfj_check'
            } else if (this.migration_obj.step === 10) {
              this.page_mode = 'success'
            }

            // データの再読み込みは必要な場合のみ
            if (this.page_mode !== 'success') {
              await this.loadMigrationData()
            }
          } else {
            this.message_class = 'danger'
            this.message = 'エラーが発生しました'
          }
        } catch (err) {
          console.error('Error in update_migration:', err)
          this.message_class = 'danger'
          this.message = 'エラーが発生しました'
        } finally {
          this.isLoading = false
          this.$forceUpdate()
        }
      },
      
      async update_app_check(check_type) {
        const post_url_path =
          this.application_obj.id > 0
            ? '/member_application/edit/' + this.application_obj.id
            : '/member_application/add'

        const params = {
          m_id: (this.member_obj || {}).id ? (this.member_obj || {}).id : 0,
          application_check_type: check_type,
        }
        await this.nonloginPosts_I(config.BASE_API_URL + post_url_path, params)
          .then(async (response) => {
            console.log('update_app_check', response)
          })
          .catch(async (err) => {
            console.log(err)
          })
          .finally(async () => {
            // await this.go_app();
            await this.loadRegistData()
            this.charge_btn_disabled = false
            this.$forceUpdate()
          })
      },

      async loadMemberObj(m_id) {
        const params = {}
        params['stat'] = [1]
        params['id'] = m_id
        console.log('loadMemberObj params', params)
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/member/lists',
          params
        )
          .then(async (response) => {
            console.log('loadMemberObj response', response)
            this.member_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.member_obj = {}
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },

      async selectedFile(e, type_cd, path) {
        this.isLoading = true
        try {
          await new Promise(resolve => setTimeout(resolve, 500))
          e.preventDefault()
          const files = [...e.target.files]
          if(files.length === 0) return;
          // let ups = [];
          files.forEach(async (file) => {
            const form = new FormData()
            form.append('file', file)
            
            await this.nonloginPosts_R(
              config.BASE_API_URL + '/upload/member_assets',
              form
            )
              .then((res) => {
                console.log(res)
                if (
                  res &&
                  res.status == 200 &&
                  path != false 
                ) {
                  this.data_obj['photo_type_' + path] = res.data.body.file_path
                    ? res.data.body.file_path
                    : ''
                }
              })
              .catch((error) => {
                console.log(error)
              })
              .finally(() => {
                if (path != false ) {
                  document.getElementById(
                    'file_up_input_' + type_cd + '_path' + path
                  ).value = ''
                }
                e.target.value = '';
                this.$forceUpdate()
              })
          })
        } finally {
          this.isLoading = false
        }
      },
      async clickUpFileBtn(type, path) {
        document
          .getElementById('file_up_input_' + type + '_path' + path)
          .click()
      },
      async postal_cd_chk() {
        const tmp = (this.data_obj || {}).postal_cd
          ? (this.data_obj || {}).postal_cd.match(/([0-9-])/g)
          : ''
        // console.log('postal_cd_chk tmp', typeof tmp, tmp);
        this.data_obj.postal_cd =
          tmp != null && typeof tmp === 'object' ? tmp.join('') : ''
        // console.log('postal_cd_chk postal_cd', this.app_form_obj.postal_cd);
      },
      async get_address_param() {
        const postal_cd = this.data_obj.postal_cd
        await this.get_address(postal_cd)
      },
      async get_address(postal_cd) {
        await this.basicGets_O(
          'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + postal_cd
        )
          .then((res) => {
            if (
              typeof res === 'object' &&
              typeof res.status === 'number' &&
              res.status == 200 &&
              typeof res.data === 'object' &&
              typeof res.data.results === 'object' &&
              typeof res.data.results[0] === 'object'
            ) {
              this.data_obj.pref_cd =
                parseInt(res.data.results[0].prefcode) < 10
                  ? '0' + res.data.results[0].prefcode
                  : res.data.results[0].prefcode
              this.data_obj.address_1 =
                res.data.results[0].address2 + res.data.results[0].address3
              this.data_obj.address_2 = ''
            } else {
              alert('住所データがありません')
            }
          })
          .catch((error) => {
            console.log(error)
            alert('住所データがありません.')
          })
          .finally(() => {
            this.$forceUpdate()
          })
      },
      chk_birth_date() {
        if (
          !this.data_obj.birth_date_year ||
          !this.data_obj.birth_date_month ||
          !this.data_obj.birth_date_day
        ) {
          alert('生年月日を入力してください')
          return true
        }
        this.proceed('license_sel')
        return true
      },
      async proceed(mode) {
        this.isLoading = true
        try {
          await new Promise(resolve => setTimeout(resolve, 500))

          const params = {
            login_id: (this.data_obj || {}).login_id,
          }
          const check = await this.nonloginPosts_S(
            config.BASE_API_URL + '/member/lists',
            params
          ).then(async (response) => {
              // レスポンスの検証
              if (
                typeof response?.data?.body === 'object' &&
                response?.data?.body.length > 0
              ) {
                return true
              } else {
                return false
              }
            })
            .catch(async (err) => {
              console.log(err)
              return false
            })
          console.log('check', check)
          const params2 = {
            'migration_key': 'login_id',
            'migration_value': (this.data_obj || {}).login_id,
          }
          const check2 = await this.nonloginPosts_S(
            config.BASE_API_URL + '/member_migration_item/lists',
            params2
          ).then(async (response) => {
              // レスポンスの検証
              if (
                typeof response?.data?.body === 'object' &&
                response?.data?.body.length > 0
              ) {
                return true
              } else {
                return false
              }
            })
            .catch(async (err) => {
              console.log(err)
              return false
            })
          console.log('check', check)

          if (check || check2) {
            alert('ログインIDが重複しています')
            return
          }
          this.page_mode = mode
          if (mode == 'license_sel') {
            this.message = 'ライセンスを選択してください。'
          } else if (mode == 'profile') {
            this.message = '個人情報を入力してください。'

            const tmp = { ...this.data_obj }
            tmp.race_sports_year = this.selected_year[0]
            tmp.mail_1 = (this.regist_obj || {}).mail_address
            this.data_obj = tmp
            console.log('proceed', this.data_obj, tmp)
          }
          Common.moveToTop()
        } finally {
          this.isLoading = false
        }
      },
      back(mode) {
        this.message_class = 'info'
        this.page_mode = mode
        if (mode == 'license_sel') {
          this.message = 'ライセンスを選択してください。'
        } else if (mode == 'form') {
          this.message = '個人情報を入力してください。'
        } else if (mode == 'license') {
          this.message = '下記項目を選択、入力してください。'
        }
        Common.moveToTop()
      },
      async loadMigrationData() {
        this.isLoading = true
        try {
          await new Promise(resolve => setTimeout(resolve, 500))
          const params = {}
          params['stat'] = [1]
          params['token'] = this.$route.query.token
          await this.nonloginPosts_S(
            config.BASE_API_URL + '/member_migration/lists',
            params
          )
            .then(async (response) => {
              console.log('loadMigrationData!!!', response)
              if (response.data && response.data.status == 200) {
                this.migration_obj =
                  response.data && response.data.body && response.data.body[0]
                    ? response.data.body[0]
                    : {}
              }
              else{
                this.migration_obj = {}
              }
            })
            .catch(async (err) => {
              this.page_mode = 'error'
              this.message_class = 'danger'
              this.message = err
              console.log('error', err)
              this.migration_obj = {}
            })
            .finally(async () => {
              await this.setMigrationObj()
              this.$forceUpdate()
            })
        } finally {
          this.isLoading = false
        }
      },
      
      async setMigrationObj() {
        if(
          this.migration_obj.step == 5
        ){
          this.page_mode = 'profile'
        }
        else if(
          this.migration_obj.step == 7
        ){
          this.page_mode = 'mfj_check'
        }
        else if(
          this.migration_obj.step == 10
        ){
          this.page_mode = 'success'
        }
        // { key: 'login_id', label: 'ログインID' },
        // { key: 'login_pass', label: 'パスワード' },
        // { key: 'photo', label: '顔写真' },
        // { key: 'm_path_type', label: '
        const tmps = {
          login_id: (this.get_migration_item('login_id'))? this.get_migration_item_value('login_id'): '',
          login_pass: (this.get_migration_item('login_pass'))? this.get_migration_item_value('login_pass'): '',
          identify_type: (this.get_migration_item('identify_type'))? this.get_migration_item_value('identify_type'): '',
          photo_type_1: (this.get_migration_item('photo_type_1'))? this.get_migration_item_value('photo_type_1'): '',
          photo_type_2: (this.get_migration_item('photo_type_2'))? this.get_migration_item_value('photo_type_2'): '',
          photo_type_3: (this.get_migration_item('photo_type_3'))? this.get_migration_item_value('photo_type_3'): '',
        }
        console.log('setMigrationObj', tmps)
        this.data_obj = tmps;
      },
      // パスワード表示切り替え
      togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible;
      },
    },
    computed: {
      ...mapState({}),
      get_list_label: function () {
        return function (key, lists) {
          if (
            typeof lists === 'object' &&
            typeof lists.find((list) => list.key == key) === 'object'
          ) {
            return lists.find((list) => list.key == key).label
          }
          return key
        }
      },
      get_list_key: function () {
        return function (label, lists) {
          if (
            typeof lists === 'object' &&
            typeof lists.find((list) => list.label == label) === 'object'
          ) {
            return lists.find((list) => list.label == label).key
          }
          return label
        }
      },
      get_migration_item_value: function(){
        const get_item = this.get_migration_item
        return function (key){
          if(
            get_item(key)
          ){
            return get_item(key).migration_value
          }
          return ''
        }
      },
      get_migration_item: function(){
        const items = (
          typeof this.migration_obj === 'object'
          && typeof this.migration_obj.items === 'object'
        )? this.migration_obj.items: false
        return function (key){
          if(
            typeof items === 'object'
            && items.find(obj => (obj.migration_key == key))
          ){
            return items.find(obj => (obj.migration_key == key))
          }
          return false
        }
      },
      get_age: function () {
        if (
          this.data_obj.birth_date_year &&
          this.data_obj.birth_date_month &&
          this.data_obj.birth_date_day
        ) {
          const birth_date = moment([
            this.data_obj.birth_date_year,
            parseInt(this.data_obj.birth_date_month) - 1,
            this.data_obj.birth_date_day,
          ]).format('YYYY-MM-DD')
          return this.calc_sports_age(birth_date)
        }
        return ''
      },
      get_wareki: function () {
        const regex1 =
          /^[0-9]{4}[/-](0[1-9]|1[0-2])[/-](0[1-9]|[12][0-9]|3[01])/
        const moment_func = moment
        return function (year, month, day) {
          const birth_date = moment_func([
            year,
            parseInt(month) - 1,
            day,
          ]).format('YYYY-MM-DD')
          if (regex1.test(birth_date)) {
            console.log('get_wareki', regex1.test(birth_date), birth_date)
            const tmp = new Date(birth_date).toLocaleDateString(
              'ja-JP-u-ca-japanese',
              {
                dateStyle: 'long',
              }
            )
            if (typeof tmp === 'string' && tmp.indexOf('年') > 0) {
              return tmp.substring(0, tmp.indexOf('年')) + '年'
            }
          }
          return ''
        }
      },
      get_birth_date: function () {
        const year = this.birth_date_year
        const month = this.birth_date_month
        const day = this.birth_date_day

        const moment_date = moment(year, parseInt(month) - 1, day)

        return moment_date.format('YYYY-MM-DD')
      },
      birth_date_year_list: function () {
        let now = 1920
        let limit = parseInt(Common.getNowYear()) + 1
        const tmps = []
        for (let i = now; i < 1970; i++) {
          tmps.push({
            key: i,
            label: i,
          })
        }
        tmps.push({
          key: '',
          label: '選択して下さい',
        })
        for (let i = 1970; i < limit; i++) {
          tmps.push({
            key: i,
            label: i,
          })
        }
        return tmps
      },
      birth_date_month_list: function () {
        const tmps = []
        for (let i = 1; i <= 12; i++) {
          tmps.push({
            key: i,
            label: i,
          })
        }
        return tmps
      },
      birth_date_day_list: function () {
        // const moment_date = moment();
        const moment_func = moment
        // const year = this.birth_date_year;
        // const month = this.birth_date_month;
        return function (year, month) {
          let max = 31
          if (year != '' && month != '') {
            const moment = moment_func([year, parseInt(month) - 1, 1])
            max = moment.daysInMonth()
          }
          const tmps = []
          for (let i = 1; i <= max; i++) {
            tmps.push({
              key: i,
              label: i,
            })
          }
          return tmps
        }
      },
      pref_cd_list: function () {
        const lists = [...this.pref_cd_obj]
        return lists.sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },
      member_document_cd_list: function () {
        return this.member_document_cd_obj
      },
      identify_type_cd_list: function () {
        return this.identify_type_cd_obj
      },
      error_mode: function () {
        return this.page_mode == 'error' ? true : false
      },
      profile_mode: function () {
        return this.page_mode == 'profile' ? true : false
      },
      confirm_mode: function () {
        return this.page_mode == 'confirm' ? true : false
      },
      check_mode: function () {
        return this.page_mode == 'mfj_check' ? true : false
      },
      success_mode: function () {
        return this.page_mode == 'success' ? true : false
      },
      reject_mode: function () {
        return this.page_mode == 'mfj_reject' ? true : false
      },

      message_class_style: function () {
        const message_class = this.message_class
        return function (target) {
          if (target == 'border') {
            return (
              'text-center py-2 bs_fs_5 mb-2 border-bottom border-' +
              message_class
            )
          } else if (target == 'alert') {
            return 'alert alert-' + message_class
          }
        }
      },
      input_class: function () {
        const page_mode = this.page_mode
        if (page_mode == 'confirm' || page_mode == 'success' || page_mode == 'mfj_check') {
          return 'form-control-plaintext'
        } else {
          return 'form-control'
        }
      },
      input_readonly: function () {
        const page_mode = this.page_mode
        if (page_mode == 'confirm' || page_mode == 'success' || page_mode == 'mfj_check') {
          return true
        } else {
          return false
        }
      },
    },

    async created() {
      this.pref_cd_obj = await this.loadBasicConfigObj('pref')
      this.identify_type_cd_obj = await this.loadBasicConfigObj(
        'identify_type_cd'
      )
      await this.loadMigrationData()
      this.validateForm() // 初期バリデーションを実行
    },
    mounted: function () {
    },
    unmounted() {
    },
    props: {},
    watch: {
      data_obj: {
        deep: true,
        handler() {
          this.validateForm()
        },
      },
      // 'data_obj.tel_1': function (newVal) {
      //   this.data_obj.tel_1 = this.formatPhoneNumber(newVal)
      // },
      // 'data_obj.tel_2': function (newVal) {
      //   this.data_obj.tel_2 = this.formatPhoneNumber(newVal)
      // },
    },
  }
</script>

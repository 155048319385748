import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { authorizeToken } from './guards'
// import Error from '../components/Error.vue'

// store
// import Store from '@/store/index.js'
const base_url = (process.env.NODE_ENV=='staging')
  ? '/mfj_renewal/app'
  : process.env.BASE_URL
const routerHistory = createWebHistory(base_url)

const router = createRouter({
  history: routerHistory,
  // base: process.env.BASE_URL,
  // base: process.env.NODE_ENV === 'production'
  // ? '/regimng/'
  // : process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
      // return { x: 0, y: 0 }
    } else {
      // console.log('scrollBehavior', to, from, savedPosition);
      return { top: 0 }
    }
    // return { x: 0, y: 0 }
  },
})
// console.log('test2');
router.beforeEach(authorizeToken)

// router.beforeEach((to, from, next) => {
//     if (to.matched.some(page => page.meta.isPublic) || Store.state.auth.token) {
//       next()
//     } else {
//       next('/login')
//     }
// });

export default router

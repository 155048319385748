export const AUTH_LOGIN = 'AUTH_LOGIN'
// basic
export const FETCH_ALL_BASICLIST = 'FETCH_ALL_BASICLIST'
export const UPDATE_BASIC = 'UPDATE_BASIC'
// search_param
export const FETCH_ALL_SEARCHPARAM = 'FETCH_ALL_SEARCHPARAM'
// pager
export const FETCH_ALL_PAGER = 'FETCH_ALL_PAGER'
// 商品
export const FETCH_ALL_ARTICLELIST = 'FETCH_ALL_ARTICLELIST'
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE'

export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const OAUTH_LOGIN = 'OAUTH_LOGIN'
export const OAUTH_LOGOUT = 'OAUTH_LOGOUT'
export const CAUTH_LOGIN = 'CAUTH_LOGIN'
export const CAUTH_LOGOUT = 'CAUTH_LOGOUT'

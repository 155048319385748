<template>
  <section class="page">
    <div class="container">
      <h3 class="title02 page__h2">ログインパスワード変更</h3>
      <div class="box box--center mb-24">
        <p>
          {{ message }}
        </p>
      </div>
      <div class="mb-40">
        <div class="mb-32 col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
          <h4 class="title08 d-block mb-24">変更するログインパスワード</h4>
          <div class="page__inputGroup">
            <div class="page__inputGroupCol">
              <p class="page__inputGroupInner">
                <label class="label page__inputGroupLabel" for="input041"
                  >パスワード</label
                >
                <input
                  type="text"
                  v-model="change_login_pass"
                  :class="input_class"
                  :readonly="input_readonly"
                />
              </p>
            </div>
            <div class="page__inputGroupCol">
              <p class="page__inputGroupInner">
                <label class="label page__inputGroupLabel" for="input042"
                  >確認</label
                >
                <input
                  type="text"
                  v-model="confirm_login_pass"
                  :class="input_class"
                  :readonly="input_readonly"
                />
              </p>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-column flex-md-row mt-32 justify-content-center column-gap-24"
          v-if="!finish_mode"
        >
          <button class="button03" @click="back_input" v-if="change_mode">
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
            <span class="button03__text"> 戻る </span>
          </button>
          <button class="button03" @click="back" v-else>
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
            <span class="button03__text"> 戻る </span>
          </button>
          <button
            v-if="change_mode"
            type="button"
            @click="update"
            class="button04"
          >
            <span class="button04__text"> 更新する </span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </button>
          <button v-else type="button" @click="confirm_chk" class="button04">
            <span class="button04__text"> 確認する </span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </button>
        </div>
        <div
          class="d-flex flex-column flex-md-row mt-32 justify-content-center column-gap-24"
          v-else
        >
          <button class="button03" @click="back">
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
            <span class="button03__text"> 戻る </span>
          </button>

        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.375rem;
    border-bottom: solid 1px #999;
  }
  .form-control-plaintext {
    border-bottom: solid 1px #999;
  }
</style>
<script>
  import { mapState } from 'vuex'
  // import BsPageTitle from '../../molecules/BsPageTitle.vue'
  // import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
  // import BsSelect from '../../atoms/BsSelect.vue'
  // import BsCheckBox from '../../atoms/BsCheckBox.vue'
  // import '../../assets/bootstrap/css/sc.css';
  import config from '../../../const'

  export default {
    name: 'UserPassWdView',
    data: function () {
      return {
        targetid: this.$store.state.oauth.userId,
        btnLabel: '',
        data_obj: {},
        change_login_pass: '',
        confirm_login_pass: '',
        change_mode: false,
        finish_mode: false,
        message: 'ログインパスワードを変更します。',
      }
    },
    components: {
      //   BsPageTitle,
      //   BsSelect,
      //   BsMsgAlert,
      // BsCheckBox,
    },
    methods: {
      async loadOwnerObj() {
        const params = {}
        params['stat'] = [1]
        params['id'] = this.$store.state.oauth.userId
        await this.basicPosts_S(config.BASE_API_URL + '/owner/lists', params)
          .then(async (response) => {
            console.log('loadOwnerObj', response)
            this.data_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async confirm_chk() {
        if (this.change_login_pass == '' || this.confirm_login_pass == '') {
          alert('変更するログインパスを入力して下さい')
          return true
        } else if (
          this.change_login_pass != '' &&
          this.confirm_login_pass != '' &&
          this.change_login_pass != this.confirm_login_pass
        ) {
          alert('入力されたログインパスが一致しません')
          return true
        // } else if (
        //   !/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])[a-zA-Z0-9!@#$%^&*.]{8,30}$/.test(
        //     this.change_login_pass
        //   )
        // ) {
        //   alert('ログインパスワードは8〜30文字の半角英数字と記号を含む必要があります。')
        //   return true
        }

        this.message = '更新できます。'
        this.change_mode = true
      },
      async update() {
        console.log('component methods', this.data_obj)
        const params = {}
        params.login_pass = this.change_login_pass
        await this.basicPosts_I(
          config.BASE_API_URL + '/owner/edit/' + this.$store.state.oauth.userId,
          params
        )
          .then(async (response) => {
            console.log('update', response)
            if (
              typeof response === 'object' &&
              typeof response.data === 'object' &&
              response.data.status == '200'
            ) {
              this.message = '更新しました'
              this.finish_mode = true
            } else {
              this.message = '更新できませんでした。'
            }
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
            this.message = '更新できませんでした。'
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      back_input() {
        this.change_mode = false
      },
      back() {
        this.$router.go(-1)
      },
    },
    computed: {
      ...mapState({}),
      input_class: function () {
        if (this.change_mode) {
          return 'form-control-plaintext'
        } else {
          return 'form-control'
        }
      },
      input_readonly: function () {
        if (this.change_mode) {
          return true
        } else {
          return false
        }
      },
    },

    async created() {
      await this.loadOwnerObj()
    },
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
    props: {},
  }
</script>

<template>
  <div class="container my-5">
    <div class="row" v-if="success_mode || error_mode"></div>
    <div v-else>
      <ul class="status">
        <li
          v-for="(step, index) in steps"
          :key="index"
          class="status__li"
          :class="{ 'status__li--isCurrent': isCurrentStep(step.mode) }"
        >
          <span class="status__number">{{ index + 1 }}</span>
          <span class="status__title">{{ step.text }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'RegistAddView',
  data: function () {
    return {
      steps: [
        { mode: 'kiyaku', text: '利用規約の確認' },
        { mode: 'mail', text: 'メールアドレスの確認' },
        { mode: 'license', text: '申請ライセンスの選択' },
        { mode: 'profile', text: '個人情報の入力' },
        { mode: 'confirm', text: '申請内容の確認' },
        { mode: 'mfj_check', text: 'MFJ確認' },
        { mode: 'charge', text: '年会費のお支払い' }
      ]
    }
  },
  methods: {
    isCurrentStep(mode) {
      return (
        this.step_mode === mode ||
        (mode === 'mail' && this.step_mode === 'passcd') ||
        (mode === 'license' && this.step_mode === 'license_sel') ||
        (mode === 'charge' && this.step_mode === 'mfj_approval')
      )
    }
  },
  props: {
    step_mode: {
      type: String,
      default: 'kiyaku'
    }
  },
  computed: {
    ...mapState({}),
    success_mode: function () {
      return this.step_mode == 'success'
    },
    error_mode: function () {
      return this.step_mode == 'error'
    }
  }
}
</script>

<style scoped>
.status__li {
  position: relative;
  width: 172px;
  padding-inline: 20px;
  text-align: center;
}

.status__li:after {
  position: absolute;
  top: 10px;
  right: -6px;
  font-size: 18px;
  content: '>';
}

.status__li:last-of-type:after {
  content: none;
}
</style>

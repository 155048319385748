<template>
  <div>
    <div class="text-center">競技会管理ページに遷移します。</div>
  </div>
</template>

<script>

  export default {
    name: 'LinkView',
    data: function () {
      return {
      }
    },
    components: {
    },
    computed: {
      
    },

    async created() {
    },
    mounted: function () {
      // location.href = 'https://www.ms-event.net/mfj/admin/';
      // location.href = `https://www.ms-event.net/mfj/admin/?imedia_test=1&owner_id=00000010&loginFlg=noPass`
    },

    methods: {
      go_link(){

      }
    },
  }
</script>

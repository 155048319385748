<template>
  <section class="page">
    <div class="container">
      <h3 class="title02 page__h2">ログインパスワード変更</h3>
      <div class="box box--center mb-24">
        <p class="border-bottom mb-10">
          {{ message }}
        </p>
        <p v-if="!change_mode">
          パスワードは8〜24文字で、英大文字、英小文字、数字、記号（!?@#%^&*）から3種類以上を組み合わせて入力してください。
        </p>
      </div>
      <div class="mb-40 row">
        <div class="mb-32 col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
          <h4 class="title08 d-block mb-24">変更するログインパスワード</h4>
          <div class="page__inputGroup mb-32">
            <div class="page__inputGroupCol">
              <p class="page__inputGroupInner">
                <label class="label page__inputGroupLabel" for="input041"
                  >パスワード</label
                >
                <input
                  type="text"
                  v-model="change_login_pass"
                  :class="input_class"
                  :readonly="input_readonly"
                />
              </p>
            </div>
            <div class="page__inputGroupCol">
              <p class="page__inputGroupInner">
                <label class="label page__inputGroupLabel" for="input042"
                  >確認</label
                >
                <input
                  type="text"
                  v-model="confirm_login_pass"
                  :class="input_class"
                  :readonly="input_readonly"
                />
              </p>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-column flex-md-row mt-32 justify-content-center column-gap-24"
        >
          <button class="button03 mb-2" @click="back_input" v-if="change_mode">
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
            <span class="button03__text"> 戻る </span>
          </button>
          <button class="button03 mb-2" @click="back" v-else>
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
            <span class="button03__text"> 戻る </span>
          </button>
          <button
            v-if="change_mode"
            type="button"
            @click="update"
            class="button04"
          >
            <span class="button04__text"> 更新する </span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </button>
          <button v-else-if="!finish_mode" type="button" @click="confirm_chk" class="button04">
            <span class="button04__text"> 確認する </span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { mapState } from 'vuex'
  import config from '../../../const'

  export default {
    name: 'UserPassWdView',
    data: function () {
      return {
        targetid: this.$store.state.auth.userId,
        btnLabel: '',
        data_obj: {},
        change_login_pass: '',
        confirm_login_pass: '',
        change_mode: false,
        finish_mode: false,
        message: 'ログインパスワードを変更します。',
      }
    },
    components: {},
    methods: {
      async loadUserObj() {
        const params = {}
        params['stat'] = [1]
        params['id'] = this.$store.state.auth.userId
        await this.basicPosts_S(config.BASE_API_URL + '/member/lists', params)
          .then(async (response) => {
            console.log('loadUserObj', response)
            this.data_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async confirm_chk() {
        if (this.change_login_pass == '' || this.confirm_login_pass == '') {
          alert('変更するログインパスを入力して下さい')
          return true
        } else if (
          this.change_login_pass != '' &&
          this.confirm_login_pass != '' &&
          this.change_login_pass != this.confirm_login_pass
        ) {
          alert('入力されたログインパスが一致しません')
          return true
        // } else if (
        //   !/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])[a-zA-Z0-9!@#$%^&*.]{8,24}$/.test(
        //     this.change_login_pass
        //   )
        // ) {
        //   alert('ログインパスワードは8〜24文字の半角英数字と記号を含む必要があります。')
        //   return true
        }
        const loginPass = this.change_login_pass

        // パスワードの条件をチェック
        const hasUpperCase = /[A-Z]/.test(loginPass)
        const hasLowerCase = /[a-z]/.test(loginPass)
        const hasNumber = /[0-9]/.test(loginPass)
        const hasSymbol = /[!?@#%^&*]/.test(loginPass)
        const isValidLength = loginPass.length >= 8 && loginPass.length <= 24

        // 3種類以上の文字種を含むかチェック
        const characterTypesCount = [hasUpperCase, hasLowerCase, hasNumber, hasSymbol]
          .filter(Boolean)
          .length

        const loginPassValid = isValidLength && characterTypesCount >= 3
        if(!loginPassValid){
          alert('パスワードは8〜24文字で、英大文字、英小文字、数字、記号（!?@#%^&*）から3種類以上を組み合わせて入力してください。')
          return true
        }

        this.message = '更新できます。'
        this.change_mode = true
      },
      async update() {
        const params = {}
        params.login_pass = this.change_login_pass
        await this.basicPosts_I(
          config.BASE_API_URL + '/member/edit/' + this.$store.state.auth.userId,
          params
        )
          .then(async (response) => {
            console.log('update', response)
            if (
              typeof response === 'object' &&
              typeof response.data === 'object' &&
              response.data.status == '200'
            ) {
              this.message = '更新しました'
              this.finish_mode = true
              this.change_mode = false
              this.change_login_pass = false
              this.confirm_login_pass = false
            } else {
              this.message = '更新できませんでした。'
            }
          })
          .catch(async (err) => {
            console.log(err)
            this.message = '更新できませんでした。'
            this.data_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      back_input() {
        this.change_mode = false
      },
      back() {
        this.$router.go(-1)
      },
    },
    computed: {
      ...mapState({}),
      input_class: function () {
        if (this.change_mode) {
          return 'form-control-plaintext'
        } else {
          return 'form-control'
        }
      },
      input_readonly: function () {
        if (this.change_mode) {
          return true
        } else {
          return false
        }
      },
    },
    async created() {
      await this.loadUserObj()
    },
    watch: {
      // change_login_pass: {
      //   handler(newVal, oldVal){
      //     if(
      //       newVal == '' || 
      //       this.chk_passwd_character(newVal)
      //     ){
      //       this.change_login_pass = newVal
      //     }
      //     else{
      //       this.change_login_pass = oldVal
      //     }
      //   }
      // },
      // confirm_login_pass: {
      //   handler(newVal, oldVal){
      //     if(
      //       newVal == '' || 
      //       this.chk_passwd_character(newVal)
      //     ){
      //       this.confirm_login_pass = newVal
      //     }
      //     else{
      //       this.confirm_login_pass = oldVal
      //     }
      //   }
      // }
    },
    mounted: function () {},
    props: {},
  }
</script>

<template>

      <label v-if="label" class="label page__inputGroupLabel" :for="id">{{
        label
      }}</label>
      <select :id="id" :name="name" :value="modelValue" @change="onChange($event)"
        class="select page__inputGroupInput input">
        <!-- <option v-if="nullOption" value="">
          {{ nullOptionLabel }}
        </option> -->
        <option v-for="(option, index) in options" :key="index" :value="option.key"
          :selected="selectedCheck(option.key, modelValue)">
          {{ option.label }}
        </option>
      </select>

</template>

<script>
// import { Field } from 'vee-validate';
export default {
  name: 'BsSelect',
  props: {
    options: {
      type: Array,
      required: true
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    nullOption: {
      type: [Boolean, String],
      default: false
    },
    classParam: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  components: {
    // Field,
  },
  computed: {
    nullOptionLabel: function () {
      if (this.nullOption != true && this.nullOption != false) {
        return this.nullOption + ''
      } else {
        return '選択して下さい'
      }
    },
    classAttach: function () {
      console.log('classAttach', this.classParam)
      let class_param = 'form-control form-select'
      if (this.classParam != '') {
        class_param += ' ' + this.classParam
      }
      return class_param
    },
    selectedCheck: function () {
      return function (op_key, m_val) {
        // console.log('op_key', op_key, 'm_val', m_val);
        if (op_key === m_val) {
          // console.log('op_key === m_val op_key', op_key, 'm_val', m_val);
          return 'selected'
        } else if (String(op_key) === String(m_val)) {
          // console.log('String(op_key) === String(m_val) op_key', op_key, 'm_val', m_val);
          return 'selected'
        }

        return ''
      }
      // :selected="(String(option.key) == String(modelValue))? 'selected': ''"
    },
  },
  methods: {
    onChange(e) {
      console.log('bsselect on change')
      console.log(e.target)
      console.log(e.target.value)
      this.$emit('update:modelValue', e.target.value)
      this.$emit('parentFunc', e.target.value)
    },
  },
}
</script>


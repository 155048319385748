<template>
  <section class="py-5">
    <step-view :step_mode="page_mode"></step-view>
    <div class="container">
      <!-- {{page_mode}} -->
      <div class="row">
        <div
          class="text-start col-10 offset-1 col-xl-6 offset-xl-3"
          v-if="error_mode"
        >
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-exclamation-lg"></i>&nbsp;エラー
              </div>
              <p>URLエラー</p>
            </div>
          </div>
        </div>
        <div
          class="text-start col-10 offset-1 col-xl-6 offset-xl-3"
          v-else-if="reject_mode"
        >
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-exclamation-lg"></i>&nbsp;承認拒否
              </div>
              <p>承認されませんでした。</p>
            </div>
          </div>
        </div>
        <div
          class="text-start col-10 offset-1 col-xl-6 offset-xl-3"
          v-else-if="approval_mode"
        >
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-exclamation-lg"></i>&nbsp;承認
              </div>
              <p>承認されました。</p>
              <p>決済へ進んでください。</p>
            </div>
          </div>
        </div>
        <div
          class="text-start col-10 offset-1 col-xl-6 offset-xl-3"
          v-else-if="success_mode"
        >
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-exclamation-lg"></i>&nbsp;承認
              </div>
              <p>承認されています。</p>
              <p>マイページへ進んでください</p>
            </div>
          </div>
        </div>
        <div
          class="text-start col-10 offset-1 col-xl-6 offset-xl-3"
          v-else-if="check_mode"
        >
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-check"></i>&nbsp;MFJ確認中
              </div>
              <p>
                現在MFJ事務局にて申請内容を確認中です。<br />
                もうしばらくお待ち下さい。
              </p>
            </div>
          </div>
        </div>

        <div
          class="text-start col-10 offset-1 col-xl-6 offset-xl-3"
          v-else-if="profile_mode || confirm_mode"
        >
          <div class="mb-3">
            <div :class="message_class_style('alert')">
              <div :class="message_class_style('border')">
                <i class="bi bi-check"></i>&nbsp;主催者情報の入力
              </div>
              <p>
                {{ message }}
              </p>
            </div>
          </div>
          <div class="mb-5">
            <div class="border border-secondary bg-light rounded-2 py-3 px-4">
              <div class="container m-0">
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">年度</div>
                  <div class="col-8">
                    <input
                      type="text"
                      class="form-control-plaintext"
                      value="2024"
                      readonly="true"
                    />
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">ログインID</div>
                  <div class="col-6">
                    <input
                      type="text"
                      :class="input_class"
                      value=""
                      :readonly="input_readonly"
                    />
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">主催者名称</div>
                  <div class="col-8">
                    <div class="input-group mb-2">
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">名称(カナ)</div>
                  <div class="col-8">
                    <div class="input-group mb-2">
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">代表者氏名</div>
                  <div class="col-8">
                    <div class="input-group mb-2">
                      <span class="input-group-text">姓</span>
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                      <span class="input-group-text">名</span>
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">代表者氏名(カナ)</div>
                  <div class="col-8">
                    <div class="input-group mb-2">
                      <span class="input-group-text">セイ</span>
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                      <span class="input-group-text">メイ</span>
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">連絡先電話番号1</div>
                  <div class="col-8">
                    <div class="input-group">
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                      <span class="input-group-text">-</span>
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                      <span class="input-group-text">-</span>
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">連絡先電話番号2</div>
                  <div class="col-8">
                    <div class="input-group">
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                      <span class="input-group-text">-</span>
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                      <span class="input-group-text">-</span>
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">住所</div>
                  <div class="col-8">
                    <div class="input-group mb-2">
                      <span class="input-group-text">郵便番号</span>
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                      <button
                        type="button"
                        class="btn btn-light btn-outline-secondary"
                      >
                        <i class="bi bi-search"></i>
                      </button>
                    </div>
                    <div class="input-group mb-2">
                      <span class="input-group-text">都道府県</span>
                      <bs-select></bs-select>
                    </div>
                    <div class="input-group mb-2">
                      <span class="">市区町村番地</span>
                    </div>
                    <div class="input-group mb-2">
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                    </div>
                    <div class="input-group mb-2">
                      <span class="">建物名</span>
                    </div>
                    <div class="input-group">
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">メールアドレス</div>
                  <div class="col-8">
                    <input
                      type="text"
                      class="form-control-plaintext"
                      value=""
                      readonly="true"
                    />
                  </div>
                </div>
                <div
                  class="row pb-2 mb-2 border-bottom border-secondary d-none"
                >
                  <div class="col-4">メールアドレス２</div>
                  <div class="col-8">
                    <div class="input-group mb-2">
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                    </div>
                    <div class="input-group">
                      <span class="input-group-text">確認</span>
                      <input
                        type="text"
                        :class="input_class"
                        value=""
                        :readonly="input_readonly"
                      />
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">MFJライセンスNo.</div>
                  <div class="col-8">
                    <input
                      type="text"
                      class="form-control"
                      value=""
                      readonly="true"
                    />
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">主催カテゴリ</div>
                  <div class="col-8">
                    <div class="row">
                      <div
                        class="col-6 mb-2"
                        v-for="cate in cate_list"
                        :key="cate"
                      >
                        <div class="d-grid">
                          <button class="btn btn-outline-secondary">
                            {{ cate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">主催者資格</div>
                  <div class="col-8">
                    <div class="row">
                      <div
                        class="col-6 mb-2"
                        v-for="ctype in type_list"
                        :key="ctype"
                      >
                        <div class="d-grid">
                          <button class="btn btn-outline-secondary">
                            {{ ctype }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">公認クラブ名</div>
                  <div class="col-8">
                    <input
                      type="text"
                      class="form-control"
                      value=""
                      readonly="true"
                    />
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">クラブ員数</div>
                  <div class="col-5">
                    <div class="input-group">
                      <input type="text" class="form-control" value="" />
                      <span class="input-group-text">名</span>
                    </div>
                  </div>
                </div>
                <div class="row pb-2 mb-2 border-bottom border-secondary">
                  <div class="col-4">備考欄</div>
                  <div class="col-5">
                    <textarea class="form-control"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div
                class="text-center col-6 offset-0 order-2 order-md-1 col-md-3 mb-2"
              >
                <button
                  class="btn btn-light btn-outline-secondary"
                  @click="back('member')"
                  v-if="page_mode == 'confirm'"
                >
                  戻る
                </button>
                <button
                  class="btn btn-light btn-outline-secondary"
                  @click="back('profile')"
                  v-else
                >
                  戻る
                </button>
              </div>
              <div
                class="text-center col-10 offset-1 order-1 order-md-2 col-md-6 offset-md-0 mb-2"
              >
                <!-- <button class="btn btn-primary" @click="update_regist()" v-if="page_mode=='confirm'">送信する</button> -->
                <button
                  class="btn btn-primary"
                  @click="proceed('mfj_check')"
                  v-if="page_mode == 'confirm'"
                >
                  申請する
                </button>
                <button
                  class="btn btn-primary"
                  @click="proceed('confirm')"
                  v-else
                >
                  確認する
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.375rem;
    /* border-bottom: solid 1px #999; */
  }
  .form-control-plaintext {
    /* border-bottom: solid 1px #999; */
  }
</style>
<script>
  import { mapState } from 'vuex'
  import StepView from './StepView.vue'
  // import CartView from './CartView.vue'
  // import BsPageTitle from '../../molecules/BsPageTitle.vue'
  // import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
  import BsSelect from '../../atoms/BsSelect.vue'
  // import BsCheckBox from '../../atoms/BsCheckBox.vue'
  // import '../../assets/bootstrap/css/sc.css';
  import config from '../../../const'

  export default {
    name: 'ClubRegistFormView',
    data: function () {
      return {
        page_mode: 'profile',
        update_result: false,
        update_error: false,
        regist_obj: {},
        data_obj: {},
        message_class: 'info',
        message: '',
        target_step: ['kiyaku', 'mail'],
        post_mail_address: '',
        members: [],
        member_form_obj: {
          license_no: '',
          yomi_label: '',
          licensse: [],
          sports_year: '',
          pref_cd_label: '',
        },
      }
    },
    components: {
      StepView,
      //   CartView,
      BsSelect,
      //   BsMsgAlert,
      // BsCheckBox,
    },
    methods: {
      async add_member_form() {
        const tmp = { ...this.member_form_obj }
        this.members.push(tmp)
      },
      proceed(mode) {
        this.page_mode = mode
        if (mode == 'license_sel') {
          this.message = 'ライセンスを選択してください。'
        } else if (mode == 'form') {
          this.message = '個人情報を入力してください。'
        }
      },
      back(mode) {
        this.message_class = 'info'
        this.page_mode = mode
        if (mode == 'license_sel') {
          this.message = 'ライセンスを選択してください。'
        } else if (mode == 'form') {
          this.message = '個人情報を入力してください。'
        } else if (mode == 'license') {
          this.message = '下記項目を選択、入力してください。'
        }
      },
      async update_regist() {
        const params = {}
        await this.nonloginPosts_I(config.BASE_API_URL + '/regist/edit', params)
          .then(async (response) => {
            console.log('update_regist!!!', response)
            if (response.data && response.data.status == 200) {
              this.update_result = true
              this.update_error = false
              this.data_obj =
                response.data && response.data.body && response.data.body[0]
                  ? response.data.body[0]
                  : {}
            } else {
              this.update_error = true
              this.message = '変更申請が見つかりませんでした。'
            }
          })
          .catch(async (err) => {
            this.update_result = false
            this.update_error = true
            this.message = err
            console.log('error', err)
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadClubRegistData() {
        const params = {}
        params['stat'] = [0]
        params['token'] = this.$route.query.token
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/club_regist/lists',
          params
        )
          .then(async (response) => {
            console.log('loadClubRegistData!!!', response)
            if (response.data && response.data.status == 200) {
              this.regist_obj =
                response.data && response.data.body && response.data.body[0]
                  ? response.data.body[0]
                  : {}
              if ((this.regist_obj || {}).step != 'add') {
                console.log('not add redirect')
              }
            } else {
              this.message = '下記項目を入力して申請を続けてください。'
            }
          })
          .catch(async (err) => {
            this.message = err
            console.log('error', err)
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      // back(){
      // }
    },
    computed: {
      ...mapState({}),
      cate_list: function () {
        return [
          'MFJ公認競技会',
          'MFJ承認競技会・行事',
          'MFJライセンス取得講習会',
          'スクール・走行会・試乗会',
        ]
      },
      type_list: function () {
        return [
          '公認クラブ',
          '地区専門部会',
          '賛助会員',
          'ネットワークショップ',
          'インストラクター',
          '特別会員',
          '全日本オーガナイザー',
        ]
      },
      message_class_style: function () {
        const message_class = this.message_class
        return function (target) {
          if (target == 'border') {
            return (
              'text-center py-2 bs_fs_5 mb-2 border-bottom border-' +
              message_class
            )
          } else if (target == 'alert') {
            return 'alert alert-' + message_class
          }
        }
      },
      member_mode: function () {
        return this.page_mode == 'member' ? true : false
      },
      license_mode: function () {
        return this.page_mode == 'license' ? true : false
      },
      license_sel_mode: function () {
        return this.page_mode == 'license_sel' ? true : false
      },
      profile_mode: function () {
        return this.page_mode == 'profile' ? true : false
      },
      confirm_mode: function () {
        return this.page_mode == 'confirm' ? true : false
      },
      member_chk_mode: function () {
        return this.page_mode == 'member_check' ? true : false
      },
      check_mode: function () {
        return this.page_mode == 'mfj_check' ? true : false
      },
      input_class: function () {
        if (this.page_mode == 'confirm') {
          return 'form-control-plaintext'
        } else {
          return 'form-control'
        }
      },
      input_readonly: function () {
        if (this.page_mode == 'confirm') {
          return true
        } else {
          return false
        }
      },
    },

    async created() {
      await this.loadClubRegistData()

      await this.add_member_form()
      await this.add_member_form()
      await this.add_member_form()
      await this.add_member_form()
      await this.add_member_form()
    },
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
    props: {},
  }
</script>

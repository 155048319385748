import * as types from './mutation-types'
import { Auth } from '../api'
import OAuth from '../api/oauth'
import CAuth from '../api/cauth'
import Basic from '../api/basic'

export default {
  setSearchParam: ({ commit }, data) => {
    console.log('loadSearchParam setSearchParam', data)
    const payload = [data.data, data.target]
    commit(types.FETCH_ALL_SEARCHPARAM, payload)
  },
  setPager: ({ commit }, data) => {
    console.log('setPager', data)
    const payload = []
    payload[data.target] = data.data
    commit(types.FETCH_ALL_PAGER, payload)
  },
  login: ({ commit }, authInfo) => {
    return Auth.login(authInfo)
      .then(({ token, userId, label, type }) => {
        console.log('login logincheck', token, userId, label, type)
        localStorage.setItem('token', token)
        commit(types.AUTH_LOGIN, { token, userId, label, type })
      })
      .catch((err) => {
        throw err
      })
  },
  licenseLogin: ({ commit }, authInfo) => {
    return Auth.licenseLogin(authInfo)
      .then(({ token, userId, label }) => {
        console.log('login logincheck', token, userId, label)
        localStorage.setItem('token', token)
        commit(types.AUTH_LOGIN, { token, userId, label })
      })
      .catch((err) => {
        throw err
      })
  },
  tokenLogin: ({ commit }, authInfo) => {
    return Auth.tokenLogin(authInfo)
      .then(({ token, userId, label, type }) => {
        console.log('tokenLogin logincheck', token, userId, label, type)
        localStorage.setItem('token', token)
        commit(types.AUTH_LOGIN, { token, userId, label, type })
        return { token: token, userId: userId, label: label, type: type }
      })
      .catch((err) => {
        throw err
      })
  },
  logout: ({ commit, state }) => {
    console.log('actionjs logout', commit, state);
    return Auth.logout(state.auth.token)
      .then(() => {
        console.log('logout token check1 ', localStorage.getItem('token'))
        localStorage.removeItem('token')
        console.log('logout token check2 ', localStorage.getItem('token'))
        commit(types.AUTH_LOGOUT, { token: null, userId: null })
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        localStorage.removeItem('token')
        commit(types.AUTH_LOGOUT, { token: null, userId: null })
      })
  },
  ologin: ({ commit }, authInfo) => {
    console.log('actionjs ologout', commit, authInfo);
    return OAuth.ologin(authInfo)
      .then(({ token, userId, label, type, owner_no }) => {
        console.log('ologin logincheck', token, userId, label, type)
        localStorage.setItem('otoken', token)
        commit(types.OAUTH_LOGIN, { token, userId, label, type, owner_no })
        return { token: token, userId: userId, label: label, type: type, owner_no: owner_no }
      })
      .catch((err) => {
        throw err
      })
  },
  otokenLogin: ({ commit }, authInfo) => {
    return OAuth.otokenLogin(authInfo)
      .then(({ token, userId, label, type }) => {
        console.log('otokenLogin logincheck', token, userId, label, type)
        localStorage.setItem('otoken', token)
        commit(types.OAUTH_LOGIN, { token, userId, label, type })
        return { token: token, userId: userId, label: label, type: type }
      })
      .catch((err) => {
        throw err
      })
  },
  ologout: ({ commit, state }) => {
    return OAuth.ologout(state.auth.otoken)
      .then(() => {
        console.log('ologout token check1 ', localStorage.getItem('otoken'))
        localStorage.removeItem('otoken')
        console.log('ologout token check2 ', localStorage.getItem('otoken'))
        commit(types.OAUTH_LOGOUT, { token: null, userId: null })
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        localStorage.removeItem('otoken')
        commit(types.OAUTH_LOGOUT, { token: null, userId: null })
      })
  },
  clogin: ({ commit }, authInfo) => {
    return CAuth.clogin(authInfo)
      .then(({ token, userId, label, type }) => {
        console.log('clogin logincheck', token, userId, label, type)
        localStorage.setItem('ctoken', token)
        commit(types.CAUTH_LOGIN, { token, userId, label, type })
        return { token: token, userId: userId, label: label, type: type }
      })
      .catch((err) => {
        throw err
      })
  },
  ctokenLogin: ({ commit }, authInfo) => {
    return CAuth.ctokenLogin(authInfo)
      .then(({ token, userId, label, type }) => {
        console.log('ctokenLogin logincheck', token, userId, label, type)
        localStorage.setItem('ctoken', token)
        commit(types.CAUTH_LOGIN, { token, userId, label, type })
        return { token: token, userId: userId, label: label, type: type }
      })
      .catch((err) => {
        throw err
      })
  },
  clogout: ({ commit, state }) => {
    return CAuth.clogout(state.auth.ctoken)
      .then(() => {
        console.log('clogout token check1 ', localStorage.getItem('ctoken'))
        localStorage.removeItem('ctoken')
        console.log('clogout token check2 ', localStorage.getItem('ctoken'))
        commit(types.CAUTH_LOGOUT, { token: null, userId: null })
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        localStorage.removeItem('ctoken')
        commit(types.OAUTH_LOGOUT, { token: null, userId: null })
      })
  },
  fetchBasicLists: ({ commit, state }, data, target, targetF) => {
    console.log('commit fetch basic lists:', data, target, targetF)
    return Basic.fetch(state.auth.token, data, target, targetF)
      .then(({ basic, target, pager, search_param }) => {
        console.log('actions fetch basic', basic, target, pager, search_param)
        const payload = [basic, target]
        commit(types.FETCH_ALL_BASICLIST, payload)
        if (pager) {
          commit(types.FETCH_ALL_PAGER, pager)
        }
        if (search_param) {
          commit(types.FETCH_ALL_SEARCHPARAM, search_param)
        }
      })
      .catch((err) => {
        throw err
      })
  },

  updateBasic: ({ commit, state }, data, target) => {
    // console.log('actions.js 開始', data);
    return new Promise((resolve, reject) => {
      Basic.update(state.auth.token, data, target)
        .then((res) => {
          console.log('updateBasic AAAA', res)
          commit(types.UPDATE_BASIC, data, target)
          resolve(res)
        })
        .catch((err) => {
          console.log('updateBasic ERR', err)
          reject(err)
        })
        .finally(async () => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        })
      // .catch(err => { throw err })
    })
    // return Basic.update(state.auth.token, data, target)
    //   .then((res) => {
    //     console.log('updateBasic AAAA', res);
    //     commit(types.UPDATE_BASIC, data, target);
    //     // resolve(res);
    //   })
    //   .catch(err => { throw err })
  },
}

<template>
  <section class="py-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1">
          <div class="container mb-2 py-3">
            <h5 class="title">公認クラブ継続申請</h5>
          </div>
          <div class="container py-3" v-if="club_last_year>=get_term_year">
            <div class="row">
              <div class="col">
                <p class="box box--center mb-24">
                  {{ get_term_year }}年度の申請は完了しています。
                </p>
              </div>
            </div>
          </div>
          <div class="container py-3" v-else-if="[100, 200].includes(parseInt((application_obj || {}).application_check_type))">
            <div class="row">
              <div class="col">
                <p class="box box--center mb-24">
                  申請中です
                </p>
              </div>
            </div>
          </div>
          <div class="container py-3" v-else>
            <p class="box box--center mb-24" v-html="message">
            </p>
          </div>
          <!-- {{ application_type_obj }} -->
          <div class="container mb-3">
            <div class="row mb-2">
              <h5 class="title02">申請年度</h5>
            </div>
          </div>
          <div class="container mb-4">
            <div class="row">
              <div class="col-12 col-sm-10 offset-sm-1">
                <div class="row">
                  <div class="col-12 col-md-4 col-lg-3 mb-2">
                    <div class="d-grid">
                      <span class="button03" v-if="last_sports_year==0">
                        新規
                      </span>
                      <span class="button03" v-else-if="last_sports_year>0">
                        {{ last_sports_year }}年
                      </span>
                    </div>
                  </div>
                  <div class="col-12 col-md-2 col-lg-2 mb-2 text-center">
                    <span class="d-none d-md-inline">→</span>
                    <span class="d-inline d-md-none">↓</span>
                  </div>
                  <div
                    class="col-12 col-md-4 col-lg-3 mb-2"
                    v-for="year in continue_year_list"
                    :key="year.key"
                  >
                    <div class="d-grid">
                      <button class="button06">
                        {{ year.label }}年
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container mb-3">
            <div class="row mb-2">
              <h5 class="title02">登録情報</h5>
            </div>
          </div>
          <div class="container mb-3">
            <div class="page__inputGroup">
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner"><span class="page__inputLabel page__inputGroupLabel">クラブコード</span>
                  <span class="showData page__inputGroupInput">{{ form_obj.club_no }}</span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner"><span class="page__inputLabel page__inputGroupLabel">クラブ名</span>
                  <span class="showData page__inputGroupInput">{{ form_obj.name_1 }}</span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner"><span class="page__inputLabel page__inputGroupLabel">カナ</span>
                  <span class="showData page__inputGroupInput">{{ form_obj.yomi_1 }}</span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <div class="page__inputGroupInner"><span class="page__inputLabel page__inputGroupLabel">代表者</span>
                  <div class="d-flex w-100">
                    <div class="w-50">
                      <p class="page__inputGroupContent">
                        <span class="col-2">姓</span>
                        <span class="showData page__inputGroupInput">{{ form_obj.name_2 }}</span>
                      </p>
                    </div>
                    <div class="w-50">
                      <p class="page__inputGroupContent">
                        <span class="col-2">名</span>
                        <span class="showData page__inputGroupInput">{{ form_obj.name_3 }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="page__inputGroupCol">
                <div class="page__inputGroupInner"><span class="page__inputLabel page__inputGroupLabel">カナ</span>
                  <div class="d-flex w-100">
                    <div class="w-50">
                      <p class="page__inputGroupContent">
                        <span class="col-2">セイ</span>
                        <span class="showData page__inputGroupInput">{{ form_obj.yomi_2 }}</span>
                      </p>
                    </div>
                    <div class="w-50">
                      <p class="page__inputGroupContent">
                        <span class="col-2">メイ</span>
                        <span class="showData page__inputGroupInput">{{ form_obj.yomi_3 }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="page__inputGroupCol">
                <div class="page__inputGroupInner"><span class="page__inputLabel page__inputGroupLabel">〒・都道府県</span>
                  <div class="page__inputWrap">
                    <p class="page__inputGroupContent">
                      <span class="col-2">〒</span>
                      <span class="showData page__inputGroupInput">{{ form_obj.postal_cd }}</span>
                      <button
                        v-if="!change_mode"
                        type="button"
                        class="button"
                        @click="get_address()"
                      >
                        検索
                      </button>
                    </p>
                  </div>
                  <div class="page__inputWrap">
                    <p class="page__inputGroupContent">
                      <span class="col-2">都道府県</span>
                      <span v-if="change_mode" class="showData page__inputGroupInput">{{ get_pref_label(form_obj.pref_cd) }}</span>
                      <bs-select
                        v-else
                        :options="pref_list"
                        :nullOption="false"
                        v-model="form_obj.pref_cd"
                        :readonly="input_readonly"
                      ></bs-select> 
                    </p>
                  </div>
                </div>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner"><span class="page__inputLabel page__inputGroupLabel">市区町村・番地</span>
                  <span class="showData page__inputGroupInput">{{ form_obj.address_1 }}</span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner"><span class="page__inputLabel page__inputGroupLabel">建物名・室号</span>
                  <span class="showData page__inputGroupInput">{{ form_obj.address_2 }}</span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner"><span class="page__inputLabel page__inputGroupLabel">電話番号1</span>
                  <span class="showData page__inputGroupInput">{{ form_obj.tel_1 }}</span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner"><span class="page__inputLabel page__inputGroupLabel">電話番号2</span>
                  <span class="showData page__inputGroupInput">{{ form_obj.tel_2 }}</span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <div class="page__inputGroupInner"><span class="page__inputLabel page__inputGroupLabel">活動種目</span>
                  <div class="col-10 offset-1 offset-lg-0 col-lg-10">
                    <div class="row">
                      <div
                        class="col-6 col-md-4 col-lg-4 mb-2"
                        v-for="event in event_list"
                        :key="event"
                      >
                        <div class="d-grid">
                          <span :class="event_class(event.id)" disabled="disabled">
                            {{ event.label }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="row d-none">
                      <div
                        class="button"
                        v-for="license_event in license_event_list"
                        :key="license_event"
                      >
                        <div class="d-grid">
                          <span
                            :class="license_event_class(license_event.id)"
                            disabled="disabled"
                          >
                            {{ license_event.label }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="page__inputGroupCol">
                <p class="page__inputGroupInner"><span class="page__inputLabel page__inputGroupLabel">URL</span>
                  <span class="showData page__inputGroupInput">{{ form_obj.website_url_1 }}</span>
                </p>
              </div>
              <div class="page__inputGroupCol">
                <div class="page__inputGroupInner">
                  <span class="page__inputLabel page__inputGroupLabel">クラブメンバー<br>({{ continue_check_count }} / {{ more_person_num }})</span>
                  <div class="container mb-5">
                    <div class="row mb-2 border-bottom pb-2">
                      <div class="col-6 col-md-2">
                        <div>ライセンスNo</div>
                      </div>
                      <div class="col-6 col-md-2">
                        <div>会員名</div>
                      </div>
                      <!-- <div class="col-6 col-md-2">
                        <div>カナ</div>
                      </div> -->
                      <div class="col-3 col-md-2">
                        <div>参加年度</div>
                      </div>
                      <div class="col-3 col-md-2">
                        <div>都道府県</div>
                      </div>
                      <div class="col-6 col-md-4">
                        <div>{{get_term_year}}年度ライセンス</div>
                      </div>
                      <!-- <div class="col-6 col-md-2">
                        <div>承認日時</div>
                      </div> -->
                    </div>
                    <div
                      class="row mb-2 border-bottom pb-2"
                      v-for="rel in m_relation_list"
                      :key="rel"
                    >
                      <div class="col-6 col-md-2">
                        <div>
                          {{ (rel || {}).license_no }}
                        </div>
                      </div>
                      <div class="col-6 col-md-2">
                        <div>
                          {{ ((rel || {}).m_data || {}).name_label }}
                        </div>
                      </div>
                      <!-- <div class="col-6 col-md-2">
                        <div>
                          {{ ((rel || {}).m_data || {}).yomi_label }}
                        </div>
                      </div> -->
                      <div class="col-3 col-md-2">
                        <div>
                          <!-- {{ ((rel || {}).m_data || {}).last_sports_year }} -->
                          {{ (rel || {}).license_year }}
                        </div>
                      </div>
                      <div class="col-3 col-md-2">
                        <div>
                          {{ ((rel || {}).m_data || {}).pref_label }}
                        </div>
                      </div>
                      <div class="col-6 col-md-4">
                        <div v-for="license in continue_check_license(rel?.m_data?.member_license)"
                        :key="license.id">
                        {{ license.license_label }}
                        </div>
                      </div>
                      <!-- <div class="col-6 col-md-2">
                        <div>
                          {{
                            ((rel || {}).m_data || {}).permission_date_datetime_label
                          }}
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row" v-if="!(application_obj || {}).application_check_type  || [600].includes(parseInt((application_obj || {}).application_check_type))">
              <div class="col-3 col-lg-4 text-start">
                <button
                  class="button03"
                  @click="back"
                  v-if="change_mode"
                >
                  戻る
                </button>
                <button
                  class="button03"
                  @click="back"
                  v-else
                >
                  戻る
                </button>
              </div>
              <div class="col-6 col-lg-4" v-if="club_last_year<get_term_year && ((application_obj || {}).application_check_type == 600 || Object.values(application_obj).length <= 0)">
                <div
                  class="d-grid"
                  
                >
                  <button
                    v-if="change_mode"
                    type="button"
                    @click="go_app"
                    :class="continue_check?  'button04': 'button03'"
                    :disabled="(continue_check)? false: true"
                  >
                    申請する
                  </button>
                  <button
                    v-else
                    type="button"
                    @click="confirm_chk"
                    class="button04"
                  >
                    確認する
                  </button>
                </div>
                <div v-if="!continue_check">
                  公認クラブの申請には、有効ライセンス(ENJOY以外)をお持ちのクラブメンバー{{more_person_num}}名以上の登録が必要です。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.375rem;
    border-bottom: solid 1px #999;
  }
  .form-control-plaintext {
    border-bottom: solid 1px #999;
  }
  .button06 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px 4px 12px;
    color: var(--color02);
    background: var(--color03);
    border: 1px var(--color03) solid;
    border-radius: 4px;
    font-weight: bold;
    font-family: inherit;
    font-size: 16px;
    line-height: 1.7em;
    text-decoration: none;
    cursor: default;
  }
  .page__inputWrap {
    width: 50%;
  }
  @media screen and (max-width: 576px) {
    .page__inputWrap {
      width: 100%;
    }
  }
</style>

<script>
  import { mapState } from 'vuex'
  // import BsPageTitle from '../../molecules/BsPageTitle.vue'
  // import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
  // import BsSelect from '../../atoms/BsSelect.vue'
  // import BsCheckBox from '../../atoms/BsCheckBox.vue'
  // import '../../assets/bootstrap/css/sc.css';
  import BsSelect from '../../atoms/BsSelect.vue'
  import Common from '../../../common/common'
  import config from '../../../const'

  export default {
    name: 'RequestContinueView',
    data: function () {
      return {
        targetid: this.$store.state.cauth.userId,
        btnLabel: '',
        data_obj: {},
        form_obj: {},
        change_login_id: '',
        confirm_login_id: '',
        change_mode: true,
        message:
          '登録された情報を変更する場合は、各項目から変更申請を行って下さい。<br>変更がなければ申請を行って下さい',
        pref_obj: [],
        request_type_cd_obj: [],
        application_obj: {},
        application_type_obj: [],
        application_check_type_obj: [],
        shipping_address_obj: [],
        event_cd_obj: [],
        event_relation_obj: [],
        license_event_cd_obj: [],
        license_event_relation_obj: [],
        m_relation_obj: [],
        race_sports_year_obj: [],
        term_year_obj: [],
        // more_person_num: (this.get_base_env=='development')? 1: 5,
        last_sports_year: 0,
        continue_check_license_obj: []
      }
    },
    components: {
      BsSelect,
      //   BsMsgAlert,
      // BsCheckBox,
    },
    methods: {
      async loadRaceSportsYear(){
        const params = {};
        await this.basicPosts_S(
            config.BASE_API_URL + '/race_sports_year/lists',
            params
        )
        .then(
            async (response) => {
                console.log('loadRaceSportsYear response', response);
                this.race_sports_year_obj = (response.data && response.data.body)? response.data.body: [];
            }
        )
        .catch(
            async (err) => {
            console.log(err);
            this.race_sports_year_obj = [];
        })
        .finally(async () => {
            this.$forceUpdate();
        });
      },
      async loadTermYear(){
        const params = {};
        await this.basicPosts_S(
            config.BASE_API_URL + '/term_year/club_continue_lists',
            params
        )
        .then(
            async (response) => {
                console.log('loadTermYear response', response);
                this.term_year_obj = (response.data && response.data.body)? response.data.body: [];
            }
        )
        .catch(
            async (err) => {
            console.log(err);
            this.term_year_obj = [];
        })
        .finally(async () => {
            this.$forceUpdate();
        });
      },
      async loadConfigObj(param) {
        const params = {}
        await this.basicPosts_S(
          config.BASE_API_URL + '/config/' + param,
          params
        )
          .then(async (response) => {
            console.log('loadConfigObj response', response)
            this[param + '_obj'] =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this[param + '_obj'] = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadPrefList() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(
          config.BASE_API_URL + '/area_pref/lists',
          params
        )
          .then(async (response) => {
            console.log('loadPrefList response', response)
            this.pref_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.pref_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadClubObj() {
        const params = {}
        params['stat'] = [1]
        params['id'] = this.$store.state.cauth.userId
        await this.basicPosts_S(config.BASE_API_URL + '/club/lists', params)
          .then(async (response) => {
            console.log('loadClubObj', response)
            this.data_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            await this.setClubObj()
            this.$forceUpdate()
          })
      },
      async loadEventCdObj() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(config.BASE_API_URL + '/event_cd/lists', params)
          .then(async (response) => {
            console.log('loadEventCdObj', response)
            this.event_cd_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.event_cd_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadEventRelationObj() {
        const params = {}
        params['stat'] = [1]
        params['c_id'] = this.$store.state.cauth.userId
        console.log('loadEventRelationObj params', params)
        await this.basicPosts_S(
          config.BASE_API_URL + '/club_event_relation/lists',
          params
        )
          .then(async (response) => {
            console.log('loadEventRelationObj', response)
            this.event_relation_obj =
              response.data && response.data.body ? response.data.body : []
            this.event_relation_obj.map(relation => {
              if(parseInt(relation.event_year) > parseInt(this.last_sports_year)){
                this.last_sports_year = relation.event_year
              }
            })
          })
          .catch(async (err) => {
            console.log(err)
            this.event_relation_obj = []
          })
          .finally(async () => {
            // await this.setClubObj();
            this.$forceUpdate()
          })
      },
      async loadLicenseCdObj() {
        const params = {}
        params['stat'] = [1]
        // ENJOY以外
        params['license_categories'] = [1, 2, 4, 5]
        await this.basicPosts_S(
          config.BASE_API_URL + '/license_cd/lists',
          params
        )
          .then(async (response) => {
            console.log('loadLicenseCdObj', response)
            this.continue_check_license_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.continue_check_license_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadLicenseEventCdObj() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(
          config.BASE_API_URL + '/license_event_cd/lists',
          params
        )
          .then(async (response) => {
            console.log('loadLicenseEventCdObj', response)
            this.license_event_cd_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.license_event_cd_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadLicenseEventRelationObj() {
        const params = {}
        params['stat'] = [1]
        params['c_id'] = this.$store.state.cauth.userId
        console.log('loadLicenseEventRelationObj params', params)
        await this.basicPosts_S(
          config.BASE_API_URL + '/club_license_event_relation/lists',
          params
        )
          .then(async (response) => {
            console.log('loadLicenseEventRelationObj', response)
            this.license_event_relation_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.license_event_relation_obj = []
          })
          .finally(async () => {
            // await this.setClubObj();
            this.$forceUpdate()
          })
      },
      async loadApplicationObj() {
        const params = {}
        params['stat'] = [1]
        params['c_id'] = this.$store.state.cauth.userId
        params['not_application_check_types'] = [500, 900]
        await this.basicPosts_S(
          config.BASE_API_URL + '/club_application/lists',
          params
        )
          .then(async (response) => {
            console.log('loadApplicationObj', response)
            this.application_obj = (response.data && response.data.body && response.data.body[0])? response.data.body[0]: {};
            // this.application_obj =
              // response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.application_obj = {}
          })
          .finally(async () => {
            await this.setClubObj()
            this.$forceUpdate()
          })
      },
      async setClubObj() {
        if (this.data_obj.tel_1 == '--') {
          this.data_obj.tel_1 = ''
        }
        if (this.data_obj.tel_2 == '--') {
          this.data_obj.tel_2 = ''
        }
        if (
          this.data_obj?.postal_cd &&
          this.data_obj.postal_cd.indexOf('-') < 0 &&
          this.data_obj.postal_cd.length > 3
        ) {
          const start_code = this.data_obj.postal_cd.substr(0, 3)
          const end_code = this.data_obj.postal_cd.substr(3)
          this.data_obj.postal_cd = start_code + '-' + end_code
        }

        this.form_obj = {
          club_no: this.data_obj.club_no,
          name_1: this.data_obj.name_1,
          name_2: this.data_obj.name_2,
          name_3: this.data_obj.name_3,
          yomi_1: this.data_obj.yomi_1,
          yomi_2: this.data_obj.yomi_2,
          yomi_3: this.data_obj.yomi_3,
          postal_cd: this.data_obj.postal_cd,
          pref_cd: this.data_obj.pref_cd,
          address_1: this.data_obj.address_1,
          address_2: this.data_obj.address_2,
          tel_1: this.data_obj.tel_1,
          tel_2: this.data_obj.tel_2,
          club_assets: this.data_obj.club_assets,
          website_url_1: this.data_obj.website_url_1,
          license_event_relation: this.data_obj.license_event_relation,
          // mail_1: this.data_obj.mail_1,
          // mail_2: this.data_obj.mail_2,
          // mail_flg: this.data_obj.mail_flg,
        }
      },
      async confirm_chk() {
        // if(
        //     this.change_login_id == '' || this.confirm_login_id == ''
        // ){
        //     alert('変更するログインIDを入力して下さい');
        //     return true;
        // }
        // else if(
        //     this.change_login_id != '' && this.confirm_login_id != ''
        //     && this.change_login_id != this.confirm_login_id
        // ){
        //     alert('入力されたログインIDが一致しません');
        //     return true;

        // }
        this.change_mode = true
      },
      async go_app() {
        const confirm_msg = this.get_term_year + '年度の申請を行います。よろしいですか？'
        if(
          !window.confirm(confirm_msg)
        ){
          return
        }
        const items = []
        items.push({
          application_id: (this.application_obj?.id)? this.application_obj.id: 0,
          application_key: 'mfj_outdb_data@last_sports_year',
          application_value: this.last_sports_year
        })
        items.push({
          application_id: (this.application_obj?.id)? this.application_obj.id: 0,
          application_key: 'mfj_outdb_data@next_sports_year',
          application_value: this.get_term_year
        })
        

        const params = {
          application_check_type: (this.application_obj||{}).application_check_type == 600? 120: 100,
          application_type: 21300,
          c_id: this.targetid,
          items: typeof items === 'object' && items.length > 0 ? items : false
        }
          
        const post_url = (this.application_obj||{}).id
          ? config.BASE_API_URL + '/club_application/edit/' + (this.application_obj||{}).id
          : config.BASE_API_URL + '/club_application/add'
        await this.basicPosts_I(
          post_url,
          params
        )
          .then(async (response) => {
            console.log('update', response)
          })
          .catch(async (err) => {
            console.log(err)
          })
          .finally(async () => {
            await this.loadClubObj()
            await this.loadApplicationObj();
            alert('申請しました')
            this.$forceUpdate()
          })
      },
      async update() {
        console.log('component methods', this.data_obj)

        const params = {
          name_1: this.form_obj.name_1,
          name_2: this.form_obj.name_2,
          yomi_1: this.form_obj.yomi_1,
          yomi_2: this.form_obj.yomi_2,
          birth_date: this.form_obj.birth_date,
          profile: [],
          club_assets: this.form_obj.club_assets,
        }
        let profiles
        let upd_profile = {
          id: 0,
          c_id: this.$store.state.cauth.userId,
          postal_cd: '',
          pref_cd: '',
          address_1: '',
          address_2: '',
          tel_1: '',
          tel_2: '',
        }
        if (
          typeof this.data_obj === 'object' &&
          typeof this.data_obj.profile === 'object' &&
          Object.keys(this.data_obj.profile).length > 0
        ) {
          profiles = [...this.data_obj.profile]
          upd_profile = profiles[0]
        } else {
          profiles.push({ ...upd_profile })
        }
        if (this.form_obj.postal_cd) {
          upd_profile.postal_cd = this.form_obj.postal_cd
        }
        if (this.form_obj.pref_cd) {
          upd_profile.pref_cd = this.form_obj.pref_cd
        }
        if (this.form_obj.address_1) {
          upd_profile.address_1 = this.form_obj.address_1
        }
        if (this.form_obj.address_2) {
          upd_profile.address_2 = this.form_obj.address_2
        }
        if (this.form_obj.tel_1) {
          upd_profile.tel_1 = this.form_obj.tel_1
        }
        if (this.form_obj.tel_2) {
          upd_profile.tel_2 = this.form_obj.tel_2
        }

        profiles[0] = upd_profile

        params.profile = profiles

        await this.basicPosts_I(
          config.BASE_API_URL + '/club/edit/' + this.$store.state.cauth.userId,
          params
        )
          .then(async (response) => {
            console.log('update', response)
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            await this.loadClubObj()
            this.message = '更新しました'
            this.$forceUpdate()
          })
      },
      async loadMRelationObj() {
        const params = {}
        params['stat'] = [1]
        params['c_id'] = this.$store.state.cauth.userId
        console.log('loadMRelationObj params', params)
        await this.basicPosts_S(
          config.BASE_API_URL + '/club_m_relation/lists',
          params
        )
          .then(async (response) => {
            console.log('loadMRelationObj', response)


            const tmpss = Array.isArray(response.data.body)? response.data.body: []
            
            const m_relation_tmp = []
            const tmps = tmpss.sort((a, b) => {
              if (parseInt(a.license_year) > parseInt(b.license_year)) {
                return -1
              } else {
                return 1
              }
            })
            tmps.map(relation => {
              if (!m_relation_tmp.find(obj => obj?.m_id == relation?.m_id)) {
                m_relation_tmp.push(relation)
              } else if (
                m_relation_tmp.find(
                  obj =>
                    obj?.m_id == relation?.m_id &&
                    obj?.license_year < relation?.license_year
                )
              ) {
                const index = m_relation_tmp.findIndex(
                  obj =>
                    obj?.m_id == relation?.m_id &&
                    obj?.license_year < relation?.license_year
                )

                m_relation_tmp.splice(index, 1)
                m_relation_tmp.push(relation)
              }
            })
            this.m_relation_obj = m_relation_tmp

            // this.m_relation_obj =
            //   response.data && response.data.body ? response.data.body : []
            // this.m_relation_obj.map(relation => {
            //   if(parseInt(relation.license_year) > parseInt(this.last_sports_year)){
            //     this.last_sports_year = relation.license_year
            //   }
            // })
          })
          .catch(async (err) => {
            console.log(err)
            this.m_relation_obj = []
          })
          .finally(async () => {
            // await this.setClubObj();
            this.$forceUpdate()
          })
      },
      back_input() {
        this.change_mode = false
      },
      back() {
        this.$router.push({ path: '/club/mypage' })
      },
    },
    computed: {
      club_last_year: function(){
        let latestYear = 0
        if(Array.isArray(this.event_relation_obj)){
          this.event_relation_obj.map(relation => {
            if(relation.event_year > latestYear){
              latestYear = relation.event_year
            }
          })
        }
        return parseInt(latestYear)
      },
      more_person_num: function(){
        return (this.get_base_env=='development')? 1: 5
      },
      continue_license: function(){
        const tmps = []
        if(
          Array.isArray(this.continue_check_license_obj)
        ){
          this.continue_check_license_obj.map(license => {
            tmps.push(parseInt(license.id))
          })
        }
        return tmps
      },
      continue_check_license: function(){
        const continue_license = this.continue_license
        const term_year = this.get_term_year
        return function(member_license){
          const tmps = []
          if(
            Array.isArray(continue_license)
            && term_year > 0
            && Array.isArray(member_license)
            // && member_license.find(license => (continue_license.includes(parseInt(license.license_cd)) && license.license_year == term_year))
          ){
            member_license.map(license => {
              if(continue_license.includes(parseInt(license.license_cd)) && license.license_year == term_year){
                tmps.push(license)
              }
            })
          }
          return (Array.isArray(tmps) && tmps.length > 0)? tmps: false
        }
      },
      continue_check_count: function(){
        if(
          Array.isArray(this.m_relation_list) && 
          Array.isArray(this.m_relation_list.filter(relation => {
            if(
              relation?.m_data?.last_sports_year >= this.get_term_year
              && Array.isArray(relation?.m_data?.member_license) 
              && Array.isArray(this.continue_check_license(relation?.m_data?.member_license))
            ){
              return true
            }
            return false
          }))
        ){
          return this.m_relation_list.filter(relation => {
            if(
              relation?.m_data?.last_sports_year >= this.get_term_year
              && Array.isArray(relation?.m_data?.member_license) 
              && Array.isArray(this.continue_check_license(relation?.m_data?.member_license))
            ){
              return true
            }
            return false
          }).length
        }
        return 0
      },
      continue_check: function(){
        if(
          Array.isArray(this.m_relation_list) && 
          this.m_relation_list.filter(relation => {
            if(
              relation?.m_data?.last_sports_year >= this.get_term_year
              && Array.isArray(relation?.m_data?.member_license) 
              && Array.isArray(this.continue_check_license(relation?.m_data?.member_license))
            ){
              return true
            }
            return false
          })?.length >= this.more_person_num
        ){
          return true
        }
        return false
      },
      race_sports_year_list: function(){
        return this.race_sports_year_obj;
      },
      continue_year_list: function(){
        return this.term_year_obj;
      },
      get_term_year: function(){
            return (
                typeof this.term_year_obj === 'object' 
                && typeof this.term_year_obj[0] === 'object'
                && this.term_year_obj[0]['label']
            )? parseInt(this.term_year_obj[0]['label']): parseInt(Common.getNowYear()); 
        },
      ...mapState({}),
      m_relation_list: function () {
        const m_relation_obj = this.m_relation_obj
        return m_relation_obj.sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
        // return this.m_relation_obj;
      },
      get_assets: function () {
        const form_obj = this.form_obj
        return function (type_cd, sort_key) {
          if (
            typeof form_obj === 'object' &&
            typeof form_obj.club_assets === 'object' &&
            form_obj.club_assets.find((asset) => asset.type == type_cd) !==
              false &&
            typeof form_obj.club_assets.filter(
              (asset) => asset.type == type_cd
            ) === 'object' &&
            typeof form_obj.club_assets.filter(
              (asset) => asset.type == type_cd
            )[sort_key] === 'object'
          ) {
            // console.log('get_assets', form_obj.club_assets.filter(asset => asset.type == type_cd));
            return form_obj.club_assets.filter(
              (asset) => asset.type == type_cd
            )[sort_key]
          }
          return {}
        }
      },
      get_pref_label: function () {
        const pref_list = this.pref_list
        return function (key) {
          if (typeof pref_list.find((pref) => pref.key == key) === 'object') {
            return pref_list.find((pref) => pref.key == key).label
          }
          return key
        }
      },
      get_request_cd_label: function () {
        const lists = this.request_type_list
        return function (key) {
          if (typeof lists.find((list) => list.key == key) === 'object') {
            return lists.find((list) => list.key == key).label
          }
          return key
        }
      },
      event_class: function () {
        const relation = this.event_relation_obj
        return function (event_id) {
          if (relation.find((obj) => obj.event_id == event_id)) {
            return 'button06'
          } else {
            return 'text-center py-2 bg-light rounded-2 border border-secondary'
          }
        }
      },
      event_list: function () {
        const lists = [...this.event_cd_obj]
        return lists.sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },
      license_event_class: function () {
        const relation = this.license_event_relation_obj
        return function (license_event_id) {
          if (
            relation.find((obj) => obj.license_event_id == license_event_id)
          ) {
            return 'button'
          } else {
            return 'text-center py-2 bg-light rounded-2 border border-secondary'
          }
        }
      },
      license_event_list: function () {
        const lists = [...this.license_event_cd_obj]
        return lists.sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },
      request_type_list: function () {
        return this.request_type_cd_obj
      },
      pref_list: function () {
        const lists = [...this.pref_obj]
        return lists.sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },
      application_type_list: function () {
        return this.application_type_obj
      },
      application_check_type_list: function () {
        return this.application_check_type_obj
      },
      get_club_data: function () {
        return {
          name_1: this.data_obj.name_1,
          name_2: this.data_obj.name_2,
          line_name_1: this.data_obj.line_name_1,
          line_name_2: this.data_obj.line_name_2,
          name_3: this.data_obj.name_3,
          yomi_1: this.data_obj.yomi_1,
          yomi_2: this.data_obj.yomi_2,
          yomi_3: this.data_obj.yomi_3,
          birth_date: this.data_obj.birth_date,
          postal_cd: this.data_obj.birth_date,
          pref_cd: this.data_obj.birth_date,
          address_1: this.data_obj.address_1,
          address_2: this.data_obj.address_2,
          tel_1: this.data_obj.tel_1,
          tel_2: this.data_obj.tel_2,
          mail_1: this.data_obj.mail_1,
          mail_2: this.data_obj.mail_2,
          mail_flg: this.data_obj.mail_flg,
        }
      },
      input_class: function () {
        if (this.change_mode) {
          return 'form-control-plaintext'
        } else {
          return 'form-control'
        }
      },
      input_readonly: function () {
        if (this.change_mode) {
          return true
        } else {
          return false
        }
      },
    },
    async created() {
      await this.loadConfigObj('request_type_cd')
      await this.loadConfigObj('application_type')
      await this.loadConfigObj('application_check_type')
      await this.loadPrefList()
      await this.loadRaceSportsYear()
      await this.loadLicenseCdObj()
      await this.loadTermYear()
      // await this.loadShippingObj()
      await this.loadClubObj()
      await this.loadApplicationObj()
      await this.setClubObj()
      await this.loadLicenseEventCdObj()
      await this.loadLicenseEventRelationObj()
      await this.loadEventCdObj()
      await this.loadEventRelationObj()
      await this.loadMRelationObj()
    },
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
    props: {},
  }
</script>

import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// 状態`Auth`と状態`Board`をVuexのstateで一元管理できるよう定義する
const state = {
  auth: {
    // 状態`Auth`
    token: localStorage.getItem('token'), // `token`は locale storage から取得する
    userId: null, // `userId`はnullで初期化
    label: null,
    level: null,
    type: null,
    relations: [],
  },
  oauth: {
    // 状態`Auth`
    token: localStorage.getItem('otoken'), // `token`は locale storage から取得する
    userId: null, // `userId`はnullで初期化
    owner_no: null,
    label: null,
    level: null,
    type: null,
    relations: [],
  },
  cauth: {
    // 状態`Auth`
    token: localStorage.getItem('ctoken'), // `token`は locale storage から取得する
    userId: null, // `userId`はnullで初期化
    label: null,
    level: null,
    type: null,
    relations: [],
  },
  apidata: {
    pager: {},
    search_param: {},
    manage_account: [],
    manage_account_level: [],
    sex_type: [],
    pref: [],
    blood_type_rh: [],
    blood_type_abo: [],
    staff_type: [],
    // clinic: [],
    // dr: [],
    input_type: [],
    payment_method: [],
    // personal_type: [],
    tax_rate: [],
    // photo_type: [],
    // photo_type_group: [],
    // process_type: [],
    // treatment_type: [],
    // estimate_type: [],
    // subsub_category: [],
    // sub_category: [],
    // category: [],
    // kikkake_code: [],
    // consulting_purpose: [],
    // consulting_progress: [],
    // consulting_no_prospects_reason: [],
    // consulting_treatment_content: [],
    // consulting_treatment_plan: [],
  },
}
export default createStore({
  strict: false,
  state,
  getters,
  actions,
  mutations,
  plugins: [
    createPersistedState({
      key: 'app',
      storage: window.sessionStorage,
    }),
  ],
})

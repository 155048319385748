<template>
  <div class="theme-user">
    <div class="d-flex flex-column min-vh-100">
      <header class="header" id="header">
        <div class="container">
          <div class="header__inner">
            <h1 class="header__logo">
              <a class="header__logoLink" href="/mfj_renewal/app/">
                <img
                  :src="require('@/assets/img/header_img_01.png')"
                  alt="Motorcycle Federation of Japan"
              /></a>
            </h1>
          </div>
        </div>
      </header>
      <main
        id="main"
        class="flex-grow-1 d-flex align-items-center justify-content-center py-5"
      >
        <BsLoginForm
          v-bind:onlogin="handleLogin"
          v-bind:onlogin2="handleLogin2"
        ></BsLoginForm>
      </main>
      <footer class="footer" id="footer">
        <div class="footer__login">
          <div class="container">
            <ul class="footer__loginUl">
              <li class="footer__loginLi">
                <router-link to="/login" class="text-decoration-none"
                  >会員ログイン</router-link
                >
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="mx-2"
                />
              </li>
              <li class="footer__loginLi">
                <router-link to="/ologin" class="text-decoration-none"
                  >主催者ログイン</router-link
                >
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="mx-2"
                />
              </li>
              <li class="footer__loginLi" v-if="mfj_phase_club_001">
                <router-link to="/clogin" class="text-decoration-none"
                  >公認クラブログイン</router-link
                >
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="mx-2"
                />
              </li>
            </ul>
          </div>
        </div>
        <div class="footer__copy">
          <div class="container">
            <p>©2020 Motorcycle Federation of Japan All RightsReserved.</p>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
  import BsLoginForm from '../molecules/BsLoginForm.vue'
  export default {
    name: 'BsLoginView',
    data() {
      return {
        login_id: '',
        login_pass: '',
        showError: false,
        errorMessage: '',
      }
    },
    computed: {
      disableLoginAction() {
        // validを使ってログイン処理の可否、progressは後述
        return false
      },
    },
    components: {
      BsLoginForm,
    },
    methods: {
      handleLogin(authInfo) {
        console.log('handleLogin')
        return this.$store
          .dispatch('login', authInfo)
          .then(() => {
            this.$router.push({ path: '/' })
          })
          .catch((err) => {
            console.log('handleLogin err', err)
            return Promise.reject(err) // エラーを再スローして、BsLoginFormで処理できるようにする
          })
      },
      handleLogin2(authInfo) {
        console.log('handleLogin2')
        return this.$store
          .dispatch('licenseLogin', authInfo)
          .then(() => {
            this.$router.push({ path: '/' })
          })
          .catch((err) => {
            console.error('Login error:', err)
            return Promise.reject(err) // エラーを再スローして、BsLoginFormで処理できるようにする
          })
      },
      // throwRejectメソッドは不要になるため削除
    },
  }
</script>

<style>
  @import '../../assets/css/style-color.css';
  .header,
  .footer {
    flex-shrink: 0;
  }

  #main {
    overflow-y: auto;
    width: 100%;
  }

  .login__title {
    margin-bottom: 2rem;
  }

  .login__box {
    margin-top: 2rem;
  }
</style>

<template>
  <section class="page">
    <div class="container">
      <h3 class="title02 page__h2">配送先住所変更申請</h3>
      <div class="box box--center mb-24">
        <p v-if="(this.application_obj || {}).application_check_type == 0">
          {{ message }}
        </p>
        <p
          v-else-if="(this.application_obj || {}).application_check_type == 100"
        >
          現在変更申請中です。
        </p>
        <p
          v-else-if="(this.application_obj || {}).application_check_type == 200"
        >
          現在変更申請を確認中です。
        </p>
        <p
          v-else-if="(this.application_obj || {}).application_check_type == 500"
        >
          承認されました。
        </p>
        <p
          v-else-if="(this.application_obj || {}).application_check_type == 600"
        >
          差し戻しがあります。内容を確認して再申請をお願いします。
        </p>
        <p v-else>変更申請フォームです</p>
      </div>
      <form class="mb-40">
        <div class="mb-32">
          <div class="row gy-24">
            <div class="col-md-6">
              <h4 class="title08 d-block mb-24">現在の登録内容</h4>
              <div class="page__inputGroup">
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <span class="page__inputLabel page__inputGroupLabel"
                      >郵便番号</span
                    >
                    <span class="showData page__inputGroupInput">{{
                      shipping_form_obj.postal_cd
                    }}</span>
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <span class="page__inputLabel page__inputGroupLabel"
                      >都道府県</span
                    >
                    <span class="showData page__inputGroupInput">{{
                      get_pref_label(shipping_form_obj.pref_cd)
                    }}</span>
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <span class="page__inputLabel page__inputGroupLabel"
                      >市区町村・番地</span
                    >
                    <span class="showData page__inputGroupInput">{{
                      shipping_form_obj.address_1
                    }}</span>
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <span class="page__inputLabel page__inputGroupLabel"
                      >建物名・室号</span
                    >
                    <span class="showData page__inputGroupInput">{{
                      shipping_form_obj.address_2
                    }}</span>
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <span class="page__inputLabel page__inputGroupLabel"
                      >電話番号</span
                    >
                    <span class="showData page__inputGroupInput">{{
                      shipping_form_obj.tel_1
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h4 class="title08 d-block mb-24">変更する申請内容</h4>
              <div class="page__inputGroup">
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel" for="input041"
                      >郵便番号</label
                    >
                    <span v-if="input_readonly" class="showData fa-18">{{ (app_form_obj || {}).postal_cd }}</span>
                    <template v-else>
                      <input maxlength="8" @change="postal_cd_chk" type="text" v-model="(app_form_obj || {}).postal_cd"
                        :class="input_class" :readonly="input_readonly" class="input page__inputGroupInput" />
                      <button v-if="!change_mode" type="button" class="btn btn-light btn-outline-secondary w-25"
                        @click="get_address((app_form_obj || {}).postal_cd)">
                        検索
                      </button>
                    </template>
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel" for="input042"
                      >都道府県</label
                    >
                    <span v-if="input_readonly" class="showData fa-18">{{ get_pref_label((app_form_obj || {}).pref_cd) }}</span>
                    <template v-else>
                      <input v-if="change_mode" type="text" :class="input_class" :readonly="input_readonly"
                        :value="get_pref_label((app_form_obj || {}).pref_cd)" class="input page__inputGroupInput" />
                      <bs-select v-else :options="pref_list" :nullOption="false" v-model="(app_form_obj || {}).pref_cd"
                        :readonly="input_readonly" :classParam="input_class"></bs-select>
                    </template>
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel" for="input043"
                      >市区町村・番地</label
                    >
                    <span v-if="input_readonly" class="showData fa-18">{{ (app_form_obj || {}).address_1 }}</span>
                    <template v-else>
                      <input type="text" v-model="(app_form_obj || {}).address_1" :class="input_class"
                        :readonly="input_readonly" class="input page__inputGroupInput" />
                    </template>
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel" for="input044"
                      >建物名・室号</label
                    >
                    <span v-if="input_readonly" class="showData fa-18">{{ (app_form_obj || {}).address_2 }}</span>
                    <template v-else>
                      <input type="text" v-model="(app_form_obj || {}).address_2" :class="input_class"
                        :readonly="input_readonly" class="input page__inputGroupInput" />
                    </template>
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel" for="input045"
                      >電話番号</label
                    >
                    <span v-if="input_readonly" class="showData fa-18">{{ formattedPhone }}</span>
                    <template v-else>
                      <input
                        type="text"
                        v-model="(app_form_obj || {}).tel_1_0"
                        :class="input_class"
                        :readonly="input_readonly"
                        class="input page__inputGroupInput"
                      />
                      <input
                        type="text"
                        v-model="(app_form_obj || {}).tel_1_1"
                        :class="input_class"
                        :readonly="input_readonly"
                        class="input page__inputGroupInput"
                      />
                      <input
                        type="text"
                        v-model="(app_form_obj || {}).tel_1_2"
                        :class="input_class"
                        :readonly="input_readonly"
                        class="input page__inputGroupInput"
                      />
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="d-flex mt-32 justify-content-center column-gap-24 button__container">
        <button
          v-if="change_mode && chk_editable"
          type="button"
          @click="back_input"
          class="button03 back__button"
        >
          <font-awesome-icon :icon="['fas', 'chevron-left']" class="" />
          <span class="button03__text"> 入力画面に戻る </span>
        </button>
        <button v-else type="button" @click="back" class="button03 back__button">
          <font-awesome-icon :icon="['fas', 'chevron-left']" class="" />
          <span class="button03__text"> 戻る </span>
        </button>
        <div class="d-grid" v-if="chk_editable">
          <button
            v-if="change_mode"
            type="button"
            @click="update_app"
            class="button04"
          >
            <span class="button03__text"> 更新する </span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="" />
          </button>
          <button v-else type="button" @click="confirm_chk" class="button04">
            <span class="button03__text"> 確認する </span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" class="" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { mapState } from 'vuex'
  import BsSelect from '../../atoms/BsSelect.vue'
  import config from '../../../const'

  export default {
    name: 'UserNameView',
    data: function () {
      return {
        targetid: this.$store.state.auth.userId,
        btnLabel: '',
        data_obj: {},
        form_obj: {},
        app_form_obj: {},
        shipping_form_obj: {},
        shipping_index: 0,
        change_login_id: '',
        confirm_login_id: '',
        change_mode_value: false,
        message: '',
        base_message: {
          base: '登録された配送先住所を変更します。変更申請内容に入力して『確認する』を押して下さい。',
          comfirm:'登録された配送先住所を変更します。入力内容に誤りが無ければ『更新する』を押して下さい。',
        },
        pref_obj: [],
        request_type_cd_obj: [],
        application_obj: {},
        application_type_obj: [],
        application_check_type_obj: [],
        shipping_obj: [],
      }
    },
    components: {
      BsSelect,
    },
    methods: {
      async postal_cd_chk() {
        const tmp = (this.app_form_obj || {}).postal_cd
          ? (this.app_form_obj || {}).postal_cd.match(/([0-9-])/g)
          : ''
        this.app_form_obj.postal_cd =
          tmp != null && typeof tmp === 'object' ? tmp.join('') : ''
      },
      async get_address(postal_cd) {
        await this.basicGets_O(
          'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + postal_cd
        )
          .then((res) => {
            if (
              typeof res === 'object' &&
              typeof res.status === 'number' &&
              res.status == 200 &&
              typeof res.data === 'object' &&
              typeof res.data.results === 'object' &&
              typeof res.data.results[0] === 'object'
            ) {
              this.app_form_obj.pref_cd =
                parseInt(res.data.results[0].prefcode) < 10
                  ? '0' + res.data.results[0].prefcode
                  : res.data.results[0].prefcode
              this.app_form_obj.address_1 =
                res.data.results[0].address2 + res.data.results[0].address3
              this.app_form_obj.address_2 = ''
            } else {
              alert('住所データがありません')
            }
          })
          .catch((error) => {
            console.log(error)
            alert('住所データがありません.')
          })
          .finally(() => {
            this.$forceUpdate()
          })
      },
      async selectedFile(e, type_cd) {
        e.preventDefault()
        const files = [...e.target.files]
        files.forEach(async (file) => {
          const form = new FormData()
          form.append('file', file)
          await this.basicPosts_R(
            config.BASE_API_URL + '/upload/member_assets',
            form
          )
            .then((res) => {
              console.log(res)
              if (res && res.status == 200) {
                const asset_tmp = {
                  id: 0,
                  stat: 1,
                  m_id: this.$store.state.auth.userId,
                  type: 0,
                  path: '',
                }
                const tmps =
                  typeof this.form_obj.member_assets === 'object'
                    ? [...this.form_obj.member_assets]
                    : []
                const asset = tmps.find((tmp) => tmp.type == type_cd)
                  ? tmps.find((tmp) => tmp.type == type_cd)
                  : asset_tmp
                asset.type = type_cd
                asset.path = res.data.body.file_path
                  ? res.data.body.file_path
                  : ''

                if (tmps.find((tmp) => tmp.id == asset.id)) {
                  const fIndex = tmps.findIndex((tmp) => tmp.id == asset.id)
                  tmps[fIndex] = asset
                  this.form_obj.member_assets = tmps
                } else {
                  this.form_obj.member_assets = [asset].concat(tmps)
                }
              }
            })
            .catch((error) => {
              console.log(error)
            })
            .finally(() => {
              document.getElementById('file_up_input_' + type_cd).value = ''
              this.$forceUpdate()
            })
        })
      },
      async clickUpFileBtn() {
        document.getElementById('file_up_input_100').click()
      },
      async loadConfigObj(param) {
        const params = {}
        await this.basicPosts_S(
          config.BASE_API_URL + '/config/' + param,
          params
        )
          .then(async (response) => {
            console.log('loadConfigObj response', response)
            this[param + '_obj'] =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this[param + '_obj'] = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadPrefList() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(
          config.BASE_API_URL + '/area_pref/lists',
          params
        )
          .then(async (response) => {
            console.log('loadPrefList response', response)
            this.pref_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.pref_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadShippingObj() {
        const params = {}
        params['stat'] = [1]
        params['m_id'] = this.$store.state.auth.userId
        params['pageorder'] = 'mfj_member_shipping_address.id'
        params['pagesort'] = 'DESC'
        params['pagegroup'] = 'mfj_member_shipping_address.id'
        await this.basicPosts_S(
          config.BASE_API_URL + '/member_shipping/lists',
          params
        )
          .then(async (response) => {
            console.log('loadShippingObj', response)
            this.shipping_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.shipping_obj = []
          })
          .finally(async () => {
            await this.setShippingObj()
            this.$forceUpdate()
          })
      },
      async setShippingObj() {
        const tmps = (this.shipping_obj || [])[this.shipping_index]
        this.shipping_form_obj = {
          postal_cd: (tmps || {}).postal_cd,
          pref_cd: (tmps || {}).pref_cd,
          address_1: (tmps || {}).address_1,
          address_2: (tmps || {}).address_2,
          tel_1: (tmps || {}).tel_1,
          tel_2: (tmps || {}).tel_2,
        }
      },
      async loadUserObj() {
        const params = {}
        params['stat'] = [1]
        params['id'] = this.$store.state.auth.userId
        // params['pagegroup'] = 'mfj_member_data.id'
        await this.basicPosts_S(config.BASE_API_URL + '/member/lists', params)
          .then(async (response) => {
            console.log('loadUserObj', response)
            this.data_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = {}
          })
          .finally(async () => {
            await this.setUserObj()
            this.$forceUpdate()
          })
      },
      async setUserObj() {
        this.form_obj = {
          name_1: (this.data_obj || {}).name_1,
          name_2: (this.data_obj || {}).name_2,
          yomi_1: (this.data_obj || {}).yomi_1,
          yomi_2: (this.data_obj || {}).yomi_2,
          birth_date: (this.data_obj || {}).birth_date,
          postal_cd: (this.data_obj || {}).postal_cd,
          pref_cd: (this.data_obj || {}).pref_cd,
          address_1: (this.data_obj || {}).address_1,
          address_2: (this.data_obj || {}).address_2,
          tel_1: (this.data_obj || {}).tel_1,
          tel_2: (this.data_obj || {}).tel_2,
          member_assets: (this.data_obj || {}).member_assets,
        }
      },
      async loadApplicationObj() {
        const params = {}
        params['stat'] = [1]
        params['m_id'] = this.$store.state.auth.userId
        params['application_type'] = 2340
        params['not_application_check_types'] = [500, 900]
        await this.basicPosts_S(
          config.BASE_API_URL + '/member_application/lists',
          params
        )
          .then(async (response) => {
            console.log('loadApplicationObj', response)
            this.application_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.application_obj = {}
          })
          .finally(async () => {
            await this.setApplicationObj()
            this.$forceUpdate()
          })
      },
      async setApplicationObj() {
        const tmps =
          typeof this.application_obj === 'object' &&
          Object.keys(this.application_obj).length > 0
            ? this.application_obj
            : {
                id: 0,
                application_check_type: '',
                application_type: '',
                application_date: '',
              }
        this.application_obj = tmps

        const tel_1 = []
        const tel_2 = []

        const tel_1_cnt =
          typeof (
            (
              this.get_target_app_item('mfj_member_shipping_address@tel_1') ||
              {}
            ).application_value || ''
          ).split('-') === 'object'
            ? (
                (
                  this.get_target_app_item(
                    'mfj_member_shipping_address@tel_1'
                  ) || {}
                ).application_value || ''
              ).length
            : 0
        const tel_2_cnt =
          typeof (
            (
              this.get_target_app_item('mfj_member_shipping_address@tel_2') ||
              {}
            ).application_value || ''
          ).split('-') === 'object'
            ? (
                (
                  this.get_target_app_item(
                    'mfj_member_shipping_address@tel_2'
                  ) || {}
                ).application_value || ''
              ).length
            : 0
        for (let i = 0; i < 3; i++) {
          if (tel_1_cnt <= i) {
            tel_1.push('')
          } else {
            tel_1.push(
              (
                (
                  this.get_target_app_item(
                    'mfj_member_shipping_address@tel_1'
                  ) || {}
                ).application_value || ''
              ).split('-')[i]
            )
          }
          if (tel_2_cnt <= i) {
            tel_2.push('')
          } else {
            tel_2.push(
              (
                (
                  this.get_target_app_item(
                    'mfj_member_shipping_address@tel_2'
                  ) || {}
                ).application_value || ''
              ).split('-')[i]
            )
          }
        }

        this.app_form_obj = {
          postal_cd: (
            this.get_target_app_item('mfj_member_shipping_address@postal_cd') ||
            {}
          ).application_value,
          postal_cd_message: (
            this.get_target_app_item('mfj_member_shipping_address@postal_cd') ||
            {}
          ).message,
          pref_cd: (
            this.get_target_app_item('mfj_member_shipping_address@pref_cd') ||
            {}
          ).application_value,
          pref_cd_message: (
            this.get_target_app_item('mfj_member_shipping_address@pref_cd') ||
            {}
          ).message,
          address_1: (
            this.get_target_app_item('mfj_member_shipping_address@address_1') ||
            {}
          ).application_value,
          address_1_message: (
            this.get_target_app_item('mfj_member_shipping_address@address_1') ||
            {}
          ).message,
          address_2: (
            this.get_target_app_item('mfj_member_shipping_address@address_2') ||
            {}
          ).application_value,
          address_2_message: (
            this.get_target_app_item('mfj_member_shipping_address@address_2') ||
            {}
          ).message,
          tel_1_0: tel_1[0],
          tel_1_1: tel_1[1],
          tel_1_2: tel_1[2],
          tel_1_message: (
            this.get_target_app_item('mfj_member_shipping_address@tel_1') || {}
          ).message,
          tel_2_0: tel_2[0],
          tel_2_1: tel_2[1],
          tel_2_2: tel_2[2],
          tel_2_message: (
            this.get_target_app_item('mfj_member_shipping_address@tel_2') || {}
          ).message,
        }
      },
      async confirm_chk() {
        // 住所のバリデーション
        if (
          !this.app_form_obj.postal_cd ||
          !this.app_form_obj.pref_cd ||
          !this.app_form_obj.address_1
        ) {
          alert('変更する住所を入力して下さい。')
          return true
        }

        // 電話番号のバリデーション
        if (
          !this.app_form_obj.tel_1_0 ||
          !this.app_form_obj.tel_1_1 ||
          !this.app_form_obj.tel_1_2
        ) {
          alert('電話番号を入力して下さい。')
          return true
        }

        // 電話番号の形式チェック（数字のみ）
        const telRegex = /^[0-9]+$/
        if (
          !telRegex.test(this.app_form_obj.tel_1_0) ||
          !telRegex.test(this.app_form_obj.tel_1_1) ||
          !telRegex.test(this.app_form_obj.tel_1_2)
        ) {
          alert('電話番号は数字のみで入力して下さい。')
          return true
        }

        // 電話番号の桁数チェック
        if (
          this.app_form_obj.tel_1_0.length < 2 ||
          this.app_form_obj.tel_1_1.length < 2 ||
          this.app_form_obj.tel_1_2.length < 4
        ) {
          alert('正しい電話番号を入力して下さい。')
          return true
        }

        this.change_mode_value = true
        this.message = this.base_message.comfirm
      },
      async update_app() {
        console.log('component methods', this.data_obj)

        const post_url_path =
          this.application_obj.id > 0
            ? '/member_application/edit/' + this.application_obj.id
            : '/member_application/add'

        const params = {
          application_check_type: 500,
          m_id: this.$store.state.auth.userId,
          application_type: 2340,
        }

        const item_column = {
          postal_cd: 'mfj_member_shipping_address@postal_cd',
          pref_cd: 'mfj_member_shipping_address@pref_cd',
          address_1: 'mfj_member_shipping_address@address_1',
          address_2: 'mfj_member_shipping_address@address_2',
        }
        const items = []
        Object.keys(item_column).forEach((item_key) => {
          items.push({
            id: this.get_target_app_item(item_column[item_key])
              ? (this.get_target_app_item(item_column[item_key]) || {}).id
              : 0,
            application_id: this.application_obj.id,
            application_key: item_column[item_key],
            application_value: this.app_form_obj[item_key],
          })
        })
        const tel_column = {
          tel_1: 'mfj_member_shipping_address@tel_1',
        }
        Object.keys(tel_column).forEach((item_key) => {
          let app_val = this.app_form_obj[item_key + '_0']
          app_val += '-'
          app_val += this.app_form_obj[item_key + '_1']
          app_val += '-'
          app_val += this.app_form_obj[item_key + '_2']
          items.push({
            id: this.get_target_app_item(tel_column[item_key])
              ? (this.get_target_app_item(tel_column[item_key]) || {}).id
              : 0,
            application_id: this.application_obj.id,
            application_key: tel_column[item_key],
            application_value: app_val,
          })
          this.app_form_obj[item_key] = app_val
        })

        params.items =
          typeof items === 'object' && items.length > 0 ? items : false

        await this.basicPosts_I(config.BASE_API_URL + post_url_path, params)
          .then(async (response) => {
            console.log('update', response)
          })
          .catch(async (err) => {
            console.log(err)
          })
          .finally(async () => {
            await this.update()
            this.$forceUpdate()
          })
      },
      async update() {
        console.log('component methods', this.data_obj)

        const params = {
          shipping_data: [],
        }
        let profiles = []
        let upd_profile = {
          id: 0,
          m_id: this.$store.state.auth.userId,
          postal_cd: '',
          pref_cd: '',
          address_1: '',
          address_2: '',
          tel_1: '',
          tel_2: '',
        }
        if (
          typeof this.data_obj === 'object' &&
          typeof this.data_obj.shipping_data === 'object' &&
          Object.keys(this.data_obj.shipping_data).length > 0
        ) {
          profiles = [...this.data_obj.shipping_data]
          upd_profile = profiles[0]
        } else {
          profiles.push({ ...upd_profile })
        }

        if (this.app_form_obj['postal_cd']) {
          upd_profile.postal_cd = this.app_form_obj['postal_cd']
        }
        if (this.app_form_obj['pref_cd']) {
          upd_profile.pref_cd = this.app_form_obj['pref_cd']
        }
        if (this.app_form_obj['address_1']) {
          upd_profile.address_1 = this.app_form_obj['address_1']
        }
        if (this.app_form_obj['address_2']) {
          upd_profile.address_2 = this.app_form_obj['address_2']
        }
        if (this.app_form_obj['tel_1']) {
          upd_profile.tel_1 = this.app_form_obj['tel_1']
        }
        if (this.app_form_obj['tel_2']) {
          upd_profile.tel_2 = this.app_form_obj['tel_2']
        }
        console.log('update this.app_form_obj', this.app_form_obj)
        profiles[0] = upd_profile

        params.shipping_data = profiles

        await this.basicPosts_I(
          config.BASE_API_URL + '/member/edit/' + this.$store.state.auth.userId,
          params
        )
          .then(async (response) => {
            console.log('update', response)
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            await this.loadUserObj()
            await this.loadShippingObj()
            await this.loadApplicationObj()
            this.change_mode_value = false
            this.message = '更新しました'
            this.$forceUpdate()
          })
      },
      back_input() {
        this.change_mode_value = false
      },
      back() {
        this.$router.go(-1)
      },
    },
    computed: {
      ...mapState({}),
      change_mode: function () {
        return this.chk_editable ? this.change_mode_value : true
      },
      chk_editable: function () {
        if (
          typeof this.application_obj !== 'object' ||
          (typeof this.application_obj === 'object' &&
            this.application_obj.id == 0)
        ) {
          return true
        }
        return [0, 600].includes(
          parseInt((this.application_obj || {}).application_check_type)
        )
      },
      get_assets: function () {
        const form_obj = this.form_obj
        return function (type_cd, sort_key) {
          if (
            typeof form_obj === 'object' &&
            typeof form_obj.member_assets === 'object' &&
            form_obj.member_assets.find((asset) => asset.type == type_cd) !==
              false &&
            typeof form_obj.member_assets.filter(
              (asset) => asset.type == type_cd
            ) === 'object' &&
            typeof form_obj.member_assets.filter(
              (asset) => asset.type == type_cd
            )[sort_key] === 'object'
          ) {
            return form_obj.member_assets.filter(
              (asset) => asset.type == type_cd
            )[sort_key]
          }
          return {}
        }
      },
      get_pref_label: function () {
        const pref_list = this.pref_list
        return function (key) {
          if (typeof pref_list.find((pref) => pref.key == key) === 'object') {
            return pref_list.find((pref) => pref.key == key).label
          }
          return key
        }
      },
      get_request_cd_label: function () {
        const lists = this.request_type_list
        return function (key) {
          if (typeof lists.find((list) => list.key == key) === 'object') {
            return lists.find((list) => list.key == key).label
          }
          return key
        }
      },
      request_type_list: function () {
        return this.request_type_cd_obj
      },
      pref_list: function () {
        const lists = [...this.pref_obj]
        return lists.sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },
      application_type_list: function () {
        return this.application_type_obj
      },
      application_check_type_list: function () {
        return this.application_check_type_obj
      },

      get_target_app_item: function () {
        const application = this.application_obj
        return function (app_key) {
          if (
            typeof application === 'object' &&
            typeof application.items === 'object'
          ) {
            return application.items.find(
              (app) => app.application_key == app_key
            )
          }
        }
      },

      application_filter: function () {
        const app_list = this.application_list
        return function (app_type, app_chk_list) {
          return app_list.filter((app) => {
            if (
              app.application_type == app_type &&
              app_chk_list.includes(app.application_check_type) !== false
            ) {
              return true
            }
            return false
          })
        }
      },
      get_target_application: function () {
        const member_application = this.application_list
        return function (app_type) {
          return member_application.find(
            (app) => app.application_type == app_type
          )
        }
      },
      get_target_application_btn_class: function () {
        const get_func = this.get_target_application
        const application_btn_class = this.application_btn_class
        return function (app_type) {
          const filtered = get_func(app_type)
          console.log('get_target_application_btn_class', app_type, filtered)
          return application_btn_class(
            (filtered || {}).application_check_type
              ? filtered.application_check_type
              : false
          )
        }
      },

      get_member_data: function () {
        return {
          name_1: this.data_obj.name_1,
          name_2: this.data_obj.name_2,
          yomi_1: this.data_obj.yomi_1,
          yomi_2: this.data_obj.yomi_2,
          birth_date: this.data_obj.birth_date,
          postal_cd: this.data_obj.birth_date,
          pref_cd: this.data_obj.birth_date,
          address_1: this.data_obj.address_1,
          address_2: this.data_obj.address_2,
          tel_1: this.data_obj.tel_1,
          tel_2: this.data_obj.tel_2,
          mail_1: this.data_obj.mail_1,
          mail_2: this.data_obj.mail_2,
          mail_flg: this.data_obj.mail_flg,
        }
      },
      input_class: function () {
        if (
          this.change_mode ||
          ((this.application_obj || {}).application_check_type > 0 &&
            (this.application_obj || {}).application_check_type != 600)
        ) {
          return 'form-control-plaintext'
        } else {
          return 'form-control'
        }
      },
      input_readonly: function () {
        if (
          this.change_mode ||
          ((this.application_obj || {}).application_check_type > 0 &&
            (this.application_obj || {}).application_check_type != 600)
        ) {
          return true
        } else {
          return false
        }
      },
      // 電話番号1のフォーマット済み表示
      formattedPhone() {
        const { tel_1_0, tel_1_1, tel_1_2 } = this.app_form_obj || {}
        if (!tel_1_0 && !tel_1_1 && !tel_1_2) return ''
        return [tel_1_0, tel_1_1, tel_1_2].filter(Boolean).join('-')
      },
    },
    async created() {
      this.message = this.base_message.base
      this.shipping_index =
        this.$route.query.shipping_index >= 0
          ? this.$route.query.shipping_index
          : 0
      await this.loadConfigObj('request_type_cd')
      await this.loadConfigObj('application_type')
      await this.loadConfigObj('application_check_type')
      await this.loadPrefList()
      await this.setShippingObj()
      await this.loadShippingObj()
      await this.setUserObj()
      await this.loadUserObj()
      await this.setApplicationObj()
      await this.loadApplicationObj()
    },
    mounted: function () {},
    props: {},
  }
</script>

<template>
  <section class="page">
    <div class="container">
      <h3 class="title02 page__h2">登録電話番号</h3>
      <div class="box box--center mb-24">
        <p v-if="(this.application_obj || {}).application_check_type == 0">
          {{ message }}
        </p>
        <p v-else-if="
          (this.application_obj || {}).application_check_type == 100
        ">
          現在変更申請中です。
        </p>
        <p v-else-if="
          (this.application_obj || {}).application_check_type == 200
        ">
          現在変更申請を確認中です。
        </p>
        <p v-else-if="
          (this.application_obj || {}).application_check_type == 500
        ">
          承認されました。
        </p>
        <p v-else-if="
          (this.application_obj || {}).application_check_type == 600
        ">
          差し戻しがあります。内容を確認して再申請をお願いします。
        </p>
        <p v-else>変更申請フォームです</p>
      </div>
      <form class="mb-40">
        <div class="mb-32">
          <div class="row gy-24">
            <div class="col-md-6">
              <h4 class="title08 d-block mb-24">現在の登録内容</h4>
              <div class="page__inputGroup">
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <span class="page__inputLabel page__inputGroupLabel">電話番号</span>
                    <span class="showData page__inputGroupInput">{{ form_obj.tel_1 }}</span>
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <span class="page__inputLabel page__inputGroupLabel">携帯番号</span>
                    <span class="showData page__inputGroupInput">{{ form_obj.tel_2 }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h4 class="title08 d-block mb-24">変更する申請内容</h4>
              <div class="page__inputGroup">
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel" for="input041">電話番号</label>
                    <input type="text" v-model="(app_form_obj || {}).tel_1_0" :class="input_class"
                      :readonly="input_readonly" />
                    <input type="text" v-model="(app_form_obj || {}).tel_1_1" :class="input_class"
                      :readonly="input_readonly" />
                    <input type="text" v-model="(app_form_obj || {}).tel_1_2" :class="input_class"
                      :readonly="input_readonly" />
                  </p>
                </div>
                <div class="page__inputGroupCol">
                  <p class="page__inputGroupInner">
                    <label class="label page__inputGroupLabel" for="input042">携帯番号</label>
                    <input type="text" v-model="(app_form_obj || {}).tel_2_0" :class="input_class"
                      :readonly="input_readonly" />
                    <input type="text" v-model="(app_form_obj || {}).tel_2_1" :class="input_class"
                      :readonly="input_readonly" />
                    <input type="text" v-model="(app_form_obj || {}).tel_2_2" :class="input_class"
                      :readonly="input_readonly" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex mt-32 justify-content-center column-gap-24">
          <button v-if="change_mode && chk_editable" type="button" @click="back_input" class="button03">
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
            <span class="button03__text">
              入力画面に戻る
            </span>
          </button>
          <button v-else type="button" @click="back" class="button03">
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="mx-2" />
            <span class="button03__text">
              戻る
            </span>
          </button>

          <div class="d-grid" v-if="chk_editable">
            <button v-if="change_mode" type="button" @click="update_app" class="button04">
              <span class="button03__text">
                更新する
              </span>
              <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
            </button>
            <button v-else type="button" @click="confirm_chk" class="button04">
              <span class="button03__text">
                確認する
              </span>
              <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
            </button>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<style scoped>
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.375rem;
    border-bottom: solid 1px #999;
  }
  .form-control-plaintext {
    border-bottom: solid 1px #999;
  }
  .form-control-plaintext2 {
    border-bottom: none !important;
  }
</style>

<script>
  import { mapState } from 'vuex'
  // import BsPageTitle from '../../molecules/BsPageTitle.vue'
  // import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
  // import BsSelect from '../../atoms/BsSelect.vue'
  // import BsCheckBox from '../../atoms/BsCheckBox.vue'
  // import '../../assets/bootstrap/css/sc.css';
  // import BsSelect from '../../atoms/BsSelect.vue'
  import config from '../../../const'

  export default {
    name: 'UserNameView',
    data: function () {
      return {
        targetid: this.$store.state.oauth.userId,
        btnLabel: '',
        data_obj: {},
        form_obj: {},
        app_form_obj: {},
        change_login_id: '',
        confirm_login_id: '',
        change_mode_value: false,
        base_message:
          '登録された電話番号を変更します。変更申請内容に入力して『確認する』を押して下さい。',
        message: '',
        pref_obj: [],
        request_type_cd_obj: [],
        application_obj: {},
        application_type_obj: [],
        application_check_type_obj: [],
      }
    },
    components: {
      // BsSelect,
      //   BsMsgAlert,
      // BsCheckBox,
    },
    methods: {
      async get_address() {
        const postal_cd = this.form_obj.postal_cd
        await this.basicGets_O(
          'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + postal_cd
        )
          .then((res) => {
            if (
              typeof res === 'object' &&
              typeof res.status === 'number' &&
              res.status == 200 &&
              typeof res.data === 'object' &&
              typeof res.data.results === 'object' &&
              typeof res.data.results[0] === 'object'
            ) {
              this.form_obj.pref_cd =
                parseInt(res.data.results[0].prefcode) < 10
                  ? '0' + res.data.results[0].prefcode
                  : res.data.results[0].prefcode
              this.form_obj.address_1 =
                res.data.results[0].address2 + res.data.results[0].address3
              this.form_obj.address_2 = ''
            } else {
              alert('電話番号データがありません')
            }
          })
          .catch((error) => {
            console.log(error)
            alert('住所データがありません.')
          })
          .finally(() => {
            this.$forceUpdate()
          })
      },
      async selectedFile(e, type_cd) {
        e.preventDefault()
        const files = [...e.target.files]
        // let ups = [];
        files.forEach(async (file) => {
          const form = new FormData()
          form.append('file', file)
          await this.basicPosts_R(
            config.BASE_API_URL + '/upload/owner_assets',
            form
          )
            .then((res) => {
              // console.log(res);
              if (res && res.status == 200) {
                const asset_tmp = {
                  id: 0,
                  stat: 1,
                  o_id: this.$store.state.oauth.userId,
                  type: 0,
                  path: '',
                }
                const tmps =
                  typeof this.form_obj.owner_assets === 'object'
                    ? [...this.form_obj.owner_assets]
                    : []
                const asset = tmps.find((tmp) => tmp.type == type_cd)
                  ? tmps.find((tmp) => tmp.type == type_cd)
                  : asset_tmp
                asset.type = type_cd
                asset.path = res.data.body.file_path
                  ? res.data.body.file_path
                  : ''

                if (tmps.find((tmp) => tmp.id == asset.id)) {
                  const fIndex = tmps.findIndex((tmp) => tmp.id == asset.id)
                  tmps[fIndex] = asset
                  this.form_obj.owner_assets = tmps
                } else {
                  this.form_obj.owner_assets = [asset].concat(tmps)
                }

                // const asset_tmp = {
                //     id: 0,
                //     stat: 1,
                //     o_id: 0,
                //     type: 0,
                //     path: '',
                // }
                // const tmps = (typeof this.owner_obj.owner_assets === 'object')? [...this.owner_obj.owner_assets]: [];
                // const asset = (
                //                 tmps.find(tmp => tmp.type == type_cd)
                //                 )? tmps.find(tmp => tmp.type == type_cd)
                //                 : asset_tmp;
                // asset.o_id = (this.not_add)? this.targetid: 0;
                // asset.type = type_cd;
                // asset.path = (res.data.body.file_path)? res.data.body.file_path: '';
                // // console.log('assets', assets);
                // const assets = [asset].concat(tmps);
                // // assets.push(asset);
                // this.form_obj.owner_assets = assets;

                // ups.push(res.data.body);
              }
            })
            .catch((error) => {
              console.log(error)
            })
            .finally(() => {
              document.getElementById('file_up_input_' + type_cd).value = ''
              this.$forceUpdate()
            })
        })
      },
      async clickUpFileBtn() {
        document.getElementById('file_up_input_100').click()
      },
      async loadConfigObj(param) {
        const params = {}
        await this.basicPosts_S(
          config.BASE_API_URL + '/config/' + param,
          params
        )
          .then(async (response) => {
            console.log('loadConfigObj response', response)
            this[param + '_obj'] =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this[param + '_obj'] = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadPrefList() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(
          config.BASE_API_URL + '/area_pref/lists',
          params
        )
          .then(async (response) => {
            console.log('loadPrefList response', response)
            this.pref_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.pref_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadOwnerObj() {
        const params = {}
        params['stat'] = [1]
        params['id'] = this.$store.state.oauth.userId
        params['pagegroup'] = 'mfj_owner_data.id'
        await this.basicPosts_S(config.BASE_API_URL + '/owner/lists', params)
          .then(async (response) => {
            console.log('loadOwnerObj', response)
            this.data_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = {}
          })
          .finally(async () => {
            await this.setOwnerObj()
            this.$forceUpdate()
          })
      },
      async setOwnerObj() {
        this.form_obj = {
          name_1: (this.data_obj || {}).name_1,
          name_2: (this.data_obj || {}).name_2,
          yomi_1: (this.data_obj || {}).yomi_1,
          yomi_2: (this.data_obj || {}).yomi_2,
          birth_date: (this.data_obj || {}).birth_date,
          postal_cd: (this.data_obj || {}).postal_cd,
          pref_cd: (this.data_obj || {}).pref_cd,
          address_1: (this.data_obj || {}).address_1,
          address_2: (this.data_obj || {}).address_2,
          tel_1: (this.data_obj || {}).tel_1,
          tel_2: (this.data_obj || {}).tel_2,
          owner_assets: (this.data_obj || {}).owner_assets,
        }
      },
      async loadApplicationObj() {
        const params = {}
        params['stat'] = [1]
        params['o_id'] = this.$store.state.oauth.userId
        params['application_type'] = 12400
        params['not_application_check_types'] = [500, 900]
        await this.basicPosts_S(
          config.BASE_API_URL + '/owner_application/lists',
          params
        )
          .then(async (response) => {
            console.log('loadApplicationObj', response)
            this.application_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.application_obj = {}
          })
          .finally(async () => {
            await this.setApplicationObj()
            this.$forceUpdate()
          })
      },
      async setApplicationObj() {
        const tmps =
          typeof this.application_obj === 'object' &&
          Object.keys(this.application_obj).length > 0
            ? this.application_obj
            : {
                id: 0,
                application_check_type: '',
                application_type: 12400,
                application_date: '',
              }
        this.application_obj = tmps

        const tel_1 = []
        const tel_2 = []

        const tel_1_cnt =
          typeof (
            (this.get_target_app_item('mfj_owner_profile@tel_1') || {})
              .application_value || ''
          ).split('-') === 'object'
            ? (
                (this.get_target_app_item('mfj_owner_profile@tel_1') || {})
                  .application_value || ''
              ).length
            : 0
        const tel_2_cnt =
          typeof (
            (this.get_target_app_item('mfj_owner_profile@tel_2') || {})
              .application_value || ''
          ).split('-') === 'object'
            ? (
                (this.get_target_app_item('mfj_owner_profile@tel_2') || {})
                  .application_value || ''
              ).length
            : 0
        for (let i = 0; i < 3; i++) {
          if (tel_1_cnt <= i) {
            tel_1.push('')
          } else {
            tel_1.push(
              (
                (this.get_target_app_item('mfj_owner_profile@tel_1') || {})
                  .application_value || ''
              ).split('-')[i]
            )
          }
          if (tel_2_cnt <= i) {
            tel_2.push('')
          } else {
            tel_2.push(
              (
                (this.get_target_app_item('mfj_owner_profile@tel_2') || {})
                  .application_value || ''
              ).split('-')[i]
            )
          }
        }

        this.app_form_obj = {
          tel_1_0: tel_1[0],
          tel_1_1: tel_1[1],
          tel_1_2: tel_1[2],
          tel_1_message: (
            this.get_target_app_item('mfj_owner_profile@tel_1') || {}
          ).message,
          tel_2_0: tel_2[0],
          tel_2_1: tel_2[1],
          tel_2_2: tel_2[2],
          tel_2_message: (
            this.get_target_app_item('mfj_owner_profile@tel_2') || {}
          ).message,
          // birth_date: this.data_obj.birth_date,
          // postal_cd: this.data_obj.postal_cd,
          // pref_cd: this.data_obj.pref_cd,
          // address_1: this.data_obj.address_1,
          // address_2: this.data_obj.address_2,
          // tel_1: this.data_obj.tel_1,
          // tel_2: this.data_obj.tel_2,
          // owner_assets: this.data_obj.owner_assets,
        }
      },
      async confirm_chk() {
        // if(
        //     this.change_login_id == '' || this.confirm_login_id == ''
        // ){
        //     alert('変更するログインIDを入力して下さい');
        //     return true;
        // }
        // else if(
        //     this.change_login_id != '' && this.confirm_login_id != ''
        //     && this.change_login_id != this.confirm_login_id
        // ){
        //     alert('入力されたログインIDが一致しません');
        //     return true;

        // }
        this.change_mode_value = true
      },
      async update_app() {
        console.log('component methods', this.data_obj)

        const post_url_path =
          this.application_obj.id > 0
            ? '/owner_application/edit/' + this.application_obj.id
            : '/owner_application/add'

        const params = {
          application_check_type: 500,
          application_type: 12400,
          o_id: this.$store.state.oauth.userId,
          // application_type: this.upd_obj.application_check_type,
          // application_check_type: this.upd_obj.application_check_type,
          // items: [],
        }

        const item_column = {
          tel_1: 'mfj_owner_profile@tel_1',
          tel_2: 'mfj_owner_profile@tel_2',
        }
        const items = []
        Object.keys(item_column).forEach((item_key) => {
          let app_val = this.app_form_obj[item_key + '_0']
          app_val += '-'
          app_val += this.app_form_obj[item_key + '_1']
          app_val += '-'
          app_val += this.app_form_obj[item_key + '_2']
          items.push({
            id: this.get_target_app_item(item_column[item_key])
              ? (this.get_target_app_item(item_column[item_key]) || {}).id
              : 0,
            application_id: this.application_obj.id,
            application_key: item_column[item_key],
            application_value: app_val,
          })
        })
        params.items =
          typeof items === 'object' && items.length > 0 ? items : false

        await this.basicPosts_I(config.BASE_API_URL + post_url_path, params)
          .then(async (response) => {
            console.log('update', response)
          })
          .catch(async (err) => {
            console.log(err)
          })
          .finally(async () => {
            // await this.loadApplicationObj();
            // this.change_mode_value = false;
            // this.message = '更新しました';
            await this.update()
            this.$forceUpdate()
          })
      },
      async update() {
        console.log('component methods', this.data_obj)

        const params = {
          // name_1: this.form_obj.name_1,
          // name_2: this.form_obj.name_2,
          // yomi_1: this.form_obj.yomi_1,
          // yomi_2: this.form_obj.yomi_2,
          // birth_date: this.form_obj.birth_date,
          profile: [],
          // owner_assets: this.form_obj.owner_assets,
        }
        let profiles
        let upd_profile = {
          id: 0,
          o_id: this.$store.state.oauth.userId,
          postal_cd: '',
          pref_cd: '',
          address_1: '',
          address_2: '',
          tel_1: '',
          tel_2: '',
        }
        if (
          typeof this.data_obj === 'object' &&
          typeof this.data_obj.profile === 'object' &&
          Object.keys(this.data_obj.profile).length > 0
        ) {
          profiles = [...this.data_obj.profile]
          upd_profile = profiles[0]
        } else {
          profiles.push({ ...upd_profile })
        }

        let tel_1 = ''
        tel_1 += this.app_form_obj['tel_1_0']
        tel_1 += '-'
        tel_1 += this.app_form_obj['tel_1_1']
        tel_1 += '-'
        tel_1 += this.app_form_obj['tel_1_2']

        let tel_2 = ''
        tel_2 += this.app_form_obj['tel_2_0']
        tel_2 += '-'
        tel_2 += this.app_form_obj['tel_2_1']
        tel_2 += '-'
        tel_2 += this.app_form_obj['tel_2_2']
        upd_profile.tel_1 = tel_1
        upd_profile.tel_2 = tel_2

        // const test = false;
        // if(!test) return ;
        profiles[0] = upd_profile

        params.profile = profiles

        await this.basicPosts_I(
          config.BASE_API_URL + '/owner/edit/' + this.$store.state.oauth.userId,
          params
        )
          .then(async (response) => {
            console.log('update', response)
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            await this.loadOwnerObj()
            await this.loadApplicationObj()
            this.change_mode_value = false
            this.message = '更新しました'
            this.$forceUpdate()
          })
      },
      back_input() {
        this.change_mode_value = false
      },
      back() {
        this.$router.go(-1)
      },
    },
    computed: {
      ...mapState({}),
      change_mode: function () {
        return this.chk_editable ? this.change_mode_value : true
      },
      chk_editable: function () {
        if (
          typeof this.application_obj !== 'object' ||
          (typeof this.application_obj === 'object' &&
            this.application_obj.id == 0)
        ) {
          return true
        }
        return [0, 600].includes(
          parseInt((this.application_obj || {}).application_check_type)
        )
      },
      get_assets: function () {
        const form_obj = this.form_obj
        return function (type_cd, sort_key) {
          if (
            typeof form_obj === 'object' &&
            typeof form_obj.owner_assets === 'object' &&
            form_obj.owner_assets.find((asset) => asset.type == type_cd) !==
              false &&
            typeof form_obj.owner_assets.filter(
              (asset) => asset.type == type_cd
            ) === 'object' &&
            typeof form_obj.owner_assets.filter(
              (asset) => asset.type == type_cd
            )[sort_key] === 'object'
          ) {
            // console.log('get_assets', form_obj.owner_assets.filter(asset => asset.type == type_cd));
            return form_obj.owner_assets.filter(
              (asset) => asset.type == type_cd
            )[sort_key]
          }
          return {}
        }
      },
      get_pref_label: function () {
        const pref_list = this.pref_list
        return function (key) {
          if (typeof pref_list.find((pref) => pref.key == key) === 'object') {
            return pref_list.find((pref) => pref.key == key).label
          }
          return key
        }
      },
      get_request_cd_label: function () {
        const lists = this.request_type_list
        return function (key) {
          if (typeof lists.find((list) => list.key == key) === 'object') {
            return lists.find((list) => list.key == key).label
          }
          return key
        }
      },
      request_type_list: function () {
        return this.request_type_cd_obj
      },
      pref_list: function () {
        const lists = [...this.pref_obj]
        return lists.sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },
      application_type_list: function () {
        return this.application_type_obj
      },
      application_check_type_list: function () {
        return this.application_check_type_obj
      },

      get_target_app_item: function () {
        const application = this.application_obj
        return function (app_key) {
          if (
            typeof application === 'object' &&
            typeof application.items === 'object'
          ) {
            return application.items.find(
              (app) => app.application_key == app_key
            )
          }
        }
      },

      application_filter: function () {
        const app_list = this.application_list
        return function (app_type, app_chk_list) {
          return app_list.filter((app) => {
            if (
              app.application_type == app_type &&
              app_chk_list.includes(app.application_check_type) !== false
            ) {
              return true
            }
            return false
          })
        }
      },
      get_target_application: function () {
        const owner_application = this.application_list
        return function (app_type) {
          return owner_application.find(
            (app) => app.application_type == app_type
          )
        }
      },
      get_target_application_btn_class: function () {
        const get_func = this.get_target_application
        const application_btn_class = this.application_btn_class
        return function (app_type) {
          const filtered = get_func(app_type)
          console.log('get_target_application_btn_class', app_type, filtered)
          return application_btn_class(
            (filtered || {}).application_check_type
              ? filtered.application_check_type
              : false
          )
        }
      },

      get_owner_data: function () {
        return {
          name_1: this.data_obj.name_1,
          name_2: this.data_obj.name_2,
          yomi_1: this.data_obj.yomi_1,
          yomi_2: this.data_obj.yomi_2,
          birth_date: this.data_obj.birth_date,
          postal_cd: this.data_obj.birth_date,
          pref_cd: this.data_obj.birth_date,
          address_1: this.data_obj.address_1,
          address_2: this.data_obj.address_2,
          tel_1: this.data_obj.tel_1,
          tel_2: this.data_obj.tel_2,
          mail_1: this.data_obj.mail_1,
          mail_2: this.data_obj.mail_2,
          mail_flg: this.data_obj.mail_flg,
        }
      },
      input_class: function () {
        if (
          this.change_mode ||
          ((this.application_obj || {}).application_check_type > 0 &&
            (this.application_obj || {}).application_check_type != 600)
        ) {
          return 'form-control-plaintext'
        } else {
          return 'form-control'
        }
      },
      input_readonly: function () {
        if (
          this.change_mode ||
          ((this.application_obj || {}).application_check_type > 0 &&
            (this.application_obj || {}).application_check_type != 600)
        ) {
          return true
        } else {
          return false
        }
      },
    },
    async created() {
      this.message = this.base_message
      await this.loadConfigObj('request_type_cd')
      await this.loadConfigObj('application_type')
      await this.loadConfigObj('application_check_type')
      await this.loadPrefList()
      await this.setOwnerObj()
      await this.loadOwnerObj()
      await this.setApplicationObj()
      await this.loadApplicationObj()
    },
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
    props: {},
  }
</script>

<template>
  <h2>3Dセキュアの処理を実行しています...</h2>
  <p>戻るなどの操作は控えて下さい</p>
</template>

<script setup>
  import { onMounted, onUnmounted } from 'vue'
  import { usePaymentPage } from '@ootbteam/mulpay-pinia'
  import axios from 'axios'
  import config from '../../../const'

  const { handlePaymentPageProps, capturePayment } = usePaymentPage()

  const nonloginPosts__ = async (post_url, params, header) => {
    console.log('nonloginPosts post_url, params, header', post_url, params, header)
    const chk = await axios
        .post(
          post_url,
          params,
          header
        )
        .then(async (response) => {
          console.log('nonloginPosts response', response)
          return (response.data && response.data.status == 200)? true: false
        })
        .catch(async (err) => {
          console.log('nonloginPosts err', err)
          // console.log('globalActionsTest basicPosts err', err);
          throw err
        })
    return chk
  }
  const setParams__ = (params, mode) => {
    const params_ = new URLSearchParams()
      if (params) {
        const keys = Object.keys(params)
        for (let i = 0; i < keys.length; i++) {
          if (
            keys[i] == 'p'
            // || keys[i].indexOf('page') >= 0
          ) {
            params_.append(keys[i], params[keys[i]])
          } else if (
            params[keys[i]] !== null &&
            typeof params[keys[i]] === 'object'
          ) {
            params_.append(
              mode + '[' + keys[i] + ']',
              JSON.stringify(params[keys[i]])
            )
          } else {
            params_.append(mode + '[' + keys[i] + ']', params[keys[i]])
          }
        }
      }
      return params_
  }
  onMounted(async () => {
    const accessId = localStorage.getItem('accessId')
    const formToken = localStorage.getItem('formToken')
    const formTokenId = localStorage.getItem('formTokenId')
    const formSalesId = localStorage.getItem('formSalesId')
    // const formPayload = localStorage.getItem('formPayload')
    console.log('accessId', accessId);
    console.log('formToken', formToken);
    console.log('formTokenId', formTokenId);
    console.log('formSalesId', formSalesId);
    // MpTokenに必要な情報を設定
    await handlePaymentPageProps({
      instance: axios.create({
        baseURL: config.BASE_API_URL,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      apiKey: config.GMO_MP_TOKEN_API_KEY_MFJ,
      pubKey: config.GMO_MP_TOKEN_PUB_KEY_MFJ,
      productionMode: config.GMO_SHOP_OP_MODE,
    })

    // await capturePayment(accessId, () => {
    //   alert('ページを戻します')
      
    //   location.href = `/mfj_renewal/app/regist/form?token=${formToken}`
    // })
    await capturePayment(accessId, async (resp) => {
      // 売上データを更新
      const { orderReference, creditResult, tds2Result } = resp
      // console.log('capturePayment orderReference', orderReference)
      // console.log('capturePayment creditResult', creditResult)

      const { status } = orderReference
      const { tds2TransResult } = tds2Result

      if (tds2TransResult !== "Y" && tds2TransResult !== "A") {
        alert("3Dセキュア認証できませんでした。決済は失敗しました。");
        location.href = config.BASE_APP_URL + `/regist/form?token=${formToken}`
        return
      } else if (status !== "CAPTURE") {
        alert("決済は完了していません。再試行してください。");
        location.href = config.BASE_APP_URL + `/regist/form?token=${formToken}`
        return
      }
      // if (tds2TransResult !== 'Y' || status !== 'CAPTURE') {
      // if(status !== 'CAPTURE'){
      //   alert('3DS認証できませんでした。決済は失敗しました。')
      //   location.href = config.BASE_APP_URL + `/regist/form?token=${formToken}`
      //   return
      // }

      // console.log('capturePayment', resp)
      // 申請データを更新
      const app_url_path =
        formTokenId > 0
          ? '/member_application/edit/' + formTokenId
          : '/member_application/add'

      const app_params = {
        application_check_type: 423,
      }
      // 更新
      const app_chk = await nonloginPosts__(
        config.BASE_API_URL + app_url_path,
        setParams__(app_params, 'iprm'),
        { headers:  { [config.BASE_API_USER_HEADER_KEY]: 'NON_LOGIN_ACCESS_TOKEN' } }
      )
      // console.log('capturePayment resp', resp)

      const sales_url_path =
        formSalesId > 0
          ? '/sales/charge_finish/' + formSalesId
          : '/sales/add'
      const sales_params = {
        sales_type: 'regist',
        charge_type: orderReference.chargeType,
        payment_value: orderReference.amount,
        sales_date: 'now()',
        charge_result: JSON.stringify(resp),
        charge_access_id: orderReference.accessId,
        charge_transaction_id: creditResult.nwTransactionId,
        charge_forward: creditResult.forwardedAcquirerCode,
      }

      const chk = (app_chk)
        ? await nonloginPosts__(
          config.BASE_API_URL + sales_url_path,
          setParams__(sales_params, 'iprm'),
          { headers:  { [config.BASE_API_USER_HEADER_KEY]: 'NON_LOGIN_ACCESS_TOKEN' } }
        )
        : false

      if(chk){
        alert('認証しました。承認処理を進めます。ページが自動で移動します。OKを押して下さい。')
        // console.log(`/mfj_renewal/app/regist/form?token=${formToken}`)
        // location.href = `/mfj_renewal/app/regist/form?token=${formToken}`
      }else if(app_chk) {
        alert('決済処理が失敗しました。決済のみ完了している可能性があります。')
        // location.href = `/mfj_renewal/app/regist/form?token=${formToken}`
      }else{
        alert('決済が失敗しました。 ')
        // location.href = `/mfj_renewal/app/regist/form?token=${formToken}`
      }
      // console.log(config.BASE_APP_URL + `/regist/form?token=${formToken}`)
      location.href = config.BASE_APP_URL + `/regist/form?token=${formToken}`
    })
    .then(() => {
      
    })
    .catch(async (err) => {
      console.log('capture err', err)
      alert('決済が失敗しました。 c')
      const app_params = {
        application_check_type: 403,
      }
      const app_url_path =
        formTokenId > 0
          ? '/member_application/edit/' + formTokenId
          : '/member_application/add'
      const app_chk = await nonloginPosts__(
        config.BASE_API_URL + app_url_path,
        setParams__(app_params, 'iprm'),
        { headers:  { [config.BASE_API_USER_HEADER_KEY]: 'NON_LOGIN_ACCESS_TOKEN' } }
      )
      console.log('capture app_chk', app_chk)
      location.href = config.BASE_APP_URL + `/regist/form?token=${formToken}`
      // location.href = `/mfj_renewal/app/regist/form?token=${formToken}`
      // console.log('globalActionsTest basicPosts err', err);
      throw err
    })
  })

  onUnmounted(() => {
    localStorage.removeItem('accessId')
    localStorage.removeItem('formToken')
    localStorage.removeItem('formTokenId')
    localStorage.removeItem('formSalesId')
  })
</script>

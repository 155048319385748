<template>
  <section class="page">
    <div class="container">
      <div class="container mb-2 py-3">
        <h5 class="title page__h1">主催者情報変更申請</h5>
      </div>
      <div class="box box--center mb-24">
        <p>{{ message }}</p>
      </div>
      <div class="container mb-3">
        <div class="page__inputGroup">
          <div class="mb-40">
            <div class="page__inputGroupCol">
              <p class="page__inputGroupInner col-md-6">
                <span class="page__inputLabel page__inputGroupLabel"
                  >主催者コード</span
                >
                <span class="showData page__inputGroupInput">{{
                  form_obj.owner_no
                }}</span>
              </p>
            </div>
            <div class="page__inputGroupCol">
              <p class="page__inputGroupInner col-md-6">
                <span class="page__inputLabel page__inputGroupLabel"
                  >主催者名称</span
                >
                <span class="showData page__inputGroupInput">{{
                  form_obj.name_1
                }}</span>
              </p>
            </div>
            <div class="page__inputGroupCol">
              <p class="page__inputGroupInner col-md-6">
                <span class="page__inputLabel page__inputGroupLabel"
                  >カナ</span
                >
                <span class="showData page__inputGroupInput">{{
                  form_obj.yomi_1
                }}</span>
              </p>
            </div>
            <p class="text-center mt-32">
              <router-link
                to="/owner_edit/name"
                :class="get_target_application_btn_class(12100)"
              >
              <span>{{ application_btn_label(12100) }}</span>
              <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
              </router-link>
            </p>
          </div>
          <div class="mb-40">
            <div class="page__inputGroupCol">
              <div class="page__inputGroupInner">
                <span class="page__inputLabel page__inputGroupLabel"
                  >担当/責任者</span
                >
                <div class="d-flex w-100">
                  <div class="w-50">
                    <p class="page__inputGroupContent">
                      <span class="col-2">姓</span>
                      <span class="showData page__inputGroupInput">{{
                        form_obj.name_2
                      }}</span>
                    </p>
                  </div>
                  <div class="w-50">
                    <p class="page__inputGroupContent">
                      <span class="col-2">名</span>
                      <span class="showData page__inputGroupInput">{{
                        form_obj.name_3
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="page__inputGroupCol">
              <div class="page__inputGroupInner">
                <span class="page__inputLabel page__inputGroupLabel"
                  >カナ</span
                >
                <div class="d-flex w-100">
                  <div class="w-50">
                    <p class="page__inputGroupContent">
                      <span class="col-2">セイ</span>
                      <span class="showData page__inputGroupInput">{{
                        form_obj.yomi_2
                      }}</span>
                    </p>
                  </div>
                  <div class="w-50">
                    <p class="page__inputGroupContent">
                      <span class="col-2">メイ</span>
                      <span class="showData page__inputGroupInput">{{
                        form_obj.yomi_3
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-center mt-32">
              <router-link
                to="/owner_edit/named"
                :class="get_target_application_btn_class(12110)"
              >
              <span>{{ application_btn_label(12110) }}</span>
              <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
              </router-link>
            </p>
            <div class="page__inputGroupCol" v-if="(form_obj || {}).license_no">
              <div class="row mb-2">
                <label
                  class="form-label text-lg-end col-10 offset-1 offset-lg-0 col-lg-2"
                  >代表者ライセンスNo.</label
                >
                <div class="col-10 offset-1 offset-lg-0 col-lg-4">
                  <div class="input-group">
                    <input
                      type="text"
                      v-model="form_obj.license_no"
                      :class="input_class"
                      :readonly="input_readonly"
                    />
                  </div>
                </div>
              </div>
            </div>
            <p class="text-center mt-32">
              <router-link
                to="/owner_edit/license_no"
                :class="get_target_application_btn_class(13110)"
              >
              <span>{{ application_btn_label(13110) }}</span>
              <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
              </router-link>
            </p>
            <div class="page__inputGroupCol" v-if="(form_obj || {}).club_id">
              <div class="row mb-2">
                <label
                  class="form-label text-lg-end col-10 offset-1 offset-lg-0 col-lg-2"
                  >クラブコード</label
                >
                <div class="col-10 offset-1 offset-lg-0 col-lg-4">
                  <div class="input-group">
                    <input
                      type="text"
                      v-model="form_obj.club_no"
                      :class="input_class"
                      :readonly="input_readonly"
                    />
                  </div>
                </div>
                <label
                  class="form-label text-lg-end col-10 offset-1 offset-lg-0 col-lg-2"
                  >クラブ名</label
                >
                <div class="col-10 offset-1 offset-lg-0 col-lg-4">
                  <div class="input-group">
                    <input
                      type="text"
                      v-model="form_obj.club_label"
                      placeholder="代表者名"
                      :class="input_class"
                      :readonly="input_readonly"
                    />
                  </div>
                </div>
                <div class="container mb-3">
                  <div class="row mb-2">
                    <label class="form-label text-lg-end col-10 offset-1 offset-lg-0 col-lg-2">
                        担当/責任者
                    </label>
                    <div class="col-10 offset-1 offset-lg-0 col-lg-5 mb-1">
                        <div class="input-group">
                            <span class="input-group-text">姓</span>
                            <input type="text" v-model="form_obj.name_2" :class="input_class" :readonly="input_readonly">
                        </div>
                    </div>
                    <div class="col-10 offset-1 offset-lg-0 col-lg-5">
                        <div class="input-group">
                            <span class="input-group-text">名</span>
                            <input type="text" v-model="form_obj.name_3" :class="input_class" :readonly="input_readonly">
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="form-label text-lg-end col-10 offset-1 offset-lg-0 col-lg-2">
                        カナ
                    </label>
                    <div class="col-10 offset-1 offset-lg-0 col-lg-5 mb-1">
                        <div class="input-group">
                            <span class="input-group-text">セイ</span>
                            <input type="text" v-model="form_obj.yomi_2" :class="input_class" :readonly="input_readonly">
                        </div>
                    </div>
                    <div class="col-10 offset-1 offset-lg-0 col-lg-5">
                        <div class="input-group">
                            <span class="input-group-text">メイ</span>
                            <input type="text" v-model="form_obj.yomi_3" :class="input_class" :readonly="input_readonly">
                        </div>
                    </div>
                  </div>
                  <div class="container mb-3">
                      <div class="row">
                          <div class="col-10 offset-1 offset-lg-8 col-lg-4 mb-1">
                              <div class="d-grid">
                                  <router-link to="/owner_edit/named" :class="get_target_application_btn_class(12110)">
                                      <i class="bi bi-pencil-square"></i>
                                      <span>{{ application_btn_label(12110) }}</span>
                                  </router-link>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="mb-40">
                    <div class="page__inputGroupCol">
                      <p class="page__inputGroupInner col-md-6">
                        <span class="page__inputLabel page__inputGroupLabel"
                          >代表者ライセンスNo.</span
                        >
                        <span class="showData page__inputGroupInput">{{
                          form_obj.license_no
                        }}</span>
                      </p>
                    </div>
                    <p class="text-center mt-32">
                      <router-link
                        to="/owner_edit/license_no"
                        :class="get_target_application_btn_class(13110)"
                      >
                      <span>{{ application_btn_label(13110) }}</span>
                      <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
                      </router-link>
                    </p>
                  </div>
                  <div class="mb-40">
                    <div class="page__inputGroupCol">
                      <p class="page__inputGroupInner col-md-6"><span class="page__inputLabel page__inputGroupLabel">クラブコード</span>
                        <span class="showData page__inputGroupInput">{{ form_obj.club_no }}</span>
                      </p>
                    </div>
                    <div class="page__inputGroupCol">
                      <p class="page__inputGroupInner col-md-6"><span class="page__inputLabel page__inputGroupLabel">クラブ名</span>
                        <span class="showData page__inputGroupInput">{{ form_obj.club_no }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="mb-40">
                    <div class="page__inputGroupCol">
                      <p class="page__inputGroupInner col-md-6"><span class="page__inputLabel page__inputGroupLabel">メールアドレス1</span>
                        <span class="showData page__inputGroupInput">{{ form_obj.mail_1 }}</span>
                      </p>
                    </div>
                    <div class="page__inputGroupCol">
                      <p class="page__inputGroupInner col-md-6"><span class="page__inputLabel page__inputGroupLabel">メールアドレス2</span>
                        <span class="showData page__inputGroupInput">{{ form_obj.mail_2 }}</span>
                      </p>
                    </div>
                    <p class="text-center mt-32">
                      <router-link
                        to="/owner_edit/mail"
                        :class="get_target_application_btn_class(12500)"
                      >
                      <span>{{ application_btn_label(12500) }}</span>
                      <font-awesome-icon :icon="['fas', 'chevron-right']" class="mx-2" />
                      </router-link>
                    </p>
                  </div>
                  <div class="container mb-3 d-none">
                      <div class="row mb-3">
                          <label class="form-label text-lg-end col-10 offset-1 offset-lg-0 col-lg-2">画像</label>
                          <div class="col-10 offset-1 offset-lg-0 col-lg-3">
                              <div class="w-100" v-if="typeof get_assets(100, 0) === 'object'">
                                  <img :src="(get_assets(100, 0) || {}).path" class="w-100">
                              </div>
                          </div>
                          <div class="col-10 offset-1 offset-lg-0 col-lg-3 text-end">
                              <button v-if="!change_mode" type="button" @click="clickUpFileBtn()" class="btn btn-light btn-outline-secondary">
                                  <i class="bi bi-file-person"></i>&nbsp;ファイルを選択する
                              </button>
                              <input type="file" class="form-control" id="file_up_input_100" @change="selectedFile($event, 100)" style="display: none;">
                          </div>
                      </div>
                  </div>
                  <div class="container mb-3 d-none">
                      <div class="row">
                          <div class="col-10 offset-1 offset-lg-8 col-lg-4 mb-1">
                              <div class="d-grid">
                                  <router-link to="/owner_edit/photo" :class="get_target_application_btn_class(12200)">
                                      <i class="bi bi-pencil-square"></i>
                                      <span>{{ application_btn_label(12200) }}</span>
                                  </router-link>{{ dev_msg('※年度更新時のみ') }}
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="container mb-3">
                      <div class="row mb-2">
                          <h5 class="bs_fs_6">登録住所</h5>
                      </div>
                  </div>
                  <div class="container mb-3">
                      <div class="row">
                          <label class="form-label text-lg-end col-10 offset-1 offset-lg-0 col-lg-2">
                              〒・都道府県
                          </label>
                          <div class="col-10 offset-1 offset-lg-0 col-lg-5 mb-1">
                              <div class="input-group">
                                  <span class="input-group-text">〒</span>
                                  <input type="text" v-model="form_obj.postal_cd" :class="input_class" :readonly="input_readonly">
                                  <button v-if="!change_mode"
                                  type="button" class="btn btn-light btn-outline-secondary" @click="get_address()">検索</button>
                              </div>
                          </div>
                          <div class="col-10 offset-1 offset-lg-0 col-lg-5">
                              <div class="input-group">
                                  <span class="input-group-text">都道府県</span>
                                  <input 
                                      v-if="change_mode"
                                      type="text" 
                                      :class="input_class"
                                      :readonly="input_readonly"
                                      :value="get_pref_label(form_obj.pref_cd)">
                                  <bs-select
                                      v-else
                                      :options="pref_list"
                                      :nullOption="false"
                                      v-model="form_obj.pref_cd"
                                      :readonly="input_readonly"></bs-select>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="container mb-3">
                      <div class="row mb-2">
                          <label class="form-label text-lg-end col-10 offset-1 offset-lg-0 col-lg-2">市区町村・番地</label>
                          <div class="col-10 offset-1 offset-lg-0 col-lg-10">
                              <div class="input-group">
                              <input type="text" v-model="form_obj.address_1" :class="input_class" :readonly="input_readonly">
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <label class="form-label text-lg-end col-10 offset-1 offset-lg-0 col-lg-2">建物名・室号</label>
                          <div class="col-10 offset-1 offset-lg-0 col-lg-10">
                              <div class="input-group">
                              <input type="text" v-model="form_obj.address_2" :class="input_class" :readonly="input_readonly">
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="container mb-3">
                      <div class="row">
                          <div class="col-10 offset-1 offset-lg-8 col-lg-4 mb-1">
                              <div class="d-grid">
                                  <router-link to="/owner_edit/address" :class="get_target_application_btn_class(12300)">
                                      <i class="bi bi-pencil-square"></i>
                                      <span>{{ application_btn_label(12300) }}</span>
                                  </router-link>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="container mb-3">
                      <div class="row mb-2">
                          <h5 class="bs_fs_6">登録電話番号</h5>
                      </div>
                  </div>
                  <div class="container mb-3">
                      <div class="row mb-2">
                          <label class="form-label text-lg-end col-10 offset-1 offset-lg-0 col-lg-2">電話番号1</label>
                          <div class="col-10 offset-1 offset-lg-0 col-lg-5">
                              <div class="input-group">
                              <input type="text" v-model="form_obj.tel_1" :class="input_class" :readonly="input_readonly">
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <label class="form-label text-lg-end col-10 offset-1 offset-lg-0 col-lg-2">電話番号2</label>
                          <div class="col-10 offset-1 offset-lg-0 col-lg-5">
                              <div class="input-group">
                              <input type="text" v-model="form_obj.tel_2" :class="input_class" :readonly="input_readonly">
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <label class="form-label text-lg-end col-10 offset-1 offset-lg-0 col-lg-2">FAX</label>
                          <div class="col-10 offset-1 offset-lg-0 col-lg-5">
                              <div class="input-group">
                              <input type="text" v-model="form_obj.fax_1" :class="input_class" :readonly="input_readonly">
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="container mb-3">
                      <div class="row">
                          <div class="col-10 offset-1 offset-lg-8 col-lg-4 mb-1">
                              <div class="d-grid">
                                  <router-link to="/owner_edit/tel" :class="get_target_application_btn_class(12400)">
                                      <i class="bi bi-pencil-square"></i>
                                      <span>{{ application_btn_label(12400) }}</span>
                                  </router-link>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="container mb-3">
                      <div class="row mb-2">
                          <label class="form-label text-lg-end col-10 offset-1 offset-lg-0 col-lg-2">主催者種類</label>
                          <div class="col-10 offset-1 offset-lg-0 col-lg-10">
                              <div class="input-group">
                              <input type="text" v-model="form_obj.race_owner_type_cd_label" :class="input_class" :readonly="input_readonly">
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="container mb-3">
                      <div class="row mb-2">
                          <label class="form-label text-lg-end col-10 offset-1 offset-lg-0 col-lg-2">主催可能カテゴリ</label>
                          <div class="col-10 offset-1 offset-lg-0 col-lg-10">
                              <div class="row">
                                  <div class="col-6 col-md-4 col-lg-4 mb-2" v-for="race_type in race_type_grade_relation_list" :key="race_type">
                                      <div class="d-grid">
                                          <span class="text-center py-2 bg-info rounded-2" disabled="disabled">
                                              {{ get_race_type_label(race_type.race_type_cd) }}
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>
                </div>
                <label
                  class="form-label text-lg-end col-10 offset-1 offset-lg-0 col-lg-2"
                  >メールアドレス2</label
                >
                <div class="col-10 offset-1 offset-lg-0 col-lg-4">
                  <div class="input-group">
                    <input
                      type="text"
                      v-model="form_obj.mail_2"
                      placeholder="メールアドレス2"
                      :class="input_class"
                      :readonly="input_readonly"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-3 col-lg-4 text-start">
          <button
            class="button03"
            @click="back"
            v-if="change_mode"
          >
            戻る
          </button>
          <button
            class="button03"
            @click="back"
            v-else
          >
            戻る
          </button>
        </div>
        <div class="col-6 col-lg-4 d-none">
          <div class="d-grid">
            <button
              v-if="change_mode"
              type="button"
              @click="update"
              class="button04__text"
            >
              更新する
            </button>
            <button
              v-else
              type="button"
              @click="confirm_chk"
              class="button04__text"
            >
              確認する
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.375rem;
    border-bottom: solid 1px #999;
  }
  .form-control-plaintext {
    border-bottom: solid 1px #999;
  }
</style>

<script>
  import { mapState } from 'vuex'
  // import BsPageTitle from '../../molecules/BsPageTitle.vue'
  // import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
  // import BsSelect from '../../atoms/BsSelect.vue'
  // import BsCheckBox from '../../atoms/BsCheckBox.vue'
  // import '../../assets/bootstrap/css/sc.css';
  import BsSelect from '../../atoms/BsSelect.vue'
  import config from '../../../const'

  export default {
    name: 'OwnerDataView',
    data: function () {
      return {
        targetid: this.$store.state.oauth.userId,
        btnLabel: '',
        data_obj: {},
        form_obj: {},
        change_login_id: '',
        confirm_login_id: '',
        change_mode: true,
        message:
          '登録された情報を変更する場合は、各項目から変更申請を行って下さい。',
        region_obj: [],
        pref_obj: [],
        district_obj: [],
        request_type_cd_obj: [],
        application_obj: [],
        application_type_obj: [],
        application_check_type_obj: [],
        shipping_address_obj: [],
        event_cd_obj: [],
        event_relation_obj: [],
        race_type_cd_obj: [],
        race_type_grade_relation_obj: [],
      }
    },
    components: {
      BsSelect,
      //   BsMsgAlert,
      // BsCheckBox,
    },
    methods: {
      async get_address() {
        const postal_cd = this.form_obj.postal_cd
        await this.basicGets_O(
          'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + postal_cd
        )
          .then((res) => {
            if (
              typeof res === 'object' &&
              typeof res.status === 'number' &&
              res.status == 200 &&
              typeof res.data === 'object' &&
              typeof res.data.results === 'object' &&
              typeof res.data.results[0] === 'object'
            ) {
              this.form_obj.pref_cd =
                parseInt(res.data.results[0].prefcode) < 10
                  ? '0' + res.data.results[0].prefcode
                  : res.data.results[0].prefcode
              this.form_obj.address_1 =
                res.data.results[0].address2 + res.data.results[0].address3
              this.form_obj.address_2 = ''
            } else {
              alert('住所データがありません')
            }
          })
          .catch((error) => {
            console.log(error)
            alert('住所データがありません.')
          })
          .finally(() => {
            this.$forceUpdate()
          })
      },
      async selectedFile(e, type_cd) {
        e.preventDefault()
        const files = [...e.target.files]
        // let ups = [];
        files.forEach(async (file) => {
          const form = new FormData()
          form.append('file', file)
          await this.basicPosts_R(
            config.BASE_API_URL + '/upload/owner_assets',
            form
          )
            .then((res) => {
              console.log(res)
              if (res && res.status == 200) {
                const asset_tmp = {
                  id: 0,
                  stat: 1,
                  o_id: this.$store.state.oauth.userId,
                  type: 0,
                  path: '',
                }
                const tmps =
                  typeof this.form_obj.owner_assets === 'object'
                    ? [...this.form_obj.owner_assets]
                    : []
                const asset = tmps.find((tmp) => tmp.type == type_cd)
                  ? tmps.find((tmp) => tmp.type == type_cd)
                  : asset_tmp
                asset.type = type_cd
                asset.path = res.data.body.file_path
                  ? res.data.body.file_path
                  : ''

                if (tmps.find((tmp) => tmp.id == asset.id)) {
                  const fIndex = tmps.findIndex((tmp) => tmp.id == asset.id)
                  tmps[fIndex] = asset
                  this.form_obj.owner_assets = tmps
                } else {
                  this.form_obj.owner_assets = [asset].concat(tmps)
                }
              }
            })
            .catch((error) => {
              console.log(error)
            })
            .finally(() => {
              document.getElementById('file_up_input_' + type_cd).value = ''
              this.$forceUpdate()
            })
        })
      },
      async clickUpFileBtn() {
        document.getElementById('file_up_input_100').click()
      },
      async loadRaceTypeCd() {
        const params = {}
        params['stat'] = [1]
        console.log('loadEventRelationObj params', params)
        await this.basicPosts_S(
          config.BASE_API_URL + '/race_type_cd/lists',
          params
        )
          .then(async (response) => {
            console.log('loadRaceTypeCd', response)
            this.race_type_cd_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.race_type_cd_obj = []
          })
          .finally(async () => {
            // await this.setClubObj();
            this.$forceUpdate()
          })
      },
      async loadRaceTypeGradeRelation() {
        const params = {}
        await this.basicPosts_S(
          config.BASE_API_URL + '/race_type_grade_relation/lists',
          params
        )
          .then(async (response) => {
            console.log('loadRaceTypeGradeRelation response', response)
            this.race_type_grade_relation_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.race_type_grade_relation_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadConfigObj(param) {
        const params = {}
        await this.basicPosts_S(
          config.BASE_API_URL + '/config/' + param,
          params
        )
          .then(async (response) => {
            console.log('loadConfigObj response', response)
            this[param + '_obj'] =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this[param + '_obj'] = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadRegionList() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(
          config.BASE_API_URL + '/area_region/lists',
          params
        )
          .then(async (response) => {
            console.log('loadPrefList response', response)
            this.region_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.region_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadPrefList() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(
          config.BASE_API_URL + '/area_pref/lists',
          params
        )
          .then(async (response) => {
            console.log('loadPrefList response', response)
            this.pref_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.pref_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadDistrictList() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(
          config.BASE_API_URL + '/area_district/lists',
          params
        )
          .then(async (response) => {
            console.log('loadPrefList response', response)
            this.district_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.district_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadShippingObj() {
        const params = {}
        params['stat'] = [1]
        params['o_id'] = this.$store.state.oauth.userId
        params['pagegroup'] = 'mfj_owner_shipping_address.id'
        await this.basicPosts_S(
          config.BASE_API_URL + '/owner_shipping/lists',
          params
        )
          .then(async (response) => {
            console.log('loadShippingObj', response)
            this.shipping_address_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.shipping_address_obj = []
          })
          .finally(async () => {
            // await this.setOwnerObj();
            this.$forceUpdate()
          })
      },
      async loadOwnerObj() {
        const params = {}
        params['stat'] = [1]
        params['id'] = this.$store.state.oauth.userId
        await this.basicPosts_S(config.BASE_API_URL + '/owner/lists', params)
          .then(async (response) => {
            console.log('loadOwnerObj', response)
            this.data_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            await this.setOwnerObj()
            this.$forceUpdate()
          })
      },
      async loadEventCdObj() {
        const params = {}
        params['stat'] = [1]
        await this.basicPosts_S(config.BASE_API_URL + '/event_cd/lists', params)
          .then(async (response) => {
            console.log('loadEventCdObj', response)
            this.event_cd_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.event_cd_obj = []
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadEventRelationObj() {
        const params = {}
        params['stat'] = [1]
        params['c_id'] = this.$store.state.cauth.userId
        console.log('loadEventRelationObj params', params)
        await this.basicPosts_S(
          config.BASE_API_URL + '/owner_event_relation/lists',
          params
        )
          .then(async (response) => {
            console.log('loadEventRelationObj', response)
            this.event_relation_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.event_relation_obj = []
          })
          .finally(async () => {
            // await this.setClubObj();
            this.$forceUpdate()
          })
      },
      async loadApplicationObj() {
        const params = {}
        params['stat'] = [1]
        params['o_id'] = this.$store.state.oauth.userId
        params['not_application_check_types'] = [500, 900]
        await this.basicPosts_S(
          config.BASE_API_URL + '/owner_application/lists',
          params
        )
          .then(async (response) => {
            console.log('loadApplicationObj', response)
            this.application_obj =
              response.data && response.data.body ? response.data.body : []
          })
          .catch(async (err) => {
            console.log(err)
            this.application_obj = []
          })
          .finally(async () => {
            await this.setOwnerObj()
            this.$forceUpdate()
          })
      },
      async setOwnerObj() {
        if (this.data_obj.tel_1 == '--') {
          this.data_obj.tel_1 = '';
        }
        if (this.data_obj.tel_2 == '--') {
          this.data_obj.tel_2 = '';
        }

        if (
          this.data_obj.postal_cd.indexOf('-') < 0 &&
          this.data_obj.postal_cd.length > 3
        ) {
          const start_code = this.data_obj.postal_cd.substr(0, 3);
          const end_code = this.data_obj.postal_cd.substr(3);
          this.data_obj.postal_cd = start_code + '-' + end_code;
        }

        this.form_obj = {
          club_id: this.data_obj.club_id,
          club_no: this.data_obj.club_no,
          club_label: this.data_obj.club_label,
          owner_no: this.data_obj.owner_no,
          license_no: this.data_obj.license_no,
          race_owner_type_cd: this.data_obj.race_owner_type_cd,
          race_owner_type_cd_label: this.data_obj.race_owner_type_cd_label,
          name_1: this.data_obj.name_1,
          name_2: this.data_obj.name_2,
          name_3: this.data_obj.name_3,
          yomi_1: this.data_obj.yomi_1,
          yomi_2: this.data_obj.yomi_2,
          yomi_3: this.data_obj.yomi_3,
          postal_cd: this.data_obj.postal_cd,
          pref_cd: this.data_obj.pref_cd,
          address_1: this.data_obj.address_1,
          address_2: this.data_obj.address_2,
          tel_1: this.data_obj.tel_1,
          tel_2: this.data_obj.tel_2,
          fax_1: this.data_obj.fax_1,
          owner_assets: this.data_obj.owner_assets,
          mail_1: this.data_obj.mail_1,
          mail_2: this.data_obj.mail_2,
          // mail_flg: this.data_obj.mail_flg,
        }
      },
      async confirm_chk() {
        // if(
        //     this.change_login_id == '' || this.confirm_login_id == ''
        // ){
        //     alert('変更するログインIDを入力して下さい');
        //     return true;
        // }
        // else if(
        //     this.change_login_id != '' && this.confirm_login_id != ''
        //     && this.change_login_id != this.confirm_login_id
        // ){
        //     alert('入力されたログインIDが一致しません');
        //     return true;

        // }
        this.change_mode = true
      },
      async update() {
        console.log('component methods', this.data_obj)

        const params = {
          name_1: this.form_obj.name_1,
          name_2: this.form_obj.name_2,
          yomi_1: this.form_obj.yomi_1,
          yomi_2: this.form_obj.yomi_2,
          birth_date: this.form_obj.birth_date,
          profile: [],
          owner_assets: this.form_obj.owner_assets,
        }
        let profiles
        let upd_profile = {
          id: 0,
          o_id: this.$store.state.oauth.userId,
          postal_cd: '',
          pref_cd: '',
          address_1: '',
          address_2: '',
          tel_1: '',
          tel_2: '',
        }
        if (
          typeof this.data_obj === 'object' &&
          typeof this.data_obj.profile === 'object' &&
          Object.keys(this.data_obj.profile).length > 0
        ) {
          profiles = [...this.data_obj.profile]
          upd_profile = profiles[0]
        } else {
          profiles.push({ ...upd_profile })
        }
        if (this.form_obj.postal_cd) {
          upd_profile.postal_cd = this.form_obj.postal_cd
        }
        if (this.form_obj.pref_cd) {
          upd_profile.pref_cd = this.form_obj.pref_cd
        }
        if (this.form_obj.address_1) {
          upd_profile.address_1 = this.form_obj.address_1
        }
        if (this.form_obj.address_2) {
          upd_profile.address_2 = this.form_obj.address_2
        }
        if (this.form_obj.tel_1) {
          upd_profile.tel_1 = this.form_obj.tel_1
        }
        if (this.form_obj.tel_2) {
          upd_profile.tel_2 = this.form_obj.tel_2
        }

        profiles[0] = upd_profile

        params.profile = profiles

        await this.basicPosts_I(
          config.BASE_API_URL + '/owner/edit/' + this.$store.state.oauth.userId,
          params
        )
          .then(async (response) => {
            console.log('update', response)
          })
          .catch(async (err) => {
            console.log(err)
            this.data_obj = []
          })
          .finally(async () => {
            await this.loadOwnerObj()
            this.message = '更新しました'
            this.$forceUpdate()
          })
      },
      back_input() {
        this.change_mode = false
      },
      back() {
        this.$router.push({ path: '/owner/mypage' })
      },
    },
    computed: {
      ...mapState({}),
      race_type_cd_list: function () {
        const obj_list = [...this.race_type_cd_obj]
        return obj_list
          .sort((a, b) => {
            if (parseInt(a.sort) < parseInt(b.sort)) {
              return -1
            } else {
              return 1
            }
          })
          .map((list) => {
            list.key = list.race_type_cd
            return list
          })
      },
      get_race_type_label: function () {
        const race_type_list = this.race_type_cd_list
        return function (key) {
          if (
            typeof race_type_list.find((race_type) => race_type.key == key) ===
            'object'
          ) {
            return race_type_list.find((race_type) => race_type.key == key)
              .label
          }
          return key
        }
      },
      race_type_grade_relation_list: function () {
        return this.race_type_grade_relation_obj.filter(
          (obj) => obj.grade == this.data_obj.race_owner_type_cd_grade
        )
      },
      get_assets: function () {
        const form_obj = this.form_obj
        return function (type_cd, sort_key) {
          if (
            typeof form_obj === 'object' &&
            typeof form_obj.owner_assets === 'object' &&
            form_obj.owner_assets.find((asset) => asset.type == type_cd) !==
              false &&
            typeof form_obj.owner_assets.filter(
              (asset) => asset.type == type_cd
            ) === 'object' &&
            typeof form_obj.owner_assets.filter(
              (asset) => asset.type == type_cd
            )[sort_key] === 'object'
          ) {
            // console.log('get_assets', form_obj.owner_assets.filter(asset => asset.type == type_cd));
            return form_obj.owner_assets.filter(
              (asset) => asset.type == type_cd
            )[sort_key]
          }
          return {}
        }
      },
      get_event_label: function () {
        const event_list = this.event_list
        return function (key) {
          if (
            typeof event_list.find((event) => event.key == key) === 'object'
          ) {
            return event_list.find((event) => event.key == key).label
          }
          return key
        }
      },
      get_pref_label: function () {
        const pref_list = this.pref_list
        return function (key) {
          if (typeof pref_list.find((pref) => pref.key == key) === 'object') {
            return pref_list.find((pref) => pref.key == key).label
          }
          return key
        }
      },
      get_request_cd_label: function () {
        const lists = this.request_type_list
        return function (key) {
          if (typeof lists.find((list) => list.key == key) === 'object') {
            return lists.find((list) => list.key == key).label
          }
          return key
        }
      },
      request_type_list: function () {
        return this.request_type_cd_obj
      },
      pref_list: function () {
        const lists = [...this.pref_obj]
        return lists.sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },
      application_type_list: function () {
        return this.application_type_obj
      },
      application_check_type_list: function () {
        return this.application_check_type_obj
      },
      application_list: function () {
        return this.application_obj
      },
      application_filter: function () {
        const app_list = this.application_list
        return function (app_type, app_chk_list) {
          return app_list.filter((app) => {
            if (
              app.application_type == app_type &&
              app_chk_list.includes(app.application_check_type) !== false
            ) {
              return true
            }
            return false
          })
        }
      },
      get_target_application: function () {
        const owner_application = this.application_list
        return function (app_type) {
          return owner_application.find(
            (app) => app.application_type == app_type
          )
        }
      },
      get_target_application_btn_class: function () {
        const get_func = this.get_target_application
        const application_btn_class = this.application_btn_class
        return function (app_type) {
          const filtered = get_func(app_type)
          console.log('get_target_application_btn_class', app_type, filtered)
          return application_btn_class(
            (filtered || {}).application_check_type
              ? filtered.application_check_type
              : false
          )
        }
      },

      application_btn_class: function () {
        return function (check_type) {
          let base = 'btn btn-sm '
          base +=
            check_type == 100
              ? 'btn-light btn-outline-secondary'
              : check_type == 200
              ? 'btn-light btn-outline-secondary'
              : check_type == 500
              ? 'btn-success'
              : check_type == 600
              ? 'btn-warning'
              : check_type == 900
              ? 'btn-danger'
              : check_type == 990
              ? 'btn-danger'
              : 'button04'
          return base
        }
      },
      application_btn_label: function () {
        const get_func = this.get_target_application
        const application_type_list = this.application_type_list
        // const application_check_type_list = this.application_check_type_list;
        return function (app_type) {
          const application_type = application_type_list.find(
            (type) => type.key == app_type
          )
          const type_label = (application_type || {}).label

          const filtered = get_func(app_type)
          let chk_label = false
          if (typeof filtered === 'object' && filtered.application_check_type) {
            chk_label =
              filtered.application_check_type == 100
                ? '申請中です'
                : filtered.application_check_type == 200
                ? '確認中です'
                : filtered.application_check_type == 500
                ? '承認されました'
                : filtered.application_check_type == 600
                ? '差し戻しがあります。'
                : filtered.application_check_type == 900
                ? '不許可です'
                : filtered.application_check_type == 990
                ? '編集禁止です'
                : '変更して下さい'
          }
          return chk_label ? chk_label : type_label
        }
      },
      shipping_address_list: function () {
        return this.shipping_address_obj.filter((shipping, index) => {
          if (typeof shipping === 'object' && index == 0) {
            return true
          }
        })
      },
      get_owner_data: function () {
        return {
          name_1: this.data_obj.name_1,
          name_2: this.data_obj.name_2,
          yomi_1: this.data_obj.yomi_1,
          yomi_2: this.data_obj.yomi_2,
          birth_date: this.data_obj.birth_date,
          postal_cd: this.data_obj.birth_date,
          pref_cd: this.data_obj.birth_date,
          address_1: this.data_obj.address_1,
          address_2: this.data_obj.address_2,
          tel_1: this.data_obj.tel_1,
          tel_2: this.data_obj.tel_2,
          mail_1: this.data_obj.mail_1,
          mail_2: this.data_obj.mail_2,
          mail_flg: this.data_obj.mail_flg,
        }
      },
      input_class: function () {
        if (this.change_mode) {
          return 'form-control-plaintext'
        } else {
          return 'form-control'
        }
      },
      input_readonly: function () {
        if (this.change_mode) {
          return true
        } else {
          return false
        }
      },
      event_class: function () {
        const relation = this.event_relation_obj
        return function (event_id) {
          if (relation.find((obj) => obj.event_id == event_id)) {
            return 'text-center py-2 bg-warning rounded-2'
          } else {
            return 'd-none text-center py-2 bg-light rounded-2 border border-secondary'
          }
        }
      },
      event_list: function () {
        const lists = [...this.event_cd_obj]
        return lists.sort((a, b) => {
          if (parseInt(a.sort) < parseInt(b.sort)) {
            return -1
          } else {
            return 1
          }
        })
      },
    },
    async created() {
      await this.loadConfigObj('request_type_cd')
      await this.loadConfigObj('application_type')
      await this.loadConfigObj('application_check_type')
      await this.loadPrefList()
      await this.loadShippingObj()
      await this.loadOwnerObj()
      await this.loadApplicationObj()
      await this.setOwnerObj()
      await this.loadEventCdObj()
      await this.loadEventRelationObj()
      await this.loadRaceTypeCd()
      await this.loadRaceTypeGradeRelation()
    },
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
    props: {},
  }
</script>

<template>
  <div class="login">
    <div class="container">
      <div class="row " v-if="false">
        <div class="col-md-8 col-lg-6 w-100 mb-32">
          <div class="wysiwyg">
            <h3>【お知らせ 】会員入会・更新・主催者ログインに関するシステム障害についてのお詫び</h3>
            平素よりモーターサイクルスポーツ活動へのご理解とご協力を賜り、誠にありがとうございます。<br>
            <br>
            2025年2月3日からスタートいたしました新システムにおいて、システムの不具合により、<br>
            一部の会員様・主催者様が手続きができず、ご不便をおかけしております。<br>
            ご迷惑をおかけしておりますこと、深くお詫び申し上げます。<br>
            <br>
            現在、順次問題改善を図っておりますが、完全復旧の目途は立っていない状況です。<br>
            <br>
            ご不明点やお困りのことがございましたら、MFJライセンス課までご連絡ください。<br>
            <br>
            ご不便、ご迷惑をおかけし、誠に申し訳ございません。何卒よろしくお願い申し上げます。<br>
            <br>
            <h5>お問い合わせ先</h5>
            <p>
              一般財団法人日本モーターサイクルスポーツ MFJライセンス課<br>
              メール：license-info@mfj.or.jp<br>
              電話：03-5565-0900（平日10:00～16:00）
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 w-100 mb-32">
          <div class="text-end">
            <a :href="get_base_asset + '/manual/mfj_mypage_250203.pdf'">「MFJ-WEB会員サービス マイページ」操作マニュアル</a>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 w-100 mb-32">
          <div class="alert alert-danger wysiwyg">
            <h5 class="title text-center">ご注意下さい</h5>
            <p class="text-center py-0 mb-3">
              旧システム(2025年1月20日稼働停止)の利用者の方は、<br>
              新システム(2025年2月3日稼働開始)の利用に際して、利用手続きが必要となります。<br>
              下記から利用手続を行ってからご利用下さい。
            </p>
            <div class="row">
              <div class="col-12">
                <div class="d-grid">
                  <router-link class="btn btn-danger btn-lg text-white" to="/migration/add">「MFJ-WEB会員サービス 利用開始手続き」はこちら</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-lg-6 w-100">
          <h2 class="title login__title text-center">会員マイページログイン</h2>
          <form @submit.prevent="handleSubmit">
            <bs-message-block
              :show="showError"
              :message="errorMessage"
            ></bs-message-block>
            <ul class="login__formList">
              <li class="login__formListItem">
                <label class="label login__label" for="user">ログインID</label>
                <div class="inputWrapper">
                  <input 
                    class="input login__input" 
                    id="user" 
                    type="text" 
                    v-model="login_id"
                    placeholder="ログインIDを入力"
                  />
                </div>
              </li>
              <li class="login__formListItem">
                <label class="label login__label" for="password">パスワード</label>
                <div class="inputWrapper">
                  <input 
                    class="input login__input" 
                    id="password" 
                    :type="isPasswordVisible ? 'text' : 'password'"
                    v-model="login_pass"
                    placeholder="パスワードを入力"
                  >
                  <span
                    class="input-group-addon"
                    @click="togglePasswordVisibility"
                  >
                    <font-awesome-icon
                      :icon="
                        isPasswordVisible
                          ? ['fas', 'eye']
                          : ['fas', 'eye-slash']
                      "
                    />
                  </span>
                </div>
              </li>
            </ul>
            <p class="login__button">
              <button class="button02" type="submit">
                <span class="button02__text">ライセンス会員マイページへログインする</span>
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="mx-2"
                />
              </button>
            </p>
          </form>
          <div class="login__box">
            <div class="login__boxIcon">
              <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
            </div>
            <ul class="login__boxUl">
              <li class="login__boxLi text-center">
                <a href="https://6e54685a.form.kintoneapp.com/public/d545c83537a9d55d68a41ffed89f683c5949d2031470200c3cc8ebbc55e3f4f0?_formAccessToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWJkb21haW4iOiI2ZTU0Njg1YSIsImlhdCI6MTczNzYyODcyNiwiZXhwIjoxNzM3NjI4Nzg2fQ.TY3ybACYHkG1pRvUTKeUIMWBZokJgPDaMRHgBIvRgJQ">※システムに関するお問い合わせはこちらから</a>
              </li>
              <li class="login__boxLi text-center">
                ※パスワードを忘れてしまった方は、<router-link
                  class="login__boxLink"
                  to="/passwd/passcd"
                  >こちら</router-link
                >から再設定手続きを行なってください。
              </li>
              <li class="login__boxLi text-center">
                ※ライセンスNo.メールアドレスともに忘れてしまった場合は、<a
                  class="login__boxLink"
                  href="https://www.mfj.or.jp/other/about/contact/"
                  >こちら</a
                >からお問い合わせ下さい。
              </li>
              <li class="login__boxLi text-center">
                ※新たに会員になりたい方は、<router-link
                  class="login__boxLink"
                  to="/regist/add"
                  >こちら</router-link
                >からお申し込みください。
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>

.input-group {
    position: relative;
  }

.input-group-addon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
.inputWrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 400px;
}
</style>
<script>
  import BsMessageBlock from '../atoms/BsMessageBlock.vue'

  export default {
    name: 'BsLoginForm',
    components: {
      BsMessageBlock,
    },
    data() {
      return {
        login_id: '',
        login_pass: '',
        showError: false,
        errorMessage: '',
        isLoading: false,
        isPasswordVisible: false,
      }
    },
    computed: {
      isSubmitDisabled() {
        return !this.login_id || !this.login_pass || this.isLoading
      },
    },
    methods: {
      // パスワード表示切り替え
      togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible
      },
      handleSubmit() {
        if (this.isSubmitDisabled) {
          this.showError = true
          this.errorMessage = 'ログイン情報を入力してください'
          return
        }

        this.isLoading = true
        this.showError = false
        this.errorMessage = ''

        this.onlogin({ login_id: this.login_id, login_pass: this.login_pass })
          .catch(() => {
            this.showError = true
            this.errorMessage =
              'ログインに失敗しました。正しいログインIDとパスワードを入力してください。もしくは新システムに移行されていない方は、移行申請を行って下さい。'
          })
          .finally(() => {
            this.isLoading = false
          })
      },
    },
    props: {
      onlogin: {
        type: Function,
        required: true,
      },
    },
  }
</script>

<template>
  <div class="container mb-5">
    <div class="row" v-if="success_mode || error_mode"></div>
    <div class="row" v-else>
      <div class="col text-center">
        <div class="row">
          <div class="col-12 col-md-9" v-if="kiyaku_mode">利用規約の確認</div>
          <div class="col-12 col-md-9" v-else>1</div>
          <div class="col-12 col-md-3">
            <i class="bi bi-caret-right-fill"></i>
          </div>
        </div>
      </div>
      <div class="col text-center">
        <div class="row">
          <div class="col-12 col-md-9" v-if="mail_mode">
            メールアドレスの確認
          </div>
          <div class="col-12 col-md-9" v-else>2</div>
          <div class="col-12 col-md-3">
            <i class="bi bi-caret-right-fill"></i>
          </div>
        </div>
      </div>
      <div class="col text-center">
        <div class="row">
          <div class="col-12 col-md-9" v-if="license_mode">
            申請ライセンスの選択
          </div>
          <div class="col-12 col-md-9" v-else>3</div>
          <div class="col-12 col-md-3">
            <i class="bi bi-caret-right-fill"></i>
          </div>
        </div>
      </div>
      <div class="col text-center">
        <div class="row">
          <div class="col-12 col-md-9" v-if="profile_mode">個人情報の入力</div>
          <div class="col-12 col-md-9" v-else>4</div>
          <div class="col-12 col-md-3">
            <i class="bi bi-caret-right-fill"></i>
          </div>
        </div>
      </div>
      <div class="col text-center">
        <div class="row">
          <div class="col-12 col-md-9" v-if="confirm_mode">申請内容の確認</div>
          <div class="col-12 col-md-9" v-else>5</div>
          <div class="col-12 col-md-3">
            <i class="bi bi-caret-right-fill"></i>
          </div>
        </div>
      </div>
      <div class="col text-center">
        <div class="row">
          <div class="col-12 col-md-9" v-if="mfj_check_mode">MFJ確認</div>
          <div class="col-12 col-md-9" v-else>6</div>
          <div class="col-12 col-md-3">
            <i class="bi bi-caret-right-fill"></i>
          </div>
        </div>
      </div>
      <div class="col text-center">
        <div class="row">
          <div class="col-12" v-if="charge_mode">年会費のお支払い</div>
          <div class="col-12" v-else>7</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
<script>
  import { mapState } from 'vuex'
  // import BsPageTitle from '../../molecules/BsPageTitle.vue'
  // import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
  // import BsSelect from '../../atoms/BsSelect.vue'
  // import BsCheckBox from '../../atoms/BsCheckBox.vue'
  // import '../../assets/bootstrap/css/sc.css';
  // import config from '../../../const'

  export default {
    name: 'RegistAddView',
    data: function () {
      return {
        // page_mode: 'kiyaku',
        // update_result: false,
        // update_error: false,
        // passwd_mode: 'chk_application',
        // message: 'メールアドレスの変更を行っています。'
      }
    },
    components: {
      //   BsPageTitle,
      //   BsSelect,
      //   BsMsgAlert,
      // BsCheckBox,
    },
    methods: {},
    props: {
      step_mode: {
        type: String,
        default: 'kiyaku',
      },
    },
    computed: {
      ...mapState({}),
      kiyaku_mode: function () {
        return this.step_mode == 'kiyaku' ? true : false
      },
      mail_mode: function () {
        return this.step_mode == 'mail' || this.step_mode == 'passcd'
          ? true
          : false
      },
      license_mode: function () {
        return this.step_mode == 'license' || this.step_mode == 'license_sel'
          ? true
          : false
      },
      profile_mode: function () {
        return this.step_mode == 'profile' ? true : false
      },
      confirm_mode: function () {
        return this.step_mode == 'confirm' ? true : false
      },
      mfj_check_mode: function () {
        return this.step_mode == 'mfj_check' ? true : false
      },
      charge_mode: function () {
        return this.step_mode == 'charge' ? true : false
      },
      success_mode: function () {
        return this.step_mode == 'success' ? true : false
      },
      error_mode: function () {
        return this.step_mode == 'error' ? true : false
      },
    },

    async created() {},
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
  }
</script>

import config from '../const'
export default {
  computed: {
    get_base_env: function(){
      return process.env.NODE_ENV
    },
    get_base_api: function(){
      return config.BASE_API_URL
    },
    get_base_asset: function(){
      return config.BASE_ASSETS_URL
    },
    chk_passwd_character: function(){
      return function(passwd){
        if(/^[A-Za-z0-9!?@#%^&*]+$/.test(passwd)){
          return true
        }
        return false
      }
    },
    mfj_phase_year_term_001: function(){
      if(
        parseInt(this.getFormattedDate) >= 20250331233000
        && 
        parseInt(this.getFormattedDate) <= 20250401003000
      ){
        return true
      } else {
        return false
      }
    },
    mfj_phase_club_001: function(){
      if (this.get_base_env != 'production') {
        return true
      }else if(
        parseInt(this.getFormattedDate) >= 20250303130000
      ){
        return true
      } else {
        return false
      }
    },
    mgw_phase_production_001: function () {
      if (process.env.NODE_ENV == 'production') {
        return true
      } else {
        return false
      }
    },
    getFormattedDate: function() {
      const now = new Date();
      
      const YYYY = now.getFullYear();
      const mm = String(now.getMonth() + 1).padStart(2, '0'); // 月は0始まりなので+1
      const DD = String(now.getDate()).padStart(2, '0');
      const HH = String(now.getHours()).padStart(2, '0');
      const ii = String(now.getMinutes()).padStart(2, '0');
      const SS = String(now.getSeconds()).padStart(2, '0');

      return `${YYYY}${mm}${DD}${HH}${ii}${SS}`;
    },
  
    vue_node_env: function () {
      return process.env.NODE_ENV
    },
    dev_msg: function () {
      const node_env = process.env.NODE_ENV
      return function (msg) {
        if (node_env != 'production') {
          return '<<' + msg + '>>'
        }
        return ''
      }
    },
    get_logout: function(){
      if(this.get_load_type == 'owner'){
        return 'ologout'
      }
      else if(this.get_load_type == 'club'){
        return 'clogout'
      }
      else if(this.get_load_type == 'member'){
        return 'logout'
      }
      return 'logout'
    },
    get_load_type: function () {
      const path = this.$route.path
      return path == '/' || this.user_range
        ? 'member'
        : path == '/club/mypage' || this.club_range
        ? 'club'
        : path == '/owner/mypage' || this.owner_range
        ? 'owner'
        : 'member'
      // return (
      //     this.owner_range
      // )? 'owner'
      // : (
      //     this.club_range
      // )? 'club'
      // : (
      //     this.user_range
      // )? 'member'
      // : 'member';
    },
    user_range: function () {
      const path = this.$route.path
      if (
        config.HEAD_MENU_USER_LIST.find((list) => list.path == path) &&
        ['/club/mypage', '/owner/mypage'].includes(path) == false
      ) {
        return true
      }
      return false
    },
    owner_range: function () {
      const path = this.$route.path
      if (
        config.HEAD_MENU_OWNER_LIST.find((list) => list.path == path) &&
        ['/club/mypage', '/'].includes(path) == false
      ) {
        return true
      }
      return false
    },
    club_range: function () {
      const path = this.$route.path
      // console.log(
      //   'club_range',
      //   path,
      //   config.HEAD_MENU_CLUB_LIST.find((list) => list.path == path),
      //   config.HEAD_MENU_CLUB_LIST
      // )
      if (
        config.HEAD_MENU_CLUB_LIST.find((list) => list.path == path) &&
        ['/', '/owner/mypage'].includes(path) == false
      ) {
        return true
      }
      return false
    },

    get_main_clinic_id: function () {
      if (
        typeof this.$store.state.auth.relations === 'object' &&
        Object.keys(this.$store.state.auth.relations).length > 0
      ) {
        return this.$store.state.auth.relations[0]
      } else {
        return ''
      }
    },
    get_relation_clinic: function () {
      if (
        typeof this.$store.state.auth.relations === 'object' &&
        Object.keys(this.$store.state.auth.relations).length > 0
      ) {
        return this.$store.state.auth.relations
      } else {
        return []
      }
    },
    get_clinic_relation_where: function () {
      if (
        typeof this.get_relation_clinic === 'object' &&
        Object.keys(this.get_relation_clinic).length > 0
      ) {
        return { ids: this.get_relation_clinic }
      } else {
        return { id: 0 }
      }
    },
    get_staff_relation_where: function () {
      if (this.$store.state.auth.level == 0) {
        return { pagegroup: 'staff_data.id' }
      } else if (
        typeof this.get_relation_clinic === 'object' &&
        Object.keys(this.get_relation_clinic).length > 0
      ) {
        return { clinic_ids: this.get_relation_clinic, pagegroup: 'staff_id' }
      } else {
        return { clinic_id: 0 }
      }
    },
    calc_age: function () {
      return function (birth_date) {
        if (birth_date && !isNaN(new Date(birth_date).getDate())) {
          // 誕生年
          let birthday_year = birth_date.substr(0, 4)
          // 誕生月
          let birthday_mon = birth_date.substr(6, 2)
          // 誕生日
          let birthday_day = birth_date.substr(9, 2)

          const sNum = []
          for (let i = 1; i < 10; i++) sNum.push('0' + String(i))
          if (sNum.includes(birthday_mon)) {
            birthday_mon = sNum.indexOf(birthday_mon) + 1
          }
          if (sNum.includes(birthday_day)) {
            birthday_day = sNum.indexOf(birthday_day) + 1
          }

          //今日
          var today = new Date()
          //今年の誕生日
          var thisYearsBirthday = new Date(
            today.getFullYear(),
            birthday_mon - 1,
            birthday_day
          )
          //年齢
          var age = today.getFullYear() - parseInt(birthday_year)
          if (today < thisYearsBirthday) {
            //今年まだ誕生日が来ていない
            age--
          }
          return age
        }
        return ''
      }
    },
    calc_sports_age: function () {
      return function (birth_date) {
        if (birth_date && !isNaN(new Date(birth_date).getDate())) {
          // 誕生年
          let birthday_year = birth_date.substr(0, 4)
          // 誕生月
          let birthday_mon = birth_date.substr(6, 2)
          // 誕生日
          let birthday_day = birth_date.substr(9, 2)

          const sNum = []
          for (let i = 1; i < 10; i++) sNum.push('0' + String(i))
          if (sNum.includes(birthday_mon)) {
            birthday_mon = sNum.indexOf(birthday_mon) + 1
          }
          if (sNum.includes(birthday_day)) {
            birthday_day = sNum.indexOf(birthday_day) + 1
          }

          //今日
          var today = new Date()
          //今年の
          // var thisYearsBirthday = new Date(today.getFullYear(), birthday_mon-1, birthday_day);
          //年齢
          var age = today.getFullYear() - parseInt(birthday_year)
          // if(today < thisYearsBirthday){
          //     //今年まだ誕生日が来ていない
          //     age--;
          // }
          return age
        }
        return ''
      }
    },
    calc_sports_age_: function () {
      return function (birth_date, sports_year) {
        if (birth_date && !isNaN(new Date(birth_date).getDate())) {
          // 誕生年
          let birthday_year = birth_date.substr(0, 4)
          // 誕生月
          let birthday_mon = birth_date.substr(6, 2)

          let age = ''
          // 早生まれ
          if (['01', '02', '03'].includes(birthday_mon)) {
            age = parseInt(sports_year) - parseInt(birthday_year)
            age = age + 1
          } else {
            age = parseInt(sports_year) - parseInt(birthday_year)
          }
          return age
          // // 誕生日
          // let birthday_day = birth_date.substr(9, 2);

          // const sNum = [];
          // for(let i=1;i<10;i++) sNum.push('0'+String(i));
          // if(
          //     sNum.includes(birthday_mon)
          // ){
          //     birthday_mon = sNum.indexOf(birthday_mon) + 1;
          // }
          // if(
          //     sNum.includes(birthday_day)
          // ){
          //     birthday_day = sNum.indexOf(birthday_day) + 1;
          // }

          // //今日
          // var today = new Date();
          // //今年の
          // var thisYearsBirthday = new Date(today.getFullYear(), birthday_mon-1, birthday_day);
          // // 年齢
          // var sportsYearDate = new Date(sports_year, 2, 31);
          // //年齢
          // var age = today.getFullYear() - parseInt(birthday_year);
          // if(sportsYearDate < thisYearsBirthday){
          // // if(today < thisYearsBirthday){
          //     //今年まだ誕生日が来ていない
          //     age--;
          // }
          // return age;
        }
        return ''
      }
    },
    chk_dev: function(){
      return (this.vue_node_env=='development')? true: false
    },
  },
  methods: {
    number_format(value) {
      if (typeof value !== 'number' && typeof value !== 'string') return value
      return value.toString().replace(/(\d+?)(?=(?:\d{3})+$)/g, function (x) {
        return x + ','
      })
    },
    is_json(value) {
      try {
        JSON.parse(value)
      } catch (error) {
        return false
      }
      return true
    },
    // setParams(params, mode){
    //     const params_ = new URLSearchParams();
    //     if(params){
    //         const keys = Object.keys(params);
    //         for(let i=0; i < keys.length; i++){
    //             if(
    //                 keys[i] == 'p'
    //                 // || keys[i].indexOf('page') >= 0
    //             ){
    //                 params_.append(keys[i], params[keys[i]]);
    //             }
    //             else if(params[keys[i]] !== null && typeof params[keys[i]] === 'object'){
    //                 params_.append(mode + '[' + keys[i] + ']', JSON.stringify(params[keys[i]]));
    //             }
    //             else{
    //                 params_.append(mode + '[' + keys[i] + ']', params[keys[i]]);
    //             }
    //         }
    //     }
    //     return params_;
    // },
    // setParams_S(params){
    //     return this.setParams(params, 'sprm');
    // },
    // setParams_I(params){
    //     return this.setParams(params, 'iprm');
    // },
  },
}

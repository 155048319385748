<template>
  <section class="py-5">
    <!-- ローディング表示 -->
    <div class="loading-overlay" v-if="isLoading">
      <div class="spinner-border text-danger" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="d-none d-md-block" v-if="!national_mode">
      <step-view :step_mode="page_mode"></step-view>
    </div>
    <div class="container" v-if="mfj_phase_year_term_001">
      <div class="row">
          <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
              <div class="box">
                  現在年度切り替え中です。<br>
                  しばらくしてお申し込み下さい。
              </div>
          </div>
      </div>
    </div>
    <div class="w-100" v-else>
      <div class="col-12 col-xl-10 offset-xl-1" v-if="national_mode">
        <div class="row " v-if="false">
          <div class="col-md-8 col-lg-6 w-100 mb-32">
            <div class="wysiwyg">
              <h3>【お知らせ 】会員入会・更新・主催者ログインに関するシステム障害についてのお詫び</h3>
              平素よりモーターサイクルスポーツ活動へのご理解とご協力を賜り、誠にありがとうございます。<br>
              <br>
              2025年2月3日からスタートいたしました新システムにおいて、システムの不具合により、<br>
              一部の会員様・主催者様が手続きができず、ご不便をおかけしております。<br>
              ご迷惑をおかけしておりますこと、深くお詫び申し上げます。<br>
              <br>
              現在、順次問題改善を図っておりますが、完全復旧の目途は立っていない状況です。<br>
              <br>
              ご不明点やお困りのことがございましたら、MFJライセンス課までご連絡ください。<br>
              <br>
              ご不便、ご迷惑をおかけし、誠に申し訳ございません。何卒よろしくお願い申し上げます。<br>
              <br>
              <h5>お問い合わせ先</h5>
              <p>
                一般財団法人日本モーターサイクルスポーツ MFJライセンス課<br>
                メール：license-info@mfj.or.jp<br>
                電話：03-5565-0900（平日10:00～16:00）
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-lg-6 w-100 mb-32">
          <div class="alert alert-danger wysiwyg">
            <h5 class="title text-center">こちらのページは、</h5>
            <h5 class="title text-center"><span class="text-danger">MFJに入会したことがない方</span>もしくは<span class="text-danger">2018年以前にMFJライセンスをお持ちだった方</span></h5>
            <h5 class="title text-center">専用の入会申請ページです。</h5>
            <p class="text-center py-0 mb-3">
              2019年度〜2024年度にMFJライセンスを保有されている方は下記より利用手続を行って下さい。
            </p>
            <div class="row">
              <div class="col-12">
                <div class="d-grid">
                  <router-link class="btn btn-danger btn-lg text-white" to="/migration/add">「MFJ-WEB会員サービス 利用開始手続き」はこちら</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box02" v-if="!national_mode">
          <h2 class="box02__title">
            <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
            {{ message }}
          </h2>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 w-100 mb-32">
            <div class="text-end">
              <a :href="get_base_asset + '/manual/mfj_member_regist_250203.pdf'">「MFJ-WEB会員サービス 新規入会申請」 マニュアル</a>
            </div>
          </div>
        </div>
        <div class="container my-4 my-md-4 mt-0 mt-md-4">
          <div class="mb-5">
            <h2 class="title page__h2 text-start">入会申請の流れ</h2>
          </div>
          <div class="box02 mb-5">
            <h2 class="box02__title">
              外国籍の方は下の「外国籍の方はこちら」ボタンから、専用ページへ進んで下さい
            </h2>
          </div>
          <div class="table-responsive">
            <table class="table table-dered">
              <thead class="bg-light">
                <tr>
                  <th style="width: 30%">ステップ</th>
                  <th class="text-start">内容</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="fw-bold">①申請前の準備</td>
                  <td>申請に必要な書類、講習会を確認していただきます</td>
                </tr>
                <tr>
                  <td class="fw-bold">②利用規約の確認</td>
                  <td>
                    MFJ会員WEBサービスの利用規約を確認し、ご利用の際は同意いただきます
                  </td>
                </tr>
                <tr>
                  <td class="fw-bold">③メールアドレスの確認と確認番号の取得</td>
                  <td>
                    登録するメールアドレスの受信確認をさせていただきます<br />
                    また、申請ライセンスの選択ページに入るための確認番号を取得していただきます<br />
                    確認番号がメール送信されます
                  </td>
                </tr>
                <tr>
                  <td class="fw-bold">④申請ライセンスの選択</td>
                  <td>申請するライセンスを選択していただきます</td>
                </tr>
                <tr>
                  <td class="fw-bold">⑤個人情報の入力</td>
                  <td>申請に必要な個人情報を入力していただきます</td>
                </tr>
                <tr>
                  <td class="fw-bold">⑥申請内容の確認と申請実行</td>
                  <td>
                    申請内容を確認し申請を実行していただきます<br />
                    申請受付メールが送信されます
                  </td>
                </tr>
                <tr>
                  <td class="fw-bold">⑦MFJでの申請内容確認</td>
                  <td>
                    MFJで内容を確認させていただきます<br />
                    不備がなければ申請料お支払いについてのご案内をメール送信致します<br />
                    不備があった場合は内容をメールにてご案内いたします
                  </td>
                </tr>
                <tr>
                  <td class="fw-bold">⑧申請料金のお支払い</td>
                  <td>
                    クレジットカード決済、コンビニ決済のいずれかを選択しお支払いをお願いいたします
                  </td>
                </tr>
                <tr>
                  <td class="fw-bold">⑨WEB講習会の受講</td>
                  <td>
                    申請ライセンスに応じたWEB講習会を受講していただきます。<br />
                    ※ロードレースライセンスはサーキットライセンスの取得などが必要です
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center mt-3 py-2 bg-light border rounded">
              <p class="fw-bold mb-0">申請終了</p>
            </div>
          </div>
        </div>

        <div class="container my-4">
          <div class="mb-5">
            <h2 class="title page__h2 text-start">
              入会申請前にご用意いただくもの
            </h2>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="bg-light">
                <tr>
                  <th style="width: 30%">必要書類等</th>
                  <th class="text-start">詳細</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="fw-bold">①デジタル会員証に必要な証明写真データ</td>
                  <td>スマホなどで撮影いただきアップロードしていただきます</td>
                </tr>
                <tr>
                  <td class="fw-bold">
                    ②本人確認書類のスキャンデータ、または画像データ
                  </td>
                  <td>
                    運転免許証、マイナンバーカード、住民票などの氏名・生年月日が記載されたもの
                  </td>
                </tr>
                <tr>
                  <td class="fw-bold">
                    ③サーキットライセンスの画像データ（ロードレースのみ）
                  </td>
                  <td>
                    サーキット名とサーキットライセンスNo.が確認できるように撮影してください
                  </td>
                </tr>
                <tr>
                  <td class="fw-bold">④対面講習会の修了証</td>
                  <td>
                    サーキットライセンス同時申請者（サーキット名称、受講日、サーキットライセンスNo.がはいったもの）
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-4">
            <h3 class="h5 mb-3">
              ロードレース競技ライセンス資格の取得について
            </h3>
            <p class="mb-0">
              ロードレース競技ライセンスの申請には、<a
                href="https://archive.mfj.or.jp/user/contents/Applications/license/pdf/kaijou.pdf"
                target="_blank"
                class="text-primary"
                >公認サーキット</a
              >が発行するライセンス証が必要です。詳しくは、「申請条件と講習会」をご確認下さい。
            </p>
          </div>
        </div>

        <div class="container my-4">
          <div class="mb-5">
            <h2 class="title page__h2 text-start">申請条件と講習会</h2>
          </div>

          <!-- ロードレース -->
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="bg-light">
                <tr>
                  <th>種目</th>
                  <th>区分</th>
                  <th>対象年齢 *1</th>
                  <th>取得条件・講習会 *2</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowspan="3">ロードレース</td>
                  <td>ジュニア *3</td>
                  <td>12～15歳</td>
                  <td>
                    有効なMFJ公認サーキットライセンスを所持していること<br />
                    またはMFJ公認ロードレースジュニアライセンス講習会を受講していること
                  </td>
                </tr>
                <tr>
                  <td>フレッシュマン</td>
                  <td>16歳～</td>
                  <td>有効なMFJ公認サーキットライセンスを所持していること</td>
                </tr>
                <tr>
                  <td>国内</td>
                  <td>16歳～</td>
                  <td>
                    MFJ公認ロードレース国内ライセンス講習会を受講していること<br />
                    MFJ公認サーキットで3時間以上のスポーツ走行を行っていること
                  </td>
                </tr>
                <tr>
                  <td colspan="4" class="small bg-light">
                    <p class="mb-2">
                      *1 対象年齢はスポーツ年齢（当該年12月31日時点の年齢）
                    </p>
                    <p class="mb-0">
                      *2
                      MFJ公認サーキットライセンスの取得は、各サーキットでの取得基準によります。お問合せは<a
                        href="https://archive.mfj.or.jp/user/contents/Applications/license/pdf/kaijou.pdf"
                        target="_blank"
                        class="text-primary"
                        >公認サーキット</a
                      >
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- その他の競技 -->
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="bg-light">
                <tr>
                  <th>種目</th>
                  <th>区分</th>
                  <th>対象年齢 *1</th>
                  <th>取得条件・講習会 *2</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowspan="3">モトクロス</td>
                  <td>PC *3</td>
                  <td>8歳まで</td>
                  <td rowspan="12">
                    MFJWEB講習会<br />または<br />MFJ公認ライセンス講習会を受講していること
                  </td>
                </tr>
                <tr>
                  <td>ジュニア</td>
                  <td>9歳～15歳</td>
                </tr>
                <tr>
                  <td>国内B級</td>
                  <td>16歳～</td>
                </tr>
                <tr>
                  <td rowspan="2">トライアル</td>
                  <td>ジュニア *3</td>
                  <td>9歳～15歳</td>
                </tr>
                <tr>
                  <td>国内B級</td>
                  <td>16歳～</td>
                </tr>
                <tr>
                  <td>エンデューロ</td>
                  <td>国内B級</td>
                  <td>16歳～</td>
                </tr>
                <tr>
                  <td>スーパーモト</td>
                  <td>B級 *3</td>
                  <td>12歳～</td>
                </tr>
                <tr>
                  <td rowspan="2">スノーモビル</td>
                  <td>ジュニア *3</td>
                  <td>9歳～15歳</td>
                </tr>
                <tr>
                  <td>B級</td>
                  <td>16歳～</td>
                </tr>
                <tr>
                  <td>エンジョイ</td>
                  <td>*3</td>
                  <td>年齢制限なし</td>
                </tr>
                <tr>
                  <td>ピットクルー *4</td>
                  <td>-</td>
                  <td>16歳～</td>
                </tr>
                <tr>
                  <td>競技役員 *5</td>
                  <td>RD・MX・TR・SN</td>
                  <td>18歳～</td>
                </tr>
                <tr>
                  <td>講師</td>
                  <td>RD・MX・TR・SN</td>
                  <td>18歳～</td>
                  <td>
                    MFJ公認ライセンス取得講習会を受講していること（対面のみ）
                  </td>
                </tr>
                <tr>
                  <td colspan="4" class="small bg-light">
                    <p class="mb-2">
                      *1 対象年齢はスポーツ年齢（当該年12月31日時点の年齢）
                    </p>
                    <p class="mb-2">
                      *2
                      MFJWEB講習会は、MFJホームページから受講できます。受講は入会手続き完了後にご案内いたします
                    </p>
                    <p class="mb-2">
                      MFJ公認ライセンス講習会は、各地のMFJ公認主催者が開催する対面での講習会です
                    </p>
                    <p class="mb-2">
                      *3
                      15歳以下の方がMFJWEB講習会を受講する際は、保護者と一緒に受講と理解度テスト回答を行ってください
                    </p>
                    <p class="mb-2">
                      *4
                      ピットクルーライセンスにエンジョイ資格を付与する方は、MFJWEB講習会を受講してください
                    </p>
                    <p class="mb-2">
                      *5
                      競技役員ライセンスにエンジョイ資格を付与する方は、MFJWEB講習会の競技役員・エンジョイを受講してください
                    </p>
                    <p class="mb-0">
                      ※エンジョイ資格とは、承認競技会ロードレースを除く競技に選手として参加できる資格
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- 重要事項 -->
          <div class="container my-4">
            <div>
              <h2 class="title page__h2 text-start">重要事項</h2>
            </div>
            <div class="mb-4">
              <ul>
                <li class="mb-3">
                  <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
                  入会時に未成年（18歳未満）の方は、手続き完了後に「<a
                    href="https://www.mfj.or.jp/licence/downloads/minors-document/"
                    target="_blank"
                    class="text-primary"
                    >未成年者の競技参加承諾書</a
                  >・親権者の印鑑登録証明書」が必要です。
                </li>
                <li class="mb-3">
                  <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
                  お手続き完了後のキャンセルや変更などはできませんのでお手続きの際はご注意ください
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="container my-4">
          <div class="d-flex flex-column flex-md-row">
            <button
              class="button04 mb-2 mb-md-0 me-md-2 flex-grow-1"
              @click="proceed('kiyaku')"
            >
              <span class="button__text">日本国籍の方はこちら </span>
              <font-awesome-icon
                :icon="['fas', 'chevron-right']"
                class="mx-2"
              />
            </button>
            <button class="button04 flex-grow-1" @click="go_location('https://6e54685a.form.kintoneapp.com/public/3663cdb1f1297a99f65405c0d37e892ecd7a79d7da38ffa98f3ca36f96c9baf6')">
              <span class="button__text">外国籍の方はこちら</span>
              <font-awesome-icon
                :icon="['fas', 'chevron-right']"
                class="mx-2"
              />
            </button>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-12 col-xl-10 offset-xl-1"></div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-10 offset-xl-1" v-if="kiyaku_mode">
        <div class="box02 mb-32">
          <div>
            <div>
              <h2 class="box02__title">
                <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
                以下の会員規約に同意してください。
              </h2>
            </div>
          </div>
        </div>

        <div class="mb-5">
          <div class="border border-secondary bg-light rounded-2 py-3 px-4">
            <p class="m-0">
              サービス利用規約<br />
              <br />
              このサービス利用規約（以下「本規約」といいます。）は、一般財団法人日本モーターサイクルスポーツ協会（以下「MFJ」といいます。）が提供する会員WEBサービス（以下「本サービス」といいます。）を利用する方（以下「会員」といいます。）に適用されるものとします。入会手続を完了した時点で、会員は本規約に同意したものとみなされます。<br />
              <br />
              第1条（会員資格）<br />
              1. 会員とは、所定の手続を完了し、MFJが入会を承認した方とします。<br />
              2. 会員は、本規約及びMFJが定めるルールに従って本サービスを利用するものとします。<br />
              <br />
              第2条（利用目的）<br />
              本サービスは、会員に対し、2輪競技者の競技会参加サポートを目的として提供されます。<br />
              <br />
              第3条（会員の義務）<br />
              1. 会員は、登録時に虚偽の情報を提供してはならないものとします。<br />
              2. 会員は、ID及びパスワードを適切に管理し、第三者に譲渡又は貸与してはなりません。<br />
              3. 会員は、MFJ又は他の会員、第三者の権利を侵害する行為や法令に違反する行為を行ってはなりません。<br />
              4. 会員は、自身のIDやパスワードが漏洩した、又はその恐れがあると判明した場合、速やかにMFJへ報告し、MFJからの指示に従うものとします。<br />
              <br />
              第4条（禁止事項）<br />
              1. 会員は、以下の行為を行ってはならないものとします。<br />
              2. 本サービスの運営を妨害する行為<br />
              3. 本サービスを通じて提供される情報を不正に利用する行為<br />
              4. 他の会員又は第三者への誹謗中傷や迷惑行為<br />
              5. MFJの財産、権利、知的財産を侵害する行為<br />
              6. 他の会員のプライバシーを侵害する行為（例：個人情報の無断収集・公開）<br />
              7. 不正アクセス又はシステムへの侵入行為<br />
              <br />
              第5条（個人情報の取り扱い）<br />
              1. MFJは、会員の個人情報を「プライバシーポリシー」に従って適切に取り扱います。<br />
              2. 会員の個人情報は、本サービスの提供及び会員サポートのために利用されます。<br />
              3. 会員が5年以上にわたり年度継続せず、情報更新も行われない場合、MFJは当該会員の情報を適切な手続により本サービスから破棄することができるものとします。この場合、MFJは個別の通知を行う義務を負わないものとします。<br />
              <br />
              第6条（サービスの変更・中断・終了）<br />
              1. MFJは、必要と判断した場合、事前に通知することなく本サービスの内容を変更又は終了することができるものとします。<br />
              2. 天災、システム障害、その他の不可抗力により本サービスの提供が一時的に中断される場合があります。<br />
              <br />
              第7条（退会）<br />
              1. 会員は、MFJ所定の手続を行うことで、いつでも本サービスから退会することができます。<br />
              2. 退会後も、会員は在籍中に行った行為に関する責任を負うものとします。<br />
              <br />
              第8条（免責事項）<br />
              1. MFJは、会員が本サービスを利用することによって生じた損害に対し、一切の責任を負わないものとします。ただし、MFJの故意又は過失による場合はこの限りではありません。<br />
              2. 本サービス内で提供される情報や助言について、MFJはその正確性や信頼性を保証しません。<br />
              3. 本サービスには、外部のウェブサイトへのリンクが含まれることがありますが、MFJはそれらの外部サイトの内容や正確性、信頼性について一切の責任を負いません。会員が外部サイトを利用する場合は、自己の責任において行うものとします。<br />
              <br />
              第9条（規約の変更）<br />
              MFJは、必要に応じて本規約を変更することができるものとし、変更後の規約はMFJウェブサイトに掲載した時点で効力を生じるものとします。<br />
              <br />
              第10条（準拠法及び管轄）<br />
              このウェブサイトの利用並びに本ご利用条件の解釈及び適用は、他に別段の定めのない限り、日本国法に準拠するものとします。また、本サービスの利用に関わる全ての紛争については、他に別段の定めのない限り、東京地方裁判所を第一審の専属管轄裁判所とするものとします。<br />
            </p>
          </div>
        </div>
        <div class="container my-4">
          <div class="d-flex flex-column flex-md-row">
            <button
              class="button03 mb-2 mb-md-0 me-md-2 flex-grow-1"
              @click="back('national')"
            >
              <font-awesome-icon
                :icon="['fas', 'chevron-left']"
                class="mx-2"
              />
              <span class="button03__text">戻る</span>
            </button>
            <button
              class="button04 flex-grow-1"
              @click="proceed('mail')"
            >
              <span class="button04__text">規約に同意して進む</span>
              <font-awesome-icon
                :icon="['fas', 'chevron-right']"
                class="mx-2"
              />
            </button>
          </div>
        </div>
      </div>
      <div
        class="text-start col-10 offset-1 col-xl-6 offset-xl-3"
        v-else-if="mail_mode"
      >
        <form @submit.prevent="submitForm" novalidate>
          <div class="box02 mb-32">
            <h2 class="box02__title">
              <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
              {{ message }}
            </h2>
          </div>
          <div class="mb-5">
            <div class="page__inputGroupCol">
              <p class="page__inputGroupInner">
                <label class="label page__inputGroupLabel" for="email"
                  >メールアドレス</label
                >
                <input
                  type="email"
                  class="input page__inputGroupInput"
                  id="email"
                  v-model="post_mail_address"
                  placeholder="touroku@mfj.or.jp"
                  @input="validateEmail"
                />
              </p>
            </div>
          </div>
          <div class="container mb-4">
            <div
              class="d-flex flex-column flex-md-row mt-32 justify-content-center align-items-center gap-3 gap-md-4 w-100"
            >
              <button class="button03 w-100" @click="back('kiyaku')">
                <font-awesome-icon
                  :icon="['fas', 'chevron-left']"
                  class="mx-2"
                />
                <span class="button03__text">戻る</span>
              </button>
              <button class="button04 w-100" @click="send_pass_cd()">
                <span class="button04__text">送信する</span>
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="mx-2"
                />
              </button>
            </div>
          </div>
        </form>
      </div>

      <div class="col-12 col-xl-10 offset-xl-1" v-else-if="passcd_mode">
        <div class="mb-3">
          <div class="box02 mb-32">
            <h2 class="box02__title">
              <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
              確認Noの入力
            </h2>
          </div>
        </div>
        <div class="mb-5">
          <div class="page__inputGroupCol">
            <p class="page__inputGroupInner">
              <label class="label page__inputGroupLabel" for="inputEmail"
                >確認No.</label
              >
              <input
                type="text"
                class="input page__inputGroupInput"
                id="inputEmail"
                v-model="passcd"
                placeholder="例 0123"
              />
            </p>
            <div
              v-if="isPasscdSubmitted && passcdError"
              class="text-danger mt-2"
            >
              {{ passcdError }}
            </div>
          </div>
          <div class="container mb-4">
            <div
              class="d-flex flex-column flex-md-row mt-32 justify-content-center align-items-center gap-3 gap-md-4 w-100"
            >
              <button class="button03 w-100" @click="back('mail')">
                <font-awesome-icon
                  :icon="['fas', 'chevron-left']"
                  class="mx-2"
                />
                <span class="button03__text">戻る</span>
              </button>
              <button class="button04 w-100" @click="chk_pass_cd()">
                <span class="button04__text">進む</span>
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="mx-2"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.input-group > .form-control-plaintext {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  padding: 0.375rem;
  border-bottom: solid 1px #999;
}

.form-control-plaintext {
  border-bottom: solid 1px #999;
  border-bottom: solid 1px #999;
}

.alert-info {
  background-color: #f8f9fa;
  border-color: #dee2e6;
  color: #495057;
}

.table {
  margin-bottom: 2rem;
  border: 1px solid #dee2e6;
}

.table th,
.table td {
  border: 1px solid #dee2e6;
  padding: 0.75rem;
  vertical-align: middle;
}

.table th {
  background-color: #f8f9fa;
}

@media (max-width: 768px) {
  .table td {
    font-size: 0.9rem;
  }
}

li .fa-circle {
  position: absolute;
  left: 0;
  top: 0.5rem;
  color: #666;
}
</style>
<script>
import { mapState } from 'vuex'
import StepView from './StepView.vue'
import config from '../../../const'
import Common from '../../../common/common'
// メールアドレスのフォーマットをチェックする正規表現
const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
  name: 'RegistAddView',
  data: function () {
    return {
      page_mode: 'national',
      update_result: false,
      update_error: false,
      regist_obj: {},
      data_obj: {},
      message_class: 'info',
      message: '',
      target_step: ['kiyaku', 'mail'],
      post_mail_address: '',
      passcd: '',
      isValidEmail: false,
      email: '',
      isFormSubmitted: false,
      isPasscdSubmitted: false,
      isLoading: false,
    }
  },
  components: {
    StepView
  },
  methods: {
    go_location(url){
      location.href= url;
    },
    async proceed(mode) {
      this.isLoading = true
      try {
        await new Promise(resolve => setTimeout(resolve, 500)) // ローディングを0.5秒表示
        
        this.page_mode = mode
        Common.moveToTop()
        this.message = 'メールアドレスを入力して送信してください。'
        Common.moveToTop()
      } finally {
        this.isLoading = false
      }
    },
    async back(mode) {
      this.isLoading = true
      try {
        await new Promise(resolve => setTimeout(resolve, 500)) // ローディングを0.5秒表示
        
        this.message_class = 'info'
        this.page_mode = mode
      } finally {
        this.isLoading = false
      }
    },
    async send_pass_cd() {
      this.isLoading = true
      try {
        await new Promise(resolve => setTimeout(resolve, 500))
        
        this.isFormSubmitted = true
        if (this.emailError) {
          this.message_class = 'danger'
          this.message = this.emailError
          this.$forceUpdate()
          return
        }
        if (!this.post_mail_address) {
          this.message_class = 'danger'
          this.message = 'メールアドレスを入力して送信してください。'
          this.$forceUpdate()
          return
        }
        if (!this.isValidEmail) {
          this.message_class = 'danger'
          this.message = '有効なメールアドレスを入力してください。'
          this.$forceUpdate()
          return
        }
        const params = {}
        params['stat'] = [1]
        params['regist_mail_address'] = this.post_mail_address
        await this.nonloginPosts_S(
          config.BASE_API_URL + '/member_regist/send_pass_cd',
          params
        )
          .then(async response => {
            console.log('loadRegistData!!!', response)
            if (
              response.data &&
              response.data.status == 200 &&
              response.data.body &&
              response.data.body.status == 200
            ) {
              this.update_result = true
              this.update_error = false
              this.page_mode = 'passcd'
              this.message = '確認Noを入力してください'
              this.data_obj =
                response.data && response.data.body && response.data.body
                  ? response.data.body
                  : {}
            } else if (
              response.data &&
              response.data.status == 200 &&
              response.data.body &&
              response.data.body.status != 200
            ) {
              this.update_error = true
              this.message_class = 'danger'
              this.message = response.data.body.message
                ? response.data.body.message
                : 'メールアドレス宛に送信できませんでした。'
            } else {
              this.update_error = true
              this.message_class = 'danger'
              this.message = 'メールアドレス宛に送信できませんでした。。'
            }
          })
          .catch(async err => {
            this.update_result = false
            this.update_error = true
            this.message = err
            console.log('error', err)
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      } finally {
        this.isLoading = false
      }
    },
    async chk_pass_cd() {
      this.isLoading = true
      try {
        await new Promise(resolve => setTimeout(resolve, 500))
        
        if (!this.post_mail_address) {
          this.message_class = 'danger'
          this.message = 'メールアドレスは必須です。入力してください。'
          this.$forceUpdate()
          return true
        }

        if (!REGEX_EMAIL.test(this.post_mail_address)) {
          this.message_class = 'danger'
          this.message = '有効なメールアドレスを入力してください。'
          this.$forceUpdate()
          return true
        }

        if (!this.passcd || this.passcd.length !== 4) {
          alert('4桁の確認Noを入力して下さい')
          return false
        }

        const params = {}
        params['regist_mail_address'] = this.post_mail_address
        params['pass_cd'] = this.passcd

        const response = await this.nonloginPosts_S(
          config.BASE_API_URL + '/member_regist/chk_pass_cd',
          params
        )

        console.log('loadRegistData!!!', response)
        if (
          response.data &&
          response.data.status == 200 &&
          response.data.body &&
          response.data.body.status == 200
        ) {
          this.update_result = true
          this.update_error = false
          if (response.data.body.token) {
            this.go_form(response.data.body.token)
          } else {
            alert('error')
          }
        } else if (
          response.data &&
          response.data.status == 200 &&
          response.data.body &&
          response.data.body.status != 200
        ) {
          this.update_error = true
          this.message_class = 'danger'
          this.message =
            response.data.body.message || '確認Noが確認できませんでした。'
        } else {
          this.update_error = true
          this.message_class = 'danger'
          this.message = '確認Noが確認できませんでした。'
        }
      } catch (err) {
        this.update_result = false
        this.update_error = true
        this.message = err
        console.log('error', err)
      } finally {
        this.isLoading = false
      }
    },
    async go_form(token) {
      this.$router.push({
        path: '/regist/form',
        query: {
          token: token
        }
      })
    },
    async loadRegistData() {
      const params = {}
      params['stat'] = [1]
      //   params['application_check_type'] = 100;
      //   params['application_type'] = 2500;
      // params['token'] = this.$route.query.token
      await this.nonloginPosts_S(
        config.BASE_API_URL + '/member_regist/lists',
        params
      )
        .then(async response => {
          console.log('loadRegistData!!!', response)
          if (response.data && response.data.status == 200) {
            this.regist_obj =
              response.data && response.data.body && response.data.body[0]
                ? response.data.body[0]
                : {}
            if ((this.regist_obj || {}).step != 'add') {
              console.log('not add redirect')
            }
          } else {
            // this.message = '規約を確認し、同意して申請を進めてください'
            this.message = '申請の流れを確認し、申請を進めて下さい。'
          }
        })
        .catch(async err => {
          this.message = err
          console.log('error', err)
        })
        .finally(async () => {
          this.$forceUpdate()
        })
    },
    validateEmail() {
      this.isValidEmail = REGEX_EMAIL.test(this.post_mail_address)
      // this.message = '' // メッセージをクリア
    }
  },
  computed: {
    emailError() {
      if (!this.isFormSubmitted) {
        return '' // フォームが送信されていない場合は空文字を返す
      }
      if (!this.post_mail_address) {
        return 'メールアドレスは必須です。'
      }
      if (!REGEX_EMAIL.test(this.post_mail_address)) {
        return '有効なメールアドレスを入力してください。'
      }
      return ''
    },
    passcdError() {
      if (!this.passcd) {
        return '確認Noは必須です。'
      }
      if (this.passcd.length !== 4 || !/^\d{4}$/.test(this.passcd)) {
        return '確認Noは4桁の数字で入力してください。'
      }
      return ''
    },
    ...mapState({}),
    message_class_style: function () {
      const message_class = this.message_class
      return function (target) {
        if (target == 'border') {
          return (
            'text-center py-2 bs_fs_5 mb-2 border-bottom border-' +
            message_class
          )
        } else if (target == 'alert') {
          return 'alert alert-' + message_class
        }
      }
    },
    national_mode: function () {
      return this.page_mode == 'national' ? true : false
    },
    kiyaku_mode: function () {
      return this.page_mode == 'kiyaku' ? true : false
    },
    mail_mode: function () {
      return this.page_mode == 'mail' ? true : false
    },
    passcd_mode: function () {
      return this.page_mode == 'passcd' ? true : false
    }
  },

  async created() {
    await this.loadRegistData()
  },
  watch: {
    post_mail_address() {
      this.validateEmail()
    }
  },
  mounted: function () {
    this.validateEmail()
  },
  props: {}
}
</script>

<template>
  <section class="page">
    <div class="container">
      <h3 class="title02 page__h2 mb-24">継続申請公認クラブ登録メンバー管理</h3>
      <div class="row">
        <div v-if="alert_type == 'default'" class="box mb-24">
        <p>
          クラブメンバーの変更についてご注意下さい。<br />
          個人情報保護の観点より新たに追加されるメンバー名は一部表示としております。<br />
          ライセンスNo.の入力は誤りの無いようご注意下さい。<br />
          公認クラブの申請には、有効ライセンスをお持ちのクラブメンバー5名以上の登録が必要です。<br />
          メンバー人数が5名に達しない場合、年度継続申請できませんのでご留意下さい。<br />
          メンバーを追加する際は、追加するメンバーとしてご申請いただいたMFJ会員様宛に公認クラブへの招待メールを送信いたします。<br />
          この招待に対し承諾が得られた時点でメンバー追加が完了します。<br />
          <!-- 招待メンバーが会員登録時のメールアドレスが届かない場合があります。 -->
          <!-- {{ message }} -->
        </p>
      </div>
      <div v-if="alert_type == 'update_success'" class="box box--center mb-24">
        <p>{{ message }}</p>
      </div>
      </div>
      
    </div>
    <div class="container">
      <ul class="nav nav-tabs mb-4">
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: activeTab === 'members' }"
            @click.prevent="activeTab = 'members'"
            href="#"
            >メンバー</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: activeTab === 'migrations' }"
            @click.prevent="activeTab = 'migrations'"
            href="#"
            >新システム未移行</a
          >
        </li>
        <li class="nav-item" v-show="false">
          <a
            class="nav-link"
            :class="{ active: activeTab === 'search' }"
            @click.prevent="activeTab = 'search'"
            href="#"
            >会員検索</a
          >
        </li>
      </ul>

      <!-- 所属メンバー一覧タブ -->
      <div v-show="activeTab === 'members'" class="member-list mt-24">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-24"
        >
          <h3
            class="title02 page__h2 border-dark border-bottom border-2 mb-3 mb-md-0"
          >
            所属メンバー一覧
          </h3>
          <div class="align-items-center">
            <h3 class="title02 page__h2">年度: {{ sports_year }}</h3>
          </div>
        </div>
        <!-- モバイル用のカードビュー -->
        <div class="d-block d-md-none">
          <div
            v-for="(rel, index) in m_relation_obj"
            :key="index"
            class="card mb-3"
          >
            <div class="card-body">
              <h5 class="card-title">
                No.{{ index + 1 }}&nbsp;
                <span v-if="rel?.m_data?.last_sports_year > sports_year" class="badge bg-success">{{ rel?.m_data?.last_sports_year }}</span>
                <span v-else-if="!rel.m_data && !rel.stat" class="">-</span>
                <span v-else class="badge bg-secondary">{{ rel?.m_data?.last_sports_year }}</span>
              </h5>
              <div class="row g-2">
                <div class="col-12">
                  <div class="form-group">
                    <label class="form-label">ライセンスNo</label>
                    <div class="d-flex gap-2">
                      <input
                        v-if="!rel.m_data && !rel.stat"
                        type="text"
                        v-model="rel.license_no"
                        :class="input_class_no(index)"
                        placeholder="ライセンスNoを入力"
                        :readonly="rel.stat === 0 || rel.stat === 1"
                      />
                      <input
                        v-else
                        type="text"
                        v-model="rel.license_no"
                        :class="(rel.m_data && !rel.stat)? input_class_no(index): 'form-control-plaintext'"
                        placeholder="ライセンスNoを入力"
                        :readonly="(rel.m_data && !rel.stat)? rel.stat === 0 || rel.stat === 1: 'readonly'"
                      />

                      <!-- メンバーではない空の場合のみ検索ボタンを表示 -->
                      <button
                        v-if="!rel.m_data && !rel.stat"
                        type="button"
                        class="button02 btn-sm"
                        style="width: 30%"
                        @click="searchMemberByLicense(rel, index)"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'magnifying-glass']"
                        />&nbsp;検索
                      </button>
                      <button
                        v-else-if="rel.m_data && !rel.stat"
                        type="button"
                        class="button02 btn-sm"
                        style="width: 30%"
                        @click="searchMemberByLicense(rel, index)"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'magnifying-glass']"
                        />&nbsp;再検索
                      </button>
                      
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <strong>会員名:</strong>
                  <div class="mt-1">{{ (rel.m_data || {}).name_label }}</div>
                </div>
                <div class="col-12">
                  <strong>姓カナ:</strong>
                  <div class="mt-1">{{ (rel.m_data || {}).yomi_label }}</div>
                </div>
                <div class="col-12">
                  <strong>参加年度:</strong>
                  <div class="mt-1">
                    {{ (rel || {}).sports_year || '' }}
                  </div>
                </div>
                <div class="col-12">
                  <strong>都道府県:</strong>
                  <div class="mt-1">
                    {{ (rel.m_data || {}).pref_label || '' }}
                  </div>
                </div>
                <div class="col-12">
                  <strong>承認状況:</strong>
                  <div class="mt-1">
                    <span v-if="rel.stat == 1">{{
                      rel.permission_date_datetime_label
                    }}</span>

                    <span v-else-if="rel.stat == 9">削除中</span>
                    <span v-else>{{ rel.permission_status_label }}</span>
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <div class="d-flex gap-2">
                    <!-- メンバー情報が取得できていて、未登録の場合に参加依頼ボタンを表示 -->
                    <button
                      v-if="rel.m_data && !rel.stat"
                      type="button"
                      @click="sendInvitation(rel, index)"
                      class="button02 btn-sm"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'paper-plane']"
                      />&nbsp;参加依頼
                    </button>
                    <!-- 承認済みの場合に登録解除ボタンを表示 -->
                    <button
                      v-else-if="parseInt(rel.stat) === 1"
                      type="button"
                      @click="delete_relation(rel.m_id)"
                      class="button02 btn-sm"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'trash']"
                      />&nbsp;登録解除
                    </button>
                    <!-- 承認待ちの場合にキャンセルボタンを表示 -->
                    <button
                      v-else-if="parseInt(rel.stat) === 0"
                      type="button"
                      @click="delete_relation(rel.m_id)"
                      class="button02 btn-sm"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'xmark']"
                      />&nbsp;キャンセル
                    </button>
                    <!-- <button
                      v-if="parseInt(rel.stat) === 0"
                      type="button"
                      @click="sendInvitation(rel.m_id)"
                      class="button02 btn-sm"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'xmark']"
                      />&nbsp;再招待
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- PC用のテーブルビュー -->
        <div class="table-responsive d-none d-md-block">
          <!-- {{ m_relation_list }} -->
          <table class="table table-bordered">
            <thead>
              <tr>
                <th style="width:10%;">-</th>
                <th style="width:15%;">ライセンスNo</th>
                <th style="width:15%;">会員名(カナ)</th>
                <th style="width:10%;">参加年度</th>
                <th style="width:10%;">都道府県</th>
                <th style="width:10%;">承認状況</th>
                <th style="width:10%;">操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rel, index) in m_relation_obj" :key="index">
                <template v-if="!rel.m_data && !rel.stat">
                  <td class="d-flex gap-2 form-group" colspan="2">
                    <div class="form-group w-100">
                      <input
                        type="text"
                        v-model="rel.license_no"
                        :class="input_class_no(index)"
                        class="form-control"
                        placeholder="ライセンスNoを入力"
                        :readonly="rel.stat === 0 || rel.stat === 1"
                      />
                    </div>
                  </td>
                  <td colspan="2">
                    <!-- メンバーではない空の場合のみ検索ボタンを表示 -->
                    <button
                      type="button"
                      class="button02 btn-sm"
                      style="width: 40%"
                      @click="searchMemberByLicense(rel, index)"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'magnifying-glass']"
                      />&nbsp;検索
                    </button>
                  </td>
                </template>
                <template v-else>
                  <td>
                    <!-- メンバー情報が取得できていて、未登録の場合に参加依頼ボタンを表示 -->
                    <button
                      v-if="rel.m_data && !rel.stat"
                      type="button"
                      @click="searchMemberByLicense(rel, index)"
                      class="button02 btn-sm"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'magnifying-glass']"
                      />&nbsp;再検索
                    </button>
                    <span v-else-if="rel?.m_data?.last_sports_year > sports_year" class="badge bg-success">{{ rel?.m_data?.last_sports_year }}</span>
                    <span v-else-if="!rel.m_data && !rel.stat" class="">-</span>
                    <span v-else class="badge bg-secondary">{{ rel?.m_data?.last_sports_year }}</span>
                  </td>
                  <td class="d-flex gap-2 form-group">
                    <div class="form-group w-100">
                      <input
                        type="text"
                        v-model="rel.license_no"
                        :class="(rel.m_data && !rel.stat)? input_class_no(index): 'form-control-plaintext'"
                        placeholder="ライセンスNoを入力"
                        :readonly="(rel.m_data && !rel.stat)? rel.stat === 0 || rel.stat === 1: 'readonly'"
                      />
                    </div>
                  </td>
                  <td>{{ (rel.m_data || {}).name_label || '' }}({{ (rel.m_data || {}).yomi_label || '' }})</td>
                </template>
                
                <td>{{ rel?.license_year || '' }}</td>
                <td>{{ (rel.m_data || {}).pref_label || '' }}</td>
                <td>{{ rel.permission_status_label || '' }}</td>
                <td>
                  <div class="d-flex gap-2">
                    <!-- メンバー情報が取得できていて、未登録の場合に参加依頼ボタンを表示 -->
                    <button
                      v-if="rel.m_data && !rel.stat"
                      type="button"
                      @click="sendInvitation(rel, index)"
                      class="button02 btn-sm"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'paper-plane']"
                      />&nbsp;参加依頼
                    </button>
                    <!-- 承認済みの場合に登録解除ボタンを表示 -->
                    <button
                      v-else-if="parseInt(rel.stat) === 1"
                      type="button"
                      @click="delete_relation(rel.m_id)"
                      class="button02 btn-sm"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'trash']"
                      />&nbsp;登録解除
                    </button>
                    <!-- 承認待ちの場合にキャンセルボタンを表示 -->
                    <button
                      v-else-if="parseInt(rel.stat) === 0"
                      type="button"
                      @click="delete_relation(rel.m_id)"
                      class="button02 btn-sm"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'xmark']"
                      />&nbsp;キャンセル
                    </button>
                    <!-- <button
                      v-if="parseInt(rel.stat) === 0"
                      type="button"
                      @click="sendInvitation(rel, index)"
                      class="button02 btn-sm"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'xmark']"
                      />&nbsp;再招待
                    </button> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-show="activeTab === 'migrations'" class="member-list mt-24">

        <div class="table-responsive d-none d-md-block">
          <!-- {{ m_relation_list }} -->
          <table class="table table-bordered">
            <thead>
              <tr>
                <th style="width:15%;">ライセンスNo</th>
                <th style="width:15%;">参加年度</th>
                <th style="width:auto;">会員名(カナ)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(migration, index) in m_migration_obj" :key="index">
                <td>{{ migration.license_no }}</td>
                <td>{{ migration.license_year }}</td>
                <td>{{ migration.member_name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- 会員検索タブ -->
      <div v-show="activeTab === 'search'" class="search-section">
        <div class="card">
          <div class="card-header">
            <h4 class="mb-0">会員検索</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="search_member">
              <div class="row g-3">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">ライセンスNo</label>
                    <input
                      type="text"
                      v-model="search_license_no"
                      class="form-control"
                      placeholder="ライセンスNoを入力"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">姓カナ</label>
                    <input
                      type="text"
                      v-model="search_sec_cd"
                      class="form-control"
                      placeholder="姓カナを入力"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">都道府県</label>
                    <select class="form-select" v-model="search_pref">
                      <option value="">選択してください</option>
                      <option
                        v-for="pref in pref_list"
                        :key="pref.key"
                        :value="pref.key"
                      >
                        {{ pref.label }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">種目</label>
                    <select class="form-select" v-model="search_event_cd">
                      <option value="">選択してください</option>
                      <option
                        v-for="event in event_cd_obj"
                        :key="event.id"
                        :value="event.id"
                      >
                        {{ event.label }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button type="submit" class="button02">
                      <font-awesome-icon
                        :icon="['fas', 'magnifying-glass']"
                      />&nbsp;検索
                    </button>
                    <button
                      type="button"
                      class="button02"
                      @click="clearSearchForm"
                    >
                      <font-awesome-icon :icon="['fas', 'times']" />&nbsp;クリア
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- 検索結果の表示 -->
        <div v-if="search_results.length > 0" class="mt-4">
          <h4 class="mb-3">検索結果</h4>

          <!-- モバイル用の検索結果 -->
          <div class="d-block d-md-none">
            <div
              v-for="result in search_results"
              :key="result.id"
              class="card mb-3"
            >
              <div class="card-body">
                <div class="row g-2">
                  <div class="col-12">
                    <strong>ライセンスNo:</strong>
                    <div class="mt-1">{{ result.license_no }}</div>
                  </div>
                  <div class="col-12">
                    <strong>会員名:</strong>
                    <div class="mt-1">{{ result.name_label }}</div>
                  </div>
                  <div class="col-12">
                    <strong>姓カナ:</strong>
                    <div class="mt-1">{{ result.yomi_label }}</div>
                  </div>
                  <div class="col-12">
                    <strong>都道府県:</strong>
                    <div class="mt-1">{{ result.pref_label }}</div>
                  </div>
                  <div class="col-12">
                    <strong>種目:</strong>
                    <div class="mt-1">{{ result.event_cd_name }}</div>
                  </div>
                  <div class="col-12 mt-2">
                    <button
                      class="button02 btn-sm w-100"
                      @click="addMember(result)"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'paper-plane']"
                      />&nbsp;参加依頼する
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- PC用の検索結果テーブル -->
          <div class="table-responsive d-none d-md-block">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>ライセンスNo</th>
                  <th>会員名</th>
                  <th>姓カナ</th>
                  <th>都道府県</th>
                  <th>種目</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="result in search_results" :key="result.id">
                  <td>{{ result.license_no }}</td>
                  <td>{{ result.name_label }}</td>
                  <td>{{ result.yomi_label }}</td>
                  <td>{{ result.pref_label }}</td>
                  <td>{{ result.event_cd_name }}</td>
                  <td>
                    <div class="d-flex gap-2 align-items-center">
                      <button
                        class="button02 btn-sm"
                        @click="addMember(result)"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'paper-plane']"
                        />&nbsp;参加依頼する
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    padding: 0.375rem;
    border-bottom: solid 1px #999;
  }
  /* .form-control-plaintext { */
  /* border-bottom: solid 1px #999; */
  /* }
     */
  .card {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .card-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
  }

  .form-label {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .nav-tabs .nav-link {
    cursor: pointer;
    color: #666;
  }

  .nav-tabs .nav-link.active {
    color: #000;
    font-weight: 500;
  }

  /* モバイル対応用のスタイル */
  @media (max-width: 767.98px) {
    .card {
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .card-body {
      padding: 1rem;
    }

    .form-control-sm {
      height: calc(1.5em + 0.5rem + 2px);
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
    }

    .button02.btn-sm {
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
    }
  }

  /* タブのスタイル改善 */
  .nav-tabs {
    border-bottom: 2px solid #dee2e6;
  }

  .nav-tabs .nav-link {
    margin-bottom: -2px;
    border: none;
    border-bottom: 2px solid transparent;
  }

  .nav-tabs .nav-link.active {
    border-bottom: 2px solid #000;
  }
</style>

<script>
  import { mapState } from 'vuex'
  // import BsPageTitle from '../../molecules/BsPageTitle.vue'
  // import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
  // import BsSelect from '../../atoms/BsSelect.vue'
  // import BsCheckBox from '../../atoms/BsCheckBox.vue'
  // import '../../assets/bootstrap/css/sc.css';
  // import BsSelect from '../../atoms/BsSelect.vue'
  import config from '../../../const'

  export default {
    name: 'ClubMemberDataView',
    data: function () {
      return {
        targetid: this.$store.state.cauth.userId,
        btnLabel: '',
        alert_type: 'default',
        message:
          'クラブに所属する会員情報を確認・追加変更する場合は、各項目から変更申請を行って下さい。',
        data_obj: {},
        m_migration_obj: [],
        m_relation_obj: [],
        m_rel_form_obj: {
          // id: 0,
          // stat: 0,
          // c_id: this.$store.state.cauth.userId,
          // m_id: 0,
          // license_no: '',
          // rel_type: 0,
          // sort: 0,
          // sendmail_flg: false,

          // permission_status_label: '',
          // m_data: null,
          
          license_no: '',
          m_data: null,
          stat: null, // 初期状態ではnull
          sendmail_flg: false,
          permission_status_label: '', // 初期状態では空
        },
        search_license_no: '',
        search_sec_cd: '',
        current_index: null, // 現在選択中のメンバーインデックス
        sports_year: '', // 年度
        term_year: [], // 年度情報
        activeTab: 'members', // タブの状態管理
        search_pref: '', // 都道府県検索
        search_event_cd: '', // 種目検索
        pref_list: [], // 都道府県リスト
        event_cd_obj: [], // 種目リスト

        // 検索結果のテストデータ
        search_results: [],
        current_member: null, // 現在選択中のメンバー
        empty_forms: Array(10)
          .fill()
          .map(() => ({
            // 10個の空フォーム
            id: 0,
            license_no: '',
            m_id: 0,
            stat: 0,
            sendmail_flg: false,
            m_data: {},
          })),
      }
    },
    components: {
      // BsSelect,
      //   BsMsgAlert,
      // BsCheckBox,
    },
    methods: {
      getIcon(flg, id) {
        if (id === 0) {
          return ['fas', 'envelope'] // 参加メール送信のアイコン
        } else {
          return ['fas', 'reply'] // 再送信のアイコン
        }
      },
      async delete_relation(m_id){
        if(m_id <= 0 || this.data_obj.id <= 0){
          alert('エラー')
          return
        }
        if(
          !window.confirm('削除します。よろしいですか？')
        ){
          return
        }
        const params = {
          stat: [0, 1],
          m_id: m_id,
          c_id: this.data_obj.id
        }
        const post_url_path = '/club_m_relation/delete_lists'
        await this.basicPosts_S(config.BASE_API_URL + post_url_path, params)
          .then(async (response) => {
            console.log('update', response)
            if (response.data && response.data.status == 200) {
              alert('登録解除しました')
            }
            else{
              alert('登録解除できませんでした')
            }
          })
          .catch(async (err) => {
            console.log(err)
            alert('登録解除できませんでした')
          })
          .finally(async () => {
            await this.loadMRelationObj()
            this.$forceUpdate()
            return true
          })
      },
      async update_relation() {
        if (typeof this.m_relation_obj === 'object') {
          // this.m_relation_obj.forEach(async (rel) => {
          //     await this.update(rel);
          // });
          const cnt = this.m_relation_obj.length
          for (let i = 0; i <= cnt; i++) {
            if (i == cnt) {
              await this.loadMRelationObj().finally(() => {
                this.alert_type = 'update_success'
                this.message = '登録しました。'
              })
            } else {
              const tparam = { ...this.m_relation_obj[i] }
              await this.update(tparam)
            }
          }
          // this.m_relation_obj.map(map => )
          // await this.loadMRelationObj();
        }
      },
      async update_single(index) {
        if (typeof this.m_relation_obj === 'object') {
          await this.update({ ...this.m_relation_obj[index] })
          await this.loadMRelationObj().finally(() => {
            this.alert_type = 'update_success'
            this.message = '登録しました。'
          })
        }
      },
      async update(params) {
        console.log('update', params)
        const post_url_path =
          params.id > 0
            ? '/club_m_relation/edit/' + params.id
            : '/club_m_relation/add'
        await this.basicPosts_I(config.BASE_API_URL + post_url_path, params)
          .then(async (response) => {
            console.log('update', response)
          })
          .catch(async (err) => {
            console.log(err)
          })
          .finally(async () => {
            this.$forceUpdate()
            return true
          })
      },
      async add_m_rel() {
        const tmp = { ...this.m_rel_form_obj }
        this.m_relation_obj.push(tmp)
      },
      async del_m_rel(index) {
        const tmps = [...this.m_relation_obj]
        const tmp = tmps[index]
        if (typeof tmp === 'object' && parseInt(tmp.id) > 0) {
          tmp.stat = 9
          await this.update(tmp)
          await this.loadMRelationObj()
          // tmps.splice(index, 1);
        } else {
          tmps.splice(index, 1)
          this.m_relation_obj = tmps
        }
      },
      async search_member() {
        try {
          // クラブIDの存在チェック
          if (!this.data_obj?.id) {
            console.error('クラブIDが取得できていません:', this.data_obj)
            alert('クラブ情報の読み込みに失敗しました。ページを再読み込みしてください。')
            return
          }

          // 検索パラメータの設定
          const params = {
            sprm: {
              c_id: this.data_obj.id,
              term_year: this.sports_year
            }
          }

          // デバッグ用ログ
          console.log('Club ID:', this.data_obj.id)
          console.log('Search params:', params)

          // 各検索条件を設定
          if (this.search_license_no) {
            params.sprm.license_no = this.search_license_no
          }
          if (this.search_sec_cd) {
            params.sprm.yomi_1 = this.search_sec_cd
          }
          if (this.search_pref) {
            params.sprm.pref_cd = this.search_pref
          }
          if (this.search_event_cd) {
            params.sprm.event_cd = this.search_event_cd
          }

          const response = await this.basicPosts_S(
            `${config.BASE_API_URL}/member/club_search`,
            params
          )

          console.log('Search response:', response)

          if (response.data?.status === 200) {
            this.search_results = response.data.body.map((member) => {
              // 都道府県の情報を取得
              const pref = this.pref_list.find(
                (p) => Number(p.key) === Number(member.pref_cd)
              )

              return {
                id: member.mfj_member_data_id || member.mfj_member_migration_id,
                license_no: member.license_no,
                name_label: member.name_label,
                yomi_label: member.yomi_label,
                pref_cd: member.pref_cd,
                pref_label: pref ? pref.label : '',
                event_cd_name: member.member_license?.[0]?.license_label,
                last_sports_year: member.last_year,
                sendmail_flg: true,
              }
            })
            console.log('Search results:', this.search_results)

            if (this.search_results.length === 0) {
              alert('該当する会員が見つかりませんでした。')
            }
          }
        } catch (error) {
          console.error('Search error:', error)
          alert('検索中にエラーが発生しました。')
        }
      },
      async loadMemberList(index, license_no, search_sec_cd) {
        console.log(
          'loadMemberList index, license_no, search_sec_cd',
          index,
          license_no,
          search_sec_cd
        )
        const params = {}
        params['stat'] = [1]

        let post_url = config.BASE_API_URL
        post_url += '/club_m_relation/member'
        params['license_no'] = license_no
        // if(search_sec_cd) params['search_sec_cd'] = search_sec_cd;
        if (search_sec_cd) params['yomi_1'] = search_sec_cd
        params['pagegroup'] = 'mfj_member_data.id'

        const tmps = [...this.m_relation_obj]
        const tmp = tmps[index]

        await this.basicPosts_S(post_url, params)
          .then(async (response) => {
            console.log('loadMemberList response', response)
            if (response.data && response.data.status == 200) {
              const tmp_obj =
                response.data && response.data.body && response.data.body[0]
                  ? response.data.body[0]
                  : {}
              tmp.m_id = tmp_obj.id
              tmp.m_data = tmp_obj
              if (tmp.stat == 0) {
                tmp.stat =
                  search_sec_cd != false &&
                  (tmp_obj.sec_cd_chk == true || tmp_obj.yomi_1_chk == true)
                    ? 1
                    : 0
              }
              tmps[index] = tmp
            } else {
              alert('データがありません。')
            }
          })
          .catch(async (err) => {
            console.log(err)
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadConfigObj(param) {
        const params = {}
        await this.basicPosts_S(
          config.BASE_API_URL + '/config/' + param,
          params
        )
          .then(async (response) => {
            console.log('loadConfigObj response', response)
            if (response.data && response.data.body) {
              if (param === 'pref') {
                this.pref_list = response.data.body
              } else {
                this[param + '_obj'] = response.data.body
              }
            }
          })
          .catch(async (err) => {
            console.log(err)
            if (param === 'pref') {
              this.pref_list = []
            } else {
              this[param + '_obj'] = []
            }
          })
          .finally(async () => {
            this.$forceUpdate()
          })
      },
      async loadClubObj() {
        try {
          const clubToken = this.$store.state.cauth.token
          if (!clubToken) {
            console.error('クラブトークンが見つかりません')
            return false
          }

          const params = {
            stat: [1],
            token: clubToken
          }
          
          console.log('Loading club with params:', params)
          
          const response = await this.basicPosts_S(config.BASE_API_URL + '/club/lists', params)
          console.log('loadClubObj response:', response)

          if (response.data?.status === 200 && response.data.body?.length > 0) {
            this.data_obj = response.data.body[0]
            // Vuexストアを直接更新
            this.$store.state.cauth.userId = this.data_obj.id
            console.log('Loaded club data:', this.data_obj)
            return true
          } else {
            console.error('Club data not found:', response.data)
            return false
          }
        } catch (error) {
          console.error('Error loading club data:', error)
          return false
        }
      },
      async loadMRelationObj() {
        try {
          // data_objからIDを直接使用
          const params = {
            // sprm: {
              c_id: this.data_obj.id,  // store.state.cauth.userIdの代わりにdata_obj.idを使用
              stat: [0, 1]
            // }
          }

          console.log('Load relation params:', params)
          console.log('Club ID:', this.data_obj.id)

          const response = await this.basicPosts_S(
            `${config.BASE_API_URL}/club_m_relation/lists`,
            params
          )

          console.log('Load relation full response:', response)
          console.log('Response data:', response.data)

          if (response.data?.status === 200 && response.data.body) {
            const tmpss = Array.isArray(response.data.body)? response.data.body: []
            
            const m_relation_tmp = []
            const tmps = tmpss.sort((a, b) => {
              if (parseInt(a.license_year) > parseInt(b.license_year)) {
                return -1
              } else {
                return 1
              }
            })
            tmps.map(relation => {
              relation.permission_status_label = this.getPermissionStatus(relation)
              if (!m_relation_tmp.find(obj => obj?.m_id == relation?.m_id)) {
                m_relation_tmp.push(relation)
              } else if (
                m_relation_tmp.find(
                  obj =>
                    obj?.m_id == relation?.m_id &&
                    obj?.license_year < relation?.license_year
                )
              ) {
                const index = m_relation_tmp.findIndex(
                  obj =>
                    obj?.m_id == relation?.m_id &&
                    obj?.license_year < relation?.license_year
                )
                
                m_relation_tmp.splice(index, 1)
                m_relation_tmp.push(relation)
              }
            })
            const tmp = { ...this.m_rel_form_obj }
            m_relation_tmp.push(tmp)

            this.m_relation_obj = [...m_relation_tmp]
            console.log('Loaded relation data:', this.m_relation_obj)
          } else {
            const m_relation_tmp = []
            const tmp = { ...this.m_rel_form_obj }
            m_relation_tmp.push(tmp)
            this.m_relation_obj = m_relation_tmp
            console.warn('No relation data found:', response.data)
          }
        } catch (error) {
          console.error('Error loading relations:', error)
        }
      },
      async loadMMigrationObj() {
        try {
          // data_objからIDを直接使用
          const params = {
            // sprm: {
              club_no: this.data_obj.club_no,  // store.state.cauth.userIdの代わりにdata_obj.idを使用
              stat: [0, 1],
              // license_year: this.sports_year
            // }
          }

          const response = await this.basicPosts_S(
            `${config.BASE_API_URL}/member/club_migrations`,
            params
          )

          console.log('Load migration full response:', response)
          console.log('Response data:', response.data)

          if (response.data?.status === 200 && response.data.body) {
            const tmpss = Array.isArray(response.data.body)? response.data.body: []
      

            this.m_migration_obj = [...tmpss]
            console.log('Loaded relation data:', this.m_migration_obj)
          } else {
            const m_relation_tmp = []
            this.m_migration_obj = m_relation_tmp
            console.warn('No relation data found:', response.data)
          }
        } catch (error) {
          console.error('Error loading relations:', error)
        }
      },
      back_input() {
        this.change_mode = false
      },
      back() {
        this.$router.push({ path: '/' })
      },
      // テストデータを読み込むメソッド
      loadTestData() {
        this.m_relation_obj = [...this.test_members]
      },
      // 種目リストの取得
      async loadEventCdObj() {
        try {
          const response = await this.basicPosts_S(
            `${config.BASE_API_URL}/event_cd/lists`,
            { stat: [1] }
          )
          if (response.data?.status === 200) {
            this.event_cd_obj = response.data.body || []
            console.log('Loaded events:', this.event_cd_obj)
          }
        } catch (error) {
          console.error('Error loading events:', error)
          this.event_cd_obj = []
        }
      },
      async addMember(member) {
        try {
          const params = {
            c_id: this.$store.state.cauth.userId,
            m_id: member.id,
            license_no: member.license_no,
            sendmail_flg: true,
            stat: 0, // 招待中
            sort: this.m_relation_list.length + 1,
          }

          // 新規登録APIを呼び出し
          const response = await this.basicPosts_S(
            `${config.BASE_API_URL}/club_m_relation/add`,
            params
          )

          if (response.data?.status === 200) {
            // メンバーリストを更新
            await this.loadMRelationObj()

            alert('メンバーを登録しました。')

            // 検索結果をクリア
            this.search_results = []
          } else {
            alert('登録に失敗しました。')
          }
        } catch (error) {
          console.error('Add member error:', error)
          alert('登録中にエラーが発生しました。')
        }
      },
      // 年度情報の取得
      async loadTermYear() {
        try {
          const response = await this.basicPosts_S(
            `${config.BASE_API_URL}/term_year/lists`,
            {}
          )
          console.log('loadTermYear response', response)

          if (response.data?.status === 200 && response.data.body) {
            // 最新の年度を設定
            const latestYear = Math.min(
              ...response.data.body.map((year) => year.label)
            )
            this.sports_year = latestYear.toString()
          }
        } catch (error) {
          console.error('Error loading term year:', error)
        }
      },
      // フォームクリアメソッドを追加
      clearSearchForm() {
        this.search_license_no = ''
        this.search_sec_cd = ''
        this.search_pref = ''
        this.search_event_cd = ''
      },
      // メンバーリストの初期化
      initializeMemberList() {
        // 空のフォームを10個用意
        this.m_relation_obj = Array(10)
          .fill()
          .map(() => ({
            id: 0,
            license_no: '',
            m_data: null,
            stat: null, // 初期状態ではnull
            sendmail_flg: false,
            permission_status_label: '', // 初期状態では空
          }))

        // 既存のデータがある場合は上書き
        if (this.data_obj?.m_relation?.length > 0) {
          this.data_obj.m_relation.forEach((rel, index) => {
            if (rel.stat !== 9) {
              // 削除されていないデータのみ
              this.m_relation_obj[index] = {
                ...rel,
                sendmail_flg: false,
                permission_status_label: this.getPermissionStatus(rel),
              }
            }
          })
        }
      },
      // 承認状態の取得
      getPermissionStatus(rel) {
        if (parseInt(rel.reject_flg) === 1) {
          return '参加辞退'
        } else if (parseInt(rel.rel_type) === 1) {
          return '参加了承済み'
        } else if (parseInt(rel.stat) === 1) {
          return '参加了承済み'
        } else {
          return ''
        }
      },
      // ライセンスNoによるメンバー検索
      async searchMemberByLicense(rel, index) {
        if (!rel.license_no) {
          alert('ライセンスNoを入力してください')
          return
        }
        if(this.m_relation_obj.find((m_relation, idx) => index != idx && m_relation.license_no == rel.license_no.trim())){
          alert('既に入力されているライセンスNoです')
          return
        }

        try {
          // 検索パラメータの設定
          const params = {
            license_no: rel.license_no.trim(),
            c_id: this.data_obj.id,
            term_year: this.sports_year,
          }

          console.log('Search params:', params) // デバッグ用

          const response = await this.basicPosts_S(
            `${config.BASE_API_URL}/member/club_search`,
            params
          )

          console.log('Search response:', response)

          if (response.data?.status === 200 && response.data.body?.length > 0) {
            const member = response.data.body[0]

            // 型を一致させて比較
            const pref = this.pref_list.find(
              (p) => Number(p.key) === Number(member.pref_cd)
            )

            if (member.relation_chk == false) {
              alert('参加できません')
              return
            }
            this.m_relation_obj[index].m_data = {
              // id: member.mfj_member_data_id || member.mfj_member_migration_id,
              id: member.id,
              name_label: member.name_label,
              yomi_label: member.yomi_label,
              // last_sports_year: last_sports_year,
              member_license: member.member_license,
              club_relation: member.club_relation,
              pref_cd: member.pref_cd,
              pref_label: pref ? pref.label : '',
            }

            console.log('Found pref:', pref)
            console.log('Updated m_data:', this.m_relation_obj[index].m_data)

            // club_relationの情報に基づいて状態を更新
            if (member.club_relation) {
              if (parseInt(member.club_relation.reject_flg) === 1) {
                this.m_relation_obj[index].permission_status_label = '参加辞退'
              } else if (parseInt(member.club_relation.rel_type) === 1) {
                this.m_relation_obj[index].permission_status_label = '参加了承済み'
              } else if (parseInt(member.club_relation.stat) === 1) {
                this.m_relation_obj[index].permission_status_label = '参加了承済み'
              }
            }
          } else {
            alert('該当する会員が見つかりませんでした。')
            this.m_relation_obj[index].m_data = null
          }
        } catch (error) {
          console.error('Search error:', error)
          alert('検索中にエラーが発生しました。')
          this.m_relation_obj[index].m_data = null
        }
      },
      // 参加依頼送信
      async sendInvitation(rel) {
        console.log('sendInvitation', rel)
        if (!rel.m_data?.id) {
          alert('メンバー情報が不正です')
          return
        }

        try {
          const params = {
            m_relation: [
              {
                c_id: this.$store.state.cauth.userId,
                m_id: rel.m_data.id,
                license_no: rel.license_no,
                license_year: this.sports_year,
                rel_type: 0, // 初期値
                stat: 0, // 未承認
              },
            ],
          }

          console.log('Invitation params:', params)

          const response = await this.basicPosts_S(
            `${config.BASE_API_URL}/club/invitation`,
            params
          )

          if (response.data?.status === 200) {
            // メンバーリストを更新
            await this.loadMRelationObj()
            alert('参加依頼を送信しました。')

            // 検索結果をクリア
            rel.m_data = null
            rel.license_no = ''
          } else {
            alert(
              response.data?.alert?.message || '参加依頼の送信に失敗しました。'
            )
          }
        } catch (error) {
          console.error('Invitation error:', error)
          alert('参加依頼の送信中にエラーが発生しました。')
        }
      },
    },
    computed: {
      ...mapState({}),
      btn_class_sendmail: function () {
        return function (flg) {
          if (flg) {
            return 'button02'
          }
          return 'btn btn-outline-secondary btn-light btn-sm'
        }
      },
      btn_icon_class_sendmail: function () {
        return function (flg) {
          if (flg) {
            return 'fas fa-check-square me-1'
          }
          return 'far fa-square me-1'
        }
      },
      m_relation_list_last_year: function(){
        const m_relation_obj = []
        const tmps = [...this.m_relation_obj].sort((a, b) => {
          if (parseInt(a.license_year) > parseInt(b.license_year)) {
            return -1
          } else {
            return 1
          }
        })
        tmps.map(relation => {
          if (!m_relation_obj.find(obj => obj?.m_id == relation?.m_id)) {
            m_relation_obj.push(relation)
          } else if (
            m_relation_obj.find(
              obj =>
                obj?.m_id == relation?.m_id &&
                obj?.license_year < relation?.license_year
            )
          ) {
            const index = m_relation_obj.findIndex(
              obj =>
                obj?.m_id == relation?.m_id &&
                obj?.license_year < relation?.license_year
            )

            m_relation_obj.splice(index, 1)
            m_relation_obj.push(relation)
          }
        })

        if(m_relation_obj.length < 10){
          for(let i=0;i<(10 - m_relation_obj.length);i++){
            const tmp = { ...this.m_rel_form_obj }
            m_relation_obj.push(tmp)
          }
        }else{
          for(let i=0;i<3;i++){
            const tmp = { ...this.m_rel_form_obj }
            m_relation_obj.push(tmp)
          }
        }
        return m_relation_obj
      },
      m_relation_list: function () {
        const m_relation_obj = this.m_relation_obj
        return m_relation_obj
          .sort((a, b) => {
            if (parseInt(a.sort) < parseInt(b.sort)) {
              return -1
            } else {
              return 1
            }
          })
          .map((rel) => {
            rel.search_sec_cd = ''
            rel.sendmail_flg = rel.sendmail_flg ? rel.sendmail_flg : false
            if (rel.permission_date_datetime) {
              rel.permission_date_datetime_label =
                rel.permission_date_datetime + 'に承認'
            } else if (rel.stat == 1) {
              rel.permission_date_datetime_label = '承認済'
            }
            if (
              !rel.permission_status_label &&
              rel.permission_date_datetime_label
            ) {
              rel.permission_status_label = rel.permission_date_datetime_label
            } else if (rel.reject_flg == 1) {
              rel.permission_status_label = '参加辞退'
            } else if (rel.stat == 0) {
              rel.permission_status_label = '承認待ち'
            } else if (rel.stat == 1) {
              rel.permission_status_label = '承認済'
            }
            let last_sports_year = 0;
            if(
              Array.isArray(rel?.m_data?.member_license)
            ){
              rel?.m_data.member_license.map(license => {
                if(
                  parseInt(license.license_year) > parseInt(last_sports_year)
                ){
                  last_sports_year = license.license_year
                }
              })
              rel.m_data.last_sports_year = last_sports_year
            }
            return rel
          })

          // let last_sports_year = 0;
          //   if(Array.isArray(member.member_license)){
          //     member.member_license.map(license => {
          //       if(
          //         parseInt(license.license_year > last_sports_year)
          //       ){
          //         last_sports_year = license.license_year
          //       }
          //     })
          //   }
          // .map(member => {
          //     member.last_sports_year = false
          //     if(Array.isArray(member?.member_license)){
          //       let last_sports_year = 0
          //       member.member_license.map(license => {
          //         if(license.license_year > last_sports_year){
          //           last_sports_year = license.license_year
          //         }
          //       })
          //       member.last_sports_year = last_sports_year
          //     }
          //     return member
          //   })
        // return this.m_relation_obj;
      },
      chk_m_rel: function () {
        const m_relation_list = this.m_relation_obj
        return function (index) {
          if (
            typeof m_relation_list === 'object' &&
            typeof m_relation_list[index] === 'object'
          ) {
            return m_relation_list[index]
          }
          return false
        }
      },
      chk_m_rel_m_id: function () {
        const chk_m_rel = this.chk_m_rel
        return function (index) {
          if (chk_m_rel(index)) {
            return parseInt(chk_m_rel(index).m_id)
          }
          return false
        }
      },
      chk_m_rel_stat: function () {
        const chk_m_rel = this.chk_m_rel
        return function (index) {
          if (chk_m_rel(index)) {
            return parseInt(chk_m_rel(index).stat)
          }
          return false
        }
      },
      chk_m_rel_reject: function () {
        const chk_m_rel = this.chk_m_rel
        return function (index) {
          if (chk_m_rel(index)) {
            return parseInt(chk_m_rel(index).reject_flg)
          }
          return false
        }
      },
      input_class_no: function () {
        const chk_m_rel_m_id = this.chk_m_rel_m_id
        return function (index) {
          if (chk_m_rel_m_id(index) == 0) {
            return 'input form-control'
          } else {
            return 'input form-control-plaintext'
          }
        }
      },
      input_class_stat_0: function () {
        const chk_m_rel_m_id = this.chk_m_rel_m_id
        const chk_m_rel_stat = this.chk_m_rel_stat
        return function (index) {
          if (
            chk_m_rel_m_id(index) === 0 ||
            (chk_m_rel_m_id(index) !== false && chk_m_rel_stat(index) === 0)
          ) {
            return 'form-control'
          } else {
            return 'form-control-plaintext'
          }
        }
      },
      input_class: function () {
        const chk_m_rel_m_id = this.chk_m_rel_m_id
        return function (index) {
          if (chk_m_rel_m_id(index) == 0) {
            return 'form-control-plaintext'
          } else {
            return 'form-control-plaintext'
          }
        }
      },
      input_readonly_no: function () {
        const chk_m_rel_m_id = this.chk_m_rel_m_id
        return function (index) {
          if (chk_m_rel_m_id(index) == 0) {
            return false
          } else {
            return true
          }
        }
      },
      input_readonly_stat_0: function () {
        const chk_m_rel_m_id = this.chk_m_rel_m_id
        const chk_m_rel_stat = this.chk_m_rel_stat
        return function (index) {
          if (
            chk_m_rel_m_id(index) === 0 ||
            (chk_m_rel_m_id(index) !== false && chk_m_rel_stat(index) === 0)
          ) {
            return false
          } else {
            return true
          }
        }
      },
      input_readonly: function () {
        const chk_m_rel_m_id = this.chk_m_rel_m_id
        return function (index) {
          if (chk_m_rel_m_id(index) == 0) {
            return true
          } else {
            return true
          }
        }
      },
      debugPrefLabel() {
        return (pref_cd) => {
          console.log(
            'Current rel.m_data:',
            this.m_relation_obj.map((rel) => rel.m_data)
          )
          console.log('Current pref_cd:', pref_cd)
          console.log('Current pref_list:', this.pref_list)

          if (!pref_cd || !this.pref_list) return ''

          // pref_listの形式を確認
          const pref = this.pref_list.find((p) => {
            console.log('Comparing:', p.key, pref_cd, p.key === pref_cd)
            return p.key === pref_cd
          })

          console.log('Found pref:', pref)
          return pref ? pref.label : ''
        }
      },
    },
    async created() {
      try {
        // 認証チェック
        if (!this.$store.state.cauth.token) {
          console.error('認証情報がありません')
          this.$router.push('/clogin')  // ログインページにリダイレクト
          return
        }

        const clubLoaded = await this.loadClubObj()
        if (!clubLoaded) {
          console.error('クラブ情報の読み込みに失敗しました')
          alert('クラブ情報の読み込みに失敗しました。ページを再読み込みしてください。')
          return
        }

        // クラブIDの存在を確認
        if (!this.data_obj?.id) {
          console.error('クラブIDが取得できていません:', this.data_obj)
          alert('クラブ情報が不正です。ページを再読み込みしてください。')
          return
        }

        // 他の初期化処理を続行
        await Promise.all([
          this.loadConfigObj('pref'),
          this.loadEventCdObj(),
          this.loadTermYear()
        ])

        this.initializeMemberList()
        await this.loadMRelationObj()
        await this.loadMMigrationObj()
      } catch (error) {
        console.error('Initialization error:', error)
        alert('初期化中にエラーが発生しました。ページを再読み込みしてください。')
      }
    },
    mounted: function () {
      // console.log('tags_list', this.tags_list);
    },
    props: {},
  }
</script>

import client from './client'
import config from '../const'

export default {
  ologin: (authInfo) => {
    return new Promise((resolve, reject) => {
      //   client.post('/auth/login', authInfo)
      // console.log('authinfo', authInfo);
      let params = new URLSearchParams()
      params.append('login_id', authInfo.login_id)
      params.append('login_pass', authInfo.login_pass)
      client
        .post(config.BASE_API_URL + '/owners/login', params)
        .then((res) => {
          console.log('oauth.js login then', res)
          if (
            typeof res === 'object' &&
            typeof res.data === 'object' &&
            res.data.status == '200'
          ) {
            resolve({
              token: res.data.body.token,
              userId: res.data.body.userId,
              label: res.data.body.label,
              type: res.data.body.type,
              owner_no: res.data.body.owner_no,
              // level: res.data.body.level,
              // relations: res.data.body.relations
            })
          } else {
            alert('login error')
            reject(new Error('error'))
          }
        })
        .catch((err) => {
          console.log('auth.js login erro ', err)
          reject(new Error(err || err.response.message || err.message))
        })
    })
  },
  otokenLogin: (authInfo) => {
    return new Promise((resolve, reject) => {
      //   client.post('/auth/login', authInfo)
      // console.log('authinfo', authInfo);
      let params = new URLSearchParams()
      const headersParam = {
        headers: { [config.BASE_API_OWNER_HEADER_KEY]: authInfo.token },
      }
      client
        .post(config.BASE_API_URL + '/owners/check', params, headersParam)
        .then((res) => {
          console.log('oauth.js tokenLogin then', res)
          if (res.data && res.data.body) {
            resolve({
              token: res.data.body.token,
              userId: res.data.body.userId,
              label: res.data.body.label,
              type: res.data.body.type,
              owner_no: res.data.body.owner_no
              // level: res.data.body.level,
              // relations: res.data.body.relations
            })
          } else {
            resolve({
              token: '',
              userId: '',
              owner_no: '',
              label: '',
              type: '',
              level: '',
              relations: [],
            })
          }
        })
        .catch((err) => {
          console.log('oauth.js login erro ', err)
          reject(new Error(err.response.message || err.message))
        })
    })
  },
  // 'regiapi-manage-token'
  ologout: (token) => {
    return new Promise((resolve, reject) => {
      const header = {
        [config.BASE_API_OWNER_HEADER_KEY]: token,
      }
      console.log('ologout token', header, token)
      client
        .get(config.BASE_API_URL + '/owners/logout', { headers: header })
        .then(() => resolve())
        .catch((err) => {
          console.log('logout err ', err)
          reject(new Error(err.response.data.message || err.message))
        })
    })
  },
}
